import React from 'react';
import { Container } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import PropertySearchv2 from './components/PropertySearchv2';
const propertySearchv2 = ({ t }) => (
  <Container >
    <PropertySearchv2 />
  </Container>
);

propertySearchv2.propTypes = {
  t: PropTypes.func.isRequired,
};

export default withTranslation('common')(propertySearchv2);
