import React from 'react';
import DataTables from '../../CommonDataTable/DataTable';
import apiCalls from '../../../../config/apiCalls'
import store from '../../../App/store';

// config file
export default class Employees extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      type: 'Task Template',
      fieldtype: [
        { "label": "User", "value": "users" },
        { "label": "Role", "value": "roles" }
      ]
    };
  }

  static getDerivedStateFromProps(props, state) {
    let storeData = store.getState()
    let languageData = storeData && storeData.settingsData && storeData.settingsData.settings && storeData.settingsData.settings.languageTranslation ? storeData.settingsData.settings.languageTranslation : ""
    return { languageData: languageData };
  }



  componentDidMount = () => {
    //Screen permisions value can be edit,view, no view

  }

  getTableFields = () => {
    let data = [
      {
        "show": true,
        "textAlign": "left",
        "width": 100,
        "field": "ruleName",
        "mobile": true,
        "header": "Name",
        "displayInSettings": true,
        "filter": false,
        "sortable": true,
      },
      {
        "show": true,
        "textAlign": "left",
        "width": 100,
        "fieldType": 'Actions',
        "field": "Actions",
        "header": "Actions",
        "filter": false,
        "displayInSettings": true,
        "sortable": false
      }
    ]
    return data;
  };



  getFormFields = () => {
    return ([
      {
        //'show': false,
        "value": "",
        "type": "text",
        "name": "title",
        "label": "Name",
        "id": "name",
        "placeholder": "Name",
        "required": true
      },
      {
        //'show': false,
        "value": "",
        "type": "checkedField",
        "name": "assignType",
        "label": "Assign Type",
        "id": "assignType",
        "placeholder": "Select Type",
        "options": this.state.fieldtype,
        "required": false
      },

    ]);
  }


  submit = async (item) => {
    await this.setState({
      openViewModal: true,
      rowData: [{ 'label': 'name', 'value': 'new name' }]
    })
  }

  render() {
    return (
      <span>
        <DataTables
          onRef={(ref) => (this.datatableref = ref)}
          getTableFields={this.getTableFields}
          formFields={this.getFormFields}
          statusFilter={false}
          className={true}
          exportRequried={false}
          printRequried={false}
          addRequried={true}
          editRequired={true}
          deleteRequired={true}
          viewRequired={true}
          settingsRequired={false}
          filterRequired={false}
          gridRequried={false}
          sample={false}
          globalSearchFieldName='user'
          globalSearch={'Search'}
          type={this.state.type}
          displayright={true}
          icon='list'
          routeTo='rules'
          displayViewOfForm='screen'
          apiResponseKey='rules'
          apiUrl={apiCalls.TaskTemplates}
          entityType='employee'
        />
      </span>
    );
  }
}