import React from 'react';
import apiCalls from '../../../../config/apiCalls'
import DataTables from '../../CommonDataTable/DataTable';
import Loader from '../../../App/Loader';
import fetchRequest from '../../../../config/service';
import config from '../../../../config/config'

// config file
export default class OpenCorporateBranchBatch extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      type: 'OpenCorporateBranchBatch',
    };
  }


  componentDidMount = () => {
    //Screen permisions value can be edit,view, no view
    // let screenPermissions = RolePermissions.screenPermissions('Employees');
  }

  //  Table Column Fields
  getTableFields = () => {
    let data = [
      // {
      //   "show": true,
      //   textAlign: 'left',
      //   width: 110,
      //   field: 'Company-id',
      //   "mobile": true,
      //   header: 'Company-id',
      //   filter: false,
      //   sortable: false,
      // },
      {
        "show": true,
        textAlign: 'left',
        width: 300,
        field: 'Company-name',
        "mobile": true,
        header: 'Company-name',
        filter: false,
        sortable: false,
      }, {
        "show": true,
        textAlign: 'left',
        width: 110,
        field: 'Company-jurisdiction_code',
        "mobile": true,
        header: 'Company-jurisdiction_code',
        filter: false,
        sortable: false,
      }, {
        "show": true,
        textAlign: 'left',
        width: 180,
        field: 'Company-company_number',
        "mobile": true,
        header: 'Company-company_number',
        filter: false,
        sortable: false,
      }, {
        "show": true,
        textAlign: 'left',
        width: 110,
        field: 'Company-current_status',
        "mobile": true,
        header: 'Company-current_status',
        filter: false,
        sortable: false,
      }, {
        "show": true,
        textAlign: 'left',
        width: 110,
        field: 'Company-incorporation_date_orig',
        "mobile": true,
        header: 'Company-incorporation_date_orig',
        fieldType: "Date",
        dateFormat: config.dateDBFormat,
        filter: false,
        sortable: false,
      }, {
        "show": true,
        textAlign: 'left',
        width: 110,
        field: 'Company-dissolution_date_orig',
        "mobile": true,
        header: 'Company-dissolution_date_orig',
        fieldType: "Date",
        dateFormat: config.dateDBFormat,
        filter: false,
        sortable: false,
      }, {
        "show": true,
        textAlign: 'left',
        width: 110,
        field: 'Company-branch',
        "mobile": true,
        header: 'Company-branch',
        filter: false,
        sortable: false,
      }, {
        "show": true,
        textAlign: 'left',
        width: 110,
        field: 'Company-home_jurisdiction_text',
        "mobile": true,
        header: 'Company-home_jurisdiction_text',
        filter: false,
        sortable: false,
      }, {
        "show": true,
        textAlign: 'left',
        width: 110,
        field: 'Company-home_jurisdiction_company_number',
        "mobile": true,
        header: 'Company-home_jurisdiction_company_number',
        filter: false,
        sortable: false,
      }, {
        "show": true,
        textAlign: 'left',
        width: 110,
        field: 'Company-current_alternative_legal_name',
        "mobile": true,
        header: 'Company-current_alternative_legal_name',
        filter: false,
        sortable: false,
      }, {
        "show": true,
        textAlign: 'left',
        width: 110,
        field: 'Company-previous_names',
        "mobile": true,
        header: 'Company-previous_names',
        filter: false,
        sortable: false,
      }, {
        "show": true,
        textAlign: 'left',
        width: 110,
        field: 'Alternative Name-id',
        "mobile": true,
        header: 'Alternative Name-id',
        filter: false,
        sortable: false,
      }, {
        "show": true,
        textAlign: 'left',
        width: 110,
        field: 'Alternative Name-name',
        "mobile": true,
        header: 'Alternative Name-name',
        filter: false,
        sortable: false,
      }, {
        "show": true,
        textAlign: 'left',
        width: 110,
        field: 'Alternative Name-jurisdiction_code',
        "mobile": true,
        header: 'Alternative Name-jurisdiction_code',
        filter: false,
        sortable: false,
      }, {
        "show": true,
        textAlign: 'left',
        width: 110,
        field: 'Alternative Name-company_number',
        "mobile": true,
        header: 'Alternative Name-company_number',
        filter: false,
        sortable: false,
      }, {
        "show": true,
        textAlign: 'left',
        width: 110,
        field: 'Alternative Name-type',
        "mobile": true,
        header: 'Alternative Name-type',
        filter: false,
        sortable: false,
      }, {
        "show": true,
        textAlign: 'left',
        width: 110,
        field: 'Alternative Name-start_date_orig',
        "mobile": true,
        header: 'Alternative Name-start_date_orig',
        fieldType: "Date",
        dateFormat: config.dateDBFormat,
        filter: false,
        sortable: false,
      }, {
        "show": true,
        textAlign: 'left',
        width: 110,
        field: 'Alternative Name-end_date_orig',
        "mobile": true,
        header: 'Alternative Name-end_date_orig',
        fieldType: "Date",
        dateFormat: config.dateDBFormat,
        filter: false,
        sortable: false,
      }, {
        "show": true,
        textAlign: 'left',
        width: 110,
        field: 'Company-registered_address.in_full',
        "mobile": true,
        header: 'Company-registered_address.in_full',
        filter: false,
        sortable: false,
      }, {
        "show": true,
        textAlign: 'left',
        width: 110,
        field: 'Non-Registered Addresses-in_full',
        "mobile": true,
        header: 'Non-Registered Addresses-in_full',
        filter: false,
        sortable: false,
      }, {
        "show": true,
        textAlign: 'left',
        width: 110,
        field: 'Company-has_been_liquidated',
        "mobile": true,
        header: 'Company-has_been_liquidated',
        filter: false,
        sortable: false,
      }, {
        "show": true,
        textAlign: 'left',
        width: 110,
        field: 'Company-has_insolvency_history',
        "mobile": true,
        header: 'Company-has_insolvency_history',
        filter: false,
        sortable: false,
      }, {
        "show": true,
        textAlign: 'left',
        width: 110,
        field: 'Company-registry_url',
        "mobile": true,
        header: 'Company-registry_url',
        filter: false,
        sortable: false,
      }, {
        "show": true,
        textAlign: 'left',
        width: 110,
        field: 'Company-retrieved_at_orig',
        "mobile": true,
        header: 'Company-retrieved_at_orig',
        fieldType: "Date",
        dateFormat: config.dateDBFormat,
        filter: false,
        sortable: false,
      }
    ]
    return data;
  };
  getOCTableFields = () => {
    let data = [
      // {
      //   "show": true,
      //   textAlign: 'left',
      //   width: 110,
      //   field: 'Company-id',
      //   "mobile": true,
      //   header: 'Company-id',
      //   filter: false,
      //   sortable: false,
      // },
      {
        "show": true,
        textAlign: 'left',
        width: 300,
        field: 'reportName',
        "mobile": true,
        header: 'File Name',
        filter: false,
        sortable: false,
      }, {
        "show": true,
        textAlign: 'left',
        width: 110,
        field: 'createdDateUTC',
        "mobile": true,
        header: 'Date Submitted',
        fieldType: "Date",
        dateFormat: config.lastModifiedDateFormat,
        filter: false,
        sortable: false,
      },
      {
        "show": true,
        textAlign: 'left',
        width: 110,
        field: 'status',
        "mobile": true,
        header: 'status',
        filter: false,
        sortable: false,
      }, {
        "show": true,
        textAlign: 'center',
        width: 180,
        field: 'sheetGenerated',
        "mobile": true,
        header: 'Download',
        fieldType: 'Download',
        filter: false,
        sortable: false,
      },
    ]
    return data;
  };
  // Form Input Fields
  getFormFields = () => {
    return ([]);
  }

//to download file
  downloadFile = async (url, fileName) => {
    this.setState({
      isLoading: true
    });

    fetchRequest('GET', url).then(async (response) => {
      this.setState({
        isLoading: false
      });
      if (response && response.value) {
        //below code is for to convert the Base64 to file
        const byteCharacters = atob(response.value);
        const byteNumbers = new Array(byteCharacters.length);
        for (let i = 0; i < byteCharacters.length; i++) {
          byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);
        const blob = new Blob([byteArray], { type: 'application/octet-stream' });
        const url = URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = `${fileName}.xlsx`;
        document.body.appendChild(a);
        a.click();
        URL.revokeObjectURL(url);
        document.body.removeChild(a);
      }
    })
  }



  render() {
    return (
      <span>
        <Loader loader={this.state.isLoading} />
        <DataTables
          onRef={(ref) => (this.datatableref = ref)}
          getTableFields={this.getOCTableFields}
          formFields={this.getFormFields}
          exportRequried={false}
          dontShowTitle={true}
          printRequried={false}
          sortField={"createdDateUTC"}
          addRequried={false}
          editRequired={false}
          isClearFilters={false}
          deleteRequired={false}
          downloadFile={this.downloadFile}
          viewRequired={false}
          screenPermissionsRoute={"Open Corporates"}
          settingsRequired={false}
          filterRequired={false}
          gridRequried={false}
          sample={false}
          type='OpenCorporateBranchBatch'
          displayright={false}
          icon='user'
          routeTo='openCorporate'
          displayViewOfForm='modal'
          apiResponseKey="openCorporates"
          apiUrl={apiCalls.openCorporateOCReport}
          entityType='employee'
        />
      </span>
    );
  }
}