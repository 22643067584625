import React from 'react';
import DataTables from '../../CommonDataTable/DataTable';
import store from '../../../App/store';
import fetchMethodRequest from '../../../../config/service';
import apiCalls from '../../../../config/apiCalls';

//Loader
import Loader from '../../../App/Loader';
// config file
export default class FactSet extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      type: 'Properties',
      tabType: "FactSet Entity Subsidiary Count",
      apiUrl: 'businessNames/factSet',
      responseKey: "factSet",
      ReasonsArray: [],
      propertyFilterSortOptions: [
        { label: 'Property Name', value: "propertyName" },
        { label: 'Prop.Address', value: "propertyAddressLine1" },
        { label: 'Business Name', value: "businessName" },
        { label: 'Property Zip Code', value: "propertyZipCode" },
        { label: 'Property City', value: "propertyCity" },
        { label: 'Property State', value: "propertyState" },
      ],
    };
  }

  static getDerivedStateFromProps(props, state) {
    let storeData = store.getState()
    let languageData = storeData && storeData.settingsData && storeData.settingsData.settings && storeData.settingsData.settings.languageTranslation ? storeData.settingsData.settings.languageTranslation : ""
    return { languageData: languageData };
  }



  componentDidMount = async () => {

    if (this.props.onRef) {
      this.props.onRef(this);
    }
    await this.getSettingsData();
    await this.getReasonForEditor();
  }
  getSettingsData = async () => {
    let StatusArray = [];
    let filterCriteria = {}
    filterCriteria['criteria'] = [{ key: 'activeIndicator', value: true, type: 'eq', add_Filter: true },
    //  { key: 'screenType', value: ["Property Editor"], type: 'in', add_Filter: true }
    ];
    fetchMethodRequest('GET', `PropertyStatus?filter=${JSON.stringify(filterCriteria)}&type=exportToCsv`).then(async (response) => {
      if (response && response["propertyStatuses"] && response["propertyStatuses"].length > 0) {
        let propertyStatuses = response["propertyStatuses"]
        for (let status of propertyStatuses) {
          StatusArray.push({ label: status.status, value: status.status })
        }
        await this.setState({ settingsData: StatusArray })
      }
    })
    return this.state.settingsData;
  }

  getReasonForEditor = async () => {
    let ReasonsArray = [];
    let filterCriteria = {};
    filterCriteria['criteria'] = [{ key: 'activeIndicator', value: true, type: 'eq', add_Filter: true }];
    return fetchMethodRequest('GET', `${apiCalls.PropertyReasons}?filter=${JSON.stringify(filterCriteria)}&type=exportToCsv`)
      .then(async (response) => {
        if (response && response["propertyReasons"].length > 0) {
          let propertyReasons = response["propertyReasons"]
          for (let reason of propertyReasons) {
            if (ReasonsArray.findIndex(obj => obj.value === reason.reason) === -1) {
              ReasonsArray.push({ label: reason.reason, value: reason.reason })
            }
          }
          await this.setState({ shownreasons: ReasonsArray })
        } else if (response && response['errorMessage'] === "Session is about to expire in 5 minutes.") {
          await this.setState({
            sessionWarning: true,

          })
        }
      }).catch((err) => {
        return err;
      })
  }

  componentWillUnmount() {
    if (this.props.onRef) {
      this.props.onRef(null);
    }
  }


  getTableFields = (tabType) => {
    if (tabType) {
      let apiUrl = this.state.apiUrl;
      let responseKey = this.state.responseKey;
      if (tabType === 'FactSet Entity Subsidiary Count') {
        apiUrl = apiCalls.FactSet;
        responseKey = "factSet"
      } else if (tabType === 'FactSet Entity Tree') {
        apiUrl = apiCalls.FactSetDetails;
        responseKey = "factSetDetails"
      } else if (tabType === 'FactSet Names-Address List For Scoring') {
        apiUrl = apiCalls.FactSetNamesAddress;
        responseKey = "factSetNameAddress"
      } else {
        apiUrl = apiCalls.FactSet;
        responseKey = "factSet"
      }
      this.setState({
        tabType: tabType,
        apiUrl: apiUrl,
        type: tabType,
        responseKey: responseKey
      })
    }

    let data = [];
    if (tabType === 'FactSet Entity Subsidiary Count') {
      data = this.getFactSetEntitySubsidiaryCountTableFields();
    }
    else if (tabType === 'FactSet Entity Tree') {
      data = this.getFactSetEntityTreeTableFields();
    } else if (tabType === 'FactSet Names-Address List For Scoring') {
      data = this.getFactSetNamesAddressListForScoringTableFields();
    } else {
      data = this.getFactSetEntitySubsidiaryCountTableFields();
    }
    return data;
  };
  getFactSetEntitySubsidiaryCountTableFields = () => {

    let data = [
      {
        "show": true,
        "textAlign": "left",
        "width": 110,
        "field": "CompanyName",
        "mobile": true,
        "header": "Company Name",
        "displayInSettings": true,
        // "filter": true,
        "sortable": false,
        "fieldType": "tabRedirect",
      },
      {
        "show": true,
        "textAlign": "left",
        "width": 110,
        "field": "CompanyID",
        "mobile": true,
        "header": "Company ID",
        "displayInSettings": true,
        // "filter": true,
        "sortable": false,
      },
      {
        "show": true,
        "textAlign": "left",
        "width": 110,
        "field": "SubsidiaryCount",
        "mobile": true,
        "header": "Subsidiary Count",
        "displayInSettings": true,
        // "filter": true,
        "sortable": false,
      }

    ]
    return data;
  };


  getFactSetEntityTreeTableFields = () => {
    let data = [
      {
        "show": true,
        "textAlign": "left",
        "width": 110,
        "field": "Ultimate Parent Entity Name",
        "mobile": true,
        "header": "Ultimate Parent Entity Name",
        "displayInSettings": true,
        // "filter": true,
        "sortable": false,
        "fieldType": "tabRedirect",
      },
      {
        "show": true,
        "textAlign": "left",
        "width": 200,
        "field": "Immediate Parent Entity Name",
        "mobile": true,
        "header": "Immediate Parent Entity Name",
        "displayInSettings": true,
        // "filter": true,
        "sortable": false,
      },
      {
        "show": true,
        "textAlign": "left",
        "width": 200,
        "field": "FactSet Entity Name",
        "mobile": true,
        "header": "FactSet Entity Name",
        "displayInSettings": true,
        // "filter": true,
        "sortable": false,
      },
      {
        "show": true,
        "textAlign": "left",
        "width": 80,
        "field": "FactSet Entity ID",
        "mobile": true,
        "header": "FactSet Entity ID",
        "displayInSettings": true,
        // "filter": true,
        "sortable": false,
      },
      {
        "show": true,
        "textAlign": "left",
        "width": 65,
        "field": "Depth",
        "mobile": true,
        "header": "Depth",
        "displayInSettings": true,
        // "filter": true,
        "sortable": false,
      },
      {
        "show": true,
        "textAlign": "left",
        "width": 110,
        "field": "HQAddressLine1",
        "mobile": true,
        "header": "HQ Add. Line 1",
        "displayInSettings": true,
        // "filter": true,
        "sortable": false,
      }, {
        "show": true,
        "textAlign": "left",
        "width": 110,
        "field": "HQCity",
        "mobile": true,
        "header": "HQ City",
        "displayInSettings": true,
        // "filter": true,
        "sortable": false,
      },
      {
        "show": true,
        "textAlign": "left",
        "width": 110,
        "field": "HQState_Province",
        "mobile": true,
        "header": "HQ State Province",
        "displayInSettings": true,
        // "filter": true,
        "sortable": false,
      },
      {
        "show": true,
        "textAlign": "left",
        "width": 65,
        "field": "HQPostCode",
        "mobile": true,
        "header": "HQ Post Code",
        "displayInSettings": true,
        // "filter": true,
        "sortable": false,
      },
      {
        "show": true,
        "textAlign": "left",
        "width": 200,
        "field": "Transaction Comments",
        "mobile": true,
        "header": "Transaction Comments",
        "displayInSettings": true,
        // "filter": true,
        "sortable": false,
      },
      {
        "show": true,
        "textAlign": "left",
        "width": 110,
        "field": "Previous Parent Entity",
        "mobile": true,
        "header": "Previous Parent Entity",
        "displayInSettings": true,
        // "filter": true,
        "sortable": false,
      },
      {
        "show": true,
        "textAlign": "left",
        "width": 110,
        "field": "New Parent Entity",
        "mobile": true,
        "header": "New Parent Entity",
        "displayInSettings": true,
        // "filter": true,
        "sortable": false,
      },
      {
        "show": true,
        "textAlign": "left",
        "width": 110,
        "field": "Parent Ownership Start Date",
        "mobile": true,
        "fieldType": "Date",
        "dateFormat": 'MM/DD/YYYY',
        "header": "Parent Ownership Start Date",
        "displayInSettings": true,
        // "filter": true,
        "sortable": false,
      },
      {
        "show": true,
        "textAlign": "left",
        "width": 110,
        "fieldType": "Date",
        "dateFormat": 'MM/DD/YYYY',
        "field": "Parent Ownership End Date",
        "mobile": true,
        "header": "Parent Ownership End Date",
        "displayInSettings": true,
        // "filter": true,
        "sortable": false,
      },
    ]
    return data;
  };

  getFactSetNamesAddressListForScoringTableFields = () => {
    let data = [
      {
        "show": true,
        "textAlign": "left",
        "width": 110,
        "field": 'Ultimate Parent Entity Name',
        "mobile": true,
        "header": 'Ultimate Parent Entity Name',
        "displayInSettings": true,
      }, {
        "show": true,
        "textAlign": "left",
        "width": 110,
        "field": 'Immediate Parent Entity Name',
        "mobile": true,
        "header": 'Immediate Parent Entity Name',
        "displayInSettings": true,
      }, {
        "show": true,
        "textAlign": "left",
        "width": 300,
        "field": 'FactSet Entity Name',
        "mobile": true,
        "header": 'FactSet Entity Name',
        "displayInSettings": true,
      }, {
        "show": true,
        "textAlign": "left",
        "width": 300,
        "field": 'BusinessName',
        "mobile": true,
        "header": 'BusinessName',
        "displayInSettings": true,
      }, {
        "show": true,
        "textAlign": "left",
        "width": 110,
        "field": 'FactSet Entity ID',
        "mobile": true,
        "header": 'FactSet Entity ID',
        "displayInSettings": true,
      }, {
        "show": true,
        "textAlign": "left",
        "width": 110,
        "field": "Name Type",
        "mobile": true,
        "header": "Name Type",
        "displayInSettings": true,
      }, {
        "show": true,
        "textAlign": "left",
        "width": 110,
        "field": 'Depth',
        "mobile": true,
        "header": 'Depth',
        "displayInSettings": true,
      }, {
        "show": true,
        "textAlign": "left",
        "width": 110,
        "field": 'HQAddressLine1',
        "mobile": true,
        "header": 'HQAddressLine1',
        "displayInSettings": true,
      }, {
        "show": true,
        "textAlign": "left",
        "width": 110,
        "field": 'HQCity',
        "mobile": true,
        "header": 'HQCity',
        "displayInSettings": true,
      }, {
        "show": true,
        "textAlign": "left",
        "width": 110,
        "field": 'HQState_Province',
        "mobile": true,
        "header": 'HQState_Province',
        "displayInSettings": true,
      }, {
        "show": true,
        "textAlign": "left",
        "width": 110,
        "field": 'HQPostCode',
        "mobile": true,
        "header": 'HQPostCode',
        "displayInSettings": true,
      }, {
        "show": true,
        "textAlign": "left",
        "width": 110,
        "field": 'Transaction Comments',
        "mobile": true,
        "header": 'Transaction Comments',
        "displayInSettings": true,
      }, {
        "show": true,
        "textAlign": "left",
        "width": 110,
        "field": 'Previous Parent Entity',
        "mobile": true,
        "header": 'Previous Parent Entity',
        "displayInSettings": true,
      }, {
        "show": true,
        "textAlign": "left",
        "width": 110,
        "field": 'New Parent Entity',
        "mobile": true,
        "header": 'New Parent Entity',
        "displayInSettings": true,
      }, {
        "show": true,
        "textAlign": "left",
        "width": 110,
        "field": 'Parent Ownership Start Date',
        "mobile": true,
        "header": 'Parent Ownership Start Date',
        "displayInSettings": true,
      }, {
        "show": true,
        "textAlign": "left",
        "width": 110,
        "field": 'Parent Ownership End Date',
        "mobile": true,
        "header": 'Parent Ownership End Date',
        "displayInSettings": true,
      }, {
        "show": true,
        "textAlign": "left",
        "width": 110,
        "field": 'Address Line1',
        "mobile": true,
        "header": 'Address Line1',
        "displayInSettings": true,
      }, {
        "show": true,
        "textAlign": "left",
        "width": 110,
        "field": 'City',
        "mobile": true,
        "header": 'City',
        "displayInSettings": true,
      }, {
        "show": true,
        "textAlign": "left",
        "width": 110,
        "field": 'Zip',
        "mobile": true,
        "header": 'Zip',
        "displayInSettings": true,
      },
    ]
    return data;
  };


  getFormFields = () => {

    return
  }

  submit = async (item) => {

  }

  render() {
    return (
      <div>
        <Loader loader={this.state.isLoading} />
        <span>
          <DataTables
            onRef={(ref) => (this.datatableref = ref)}
            getTableFields={this.getTableFields}
            formFields={this.getFormFields}
            dontShowTitle={true}
            exportRequried={false}
            printRequried={false}
            addRequried={false}
            editRequired={true}
            frozen={true}
            sortField={"CompanyName, BusinessName, PropertyName, SourceState, PropertyCity"}
            deleteRequired={false}
            viewRequired={true}
            settingsRequired={false}
            filterRequired={true}
            xlsxExport={true}
            gridRequried={false}
            sample={false}
            globalSearchFieldName='user'
            globalSearch={''}
            type={"FactSet"}
            displayright={true}
            icon='faCity'
            scrollHeight={"580px"}
            activeTab={"FactSet Entity Subsidiary Count"}
            isClearFilters={this.state.tabType === "Data" ? true : false}
            tabType={true}
            tabOptions={["FactSet Entity Subsidiary Count", "FactSet Entity Tree", "FactSet Names-Address List For Scoring"]}
            fontAwesome={true}
            routeTo='factSet'
            displayViewOfForm='modal'
            apiResponseKey={this.state.responseKey}
            className={true}
            settingsData={this.state.settingsData}
            shownreasons={this.state.shownreasons}
            // isShownnFieldType={true}
            apiUrl={this.state.apiUrl}
            entityType='employee'
          />
          {this.state.isOpenMatchModel ? this.getOpenPropertyMatchModel() : null
          }
        </span>
      </div>
    );
  }
}