import React,{useState} from "react";
import { Dialog } from 'primereact/dialog';
import {InputTextarea} from 'primereact/inputtextarea';
import { Button } from 'reactstrap';

interface props {
    showFillNotes:boolean,
    hideNotesPopup:Function,
    onClickSaveNotes:Function,
    newNotes:string,
    setNewNotes:Function
}

export const FillNotesPopup = ({showFillNotes,hideNotesPopup,onClickSaveNotes,newNotes,setNewNotes}:props) => {
    return <Dialog header={'Notes'} visible={showFillNotes} style={{ width: '44vw' }} closable={false} onHide={() => hideNotesPopup}>
        <div>
            <InputTextarea rows={5} style={{ width: '100%' }} value={newNotes} onChange={(e) => setNewNotes((e.target as HTMLInputElement).value)} />
        </div>
        <div className="text-center pt-3">
            <Button color='primary' outline disabled={!newNotes} onClick={onClickSaveNotes}>Save</Button>
            <Button color='primary' outline onClick={hideNotesPopup}>Cancel</Button>
        </div>

    </Dialog>
}