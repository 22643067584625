import React from 'react';
import DataTables from '../../CommonDataTable/DataTable';
import { SelectButton } from 'primereact/selectbutton';
import apiCalls from '../../../../config/apiCalls'
import config from '../../../../config/config'
import store from '../../../App/store';
import Loader from '../../../App/Loader';
import AddtoQueueModal from '../../CommonModals/Queue'
import moment from 'moment-timezone';

// config file
export default class RsSchedular extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      type: 'RS Scheduler',
      loading: false,
      sessionWarning: false,
      readOnly: false,
      roleOptions: [],
      business: [],
      SelectedFor: [
        { label: 'Update Scheduled Date', value: 'Update Scheduled Date' },
        { label: 'Queue', value: 'Queue' },
        // { label: 'View History', value: 'View History' }
      ],
      queueBody: {}
    };
  }

  static getDerivedStateFromProps(props, state) {
    let storeData = store.getState()
    let languageData = storeData && storeData.settingsData && storeData.settingsData.settings && storeData.settingsData.settings.languageTranslation ? storeData.settingsData.settings.languageTranslation : ""
    return { languageData: languageData };
  }



  componentDidMount = async () => {
    //Screen permisions value can be edit,view, no view
    let loginCredentials = JSON.parse(localStorage.getItem("loginCredentials"));
    if (loginCredentials.rolePermissions[this.state.type] === "Edit") {
      await this.setState({
        readOnly: true
      })
    }
  }


  getTableFields = () => {
    let data = [
      {
        textAlign: "center",
        width: 47,
        field: "",
        // fieldType: "multiple",
        header: "",
        selectionMode: "multiple",
        show: true,
        mobile: true,
        displayInSettings: false,
      },
      {
        "show": true,
        "textAlign": "left",
        "width": 220,
        "isBold": "true",
        // "fieldType": 'label',
        "field": "sellerName",
        // "editField": "dropDown",
        "mobile": false,
        "header": "Seller Name",
        "filter": true,
        // "fieldType": 'profile',
        "displayInSettings": true,
        "sortable": true
      },
      {
        "show": true,
        "textAlign": "left",
        "width": 175,
        "isBold": "true",
        "field": "scoringType",
        // "editField": "dropDown",
        "mobile": false,
        "header": "ScoringType",
        // "filter": true,
        // "fieldType": 'profile',
        "displayInSettings": true,
        // "sortable": true
      },
      {
        "show": true,
        "textAlign": 'left',
        "width": 100,
        "isBold": "true",
        "field": 'scoredTime',
        "mobile": true,
        "dateFormat": "MM/DD/YYYY",
        // "editField": "autoComplete",
        "fieldType": 'Date',
        "tableField": 'name',
        "filterType": "num",
        "header": 'Last Full Search',
        // "options": this.state.roleOptions,
        "filter": true,
        "displayInSettings": true,
        "displayInFilter": true,
        "sortable": true,
      },
      {
        "show": true,
        "textAlign": "left",
        "width": 100,
        "field": "frequency",
        "mobile": true,
        // "options": this.state.roleOptions,
        "header": "Scheduled Frequency",
        "editField": "dropDown",
        options: config.shifts,
        defValue: "None",
        "displayInSettings": true,
        "filter": true,
        "sortable": true,
      },
      {
        "show": true,
        "textAlign": "left",
        "width": 100,
        "mobile": true,
        "field": "nextScheduleTime",
        "fieldType": 'number',
        "header": "Next Scheduled Date",
        "filterType": "num",
        "dateFormat": "MM/DD/YYYY",
        "editField": "date",
        "fieldType": 'Date',
        // "editField": "text",
        "filter": true,
        "displayInSettings": true,
        "displayInFilter": false,
        "sortable": true,
      },
      {
        "show": true,
        "textAlign": "left",
        "width": 70,
        "mobile": true,
        "field": "iterations",
        "header": "Iterations",
        fieldType: "number",
        "editField": "text",
        keyfilter: "pint",
        "filter": true,
        "displayInSettings": true,
        "displayInFilter": false,
        "sortable": false,
      }, {
        "show": true,
        "textAlign": "left",
        "width": 150,
        "mobile": true,
        fieldType: 'onlyTime',

        "field": "pickupTime",
        "header": "pickup Time",
        "editField": "calenderTime",
        "filter": true,
        "displayInSettings": true,
        "displayInFilter": false,
        "sortable": false,
      },
      {
        "show": true,
        "textAlign": "left",
        "width": 200,
        "mobile": true,
        "field": "note",
        "header": "Notes",
        "editField": "textArea",
        "filter": true,
        "displayInSettings": true,
        "displayInFilter": false,
        "sortable": false,
      },



    ]
    return data;
  };

  getTableFieldsbasedonRole = () => {
    let data = this.getTableFields();
    if (!this.state.readOnly) {
    } else {
      data.push(
        {
          "show": true,
          "textAlign": "left",
          "width": 70,
          "fieldType": "rowEditor",
          "header": "Edit",
          "filter": true,
          "sortable": false
        },
      )
    }
    return data;
  }

  getFormFields = () => {
    return (
      [
      ]
    );
  }

  closeAddtoQueueModal = (response) => {
    if (response) {
      this.datatableref.getErrorResponseFromServer(response, "", "error")
    } else {
      this.datatableref.disableInlineEdit([])
      this.datatableref.getreturnSelectedRows("remove")
      let criteris = this.datatableref.getFiltercriteria()
      this.datatableref.getDataFromServer(criteris)
    }
    this.setState({
      openAddtoQueueModal: false,
      selectedvalue: "",
    })
  }

  getAddtoQueueModal = () => {
    return (
      <AddtoQueueModal
        openAddtoQueueModal={this.state.openAddtoQueueModal}
        closeAddtoQueueModal={this.closeAddtoQueueModal}
        selectedRows={this.state.selectedRows}
        selectedvalue={this.state.selectedvalue}
      />
    )
  }

  handleFieldValues(itemdata) {
    // console.log("itemD", itemdata)
    let modifiedData = [];
    itemdata.forEach((item, index) => {
      if (item.nextScheduleTime && item.nextScheduleTime !== null) {
        item.nextScheduleTime = moment(item.nextScheduleTime).format(config.dbcstOnlyDateFormat)
      }
      modifiedData.push(item);
    });
    return modifiedData;
  }

  selectedOption = (val) => {
    let selectedRows = this.datatableref.getreturnSelectedRows()
    if (val === "Queue" || val === "Update Scheduled Date") {
      this.setState({
        openAddtoQueueModal: true
      })
    }
    this.setState({
      selectedvalue: val,
      selectedRows: selectedRows
    })
  }

  getHeaderforSchedule = () => {
    let selectedRows = this.datatableref && this.datatableref.getreturnSelectedRows() ? this.datatableref.getreturnSelectedRows() : []
    return (
      <div className='d-flex justify-content-flex-start mb-2'>

        <div className='d-grid' style={this.state.readOnly ? { display: "block" } : { display: "none" }}>

          <SelectButton className='ml-2' value={this.state.selectedvalue} options={this.state.SelectedFor} onChange={(e) => this.selectedOption(e.value)} disabled={selectedRows.length === 0} />
        </div>
      </div>
    )
  }

  submit = async (item) => {

  }

  render() {
    return (
      <span>
        <Loader loader={this.state.isLoading} />
        <DataTables
          onRef={(ref) => (this.datatableref = ref)}
          getTableFields={this.getTableFieldsbasedonRole}
          formFields={this.getFormFields}
          criteria={[{ key: "scoringType", value: "RegularScoring", type: "eq" }]}
          className={true}
          exportRequried={false}
          isCustomHeader={true}
          isClearFilters={true}
          getHeaderforSchedule={this.getHeaderforSchedule}
          printRequried={false}
          screenPermissionsRoute={"RS Scheduler"}
          addRequried={false}
          editRequired={true}
          deleteRequired={false}
          viewRequired={true}
          rowspan={true}
          settingsRequired={false}
          filterRequired={true}
          gridRequried={false}
          handleFieldValues={this.handleFieldValues}
          isEdit={this.state.readOnly ? true : false}
          multiApi={`${apiCalls.Schedular}/multi`}
          multiMethod="PUT"
          multiApiKey="selectedRecords"
          sample={false}
          globalSearchFieldName='user'
          type='Regular Scoring Scheduler'
          displayright={true}
          icon='calendar-full'
          sortField={"sellerName"}
          routeTo='rsscheduler'
          displayViewOfForm='modal'
          apiResponseKey='nsScoredSellers'
          apiUrl={apiCalls.Schedular}
          entityType='employee'
          sessionWarning={this.state.sessionWarning}
        />
        {this.state.openAddtoQueueModal ? this.getAddtoQueueModal() : null}

      </span>
    );
  }
}