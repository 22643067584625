import React from 'react';
import PropTypes from 'prop-types';
import { Badge } from 'reactstrap';
import { NavLink } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faToggleOff, faBuilding, faCity, faFolder, faUsers,faFilePdf } from '@fortawesome/free-solid-svg-icons';

const SidebarLink = ({
  title, icon, newLink, route, t, onClick, count
}) => (
  < div >
    <NavLink
      to={route}
      onClick={onClick}
      activeclassname="sidebar__link-active"
      activeStyle={{
        backgroundColor: "#f2f4f7", borderTopLeftRadius: 20, borderBottomLeftRadius: 20,
      }}
    >
      <div className="sidebar__link">
        {icon ?
          icon.toLowerCase().includes(".jpg") || icon.toLowerCase().includes(".jpeg") || icon.toLowerCase().includes(".png") ?
            <img className={''} src={require('../../../assets/img/' + icon)} style={{ height: '20px', width: '20px', marginTop: 0, borderRadius: 0 }} alt="" />
            : title === "Task View" ? <FontAwesomeIcon icon={"grip-horizontal"} style={{ marginTop: 7 }} className='sidebar__link-icon' /> :
              title === "User View" ? <FontAwesomeIcon icon={"grip-horizontal"} style={{ marginTop: 7 }} className='sidebar__link-icon' /> :
                icon.toLowerCase().includes("lnr") ? <span className={`sidebar__link-icon lnr ${icon}`} /> :
                  title === "Status" ? <FontAwesomeIcon icon={faToggleOff} style={{ marginTop: 7 }} className='sidebar__link-icon' /> :
                    title === "Process Documents" ? <FontAwesomeIcon icon={faFolder} style={{ marginTop: 7 }} className='sidebar__link-icon' /> :
                      title === "Deal Mgmt" ? <FontAwesomeIcon icon={faUsers} style={{ marginTop: 7 }} className='sidebar__link-icon' /> :
                        title === "Properties" ? <FontAwesomeIcon icon={faCity} style={{ marginTop: 7 }} className='sidebar__link-icon' /> :
                          title === "Property Editor" || title === "OPRA2 Property Editor" || title === "Deal Sizing-Properties" ? <FontAwesomeIcon icon={faBuilding} style={{ marginTop: 7 }} className='sidebar__link-icon' /> :
                          title === "PDF Editor" ? <FontAwesomeIcon icon={faFilePdf} style={{ marginTop: 7 }} className='sidebar__link-icon' /> :

                            <span className={`sidebar__link-icon lnr lnr-${icon}`} />
          : ''}

        <p className="sidebar__link-title" activeclassname='sidebar__link-active'
        >
          {t(title)}
          {/* {count ?
            <div>
              <span>(</span>
              {count}
              <span>)</span>
            </div>
            : ''} */}
          {newLink ? <Badge className="sidebar__link-badge"><span>New</span></Badge> : ''}
        </p>
      </div>
    </NavLink>
  </div >
);

SidebarLink.propTypes = {
  title: PropTypes.string.isRequired,
  icon: PropTypes.string,
  newLink: PropTypes.bool,
  route: PropTypes.string,
  onClick: PropTypes.func,
};

SidebarLink.defaultProps = {
  icon: '',
  newLink: false,
  route: '/',
  onClick: () => { },
};

export default withTranslation('common')(SidebarLink);
