import React from 'react';
import { Container } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import Request from './components/Request';

const SendRequest = ({ t }) => (
    <Container>
        <Request />
    </Container>
);

SendRequest.propTypes = {
    t: PropTypes.func.isRequired,
};

export default withTranslation('common')(SendRequest);
