import React from 'react';
import { Container } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import DataFeedInclusion from './components/DataFeedInclusion';
const dataFeedInclusion = ({ t }) => (
  <Container>
    <DataFeedInclusion />
  </Container>
);

dataFeedInclusion.propTypes = {
  t: PropTypes.func.isRequired,
};

export default withTranslation('common')(dataFeedInclusion);
