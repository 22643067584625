import React from 'react';
import { Container } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import Investor from './components/Investor';
const investor = ({ t }) => (
  <Container>
    <Investor />
  </Container>
);

investor.propTypes = {
  t: PropTypes.func.isRequired,
};

export default withTranslation('common')(investor);
