import React from 'react';
import DataTables from '../../CommonDataTable/DataTable';
import apiCalls from '../../../../config/apiCalls'
import store from '../../../App/store';
import Loader from '../../../App/Loader';

// config file
export default class Employees extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      type: 'Employees',
      loading: false,
      sessionWarning: false,
    };
  }

  static getDerivedStateFromProps(props, state) {
    let storeData = store.getState()
    let languageData = storeData && storeData.settingsData && storeData.settingsData.settings && storeData.settingsData.settings.languageTranslation ? storeData.settingsData.settings.languageTranslation : ""
    return { languageData: languageData };
  }



  componentDidMount = () => {
    //Screen permisions value can be edit,view, no view
  }

  getTableFields = () => {
    let data = [
      {
        "show": true,
        "textAlign": "left",
        "width": 150,
        "field": "estateID",
        "mobile": true,
        "header": "Estate Id",
        "displayInSettings": true,
        "filter": false,
        "sortable": true,
      },
      {
        "show": true,
        "textAlign": "left",
        "width": 150,
        "header": "Estate Name",
        "mobile": true,
        "field": "estateName",
        "displayInSettings": true,
        "filter": false,
        "sortable": true,
      },
      {
        "show": true,
        "textAlign": "left",
        "width": 150,
        "mobile": true,
        "header": "Status",
        "field": "scoringStatus",
        "filter": false,
        "displayInSettings": true,
        "displayInFilter": false,
        //"options": [{ "label": "Active", "value": 'Active' }, { "label": "Pending", "value": 'Pending' }, { "label": "Inactive", "value": 'Inactive' }],
        "sortable": true,
      },
      {
        "show": true,
        "textAlign": "left",
        "width": 150,
        "field": "scoringDateTime",
        "mobile": true,
        "fieldType": "Date",
        "dateFormat": 'MM-DD-yyyy',
        "header": "Start Date",
        "displayInSettings": true,
        "filter": false,
        "sortable": true,
      },
      {
        "show": true,
        "textAlign": "left",
        "width": 150,
        "header": "Completion Date",
        "mobile": true,
        "fieldType": "Date",
        "dateFormat": 'MM-DD-yyyy',
        "field": "scoringEndTime",
        "displayInSettings": true,
        "filter": false,
        "sortable": true,
      },

      {
        "show": true,
        "textAlign": "left",
        "width": 150,
        "header": "Scoring Type",
        "mobile": true,
        // "fieldType": "Date",
        // "dateFormat": 'MM-DD-yyyy',
        "field": "scoringType",
        "displayInSettings": true,
        "filter": false,
        "sortable": true,
      },


    ]
    return data;
  };


  getFormFields = () => {
    return ([
    ]);
  }



  submit = async (item) => {

  }

  render() {
    return (
      <span>
        <Loader loader={this.state.isLoading} />
        <DataTables
          onRef={(ref) => (this.datatableref = ref)}
          getTableFields={this.getTableFields}
          formFields={this.getFormFields}
          statusFilter={false}
          className={true}
          exportRequried={false}
          printRequried={false}
          addRequried={false}
          editRequired={true}
          deleteRequired={true}
          viewRequired={true}
          settingsRequired={false}
          filterRequired={true}
          gridRequried={false}
          sample={false}
          globalSearchFieldName='user'
          globalSearch={'Search'}
          dataKey={"Sno"}
          isSelect={"multiple"}
          type='Deal Sizing'
          displayright={true}
          icon='line-spacing'
          routeTo='dealSizing'
          sortField={"estateName"}
          displayViewOfForm='modal'
          apiResponseKey='ns_scoring_trackers'
          apiUrl={apiCalls.Deals}
          entityType='employee'
          sessionWarning={this.state.sessionWarning}
        />

      </span>
    );
  }
}