import React from 'react';
import DataTables from '../../CommonDataTable/DataTable';
import apiCalls from '../../../../config/apiCalls'
import store from '../../../App/store';

// config file
export default class Employees extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      type: 'Properties',
      propertyFilterSortOptions: [
        { label: 'Seller Name', value: "estate_Company_Name" },
        { label: 'Non-Match Prop.Address', value: "non_Matching_Property_Address" },
        { label: 'Matching ESt.Add Line1', value: "matching_Estate_Address_Line1" },
        { label: 'Matching ESt.Add State', value: "matching_Estate_Address_State" },
        { label: 'Prop.Holder Name', value: "property_HolderName" },
        { label: 'Prop.Des', value: "property_PropertyDescription" },
      ],
    };
  }

  static getDerivedStateFromProps(props, state) {
    let storeData = store.getState()
    let languageData = storeData && storeData.settingsData && storeData.settingsData.settings && storeData.settingsData.settings.languageTranslation ? storeData.settingsData.settings.languageTranslation : ""
    return { languageData: languageData };
  }



  componentDidMount = () => {
    //Screen permisions value can be edit,view, no view
  }

  // getTableFields = () => {

  //   let data = [
  //     {
  //       "show": true,
  //       "textAlign": "left",
  //       "width": 180,
  //       "field": "Prop.- StatePropertyId",
  //       "mobile": true,
  //       // "getField": "Property",
  //       "header": "Prop.- StatePropertyId",
  //       "displayInSettings": true,
  //       "filter": true,
  //       "sortable": true,
  //     }, {
  //       "show": true,
  //       "textAlign": "left",
  //       "width": 180,
  //       "field": "PropertyStateId",
  //       "mobile": true,
  //       "header": "Prop.- St.Id",
  //       "displayInSettings": true,
  //       "filter": true,
  //       "sortable": true,
  //     }, {
  //       "show": true,
  //       "textAlign": "left",
  //       "width": 180,
  //       "field": "Prop.- PropertyValueMin",
  //       "mobile": true,
  //       "header": "Prop.- Prop.Value Min",
  //       "displayInSettings": true,
  //       "filter": true,
  //       "sortable": true,
  //     },
  //     {
  //       "show": true,
  //       "textAlign": "left",
  //       "width": 180,
  //       "field": "Prop.- PropertyValueMax",
  //       "mobile": true,
  //       // "fieldType": "Link",
  //       "header": "Prop.- Prop.Value Max",
  //       "displayInSettings": true,
  //       "filter": true,
  //       "sortable": true,
  //     }, {
  //       "show": true,
  //       "textAlign": "left",
  //       "width": 180,
  //       "field": "Prop.- PropertyDescription",
  //       "mobile": true,
  //       "header": "Prop.- Prop.Description",
  //       "filter": true,
  //       "sortable": true,
  //       "displayInSettings": true,
  //       // "options": [{ "label": "Active", "value": 'Active' }, { "label": "Pending", "value": 'Pending' }, { "label": "Inactive", "value": 'Inactive' }],
  //     },
  //     {
  //       "show": true,
  //       "textAlign": "left",
  //       "width": 180,
  //       "field": "Prop.- HolderName",
  //       "mobile": true,
  //       "header": "Prop.- Holder Name",
  //       "displayInSettings": true,
  //       "filter": true,
  //       "sortable": true,
  //     }, {
  //       "show": true,
  //       "textAlign": "left",
  //       "width": 180,
  //       "field": "Prop.- DataSourceId",
  //       "mobile": true,
  //       "header": "Prop.- Data Source Id",
  //       "displayInSettings": true,
  //       "filter": true,
  //       "sortable": true,
  //     }, {
  //       "show": true,
  //       "textAlign": "left",
  //       "width": 180,
  //       "field": "Prop.- AlternateId",
  //       "mobile": true,
  //       "header": "Prop.- Alternate Id",
  //       "displayInSettings": true,
  //       "filter": true,
  //       "sortable": true,
  //     },
  //     {
  //       "show": true,
  //       "textAlign": "left",
  //       "width": 180,
  //       "field": "Non-Match Prop.Address",
  //       "mobile": true,
  //       // "fieldType": "Link",
  //       "header": "Non-Match Prop.Address",
  //       "displayInSettings": true,
  //       "filter": true,
  //       "sortable": true,
  //     }, {
  //       "show": true,
  //       "textAlign": "left",
  //       "width": 180,
  //       "field": "MatchingPropertyName",
  //       "mobile": true,
  //       "header": "Match Prop.Name",
  //       "filter": true,
  //       "sortable": true,
  //       "displayInSettings": true,
  //       // "options": [{ "label": "Active", "value": 'Active' }, { "label": "Pending", "value": 'Pending' }, { "label": "Inactive", "value": 'Inactive' }],
  //     },
  //     {
  //       "show": true,
  //       "textAlign": "left",
  //       "width": 180,
  //       "field": "Match Prop.Owner Add- City",
  //       "mobile": true,
  //       "header": "Match Prop.Owner Add- City",
  //       "filter": true,
  //       "sortable": true,
  //       "displayInSettings": true,
  //       // "options": [{ "label": "Active", "value": 'Active' }, { "label": "Pending", "value": 'Pending' }, { "label": "Inactive", "value": 'Inactive' }],
  //     },
  //     {
  //       "show": true,
  //       "textAlign": "left",
  //       "width": 180,
  //       "field": "Match Prop.Owner Add- Line1",
  //       "mobile": true,
  //       "header": "Match Prop.Owner Add- Line1",
  //       "displayInSettings": true,
  //       "filter": true,
  //       "sortable": true,
  //     }, {
  //       "show": true,
  //       "textAlign": "left",
  //       "width": 180,
  //       "field": "Match Prop.Owner Add- Line2",
  //       "mobile": true,
  //       "header": "Match Prop.Owner Add- Line2",
  //       "displayInSettings": true,
  //       "filter": true,
  //       "sortable": true,
  //     }, {
  //       "show": true,
  //       "textAlign": "left",
  //       "width": 180,
  //       "field": "Match Prop.Owner Add- StateId",
  //       "mobile": true,
  //       "header": "Match Prop.Owner Add- StateId",
  //       "displayInSettings": true,
  //       "filter": true,
  //       "sortable": true,
  //     },
  //     {
  //       "show": true,
  //       "textAlign": "left",
  //       "width": 180,
  //       "field": "Match Prop.Owner Add- Zip",
  //       "mobile": true,
  //       // "fieldType": "Link",
  //       "header": "Match Prop.Owner Add- Zip",
  //       "displayInSettings": true,
  //       "filter": true,
  //       "sortable": true,
  //     },
  //     {
  //       "show": true,
  //       "textAlign": "left",
  //       "width": 180,
  //       "field": "Legacy Notes - AddressNotes",
  //       "mobile": true,
  //       "header": "Legacy Notes - AddNotes",
  //       "filter": true,
  //       "sortable": true,
  //       "displayInSettings": true,
  //       // "options": [{ "label": "Active", "value": 'Active' }, { "label": "Pending", "value": 'Pending' }, { "label": "Inactive", "value": 'Inactive' }],
  //     },
  //     {
  //       "show": true,
  //       "textAlign": "left",
  //       "width": 180,
  //       "field": "Legacy Notes - Denial Notes",
  //       "mobile": true,
  //       "header": "Legacy Notes - Denial Notes",
  //       "filter": true,
  //       "sortable": true,
  //       "displayInSettings": true,
  //       // "options": [{ "label": "Active", "value": 'Active' }, { "label": "Pending", "value": 'Pending' }, { "label": "Inactive", "value": 'Inactive' }],
  //     },
  //     {
  //       "show": true,
  //       "textAlign": "left",
  //       "width": 180,
  //       "field": "Legacy Notes - Name Notes",
  //       "mobile": true,
  //       "header": "Legacy Notes - Name Notes",
  //       "filter": true,
  //       "sortable": true,
  //       "displayInSettings": true,
  //       // "options": [{ "label": "Active", "value": 'Active' }, { "label": "Pending", "value": 'Pending' }, { "label": "Inactive", "value": 'Inactive' }],
  //     },
  //     {
  //       "show": true,
  //       "textAlign": "left",
  //       "width": 180,
  //       "field": "Match - AddProbability",
  //       "mobile": true,
  //       "header": "Match - AddProbability",
  //       "displayInSettings": true,
  //       "filter": true,
  //       "sortable": true,
  //     }, {
  //       "show": true,
  //       "textAlign": "left",
  //       "width": 180,
  //       "field": "Match - ClaimId",
  //       "mobile": true,
  //       "header": "Match - Claim Id",
  //       "displayInSettings": true,
  //       "filter": true,
  //       "sortable": true,
  //     }, {
  //       "show": true,
  //       "textAlign": "left",
  //       "width": 180,
  //       "field": "Match - MatchStatusId",
  //       "mobile": true,
  //       "header": "Match - MatchStatusId",
  //       "displayInSettings": true,
  //       "filter": true,
  //       "sortable": true,
  //     },
  //     {
  //       "show": true,
  //       "textAlign": "left",
  //       "width": 180,
  //       "field": "Match - NameProbability",
  //       "mobile": true,
  //       // "fieldType": "Link",
  //       "header": " Match - Name Probability",
  //       "displayInSettings": true,
  //       "filter": true,
  //       "sortable": true,
  //     },
  //     {
  //       "show": true,
  //       "textAlign": "left",
  //       "width": 180,
  //       "field": "Match - OverallProbability",
  //       "mobile": true,
  //       "header": "Match - Overall Probability",
  //       "displayInSettings": true,
  //       "filter": true,
  //       "sortable": true,
  //     }, {
  //       "show": true,
  //       "textAlign": "left",
  //       "width": 180,
  //       "field": "Match - StatusNotes",
  //       "mobile": true,
  //       "header": "Match - Status Notes",
  //       "displayInSettings": true,
  //       "filter": true,
  //       "sortable": true,
  //     },
  //     {
  //       "show": true,
  //       "textAlign": "left",
  //       "width": 180,
  //       "field": "Matching ESt.AddCity",
  //       "mobile": true,
  //       // "fieldType": "Link",
  //       "header": "Matching ESt.AddCity",
  //       "displayInSettings": true,
  //       "filter": true,
  //       "sortable": true,
  //     }, {
  //       "show": true,
  //       "textAlign": "left",
  //       "width": 180,
  //       "field": "Matching ESt.AddLine1",
  //       "mobile": true,
  //       "header": "Matching ESt.AddLine1",
  //       "displayInSettings": true,
  //       "filter": true,
  //       "sortable": true,
  //     },
  //     {
  //       "show": true,
  //       "textAlign": "left",
  //       "width": 180,
  //       "field": "Matching ESt.AddLine2",
  //       "mobile": true,
  //       // "fieldType": "Link",
  //       "header": "Matching ESt.AddLine2",
  //       "displayInSettings": true,
  //       "filter": true,
  //       "sortable": true,
  //     }, {
  //       "show": true,
  //       "textAlign": "left",
  //       "width": 180,
  //       "field": "Matching ESt.AddState",
  //       "mobile": true,
  //       "header": "Matching ESt.AddState",
  //       "displayInSettings": true,
  //       "filter": true,
  //       "sortable": true,
  //     },
  //     {
  //       "show": true,
  //       "textAlign": "left",
  //       "width": 180,
  //       "field": "Matching ESt.AddZip",
  //       "mobile": true,
  //       // "fieldType": "Link",
  //       "header": "Matching ESt.AddZip",
  //       "displayInSettings": true,
  //       "filter": true,
  //       "sortable": true,
  //     }, {
  //       "show": true,
  //       "textAlign": "left",
  //       "width": 180,
  //       "field": "Matching ESt.Name Issues",
  //       "mobile": true,
  //       "header": "Matching ESt.Name Issues",
  //       "displayInSettings": true,
  //       "filter": true,
  //       "sortable": true,
  //     },
  //   ]
  //   return data;estate_Company_Name
  // };
  getTableFields = () => {

    let data = [
      {
        "show": true,
        "textAlign": "left",
        "width": 220,
        "field": "estate_Company_Name",
        "mobile": true,
        // "getField": "Property",
        "header": "Seller Name",
        "displayInSettings": true,
        "filter": true,
        "sortable": true,
      },
      {
        "show": true,
        "textAlign": "right",
        "width": 130,
        "field": "property_StatePropertyId",
        "filterType": "num",
        "mobile": true,
        // "getField": "Property",
        "header": "St.Prop.Id",
        "displayInSettings": true,
        "filter": true,
        "sortable": true,
      }, {
        "show": true,
        "textAlign": "right",
        "width": 130,
        "filterType": "num",
        "field": "opra_Property_ID",
        "mobile": true,
        // "getField": "Property",
        "header": "Opra Prop.Id",
        "displayInSettings": true,
        "filter": true,
        "sortable": true,
      }, {
        "show": true,
        "textAlign": "left",
        "width": 65,
        "field": "property_StateId",
        "mobile": true,
        "header": "Prop.St.Id",
        "displayInSettings": true,
        "filter": true,
        "sortable": true,
      }, {
        "show": true,
        "textAlign": "right",
        "width": 120,
        "filterType": "num",
        "field": "property_PropertyValueMin",
        "mobile": true,
        "fieldType": "formatCurrency",
        "header": "Prop.Value Min (in $)",
        "displayInSettings": true,
        "filter": true,
        "sortable": true,
      },
      {
        "show": true,
        "textAlign": "right",
        "width": 120,
        "field": "property_PropertyValueMax",
        "mobile": true,
        "filterType": "num",
        "fieldType": "formatCurrency",
        "header": "Prop.Value Max (in $)",
        "displayInSettings": true,
        "filter": true,
        "sortable": true,
      }, {
        "show": true,
        "textAlign": "left",
        "width": 120,
        "field": "property_PropertyDescription",
        "mobile": true,
        "header": "Prop.Des",
        "filter": true,
        "sortable": true,
        "displayInSettings": true,
        // "options": [{ "label": "Active", "value": 'Active' }, { "label": "Pending", "value": 'Pending' }, { "label": "Inactive", "value": 'Inactive' }],
      },
      {
        "show": true,
        "textAlign": "left",
        "width": 120,
        "field": "property_HolderName",
        "mobile": true,
        "header": "Prop.Holder Name",
        "displayInSettings": true,
        "filter": true,
        "sortable": true,
      }, {
        "show": true,
        "textAlign": "left",
        "width": 120,
        "field": "property_DataSourceId",
        "mobile": true,

        "header": "Prop.Data Src Id",
        "displayInSettings": true,
        "filter": true,
        "sortable": true,
      }, {
        "show": true,
        "textAlign": "left",
        "width": 120,
        "field": "property_AlternateId",
        "mobile": true,
        "header": "Prop. Alt Id",
        "displayInSettings": true,
        "filter": true,
        "sortable": true,
      },
      {
        "show": true,
        "textAlign": "left",
        "width": 120,
        "field": "non_Matching_Property_Address",
        "mobile": true,
        // "fieldType": "Link",
        "header": "Non-Mtc Prop.Add",
        "displayInSettings": true,
        "filter": true,
        "sortable": true,
      }, {
        "show": true,
        "textAlign": "left",
        "width": 120,
        "field": "matchingPropertyName",
        "mobile": true,
        "header": "Mtc Prop.Name",
        "filter": true,
        "sortable": true,
        "displayInSettings": true,
        // "options": [{ "label": "Active", "value": 'Active' }, { "label": "Pending", "value": 'Pending' }, { "label": "Inactive", "value": 'Inactive' }],
      },
      {
        "show": true,
        "textAlign": "left",
        "width": 150,
        "field": "matching_Property_Owner_Address_City",
        "mobile": true,
        "header": "Mtc Prop.Owner Add-City",
        "filter": true,
        "sortable": true,
        "displayInSettings": true,
        // "options": [{ "label": "Active", "value": 'Active' }, { "label": "Pending", "value": 'Pending' }, { "label": "Inactive", "value": 'Inactive' }],
      },
      {
        "show": true,
        "textAlign": "left",
        "width": 120,
        "field": "matchingPropertyOwnerAddressLine1",
        "mobile": true,
        "header": "Mtc Prop.Owner Add-Line 1",
        "displayInSettings": true,
        "filter": true,
        "sortable": true,
      }, {
        "show": true,
        "textAlign": "left",
        "width": 120,
        "field": "matching_Property_Owner_Address_Line2",
        "mobile": true,
        "header": "Mtc Prop.Owner Add- Line 2",
        "displayInSettings": true,
        "filter": true,
        "sortable": true,
      }, {
        "show": true,
        "textAlign": "left",
        "width": 110,
        "field": "matching_Property_Owner_Address_StateId",
        "mobile": true,
        "header": "Mtc Prop.Owner Add-St.Id",
        "displayInSettings": true,
        "filter": true,
        "sortable": true,
      },
      {
        "show": true,
        "textAlign": "left",
        "width": 120,
        "field": "matching_Property_Owner_Address_Zip",
        "mobile": true,
        // "fieldType": "Link",
        "header": "Mtc Prop.Owner Add- Zip",
        "displayInSettings": true,
        "filter": true,
        "sortable": true,
      },
      {
        "show": true,
        "textAlign": "left",
        "width": 120,
        "field": "legacy_Notes_AddressNotes",
        "mobile": true,
        "header": "Legacy- Add Notes",
        "filter": true,
        "sortable": true,
        "displayInSettings": true,
        // "options": [{ "label": "Active", "value": 'Active' }, { "label": "Pending", "value": 'Pending' }, { "label": "Inactive", "value": 'Inactive' }],
      },
      {
        "show": true,
        "textAlign": "left",
        "width": 120,
        "field": "legacy_Notes_DenialNotes",
        "mobile": true,
        "header": "Legacy- Denial Notes",
        "filter": true,
        "sortable": true,
        "displayInSettings": true,
        // "options": [{ "label": "Active", "value": 'Active' }, { "label": "Pending", "value": 'Pending' }, { "label": "Inactive", "value": 'Inactive' }],
      },
      {
        "show": true,
        "textAlign": "left",
        "width": 120,
        "field": "legacy_Notes_NameNotes",
        "mobile": true,
        "header": "Legacy Name Notes",
        "filter": true,
        "sortable": true,
        "displayInSettings": true,
        // "options": [{ "label": "Active", "value": 'Active' }, { "label": "Pending", "value": 'Pending' }, { "label": "Inactive", "value": 'Inactive' }],
      },
      {
        "show": true,
        "textAlign": "left",
        "width": 100,
        "field": "match_AddressProbability",
        "fieldType": "Percentage",
        "filterType": "num",
        "mobile": true,
        "header": "Mtc - Add Prob",
        "allowInt": true,
        "displayInSettings": true,
        "filter": true,
        "sortable": true,
      }, {
        "show": true,
        "textAlign": "left",
        "width": 100,
        "field": "match_ClaimId",
        "mobile": true,
        "header": "Mtc - Claim Id",
        "displayInSettings": true,
        "filter": true,
        "sortable": true,
      }, {
        "show": true,
        "textAlign": "left",
        "width": 100,
        "field": "matchMatchStatusId",
        "mobile": true,
        "header": "Mtc Status Id",
        "displayInSettings": true,
        "filter": true,
        "sortable": true,
      },
      {
        "show": true,
        "textAlign": "left",
        "width": 100,
        "filterType": "num",
        "field": "match_NameProbability",
        "mobile": true,
        "allowInt": true,
        "fieldType": "Percentage",
        "header": " Mtc- Name Prob",
        "displayInSettings": true,
        "filter": true,
        "sortable": true,
      },
      {
        "show": true,
        "textAlign": "left",
        "width": 80,
        "filterType": "num",
        "allowInt": true,
        "field": "match_OverallProbability",
        "mobile": true,
        "fieldType": "Percentage",
        "header": "Mtc- Overall Prob",
        "displayInSettings": true,
        "filter": true,
        "sortable": true,
      }, {
        "show": true,
        "textAlign": "left",
        "width": 120,
        "field": "match_StatusNotes",
        "mobile": true,
        "header": "Mtc- Status Notes",
        "displayInSettings": true,
        "filter": true,
        "sortable": true,
      },
      {
        "show": true,
        "textAlign": "left",
        "width": 115,
        "field": "matching_Estate_Address_City",
        "mobile": true,
        // "fieldType": "Link",
        "header": "Mtc Est Add City",
        "displayInSettings": true,
        "filter": true,
        "sortable": true,
      }, {
        "show": true,
        "textAlign": "left",
        "width": 115,
        "field": "matching_Estate_Address_Line1",
        "mobile": true,
        "header": "Mtc Est Add Line1",
        "displayInSettings": true,
        "filter": true,
        "sortable": true,
      },
      {
        "show": true,
        "textAlign": "left",
        "width": 115,
        "field": "matching_Estate_Address_Line2",
        "mobile": true,
        // "fieldType": "Link",
        "header": "Mtc Est Add Line2",
        "displayInSettings": true,
        "filter": true,
        "sortable": true,
      }, {
        "show": true,
        "textAlign": "left",
        "width": 115,
        "field": "matching_Estate_Address_State",
        "mobile": true,
        "header": "Mtc Est Add State",
        "displayInSettings": true,
        "filter": true,
        "sortable": true,
      },
      {
        "show": true,
        "textAlign": "left",
        "width": 105,
        "field": "matching_Estate_Address_Zip",
        "mobile": true,
        // "fieldType": "Link",
        "header": "Mtc Est Add Zip",
        "displayInSettings": true,
        "filter": true,
        "sortable": true,
      }, {
        "show": true,
        "textAlign": "left",
        "width": 105,
        "field": "matching_Estate_Name_Issues",
        "mobile": true,
        "filterType": "num",
        "allowInt": true,
        "header": "Mtc Est Name Issues",
        "displayInSettings": true,
        "filter": true,
        "sortable": true,
      },
    ]
    return data;
  };
  getFormFields = () => {

    return
  }



  submit = async (item) => {

  }

  render() {
    return (
      <span>
        <DataTables
          onRef={(ref) => (this.datatableref = ref)}
          getTableFields={this.getTableFields}
          formFields={this.getFormFields}
          exportRequried={false}
          printRequried={false}
          addRequried={false}
          editRequired={true}
          scrollHeight={"580px"}
          dontShowTitle={false}
          sortField={"property_PropertyValueMax"}
          deleteRequired={false}
          viewRequired={true}
          isClearFilters={true}
          settingsRequired={false}
          filterRequired={true}
          gridRequried={false}
          sample={false}
          propertyFilterSortOptions={this.state.propertyFilterSortOptions}
          globalSearchFieldName='user'
          globalSearch={'Search'}
          type={this.state.type}
          displayright={true}
          icon='faCity'
          fontAwesome={true}
          routeTo='properties'
          displayViewOfForm='modal'
          apiResponseKey='properties'
          className={true}
          apiUrl={apiCalls.properties}
          entityType='employee'
        />

      </span>
    );
  }
}