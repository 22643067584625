import React from 'react';
import { Container } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import Purpose from './components/Purpose';
let permissions = localStorage.getItem('rolePermissions')
permissions = permissions ? JSON.parse(permissions)["Purposes"] : "NoView"
const employee = ({ t }) => (
  <Container className={permissions==="Edit"||permissions==="View"?"Custom_container":""}>
    <Purpose />
  </Container>
);

employee.propTypes = {
  t: PropTypes.func.isRequired,
};

export default withTranslation('common')(employee);
