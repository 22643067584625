import React from 'react';
import { Container } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import DsSchedular from './components/DsSchedular';

const dsSchedular = ({ t }) => (
  <Container >
    <DsSchedular />
  </Container>
);

dsSchedular.propTypes = {
  t: PropTypes.func.isRequired,
};

export default withTranslation('common')(dsSchedular);
