import React from 'react';
import { Button, Modal, ModalBody } from 'reactstrap';
import { withTranslation } from 'react-i18next';


// config file
class confirmationModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        };
        if (this.props && this.props.checkForAutoturnoff) {
            this.props.checkForAutoturnoff()
        }
    }

    deleteSelectedRow = () => {
        // this.props.deleteSelectedRow();
    }

    render() {
        const { t } = this.props
        return (
            <div>
                <Modal isOpen={this.props.openConfirmationModal} fade={false} className='deleteModalHeight'>
                    <ModalBody className='deleteModalBody'>
                        <div style={{ marginBottom: 10 }}>{this.props.shownText}</div>
                        <Button color="primary" outline onClick={() => this.props.closeConfirmationModal("close")} className='deleteModalBtn marginRight'>{t('Yes')}</Button>
                        <Button color="primary" outline onClick={this.props.closeConfirmationModal}
                            className='deleteModalBtn'>{t('No')}</Button>
                    </ModalBody>
                </Modal>
            </div>
        );
    }
}
export default withTranslation('common')(confirmationModal);
