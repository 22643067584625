const validate = (values) => {
  const errors = {};

  let fields = ['firstName', 'lastName', 'dateOfBirth', 'joinDate', 'phoneNumber', 'skypeId', 'bitbuketId', 'laptopSNO', 'pancardNumber', 'aadharcardNumber'];
  // let lengths = [{ field: "SOSId", value: 1000 }]
  // for (let fiel of lengths) {

  // }
  // let fields2 = {
  //   "ProposedLegalNameSource": 50,
  //   "PBConnectionType": 50,
  //   "ActiveInactiveNotes": 1000,
  //   "SOSId": 1000,
  //   "IntegrityNotes": 1000,
  //   "LegalNotes": 1000,
  //   "APAExhibitPlacement": 1000,
  //   "SellerComments": 4000,
  //   "judgmentPreJudgmentInterest": 50,
  //   "EntityStatus": 50,
  //   "EntityStatusSubType": 50,
  //   "DateRestrictions": 1000

  // }
  // for (let filed in fields2) {
  //   // let length
  //   // let val = values[filed]
  //   // if (val) {
  //   //   length = val.length
  //   // }
  //   if (values[filed] && values[filed].length && values[filed].length > fields2[filed]) {
  //     errors[filed] = `${filed} field shouldn’t be greater than ${fields2[filed]}`;
  //   }
  // }


  fields.forEach((field) => {
    if (!values[field]) {
      let fieldName = capitalize(field);
      errors[field] = `${fieldName} field shouldn’t be empty`;
    }
  });

  if (!values.email) {
    errors.email = 'Email field shouldn’t be empty';
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
    errors.email = 'Invalid email address';
  }
  if (values.NumberofLocations && (values.NumberofLocations === "e" || values.NumberofLocations === "E")) {
    errors.NumberofLocations = 'Number of Locations could not be text';
  }

  // if (!values.APAType) {
  //   errors.APAType = 'APAType shouldn’t be empty';
  // } else if (values.APAType < 8 || values.APAType > 14) {
  //   errors.APAType = 'Number should be between 8 and 14';
  // }
  // if (!values.BidProceduresOverbidAmount) {
  //   errors.BidProceduresOverbidAmount = 'BidProceduresOverbidAmount shouldn’t be empty';
  // } else 
  // if (values.RemnantAssetLanguage && values.RemnantAssetLanguage.length > 100) {
  //   errors.RemnantAssetLanguage = 'Remnant Assets Language, Inclusions field shouldn’t be more than 100';
  // }
  if (values.APAExclusions && values.APAExclusions.length > 4000) {
    errors.APAExclusions = 'APA Exclusions field shouldn’t be more than 4000';

  }
  if (values.SharingAgreement && values.SharingAgreement.length > 2000) {
    errors.SharingAgreement = 'Price Caveat / Sharing Agreement field shouldn’t be more than 2000';

  }
  if (values.BidProceduresOverbidAmount) {
    if (!/^[0-9]*$/i.test(values.BidProceduresOverbidAmount)) {
      errors.BidProceduresOverbidAmount = 'Not valid amount.Should enter only numbers';
    }
  }

  if (!values.companyEmail) {
    errors.companyEmail = 'Email field shouldn’t be empty';
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
    errors.companyEmail = 'Invalid email address';
  }
  var passwordRegexp = /^(?=.{8,})((?=.*\d)(?=.*[a-z])(?=.*[A-Z])|(?=.*\d)(?=.*[a-zA-Z])(?=.*[\W_])|(?=.*[a-z])(?=.*[A-Z])(?=.*[\W_])).*/;
  if (!values.password) {
    errors.password = 'Password field shouldn’t be empty';
  }
  else if (!passwordRegexp.test(values.password)) {
    errors.password = 'Your password must contain 3 of the following: one upper case, one lower case, one numeric, or one special character.';
  }

  if (!values.select) {
    errors.select = 'Please select the option';
  }

  return errors;
};

const capitalize = (str) => {
  return str.charAt(0).toUpperCase() + str.slice(1);
};

export default validate;