import React from 'react';
import store from '../../../App/store';
import { Field, reduxForm } from 'redux-form';
import fetchMethodReqsuest from '../../../../config/service';
import Select from '../../../../shared/components/form/Select';
import MultiSelect from '../../../Form/components/MultiSelect';
import DefaultInput from '../../../../shared/components/form/DefaultInput';
import DefaultTextArea from '../../../../shared/components/form/DefaultTextArea';

// import FileUpload from '../../../Form/components/FileUpload'
import FileUpload from '../../../../shared/components/form/DropZoneMultiple'
// import FileUpload from '../../../../shared/components/form/FileInput'

import { Card } from 'reactstrap';
import RadioButton from '../../../../shared/components/form/RadioButton';
import { connect } from 'react-redux';
import validate from '../../../Validations/validate';
import PropTypes from 'prop-types';



class DocumentForm extends React.Component {
  static propTypes = {
    t: PropTypes.func,
    handleSubmit: PropTypes.func.isRequired,
    reset: PropTypes.func.isRequired,
    pristine: PropTypes.bool.isRequired,
    submitting: PropTypes.bool.isRequired,
  };
  constructor(props) {
    super(props);
    this.state = {
      type: 'DocumentForm',
      formFields: this.getFormFields,
      documentRelevanceDataArray: this.documentRelevanceDataArray,
    };
  }

  static getDerivedStateFromProps(props, state) {
    let storeData = store.getState()
    let languageData = storeData && storeData.settingsData && storeData.settingsData.settings && storeData.settingsData.settings.languageTranslation ? storeData.settingsData.settings.languageTranslation : ""
    return { languageData: languageData };
  }



  componentDidMount = () => {
    //Screen permisions value can be edit,view, no view
    // let screenPermissions = RolePermissions.screenPermissions('Employees');
  }

  documentRelevanceDataArray = [{ label: 'a', value: 'a' }, { label: 'b', value: 'b' }, { label: 'c', value: 'c' }]

  // getFormFields = [{
  //   //'show': false,
  //   "value": "",
  //   "type": RadioButton,
  //   height: 50,
  //   "name": "dealType",
  //   "label": "Deal Type",
  //   "options": [{ label: 'CR', value: 'CR' }, { label: 'BK', value: 'BK' }],
  //   "id": "name",
  //   "placeholder": "Deal Type",
  //   class: 'col-md-3 col-sm-6 row',
  //   "required": true
  // },
  // {
  //   //'show': false,
  //   // 'isMultiSelect': true,
  //   "value": "",
  //   "type": DefaultInput,
  //   height: 50,
  //   "name": "deal",
  //   "label": "Deal",
  //   "id": "name",
  //   "placeholder": "Deal",
  //   "required": true,
  //   class: 'col-md-3 col-sm-6 row',
  // },
  // {
  //   //'show': false,
  //   // 'isMultiSelect': true,
  //   "value": "",
  //   "type": DefaultInput,
  //   height: 50,
  //   "name": "documentCategory",
  //   "label": "Document Category",
  //   "id": "name",
  //   "placeholder": "Document Category",
  //   "required": true,
  //   class: 'col-md-3 col-sm-6 row',
  // },
  // {
  //   //'show': false,
  //   // 'isMultiSelect': true,
  //   "value": "",
  //   "type": DefaultInput,
  //   height: 50,
  //   "name": "source",
  //   "label": "Source",
  //   "id": "name",
  //   "placeholder": "Source",
  //   "required": true,
  //   class: 'col-md-3 col-sm-6 row',
  // },
  // {
  //   //'show': false,
  //   // 'isMultiSelect': true,
  //   "value": "",
  //   "type": DefaultInput,
  //   height: 50,
  //   "name": "name",
  //   "label": "Name",
  //   "id": "name",
  //   "placeholder": "Name",
  //   "required": true,
  //   class: 'col-md-3 col-sm-6 row',
  // },
  // {
  //   //'show': false,
  //   // 'isMultiSelect': true,
  //   "value": "",
  //   "type": MultiSelect,
  //   options: this.documentRelevanceDataArray,
  //   height: 50,
  //   "name": "documentRelevance",
  //   "label": "Document Relevance",
  //   "id": "name",
  //   "placeholder": "Document Relevance",
  //   "required": true,
  //   class: 'col-md-3 col-sm-6 row',
  // },
  // {
  //   //'show': false,
  //   // 'isMultiSelect': true,
  //   "value": "",
  //   "type": MultiSelect,
  //   options: this.documentRelevanceDataArray,
  //   height: 50,
  //   "name": "sharePointTag",
  //   "label": "SharePoint Tag",
  //   "id": "name",
  //   "placeholder": "SharePoint Tag",
  //   "required": true,
  //   class: 'col-md-3 col-sm-6 row',
  // },
  // {
  //   //'show': false,
  //   // 'isMultiSelect': true,
  //   "value": "",
  //   "type": DefaultTextArea,
  //   height: 120,
  //   "name": "documentNote",
  //   "label": "Document Note",
  //   "id": "name",
  //   "placeholder": "Document Note",
  //   "required": true,
  //   class: 'col-md-6 col-sm-6',
  // },

  // ];

  // myFun = (obj) => {

  //   if (obj.type == RadioButton) {
  //     alert(obj.name)
  //     return <div className="row" style={{ height: `${obj.height}px` }}>
  //       <label className='col-md-3 col-sm-6 text-right'>{obj.label}</label>
  //       <div className={obj.class}>
  //         {obj.options.map((obj1) => {
  //           return <div className="form-check form-check-inline custom-align mr-4">
  //             <Field
  //               component={obj.type}
  //               name={obj.name}
  //               className=""
  //               label={obj1.label}
  //               radioValue={obj1.value} />

  //           </div>
  //         })}

  //       </div>
  //     </div>
  //   }

  //   else if (obj.type == MultiSelect) {
  //     alert(obj.name)
  //     return <div className="row" style={{ height: `${obj.height}px` }}>
  //       <label className='col-md-3 col-sm-6 text-right'>{obj.label}</label>
  //       <div className={obj.class}>
  //         <Field
  //           name={obj.name}
  //           component={obj.type}
  //           options={obj.options}
  //           placeholder={obj.placeholder}
  //         />
  //       </div>
  //     </div>
  //   }

  //   return <div className="row" style={{ height: `${obj.height}px` }}>
  //     <label className='col-md-3 col-sm-6 text-right'>{obj.label}</label>
  //     <div className="col-md-3 col-sm-6">
  //       <Field
  //         name={obj.name}
  //         component={obj.type}
  //         // options={}
  //         className="form-control"
  //         placeholder={obj.placeholder}
  //       />
  //     </div>
  //   </div>
  // }


  submit = async (item) => {
    alert('submit');
  }

  render() {
    const { handleSubmit } = this.props;
    const { options } = this.state;
    let isSubmitting = false;
    let required = false;

    return <div className='border'>
      {/* <h1 className='text-center'>Deal Documents Upload</h1> */}
      <form
        onSubmit={handleSubmit(this.submit)}
      >
        <Card className='settingsCard row m-0 p-4 w-100'>

          {/* {this.state.formFields.map((obj) => { this.myFun(obj) })} */}




          <div className="row" style={{ height: '50px' }}>
            <label className='col-md-3 col-sm-6 text-right'>Deal Type</label>
            <div className="col-md-2 col-sm-6 row">
              {/* <div className=" col-sm-12 d-flex pl-0"> */}
                <Field
                  id='BK'
                  name='dealType'
                  label="BK"
                  value="BK"
                  radioValue="BK"
                  component={RadioButton}
                  validate={required}
                  showError={true}
                />
                <Field
                  id='CR'
                  name='dealType'
                  label="CR"
                  value="CR"
                  radioValue="CR"
                  validate={required}
                  component={RadioButton}
                />
              {/* </div> */}
            </div>
          </div>

          <div className="row" style={{ height: '50px' }}>
            <label className='col-md-3 col-sm-6 text-right'>Deal</label>
            <div className="col-md-3 col-sm-6">
              <Field
                name={'deal'}
                component={DefaultInput}
                // options={}
                className="form-control"
                placeholder={'Deal'}
              />
            </div>
          </div>

          <div className="row" style={{ height: '50px' }}>
            <label className='col-md-3 col-sm-6 text-right' >Document Category</label>
            <div className="col-md-3 col-sm-6">
              <Field
                name={'documentCategory'}
                component={DefaultInput}
                // options={}
                className="form-control"
                placeholder={'Document Category'}
              />
            </div>
          </div>

          <div className="row" style={{ height: '50px' }}>
            <label className='col-md-3 col-sm-6 text-right'>Document Sub-Category</label>
            <div className="col-md-3 col-sm-6">
              <Field
                name={'documentSubCategory'}
                component={DefaultInput}
                // options={}
                className="form-control"
                placeholder={'Document Sub-Category'}
              />
            </div>
          </div>

          <div className="row" style={{ height: '50px' }}>
            <label className='col-md-3 col-sm-6 text-right'>Source</label>
            <div className="col-md-3 col-sm-6">
              <Field
                name={'source'}
                component={DefaultInput}
                // options={}
                className="form-control"
                placeholder={'Source'}
              />
            </div>
          </div>

          <div className="row" style={{ height: '50px' }}>
            <label className='col-md-3 col-sm-6 text-right'>Name</label>
            <div className="col-md-3 col-sm-6">
              <Field
                name={'name'}
                component={DefaultInput}
                // options={}
                className="form-control"
                placeholder={'Name'}
              />
            </div>
          </div>

          <div className="row" style={{ height: '50px' }}>
            <label className='col-md-3 col-sm-6 text-right'>Document Relevance</label>
            <div className="col-md-3 col-sm-6">
              <Field className=''
                type={'dropDown'}
                name="documentRelevance"
                id="documentRelevance"
                component={MultiSelect}
                filterElement={this.state.documentRelevanceDataArray}
                filter={true}
                placeholder='Select documentRelevance'

              />
            </div>
          </div>

          <div className="row" style={{ height: '50px' }}>
            <label className='col-md-3 col-sm-6 text-right'>SharePoint Tag</label>
            <div className="col-md-3 col-sm-6">
              <Field
                name={'sharePointTag'}
                component={Select}
                options={this.state.documentRelevanceDataArray}
                placeholder={'SharePoint Tag'}
              />
            </div>
          </div>

          <div className="row" style={{ height: '120px' }}>
            <label className='col-md-3 col-sm-6 text-right'>Document Note</label>
            <div className="col-md-6 col-sm-6">
              <Field
                name={'documentNote'}
                component={DefaultTextArea}
                placeholder={'Document Note'}
              />
            </div>
          </div>

          <div className="row" style={{ height: '50px' }}>
            <label className='col-md-3 col-sm-6 text-right'>Choose File To Upload</label>
            <div className="col-md-3 col-sm-6">
              <Field
                name={'file'}
                component={FileUpload}
                // options={[{label:'A',value:'a'}]}
                placeholder={'Choose File To Upload'}
              />
            </div>
          </div>

          <div className='pl-5 text-center'>
            <button type='submit' className='btn btn-info '>Update</button>
          </div>
        </Card>
      </form>

    </div>
  }
}

// 
DocumentForm = reduxForm({
  form: "Common Form", // a unique identifier for this form
  validate,
  enableReinitialize: true,
})(DocumentForm);

// You have to connect() to any reducers that you wish to connect to yourself
DocumentForm = connect(
  state => ({
    initialValues: state.commonData.data // pull initial values from account reducer
  }),

  {
    // load: loadAccount,// bind account loading action creator
    // settingsLoad: settings,
    // settingsData:auth
  }
)(DocumentForm);

export default DocumentForm;