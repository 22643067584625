
import React from 'react';
import { Field, reduxForm } from 'redux-form';
import { Button, Card, CardBody } from 'reactstrap';
import { connect } from 'react-redux';
import { load as loadAccount } from '../../../redux/reducers/commonReducer';
// fecth method from service.js file
import fetch from '../../../config/service';
import configMessage from '../../../config/configMessages';
// show message 
import DefaultInput from '../../../shared/components/form/DefaultInput';
import validate from '../../Validations/validate';
import ExpiredSessionModal from '../../Cruds/CommonModals/ExpiredSessionModal';

import Loader from '../../App/Loader';
import { withTranslation } from 'react-i18next';
import apiCalls from '../../../config/apiCalls';
import { Redirect } from 'react-router';

const required = value => (value || typeof value === 'string' ? undefined : configMessage.fillField)
let timeout

//validations
// let newpassval = value => (value ? config.userPasswordRegex.test(value) ? undefined : 'Password must have at least 12 characters and contain at least 1 Uppercase and 1 special character.' : configMessage.fillField)

class NameScoring extends React.Component {
	constructor(props) {
		super(props);
		this.buttonActionType = null;
		this.state = {
			isLoginSuccess: false,
			isLoading: false,
		};
	}

	componentDidMount = async () => {
		this.setState({ isLoading: false });
	}
	cancelexpiredSessionModal = async () => {
		await this.setState({
			expiredSessionModal: false
		})
	}
	expiredSessionModal() {
		return (
			<ExpiredSessionModal
				openConfirmationModal={this.state.expiredSessionModal}
				cancelSessionWarningModal={this.cancelexpiredSessionModal}
				getData={this.getDataFromServer}

			/>
		)
	}

	componentDidUpdate() {
		if (this.state.errorResponse) {
			setTimeout(() => this.setState({ errorResponse: false, errorMessage: '' }), 30000);
		}
		if (!this.state.errorResponse && localStorage.getItem('serverError') === "true") {
			localStorage.removeItem('serverError')
		}
		// if (localStorage.getItem('loggedTime')) {
		// 	let loggedTime = localStorage.getItem('loggedTime')
		// 	if (timeout) {
		// 		clearTimeout(timeout)
		// 	}
		// 	timeout = setTimeout(() => this.setState({ expiredSessionModal: true, openedMultiTabs: true }), loggedTime);
		// }
		if (this.state.progress === 100) {
			setTimeout(() => this.setState({ progress: 0 }), 200);
		}
	}
	// clear input data
	clearInputFields = () => {
		this.props.reset();
	}

	submit = (values) => {
		let filterCriteria = {}
		if (values) {
			filterCriteria['criteria'] = [{ key: "CompanyName", value: values.companyName, type: 'eq' }];
			return fetch('GET', `${apiCalls.Companies}?filter=${JSON.stringify(filterCriteria)}`)
				.then(async (response) => {
					if (response && response.companies && response.companies.length > 0) {
						this.props.reset();
						this.setState({
							errorMessage: `Recovery potential research job submitted for ${values.companyName}.  You will get notified once the file is created.`,
							errorResponse: true
						})
						let loggedUser = this.props.loginData.email
						return fetch('GET', `opp?query=${values.companyName}&job=true&email=${loggedUser}`)
							.then(async (response) => {
								this.setState({
									isLoading: false,
								})
							}).catch((err) => {
								return err;
							});
					} else {
						this.setState({
							errorMessage: `Company Name is not Exists.`,
							errorResponse: true
						})
					}

				}).catch((err) => {
					return err;
				});
		}

	}

	onChange = async (e) => {

	}

	render() {
		const { handleSubmit } = this.props;
		return (
			<div>
				<form className="form" onSubmit={handleSubmit(this.submit)} style={{ height: 300 }}>
					<Card className='pb-0 '>
						<Loader loader={this.state.isLoading} />
						<CardBody className=' p-0 mt-3'
						>
							<Loader loader={this.state.isLoading} />
							<h3 className="topBarImageAlignment" style={{ margin: '10px 0px' }}>Recovery Research – Deal Opportunity Sizing</h3>
							<div className="col-sm-6 mt-4">
								<div className="d-flex">
									<label className="col-sm-3" style={{ marginTop: '6px' }}>Company Name</label>
									<div className=' form__form-group-field'>
										<Field className='inputLogin'
											type={'text'}
											name="companyName"
											component={DefaultInput}
											placeholder='Company Name'
											validate={required}
										/>
									</div>
								</div>
							</div>
							<div className='topBarImageAlignment'>
								<Button
									color='primary'
									className={"mb-0 "}
									outline
									type="submit">
									{/* <FontAwesomeIcon
									style={{ width: "15px" }}
									icon={faSave}
								/> */}
									Submit
								</Button>
							</div>

							{this.state.errorResponse ? <h5 className='topBarImageAlignment mt-4' style={{ color: "red" }}>{this.state.errorMessage}</h5> : null}
						</CardBody>
					</Card>
				</form >
				{this.state.expiredSessionModal ? this.expiredSessionModal() : null}
				{this.state.forceLogout ? <Redirect to={'/log_in'} /> : null}
				{this.state.sess ? <Redirect to={'/log_in'} /> : null}
			</div>
		);
	}
}


NameScoring = reduxForm({
	form: "Name Scoring Form", // a unique identifier for this form
	validate,
	enableReinitialize: true,
})(NameScoring);

// You have to connect() to any reducers that you wish to connect to yourself
NameScoring = connect(
	state => ({
		initialValues: state.commonData.data // pull initial values from account reducer
	}),
	{ load: loadAccount } // bind account loading action creator
)(NameScoring);

export default withTranslation('common')(NameScoring);
