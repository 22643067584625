import React from 'react';
import { Container } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import Department from './components/Department';
let permissions = localStorage.getItem('rolePermissions')
permissions = permissions ? JSON.parse(permissions)["Teams"] : "NoView"
const department = ({ t }) => (
  <Container className={permissions === "Edit" || permissions === "View" ? "Custom_container" : ""}>
    <Department />
  </Container>
);

department.propTypes = {
  t: PropTypes.func.isRequired,
};

export default withTranslation('common')(department);
