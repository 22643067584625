import React from 'react';
import { Button, Modal, ModalBody } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import { InputText } from 'primereact/inputtext';
import { RadioButton } from 'primereact/radiobutton';



// config file
class SaveBookMarkModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            bookMarkNameDetails: this.props.bookMarkNameDetails
        };
    }

    componentDidMount = async () => {
        if (this.props.onRef) {
            this.props.onRef(this);
        }
        if (this.props.bookMarkNameDetails && Object.keys(this.props.bookMarkNameDetails).length > 0) {
            await this.setState({ name: this.props.bookMarkNameDetails.filterName, updateClaim: true })
        }
    };

    componentDidUpdate(prevProps, prevState) {
        if (this.state.isshowToasterMsg) {
            if (toasterMessage) {
                clearTimeout(toasterMessage);
            }
            var toasterMessage = setTimeout(() => this.setState({ isshowToasterMsg: false, showToasterMsg: '' }), 5000);
        }
    }

    closeBookMarkModal = () => {
        this.props.closeBookMarkModal();
    }
    onNameChange = async (e) => {
        await this.setState({ name: e.target.value })
    }

    showToasterMessage = (res) => {
        const message = res['respMessage'] || res['errorMessage'];
        if (res && message) {
            this.setState({
                isshowToasterMsg: true,
                showToasterMsg: message,
                backgroundColor: res['errorMessage'] ? "#fcd3D2" : "#e6eed1",
                color: res['errorMessage'] ? "#811211" : "#5e6919",
            });
        }
    }

    saveBookMark = async () => {
        this.setState({ disabledSaveButton: true });
        if (this.state.bookMarkNameDetails && Object.keys(this.state.bookMarkNameDetails).length > 0) {
            await this.props.updateBookMark(this.state.name, this.props.bookMarkNameDetails)
        } else {
            await this.props.saveBookMark(this.state.name)
        }
        this.setState({ disabledSaveButton: false });
    }
    setType = async (key) => {
        key === "updateClaim" ? this.setState({ updateClaim: true, newClaim: false, bookMarkNameDetails: this.props.bookMarkNameDetails, name: this.props.bookMarkNameDetails.filterName }) : this.setState({ updateClaim: false, newClaim: true, bookMarkNameDetails: null, name: "" })
    }
    render() {
        const { t } = this.props;
        return (
            <div>
                <Modal isOpen={this.props.openDeleteModal} fade={false}
                    className='modal-dialog-centered modal-dialog--primary m-auto logout_modal_width w-auto'
                >
                    <ModalBody className='deleteModalBody' style={{ padding: 10 }}>
                        <div style={{ marginBottom: 10, fontWeight: "bold", textAlign: "left" }}>{this.state.bookMarkNameDetails && Object.keys(this.state.bookMarkNameDetails).length > 0 ? "Edit Bookmark" : "Add Bookmark"}</div>

                        {Object.keys(this.props.bookMarkNameDetails).length > 0 ? <div className="row ml-1 justify-content-lg-center">
                            {/* <div className=" col-sm-12 d-flex pl-0"> */}

                            <RadioButton inputId="updateClaim" name="updateClaim" value={this.state.updateClaim} onChange={(e) => this.setType("updateClaim")} checked={this.state.updateClaim} />
                            <label className="ml-2">Update Existing Bookmark</label>
                            <RadioButton className='ml-5' inputId="newClaim" name="newClaim" value={this.state.newClaim} onChange={(e) => this.setType("newClaim")} checked={this.state.newClaim} />
                            <label className="ml-2">Create New Bookmark</label>

                            {/* </div> */}
                        </div> : null}

                        <div className='mt-1 mb-2 d-flex'>
                            <p className='mr-2 mt-2'> {"Name :"}</p>
                            <InputText
                                type="Name"
                                name='Name'
                                id='Name'

                                value={this.state.name}
                                style={{
                                    height: 37,
                                    // paddingLeft: "2.5rem",
                                    width: "90%"
                                }}
                                onChange={(e) => this.onNameChange(e)}
                                placeholder={'Enter Bookmark Name'}
                            />
                        </div>
                        <div></div>
                        {this.state.isshowToasterMsg &&
                            <div className='flex-auto mx-auto '><span className="px-4 my-2" style={{
                                backgroundColor: this.state.backgroundColor, color: this.state.color, display: "flex", justifyContent: "center",
                                width: "fit-content", marginLeft: "auto", marginRight: "auto",
                            }}>
                                {this.state.showToasterMsg}</span></div>
                        }
                        <Button color="success" outline
                            onClick={this.saveBookMark}
                            disabled={(!this.state.name || (this.state.name && this.state.disabledSaveButton)) ? true : false}
                            // style={this.state.name ? { backgroundColor: "#94db94" } : {}}
                            className='deleteModalBtn marginRight'>{this.state.bookMarkNameDetails && Object.keys(this.state.bookMarkNameDetails).length > 0 ? "Update" : "Save"}</Button>
                        <Button color="primary" outline onClick={this.closeBookMarkModal}
                            className='deleteModalBtn'>{"Cancel"}</Button>
                    </ModalBody>
                </Modal>
            </div >
        );
    }
}
export default withTranslation('common')(SaveBookMarkModal);
