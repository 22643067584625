import React from 'react';
import store from '../../../App/store';
import DataTables from '../../CommonDataTable/DataTable';
import Loader from '../../../App/Loader';


// config file
export default class Suffixes extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      type: 'Suffixes',
    };
  }

  static getDerivedStateFromProps(props, state) {
    let storeData = store.getState()
    let languageData = storeData && storeData.settingsData && storeData.settingsData.settings && storeData.settingsData.settings.languageTranslation ? storeData.settingsData.settings.languageTranslation : ""
    return { languageData: languageData };
  }



  componentDidMount = () => {
    //Screen permisions value can be edit,view, no view
    // let screenPermissions = RolePermissions.screenPermissions('Employees');
  }

  getTableFields = () => {
    let data = [

      {
        "show": true,
        textAlign: 'left',
        width: 80,
        field: 'suffixPresent',
        // fieldType: 'BoolText',
        "mobile": true,
        header: 'Label Name',
        filter: true,
        "displayInSettings": true,
        "displayInFilter": true,
        sortable: true,
      },
      {
        "show": true,
        textAlign: 'left',
        // width: 40,
        field: 'suffixAbsent',
        // "fieldType": 'number',
        "mobile": true,
        header: 'Sequence',
        filter: true,
        "displayInSettings": true,
        "displayInFilter": true,
        sortable: true,
      },
      // {
      //   "show": true,
      //   textAlign: 'left',
      //   width: 80,
      //   field: 'purposeName',
      //   fieldType: 'BoolText',
      //   "mobile": true,
      //   header: 'Purpose Name',
      //   filter: false,
      //   "displayInSettings": true,
      //   "displayInFilter": true,
      //   sortable: true,
      // },

      {
        "show": true,
        "textAlign": "left",
        // "width": 40,
        "fieldType": 'Actions',
        "field": "Actions",
        "header": "Actions",
        "filter": false,
        "displayInSettings": true,
        "sortable": false
      }
    ]
    return data;
  };


  getFormFields = () => {
    return ([

      {
        //'show': false,
        "value": "",
        "type": "text",
        "name": "suffixPresent",
        "label": "Suffix Present",
        "id": "name",
        "placeholder": "Suffix Present",
        "required": true
      },
      {
        //'show': false,
        "value": "",
        "type": "text",
        "name": "suffixAbsent",
        "label": "Suffix Absent",
        "id": "name",
        "placeholder": "Suffix Absent",
        "required": true
      },
    ]);
  }


  submit = async (item) => {


  }

  render() {
    return (
      <span>
        <Loader loader={this.state.isLoading} />
        <DataTables
          onRef={(ref) => (this.datatableref = ref)}
          getTableFields={this.getTableFields}
          formFields={this.getFormFields}
          exportRequried={false}
          printRequried={false}
          sortField={"created"}
          addRequried={true}
          editRequired={true}
          isClearFilters={true}
          deleteRequired={false}
          viewRequired={true}
          settingsRequired={false}
          filterRequired={true}
          gridRequried={false}
          sample={false}
          expandTableFields={this.expandTableFields}
          globalSearchFieldName='user'
          globalSearch={'Search'}
          type='Legal Entity Suffix'
          displayright={true}
          icon='user'
          routeTo='configurations'
          displayViewOfForm='modal'
          apiResponseKey='legalEntitySuffixes'
          apiUrl={"NsScoredProperties/LegalEntitySuffixes"}
          entityType='employee'
        />
      </span>
    );
  }
}