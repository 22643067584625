import React from 'react';
import { Container } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import PropCount from './components/SellerSummaryReportDetailedPropertyView';
const propCount = (props) => (
  <Container >
    <PropCount
      EstateID={props.EstateID}
      criteria={props.criteria}
    />
  </Container>
);

propCount.propTypes = {
  t: PropTypes.func.isRequired,
};

export default withTranslation('common')(propCount);
