import React from 'react';
import store from '../../../App/store';
import apiCalls from '../../../../config/apiCalls'
import DataTables from '../../CommonDataTable/DataTable';
import fetch from '../../../../config/service';
import Loader from '../../../App/Loader';


// config file
export default class BusinessNameIssueCode extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      type: 'Business Name Issue Code',
    };
  }

  static getDerivedStateFromProps(props, state) {
    let storeData = store.getState()
    let languageData = storeData && storeData.settingsData && storeData.settingsData.settings && storeData.settingsData.settings.languageTranslation ? storeData.settingsData.settings.languageTranslation : ""
    return { languageData: languageData };
  }



  componentDidMount = () => {
    this.getStatusOptions()
    //Screen permisions value can be edit,view, no view
    // let screenPermissions = RolePermissions.screenPermissions('Employees');
  }

  //user Task label options
  getStatusOptions = () => {
    fetch('GET', apiCalls.PropertyStatuses)
      .then(async (response) => {
        if (response) {
          let statuses = response.propertyStatuses;
          let modifiedStatuses = [];
          for (let status of statuses) {
            modifiedStatuses.push({
              label: status.status,
              value: {
                status: status.status,
                id: status._id,
              },
            })
          }
          await this.setState({
            Statuses: modifiedStatuses
          })
          // this.setUnitName()

        }
      }).catch((err) => {
        return err;
      });
  }
  getTableFields = () => {
    let data = [
      // {
      //   "show": true,
      //   "textAlign": "center",
      //   "width": 6,
      //   "fieldType": "expander",
      //   "header": "",
      //   "filter": false,
      //   "sortable": false,
      //   "placeholder": "Search"
      // },
      {
        "show": true,
        textAlign: 'left',
        width: 60,
        field: 'BusinessNameIssueCode',
        // fieldType: 'BoolText',
        "mobile": true,
        header: 'New Name Issue Code',
        filter: true,
        "displayInSettings": true,
        "displayInFilter": true,
        sortable: true,
      },
      // {
      //   "show": true,
      //   textAlign: 'left',
      //   width: 40,
      //   field: 'sequence',
      //   "fieldType": 'number',
      //   "mobile": true,
      //   header: 'Sequence',
      //   filter: false,
      //   "displayInSettings": true,
      //   "displayInFilter": true,
      //   sortable: true,
      // },
      {
        "show": true,
        textAlign: 'left',
        width: 80,
        field: 'BusinessNameIssueLabel',
        fieldType: 'BoolText',
        "mobile": true,
        header: 'New Name issue Code Definition',
        filter: true,
        "displayInSettings": true,
        "displayInFilter": true,
        sortable: true,
      },
      {
        "show": true,
        textAlign: 'left',
        width: 80,
        field: 'BusinessNameIssueCodeDescription',
        // fieldType: 'BoolText',
        "mobile": true,
        header: 'New Name Issue Code Description',
        filter: true,
        "displayInSettings": true,
        "displayInFilter": true,
        sortable: true,
      }

      , {
        "show": true,
        textAlign: 'left',
        width: 80,
        field: 'BusinessNameIssueCodeUpdatePrivileges',
        // fieldType: 'BoolText',
        "mobile": true,
        header: 'New Name Issue Code Update Privileges',
        // filter: true,
        "displayInSettings": true,
        "displayInFilter": true,
        // sortable: true,
      }, {
        "show": true,
        textAlign: 'left',
        width: 80,
        field: 'PropertyStatusToSet',
        // fieldType: 'BoolText',
        "mobile": true,
        header: 'Property Status To Set',
        filter: true,
        "displayInSettings": true,
        "displayInFilter": true,
        sortable: true,
      }, {
        "show": true,
        textAlign: 'left',
        width: 80,
        field: 'RecoveryActionRecommendation',
        // fieldType: 'BoolText',
        "mobile": true,
        header: 'Recovery Action Recommendation',
        filter: true,
        "displayInSettings": true,
        "displayInFilter": true,
        sortable: true,
      },

      {
        "show": true,
        textAlign: 'left',
        width: 80,
        field: 'LegacyIssueID',
        // fieldType: 'BoolText',
        "mobile": true,
        header: 'Legacy Name Issue Code',
        filter: true,
        "displayInSettings": true,
        "displayInFilter": true,
        sortable: true,
      }, {
        "show": true,
        textAlign: 'left',
        width: 80,
        field: 'LegacyIssueDescription',
        // fieldType: 'BoolText',
        "mobile": true,
        header: 'Legacy Name Issue Code Definition',
        filter: true,
        "displayInSettings": true,
        "displayInFilter": true,
        sortable: true,
      },
      {
        "show": true,
        textAlign: 'left',
        width: 80,
        field: 'LegacyNameIssueCodeDescription',
        // fieldType: 'BoolText',
        "mobile": true,
        header: 'Legacy Name Issue Code Description',
        filter: true,
        "displayInSettings": true,
        "displayInFilter": true,
        sortable: true,
      }
      , {
        "show": true,
        textAlign: 'left',
        width: 80,
        field: 'RecoveryAction',
        // fieldType: 'BoolText',
        "mobile": true,
        header: 'Recovery Action',
        filter: true,
        "displayInSettings": true,
        "displayInFilter": true,
        sortable: true,
      },
      {
        "show": true,
        textAlign: 'left',
        width: 80,
        field: 'MatchStatusSuggestion',
        // fieldType: 'BoolText',
        "mobile": true,
        header: 'Match Status Suggestion',
        filter: true,
        "displayInSettings": true,
        "displayInFilter": true,
        sortable: true,
      }, {
        "show": true,
        textAlign: 'left',
        width: 80,
        field: 'IsSystemSearchable',
        fieldType: "ActiveIndicator",
        "mobile": true,
        header: 'Is System Searchable',
        filter: true,
        "displayInSettings": true,
        "displayInFilter": true,
        sortable: true,
      }, {
        "show": true,
        textAlign: 'left',
        width: 80,
        field: 'IsManuallySearchable',
        fieldType: "ActiveIndicator",
        "mobile": true,
        header: 'Is Manually Searchable',
        filter: true,
        "displayInSettings": true,
        "displayInFilter": true,
        sortable: true,
      },

      {
        "show": true,
        "textAlign": "left",
        "width": 80,
        "fieldType": 'Actions',
        "field": "Actions",
        "header": "Actions",
        "filter": false,
        "displayInSettings": true,
        "sortable": false
      }
    ]
    return data;
  };


  getFormFields = () => {
    return ([

      {
        //'show': false,
        "value": "",
        "type": "text",
        "name": "BusinessNameIssueCode",
        "label": "New Name Issue Code",
        "id": "name",
        "placeholder": "New Name Issue Code",
        // "required": true
      },
      {
        //'show': false,
        "value": "",
        "type": "text",
        "name": "BusinessNameIssueLabel",
        "label": "New Name issue Code Definition",
        "id": "name",
        "placeholder": "New Name issue Code Definition",
        // "required": true
      },
      {
        //'show': false,
        "value": "",
        "type": "textarea",
        "name": "BusinessNameIssueCodeDescription",
        "label": "New Name Issue Code Description",
        "id": "name",
        "placeholder": "New Name Issue Code Description",
        // "required": true
      },
      // {
      //   //'show': false,
      //   "value": "",
      //   "type": "textarea",
      //   "name": "LegacyIssueDescription",
      //   "label": "Legacy Issue Description",
      //   "id": "name",
      //   "placeholder": "Legacy Description",
      //   "required": true
      // },
      {
        //'show': false,
        "value": "",
        "type": "text",
        "name": "BusinessNameIssueCodeUpdatePrivileges",
        "label": "New Name Issue Code Update Privileges",
        "id": "name",
        "placeholder": "New Name Issue Code Update Privileges",
        // "required": true
      }, {
        //'show': false,
        "value": "",
        "type": "text",
        "name": "RecoveryActionRecommendation",
        "label": "Recovery Action Recommendation",
        "id": "name",
        "placeholder": "Recovery Action Recommendation",
        // "required": true
      }, {
        //'show': false,
        "value": "",
        "type": "text",
        "name": "PropertyStatusToSet",
        "label": "Property Status To Set",
        "id": "name",
        "placeholder": "Property Status To Set",
        // "required": true
      },
      {
        //'show': false,
        "value": "",
        "type": "textarea",
        "name": "LegacyIssueDescription",
        "label": "Legacy Name Issue Code Definition",
        "id": "name",
        "placeholder": "Legacy Name Issue Code Definition",
        // "required": true
      },
      {
        //'show': false,
        "value": "",
        "type": "textarea",
        "name": "LegacyNameIssueCodeDescription",
        "label": "Legacy Name Issue Code Description",
        "id": "name",
        "placeholder": "Legacy Name Issue Code Description",
        // "required": true
      },
      {
        //'show': false,
        // 'isMultiSelect': Number,
        "value": "",
        "type": "text",
        "name": "LegacyIssueID",
        "label": "Legacy Name Issue Code",
        // "options": this.state.company,
        // "id": "name",
        "placeholder": "Legacy Name Issue Code",
        // "required": true
      },
      {
        //'show': false,
        "value": "",
        "type": "text",
        "name": "RecoveryAction",
        "label": "Recovery Action",
        "id": "name",
        "placeholder": "Recovery Action",
        // "required": true
      },
      {
        //'show': false,
        // 'isMultiSelect': Number,
        "value": "",
        "type": "text",
        "name": "MatchStatusSuggestion",
        "label": "Match Status Suggestion",
        // "options": this.state.company,
        // "id": "name",
        "placeholder": "Match Status Suggestion",
        // "required": true
      },
      {
        //'show': false,
        "value": "",
        "type": "switch",
        "name": "IsSystemSearchable",
        "label": "Is System Searchable",
        "id": "IsSystemSearchable",
        "placeholder": "IsSystemSearchable",
        // "required": true
      },
      {
        //'show': false,
        "value": "",
        "type": "switch",
        "name": "IsManuallySearchable",
        "label": "Is Manually Searchable",
        "id": "IsSystemSearchable",
        "placeholder": "IsSystemSearchable",
        // "required": true
      },
      {
        //'show': false,
        "value": "",
        "type": "switch",
        "name": "DWRowIsCurrent",
        "label": "Active /Inactive Status",
        "id": "name",
        "placeholder": "Name",
        // "required": true
      },




    ]);
  }


  submit = async (item) => {


  }

  render() {
    return (
      <span>
        <Loader loader={this.state.isLoading} />
        <DataTables
          onRef={(ref) => (this.datatableref = ref)}
          getTableFields={this.getTableFields}
          formFields={this.getFormFields}
          exportRequried={false}
          printRequried={false}
          sortField={"BusinessNameIssueCode"}
          addRequried={true}
          editRequired={true}
          isClearFilters={true}
          deleteRequired={false}
          viewRequired={true}
          settingsRequired={false}
          filterRequired={true}
          gridRequried={false}
          sample={false}
          expandTableFields={this.expandTableFields}
          globalSearchFieldName='user'
          globalSearch={'Search'}
          type='Business Name Issue Code'
          displayright={true}
          icon='user'
          routeTo='statusReason'
          displayViewOfForm='modal'
          apiResponseKey='businessNameIssueCodeConfigurations'
          apiUrl={apiCalls.businessnameissuecodeconfiguration}
          entityType='employee'
        />
      </span>
    );
  }
}