import React from 'react';
import { Button, Modal, ModalBody, Row, ModalHeader } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import ModalFooter from 'reactstrap/lib/ModalFooter';
import { ColumnGroup } from 'primereact/columngroup';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import * as moment from 'moment-timezone';

const bodyStyle = {
  textOverflow: 'ellipsis',
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  width: 150,
  fontSize: 12,
  textAlign: "left",
  verticalAlign: "inherit"
}

const headerStyle = {
  width: 150,
  textAlign: "center",
}

// config file
class NameIssueCodeModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      PaymentsArray: {},
      recordData: {}
    };
  }

  componentDidMount = async (type) => {
    if (this.props.onRef) {
      this.props.onRef(this);
    }
    // let PaymentsArray = this.state.PaymentsArray
    // PaymentsArray = await this.updateSlNoToData(this.props.PaymentsArray["Payments"])
    // this.setState({
    //   PaymentsArray: PaymentsArray,
    //   recordData: this.props.PaymentsArray,
    // })
  }

  componentWillUnmount() {
    if (this.props.onRef) {
      this.props.onRef(null);
    }
  }

  openEditModal = (item, rowData) => {
    this.setState({
      EditedIndex: rowData.Sno - 1
    })
    this.props.openFormModal(item, 'edit', "Payments", rowData, rowData.Sno - 1)
  }

  dateTemplate = (rowData, column) => {
    var m = rowData["DWDateLastModified"] ? moment.utc(rowData["DWDateLastModified"]) : null;
    m.tz('America/Chicago');
    var s = m ? m.format("MM-DD-YYYY hh:mm A") : null;
    return <span className='d-flex'>
      {rowData["DWDateLastModified"] ? s : "N/A"}
      {/* {rowData["DWDateLastModified"] ? moment(rowData["DWDateLastModified"]).format("MM-DD-YYYY") : "N/A"} */}
    </span >
  }

  hoverTemplate = (rowData, column) => {
    return <span className='' data-toggle="tool-tip"
      title={rowData[column.field]} style={{ textOverflow: "ellipsis" }}>
      {rowData[column.field] ? rowData[column.field] : ""}
    </span >
  }
  notesTemplate = (rowData, column) => {
    // var text = rowData[column.field].replace(/(<([^>]+)>)/g, "");
    // return text
    let htmlString = rowData[column.field];
    return <div data-toggle="tool-tip"
      dangerouslySetInnerHTML={{ __html: htmlString }}
      title={htmlString}>

    </div>
  }

  cancelReset = () => {
    this.props.cancelReset()
  }

  render() {
    return (
      < div >

        <Modal isOpen={this.props.openHistoryModel} fade={false}
          className='modal-dialog modal-dialog--primary m-auto'
          style={{ maxWidth: 1050 }}

        >
          {/* <ModalHeader className={"modal__header kanbanScreen d-flex "} style={{ justifyContent: "center" }}>
            <p className="bold-text  modal__title ml-2" > {"Name Issue Note Details"}</p>
          </ModalHeader> */}
          <ModalBody className='deleteModalBody' style={{
            padding: 10,
            // width: 110,
            maxHeight: 800,
            height: "auto",
            overflow: "auto",
            textAlign: "left"

          }}>
            <>
              {this.props.selectedHistoryFields && this.props.selectedHistoryFields.length > 0 ?
                <DataTable value={this.props.selectedHistoryFields} header="Name Issue Note Details" scrollable={true} scrollHeight="650px" resizableColumns={true} columnResizeMode="expand"
                >
                  <Column field="BusinessIssueCodeFrom" header="Issue Code From" bodyStyle={bodyStyle} headerStyle={headerStyle} body={this.hoverTemplate} />
                  <Column field="BusinessIssueCodeTo" header="Issue Code To" bodyStyle={bodyStyle} body={this.hoverTemplate} headerStyle={headerStyle} />
                  <Column field="BusinessIssueCodeNotesFrom" header="Issue Note From" bodyStyle={bodyStyle} body={this.notesTemplate} headerStyle={headerStyle} />
                  <Column field="BusinessIssueCodeNotesTo" header="Issue Note To" bodyStyle={bodyStyle} body={this.notesTemplate} headerStyle={headerStyle} />
                  <Column field="DWLastModifiedBy" header="Updated By" body={this.hoverTemplate} bodyStyle={bodyStyle} headerStyle={headerStyle} />
                  <Column field="DWDateInserted" header="Updated On (Time in CT)" body={this.dateTemplate} bodyStyle={bodyStyle} headerStyle={headerStyle} />

                  {/* <Column body={this.actionTemplate} bodyStyle={{ textAlign: 'center', width: '8em' }} headerStyle={headerStyle} /> */}

                </DataTable> : "No Issue Codes & Notes History"}


            </>
          </ModalBody>
          <ModalFooter className="modalFooter " style={{ width: "100%", padding: "0px", backgroundColor: "white", borderTop: "none" }}>

            <Button color="primary" outline
              onClick={this.cancelReset}
              className='deleteModalBtn marginRight'>Back</Button>
          </ModalFooter>
        </Modal >
      </div >
    );
  }
}
export default withTranslation('common')(NameIssueCodeModal);
