
import React from 'react';
import classNames from 'classnames';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';
import { withTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStarOfLife } from '@fortawesome/free-solid-svg-icons';

import { Button, Card, CardBody, Modal, ModalHeader, ModalBody } from 'reactstrap';
import { load as loadAccount } from '../../../redux/reducers/commonReducer';
// fecth method from service.js file
import fetchMethodRequest from '../../../config/service';
import apiCalls from '../../../config/apiCalls'
// show message 
import Select from '../../../shared/components/form/Select';
import validate from '../../Form/validate';
import DataTables from '../../Cruds/CommonDataTable/DataTable';
import configMessages from '../../../config/configMessages';
import DefaultTextArea from '../../../shared/components/form/DefaultTextArea';
import ConfirmaionModelorSave from '../../Cruds/CommonModals/ConfirmationModal'
import { Dropdown } from 'primereact/dropdown';

//validations
// let newpassval = value => (value ? config.userPasswordRegex.test(value) ? undefined : 'Password must have at least 12 characters and contain at least 1 Uppercase and 1 special character.' : configMessage.fillField)

class MatchProperty extends React.Component {
    constructor(props) {
        super(props);
        this.buttonActionType = null;
        this.state = {
            isShown: false,
            isLoginSuccess: false,
            isLoading: false,
            isShowTable: false,
            progress: 0,
            isRedircewithselectedData: false,
            fileForOptions: [],
            selectedRecordIds: [],
            selectedRecordIdslength: 0,
            modifiedStates: [
                { label: "AL", value: "AL" },
                { label: "AK", value: "AK" },
                { label: "AZ", value: "AZ" },
                { label: "AR", value: "AR" },
                { label: "CA", value: "CA" },
                { label: "CO", value: "CO" },
                { label: "CT", value: "CT" },
                { label: "DE", value: "DE" },
                { label: "FL", value: "FL" },
                { label: "GA", value: "GA" },
                { label: "HI", value: "HI" },
                { label: "ID", value: "ID" },
                { label: "IL", value: "IL" },
                { label: "IN", value: "IN" },
                { label: "IA", value: "IA" },
                { label: "KS", value: "KS" },
                { label: "KY", value: "KY" },
                { label: "LA", value: "LA" },
                { label: "ME", value: "ME" },
                { label: "MD", value: "MD" },
                { label: "MA", value: "MA" },
                { label: "MI", value: "MI" },
                { label: "MN", value: "MN" },
                { label: "MS", value: "MS" },
                { label: "MO", value: "MO" },
                { label: "MT", value: "MT" },
                { label: "NE", value: "NE" },
                { label: "NV", value: "NV" },
                { label: "NH", value: "NH" },
                { label: "NJ", value: "NJ" },
                { label: "NM", value: "NM" },
                { label: "NY", value: "NY" },
                { label: "NC", value: "NC" },
                { label: "ND", value: "ND" },
                { label: "OH", value: "OH" },
                { label: "OK", value: "OK" },
                { label: "OR", value: "OR" },
                { label: "PA", value: "PA" },
                { label: "PR", value: "PR" },
                { label: "RI", value: "RI" },
                { label: "SC", value: "SC" },
                { label: "SD", value: "SD" },
                { label: "TN", value: "TN" },
                { label: "TX", value: "TX" },
                { label: "UT", value: "UT" },
                { label: "VT", value: "VT" },
                { label: "VA", value: "VA" },
                { label: "DC", value: "DC" },
                { label: "WA", value: "WA" },
                { label: "WV", value: "WV" },
                { label: "WI", value: "WI" },
                { label: "WY", value: "WY" },
            ],
            PropertyId: "",
            StatePropertyId: "",
            businessName: null,
            filter: {
                limit: localStorage.getItem("defaultPageOption") ? parseInt(localStorage.getItem("defaultPageOption")) : 10,
                page: 1,
                criteria: [],
                // SelectedRecord: "",
            },
            isDisablemissingStPropIds: true,
            isDisablemissingPropIds: true,
            isMatchedSuccess: false,
            duplicatePropertyIds: []

        };

    }

    componentDidMount = async () => {
        if (this.props.onRef) {
            this.props.onRef(this);
        }
        this.getFileForEditor(this.props.seller.EstateID)
        // this.datatableref.isShowTable(false)
    }

    componentWillUnmount() {
        if (this.props.onRef) {
            this.props.onRef(null);
        }
    }

    // clear input data
    clearInputFields = () => {
        this.props.reset();
    }

    //Call the PNAAM Call once hits go button
    submit = (values) => {
        this.datatableref.isShowTable(true)
        this.setState({
            isShowTable: true,
            businessName: null,
        })
        if (this.state.selectedRecordIdslength > 0) {
            this.datatableref.removeSelectedRows()
        }
        this.getResponseforSelectedPropertyData()
    }


    //get the matched Names for the 
    getFileForEditor = async (val) => {
        let fileForOptions = [];
        let url = `seller/sellerRelevanceNames?estateId=${val}&nameType=mappedName`
        return fetchMethodRequest('GET', url)
            .then(async (response) => {

                if (response && response["sellerRelevanceNames"].length > 0) {
                    let propertyReasons = response["sellerRelevanceNames"]
                    for (let reason of propertyReasons) {
                        fileForOptions.push({ label: reason.CombinedName, value: { labelName: reason.BusinessName, id: reason.BusinessNamesID } })
                    }
                    await this.setState({ fileForOptions: fileForOptions })
                } else if (response && response["sellerRelevanceNames"].length === 0) {
                    await this.setState({ fileForOptions: [] })
                } else if (response && response['errorMessage'] === configMessages.SESSION_EXPIRATION_WARNING_MESSAGE) {
                    await this.setState({
                        sessionWarning: true,

                    })
                }

            }).catch((err) => {
                return err;
            })
    }
    //fetch call for pnaam
    getResponseforSelectedPropertyData = async (filtercriteria) => {
        let filter = {}
        filter.sortfield = "PropertyId"
        filter.direction = filtercriteria ? filtercriteria.direction : "desc"
        filter.criteria = filtercriteria ? filtercriteria.criteria : []
        if (this.state.SourceState) {
            filter.criteria.push({ key: "SourceState", value: this.state.SourceState, type: "eq" })
        }
        let url = `${apiCalls.MatchPropertyGet}?filter=${JSON.stringify(filter)}`;
        var body = {}
        if (this.state.PropertyId) {
            var propIds = this.state.PropertyId
            propIds = propIds.replace(/\t/g, "");
            propIds = propIds.replace(/\n/g, ",");
            body.propertyId = propIds
        }
        if (this.state.StatePropertyId) {
            var statePropIds = this.state.StatePropertyId
            statePropIds = statePropIds.replace(/\t/g, "");
            statePropIds = statePropIds.replace(/\n/g, ",");
            body.statePropertyId = statePropIds

        }
        if (this.datatableref) {
            this.datatableref.getisLoading("true")
        }
        let valid_stateProp = []
        let valid_prop = []
        return fetchMethodRequest("POST", url, body).then(async (response) => {
            if (response && response.properties) {
                this.datatableref.getErrorMessage("", "", "error")

                if (this.state.PropertyId) {
                    valid_prop = response.properties.map(({ PropertyId }) => PropertyId)
                }
                if (this.state.StatePropertyId) {
                    valid_stateProp = response.properties.map(({ StatePropertyId }) => StatePropertyId)
                    valid_stateProp = valid_stateProp.filter((value, index) => valid_stateProp.indexOf(value) === index);
                }
                this.setState({
                    isShowTable: true,
                })
                this.datatableref.showMatchedResponses(response)
                if (response && response.missingPropertyIds && response.missingPropertyIds.length > 0) {
                    this.props.change('missingOpraPropertyId', response.missingPropertyIds.toString().replace(/,/g, "\n"))
                    this.props.change('missingStatePropertyId', '')
                    this.setState({
                        isDisablemissingPropIds: false
                    })
                } else {
                    this.props.change('missingOpraPropertyId', '')
                    this.setState({
                        isDisablemissingPropIds: true
                    })

                }
                if (response && response.missingStatePropertyIds && response.missingStatePropertyIds.length > 0) {
                    this.props.change('missingStatePropertyId', response.missingStatePropertyIds.toString().replace(/,/g, "\n"))
                    this.props.change('missingOpraPropertyId', '')
                    this.setState({
                        isDisablemissingStPropIds: false
                    })
                } else {
                    this.props.change('missingStatePropertyId', '')
                    this.setState({
                        isDisablemissingStPropIds: true
                    })
                }
                if (response && response.properties.length > 0) {
                    // this.datatableref.getErrorMessage("Please select all or few properties to match", '', "error")
                }
                this.setState({
                    errorColor: "info",
                    errorMessage: response && response.properties.length > 0 ? "Note: Please select all or few properties to match." : null,
                    isShownErrorResp: response && response.properties.length > 0 ? true : false,
                    valid_prop: valid_prop,
                    valid_stateProp: valid_stateProp
                })
                this.datatableref.removeSelectedRows()
                // localStorage.setItem("PropertyIds",JSON.stringify())
            } else if (response && response.errorMessage) {
                this.datatableref.getErrorMessage(response.errorMessage, "", "error")
            }
            if (this.datatableref) {
                this.datatableref.getisLoading("false")
            }
        });
    }

    cancelReset = (response) => {
        this.props.isCloseModal(response);
        if (response) {
            this.props.isShowErrorMessage(response)
        }
    }

    //Find the Duplicates from selected records
    findDuplicateObjects = (array, property) => {
        const seen = {};
        const duplicates = [];
        array.forEach((item) => {
            const value = item[property];
            if (seen[value]) {
                duplicates.push(item);
            } else {
                seen[value] = true;
            }
        });

        return duplicates;
    }

    //Call for row color change
    custumisedRowHighlights = (item) => {
        if (this.state.duplicatePropertyIds && this.state.duplicatePropertyIds.length > 0 && item) {
            return ({
                'p-isEditedRow': this.state.duplicatePropertyIds.includes(item.PropertyId) && this.state.selectedRecords.find((element) => element.Id === item.Id)
            })
        }
    }

    //check duplicates
    getduplicateIds = async () => {
        var duplicateObjects = this.findDuplicateObjects(this.state.selectedRecords, 'PropertyId');
        let duplicatePropertyIds = duplicateObjects.map(function (user) {
            return user.PropertyId;
        });
        if (duplicateObjects.length > 0) {
            await this.setState({
                duplicatePropertyIds: duplicatePropertyIds,
                errorMessage: "Please select unique Property IDs to match.",
                errorColor: "error",
                isShownErrorResp: true

            })
        } else {
            this.setState({
                isopenConfirmaionModelforSave: true,
                errorMessage: "",
                errorType: "info",
                isShownErrorResp: false
            })
        }

    }

    getMatchPropBtn() {
        return (

            <div className='col-sm-12 row ' style={{ justifyContent: 'center' }}>
                <div className='col-sm-4 filterCustum'>
                    <p className='d-flex'>Matched Name</p>
                    <div className='col-sm-12 m-0 p-0'>
                        <Dropdown className='inputLogin'
                            name="businessName"
                            filter={true}
                            value={this.state.businessName}
                            component={Select}
                            options={this.state.fileForOptions}
                            style={{ width: "100%" }}
                            placeholder=''
                            onChange={(e) => this.setState({ businessName: e.value })}
                            // validate={[required]}
                            disabled={this.state.selectedRecordIdslength > 0 ? false : true}

                        />
                    </div>
                </div>

                <Button size="sm" color="primary" outline
                    className="p-1 mt-3 mb-0"
                    style={{ cursor: "pointer", height: 34, width: 115 }}
                    onClick={() => this.getduplicateIds()}
                    disabled={this.state.businessName && this.state.businessName.labelName && this.state.selectedRecordIdslength > 0 ? false : true}
                >
                    Match
                </Button>

            </div>
        )
    }

    closeConfirmationModalforSave = async (type) => {
        if (this.datatableref) {
            this.datatableref.getisLoading("true")
        }
        this.url = `${window.location.protocol}//${window.location.host}/${this.props.type === "Deal Sizing Property Editor" ? 'dealproperties' : this.props.screen}?sellerName=${encodeURIComponent(this.props.seller.EstateName)}&sellerId=${this.props.seller.EstateID}&bucket=Green${this.state.SourceState ? `&state=${this.state.SourceState}` : ''}`
        if (type === "close") {
            await this.callDataProperty()
        }
        if (this.datatableref) {
            this.datatableref.getisLoading("false")
        }
        await this.setState({
            isopenConfirmaionModelforSave: false,
            isRedircewithselectedData: type === "close" ? true : false,
            propertiesUrl: this.url
        })
        // document.getElementsByClassName("redirectToProperties")[0].click();

    }

    getConfirmaionModelforSave() {
        return (
            <ConfirmaionModelorSave
                openConfirmationModal={this.state.isopenConfirmaionModelforSave}
                closeConfirmationModal={this.closeConfirmationModalforSave}
                shownText={`Confirm match of ${this.state.selectedRecordIdslength ? this.state.selectedRecordIdslength : 0} properties with ${this.props.seller ? this.props.seller.EstateName : ""} with Source State as ${this.state.SourceState} .`}
            // confirm={this.handleCheckValidations}
            />
        )
    }

    //Fetch for Create 
    callDataProperty = () => {
        let url = `${apiCalls.ManualMatch}`;
        if (this.props.type === "Deal Sizing Property Editor") {
            url = `${apiCalls.ManualMatch}?screen=NSD`;
        }
        let userBody = {}

        userBody.manualMatchModel = {
            "CompanyID": this.props.seller.EstateID,
            "BusinessName": this.state.businessName.labelName,
            "BusinessNameID": this.state.businessName.id,
            "EstateName": this.props.seller.EstateName,
        }
        userBody.selectedIDs = this.state.selectedRecordIds
        return fetchMethodRequest("POST", url, userBody).then(async (response) => {
            if (response && response.respCode === 200) {
                if (this.state.valid_prop && this.state.valid_prop.length > 0) {
                    localStorage.setItem("matchedProperties", this.state.valid_prop.toString())
                }
                if (this.state.valid_stateProp && this.state.valid_stateProp.length > 0) {
                    localStorage.setItem("matchedStateProperties", this.state.valid_stateProp.toString())
                }
                this.datatableref.getErrorMessage("", '', "")
                if (response.respCode) {
                    this.setState({
                        isMatchedSuccess: true,
                        errorMessage: response.respMessage,
                        isShownErrorResp: true,
                        errorColor: "green",
                        duplicatePropertyIds: []
                    })
                }
                // setTimeout(() => document.getElementsByClassName("redirectToProperties")[0].click(), 3000);
            } else {
                this.setState({
                    isMatchedSuccess: false,
                    errorMessage: response.respMessage,
                    isShownErrorResp: false,
                    errorColor: "info",
                    duplicatePropertyIds: []
                })
                this.datatableref.getErrorMessage(response.errorMessage, "", "error")
            }
        });


    }

    //Select record 
    sendSelctedRecordtoDb = async (selectedRecord) => {
        var selectedRecordIds = {}
        if (selectedRecord && selectedRecord.length > 0) {
            selectedRecordIds = new Set(selectedRecord.map((item) => item.Id))
        }
        // this.props.change("businessName", "")
        const selectedRecordIdslength = selectedRecord.length
        await this.setState({
            selectedRecords: selectedRecord,
            errorColor: "info",
            businessName: selectedRecord && selectedRecord.length > 0 ? this.state.businessName : null,
            errorMessage: selectedRecord && selectedRecord.length > 0 ? null : "Note: Please select all or few properties to match.",
            isShownErrorResp: selectedRecord && selectedRecord.length > 0 ? false : true,
            selectedRecordIds: selectedRecordIds,
            selectedRecordIdslength: selectedRecordIdslength
        })
        // this.getduplicateIds()

    }

    handleChange = (e) => {
        this.setState({
            PropertyID: e
        })
    }

    getSortedData = (type, field, selectedOption) => {
        var color, getField;
        if (selectedOption === "unsort") {
            field = "CreatedDate"
            selectedOption = "desc"
        }

        return type.sort((a, b) => {
            if (!a[field] && !b[field]) {
                return 0;
            }
            if (!a[field]) {
                return 1; // nulls should come after non-null values
            }
            if (!b[field]) {
                return -1; // non-null values should come before nulls
            }
            if (selectedOption === "asc") {
                if (typeof a[field] === "number") {
                    return a[field] - b[field];
                } else {
                    if (field === "SS_PropertyZipCode" || field === "AlternateID" || field === "PropertyZip") {
                        return parseInt(a[field]) - parseInt(b[field]);
                    } else {
                        return a[field].localeCompare(b[field]);
                    }
                }
            } else {
                if (typeof a[field] === "number") {
                    return b[field] - a[field];
                } else {
                    if (field === "SS_PropertyZipCode" || field === "AlternateID" || field === "PropertyZip") {
                        return parseInt(b[field]) - parseInt(a[field]);
                    } else {
                        return b[field].localeCompare(a[field]);
                    }
                }
            }

        });
    }
    getTableFields = () => {
        let data = [
            {
                textAlign: "center",
                width: 47,
                field: "",
                // fieldType: "multiple",
                header: "",
                selectionMode: "multiple",
                show: true,
                mobile: true,
                displayInSettings: false,
            },
            {
                "show": true,
                "textAlign": "left",
                "width": 110,
                "field": "PropertyId",
                "mobile": true,
                "header": "Property Id",
                "displayInSettings": true,
                "filter": false,
                "sortable": true,
            }, {
                "show": true,
                "textAlign": "left",
                "width": 80,
                "field": "StatePropertyId",
                // "filterType": "num",
                "mobile": true,
                // "fieldType": "ShowData",
                "header": "St. Prop.ID",
                "displayInSettings": true,
                "filter": false,
                "sortable": true,
            }, {
                "show": true,
                "textAlign": "left",
                "width": 80,
                "field": "SourceState",
                "mobile": true,
                "filterType": "num",
                // "fieldType": "ShowData",
                "header": "Src St",
                "displayInSettings": true,
                "filter": false,
                "sortable": true,
            }, {
                "show": true,
                "textAlign": "left",
                "width": 80,
                "field": "PropertyDataSource",
                "filterType": "num",
                "mobile": true,
                // "fieldType": "ShowData",
                "header": "Prop. Data Src",
                "displayInSettings": true,
                "filter": false,
                "sortable": true,
            }, {
                "show": true,
                "textAlign": "left",
                "width": 115,
                "field": "PropertyOwnerName",
                // "filterType": "num",
                "mobile": true,
                "header": "Prop. Owner Name",
                "displayInSettings": true,
                "filter": false,
                "sortable": true,
            }, {
                "show": true,
                "textAlign": "left",
                "width": 115,
                "field": "PropertyAddressLine1",
                "allowInt": true,
                // "filterType": "num",
                "mobile": true,
                "header": "Prop. Add.Line1",
                "displayInSettings": true,
                "filter": false,
                "sortable": true,
            },  
            // {
            //     "show": true,
            //     "textAlign": "left",
            //     "width": 115,
            //     // "allowInt": true,
            //     // "filterType": "num",
            //     "field": "SS_PropertyAddressLine1",
            //     "mobile": true,
            //     "header": "Prop. SS_Prop AddressLine1",
            //     "displayInSettings": true,
            //     "filter": false,
            //     "sortable": true,
            // }, 
            // {
            //     "show": true,
            //     "textAlign": "left",
            //     "width": 115,
            //     // "allowInt": true,
            //     // "filterType": "num",
            //     "field": "SS_PropertyAddressLine2",
            //     "mobile": true,
            //     "header": "Prop. SS_Prop AddressLine2",
            //     "displayInSettings": true,
            //     "filter": false,
            //     "sortable": true,
            // }, 
            {
                "show": true,
                "textAlign": "left",
                "width": 80,
                "field": "AddressId",
                "allowInt": true,
                "mobile": true,
                "filterType": "num",
                // "fieldType": "ShowData",
                "header": "Address Id",
                "displayInSettings": true,
                "filter": false,
                "sortable": true,
            }, {
                "show": true,
                "textAlign": "left",
                "width": 80,
                "allowInt": true,
                "filterType": "num",
                "field": "NameId",
                "mobile": true,
                "header": "Name Id",
                "displayInSettings": true,
                "filter": false,
                "sortable": true,
            }, {
                "show": true,
                "textAlign": "left",
                "width": 115,
                "field": "PropertyCity",
                "allowInt": true,
                // "filterType": "num",
                "mobile": true,
                "header": "Prop. City",
                "displayInSettings": true,
                "filter": false,
                "sortable": true,
            }, {
                "show": true,
                "textAlign": "left",
                "width": 115,
                "field": "PropertyStateAbbreviation",
                "allowInt": true,
                // "filterType": "num",
                "mobile": true,
                "header": "Prop. State",
                "displayInSettings": true,
                "filter": false,
                "sortable": true,
            }, {
                "show": true,
                "textAlign": "right",
                "width": 80,
                "field": "PropertyZip",
                "allowInt": true,
                "mobile": true,
                "header": "Prop. Zip",
                "filterType": "num",
                // "fieldType": "formatCurrencywithdec",
                "filter": false,
                "sortable": true,
                "displayInSettings": true,
            }, {
                "show": true,
                "textAlign": "right",
                "width": 80,
                "field": "PropertyValueMin",
                "allowInt": true,
                "mobile": true,
                "header": "Min Amt",
                "filterType": "num",
                "fieldType": "formatCurrencywithdec",
                "filter": false,
                "sortable": true,
                "displayInSettings": true,
            },
            {
                "show": true,
                "textAlign": "right",
                "width": 80,
                "field": "PropertyValueMax",
                "allowInt": true,
                "fieldType": "formatCurrencywithdec",
                "filterType": "num",
                "mobile": true,
                "header": "Max Amt",
                "displayInSettings": true,
                "filter": false,
                "sortable": true,
            }, {
                "show": true,
                "textAlign": "left",
                "width": 110,
                "field": "HolderName",
                "mobile": true,
                "header": "Holder Name",
                "displayInSettings": true,
                "filter": false,
                "sortable": true,
            }, {
                "show": true,
                "textAlign": "left",
                "width": 110,
                "field": "PropertyDescription",
                "mobile": true,
                "header": "Prop. Description",
                "displayInSettings": true,
                "filter": false,
                "sortable": true,
            }, {
                "show": true,
                "textAlign": "left",
                "width": 110,
                "field": "CreatedByUser",
                "mobile": true,
                "header": "Created By User",
                "displayInSettings": true,
                "filter": false,
                "sortable": true,
            },
            {
                "show": true,
                "textAlign": "left",
                "width": 110,
                "field": "PropertyStatus",
                "mobile": true,
                "header": "Prop.Status",
                "displayInSettings": true,
                "filter": false,
                "sortable": true,
            },
            {
                "show": true,
                "textAlign": "left",
                "width": 100,
                "field": "AlternateID",
                "mobile": true,
                "header": "Alternate ID",
                "filter": false,
                "sortable": true,
                "displayInSettings": true,
                // "options": [{ "label": "Active", "value": 'Active' }, { "label": "Pending", "value": 'Pending' }, { "label": "Inactive", "value": 'Inactive' }],
            },
            {
                "show": true,
                "textAlign": "left",
                "width": 80,
                // "filterType": "num",
                "field": "ProspectiveStatus",
                "mobile": true,

                "header": "Prospective Status",
                "filter": false,
                "sortable": true,
                "displayInSettings": true,
            },
            {
                "show": true,
                "textAlign": "left",
                "width": 115,
                "field": "CreatedBy",
                "mobile": true,
                "header": "Created By",
                "displayInSettings": true,
                "filter": false,
                "sortable": true,
            },
            {
                "show": true,
                "textAlign": "left",
                "width": 115,
                "allowInt": true,
                "filterType": "num",
                "field": "PropertyResidentialFlag",
                "mobile": true,
                "header": "Prop. Residential Flag",
                "displayInSettings": true,
                "filter": false,
                "sortable": true,
            },

        //    {
        //         "show": true,
        //         "textAlign": "left",
        //         "width": 115,
        //         // "allowInt": true,
        //         // "filterType": "num",
        //         "field": "SS_PropertyCity",
        //         "mobile": true,
        //         "header": "Prop. SS_Prop City",
        //         "displayInSettings": true,
        //         "filter": false,
        //         "sortable": true,
        //     }, {
        //         "show": true,
        //         "textAlign": "left",
        //         "width": 115,
        //         // "allowInt": true,
        //         // "filterType": "num",
        //         "field": "SS_PropertyState",
        //         "mobile": true,
        //         "header": "Prop. SS_Prop St",
        //         "displayInSettings": true,
        //         "filter": false,
        //         "sortable": true,
        //     }, {
        //         "show": true,
        //         "textAlign": "left",
        //         "width": 115,
        //         // "allowInt": true,
        //         // "filterType": "num",
        //         "field": "SS_PropertyZipCode",
        //         "mobile": true,
        //         "header": "Prop. SS_Prop ZipCode",
        //         "displayInSettings": true,
        //         "filter": false,
        //         "sortable": true,
        //     }, {
        //         "show": true,
        //         "textAlign": "left",
        //         "width": 115,
        //         // "allowInt": true,
        //         // "filterType": "num",
        //         "field": "SS_PropertyLatitude",
        //         "mobile": true,
        //         "header": "Prop. SS_Prop Latitude",
        //         "displayInSettings": true,
        //         "filter": false,
        //         "sortable": true,
        //     }, {
        //         "show": true,
        //         "textAlign": "left",
        //         "width": 115,
        //         // "allowInt": true,
        //         // "filterType": "num",
        //         "field": "SS_PropertyLongitude",
        //         "mobile": true,
        //         "header": "Prop. SS_Prop Longitude",
        //         "displayInSettings": true,
        //         "filter": false,
        //         "sortable": true,
        //     },
        ]
        return data;
    };

    showSave = (e, name) => {
        let filter = this.state.filter
        let index = filter["criteria"].findIndex(obj => obj.key === name)
        if (name === "SourceState") {
            this.setState({
                selectedState: e
            })
        }
        if (e && name === "PropertyId") {
            var result = e ? e.split(/\r?\n/) : null;
            var filtered = result.filter(function (el) {
                return el != "";
            });
            e = filtered
            var acc = [], missed_arr = []
            let miss_result = filtered.reduce(function (acc, e) {
                const regex = /^[0-9]+$/;
                if (regex.test(e)) {
                    acc.push(e);
                }
                return acc;
            }, []);
            let miss_ar = filtered.reduce(function (acc, e) {
                if (e && e.length > 0) {

                    const regex = /^[0-9]+$/;

                    if (!regex.test(e)) {
                        missed_arr.push(e);
                    }
                }

                return missed_arr;
            }, []);
        }
        if (index !== -1) filter["criteria"].splice(index, 1)
        if (e && e.length > 0) {
            filter.criteria.push({ key: name, value: e, type: "eq" },)
        }
        let shownindex = filter["criteria"].findIndex(obj => obj.key === "SourceState")
        if (shownindex !== -1) {
            this.setState({
                isShown: true,
                isShowTable: false
            })
        } else {
            this.setState({
                isShown: false
            })
        }
    }

    getrediredBtn = () => {
        return (
            this.state.isMatchedSuccess ?
                <div className={"d-flex"} style={{ justifyContent: "center" }}>
                    <Button
                        color='primary'
                        className={"mb-0 btn btn-sm"}
                        onClick={() => document.getElementsByClassName("redirectToProperties")[0].click()}
                    >
                        {'Ok'}
                    </Button></div>
                : null
        )
    }

    render() {
        const { handleSubmit } = this.props;
        const modalClass = classNames({
            'modal-dialog--colored': this.state.colored,
            'modal-dialog--header': this.state.header,
        });
        return (
            <Modal
                fade={false}
                isOpen={this.props.isOpenMatchmodel}
                style={{ maxWidth: "1800px" }}
                className={`p-0 modal-dialog modal-dialog--primary  ${modalClass}`}
            >
                <ModalHeader className={"modal__header kanbanScreen manual_match"} style={{ border: "none" }}>
                    <div className="d-flex" style={{ textAlign: "center" }}>
                        <button className="lnr lnr-cross modal__close-btn" type="button" style={{ color: "black", right: 40 }}
                            onClick={() => this.cancelReset()} />
                        <span />
                        <h4 className='' style={{ textAlign: "center" }}>Manual Property Match</h4>
                    </div>

                </ModalHeader>
                <ModalBody className={"modal__body mb-0 pt-0 "} style={{ height: "100%" }}
                >
                    <Card className='pb-0 '>
                        {/* <Loader loader={this.state.isLoading} /> */}
                        <CardBody className=' p-0 mt-3'
                        >
                            <p className='ml-auto mr-auto info-color mb-4' >State is mandatory. Provide either OPRA Property IDs or State Property IDs.</p>
                            <form className="form row" onSubmit={handleSubmit(this.submit)}>
                                <div className={"col-sm-1 pr-0"} >
                                    <div className="form__form-group pb-1">
                                        <label className="form__form-group-label">State
                                            <span >
                                                <FontAwesomeIcon icon={faStarOfLife}
                                                    className='pl-1'
                                                    color='red'
                                                    data-toggle="tool-tip"
                                                    title="Mandotary Field"
                                                    style={{ width: 11 }}
                                                /></span>
                                        </label>
                                        <div className='form__form-group-field'>
                                            <Field className='inputLogin'
                                                name="SourceState"
                                                component={Select}
                                                placeholder='State'
                                                // validate={[required]}
                                                onChange={(e) => this.setState({ SourceState: e })}
                                                options={this.state.modifiedStates}
                                            // isDisable={view === "loggedThroughmail" || rowData.claimID || (type === "edit" && (rowData.assignToId && rowData.assignToId !== user._id && user.roleName !== "Admin") && !this.state.disabledField) ? true : false}

                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className={"col-sm-3"} >
                                    <div className="form__form-group pb-1">
                                        <label className="form__form-group-label">OPRA Property Id
                                        </label>
                                        <div className='form__form-group-field'>
                                            <Field className='inputLogin'
                                                name="Opra_Property_Id"
                                                component={DefaultTextArea}
                                                placeholder='Property Id'
                                                onChange={(e) => this.setState({ PropertyId: e })}

                                            // onChange={(e) => this.showSave(e, "PropertyId")}
                                            // validate={[required]}
                                            // isDisable={view === "loggedThroughmail" || rowData.claimID || (type === "edit" && (rowData.assignToId && rowData.assignToId !== user._id && user.roleName !== "Admin") && !this.state.disabledField) ? true : false}

                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className={"col-sm-3 "} >
                                    <div className="form__form-group pb-1">
                                        <label className="form__form-group-label">State Property Id
                                        </label>
                                        <div className='form__form-group-field'>
                                            <Field className='inputLogin'
                                                name="StatePropertyId"
                                                component={DefaultTextArea}
                                                placeholder='State Property ID'
                                                onChange={(e) => this.setState({ StatePropertyId: e })}
                                            // validate={[required]}
                                            // isDisable={view === "loggedThroughmail" || rowData.claimID || (type === "edit" && (rowData.assignToId && rowData.assignToId !== user._id && user.roleName !== "Admin") && !this.state.disabledField) ? true : false}

                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className={"col-sm-2 "} >
                                    <div className="form__form-group pb-1">
                                        <label className="form__form-group-label">Missing OPRA Property Id
                                        </label>
                                        <div className='form__form-group-field'>
                                            <Field className='inputLogin'
                                                name="missingOpraPropertyId"
                                                component={DefaultTextArea}
                                                placeholder='Missing OPRA Property Id'
                                                onChange={(e) => this.showSave(e, "PropertyId")}
                                                // validate={[required]}
                                                isDisable={this.state.isDisablemissingPropIds}

                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className={"col-sm-2"} >
                                    <div className="form__form-group pb-1">
                                        <label className="form__form-group-label">Missing State Property Id
                                        </label>
                                        <div className='form__form-group-field'>
                                            <Field className='inputLogin'
                                                name="missingStatePropertyId"
                                                component={DefaultTextArea}
                                                placeholder='Missing State Property ID'
                                                onChange={(e) => this.showSave(e, "StatePropertyId")}
                                                // validate={[required]}
                                                isDisable={this.state.isDisablemissingStPropIds}

                                            // isDisable={view === "loggedThroughmail" || rowData.claimID || (type === "edit" && (rowData.assignToId && rowData.assignToId !== user._id && user.roleName !== "Admin") && !this.state.disabledField) ? true : false}

                                            />
                                        </div>
                                    </div>
                                </div>

                                <div className={"col-sm-1"} >
                                    <Button
                                        style={{ height: "34px" }}
                                        color='primary'
                                        className={"mb-0 mt-3"}
                                        outline
                                        type="submit"
                                        disabled={this.state.SourceState && (this.state.PropertyId.trim().length > 0 || this.state.StatePropertyId.trim().length > 0) ? false : true}

                                    >
                                        {'Go'}
                                    </Button></div>

                            </form >
                            {this.getMatchPropBtn()}
                            <p className='' style={{ height: "30px", color: this.state.errorColor === "info" ? "#354f6e" : this.state.errorColor === "error" ? "red" : "green" }}>{this.state.isShownErrorResp ? this.state.errorMessage : ""}</p>
                            {this.getrediredBtn()}
                            <>
                                <DataTables
                                    onRef={(ref) => (this.datatableref = ref)}
                                    isFormTable={true}
                                    dontShowTitle={true}
                                    isClearFilters={false}
                                    getTableFields={this.getTableFields}
                                    loading={true}
                                    formFields={[]}
                                    exportRequried={false}
                                    printRequried={false}
                                    filter={this.state.filter}
                                    scrollHeight={"750px"}
                                    sortField={"CreatedDate"}
                                    sortDirection={"asc"}
                                    noLimit={true}

                                    isMatchproperties={true}
                                    getResponseforSelectedPropertyData={this.getResponseforSelectedPropertyData}
                                    criteria={this.props.criteria}
                                    statsUrl={"NsScoredProperties/ValidatedStats"}
                                    // sellerID={this.props.EstateID}
                                    addRequried={false}
                                    editRequired={false}
                                    deleteRequired={false}
                                    viewRequired={true}
                                    settingsRequired={false}
                                    custumisedRowHighlights={this.custumisedRowHighlights}
                                    filterRequired={true}
                                    gridRequried={false}
                                    // gettaskFilters={this.getrediredBtn}
                                    getSortedData={this.getSortedData}
                                    activeTab={"1"}
                                    sample={false}
                                    // getMatchPropBtn={this.getMatchPropBtn()}
                                    dataKey={"Id"}
                                    sendSelctedRecordtoDb={this.sendSelctedRecordtoDb}
                                    screenPermissionsRoute={this.props.screenPermissions}

                                    // expandTableFields={this.expandTableFields}
                                    // globalSearchFieldName='user'
                                    // globalSearch={'Search'}
                                    // type='Validated Prop. By State'
                                    displayright={true}
                                    icon='user'
                                    routeTo='properties'
                                    displayViewOfForm='modal'
                                    apiResponseKey='properties'
                                    apiUrl={apiCalls.MatchPropertyGet}
                                    entityType='employee'
                                />
                                {this.state.isopenConfirmaionModelforSave ? this.getConfirmaionModelforSave() : null}
                                <a
                                    className="redirectToProperties"
                                    href={this.state.propertiesUrl}
                                // target='_blank'
                                >
                                    {" "}
                                </a>
                            </>

                        </CardBody>
                    </Card>
                </ModalBody>


            </Modal >
        );
    }
}


MatchProperty = reduxForm({
    form: "MatchPropertyModal", // a unique identifier for this form
    validate,
    enableReinitialize: true,
})(MatchProperty);

// You have to connect() to any reducers that you wish to connect to yourself
MatchProperty = connect(
    state => ({
        initialValues: state.commonData.data // pull initial values from account reducer
    }),
    { load: loadAccount } // bind account loading action creator
)(MatchProperty);

export default withTranslation('common')(MatchProperty);




