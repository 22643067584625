import React from 'react';
import DataTables from '../../CommonDataTable/DataTable';
import config from '../../../../config/config';
import apiCalls from '../../../../config/apiCalls'
import store from '../../../App/store';
import RolePermissions from '../../CommonModals/Permissions';

// config file
export default class NamesLookup extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      type: 'NamesLookup',
      rolePermission: ""
    };
  }

  static getDerivedStateFromProps(props, state) {
    let storeData = store.getState()
    let languageData = storeData && storeData.settingsData && storeData.settingsData.settings && storeData.settingsData.settings.languageTranslation ? storeData.settingsData.settings.languageTranslation : ""
    return { languageData: languageData };
  }

  componentDidMount = () => {
    //Screen permisions value can be edit,view, no view
    let screenPermissions = RolePermissions.screenPermissions('NamesLookup');
    if (screenPermissions) {
      this.setState({
        rolePermission: screenPermissions
      })
    }
  }

  getTableFields = () => {
    let { languageData } = this.state
    let data =
      [
        {
          textAlign: "center",
          show: true,
          mobile: true,
          width: 80,
          field: "CompanyID",
          dateFormat: config.basicDateFromat,
          header: "Company ID",
          filter: true,
          sortable: true,
          displayInSettings: true,
          filterType: "Equals"
        },
        {
          textAlign: "left",
          width: 80,
          field: "Deal",
          mobile: true,
          header: "Deal",
          filter: true,
          sortable: true,
          show: true,
          textCapitalize: true,
          displayInSettings: true,
        },
        {
          textAlign: "left",
          width: 80,
          field: "DealID",
          mobile: true,
          header: "Deal ID",
          filter: true,
          sortable: true,
          show: true,
          textCapitalize: true,
          displayInSettings: true,
          filterType: "num"
        },
        {
          textAlign: "left",
          width: 80,
          field: "BusinessName",
          mobile: true,
          header: "Business Name",
          fieldType: "Link",
          filter: true,
          sortable: true,
          show: true,
          textCapitalize: true,
          displayInSettings: true,
        },
        {
          textAlign: "left",
          width: 80,
          field: "Date",
          mobile: true,
          header: "Date",
          filter: false,
          sortable: true,
          show: true,
          textCapitalize: true,
          displayInSettings: true,
        },
        {
          textAlign: "left",
          width: 80,
          field: "type",
          mobile: true,
          header: "type",
          filter: true,
          sortable: true,
          show: true,
          textCapitalize: true,
          displayInSettings: true,
        },
        {
          textAlign: "left",
          width: 80,
          field: "Status",
          mobile: true,
          header: "Status",
          filter: true,
          sortable: true,
          show: true,
          textCapitalize: true,
          displayInSettings: true,
        },

        {
          textAlign: "center",
          show: true,
          mobile: true,
          width: 80,
          field: "LeadCourtCaseKey",
          dateFormat: config.basicDateFromat,
          header: "Lead Court Case Key",
          filter: true,
          sortable: true,
          displayInSettings: true,
        },

      ]
    return data;
  };


  getFormFields = () => {
    let { languageData } = this.state
    return ([
      {
        'show': false,
        "value": "",
        "type": "text",
        "name": "firstName",
        "label": "First Name",
        "id": "firstName",
        "placeholder": "First Name",
        "required": true
      },

    ]);
  }

  render() {
    return (
      <span>
        <DataTables
          onRef={(ref) => (this.datatableref = ref)}
          getTableFields={this.getTableFields}
          formFields={this.getFormFields}
          sortField={"BusinessName"}
          exportRequried={false}
          printRequried={false}
          addRequried={false}
          editRequired={false}
          isClearFilters={true}
          deleteRequired={false}
          viewRequired={true}
          settingsRequired={false}
          filterRequired={false}
          gridRequried={false}
          sample={false}
          // globalSearchFieldName='DealName'
          // globalSearch={'Search'}
          type="Name Lookup"
          screenPermissionsRoute={"Names Lookup"}
          displayright={true}
          icon='tag'
          routeTo='NamesLookup'
          displayViewOfForm='modal'
          apiResponseKey={"namesLookup"}
          apiUrl={"seller/NamesAddressLookup"}
          entityType='employee'
        />
      </span>
    );
  }
}