import React from 'react';
import DataTables from '../../CommonDataTable/DataTable';
import apiCalls from '../../../../config/apiCalls'
import store from '../../../App/store';

// config file
export default class Rules extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      type: 'Rules',
      fieldtype: [
        { "label": "User", "value": "User" },
        { "label": "Role", "value": "Role" }
      ],
      actions: [
        { "label": "Send Notification", "value": "sendNotification" },
        {
          "label": "Change Assigned User", "value": "ChangeAssignedUser"
        }
      ]
    };
  }

  static getDerivedStateFromProps(props, state) {
    let storeData = store.getState()
    let languageData = storeData && storeData.settingsData && storeData.settingsData.settings && storeData.settingsData.settings.languageTranslation ? storeData.settingsData.settings.languageTranslation : ""
    return { languageData: languageData };
  }



  componentDidMount = () => {

  }
  /**
   * 
   * @returns tablefields to show in grid
   */
  getTableFields = () => {
    let data = [
      {
        "show": true,
        "textAlign": "left",
        // "width": 100,
        "field": "ruleName",
        "mobile": true,
        "fieldType": 'BoolText',
        "header": "Name",
        "displayInSettings": true,
        "filter": true,
        "sortable": true,
      }, {
        "show": true,
        "textAlign": "left",
        // "width": 100,
        "field": "action",
        "mobile": true,
        "header": "Action",
        "displayInSettings": true,
        "filter": true,
        "sortable": true,
      }, {
        "show": true,
        "textAlign": "left",
        // "width": 100,
        "field": "assignType",
        "mobile": true,
        "header": "Assign Type",
        "displayInSettings": true,
        "filter": true,
        "sortable": true,
      }, {
        "show": true,
        "textAlign": "left",
        // "width": 100,
        "field": "assignTo",
        "mobile": true,
        "header": "Assign To",
        "displayInSettings": true,
        "filter": true,
        "sortable": true,
      },
      {
        "show": true,
        "textAlign": "left",
        // "width": 100,
        "fieldType": 'Actions',
        "field": "Actions",
        "header": "Actions",
        "filter": true,
        "displayInSettings": true,
        "sortable": false
      }
    ]
    return data;
  };


  /**
   * 
   * @returns formfields to show in form
   */
  getFormFields = () => {
    return ([
      {
        "value": "",
        "type": "text",
        "name": "ruleName",
        "label": "Name",
        "id": "name",
        "placeholder": "Name",
        "required": true
      }, {
        "value": "",
        "type": "dropDown",
        "name": "action",
        "label": "Action",
        "id": "assignType",
        "placeholder": "Select Type",
        "options": this.state.actions,
        "required": false
      },
      {
        "value": "",
        "type": "dropDown",
        "name": "assignType",
        "label": "Assign Type",
        "id": "assignType",
        "placeholder": "Select Type",
        "options": this.state.fieldtype,
        "required": false
      },
      {
        "value": "",
        "type": "autoComplete",
        "name": "assignTo",
        "label": "Assign To",
        "id": "assignTo",
        "placeholder": "Assign To",
        "required": false
      },
      {
        "value": "",
        "type": "switch",
        "name": "activeIndicator",
        "label": "Status",
        "id": "name",
        "placeholder": "Name",
      },

    ]);
  }


  submit = async (item) => {

    await this.setState({
      openViewModal: true,
      rowData: [{ 'label': 'name', 'value': 'new name' }]
    })
  }

  render() {
    return (
      <span>
        <DataTables
          onRef={(ref) => (this.datatableref = ref)}
          getTableFields={this.getTableFields}
          formFields={this.getFormFields}
          statusFilter={false}
          className={true}
          exportRequried={false}
          printRequried={false}
          addRequried={true}
          editRequired={true}
          deleteRequired={true}
          isClearFilters={true}
          viewRequired={true}
          settingsRequired={false}
          filterRequired={false}
          gridRequried={false}
          sample={false}
          globalSearchFieldName='user'
          globalSearch={'Search'}
          type='Rules'
          displayright={true}
          icon='list'
          routeTo='rules'
          displayViewOfForm='screen'
          apiResponseKey='rules'
          apiUrl={apiCalls.Rules}
          entityType='employee'
        />
      </span>
    );
  }
}