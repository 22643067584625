import React from 'react';
import { Container } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import SubCategory from './components/SubCategory';
const subCategory = ({ t }) => (
  <Container className="Custom_container">
    <SubCategory />
  </Container>
);

subCategory.propTypes = {
  t: PropTypes.func.isRequired,
};

export default withTranslation('common')(subCategory);
