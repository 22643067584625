import { useEffect } from "react";

const useUrlScript = (url: string, updateVal: any = null) => {
  useEffect(() => {
    const script = document.createElement("script");

    script.src = url;
    script.async = true;
    script.type = "text/javascript";
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, [url, updateVal]);
};

export default useUrlScript;
