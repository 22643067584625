import React from 'react';
import { TLEditor } from './TLEditor';
import fetchMethod from '../../../../config/service';
import Loader from '../../../App/Loader';
import * as chainOfTitle from '../../../../shared/chainOfTitle';

export default class TransmittalLetter extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            chainOfTitleData:[]
        }
        this.queryParams = new URLSearchParams(window.location.search);      
    }

    getChainOfTitle = async () => {
        await this.setState({ loading: true });
        const cot = await chainOfTitle.getChainOfTitle(this.queryParams.get('companyID'), this.queryParams.get('mappedNameID'));        
        this.setState({ chainOfTitleData: cot.data, loading: false });
    }  
   
    render() {
        return (
            <>
                <TLEditor
                    getChainOfTitle={this.getChainOfTitle}
                    chainOfTitleData={this.state.chainOfTitleData}
                />
                <Loader loader={this.state.loading} progress={0}></Loader>
            </>
        )
    }
}