import React from 'react';
import { Collapse, Card, CardBody, Button, CardText } from 'reactstrap';
import {
  LineChart, Label, Line, XAxis, LabelList, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, Sankey
} from 'recharts';

import fetch from '../../../../config/service';
import Loader from '../../../App/Loader';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch, faTimes } from '@fortawesome/free-solid-svg-icons';
import apiCalls from '../../../../config/apiCalls';
import { Redirect } from 'react-router-dom';
// import Chart from 'react-google-charts'
// import MetricsPage from '../../CommonModals/AddMetricsPage'
import Circle from 'mdi-react/CircleIcon';
import dateFormats from '../../../UI/FormatDate/formatDate';

let length = 0;
class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      players: '',
      nameList: '',
      searchValue: '',
      isLoading: false,
      chartTitle: 'Network Graphs',
      selectedIndex: '',
      colors: ['#006699', '#f58f0a'],
      filterCriteria: {
        limit: 20,
        page: 1,
        criteria: [],
        sortfield: '',
        direction: 'desc'
      },
      selectedNameIndex: '',
      showCardsList: false,
      showSearchIcon: true,
      graphData: '',
      previousSearchValue: '',
      gender: '',
      width: '',
      dimensions:
        [
          {
            "value": "ad_unit",
            "label": "Ad unit",
          },
          {
            "value": "app_name",
            "label": "App Name",
          },
          {
            "value": "created",
            "label": "Date",
          },
          {
            "value": "line_item_name",
            "label": "Inventory Format",
          },
          {
            "value": "operating_System",
            "label": "Platform",
          },
          {
            "value": "country",
            "label": "Country",
          },
          {
            "value": "bundle_id",
            "label": "Bundle Id",
          },
        ],
      sankeyReports: true,

      metrics:
        [
          {
            "label": "Clicks",
            "value": "total_clicks",
          },


          {
            "value": "total_ad_requests",
            "label": "Network Requests",
          },
          {
            "value": "total_code_served_count",
            "label": "Matched Requests",
          },
          {
            "value": "total_line_item_level_all_revenue",
            "label": "Estimated Earnings",
          },
          {
            "value": "impressions",
            "label": "Impressions",
          },
        ],
      info: {
        // 'metric1': 'total_code_served_count',
        // 'metric2': 'total_ad_requests',
        'metric1': 'total_clicks',
        'metric2': 'impressions',
        'metric3': 'total_code_served_count',
        'metric4': 'total_ad_requests',
        'dimension': 'created',
        'dimension2': 'created',
      },
      chartType: '',
      isLoading: false,
      closeCharts: false,
      length: 0,
      dateQuery: -1,
      matchQuery: -1,
    }
  }
  componentDidMount = async () => {
    let info = this.state.info;
    if (this.props.onRef) {
      this.props.onRef(this);
    }
    await this.getNetworkDetails(info)
  }
  componentWillUnmount() {
    if (this.props.onRef) {
      this.props.onRef(null);
    }
  }
  setInfo = async (info) => {
    let temp = this.state.info;
    if (this.state.chartType === 'chart1') {
      temp['metric1'] = info['metric1']
      temp['metric2'] = info['metric2']
      temp['dimension'] = info['dimension']
    } else {
      temp['metric1'] = info['metric1']
      temp['metric2'] = info['metric2']
      temp['dimension'] = info['dimension']
    }
    await this.setState({
      info: temp
    })
    this.getNetworkDetails()
  }
  getNetworkDetails = async () => {

    var url1 = window.location.href
    var x = url1.split("/")
    var y = url1 && x ? JSON.parse(x[x.length - 1]) : ""
    this.setState({ dimSellerId: y })
    let url2 = `${apiCalls.sankeyReport}?sellerID=${y}`;

    fetch("GET", url2)
      .then(async (response) => {


        if (response && response.details) {
          var size = Object.keys(response.details).length;
          if (size > 0) {
            await this.setState({ sankeyReportsNew: response.details })
          }

        }
      }).catch((err) => {
        return err;
      });

  }
  //   new Date(parseInt( report['date'].slice(0,4)),parseInt(report['date'].slice(5,7))-1 )

  openMetric = (chartType) => {
    this.setState({
      openMetricsModal: true,

    })
  }
  closeMetricModal = () => {
    this.setState({
      openMetricsModal: false,
    })
  }


  render() {
    const { graphData } = this.state;
    return (
      <div>
        <div >
          <Loader
            loader={this.state.isLoading}
          />
          <Button
            type='button'
            className='p-1'
            color='primary'
            outline
            style={{ float: 'right', zIndex: 100 }}
            onClick={() => this.openMetric('chart2')}>Back</Button>

          <div className='my-3'>
            <Card className='grphCard pb-0' style={{ height: 1000 }}>
              {this.state.sankeyReports && this.state.sankeyReportsNew ?

                <CardBody>
                  <Sankey
                    width={960}
                    height={1000}
                    data={this.state.sankeyReportsNew ? this.state.sankeyReportsNew : null}
                    // node={<MyCustomNode />}
                    nodePadding={250}
                    margin={{
                      left: 200,
                      right: 200,
                      top: 100,
                      bottom: 100,
                    }}
                    link={{ stroke: '#77c878' }}
                  >
                    <Tooltip />
                  </Sankey>

                </CardBody>
                : <div>
                  <CardText>
                    There is currently no data available for the parameters selected.Please try different combinations.
                  </CardText>
                </div>}
            </Card>
          </div>
        </div>
        {this.state.openMetricsModal ? <Redirect to="/sellerSummaryReport" /> : ""}
      </div >
    )
  }
}


export default App;
