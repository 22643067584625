import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faTimes,faExclamationTriangle,faExclamationCircle } from '@fortawesome/free-solid-svg-icons';

const Alerts = ({type,message,changeStatus}) => {    
    return (
        <div className={`alert alert-${type} alert-dismissible fade show init-border`} role="alert">
            <strong><FontAwesomeIcon
                    icon={type === 'success' ? faCheck : type === 'danger' ? faTimes : type === 'warning' ? faExclamationTriangle : faExclamationCircle } className='mr-1' />{type === 'success' && 'Success'}</strong> {message}
            <button type="button" className="close" data-dismiss="alert" aria-label="Close" onClick={() => changeStatus()}>
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
    )
}
export default Alerts;