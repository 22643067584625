import { useEffect } from "react";

const useInlineScript = (code: string) => {
  useEffect(() => {

    const script = document.createElement("script");

    script.innerText = code;
    script.type = "text/javascript";
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
    
  }, [code]);
};

export default useInlineScript;
