import React from 'react';
import { withTranslation } from 'react-i18next';
import Table from 'react-bootstrap/Table';
import { Button, Modal, ModalHeader, ModalBody } from 'reactstrap';
import { InputText } from 'primereact/inputtext';
import { Checkbox } from 'primereact/checkbox';
import { Calendar } from 'primereact/calendar';
import { MultiSelect } from 'primereact/multiselect';
import { Dropdown } from 'primereact/dropdown';
import dateFormats from '../../UI/FormatDate/formatDate';
//Loader
import Loader from '../../App/Loader';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlusCircle, faTrash, faPen } from '@fortawesome/free-solid-svg-icons';

// config file
class PropertySearchModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            filterCriteria: this.props.filterCriteria,
            serverCriteria: this.props.serverCriteria,
            States: this.props.States,
            selectedStates: this.props.criteria.Criteria === "sourceState" ? JSON.parse(this.props.criteria.Value) : [],
            Sources: this.props.Sources,
            selectedSources: this.props.criteria.Criteria === "propertyDataSource" ? JSON.parse(this.props.criteria.Value) : [],
            propertyStatuses: this.props.propertyStatuses,
            selectedPropertyStatus: this.props.criteria.Criteria === "propertyStatus" ? JSON.parse(this.props.criteria.Value) : []
        };
    }

    handlekeyValueChange = async (value, field) => {
        await this.setState({
            [field + "word"]: value
        })
    }

    onStateChange = async (e) => {
        if (e && e.value) {
            await this.setState({
                selectedStates: e.value,
            })
        } else {
            await this.setState({
                selectedStates: e,
            })
        }
    };

    onSourceChange = async (e) => {
        if (e && e.value) {
            await this.setState({
                selectedSources: e.value,
            })
        } else {
            await this.setState({
                selectedSources: e,
            })
        }
    };

    onPropertyStatusChange = async (e) => {
        if (e && e.value) {
            await this.setState({
                selectedPropertyStatus: e.value,
            })
        } else {
            await this.setState({
                selectedPropertyStatus: e,
            })
        }
    }

    getTemplate = (data, criteria) => {
        // this.handlekeyValueChange(criteria.Value, data)
        // this.setState({
        //     [data + "word"]: criteria.Value
        // })

        let input1 = ["propertyOwnerName", "propertyAddressLine1", "Owner City", "holderName", "keyWord", "propertyDescription"] // owner city
        let input2 = ["sourceState", "state"]
        let input3 = ["propertyValue", "shares", "fileYear"]
        let input4 = ["propertyDataSource"]
        let input5 = ["ownerPostalCode", "propertyId", "statePropertyId"]
        let input6 = ["escheatmentDate", "dateOfLastContact"]
        let input7 = ["propertyStatus"]
        if (input1.includes(data)) {

            return (
                <div style={{ margin: '20px' }}>
                    <div className='row pl-5 d-flex col-12' >
                        <div className='col-6 pl-0 p-float-label' style={{ alignSelf: "center", textAlign: "left" }}>
                            <InputText id="in" value={this.state[data] ? this.state[data] : criteria.Value} onChange={(e) => this.setState({ [data]: e.target.value })} />
                            <label htmlFor="in">criteria</label>
                        </div>
                        <div className='d-flex col-6' style={{ justifyContent: "space-around", textAlign: "left" }}>
                            <div >
                                <div style={{ verticalAlign: "middle", paddingLeft: "20px" }}>
                                    <label>
                                        <input
                                            type="radio"
                                            name={data + "word"}
                                            value={"word"}
                                            checked={this.state[data + "word"] ? this.state[data + "word"] === "word" : criteria.Type === "word" ? true : false}
                                            onChange={(e) => this.handlekeyValueChange(e.target.value, data)}
                                        />
                                        &nbsp;{"Word"}
                                    </label>
                                </div>

                                <div style={{ verticalAlign: "middle", paddingLeft: "20px" }}>
                                    <label>
                                        <input
                                            type="radio"
                                            name={data + "word"}
                                            value={"wordPrefix"}
                                            checked={this.state[data + "word"] ? this.state[data + "word"] === "wordPrefix" : criteria.Type === "wordPrefix" ? true : false}
                                            onChange={(e) => this.handlekeyValueChange(e.target.value, data)}
                                        />
                                        &nbsp;{"Word Prefix"}
                                    </label>
                                </div>

                                <div style={{ verticalAlign: "middle", paddingLeft: "20px" }}>
                                    <label>
                                        <input
                                            type="radio"
                                            name={data + "word"}
                                            value={"wildCard"}
                                            checked={this.state[data + "word"] ? this.state[data + "word"] === "wildCard" : criteria.Type === "wildCard" ? true : false}
                                            onChange={(e) => this.handlekeyValueChange(e.target.value, data)}
                                        />
                                        &nbsp;{"Wild Card"}
                                    </label>
                                </div>

                                <div style={{ verticalAlign: "middle", paddingLeft: "20px" }}>
                                    <label>
                                        <input
                                            type="radio"
                                            name={data + "word"}
                                            value={"fuzzy"}
                                            checked={this.state[data + "word"] ? this.state[data + "word"] === "fuzzy" : criteria.Type === "fuzzy" ? true : false}
                                            onChange={(e) => this.handlekeyValueChange(e.target.value, data)}
                                        />
                                        &nbsp;{"Fuzzy"}
                                    </label>
                                </div>
                            </div>
                            {/* <div style={{ alignSelf: "center" }}>
                                <FontAwesomeIcon
                                    icon={faPlusCircle}
                                    className='pl-1'
                                    color='black'
                                    data-toggle="tool-tip"
                                    title="Mandotary Field"
                                    style={{ width: 30 }}
                                />
                            </div> */}
                        </div>
                    </div>
                    <div style={{ textAlign: "right" }}>
                        <Button
                            outline
                            color="primary"
                            // size="sm"
                            disabled={(this.state[data + "word"] || this.state[data]) ? false : true}
                            // className={'p-1 m-2'}
                            className='saveModalBtn marginRight'
                            style={{ width: "85px" }}
                            onClick={() => this.UpdateCriteria(data, "input1")}
                        >
                            {"Save"}
                        </Button>
                        <Button
                            outline
                            color="primary"
                            // size="sm"
                            // className={'p-1 m-2'}
                            className='saveModalBtn marginRight'
                            style={{ width: "85px" }}
                            onClick={this.props.closeDeleteModal}
                        >
                            {"Cancel"}
                        </Button>
                        {/* onClick={this.props.closeDeleteModal}
                        <Button
                            color="primary"
                            outline
                            onClick={this.props.closeDeleteModal}
                            className={'p-1 m-2'}
                            style={{ width: "85px" }}
                        >
                            {'Cancel'}
                        </Button> */}
                    </div>
                </div>
            )
        } else if (input2.includes(data)) {
            let selectedStates = JSON.parse(this.props.criteria.Value)
            return (
                <div className="card flex justify-content-center">
                    <div className="flex pl-5 pt-3 flex-column gap-3">
                        {/* {
                            this.state.States.map((state) => {
                                return (
                                    <div key={state.Abbreviation} className="flex align-items-center">
                                        <Checkbox
                                            inputId={state.Abbreviation}
                                            name="state"
                                            value={state}
                                            onChange={(e) => this.onStateChange(e)}
                                            checked={this.state.selectedStates.length > 0 ? this.state.selectedStates.some((item) => item.key === state.Abbreviation) : selectedStates.some((item) => item.key === state.Abbreviation)}
                                        />
                                        <label htmlFor={state.Abbreviation} className="ml-2">{state.Name}</label>
                                    </div>
                                );
                            })
                        } */}
                        <MultiSelect
                            name={"Source State"}
                            id="state"
                            filter={true}
                            value={this.state.selectedStates}
                            style={{ width: "50%", height: 32 }}
                            appendTo={document.body}
                            // disabled={this.props.type === "ClaimsManagement" ? false : this.state.selectedSeller ? false : true}
                            maxSelectedLabels={1}
                            options={this.state.States}
                            onChange={(e) => this.onStateChange(e, "Source State")}

                        />
                    </div>
                    <div style={{ textAlign: "right" }}>
                        <Button
                            outline
                            color="primary"
                            size="sm"
                            disabled={this.state.selectedStates.length > 0 ? false : true}
                            className={'p-1 m-2'}
                            style={{ width: "85px" }}
                            onClick={() => this.UpdateCriteria(data, "input2")}
                        >
                            {"Save"}
                        </Button>
                        <Button color="primary" outline
                            onClick={this.props.closeDeleteModal}
                            className={'p-1 m-2'}>{'Cancel'}</Button>
                    </div>
                </div>
            )
        } else if (input3.includes(data)) {
            return (
                <div style={{ margin: '20px' }}>
                    <div className='row pl-5 d-flex col-12' >
                        <div className='col-6 pl-0 p-float-label' style={{ alignSelf: "center" }}>
                            <InputText
                                id="in"
                                value={this.state[data + "min"] ? this.state[data + "min"] : criteria.minValue}
                                onChange={(e) => this.setState({ [data + "min"]: e.target.value })} />
                            <label htmlFor="in">minimum</label>
                        </div>
                        <div className='col-6 pl-0 p-float-label' style={{ alignSelf: "center" }}>
                            <InputText
                                id="in"
                                value={this.state[data + "max"] ? this.state[data + "max"] : criteria.maxValue}
                                onChange={(e) => this.setState({ [data + "max"]: e.target.value })} />
                            <label htmlFor="in">maximum</label>
                        </div>
                    </div>
                    <div style={{ textAlign: "right" }}>
                        <Button
                            outline
                            color="primary"
                            size="sm"
                            disabled={this.state[data + "min"] && this.state[data + "max"] ? false : true}
                            className={'p-1 m-2'}
                            style={{ width: "85px" }}
                            onClick={() => this.UpdateCriteria(data, "input3")}
                        >
                            {"Save"}
                        </Button>
                        <Button color="primary" outline
                            onClick={this.props.closeDeleteModal}
                            className={'p-1 m-2'}>{'Cancel'}</Button>
                    </div>
                </div>
            )
        } else if (input4.includes(data)) {
            let selectedSources = JSON.parse(this.props.criteria.Value)
            return (
                <div className="card flex justify-content-center">
                    <div className="flex pl-5 pt-3 flex-column gap-3">
                        {/* {
                            this.state.Sources.map((source) => {
                                return (
                                    <div key={source.key} className="flex align-items-center">
                                        <Checkbox
                                            inputId={source.key}
                                            name="source"
                                            value={source}
                                            onChange={(e) => this.onSourceChange(e)}
                                            checked={this.state.selectedSources ? this.state.selectedSources.some((item) => item.key === source.key) : selectedSources.some((item) => item.key === source.key)}
                                        />
                                        <label htmlFor={source.key} className="ml-2">{source.name}</label>
                                    </div>
                                );
                            })
                        } */}
                        <MultiSelect
                            name={"Source"}
                            id="sourece"
                            filter={true}
                            value={this.state.selectedSources}
                            style={{ width: "50%", height: 32 }}
                            appendTo={document.body}
                            maxSelectedLabels={1}
                            options={this.state.Sources}
                            onChange={(e) => this.onSourceChange(e, "Source")}

                        />
                    </div>
                    <div style={{ textAlign: "right" }}>
                        <Button
                            outline
                            color="primary"
                            size="sm"
                            disabled={this.state.selectedSources.length > 0 ? false : true}
                            className={'p-1 m-2'}
                            style={{ width: "85px" }}
                            onClick={() => this.UpdateCriteria(data, "input4")}
                        >
                            {"Save"}
                        </Button>
                        <Button color="primary" outline
                            onClick={this.props.closeDeleteModal}
                            className={'p-1 m-2'}>{'Cancel'}</Button>
                    </div>
                </div>
            )
        } else if (input5.includes(data)) {
            return (
                <div style={{ margin: '20px' }}>
                    <div className='row pl-5 d-flex col-12' >
                        <div className='col-6 pl-0 p-float-label' style={{ alignSelf: "center" }}>
                            <InputText id="in" value={this.state[data] ? this.state[data] : criteria.Value} onChange={(e) => this.setState({ [data]: e.target.value })} />
                            <label htmlFor="in">criteria</label>
                        </div>
                    </div>
                    <div style={{ textAlign: "right" }}>
                        <Button
                            outline
                            color="primary"
                            size="sm"
                            disabled={this.state[data] ? false : true}
                            className={'p-1 m-2'}
                            style={{ width: "85px" }}
                            onClick={() => this.UpdateCriteria(data, "input5")}
                        >
                            {"Save"}
                        </Button>
                        <Button color="primary" outline
                            onClick={this.props.closeDeleteModal}
                            className={'p-1 m-2'}>{'Cancel'}</Button>
                    </div>
                </div>
            )
        } else if (input6.includes(data)) {
            return (
                <div style={{ margin: '20px' }}>
                    <div className='row pl-5 d-flex col-12' >
                        <div className='col-6 pl-0 p-float-label' style={{ alignSelf: "center" }}>
                            <Calendar
                                style={{ zindex: 1004, width: '100%', lineHeight: 1.15, height: '25px' }}
                                appendTo={document.body}
                                monthNavigator={true}
                                yearNavigator={true}
                                yearRange="1940:2530"
                                name={"calendar1"}
                                // placeholder='mm/dd/yyyy'
                                dateFormat="mm/dd/yy"
                                value={this.state[data + "minDate"] ? this.state[data + "minDate"] : criteria.minDateValue}
                                onChange={(e) => this.setState({ [data + "minDate"]: e.value })}
                            />
                            <label htmlFor="in">Min Date</label>
                        </div>
                        <div className='col-6 pl-0 p-float-label' style={{ alignSelf: "center" }}>
                            <Calendar
                                style={{ zindex: 1004, width: '100%', lineHeight: 1.15, height: '25px' }}
                                appendTo={document.body}
                                monthNavigator={true}
                                yearNavigator={true}
                                yearRange="1940:2530"
                                name={"calendar2"}
                                // placeholder='mm/dd/yyyy'
                                dateFormat="mm/dd/yy"
                                value={this.state[data + "maxDate"] ? this.state[data + "maxDate"] : criteria.maxDateValue}
                                onChange={(e) => this.setState({ [data + "maxDate"]: e.value })}
                            />
                            <label htmlFor="in">Max Date</label>
                        </div>
                    </div>
                    <div style={{ textAlign: "right" }}>
                        <Button
                            outline
                            color="primary"
                            size="sm"
                            disabled={this.state[data + "minDate"] || this.state[data + "maxDate"] ? false : true}
                            className={'p-1 m-2'}
                            style={{ width: "85px" }}
                            onClick={() => this.UpdateCriteria(data, "input6")}
                        >
                            {"Save"}
                        </Button>
                        <Button color="primary" outline
                            onClick={this.props.closeDeleteModal}
                            className={'p-1 m-2'}>{'Cancel'}</Button>
                    </div>
                </div >
            )
        } else if (input7.includes(data)) {
            return (
                <div className="card flex justify-content-center">
                    <div className="flex pl-5 pt-3 flex-column gap-3">
                        <MultiSelect
                            name={"PropertyStatus"}
                            id="propertyStatus"
                            filter={true}
                            value={this.state.selectedPropertyStatus}
                            style={{ width: "50%", height: 32 }}
                            appendTo={document.body}
                            // disabled={this.props.type === "ClaimsManagement" ? false : this.state.selectedSeller ? false : true}
                            maxSelectedLabels={1}
                            options={this.state.propertyStatuses}
                            onChange={(e) => this.onPropertyStatusChange(e, "propertyStatus")}

                        />
                    </div>
                    <div style={{ textAlign: "right" }}>
                        <Button
                            outline
                            color="primary"
                            size="sm"
                            disabled={this.state.selectedPropertyStatus.length > 0 ? false : true}
                            className={'p-1 m-2'}
                            style={{ width: "85px" }}
                            onClick={() => this.UpdateCriteria(data, "input7")}
                        >
                            {"Save"}
                        </Button>
                    </div>
                </div>
            )
        } else {
            return (
                <div className="p-md-10 ml-4" style={{ fontWeight: 'bold' }}>No filter found</div>
            );
        }
    }


    UpdateCriteria = async (field, type) => {

        let Criteria = this.state.filterCriteria;
        let serverCriteria = this.state.serverCriteria;
        let index = this.props.editRowIndex
        let filter = {}
        let serverFilter = {}
        if (type == "input1") {
            filter = {
                Criteria: field,
                Type: this.state[field + "word"] ? this.state[field + "word"] : this.props.criteria.Type,
                Value: this.state[field] ? this.state[field] : this.props.criteria.Value,
                Actions: await this.props.Actions(index),
                index: index
            }
            serverFilter = {
                Criteria: field,
                Type: this.state[field + "word"] ? this.state[field + "word"] : this.props.criteria.Type,
                Value: this.state[field] ? this.state[field] : this.props.criteria.Value,
                index: index
            }
        } else if (type == "input2") {
            filter = {
                Criteria: field,
                Type: "Includes",
                Value: JSON.stringify(this.state.selectedStates),
                Actions: await this.props.Actions(index),
                index: index
            }
            serverFilter = {
                Criteria: field,
                Type: "Includes",
                Value: JSON.stringify(this.state.selectedStates),
                index: index
            }
        } else if (type == "input3") {
            filter = {
                Criteria: field,
                Type: "MinMax",
                Value: `${this.state[field + "min"]} to ${this.state[field + "max"]}`,
                minValue: this.state[field + "min"],
                maxValue: this.state[field + "max"],
                Actions: await this.props.Actions(index),
                index: index
            }
            serverFilter = {
                Criteria: field,
                Type: "MinMax",
                Value: `${this.state[field + "min"]} to ${this.state[field + "max"]}`,
                minValue: this.state[field + "min"],
                maxValue: this.state[field + "max"],
                index: index
            }

        } else if (type == "input4") {
            filter = {
                Criteria: field,
                Type: "Includes",
                Value: JSON.stringify(this.state.selectedSources),
                Actions: await this.props.Actions(index),
                index: index
            }
            serverFilter = {
                Criteria: field,
                Type: "Includes",
                Value: JSON.stringify(this.state.selectedSources),
                index: index
            }
        } else if (type == "input5") {
            filter = {
                Criteria: field,
                Type: "autoComplete",
                Value: this.state[field],
                Actions: await this.props.Actions(index),
                index: index
            }
            serverFilter = {
                Criteria: field,
                Type: "autoComplete",
                Value: this.state[field],
                index: index
            }
        } else if (type == "input6") {
            let inputValue = ''
            if (this.state[field + "minDate"] && this.state[field + "maxDate"]) {
                inputValue = `${dateFormats.formatDate(this.state[field + "minDate"], "MM/DD/YY")} to ${dateFormats.formatDate(this.state[field + "maxDate"], "MM/DD/YY")}`
            } else if (this.state[field + "minDate"] && !this.state[field + "maxDate"]) {
                inputValue = `from ${dateFormats.formatDate(this.state[field + "minDate"], "MM/DD/YY")}`
            } else if (!this.state[field + "minDate"] && this.state[field + "maxDate"]) {
                inputValue = `upto ${dateFormats.formatDate(this.state[field + "maxDate"], "MM/DD/YY")}`
            }
            filter = {
                Criteria: field,
                Type: "MinMax",
                Value: inputValue,
                minDateValue: this.state[field + "minDate"] ? this.state[field + "minDate"] : this.props.criteria.minDateValue,
                maxDateValue: this.state[field + "maxDate"] ? this.state[field + "maxDate"] : this.props.criteria.maxDateValue,
                Actions: await this.props.Actions(index),
                index: index
            }
            serverFilter = {
                Criteria: field,
                Type: "MinMax",
                Value: inputValue,
                minDateValue: this.state[field + "minDate"] ? this.state[field + "minDate"] : this.props.criteria.minDateValue,
                maxDateValue: this.state[field + "maxDate"] ? this.state[field + "maxDate"] : this.props.criteria.maxDateValue,
                index: index
            }
        } else if (type == "input7") {
            filter = {
                Criteria: field,
                Type: "Includes",
                Value: JSON.stringify(this.state.selectedPropertyStatus),
                Actions: await this.props.Actions(index),
                index: index
            }
            serverFilter = {
                Criteria: field,
                Type: "Includes",
                Value: JSON.stringify(this.state.selectedPropertyStatus),
                index: index
            }
        }

        Criteria[this.props.editRowIndex] = filter
        serverCriteria[this.props.editRowIndex] = serverFilter


        await this.setState({
            filterCriteria: Criteria,
            serverCriteria: serverCriteria
        })
        // clear filters
        await this.setState({
            [field + "word"]: "",
            [field]: "",
            [field + "min"]: "",
            [field + "max"]: "",
            [field + "minDate"]: "",
            [field + "maxDate"]: "",
            selectedStates: [],
            selectedSources: []
        })
        this.props.UpdateFilter(Criteria, serverCriteria);
    }

    editSelectedRow = () => {
        this.props.editSelectedRow();
    }

    render() {
        const { t } = this.props;
        let criteria = this.props.criteria
        return (
            <Modal isOpen={this.props.openFormModal} fade={false} className='right modal-dialog-centered modal-dialog--primary'>
                <Loader loader={this.state.isLoading} progress={this.state.progress} className="progress-loader" />
                <ModalBody >
                    {/* <div> */}
                    <Table hover className="table table-striped">
                        <thead className="thead">
                            <h3>{"Edit Cirteria"}</h3>
                        </thead>
                        <tbody>
                            {this.getTemplate(criteria.Criteria, criteria)}
                        </tbody>
                    </Table>
                </ModalBody>
            </Modal >
        );
    }
}
export default withTranslation('common')(PropertySearchModal);
