import React, {useState,useEffect,Dispatch, SetStateAction  } from 'react';
import { StaticGrid } from '../../../../shared/StaticGrid';
import fetchMethodRequest from '../../../../config/service';
import { Button } from 'reactstrap';
import { Checkbox } from 'primereact/checkbox';
import * as documentsAPIs from '../../../../shared/documentsApis';
import dateFormats from '../../../UI/FormatDate/formatDate';
interface props {
    documents:Array<any>,
    getDocumentsData:Function,
    showModal:any,
    closeModal:Function,
    loadedDocuments:any,
    onSelectUnselectBypassDoc:Function,
    selectionData:Array<any>,
    isSectionLoaded:boolean,
    onClickAddNote:Function,
    onClickNotes:Function,
    onClickLoadAddrEvid:Function,
    disableLoadButton:boolean,
    updateSelectedFiles:Function
    selectedFilesNumbers:Array<any>    
}


export const AddressEvid = ({ documents, getDocumentsData, showModal, closeModal, loadedDocuments, onSelectUnselectBypassDoc, selectionData, isSectionLoaded, onClickAddNote, onClickNotes, onClickLoadAddrEvid, disableLoadButton, updateSelectedFiles, selectedFilesNumbers }: props) => {
    const [disableButton, setDisableButton] = useState<boolean>(true); 
    const [disableSelection, setDisableSelection] = useState<boolean>(false);
    const [disableByPass, setDisableByPass] = useState(false);   

    

    useEffect(() => {
      if(selectionData && selectionData[0] ){
        setDisableSelection(selectionData[0].isBypassed);
      }
    
      return () => {
        setDisableSelection(false);
      }
    }, [selectionData])
    
    

    /**
     * Redirecting to Pdf editor on click file name.
     * @param id {*} 
     */
    const redirectToPDFEditor = (id:any) => {
        fetchMethodRequest('GET', `getDocumentById?Id=${id}`,'','','','').then(async (res) => {
            let data = res && res.respCode && res.outResponse && res.outResponse.details ? res.outResponse.details : null;
            if (data) {                
                localStorage.setItem('pdfDocDetails',JSON.stringify(data));
                let url = `/pdfEditor?details=loadDoc`;
                window.open(url, '_blank', 'noopener=true');
            }            
        })
    }

    const fileNameBody = (row:any) => {
        return <><a onClick={() => redirectToPDFEditor(row._id)} className='text-info' style={{ cursor: 'pointer' }}>{row.fileName}</a>
            {loadedDocuments[row.documentNumber] && <div>(Document already loaded for {loadedDocuments[row.documentNumber].docType})</div>}</>
    }

    // Table columns of address evidence
    const columns = [
        {
            field: 'documentTypeName',
            header: 'Document Type',
            filter: false,
            filterPlaceHolder: '',
            sortable: false,
            style:{textAlign:'left',padding:'revert-layer'}
        },
        {
            field: 'bsAddress',
            header: 'Address',
            filter: true,
            filterPlaceHolder: 'Search',
            sortable: true,
            style:{textAlign:'left',padding:'revert-layer'}
        },
        {
            field: 'bsCity',
            header: 'City',
            filter: true,
            filterPlaceHolder: 'Search',
            sortable: true,
            style:{textAlign:'left',padding:'revert-layer'}
        },
        {
            field: 'bsState',
            header: 'State',
            filter: true,
            filterPlaceHolder: 'Search',
            sortable: true,
            style:{padding:'revert-layer'}
        },
        {
            field: 'fileName',
            header: 'File Name',
            filter: true,
            filterPlaceHolder: 'Search',
            sortable: false,
            style:{width:'40%',textAlign:'left',padding:'revert-layer'},
            formatter:fileNameBody
        }

    ]

    
    const setSelectedFilesInParent = (selecFiles:Array<any>) => {
        const selFiles:Array<any> = documents.filter((row:any) => selecFiles[row.documentNumber] );          
        if(selFiles.length > 0){
            setDisableByPass(true); 
            setDisableButton(false);           
        }else{
            setDisableByPass(false); 
            setDisableButton(true); 
        }
        if(updateSelectedFiles){
            updateSelectedFiles(selFiles ? selFiles : []);
        }
    }
    
    return (      
        <>{ showModal &&
            <StaticGrid
                data={documents}
                columns={columns}
                pagination={true}
                rows={10}
                removableSort={true}
                sortMode='multiple'
                rowsPerPageOption={[10,15,20,30, 50, 100,250]}
                emptyMessage='No Documents Found'
                getDocumentsData={getDocumentsData}
                closeModal={closeModal}
                loadedDocuments={loadedDocuments}
                selectedFilesNumbers={selectedFilesNumbers}
                disableLoadButton={disableLoadButton}
                disableEnableLoadButton= {(value:boolean) => {setDisableButton(value)}} 
                setSelectedFilesInParent={setSelectedFilesInParent}  
                disableSelection={disableSelection}             
            />}
            <table className="table table-bordered">
                
                <tbody>
                    <td className='text-center' style={{width:'50%'}}><Checkbox onChange={() => {onSelectUnselectBypassDoc(documentsAPIs.ADDRESS_EVIDENCE,0);setDisableButton(!disableButton) }} checked={selectionData && selectionData[0] && selectionData[0].isBypassed} disabled={disableByPass || (selectionData && selectionData[0] && selectionData[0].isMandatory)} /> By Pass ADDR Evid</td>
                    <td style={{ width: '50%' }}>
                        {(selectionData && selectionData[0] && selectionData[0].notes && selectionData[0].notes.length > 0)  ?
                            <div onClick={() => {onClickNotes(documentsAPIs.ADDRESS_EVIDENCE,selectionData[0].notes) }}>
                                <div className="font-weight-bold">{selectionData[0].notes[selectionData[0].notes.length - 1].noteAddedOnDate ? dateFormats.formatDate(selectionData[0].notes[selectionData[0].notes.length - 1].noteAddedOnDate, "MM/DD/YYYY", "nhg") : `${new Date().getMonth() + 1}/${new Date().getDate()}/${new Date().getFullYear()}`}:{selectionData[0].notes[selectionData[0].notes.length - 1].noteAddedBy}</div>
                                <span >{selectionData[0].notes[selectionData[0].notes.length - 1].notes}</span>
                            </div>
                            :
                            <Button color='primary' outline onClick={() => {onClickAddNote(documentsAPIs.ADDRESS_EVIDENCE,false)}}>Add Notes</Button>
                        }
                    </td>
                </tbody>
            </table>
            <div className="pt-3 text-center">
                
                <Button color='primary' outline disabled={!isSectionLoaded && disableButton} onClick={onClickLoadAddrEvid}>Load</Button>
                <Button color='primary' outline onClick={closeModal}> Close</Button>
            </div>          
            
        </>
        
    )
}