import React from 'react';
import store from '../../../App/store';
import DataTables from '../../CommonDataTable/DataTable';
import fetchRequest from '../../../../config/service';
// config file
export default class UPOpportunity extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      // tabType: "",
      type: 'Purposes',
      selectedCR: true,
      tabDescriptionArray: [],
      selectedBK: true,
      criteria: [],
      //  activeTab: null,
      apiUrl: ""
    };
  }

  static getDerivedStateFromProps(props, state) {
    let storeData = store.getState()
    let languageData = storeData && storeData.settingsData && storeData.settingsData.settings && storeData.settingsData.settings.languageTranslation ? storeData.settingsData.settings.languageTranslation : ""
    return { languageData: languageData };
  }



  componentDidMount = async () => {
    if (this.props.onRef) {
      this.props.onRef(this);
    }
    let data = []
    return fetchRequest('GET', 'ruleConfiguration')
      .then(async (response) => {

        if (response && response.ruleConfigurations && response.ruleConfigurations.length > 0) {

          let labels = response.ruleConfigurations;
          let modifiedLabels = []; let tabOptions = [];
          for (let i = 0; i < labels.length; i++) {
            let timeStamp = labels[i].flagTimeStamp
            let flagStamp = timeStamp.charAt(0).toUpperCase() + timeStamp.slice(1)
            tabOptions.push({ flagName: labels[i].flagName, name: labels[i].name, flagTimeStamp: flagStamp })
            modifiedLabels.push({
              label: labels[i].name,
              value: { flagName: labels[i].flagName, name: labels[i].name, description: labels[i].description, flagTimeStamp: labels[i].flagTimeStamp },
            })
          }

          await this.setState({
            data: data,
            tabOptions: tabOptions
          })
          localStorage.setItem('ruleGroups', JSON.stringify(modifiedLabels))
        } else {
          await this.setState({
            data: data,
            tabOptions: []
          });
          localStorage.setItem("ruleGroups", JSON.stringify(data))
        }
      }).catch((err) => {
        return err;
      });

  }
  componentWillUnmount() {
    if (this.props.onRef) {
      this.props.onRef(null);
    }
  }
  //user Task label options

  getTableFieldsForTabs = (tabType) => {
    let tabData = this.state.tabOptions
    let timeStamp = tabData && tabData.length > 0 ? tabData[0].flagTimeStamp : ""
    let flagStamp = tabData && tabData.length > 0 ? timeStamp.charAt(0).toUpperCase() + timeStamp.slice(1) : ""
    let data = [
      // {
      //   "show": true,
      //   "textAlign": "center",
      //   "width": 6,
      //   "fieldType": "expander",
      //   "header": "",
      //   "filter": false,
      //   "sortable": false,
      //   "placeholder": "Search"
      // },
      {
        "show": true,
        textAlign: 'left',
        width: 80,
        field: 'EstateName',
        // fieldType: 'BoolText',
        "mobile": true,
        header: 'Deal Name',
        "filter": true,
        "displayInSettings": true,
        "displayInFilter": true,
        sortable: true,
      },
      {
        "show": true,
        textAlign: 'left',
        width: 40,
        field: 'COUNT',
        "allowInt": true,
        "fieldType": "redirect",
        "filterType": 'num',
        "mobile": true,
        header: '# Properties',
        "filter": false,
        "displayInSettings": true,
        "displayInFilter": true,
        sortable: true,
      },
      {
        "show": true,
        textAlign: 'left',
        width: 100,
        field: 'Count10kTo50k',
        "fieldType": "redirect",
        "allowInt": true,
        // "fieldType": " num",
        "filterType": 'num',
        "mobile": true,
        header: '# properties in 10k-50k range ',
        "filter": false,
        "displayInSettings": true,
        "displayInFilter": true,
        sortable: true,
      }, {
        "show": true,
        textAlign: 'left',
        width: 100,
        field: 'CountAbove50k',
        "fieldType": "redirect",
        "allowInt": true,
        // "fieldType": "num",
        "filterType": 'num',
        "mobile": true,
        header: '# properties > $50k',
        "filter": false,
        "displayInSettings": true,
        "displayInFilter": true,
        sortable: true,
      }, {
        "show": true,
        textAlign: 'left',
        width: 100,
        field: 'CountPropertyUnknown',
        "allowInt": true,
        "fieldType": "redirect",
        // "fieldType": "num",
        "filterType": 'num',
        "mobile": true,
        header: '# unknown properties in single state for a single name',
        "filter": false,
        "displayInSettings": true,
        "displayInFilter": true,
        sortable: true,
      },
      {
        "show": true,
        textAlign: 'right',
        width: 80,
        field: 'PropertyEstimatedMaxValueAmountSum',
        fieldType: "formatCurrency",
        "mobile": true,
        header: 'Est. Value',
        "filter": false,
        "displayInSettings": true,
        "displayInFilter": true,
        sortable: true,
      }, {
        "show": true,
        textAlign: 'right',
        width: 80,
        field: 'PropertyKnownMaxValueAmountSum',
        "mobile": true,
        header: 'Known Value',
        fieldType: "formatCurrency",
        "filter": false,
        "displayInSettings": true,
        "displayInFilter": true,
        sortable: true,
      },
      {
        "show": true,
        textAlign: 'right',
        width: 80,
        field: flagStamp,
        "mobile": true,
        "filterType": "num",
        "fieldType": 'Date',
        "dateFormat": "MM/DD/YYYY",
        header: 'Last Refresh Date',
        "filter": false,
        "displayInSettings": true,
        "displayInFilter": true,
        sortable: true,
      },
      {
        "show": true,
        textAlign: 'left',
        width: 80,
        field: 'Team',
        // fieldType: 'BoolText',
        "mobile": true,
        header: 'Team',
        "filter": true,
        "displayInSettings": true,
        "displayInFilter": true,
        sortable: true,
      },
    ]
    return data;
  };

  getFormFields = () => {
    return ([
    ]);
  }

  getTableFields = (tabType) => {
    if (tabType) {
      this.setState({
        tabType: tabType,

      })
    }
    let data = this.getTableFieldsForTabs(tabType);

    return data;
  };

  submit = async (item) => {
  }




  render() {
    let tabData = JSON.parse(localStorage.getItem("ruleGroups"))
    return (
      <span>
        {/* <Loader loader={this.state.isLoading} /> */}
        <DataTables
          onRef={(ref) => (this.datatableref = ref)}
          getTableFields={this.getTableFields}
          formFields={this.getFormFields}
          exportRequried={false}
          excelDownload={true}
          printRequried={false}
          addRequried={false}
          editRequired={true}
          deleteRequired={false}
          viewRequired={true}
          dontShowTitle={true}
          settingsRequired={false}
          customisedExport={true}//for post method in download excel
          filterRequired={true}
          gridRequried={false}
          sample={false}
          tabDescriptionArray={tabData && tabData.length > 0 ? tabData : []}
          isClearFilters={false}
          globalSearchFieldName='user'
          globalSearch={""}
          criteria={tabData && tabData.length > 0 ? [{ key: tabData[0].value.flagName, value: true, type: "eq" }] : []}
          type={"UP Opportunity – V2"}
          displayright={true}
          icon='menu-circle'
          sortField={"COUNT"}
          activeTab={tabData && tabData.length > 0 ? tabData[0].label : ""}
          tabType={true}
          tabOptions={this.state.tabOptions}
          routeTo='sellers'
          displayViewOfForm='modal'
          apiResponseKey={"summary"}
          className={true}
          apiUrl={"NsScoredProperties/GetSummaryV2"}
          entityType='employee'
        />
      </span>
    );
  }
}