import React, { Component } from 'react';
import PropTypes from 'prop-types';
import SidebarLink from './SidebarLink';
import SidebarCategory from './SidebarCategory';

class SidebarContent extends Component {
  static propTypes = {
    changeToDark: PropTypes.func.isRequired,
    changeToLight: PropTypes.func.isRequired,
    onClick: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      // languageData: '',
      menuList: this.props.menuList,
      assignedUsers: []

    };
  }
  componentDidMount = () => {


  }
  hideSidebar = () => {
    const { onClick } = this.props;
    onClick();
  };

  render() {
    const { menuList, adminsitrationList } = this.props;
    return (
      <div className="sidebar__content">
        <ul className="sidebar__block">
          {menuList && menuList.length > 0 ?
            menuList.map((item, index) => {
              if (item.group === "Resources") {
                if (item.submenus && item.submenus.length > 0) {
                  return (
                    <SidebarCategory
                      key={index}
                      title={item.displayTitle === 'Settings' ? 'Settings Menu' : item.displayTitle}
                      icon={item.icon}
                    >
                      {item.submenus.map((sitem, sindex) => {
                        return (
                          <SidebarLink key={sindex}
                            title={sitem.displayTitle}
                            icon={sitem.icon}
                            onClick={this.hideSidebar}
                            route={sitem.route}
                            count={sitem.count}
                          />
                        )
                      })}
                    </SidebarCategory>
                  )
                } else {
                  return (
                    <SidebarLink
                      key={index}
                      icon={item.icon}
                      title={item.displayTitle}
                      route={item.route}
                      onClick={this.hideSidebar}
                    />
                  );
                }
              }
            })
            : null}
        </ul>
        {adminsitrationList > 0 ?
          <h5 style={{ marginLeft: "20px", color: "white" }}>Administration</h5>
          : null}
        <ul className="sidebar__block">
          {menuList && menuList.length > 0
            ? menuList.map((item, index) => {
              if (item.group === "Administration") {
                if (item.submenus && item.submenus.length > 0) {
                  return (
                    <SidebarCategory
                      key={index}
                      title={item.displayTitle === 'Settings' ? 'Settings Menu' : item.displayTitle}
                      icon={item.icon}
                    >
                      {item.submenus.map((sitem, sindex) => {
                        return (
                          <SidebarLink key={sindex}
                            title={sitem.displayTitle}
                            icon={sitem.icon}
                            onClick={this.hideSidebar}
                            route={sitem.route} />
                        )
                      })}
                    </SidebarCategory>
                  )
                } else {
                  return (
                    <SidebarLink
                      key={index}
                      icon={item.icon}
                      title={item.displayTitle}
                      route={item.route}
                      onClick={this.hideSidebar}
                    />
                  );
                }
              }
            })
            : null}
        </ul>
        <ul className="sidebar__block">
          {menuList && menuList.length > 0 ?
            menuList.map((item, index) => {
              if (item.group === "Profile") {
                if (item.submenus && item.submenus.length > 0) {
                  return (
                    <SidebarCategory
                      key={index}
                      title={item.displayTitle}
                      icon={item.icon}
                    >
                      {item.submenus.map((sitem, sindex) => {
                        return (
                          <SidebarLink key={sindex}
                            title={sitem.displayTitle}
                            icon={sitem.icon}
                            onClick={this.hideSidebar}
                            route={sitem.route}
                            count={sitem.count}
                          />
                        )
                      })}
                    </SidebarCategory>
                  )
                } else {
                  return (
                    <SidebarLink
                      key={index}
                      icon={item.icon}
                      title={item.displayTitle}
                      route={item.route}
                      onClick={this.hideSidebar}
                    />
                  );
                }
              }
            })
            : null}
        </ul>
      </div>
    );
  }
}

export default SidebarContent;

