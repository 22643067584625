import React from 'react';
import store from '../../../App/store';
import apiCalls from '../../../../config/apiCalls'
import DataTables from '../../CommonDataTable/DataTable';
import fetch from '../../../../config/service';
import Loader from '../../../App/Loader';


// config file
export default class DataFeedInclusion extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            type: 'Purposes',
            states: [
                { label: "AL", value: "AL" },
                { label: "AK", value: "AK" },
                { label: "AZ", value: "AZ" },
                { label: "AR", value: "AR" },
                { label: "CA", value: "CA" },
                { label: "CO", value: "CO" },
                { label: "CT", value: "CT" },
                { label: "DE", value: "DE" },
                { label: "FL", value: "FL" },
                { label: "GA", value: "GA" },
                { label: "HI", value: "HI" },
                { label: "ID", value: "ID" },
                { label: "IL", value: "IL" },
                { label: "IN", value: "IN" },
                { label: "IA", value: "IA" },
                { label: "KS", value: "KS" },
                { label: "KY", value: "KY" },
                { label: "LA", value: "LA" },
                { label: "ME", value: "ME" },
                { label: "MD", value: "MD" },
                { label: "MA", value: "MA" },
                { label: "MI", value: "MI" },
                { label: "MN", value: "MN" },
                { label: "MS", value: "MS" },
                { label: "MO", value: "MO" },
                { label: "MT", value: "MT" },
                { label: "NE", value: "NE" },
                { label: "NV", value: "NV" },
                { label: "NH", value: "NH" },
                { label: "NJ", value: "NJ" },
                { label: "NM", value: "NM" },
                { label: "NY", value: "NY" },
                { label: "NC", value: "NC" },
                { label: "ND", value: "ND" },
                { label: "OH", value: "OH" },
                { label: "OK", value: "OK" },
                { label: "OR", value: "OR" },
                { label: "PA", value: "PA" },
                { label: "PR", value: "PR" },
                { label: "RI", value: "RI" },
                { label: "SC", value: "SC" },
                { label: "SD", value: "SD" },
                { label: "TN", value: "TN" },
                { label: "TX", value: "TX" },
                { label: "UT", value: "UT" },
                { label: "VT", value: "VT" },
                { label: "VA", value: "VA" },
                { label: "DC", value: "DC" },
                { label: "WA", value: "WA" },
                { label: "WV", value: "WV" },
                { label: "WI", value: "WI" },
                { label: "WY", value: "WY" },
            ],
            dealTypes: [{ "label": "BK", "value": "BK" }, { "label": "CR", "value": "CR" }],
        };
    }

    static getDerivedStateFromProps(props, state) {
        let storeData = store.getState()
        let languageData = storeData && storeData.settingsData && storeData.settingsData.settings && storeData.settingsData.settings.languageTranslation ? storeData.settingsData.settings.languageTranslation : ""
        return { languageData: languageData };
    }



    componentDidMount = () => {
        this.getDataGroupSources()
    }


    /**
     * 
     * @returns dataArray
     */
    getDataGroupSources = async () => {
        let dataArray = [];
        let filterCriteria = {}
        let loginCredentials = JSON.parse(localStorage.getItem("loginCredentials"));
        let loginRole = loginCredentials.roleName
        // filterCriteria['criteria'] = [{ key: 'activeIndicator', value: true, type: 'eq' }];
        filterCriteria['criteria'] = [{ key: 'activeIndicator', value: true, type: 'eq' }, { key: "roles", value: loginRole, type: "eq" }];
        await fetch('GET', `${apiCalls.DataSource}?type=group&filter=${JSON.stringify(filterCriteria)}`).then(async (response) => {
            if (response && response["dataSources"] && response["dataSources"].length > 0) {
                let propertyStatuses = response["dataSources"]
                for (let status of propertyStatuses) {
                    dataArray.push({ label: status.group, value: status.group })
                }
                this.setState({ dataArray: dataArray })
            }
        })
        return this.state.settingsData;
    }

    /**
     * 
     * @returns tableFields
     */
    getTableFields = () => {
        let data = [
            // {
            //   "show": true,
            //   "textAlign": "center",
            //   "width": 6,
            //   "fieldType": "expander",
            //   "header": "",
            //   "filter": false,
            //   "sortable": false,
            //   "placeholder": "Search"
            // },
            {
                "show": true,
                textAlign: 'left',
                // width: 80,
                field: 'state',
                fieldType: 'BoolText',
                "mobile": true,
                header: 'State',
                "filter": true,
                "displayInSettings": true,
                "displayInFilter": true,
                sortable: true,
            },
            {
                "show": true,
                textAlign: 'left',
                // width: 40,
                field: 'dataSourceGroup',
                "allowInt": true,
                "filterType": 'num',
                "mobile": true,
                header: 'Data Group',
                "filter": true,
                "displayInSettings": true,
                "displayInFilter": true,
                sortable: true,
            },

            {
                "show": true,
                "textAlign": "left",
                // "width": 40,
                "fieldType": 'Actions',
                "field": "Actions",
                "header": "Actions",
                "filter": false,
                "displayInSettings": true,
                "sortable": false
            }
        ]
        return data;
    };

    /**
     * 
     * @returns formFields
     */
    getFormFields = () => {
        return ([

            {
                //'show': false,
                // 'isMultiSelect': true,
                "value": "",
                "type": "dropDown",
                "name": "state",
                "label": "State",
                "options": this.state.states,
                "id": "name",
                "placeholder": "State",
                "required": true
            },

            {
                //'show': false,
                // 'isMultiSelect': true,
                "value": "",
                "type": "dropDown",
                "name": "dataSourceGroup",
                "label": "Data Group",
                "id": "name",
                "options": this.state.dataArray,
                "placeholder": "Data Group",
                "required": true
            },
            {
                //'show': false,
                "value": "",
                "type": "switch",
                "name": "activeIndicator",
                "label": "Active /Inactive Status",
                "id": "name",
                "placeholder": "Name",
                // "required": true
            },
        ]);
    }


    submit = async (item) => {


    }

    render() {
        return (
            <span>
                <Loader loader={this.state.isLoading} />
                <DataTables
                    onRef={(ref) => (this.datatableref = ref)}
                    getTableFields={this.getTableFields}
                    formFields={this.getFormFields}
                    exportRequried={false}
                    printRequried={false}
                    sortField={"state"}
                    addRequried={true}
                    editRequired={true}
                    deleteRequired={false}
                    viewRequired={true}
                    settingsRequired={false}
                    filterRequired={true}
                    gridRequried={false}
                    sample={false}
                    isClearFilters={true}
                    expandTableFields={this.expandTableFields}
                    globalSearchFieldName='user'
                    globalSearch={'Search'}
                    type='Data Feed - Inclusion'
                    displayright={true}
                    icon='user'
                    routeTo='configurations'
                    displayViewOfForm='modal'
                    apiResponseKey='dataFeedInclusions'
                    apiUrl="dataFeedInclusions"
                    entityType='employee'
                />
            </span>
        );
    }
}