import React from 'react';
import { Container } from 'reactstrap';
import { connect } from 'react-redux';
import { Button, Card } from 'reactstrap';
import moment from 'moment-timezone';
import { FormControl } from 'react-bootstrap';
import { Redirect } from 'react-router-dom';
import { Field, reduxForm } from 'redux-form';
import { InputText } from 'primereact/inputtext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { faMinus, faPlus } from '@fortawesome/free-solid-svg-icons';
import { InputSwitch } from 'primereact/inputswitch';
import ExpiredSessionModal from '../Cruds/CommonModals/ExpiredSessionModal';


import apiCalls from '../../config/apiCalls';
import config from '../../config/config';
import fetchMethodRequest from '../../config/service';
import configMessages from '../../config/configMessages';
import { Dropdown } from 'primereact/dropdown';
//import '@atlaskit/css-reset';
import Reload from '../reload'
import { MultiSelect } from 'primereact/multiselect';

// import { DragDropContext } from 'react-beautiful-dnd';
// import Column from './components/CountDisplay';
import DateTimeRangeContainer from 'react-advanced-datetimerange-picker'
import dateFormats from '../../containers/UI/FormatDate/formatDate';
import Loader from '../App/Loader';
import Task from './components/task';
// import Loader from "react-loader-spinner";
import { load as loadAccount } from '../../redux/reducers/commonReducer';
import DefaultInput from '../../shared/components/form/DefaultInput';
import KanbanModal from '../Cruds/CommonModals/KanbanModal';
import ContactNoticeModal from '../Cruds/CommonModals/NoticeModal';
import SessionWarningModal from '../Cruds/CommonModals/SessionWarningModal';
import renderCheckBoxField from '../../shared/components/form/CheckBox';
import NoViewComponent from "../Cruds/CommonDataTable/NoView"

let timeout

export default class Filter extends React.Component {
  constructor(props) {
    super(props);
    let now = new Date();
    let start = moment(new Date(now.getFullYear(), now.getMonth(), now.getDate(), 0, 0, 0, 0));
    let end = moment(start).add(1, "days").subtract(1, "seconds");
    let dueStartDate = moment(new Date(now.getFullYear(), now.getMonth(), now.getDate(), 0, 0, 0, 0));
    let dueEndDate = moment(start).add(1, "days").subtract(1, "seconds");
    this.applyCallback = this.applyCallback.bind(this);
    this.closeMultiSelectDropdown = React.createRef();
    this.state = {
      isM2Disabled: false,
      start: start,
      end: end,
      dueStartDate: dueStartDate,
      dueEndDate: dueEndDate,
      filterFields: [{}],
      filterCriteria: {
        limit: 10, page: 1, criteria: [], sortfield: "created",
        direction: 'desc'
      },
      screenPermissions:"",
      showFilterModal: false,
      filteredCountriesMultiple: null,
      // filterRange: 'All Time',
      selectedStatus: ["Not Started", "In Progress", "Paused", "Completed"],
      isOpenFormModal: false,
      bordercolor: "#20c997",
      borderImageColor: "",
      colors: ["#20c997", "#ffc107", "#fd7e14", "#e83e8c", "#6f42c1", "red"],
      imgcolors: ['#ffe6b3', '#ffb3b3', '#d9b3ff', '#ccddff', '#c6ffb3', '#b3ffb3'],
      deffilterCriteria: {
        limit: 10, page: 1, criteria: [],
        sortfield: "created",
        direction: 'desc'
      },
      // selectedStatus: "",
      sessionWarning: false,
      errorResponse: false,
      loginTasks: true,
      iscontactNoticeModal: false,
      userRole: false,
      perObj: {},
      selectedfilterValue: "",
      selectedassignTo: "",
      selectedcompany: "",
      taskDepartmentItems: [],
      progress: 0,
      value: true,
      unassignedOnly: false,
      isShowunAssign: false
    };
  }

  componentDidMount = async () => {
    let serverError = await localStorage.getItem('serverError')
    let filteredData
    if (serverError === "true") {
      this.getErrorMessage(config.serverErrMessage)
    }
    window.addEventListener("storage", (event) => {
      if (localStorage.getItem("isTaskUpdatedfromTopbar")) {
        localStorage.removeItem("isTaskUpdatedfromTopbar")
        this.getKanbanCards(this.state.filterCriteria);
      }
    });
    Reload.reload()//for testing new Changes are present
    await this.getDepartments()
    // if (document.getElementsByClassName("layout layout--collapse")[0]) {
    // }
    let sessionexpired = await localStorage.getItem('sessionexpired')
    if (sessionexpired === "true") {
      // await this.setState({ sessionExpiryModal: true })
      localStorage.removeItem("sessionexpired")
    }
    let user = JSON.parse(localStorage.getItem('loginCredentials'));
    let DepartmentArray = [], departMentIdArray = []
    // let user = JSON.parse(localStorage.getItem('loginCredentials'));
    if (user && user.department && user.roleName && user.roleName.includes("CoreOps")) {
      DepartmentArray.push(user.department)
      departMentIdArray.push(user.departmentId)
      await this.setState({ selectedDepartment: DepartmentArray, departMentIdArray: departMentIdArray })
    }

    let permissions = localStorage.getItem('rolePermissions');
    if (user) {
      let userName = user.displayName
      if (userName.includes("FINAC".toLowerCase()) || userName.includes("Integrity".toLowerCase())) {
        this.setState({
          isShowunAssign: true,
        })
      }
      if (document.getElementsByClassName("layout layout--collapse").length === 0) {
        document.getElementsByClassName("topbar__button topbar__button--desktop")[0].click();
      }
      if (localStorage.getItem("SearchedCompany")) {
        localStorage.removeItem("SearchedCompany")
      }
      let result = user.roleName.toLowerCase();
      let excludeStr = "Search"
      if (result === "analyst") {
        this.setState({
          iscontactNoticeModal: true
        })
      } else if (result.includes("manager")) {
        if (result.includes(excludeStr.toLowerCase())) {
          await this.setState({ showSiteDiscoveryCards: true, isShowunAssign: false })
        }
        await this.setState({ userRole: true })
      } else if (result === "admin" || result === "leadership") {
        await this.setState({ userRole: true })
      } else {
        if (result.includes(excludeStr.toLowerCase())) {
          await this.setState({ showSiteDiscoveryCards: true, isShowunAssign: true })
        }
        await this.setState({ userRole: false })
      }
      if (!this.state.iscontactNoticeModal) {
        this.setState({
          prefObj: user.preferences,
          screenPermissions: permissions ? JSON.parse(permissions)["Task View"] : "NoView"

        })
        console.log("sdfc",JSON.parse(permissions)["Task View"] )
        if (filteredData) {
          await this.loadFilters(filteredData)
          this.setState({
            filterCriteria: filteredData
          })
          this.getKanbanCards(filteredData)
        } else {
          this.getKanbanCards()
        }

      }
    } else {
      await this.setState({ sessionExpiryModal: true })
    }
  }


  loadFilters = async (filters) => {
    let filteredData = filters.criteria

    let companyIndex = filteredData.findIndex(obj => obj.key === "company")
    if (companyIndex !== -1) {
      this.setState({
        selectedcompany: filteredData[companyIndex].value
      })
      document.getElementById("bussiness").value = filteredData[companyIndex].value
    }
    let analystIndex = filteredData.findIndex(obj => obj.key === "assignTo")
    if (analystIndex !== -1) {
      this.setState({
        selectedassignTo: filteredData[analystIndex].value,

      })
    }

    if (localStorage.getItem("selectedFlagfilters")) {
      let teamFilter = JSON.parse(localStorage.getItem("selectedFlagfilters"))
      localStorage.removeItem("selectedFlagfilters")
      if (teamFilter && teamFilter.departMentIdArray && teamFilter.departMentIdArray.length > 0) {
        this.setState({
          departMentIdArray: teamFilter.departMentIdArray,
          selectedDepartment: teamFilter.selectedDepartment
        })
      }
      if (teamFilter && teamFilter.createdDateFilter && teamFilter.createdDateFilter.startDate) {
        this.setState({
          startDate: teamFilter.createdDateFilter.startDate,
          endDate: teamFilter.createdDateFilter.endDate,
          filterRange: teamFilter.createdDateFilter.filterRange,
          dateQuery: teamFilter.createdDateFilter.dateQuery
        })
      }
      if (teamFilter && teamFilter.dueDateFilter && teamFilter.dueDateFilter.filterRangedueDate) {
        this.setState({
          startDate: teamFilter.dueDateFilter.startDate,
          endDate: teamFilter.dueDateFilter.endDate,
          filterRangedueDate: teamFilter.dueDateFilter.filterRangedueDate
        })
      }

    }


    let statusFilter = filteredData.findIndex(obj => obj.key === "status")
    if (statusFilter !== -1) {
      this.setState({
        selectedStatus: filteredData[statusFilter].value,
      })
    }
    // let teamFilter = filteredData.findIndex(obj => obj.key === "department")
    // if (teamFilter !== -1) {
    //   this.setState({
    //     selectedDepartment: filteredData[teamFilter].value,
    //   })
    // }
  }
  /*
  Fetch all Departments from the api 
  */
  getDepartments = () => {
    let loginData = localStorage.getItem("loginCredentials");
    let filter = {criteria:[]}
    if (loginData) {
      loginData = JSON.parse(loginData);
      let result = loginData.roleName.toLowerCase();
      if(result.includes("analyst") && result !== "recovery analyst"){
        filter.criteria = [{ key: "id", value: loginData.departmentId, type: "eq" }]
      }
      filter.criteria.push({key: 'activeIndicator', value: true, type: 'eq' })

    }
    let apiUrl;
    return fetchMethodRequest('GET', `departments?filter=${JSON.stringify(filter)}&type=exportToCsv`)
      .then(async (response) => {
        if (response && response.departments) {
          let labels = response.departments;
          let modifiedLabels = [];
          for (let label of labels) {
            modifiedLabels.push({
              label: label.departmentName,
              value: label.departmentName,
              departmentId: label._id

            })
          }
          /* Sorting the response based on the Alphabetical descending*/
          const sorted = modifiedLabels.sort((a, b) => {
            let fa = a.label.toLowerCase(),
              fb = b.label.toLowerCase();
            if (fa < fb) {
              return -1;
            }
            if (fa > fb) {
              return 1;
            }
            return 0;
          });
          await this.setState({
            taskDepartmentItems: sorted
          })
        } else {
          this.setState({
            taskDepartmentItems: []
          });
          // let perObj = this.state.perObj
          if (response) {
            if (response['errorMessage'] === configMessages.warningMessage) {
              this.setState({
                sessionWarning: true
              })
            } else if (response['errorMessage'] === configMessages.tokenMessage || response['errorMessage'] === configMessages.sessionExpired) {
              this.setState({
                sessionExpiryModal: true
              })

            } else if (localStorage.getItem('sessionexpired') === true) {
              let sessionexpired = await localStorage.getItem('sessionexpired')
              if (sessionexpired === "true") {
                await this.setState({ sessionExpiryModal: true })
              }
            }
          }
        }
      }).catch((err) => {
        return err;
      });
  }


  componentWillUnmount() {
    // document.removeEventListener('mousedown', this.handleClickOutside)
  }


  componentDidUpdate() {
    if (this.state.errorResponse) {
      setTimeout(() => this.setState({ errorResponse: false, errorMessage: '' }), 6000);
    }
    if (!this.state.errorResponse && localStorage.getItem('serverError') === "true") {
      this.getErrorMessage(config.serverErrMessage)
      localStorage.removeItem('serverError')
    }
    // if (localStorage.getItem('loggedTime')) {
    //   let loggedTime = localStorage.getItem('loggedTime')
    //   if (timeout) {
    //     clearTimeout(timeout)
    //   }
    //   timeout = setTimeout(() => this.setState({ expiredSessionModal: true, openedMultiTabs: true }), loggedTime);
    // }

  }

  /**
   * 
   * @param {Object} filterCriteria 
   * @param {String} type 
   * @param {Object} dateQue 
   * @returns list Call
   */
  getKanbanCards = async (filterCriteria, type, dateQue) => {
    this.isLoading(true)
    await this.setState({
      progress: 0,
    })
    if (filterCriteria) {
      filterCriteria.page = 1
    }
    let apiUrl;
    let user = JSON.parse(localStorage.getItem('loginCredentials'));
    let prefObj = user && user.preferences ? user.preferences : {}
    if (user.roleName.includes("Manager")) {
      await this.setState({ userRole: true })
    } else if (user.roleName === "Admin" || user.roleName === "LeaderShip") {
      await this.setState({ userRole: true })
    } else {
      await this.setState({ userRole: false })
    }
    if (type === "globalSearch") {
      delete filterCriteria.globalSearch
      await this.setState({ selectedfilterValue: "" })
    }


    if (filterCriteria) {
      if (this.state.filterCriteria && this.state.filterCriteria.criteria) {
        var newArray2 = this.state.filterCriteria.criteria.filter(function (el) {
          return el.key === 'assignToId'
        });

        if (newArray2.length === 0 && this.state.loginTasks) {
          this.state.filterCriteria && this.state.filterCriteria.criteria && this.state.filterCriteria.criteria.push({
            "key": "assignToId",
            "value": user ? user._id : localStorage.getItem('loginCredentialsId'),
            "type": "eq"
          });
        } else if (!this.state.loginTasks && newArray2.length == 1) {
          let index = filterCriteria['criteria'].findIndex(obj => obj.key === "assignToId")
          filterCriteria['criteria'].splice(index, 1)
        }
      }
      if (this.state.showSiteDiscoveryCards && filterCriteria && filterCriteria['criteria'] && filterCriteria['criteria'].findIndex(obj => obj.key === "title") === -1) {
        filterCriteria['criteria'].push({
          "key": "title",
          "value": "Site",
          "type": "regexOr"
        })
      }
      if (filterCriteria && filterCriteria.globalSearch && filterCriteria.globalSearch.value) {
        this.state.filterCriteria.globalSearch = filterCriteria.globalSearch
      }
      if (type === 'dateQuery') {
        apiUrl = `${apiCalls.TasksGrid}&filter=${JSON.stringify(this.state.filterCriteria)}&dateQuery=${JSON.stringify(filterCriteria)}`;
      } else if (type === 'collapse') {
        let criteria = this.state.filterCriteria
        criteria["page"] = 1
        apiUrl = `tasks?preference=${JSON.stringify(filterCriteria)}&view=grid&filter=${JSON.stringify(this.state.filterCriteria)}`
      } else {
        if (this.state.dateQuery || this.props.dateQuery || dateQue) {
          apiUrl = `tasks?preference=${JSON.stringify(prefObj)}&view=grid&filter=${JSON.stringify(this.state.filterCriteria)}&dateQuery=${JSON.stringify(this.state.dateQuery)}`;
        } else {
          apiUrl = `tasks?preference=${JSON.stringify(prefObj)}&view=grid&filter=${JSON.stringify(this.state.filterCriteria)}`;
        }
      }
    } else {
      if (this.state.loginTasks) {
        var newArray = this.state.filterCriteria.criteria.filter(function (el) {
          return el.key === 'assignToId'
        });
        if (newArray.length === 0) {
          this.state.filterCriteria.criteria.push({
            "key": "assignToId",
            "value": user ? user._id : localStorage.getItem('loginCredentialsId'),
            "type": "eq"
          });
        }
        if (this.state.filterCriteria['criteria'].findIndex(obj => obj.key === "status") === -1) {
          this.state.filterCriteria['criteria'].push({
            "key": "status",
            "value": this.state.selectedStatus,
            "type": "in"
          })
        }
        let filterCriteria = this.state.filterCriteria
        if (this.state.showSiteDiscoveryCards && filterCriteria && filterCriteria['criteria'] && filterCriteria['criteria'].findIndex(obj => obj.key === "title") === -1) {
          filterCriteria['criteria'].push({
            "key": "title",
            "value": "Site",
            "type": "regexOr"
          })
        }

        if (this.state.dateQuery) {
          apiUrl = `tasks?preference=${JSON.stringify(prefObj)}&view=grid&filter=${JSON.stringify(this.state.filterCriteria)}&dateQuery=${JSON.stringify(this.state.dateQuery)}`;
        } else {
          apiUrl = `tasks?preference=${JSON.stringify(prefObj)}&view=grid&filter=${JSON.stringify(this.state.filterCriteria)}`;
        }
      } else {
        apiUrl = `tasks?preference=${JSON.stringify(prefObj)}&view=grid`
      }
    }
    if (this.state.unassignedOnly) {
      apiUrl = `${apiUrl}&isUnassigned=true`
    }
    if (this.state.selectedDepartment && this.state.selectedDepartment.length > 0) {
      apiUrl = `${apiUrl}&selectedDepartment=${encodeURIComponent(this.state.departMentIdArray.join(", "))}`
    }
    return fetchMethodRequest('GET', apiUrl)
      .then(async (response) => {
        if (response && response.tasks) {
          this.setState({
            ColumnOrder: response.tasks,
            preferences: response.preference,
            perObj: response.preference,

          });
        } else {
          this.setState({
            ColumnOrder: []
          });
          // let perObj = this.state.perObj
          if (response) {
            if (response['errorMessage'] === configMessages.warningMessage) {
              this.setState({
                sessionWarning: true
              })
            } else if (response['errorMessage'] === configMessages.tokenMessage || response['errorMessage'] === configMessages.sessionExpired) {
              this.setState({
                sessionExpiryModal: true
              })

            } else if (localStorage.getItem('sessionexpired') === true) {
              let sessionexpired = await localStorage.getItem('sessionexpired')
              if (sessionexpired === "true") {
                await this.setState({ sessionExpiryModal: true })
              }
            }


          }
        }
        this.getFormatData(this.state.ColumnOrder)

      }).catch((err) => {
        return err;
      });
  }


  /**
   *   Formating the data like adding the immage field and splicing and changed the States to shortcut format
   */
  getFormatData = async () => {
    let ColumnOrder = this.state.ColumnOrder
    if (ColumnOrder && ColumnOrder.length > 0) {
      for (let task in ColumnOrder) {
        for (let t in ColumnOrder[task]) {
          if (t === 'tasks') {
            for (let solo in ColumnOrder[task][t]) {
              let statecp = []
              for (let sine in ColumnOrder[task][t][solo]['states']) {
                statecp.push(ColumnOrder[task][t][solo]['states'][sine].slice(-2))
              }
              ColumnOrder[task][t][solo]['statecp'] = statecp.join(', ')
              let str = ColumnOrder[task][t][solo].assignTo;
              let matches = str.match(/\b(\w)/g).join('');
              if (matches && matches.length > 2) {
                let result = matches.slice(0, 2);
                ColumnOrder[task][t][solo]['imageIcon'] = result
              } else {
                ColumnOrder[task][t][solo]['imageIcon'] = matches
              }
              if (ColumnOrder[task][t][solo].assignImage) {
              } else {
                // let borderbgIndex = this.state.imgcolors.indexOf(this.state.borderImageColor)
                // if (borderbgIndex === -1 || borderbgIndex === 5) {
                //   await this.setState({
                //     borderImageColor: this.state.imgcolors[0]
                //   })
                // } else {
                //   let borderbgIndex = this.state.imgcolors.indexOf(this.state.borderImageColor)
                //   this.setState({
                //     borderImageColor: this.state.imgcolors[borderbgIndex + 1]
                //   })
                // }
              }
              ColumnOrder[task][t][solo]['imgbgcolor'] = "antiquewhite"
              // ColumnOrder[task][t][solo]['toggleStatus'] = ColumnOrder[task][t][solo].assignImage === "start" ? true : false
            }
          }
        }
      }
      /*
          for column top border colors*/
      for (let i = 0; i < ColumnOrder.length; i++) {
        if (i === 0 || i % 6 === 0) {
          let bordercolor = this.state.bordercolor
          ColumnOrder[i].color = bordercolor
          this.setState({
            bordercolor: bordercolor
          })
        } else if (i > 0) {
          let borderIndex = ColumnOrder[i - 1].color
          let stateColor = this.state.colors
          let borderIndex2 = stateColor.indexOf(borderIndex)
          ColumnOrder[i].color = stateColor[borderIndex2 + 1]
        }
        this.setState({
          ColumnOrder: ColumnOrder,
          progress: 100,
          // permissionsObj: permissionsObj
        })
        this.isLoading(false)
      }
    }
  }


  /**
   * 
   * @param {Object} column 
   * @returns Tasks array with pagination
   * increment by +1 when click on View more in every column
   */

  getMoreCards = (column) => {
    this.isLoading(true)
    let deffilterCriteria = this.state.filterCriteria
    let tasksLength = parseInt(column.tasks.length / 10);
    deffilterCriteria["page"] = tasksLength + 1
    let apiUrl
    if (column.purpose.length > 0) {
      apiUrl = `tasks?preference=${JSON.stringify(this.state.prefObj)}&view=grid&column={"label":"${column._id}","purpose":"${column.purpose}"}&filter=${JSON.stringify(deffilterCriteria)}`
    } else {
      apiUrl = `tasks?preference=${JSON.stringify(this.state.prefObj)}&view=grid&column={"label":"${column._id}"}&filter=${JSON.stringify(deffilterCriteria)}`
    }
    if (this.state.unassignedOnly) {
      apiUrl = `${apiUrl}&isUnassigned=true`
    }
    return fetchMethodRequest('GET', apiUrl)
      .then(async (response) => {
        if (response && response.tasks) {
          let allTasks = this.state.ColumnOrder;
          let data = response.tasks[0];
          for (var obj of allTasks) {

            if ((obj._id === data._id) && (obj.purpose === data.purpose)) {
              let tasksdata = [...obj.tasks, ...data.tasks]
              obj.tasks = tasksdata
            }
            this.setState({
              ColumnOrder: allTasks,
              progress: 0
            })
            this.getFormatData(this.state.ColumnOrder)
          }
        } else {
          if (response && (response['errorMessage'] === configMessages.warningMessage)) {
            this.setState({
              sessionWarning: true,
              ColumnOrder: []
            })
          }
          else if (response && response['errorMessage'] === configMessages.tokenMessage) {
            this.setState({
              sessionExpiryModal: true,
              ColumnOrder: []
            })
          }
        }
      }).catch((err) => {
        return err;
      });
  }


  /**
   * 
   * @param {String} columnName 
   * @param {Object} e 
   *  toggle switch when click expand or collapse (+) in every column
   *  when it is expand it will group based on label and purpose
   * if it collapse it will group based on label
   */
  toggle = async (columnName, e) => {
    if (Object.keys(e).length > 0) {
      e[columnName] = e[columnName] === "expand" ? "collapse" : "expand"
      await this.setState({
        perObj: e
      })
    } else {
      let perObj = this.state.perObj
      perObj[columnName] = perObj[columnName] === "expand" ? "collapse" : "expand"
      await this.setState({
        perObj: perObj
      })
    }
    this.getKanbanCards(this.state.perObj, 'collapse')
  }

  submit = () => { }
  /**
   * 
   * @param {string} errorMessage 
   * to set the errormessage string
   */
  getErrorMessage = (errorMessage) => {
    this.setState({
      errorMessage: errorMessage,
      errorResponse: true
    })
  }


  /**
   * 
   * @param {Object} e 
   * @param {String} type 
   * @param {String} columnValue 
   * to open add or edit form
   */
  openKanbanModal = async (e, type, columnValue) => {
    e.preventDefault();
    await this.setState({
      isOpenFormModal: true,
      type: type,
      columnValue: columnValue ? columnValue : "Not Started"
    })
  }

  cancelReset = () => {
    this.setState({
      isOpenFormModal: false,
    })
  }


  /**
   *   resetting the additional filters given in the top
   */
  reset = async () => {
    // this.props.reset()
    // this.props.load({})
    await this.setState({
      filterRange: '',
      dateQuery: "",
      selectedassignTo: "",
      selectedDepartment: "",
      filterRangedueDate: '',
      selectedcompany: "",
      selectedStatus: ["Not Started", "In Progress", "Paused", "Completed"],
    })
    this.props.reset()
    this.props.load({})
    let user = JSON.parse(localStorage.getItem('loginCredentials'));
    let DepartmentArray = [], departMentIdArray = []
    // let user = JSON.parse(localStorage.getItem('loginCredentials'));
    if (user && user.department && user.roleName && user.roleName.includes("CoreOps")) {
      DepartmentArray.push(user.department)
      departMentIdArray.push(user.departmentId)
    }
    this.setState({ selectedDepartment: DepartmentArray.length > 0 ? DepartmentArray : null, departMentIdArray: departMentIdArray.length > 0 ? departMentIdArray : null })

    let keys = ["company", "assignTo", "status", "department", "dueDate"]
    if (this.state.filterCriteria.criteria &&
      this.state.filterCriteria.criteria.length > 0) {
      keys.forEach((itemKey) => {
        for (let i = 0; i < this.state.filterCriteria.criteria.length; i++) {
          let crit = this.state.filterCriteria.criteria
          if (itemKey === crit[i].key) {
            crit.splice(i, 1)
          }
        }
        let criteria = this.state.filterCriteria.criteria
        criteria = criteria.filter((function (el) {
          return el.key !== 'dueDate'
        }))
        this.state.filterCriteria.criteria = criteria
      })

      // delete this.state.filterCriteria.globalSearch
    }
    this.state.filterCriteria['criteria'].push({
      "key": "status",
      "value": ["Not Started", "In Progress", "Paused", "Completed"],
      "type": "in"
    })
    this.getKanbanCards(this.state.filterCriteria);
  }

  /**
   * 
   * @param {Object} e 
   * @param {String} key 
   * @param {string} selectedtype 
   * setting filter to get filtered Results
   */
  onFilterChange = async (e, key, selectedtype) => {
    if (e && e.persist) {
      e.persist();
    }
    let val
    this.setState({
      selectedKey: e.key === "Enter" ? true : false,
    })
    if (key === 'status') {
      this.setState({
        selectedStatus: e.value,
      })
      val = e.value
    } else {
      val = e.target.value
      if (key === 'assignTo') {
        this.setState({
          selectedassignTo: e.value,
        })
      } else if (key === 'company') {
        await this.setState({
          selectedcompany: e.value,
        })
      } else if (key === 'department') {
        await this.setState({
          selectedDepartment: e.value,
        })
      }
    }
    if (key === "company" || key === 'assignTo' || val === "department") {
      val = encodeURIComponent(val)
    }
    let filtercriteria = {
      "key": key,
      "value": val,
      "type": selectedtype
    }
    let crit = this.state.filterCriteria.criteria
    if (crit &&
      crit.length > 0) {
      for (let i = 0; i < crit.length; i++) {
        if ((crit[i].key === filtercriteria.key)) {
          crit.splice(i, 1)
        }
      }
    }

    crit.push(filtercriteria)
    let isExists = crit.findIndex(obj => obj.key === "status")
    if (isExists > -1) {
      if (crit[isExists].value.length === 0) {
        crit.splice(isExists, 1)
      }
    }
    if (key !== "status") {
      if (this.state.selectedKey) {
        this.getKanbanCards(this.state.filterCriteria);
      }
    } else {
      this.getKanbanCards(this.state.filterCriteria);
    }
    // else {
    //   this.getKanbanCards(this.state.filterCriteria);
    // }

  }

  /**
   * 
   * @returns ContactNoticeModal
   *  invokes when user role is Analyst
   */
  getContactNoticeModal() {
    return (
      <ContactNoticeModal
        isNoticeModal={this.state.iscontactNoticeModal}
      />
    )
  }
  cancelexpiredSessionModal = async () => {
    await this.setState({
      expiredSessionModal: false
    })
  }
  expiredSessionModal() {
    return (
      <ExpiredSessionModal
        openConfirmationModal={this.state.expiredSessionModal}
        cancelSessionWarningModal={this.cancelexpiredSessionModal}
        getData={this.getDataFromServer}

      />
    )
  }

  /**
   *   returns the Add tas Modal./
*/
  getkanbanModal() {

    let departmentArr = {
      departMentIdArray: this.state.departMentIdArray,
      selectedDepartment: this.state.selectedDepartment,
      createdDateFilter: { startDate: this.state.startDate, endDate: this.state.endDate },
      dueDateFilter: { startDate1: this.state.startDate1, endDate1: this.state.endDate1 }
    }
    return (
      <KanbanModal
        openUserPasswordResetModal={this.state.isOpenFormModal}
        cancelReset={this.cancelReset}
        getKanbanCards={this.getKanbanCards}
        filterData={this.state.filterCriteria}
        dateQuery={this.state.dateQuery}
        type={this.state.type}
        columnValue={this.state.columnValue}
        unassignedOnly={this.state.unassignedOnly}
        getErrorMessage={this.props.getErrorMessage}
        departmentArr={departmentArr}
      />
    )
  }

  cancelSessionWarningModal = async () => {
    await this.setState({
      sessionWarning: false
    })
  }


  /*
  *Counter to show percentage
*/
  handlePercentage = async () => {
    let counter = 0;
    const interval = setInterval(async () => {
      if (this.state.isLoading) {
        counter = counter + 15;
        await this.setState({
          progress: counter,
        })
      } else {
        if (!this.state.isLoading) {
          clearInterval(interval);
        }
      }
    }, 100);
  }

  //stay signed in Modal
  getSessionWraningModal() {
    return (
      <SessionWarningModal
        openConfirmationModal={this.state.sessionWarning}
        cancelSessionWarningModal={this.cancelSessionWarningModal}
        getData={this.getKanbanCards}
      />
    )
  }

  onClick = (e) => {
  }

  /**
   * 
   * @param {Object} startDate 
   * @param {Object} endDate 
   *   When click on task Created date filter
   */
  applyCallback(startDate, endDate) {
    let dateStartRange = startDate.toISOString()
    let dateEndRange = endDate.toISOString()
    var d1 = new Date(dateFormats.formatDate(new Date(startDate), 'MM-DD-YYYY'))
    var d2 = new Date(dateFormats.formatDate(new Date(endDate), 'MM-DD-YYYY'))

    var startDate1 = moment(d1, "MM-DD-YYYY");
    var endDate2 = moment(d2, "MM-DD-YYYY");
    var result = endDate2.diff(startDate1, 'days');
    var filterRange = result === 0 ? `${dateFormats.formatDate(new Date(startDate), 'MM-DD-YYYY')}` : `${dateFormats.formatDate(new Date(startDate), 'MM-DD-YYYY')} to ${dateFormats.formatDate(new Date(endDate), 'MM-DD-YYYY')} `

    let dateQuery = {
      'startDate': dateStartRange, 'endDate': dateEndRange, type: this.state.range
    }
    let departmentArr = {
      departMentIdArray: this.state.departMentIdArray,
      selectedDepartment: this.state.selectedDepartment,
      createdDateFilter: { startDate: startDate1, endDate: endDate2, dateQuery: dateQuery, filterRange: filterRange },
      dueDateFilter: { startDate1: this.state.dueStartDate, endDate1: this.state.dueEndDate },
    }
    this.setState({
      start: startDate,
      end: endDate,
      departmentArr: departmentArr,
      filterRange: filterRange
    }
    )

    this.setState({
      dateQuery: dateQuery
    })
    this.getKanbanCards(dateQuery, 'dateQuery');
  }


  /**
   * 
   * @param {Object} startDate 
   * @param {Object} endDate 
   *  When click on task Due date filter
   */
  applyCallbackfordueDate = async (startDate, endDate) => {
    var d1 = moment(startDate).format(config.dbOnlyDateFormat);
    var d2 = moment(endDate).format(config.dbDateFormat);
    var filterRangedueDate = this.state.range === "Overdue" ? `${dateFormats.formatDate(new Date(startDate), 'MM-DD-YYYY')}` : `${dateFormats.formatDate(new Date(startDate), 'MM-DD-YYYY')} to ${dateFormats.formatDate(new Date(endDate), 'MM-DD-YYYY')} `

    let departmentArr = {
      departMentIdArray: this.state.departMentIdArray,
      selectedDepartment: this.state.selectedDepartment,
      createdDateFilter: { startDate: this.state.startDate, endDate: this.state.endDate },
      dueDateFilter: { startDate1: startDate, endDate1: endDate, filterRangedueDate: filterRangedueDate }
    }
    await this.setState({
      departmentArr: departmentArr,
      dueStartDate: startDate,
      dueEndDate: endDate,
      filterRangedueDate: filterRangedueDate
    })



    let criteria = this.state.filterCriteria;
    let filtercriteria = criteria.criteria.filter(function (el) {
      return el.key !== "dueDate"
    });
    criteria.criteria = filtercriteria
    if (this.state.range !== "Overdue") {
      criteria.criteria.push({
        key: "dueDate",
        value: d1,
        type: "gte"
      })
      criteria.criteria.push({
        key: "dueDate",
        value: d2,
        type: "lt"
      })
    } else {
      criteria.criteria.push({
        key: "dueDate",
        value: d2,
        type: "lt"
      })
    }

    this.getKanbanCards(criteria);

  }
  /**
   * 
   * @param {*} i 
   * @param {ranges} v 
   * setting the selected Range
   */
  rangeCallback = (i, v) => {
    this.setState({
      range: v
    })
  }
  /**
   * 
   * @param {boolean} value 
   * to show loader 
   */
  isLoading = async (value) => {
    await this.setState({
      isLoading: value
    })
    await this.handlePercentage('1')
  }

  /**
   * 
   * @param {Object} e 
   * Adding the globalsearch
   */
  onFilterChangeglobalSearch(e) {
    let filterCriteria = this.state.filterCriteria
    if (e.key === "Enter") {
      if (e.target.value && e.target.value.length > 1) {
        filterCriteria.globalSearch = {
          value: encodeURIComponent(e.target.value),
          type: "regexOr"
        };
        this.getKanbanCards(filterCriteria)
      } else {
        delete filterCriteria.globalSearch
        this.getKanbanCards(filterCriteria)
      }
    }
  }

  /**
   * 
   * @param {Object} e 
   * handle to see check all users or only his tasks
   */
  handleCheckboxChange = async (e) => {
    await this.setState({
      loginTasks: this.state.loginTasks === true ? false : true
    })
    // if (this.state.loginTasks) {
    //   this.getKanbanCards(this.state.filterCriteria)
    // } else {

    //   for (var i = 0; i < this.state.filterCriteria.criteria.length; i++) {
    //     if (this.state.filterCriteria.criteria[i].key === 'assignToId') {
    //       this.state.filterCriteria.criteria.splice(i, 1);
    //     }
    //   }
    this.getKanbanCards(this.state.filterCriteria, 'allTasks')
    // }
  }

  /**
   * 
   * @param {Object} e 
   * to see Unassigned Cards or Not
   */
  handleunassigned = async (e) => {
    await this.setState({
      unassignedOnly: this.state.unassignedOnly === true ? false : true
    })
    this.getKanbanCards(this.state.filterCriteria)
  }

  /**
   * 
   * @param {{
   *  originalEvent: Event;
   *  value: any;
   * }}
   * handle the Department change
   */
  onChangeDepartment = async (e) => {
    let DepartmentArray = [], departmentIdArray = []
    for (let id of e.value) {
      let z = this.state.taskDepartmentItems.find(elem => elem.value === id)
      if (z) {
        DepartmentArray.push(z.department)
        departmentIdArray.push(z.departmentId)
      }
    }
    let departmentArr = {
      departMentIdArray: departmentIdArray,
      selectedDepartment: e.value
    }
    this.setState({ departMentIdArray: departmentIdArray, selectedDepartment: e.value, departmentArr: departmentArr })
    // await this.setState({
    //   selectedDepartment: e.value,
    // })

    await this.getKanbanCards(this.state.filterCriteria)
  }

  render() {
    const { handleSubmit } = this.props;
    const { ColumnOrder } = this.state;
    let now = new Date();
    let month = now.getMonth()
    let year = now.getFullYear()
    let start = moment(new Date(now.getFullYear(), now.getMonth(), now.getDate(), 0, 0, 0, 0));
    let end = moment(start).add(1, "days").subtract(1, "seconds");
    let dueStartDate = moment(new Date(now.getFullYear(), now.getMonth(), now.getDate(), 0, 0, 0, 0));
    let dueEndDate = moment(start).add(1, "days").subtract(1, "seconds");
    //number of days in last month
    let y = new Date(year, month - 1, 0).getDate();
    let t = now.getDate() + y + 2;
    let ranges = {
      "Today Only": [moment(start), moment(end)],
      "Yesterday Only": [moment(start).subtract(1, "days"), moment(end).subtract(1, "days")],
      "This Week(sun-Today)": [moment(start).subtract(now.getDay(), "days"), moment(end)],
      "Last 7 days": [moment(start).subtract(7, "days"), moment(end).subtract(1, "days")],
      "Last Week(Sun-Sat)": [moment(start).subtract(now.getDay() + 7, "days"), moment(start).subtract(now.getDay() + 1, "days")],
      "Last 14 days": [moment(start).subtract(14, "days"), moment(end).subtract(1, "days")],
      "This month": [moment(start).subtract((now.getDate() - 1), "days"), moment(end)],
      "Last 30 days": [moment(start).subtract(30, "days"), moment(end).subtract(1, "days")],
      "Last month": [moment(start).subtract(t, "days"), moment(start).subtract(now.getDate(), "days")],
      "Last 3 Days": [moment(start).subtract(3, "days"), moment(end).subtract(1, "days")],
      "30 Days upto today": [moment(start).subtract(29, "days"), moment(end)],
      "All Time": [moment(start), moment(end)]
    }
    var next = new Date(now.getFullYear(), now.getMonth(), now.getDate() + (7 - now.getDay()));
    var getDate = new Date(next.setDate(next.getDate() + 6));

    var firstDay = new Date(now.getFullYear(), now.getMonth() + 1, 1);
    var lastDay = new Date(now.getFullYear(), now.getMonth() + 2, 0);

    let dueRanges = {
      "Overdue": [moment(dueStartDate), moment(dueEndDate)],
      "Due in the Next Day": [moment(dueStartDate).add(1, "days"), moment(dueEndDate).add(1, "days").subtract(1, "seconds")],
      "Due in the Next Week": [moment(new Date(now.getFullYear(), now.getMonth(), now.getDate() + (7 - now.getDay()))), moment(getDate).add(1, "days").subtract(1, "seconds")],
      "Due in the Next Month": [moment(firstDay), moment(lastDay).add(1, "days").subtract(1, "seconds")],
      // "Due in the Next Month": [moment(firstDay), moment()],
    }
    let departmentArr = {
      departMentIdArray: this.state.departMentIdArray,
      selectedDepartment: this.state.selectedDepartment,
      createdDateFilter: { startDate: this.state.start, endDate: this.state.end, dateQuery: this.state.dateQuery, filterRange: this.state.filterRange },
      dueDateFilter: { startDate1: this.state.dueStartDate, endDate1: this.state.dueEndDate, filterRangedueDate: this.state.filterRangedueDate }
    }

    let local = {
      "format": "MM-DD-YYYY HH:mm",
    }
    let maxDate = moment(end);
    return (
      (this.state.screenPermissions==="Edit" ||this.state.screenPermissions==="View") ?
        <>
          <Loader loader={this.state.isLoading} progress={this.state.progress} className="progress-loader"
          />
          <Container className="dashboard" style={{ width: '100%', height: '100%', padding: "15px" }}>

            <div className="dashboardCard" style={{ height: 'calc(100vh - 92px)' }}>
              <div style={{
                height: 155
              }}>
                <div className="mt-2 P-5">
                  <div  >
                    <form className="form " autoComplete={'off'} style={{ display: 'inherit' }} onSubmit={handleSubmit(this.submit)}>
                      <div className="row newCardBtn mt-2 mb-2 ml-auto" style={{ paddingRight: 5, paddingTop: 5 }}>
                        <div className="topbar__left">
                          <span ><FontAwesomeIcon className={`tableheadericon ml-3`} icon={"grip-horizontal"} /></span>
                          <h4 className="Kanban_title_card " style={{ width: 116 }}>Task View</h4>
                          <div style={this.state.userRole? { display: "block" } : { display: "none" }}>
                            <div className="">
                              <Field
                                label='Only me'
                                type='checkbox'
                                id="Only_me"
                                component={renderCheckBoxField}
                                name={'Only_me'}
                                className="analystCheckbox"
                                checked={this.state.loginTasks}
                                onChange={(e) => this.handleCheckboxChange(e)}
                              />
                            </div>
                          </div>
                          <div className='ml-3'>
                            <span><b>Condensed View</b></span>
                          </div>
                          <div className='ml-3'>
                            <InputSwitch checked={this.state.value} onChange={(e) => this.setState({ value: e.value })} />
                          </div>

                        </div>

                        {/* {this.state.errorResponse ? this.state.errorMessage : null} */}
                        <span className="topbar__center errorResponse" >{this.state.errorResponse ? this.state.errorMessage : null}</span>
                        <div className="topbar__right" style={{ marginRight: "40px", height: 0 }}>
                          <span className="p-input-icon-left">
                            <i className="pi pi-search" />
                            <span className="p-input-icon-right">
                              <InputText
                                type="search"
                                // value={this.state.selectedfilterValue}
                                name='globalSearch'
                                id='globalSearch'
                                style={{ height: 34, paddingLeft: "2.5rem" }}
                                placeholder={"Identifier/Label/Purpose/Priority/Claim Id"}
                                onKeyPress={(e) => this.onFilterChangeglobalSearch(e, '', 'globalSearch')}
                                onChange={(e) => this.onFilterChangeglobalSearch(e, '', 'globalSearch')}
                                size={"25"} />
                            </span>

                          </span>
                          <div className="text-right ml-3">
                            <Button className="btn-sm mb-0 ml-auto" style={this.state.userRole &&this.state.screenPermissions==="Edit" ? { padding: "5px 10px" } : { display: "none" }}
                              color="primary" outline onClick={(e) => this.openKanbanModal(e, 'add')}
                            >+ Create Task</Button>
                          </div>
                        </div>
                      </div>
                      <div className="kanbanfilterDiv">
                        <div className="row filterKanbandiv" >
                          <div className=" align-items-center pl-0" style={{ display: "inline-flex", width: "100%" }}>
                            <div className="kanban_filter_field" >
                              <label className="mr-1 kanban_filter_label" style={{ marginTop: 4 }}>
                                Business:
                              </label>
                              <div className="" >
                                <InputText
                                  name="bussiness"
                                  id="bussiness"
                                  component={DefaultInput}
                                  value={this.state.selectedcompany}
                                  onKeyPress={(e) => this.onFilterChange(e, 'company', 'regexOr')}
                                  onChange={(e) => this.onFilterChange(e, 'company', 'regexOr')}
                                  size={"25"} />
                              </div>
                            </div>
                            <div className="kanban_filter_field">
                              <div className="d-flex">
                                <label className="mr-auto kanban_filter_label" style={{ marginTop: 4 }}>
                                  Analyst:
                                </label>
                                <div style={!this.state.isShowunAssign ? { display: "block" } : { display: "none" }}>
                                  <div className="">
                                    <Field
                                      label='Unassigned Only'
                                      id='Unassigned Only'
                                      type='checkbox'
                                      component={renderCheckBoxField}
                                      name={'unassigned'}
                                      className="unassignCheckbox mb-0"
                                      checked={this.state.unassignedOnly}
                                      onChange={(e) => this.handleunassigned(e)}
                                    />
                                  </div>
                                </div>
                              </div>
                              <div className="">
                                <InputText
                                  name="analyst"
                                  id="analyst"
                                  component={DefaultInput}
                                  value={this.state.selectedassignTo}
                                  // style={{ height: 34 }}
                                  // placeholder={"Identifier/Label/Purpose/Priority"}
                                  onChange={(e) => this.onFilterChange(e, 'assignTo', 'regexOr')}
                                  onKeyPress={(e) => this.onFilterChange(e, 'assignTo', 'regexOr')}
                                  size={"25"} />
                              </div>
                            </div>
                            <div className="kanban_filter_field" id='department'>
                              <label className="mr-1 kanban_filter_label" id="department" style={{ marginTop: 4 }}>
                                Team:
                              </label>
                              <MultiSelect
                                id="department"
                                className={"form__form-group-field "}
                                style={{ width: "100%", height: 32 }}
                                value={this.state.selectedDepartment}
                                filter={true}
                                options={this.state.taskDepartmentItems}
                                onChange={(e) => this.onChangeDepartment(e)}
                                placeholder=''
                              />
                            </div>
                            <div className="kanban_filter_field" id="task_status">
                              <label className="mr-1 kanban_filter_label" id="task_status" style={{ marginTop: 0 }}>
                                Task Status:
                              </label>
                              <div className="form__form-group-input-wrap">
                                <MultiSelect
                                  id="task_status"
                                  className={"form__form-group-field "}
                                  style={{ width: "100%", height: 32 }}
                                  value={this.state.selectedStatus}
                                  options={config.taskFilterItems}
                                  filter={true}
                                  onChange={(e) => this.onFilterChange(e, 'status', 'in')}
                                  placeholder=''
                                />
                              </div>
                            </div>
                            <div className="kanban_filter_field" id="created_date">
                              <label className="mr-1 kanban_filter_label">
                                Task Created Date:
                              </label>
                              <DateTimeRangeContainer
                                ranges={ranges}
                                start={this.state.start}
                                end={this.state.end}
                                local={local}
                                maxDate={maxDate}
                                // enableButtons={true}
                                onClick={this.onClick}
                                onChange={this.applyCallback}
                                applyCallback={this.applyCallback}
                                rangeCallback={this.rangeCallback}
                                leftMode
                                noMobileMode
                                style={{
                                  customRangeButtons: { backgroundColor: "#357ABD" },
                                  customRangeSelected: { backgroundColor: "#357ABD" },

                                }}
                              >
                                <FormControl
                                  id="formControlscreatedDate"
                                  type="text"
                                  label="Text"
                                  placeholder="Task Created Date"
                                  style={{ cursor: "pointer" }}
                                  // disabled={true}
                                  className={"dateFilterForm mb-0"}
                                  value={this.state.filterRange}
                                />
                              </DateTimeRangeContainer>

                            </div>
                            <div className="kanban_filter_field">
                              <label className="mr-1 kanban_filter_label">
                                Due Date:
                              </label>
                              <DateTimeRangeContainer
                                ranges={dueRanges}
                                start={this.state.dueStartDate}
                                end={this.state.dueEndDate}
                                local={local}
                                // maxDate={maxDate}
                                // enableButtons={true}

                                onClick={this.onClick}
                                onChange={this.applyCallbackfordueDate}
                                applyCallback={this.applyCallbackfordueDate}
                                rangeCallback={this.rangeCallback}
                                leftMode
                                noMobileMode
                                style={{
                                  customRangeButtons: { backgroundColor: "#357ABD" },
                                  customRangeSelected: { backgroundColor: "#357ABD" },

                                }}
                              >
                                <FormControl
                                  id="formControlsTextDuedate"
                                  type="text"
                                  label="Text"
                                  placeholder="Due Date"
                                  style={{ cursor: "pointer" }}
                                  // disabled={true}
                                  className={"dateFilterForm mb-0"}
                                  value={this.state.filterRangedueDate}
                                />
                              </DateTimeRangeContainer>

                            </div>
                            <div className="" id="clear">
                              <span className=" " style={{ cursor: "pointer" }}>
                                <FontAwesomeIcon
                                  icon={faTimes}
                                  id="clear"
                                  style={{
                                    width: 16,
                                    height: 16,
                                    margin: 5,
                                    marginTop: 15,
                                  }}
                                  color="red"
                                  onClick={(e) => this.reset(e)}
                                />
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
              <div className="kanban_drag mt-2">
                {/* <DragDropContext onDragEnd={this.onDragEnd} > */}
                {ColumnOrder ?
                  <div className="col-sm-12 kanban_drag_column">
                    {
                      ColumnOrder && ColumnOrder.length && ColumnOrder.length > 0 ?
                        ColumnOrder.map((columnId, ind) => (

                          // Object.keys(this.state.perObj).length > 0 ?
                          < div className=" col-12 col-md-6 col-lg-4 col-xl-3 pl-1 pr-1" >
                            <div className="pl-0 pr-0 m-1 mt-0 mb-0 ml-0">
                              <Card className="kanbanColumn" style={{ borderTop: `5px solid  ${columnId.color}` }}>
                                <div style={{ paddingRight: 15 }}>
                                  <div className="row ml-0 mr-0" >
                                    <span className="kanbanTitle" style={{ width: "92%" }}>{columnId._id}
                                      <span className="ml-2">({columnId.totalCount})</span></span>

                                    <span className="ml-auto" style={{ float: "right", width: "8%" }}>

                                      <FontAwesomeIcon
                                        style={columnId._id === "To Do" ? { display: 'none' } : { width: "15px", cursor: "pointer" }}
                                        color="#505050"
                                        id="expand"
                                        icon={this.state.preferences[columnId._id] === "expand" ? faMinus : faPlus}
                                        onClick={(e) => this.toggle(columnId._id, this.state.preferences)}
                                      />
                                    </span>
                                  </div>
                                  <span className=" sub_text" style={{ fontSize: 13 }} >{columnId.purpose}</span>
                                  {/* <span className="kanbanColumnsubheading">$5.656</span> */}
                                  <hr className="Kanbanhr" style={{ borderTop: "1px solid #dee2e6" }} />
                                </div>
                                <div className="kanbanCardcolumn pr-0" >
                                  <div>
                                    {columnId.tasks ?
                                      columnId.tasks.map((task, index) => (
                                        < Task key={index}
                                          task={task}
                                          imageIcon={task.imageIcon}
                                          screenPermissions={this.state.screenPermissions}
                                          imgbgcolor={task.imgbgcolor}
                                          taskStates={task.statecp}
                                          condensedView={this.state.value}
                                          getKanbanCards={this.getKanbanCards}
                                          index={index}
                                          columnData={this.columnData}
                                          color={this.state.bordercolor}
                                          unassignedOnly={this.state.unassignedOnly}
                                          loginTasks={this.state.loginTasks}

                                          isLoading={this.isLoading}
                                          filterData={this.state.filterCriteria}
                                          dateQuery={this.state.dateQuery}
                                          getErrorMessage={this.getErrorMessage}
                                          showCard={ind === ColumnOrder.length - 1 ? true : false}
                                          getlastTaskindex={columnId.tasks.length - 1 === index ? true : false}
                                          departmentArr={departmentArr}


                                        />
                                      )) : null
                                    }
                                    <span style={columnId.tasks.length !== columnId.totalCount ? { display: "block" } : { display: "none" }} >
                                      <a href="#" onClick={() => this.getMoreCards(columnId)} id="view_more" style={{ float: "right" }}><u>View 10 More</u></a></span>
                                  </div>
                                  {/* : null} */}
                                </div>
                              </Card >
                            </div></div>
                          // : null
                        )) : null
                    }

                  </div>
                  : null}
                {/* </DragDropContext> */}
              </div>
            </div >
            {this.state.isOpenFormModal ? this.getkanbanModal() : ""}
            {this.state.sessionWarning ? this.getSessionWraningModal() : null}
            {this.state.forceLogout ? <Redirect to="/log_in" /> : ""}
            {this.state.iscontactNoticeModal ? this.getContactNoticeModal() : ""}
            {this.state.expiredSessionModal ? this.expiredSessionModal() : null}

            {
              this.state.sessionExpiryModal ?
                // <SessionexpiryModal
                //   SOpen={this.state.sessionExpiryModal}
                // />
                <Redirect to="/log_in" />
                : null
            }

          </Container >
        </>:this.state.screenPermissions===""?null
        : <NoViewComponent/>
    );
  }
}
Filter = reduxForm({
  form: "filterForm", // a unique identifier for this form
  enableReinitialize: true,

})(Filter);

// You have to connect() to any reducers that you wish to connect to yourself
Filter = connect(
  state => ({
    initialValues: state.commonData.data // pull initial values from account reducer
  }),

  { load: loadAccount } // bind account loading action creator
)(Filter);