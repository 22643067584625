import React from 'react';
import store from '../../../App/store';
import apiCalls from '../../../../config/apiCalls'
import DataTables from '../../CommonDataTable/DataTable';
import fetch from '../../../../config/service';
import Loader from '../../../App/Loader';


// config file
export default class Purposes extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      type: 'Purposes',
      dealTypes: [{ "label": "BK", "value": "BK" }, { "label": "CR", "value": "CR" }],
    };
  }

  static getDerivedStateFromProps(props, state) {
    let storeData = store.getState()
    let languageData = storeData && storeData.settingsData && storeData.settingsData.settings && storeData.settingsData.settings.languageTranslation ? storeData.settingsData.settings.languageTranslation : ""
    return { languageData: languageData };
  }



  componentDidMount = () => {
    this.getLabelOptions()
  }


  /**
   * get lables from db 
   */
  getLabelOptions = () => {
    fetch('GET', `${apiCalls.Labels}?type=exportToCsv`)
      .then(async (response) => {
        if (response) {
          let labels = response.labels;
          let modifiedLabels = [];
          for (let label of labels) {
            modifiedLabels.push({
              label: label.labelName,
              value: {
                labelName: label.labelName,
                id: label._id,
              },
            })
          }
          await this.setState({
            company: modifiedLabels
          })
        }
      }).catch((err) => {
        return err;
      });
  }

  /**
   * 
   * @returns tableFieldsto show in grid
   */
  getTableFields = () => {
    let data = [
      {
        "show": true,
        textAlign: 'left',
        //width: 80,
        field: 'labelName',
        "mobile": true,
        header: 'Label Name',
        "filter": true,
        "displayInSettings": true,
        "displayInFilter": true,
        sortable: true,
      },
      {
        "show": true,
        textAlign: 'left',
        //width: 40,
        field: 'sequence',
        "allowInt": true,
        "filterType": 'num',
        "mobile": true,
        header: 'Sequence',
        "filter": true,
        "displayInSettings": true,
        "displayInFilter": true,
        sortable: true,
      },
      {
        "show": true,
        textAlign: 'left',
        //width: 80,
        field: 'purposeName',
        fieldType: 'BoolText',
        "mobile": true,
        header: 'Purpose Name',
        "filter": true,
        "displayInSettings": true,
        "displayInFilter": true,
        sortable: true,
      },

      {
        "show": true,
        "textAlign": "left",
        //"width": 40,
        "fieldType": 'Actions',
        "field": "Actions",
        "header": "Actions",
        "filter": false,
        "displayInSettings": true,
        "sortable": false
      }
    ]
    return data;
  };

  /**
   * 
   * @returns formfields to show in form
   */
  getFormFields = () => {
    return ([

      {
        "value": "",
        "type": "text",
        "name": "purposeName",
        "label": "Purpose",
        "id": "name",
        "placeholder": "Purpose Name",
        "required": true
      },
      {
        "value": "",
        "type": "dropDown",
        "name": "labelName",
        "label": "Label",
        "options": this.state.company,
        "id": "name",
        "placeholder": "Name",
        "required": true
      },
      {
        "value": "",
        "type": "text",
        "formatType": "Number",
        "name": "sequence",
        "label": "Sequence",
        "placeholder": "Sequence",
      },
      {
        'isMultiSelect': true,
        "value": "",
        "type": "dropDown",
        "name": "dealTypes",
        "label": "Deal Type",
        "id": "name",
        "options": this.state.dealTypes,
        "placeholder": "Name",
        "required": true
      },
      {
        "value": "",
        "type": "switch",
        "name": "activeIndicator",
        "label": "Active /Inactive Status",
        "id": "name",
        "placeholder": "Name",
      },


    ]);
  }


  submit = async (item) => {


  }

  render() {
    return (
      <span>
        <Loader loader={this.state.isLoading} />
        <DataTables
          onRef={(ref) => (this.datatableref = ref)}
          getTableFields={this.getTableFields}
          formFields={this.getFormFields}
          exportRequried={false}
          printRequried={false}
          sortField={"sequence"}
          addRequried={true}
          editRequired={true}
          deleteRequired={false}
          viewRequired={true}
          settingsRequired={false}
          filterRequired={true}
          gridRequried={false}
          sample={false}
          isClearFilters={true}
          globalSearchFieldName='user'
          globalSearch={'Search'}
          type='Purposes'
          displayright={true}
          icon='user'
          routeTo='purposes'
          displayViewOfForm='modal'
          apiResponseKey='purposes'
          apiUrl={apiCalls.Purposes}
          entityType='employee'
        />
      </span>
    );
  }
}