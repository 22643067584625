import React from "react";
import apiCalls from "../../../../config/apiCalls";
import store from "../../../App/store";
import DataTables from "../../CommonDataTable/DataTable";
import fetchMethodRequest from '../../../../config/service';
import configMessages from '../../../../config/configMessages';
// config file
export default class AuditLog extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      languageData: "",
    };
  }

  static getDerivedStateFromProps(props, state) {
    let storeData = store.getState();
    let languageData =
      storeData &&
        storeData.settingsData &&
        storeData.settingsData.settings &&
        storeData.settingsData.settings.languageTranslation
        ? storeData.settingsData.settings.languageTranslation
        : "";
    return { languageData: languageData };
  }
  componentDidMount = async () => {
    await this.getDepartments()
  }
  getDepartments = () => {
    let filterCriteria = {}
    filterCriteria.criteria = [{ key: "CoreOpsTeam", value: true, type: "eq" },{ key: 'activeIndicator', value: true, type: 'eq' }]
    return fetch('GET', `${apiCalls.Departments}?type=exportToCsv&filter=${JSON.stringify(filterCriteria)}`)
      .then(async (response) => {
        if (response && response.departments) {
          if (response && response.departments) {
            let labels = response.departments;
            let modifiedLabels = [];
            for (let label of labels) {
              modifiedLabels.push({
                label: label.departmentName,
                value: label.departmentName,
                departmentId: label._id

              })
            } const sorted = modifiedLabels.sort((a, b) => {
              let fa = a.label.toLowerCase(),
                fb = b.label.toLowerCase();
              if (fa < fb) {
                return -1;
              }
              if (fa > fb) {
                return 1;
              }
              return 0;
            });
            await this.setState({
              taskDepartmentItems: sorted
            })

          }
        } else {
          this.setState({
            taskDepartmentItems: []
          });
          // let perObj = this.state.perObj
          if (response) {
            if (response['errorMessage'] === configMessages.warningMessage) {
              this.setState({
                sessionWarning: true
              })
            } else if (response['errorMessage'] === configMessages.tokenMessage || response['errorMessage'] === configMessages.sessionExpired) {
              this.setState({
                sessionExpiryModal: true
              })

            } else if (localStorage.getItem('sessionexpired') === true) {
              let sessionexpired = await localStorage.getItem('sessionexpired')
              if (sessionexpired === "true") {
                await this.setState({ sessionExpiryModal: true })
              }
            }
          }
        }
      }).catch((err) => {
        return err;
      });
  }
  getTableFields = () => {

    let data = [
      {
        textAlign: "left",
        width: 150,
        field: "taskID",
        mobile: true,
        header: "Task Identifier",
        "filter": true,
        sortable: true,
        show: true,
        textCapitalize: true,
      },
      {
        textAlign: "left",
        width: 150,
        field: "company",
        mobile: true,
        header: "Business Name",
        "filter": true,
        sortable: true,
        show: true,
        textCapitalize: true,
      }, {
        textAlign: "left",
        width: 180,
        field: "title",
        mobile: true,
        header: "Label",
        "filter": true,
        sortable: true,
        show: true,
        textCapitalize: true,
      },

      {
        textAlign: "left",
        width: 100,
        field: "purpose",
        mobile: true,
        header: "Purpose",
        "filter": true,
        sortable: true,
        show: true,
        textCapitalize: true,
      },
      {
        textAlign: "left",
        width: 100,
        field: "assignTo",
        mobile: true,
        header: "User",
        "filter": true,
        sortable: true,
        show: true,
        textCapitalize: true,
      },

      {
        textAlign: "left",
        width: 150,
        field: "timeSpentInMin",
        "filterType": "num",
        "allowInt": true,
        mobile: true,
        header: "Time in Minutes",
        "filter": true,
        sortable: true,
        show: true,
        textCapitalize: true,
      },
      {
        textAlign: "left",
        width: 150,
        //  fieldType: "Time",
        field: "timeSpentInHours",
        "filterType": "num",
        "allowInt": true,
        mobile: true,
        header: "Time in Hours",
        "filter": true,
        sortable: true,
        show: true,
        textCapitalize: true,
      },
      {
        textAlign: "left",
        width: 150,
        fieldType: "Time",
        field: "startTime",
        "filterType": "num",
        mobile: true,
        header: "Start Time(CT)",
        "filter": true,
        sortable: true,
        show: true,
        textCapitalize: true,
      },
      {
        textAlign: "left",
        width: 150,
        fieldType: "Time",
        field: "stopTime",
        "filterType": "num",
        mobile: true,
        header: "Stop Time(CT)",
        "filter": true,
        sortable: true,
        show: true,
        textCapitalize: true,
      },


    ];
    return data;
  };

  getFormFields = () => {
    return [
      {
        value: "",
        type: "text",
        name: "user_name",
        label: "User Name",
        id: "user_name",
        placeholder: "User Name",
        required: true,
      },
      {
        value: "",
        type: "text",
        name: "email",
        label: "Email",
        id: "email",
        placeholder: "Email",
        required: true,
      },
    ];
  };

  render() {
    return (
      <DataTables
        onRef={(ref) => (this.datatableref = ref)}
        getTableFields={this.getTableFields}
        formFields={this.getFormFields}
        statusFilter={false}
        className={true}
        isClearFilters={true}
        exportRequried={false}
        excelDownload={true}
        printRequried={false}
        addRequried={false}
        editRequired={false}
        deleteRequired={false}
        viewRequired={false}
        settingsRequired={false}
        filterRequired={false}
        gridRequried={false}
        taskDepartmentItems={this.state.taskDepartmentItems}
        sample={false}
        globalSearchFieldName='user'
        globalSearch={'Search'}
        type='Activity Details Reports'
        screenPermissionsRoute="Report - Activity Details Report"
        displayright={true}
        icon='apartment'
        routeTo='reports'
        displayViewOfForm='modal'
        apiResponseKey='reports'
        apiUrl={apiCalls.Reports}
        entityType='employee'
      />
    );
  }
}
