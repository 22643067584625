import React from 'react';
import { Container } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import EvidenceSummary from './components/EvidenceSummary';
const evidenceSummary = (props) => (

  <Container >
    <EvidenceSummary {...props} />
  </Container>
);

evidenceSummary.propTypes = {
  t: PropTypes.func.isRequired,
};

export default withTranslation('common')(evidenceSummary);
