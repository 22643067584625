import React, { useState,useEffect } from 'react';
import { Dropdown } from 'primereact/dropdown';

const MONTHS = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
const CURRENT_YEAR = (new Date()).getFullYear();
const MONTHS_DAYS = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];

export const DateGroup = ({ yearFrom, handleOutput, otherFields, populateItems, disableAll}) => {
    const [days, setDays] = useState([]);    

    const startYear = yearFrom && typeof yearFrom === 'number' ? yearFrom : 1980;
    const years = Array.from({ length: (CURRENT_YEAR - startYear) + 1 }, (x, i) => { return { label: CURRENT_YEAR - i, value: CURRENT_YEAR - i } });
    const months = MONTHS.map((mon, i) => { return { label: mon, value: i + 1 } });

    useEffect(() => {
        setDates(populateItems.month);             
    }, [populateItems.month]);

    /**
     * Setting days based on month selected.
     * @param {Number} value  
     */
    const setDates = (value) => {
        if (!value) {
            setDays(null);
            return;
        }
        const month = parseInt(value) - 1;
        let numOfDays = 0;
        if (month === 1 && populateItems.year && ((populateItems.year % 4 === 0 && populateItems.year % 100 !== 0) || populateItems.year % 400 === 0)) {
            numOfDays = 29;
        } else {
            numOfDays = MONTHS_DAYS[month] ? MONTHS_DAYS[month] : 31;
        }
        const days = Array.from({ length: numOfDays }, (x, i) => { return { label: i + 1, value: i + 1 } });
        setDays(days);
    }

    return (
        <div className="d-flex text-center date-group">
            <div className='form-group mb-0 mr-1' style={{ width: '33%' }}>
                <Dropdown className='w-100' value={populateItems.year} options={years} placeholder='Year' onChange={(e) => { handleOutput('year', e.value, otherFields) }} disabled={disableAll} />
            </div>
            <div className='form-group mb-0 mx-1' style={{ width: '40%' }}>
                <Dropdown className='w-100' disabled={!populateItems.year || disableAll} value={populateItems.month} options={months} placeholder='Month' onChange={(e) => { handleOutput('month', e.value, otherFields) }} />
            </div>
            <div className='form-group mb-0 ml-1' style={{ width: '27%' }}>
                <Dropdown className='w-100' disabled={!populateItems.year || disableAll} value={populateItems.day} options={days} placeholder='Day' onChange={(e) => { handleOutput('day', e.value, otherFields) }} />
            </div>
        </div>
    )
} 