import React, { PureComponent } from 'react';
import { InputText } from 'primereact/inputtext';
import { Button } from 'reactstrap'
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';
import config from '../../../config/configMessages'
import fetch from '../../../config/service';
import SessionWarningModal from '../../Cruds/CommonModals/SessionWarningModal';
import ExpiredSessionModal from '../../Cruds/CommonModals/ExpiredSessionModal';

import store from '../../App/store'
let timeout

class CountDisplay extends PureComponent {
	static propTypes = {
		t: PropTypes.func.isRequired,
	};

	constructor(props) {
		super(props);
		this.state = {
			sessionWarningModal: false,
			greenNameOne: "",
			greenNameTwo: "",
			greenAddOne: "",
			greenAddTwo: "",
			greenCSZ_One: "",
			greenCSZ_Two: "",
			blueNameOne: "",
			blueAddOne_1: "",
			blueAddOne_2: "",
			blueCSZ_One_1: "",
			blueCSZ_One_2: "",
			yellowNameOne_1: "",
			yellowNameOne_2: "",
			yellowNameTwo_1: "",
			yellowNameTwo_2: "",
			yellowAddOne: "",
			yellowAddTwo: "",
			yellowCSZ_One: "",
			yellowCSZ_Two: "",
			brownNameOne: "",
			brownAddOne: "",
			brownCSZ_One: "",
		};
	}
	static getDerivedStateFromProps(props, state) {
		let storeData = store.getState()
		let languageData = storeData && storeData.settingsData && storeData.settingsData.settings && storeData.settingsData.settings.languageTranslation ? storeData.settingsData.settings.languageTranslation : ""
		return { languageData: languageData };
	}

	componentDidMount() {
		if (localStorage.getItem('loginCredentials')) {
			let user = JSON.parse(localStorage.getItem('loginCredentials'));
			if (user.roleName) {
				this.setState({ employeeRole: user.roleName });
			}
			// this.getDataFromServer();
		}
	}
	cancelexpiredSessionModal = async () => {
		await this.setState({
			expiredSessionModal: false
		})
	}
	expiredSessionModal() {
		return (
			<ExpiredSessionModal
				openConfirmationModal={this.state.expiredSessionModal}
				cancelSessionWarningModal={this.cancelexpiredSessionModal}
				getData={this.getDataFromServer}

			/>
		)
	}

	componentDidUpdate() {
		if (this.state.apierrorResponse) {
			setTimeout(() => this.setState({ apierrorResponse: false, apierrorMessage: '' }), 200000);
		}
		// if (localStorage.getItem('loggedTime')) {
		// 	let loggedTime = localStorage.getItem('loggedTime')
		// 	if (timeout) {
		// 		clearTimeout(timeout)
		// 	}
		// 	timeout = setTimeout(() => this.setState({ expiredSessionModal: true, openedMultiTabs: true }), loggedTime);
		// }
	}

	//Get THreshold Values from SQL
	getDataFromServer = async () => {
		this.props.isShowLoader(true)
		fetch('GET', 'businessnames/bucket')
			.then(async (response) => {
				if (response && response.details) {
					await this.setState({
						dashboardData: response.details,
						greenNameOne: response.details.greenNameOne,
						greenNameTwo: response.details.greenNameTwo,
						greenAddOne: response.details.greenAddOne,
						greenAddTwo: response.details.greenAddTwo,
						greenCSZ_One: response.details.greenCSZ_One,
						greenCSZ_Two: response.details.greenCSZ_Two,
						blueNameOne: response.details.blueNameOne,
						blueAddOne_1: response.details.blueAddOne_1,
						blueAddOne_2: response.details.blueAddOne_2,
						blueCSZ_One_1: response.details.blueCSZ_One_1,
						blueCSZ_One_2: response.details.blueCSZ_One_2,
						yellowNameOne_1: response.details.yellowNameOne_1,
						yellowNameOne_2: response.details.yellowNameOne_2,
						yellowNameTwo_1: response.details.yellowNameTwo_1,
						yellowNameTwo_2: response.details.yellowNameTwo_2,
						yellowAddOne: response.details.yellowAddOne,
						yellowAddTwo: response.details.yellowAddTwo,
						yellowCSZ_One: response.details.yellowCSZ_One,
						yellowCSZ_Two: response.details.yellowCSZ_Two,
						brownNameOne: response.details.brownNameOne,
						brownAddOne: response.details.brownAddOne,
						brownCSZ_One: response.details.brownCSZ_One,
					});
				} else if (response && response.errorMessage && (response.errorMessage === config.tokenMessage || response.errorMessage === config.sessionExpired)) {
					await this.setState({
						sessionExpiryModal: true
					})
				} else if (response && response.errorMessage && response.errorMessage === config.warningMessage) {
					await this.setState({
						sessionWarningModal: true
					})
				}
				this.props.isShowLoader(false)
			}).catch((err) => {
				return err;
			});
	}

	//stay signed in Modal
	getSessionWraningModal() {
		return (
			<SessionWarningModal
				openConfirmationModal={this.state.sessionWarning}
				cancelSessionWarningModal={this.cancelSessionWarningModal}
				getData={this.getKanbanCards}

			/>
		)
	}
	ongreenNameOneChange = async (e) => {
		this.setState({
			greenNameOne: e.target.value
		})
	}
	ongreenNameTwoChange = async (e) => {
		this.setState({
			greenNameTwo: e.target.value
		})
	}
	ongreenAddOneChange = async (e) => {
		this.setState({
			greenAddOne: e.target.value
		})
	}
	ongreenAddTwoChange = async (e) => {
		this.setState({
			greenAddTwo: e.target.value
		})
	}
	ongreenCSZ_OneChange = async (e) => {
		this.setState({
			greenCSZ_One: e.target.value
		})
	}
	ongreenCSZ_TwoChange = async (e) => {
		this.setState({
			greenCSZ_Two: e.target.value
		})
	}
	onblueNameOneChange = async (e) => {
		this.setState({
			blueNameOne: e.target.value
		})
	}
	onblueAddOne_1Change = async (e) => {
		this.setState({
			blueAddOne_1: e.target.value
		})
	}
	onblueAddOne_2Change = async (e) => {
		this.setState({
			blueAddOne_2: e.target.value
		})
	}
	onblueCSZ_One_1Change = async (e) => {
		this.setState({
			blueCSZ_One_1: e.target.value
		})
	}
	onblueCSZ_One_2Change = async (e) => {
		this.setState({
			blueCSZ_One_2: e.target.value
		})
	}
	onyellowNameOne_1Change = async (e) => {
		this.setState({
			yellowNameOne_1: e.target.value
		})
	}
	onyellowNameOne_2Change = async (e) => {
		this.setState({
			yellowNameOne_2: e.target.value
		})
	}
	onyellowNameTwo_1Change = async (e) => {
		this.setState({
			yellowNameTwo_1: e.target.value
		})
	}
	onyellowNameTwo_2Change = async (e) => {
		this.setState({
			yellowNameTwo_2: e.target.value
		})
	}
	onyellowAddOneChange = async (e) => {
		this.setState({
			yellowAddOne: e.target.value
		})
	}
	onyellowAddTwoChange = async (e) => {
		this.setState({
			yellowAddTwo: e.target.value
		})
	}
	onyellowCSZ_OneChange = async (e) => {
		this.setState({
			yellowCSZ_One: e.target.value
		})
	}
	onyellowCSZ_TwoChange = async (e) => {
		this.setState({
			yellowCSZ_Two: e.target.value
		})
	}
	onbrownNameOneChange = async (e) => {
		this.setState({
			brownNameOne: e.target.value
		})
	}
	onbrownAddOneChange = async (e) => {
		this.setState({
			brownAddOne: e.target.value
		})
	}
	onbrownCSZ_OneChange = async (e) => {
		this.setState({
			brownCSZ_One: e.target.value
		})
	}
	getSearchDataFromServer = async () => {
		this.props.isShowLoader(true)
		let ThresholdValues = {
			greenNameOne: this.state.greenNameOne,
			greenNameTwo: this.state.greenNameTwo,
			greenAddOne: this.state.greenAddOne,
			greenAddTwo: this.state.greenAddTwo,
			greenCSZ_One: this.state.greenCSZ_One,
			greenCSZ_Two: this.state.greenCSZ_Two,
			blueNameOne: this.state.blueNameOne,
			blueAddOne_1: this.state.blueAddOne_1,
			blueAddOne_2: this.state.blueAddOne_2,
			blueCSZ_One_1: this.state.blueCSZ_One_1,
			blueCSZ_One_2: this.state.blueCSZ_One_2,
			yellowNameOne_1: this.state.yellowNameOne_1,
			yellowNameOne_2: this.state.yellowNameOne_2,
			yellowNameTwo_1: this.state.yellowNameTwo_1,
			yellowNameTwo_2: this.state.yellowNameTwo_2,
			yellowAddOne: this.state.yellowAddOne,
			yellowAddTwo: this.state.yellowAddTwo,
			yellowCSZ_One: this.state.yellowCSZ_One,
			yellowCSZ_Two: this.state.yellowCSZ_Two,
			brownNameOne: this.state.brownNameOne,
			brownAddOne: this.state.brownAddOne,
			brownCSZ_One: this.state.brownCSZ_One,
		}
		// return fetch('PUT', `businessnames/bucket?data=${JSON.stringify(ThresholdValues)}`)
		// 	.then(async (response) => {
		// 		if (response && response.respCode && response.respCode === "200") {
		// 			this.getDataFromServer();
		// 		} else if (response && response.errorMessage && (response.errorMessage === config.tokenMessage || response.errorMessage === config.sessionExpired)) {
		// 			await this.setState({
		// 				sessionExpiryModal: true
		// 			})
		// 		} else if (response && response.errorMessage && response.errorMessage === config.warningMessage) {
		// 			await this.setState({
		// 				sessionWarningModal: true
		// 			})
		// 		} else if (response && response.errorMessage) {
		// 			this.getErrorMessage(response.errorMessage)
		// 		}
		// 		this.props.isShowLoader(true)
		// 	}).catch((err) => {
		// 		return err;
		// 	});
	}

	getReset = async () => {
		this.getDataFromServer();
	}

	render() {
		return (
			<div className='row mr-0 ml-0 p-1'>
				{/* Go */}
				<p>Specify the minimum threshold for a property to be considered for Deal Sizing.</p>
				<div className='col-sm-12' style={{ textAlign: "center", alignSelf: 'center' }}>
					<div className='row pb-5' style={{ textAlign: "center", alignSelf: 'center' }}>
						<div className='col-sm-2' style={{ textAlign: "center", alignSelf: 'center' }}>
							<h2 style={{ color: "green" }}>Green </h2>
						</div>
						<div className='col-sm-10' style={{ textAlign: "center", alignSelf: 'center' }}>
							<div className='row mr-0 ml-0 pt-1'>
								{'>= '}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
								<InputText
									type="search"
									name='blueNameOne'
									id='blueNameOne'
									value={this.state['blueNameOne']}
									onChange={(e) => this.onblueNameOneChange(e)}
									placeholder={'Percentage'}
									size="10" />

							</div>
						</div>
					</div>
					<div className='row pb-5' style={{ textAlign: "center", alignSelf: 'center' }}>
						<div className='col-sm-2' style={{ textAlign: "center", alignSelf: 'center' }}>
							<h2 style={{ color: "#008080" }}>Teal</h2>
						</div>
						<div className='col-sm-10' style={{ textAlign: "center", alignSelf: 'center' }}>
							<div className='row mr-0 ml-0 pt-1'>
								{'  > '}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
								<InputText
									type="search"
									name='brownNameOne'
									id='brownNameOne'
									value={this.state['brownNameOne']}
									onChange={(e) => this.onbrownNameOneChange(e)}
									placeholder={'Percentage'}
									size="10" />
							</div>
						</div>
					</div>
					<div className='col-sm-12 pt-4' style={{ textAlign: "center", alignSelf: 'center' }}>
						<Button
							outline
							color="primary"
							size="sm"
							// disabled={this.state.searchValue ? false : true}
							className={'p-1 m-2'}
							style={{ width: "75px" }}
							onClick={() => this.getSearchDataFromServer(this.state.filterCriteria, 'refresh')}>
							Save
						</Button>
						<Button
							outline
							color="danger"
							size="sm"
							// disabled={this.state.searchValue ? false : true}
							className={'p-1 m-2'}
							style={{ width: "85px" }}
							onClick={() => this.getReset()}>
							Cancel
						</Button>
					</div>
				</div>
				{
					this.state.sessionExpiryModal ?
						<Redirect to="/log_in" />
						: null
				}
				{this.state.expiredSessionModal ? this.expiredSessionModal() : null}
				{this.state.forceLogout ? <Redirect to="/log_in" /> : ""}

				{this.state.sessionWarningModal ? this.getSessionWraningModal() : null}
			</div>
		);
	}
}

export default withTranslation('common')(CountDisplay);

