import React from 'react';
import Table from 'react-bootstrap/Table';
// import 'bootstrap/dist/css/bootstrap.min.css';
import { InputText } from 'primereact/inputtext';
import { Button, Modal, ModalHeader, ModalBody } from 'reactstrap';
import config from '../../../config/config';
import showToasterMessage from '../../UI/ToasterMessage/toasterMessage';
import fetchMethodRequest from '../../../config/service';
//Loader
import Loader from '../../App/Loader';

// config file
export default class DeleteRowModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            click: false,
            showHelp: false,
            data: [],
            tableRows: [],
            progress: "0"
        };
    }

    componentDidMount = async () => {
        this.updateTable()
    }

    onPropertyIdChange = async (e) => {
        this.setState({
            PropertyId: e.target.value
        })
    }

    handlePercentage = async (Val) => {
        let counter = 0;
        const interval = setInterval(async () => {
            if (this.state.isLoading) {
                counter = counter + 15;
                await this.setState({
                    progress: counter,
                })
            } else {
                if (!this.state.isLoading) {
                    clearInterval(interval);
                }
            }
        }, 100);
    }

    getSearchDataFromServer = async () => {
        this.setState({
            isLoading: true
        })
        this.handlePercentage('1')
        let url = `seller/getPropertyPresentFromDifferentSources?propertyId=${this.state.PropertyId}`

        await fetchMethodRequest('GET', url)
            .then(async (response) => {
                if (response) {
                    if (response.respCode && response.respMessage) {
                        showToasterMessage(response.respMessage, 'success');
                        await this.setState({
                            data: response.details,
                            isLoading: false
                        })
                        this.updateTable()
                    } else if (response.errorMessage) {
                        // display message
                        showToasterMessage(response.errorMessage, 'error');
                        this.setState({
                            isLoading: false
                        })
                    }
                }
            })
    }

    updateTable = async () => {
        var tableRows = this.state.data.map(
            (element) => {
                return (

                    <tr>
                        <td>{element.Source}</td>
                        <td>{element.Present}</td>
                        <td>{element.LastUpdatedDate}</td>
                    </tr>

                )
            }
        )
        this.setState({
            tableRows: tableRows
        })
    }



    render() {
        return (

            <Modal isOpen={this.props.openPropertyTrailViewerModal} fade={false} className='right modal-dialog-centered modal-dialog--primary'>
                <Loader loader={this.state.isLoading} progress={this.state.progress} className="progress-loader" />
                <ModalHeader >
                    <div className='row mr-0 ml-0 p-1'>
                        <div className='col-sm-12' style={{ textAlign: "right", alignSelf: 'center' }}>
                            {'Property Id: '}
                            <InputText
                                type="search"
                                name='PropertyId'
                                id='PropertyId'
                                value={this.state['PropertyId']}
                                onChange={(e) => this.onPropertyIdChange(e)}
                                placeholder={'PropertyId'}
                            // size="1"
                            />
                            <Button color="primary"
                                className='p-1 m-2'
                                size={'sm'} //onClick={this.getSearchDataFromServer(this.state.filterCriteria, 'exportToCsv')}
                                onClick={() => this.getSearchDataFromServer()}
                                disabled={this.state.PropertyId ? false : true}
                            >
                                Go
                            </Button>
                        </div>
                    </div>
                    <button className="lnr lnr-cross modal__close-btn" type="button" style={{ color: "black", right: 40 }}
                        onClick={() => this.props.closePropertyTrailViewerModal()} />
                </ModalHeader>
                <ModalBody >
                    {/* <div> */}
                    <Table hover className="table table-striped">
                        <thead className="thead-teal">
                            <tr>
                                <th>Source</th>
                                <th>Present</th>
                                <th>Last Updated Date</th>
                            </tr>
                        </thead>
                        <tbody>
                            {this.state.tableRows}
                        </tbody>
                    </Table>
                    {/* </div> */}
                    {/* <div className='row'>
                        <div className='col-sm-5'>
                            test row 1
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-sm-5'>
                            testrow 2
                        </div>
                    </div> */}
                </ModalBody>
                {/* <CountDisplay
                    isShowLoader={this.isShowLoader}
                /> */}
            </Modal >
        );
    }
}