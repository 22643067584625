import React from 'react';
import apiCalls from '../../../../config/apiCalls'
import store from '../../../App/store';
import RolePermissions from '../../CommonModals/Permissions';
import CompanyView from '../../../Form/CompanyView';

// config file
export default class Employees extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      type: 'Company'
    };
  }

  static getDerivedStateFromProps(props, state) {
    let storeData = store.getState()
    let languageData = storeData && storeData.settingsData && storeData.settingsData.settings && storeData.settingsData.settings.languageTranslation ? storeData.settingsData.settings.languageTranslation : ""
    return { languageData: languageData };
  }



  componentDidMount = () => {
    //Screen permisions value can be edit,view, no view
    let screenPermissions = RolePermissions.screenPermissions(this.state.type);
    this.setState({
      screenPermissions: screenPermissions
    })
  }


  getTableFields = () => {
    let data = [
      {
        "show": true,
        "textAlign": "left",
        "width": 120,
        "field": "CompanyID",
        "mobile": true,
        "header": "Company Id",
        "displayInSettings": true,
        filter: true,
        "sortable": true,
      },
      {
        "show": true,
        "textAlign": "left",
        "width": 220,
        "field": "CompanyName",
        "mobile": true,
        "header": "HQ Company Name",
        "fieldType": "Link",
        "displayInSettings": true,
        filter: true,
        "sortable": true,
      },

      {
        "show": true,
        "textAlign": "left",
        "width": 220,
        "field": "HQCity",
        "fieldType": "CityField",
        "mobile": true,
        "header": "City State",
        "displayInSettings": true,
        filter: true,
        "sortable": true,
      },
      {
        "show": true,
        "textAlign": "left",
        "width": 150,
        "mobile": true,
        "field": "HQAddressLine1",
        "header": "Address Line 1",
        filter: true,
        "displayInSettings": true,
        "displayInFilter": false,
        //"options": [{ "label": "Active", "value": 'Active' }, { "label": "Pending", "value": 'Pending' }, { "label": "Inactive", "value": 'Inactive' }],
        "sortable": true,
      },
      {
        "show": true,
        "textAlign": "left",
        "width": 140,
        "field": "HQPhone",
        "mobile": true,
        "header": "HQ Phone",
        filter: true,
        "sortable": true,
        "displayInSettings": true,
      },
      {
        "show": true,
        "textAlign": "left",
        "width": 180,
        "field": "HQEmail",
        "mobile": true,
        "header": "HQ Email Id",
        filter: true,
        "sortable": true,
        "displayInSettings": true,
      },
      {
        "show": true,
        "textAlign": "left",
        "width": 120,
        "field": "Employees",
        "mobile": true,
        "header": "Employees",
        filter: true,
        "sortable": true,
        "displayInSettings": true,
      },
      {
        "show": true,
        "textAlign": "left",
        "width": 160,
        "field": "Website",
        "mobile": true,
        "header": "Website",
        filter: true,
        "sortable": false,
        "displayInSettings": true,
      },
      {
        "show": true,
        "textAlign": "left",
        "width": 120,
        "field": "YearFounded",
        "mobile": true,
        "header": "Year Founded",
        filter: true,
        "sortable": true,
        "displayInSettings": true,
      },
      {
        "show": true,
        "textAlign": "left",
        "width": 160,
        "field": "Revenue",
        "fieldType": "currency",
        "mobile": true,
        "header": "Revenue(in Millions of USD)",
        filter: true,
        "sortable": true,
        "displayInSettings": true,
      },
      {
        "show": true,
        "textAlign": "left",
        "width": 140,
        "field": "ParentCompany",
        "mobile": true,
        "header": "Parent Company",
        filter: true,
        "sortable": true,
        "displayInSettings": true,
      },
      {
        "show": true,
        "textAlign": "left",
        "width": 140,
        "field": "Description",
        "mobile": true,
        "header": "Description",
        filter: true,
        "sortable": true,
        "displayInSettings": true,
      },
      {
        "show": true,
        "textAlign": "left",
        "width": 140,
        "field": "DataSourceName",
        "mobile": true,
        "header": "Data Source Name",
        filter: true,
        "sortable": true,
        "displayInSettings": true,
      }, {

        "show": true,
        "textAlign": "left",
        "width": 160,
        "field": "LastUpdated",
        "fieldType": "Date",
        "filterType": "num",
        "dateFormat": 'MM-DD-yyyy',
        "mobile": true,
        "header": "Last Updated On",
        filter: true,
        "sortable": true,
        "displayInSettings": true,
      }, {
        "show": true,
        "textAlign": "left",
        "width": 160,
        "field": "HQPostCode",
        "mobile": true,
        "header": "Post Code",
        filter: true,
        "sortable": true,
        "displayInSettings": true,
      },
      // {
      //   show: true,
      //   mobile: true,
      //   textAlign: 'left',
      //   width: 180,
      //   field: 'Industry',
      //   header: 'Industry',
      //   sortable: true
      // },

    ]
    return data;
  };
  getAcquisitionFields = () => {
    let data = [
      {
        show: true,
        mobile: true,
        textAlign: 'left',
        width: 60,
        field: 'expander',
      },
      {
        show: true,
        mobile: true,
        textAlign: 'left',
        width: 130,
        field: 'CompanyID',
        header: 'Company ID',
        filter: true,
        sortable: true,
        // dateFormat: config.dateDayMonthFormat
      },
      {
        show: true,
        mobile: true,
        textAlign: 'left',
        width: 180,
        field: 'TargetCompanyID',
        fieldType: 'ParentCompany',
        getField: "TargetCompanyID",
        header: 'Target Company ID',
        filter: true,
        sortable: true
      },
      {
        show: true,
        mobile: true,
        textAlign: 'left',
        width: 200,
        // fieldType: "Link",
        field: 'TargetCompanyName',
        header: 'Target Company Name',
        filter: true,
        sortable: true
      },
      {
        show: true,
        mobile: true,
        textAlign: 'left',
        width: 120,
        field: 'DealID',
        header: 'Deal ID',
        filter: true,
        sortable: true
      },
      {
        show: true,
        mobile: true,
        textAlign: 'left',
        width: 120,
        "filterType": "num",
        fieldType: "Date",
        type: 'date',
        field: 'DealDate',
        header: 'Deal Date',
        filter: true,
        sortable: true
      },
      {
        show: true,
        mobile: true,
        textAlign: 'left',
        width: 120,
        field: 'DealType',
        header: 'Deal Type',
        filter: true,
        sortable: true
      },
      {
        show: true,
        mobile: true,
        textAlign: 'left',
        width: 120,
        filterType: "num",
        field: 'DealSize',
        header: 'Deal Size',
        filter: true,
        sortable: true
      },
      {
        show: true,
        mobile: true,
        textAlign: 'left',
        width: 150,
        field: 'CompanyStage',
        header: 'Company Stage',
        filter: true,
        sortable: true
      },
      {
        show: true,
        mobile: true,
        textAlign: 'left',
        width: 110,
        field: 'Industry',
        header: 'Industry',
        filter: true,
        sortable: true
      },
      {
        show: true,
        mobile: true,
        textAlign: 'left',
        width: 170,
        field: 'LeadPartner',
        header: 'Lead Partner',
        filter: true,
        sortable: true
      },
    ];
    return data;
  }
  getAffiliatesFields = () => {
    let data = [
      {
        show: true,
        mobile: true,
        textAlign: 'left',
        width: 130,
        field: 'CompanyID',
        header: 'Company ID',
        filter: true,
        sortable: true,
      },
      {
        show: true,
        mobile: true,
        textAlign: 'left',
        width: 200,
        field: 'AffiliateID',
        getField: "AffiliateID",
        fieldType: 'ParentCompany',
        header: 'Affiliate ID',
        filter: true,
        sortable: true
      },
      {
        show: true,
        mobile: true,
        textAlign: 'left',
        width: 120,
        field: 'AffiliateName',
        header: 'Affiliate Name',
        filter: true,
        sortable: true
      },
      {
        show: true,
        mobile: true,
        textAlign: 'left',
        width: 150,
        field: 'AffiliateType',
        header: 'Affiliate Type',
        filter: true,
        sortable: true
      },
      {
        show: true,
        mobile: true,
        textAlign: 'left',
        width: 150,
        field: 'Location',
        header: 'Location',
        filter: true,
        sortable: true
      }, {
        show: true,
        mobile: true,
        textAlign: 'left',
        width: 150,
        field: 'Industry',
        header: 'Industry',
        filter: true,
        sortable: true
      },
      {
        show: true,
        mobile: true,
        textAlign: 'left',
        width: 150,
        field: 'YearFounded',
        header: 'Year Founded',
        filter: true,
        sortable: true
      },
    ];
    return data;
  }

  getNewsFields = () => {
    let data = [
      {
        show: true,
        mobile: true,
        textAlign: 'left',
        width: 130,
        field: 'PublishDate',
        "filterType": "num",
        fieldType: "Date",
        "dateFormat": 'MM-DD-yyyy',
        header: 'Publish Date',
        filter: true,
        sortable: true,
      },
      {
        show: true,
        mobile: true,
        textAlign: 'left',
        width: 200,
        field: 'Source',
        header: 'Source',
        filter: true,
        sortable: true
      }, {
        show: true,
        mobile: true,
        textAlign: 'left',
        width: 200,
        field: 'title',
        fieldType: 'url',
        header: 'Title',
        filter: true,
        sortable: true
      },
    ];
    return data;
  }
  getContactsFields = () => {
    let data = [
      {
        show: true,
        mobile: true,
        textAlign: 'left',
        width: 130,
        field: 'PersonName',
        // "filterType": "num",fieldType: "Date",
        header: 'Person Name',
        filter: true,
        sortable: true,
      },
      {
        show: true,
        mobile: true,
        textAlign: 'left',
        width: 200,
        field: 'FullTitle',
        header: 'Full Title',
        filter: true,
        sortable: true
      }, {
        show: true,
        mobile: true,
        textAlign: 'left',
        width: 200,
        field: 'IsOnBoard',
        header: 'Is Onboard',
        filter: true,
        sortable: true
      },
      {
        show: true,
        mobile: true,
        textAlign: 'left',
        width: 200,
        field: 'RepresentingName',
        header: 'Representing Name',
        filter: true,
        sortable: true
      }, {
        show: true,
        mobile: true,
        textAlign: 'left',
        width: 200,
        field: 'Location',
        header: 'Location',
        filter: true,
        sortable: true
      }, {
        show: true,
        mobile: true,
        textAlign: 'left',
        width: 200,
        field: 'StartDate',
        "filterType": "num",
        fieldType: "Date",
        "dateFormat": 'MM-DD-yyyy',
        header: 'Start Date',
        filter: true,
        sortable: true
      }
    ];
    return data;
  }
  getNamesAddressFields = () => {
    let data = [
      {
        show: true,
        mobile: true,
        textAlign: 'left',
        width: 130,
        field: 'CompanyID',
        getField: "CompanyID",
        header: 'Company ID',
        fieldType: "ParentCompany",
        filter: true,
        sortable: true,
      },
      {
        show: true,
        mobile: true,
        textAlign: 'left',
        width: 150,
        field: 'BusinessName',
        header: 'Business Name',
        filter: true,
        sortable: true
      }, {
        show: true,
        mobile: true,
        textAlign: 'left',
        width: 200,
        field: 'NameType',
        header: 'Name Type',
        filter: true,
        sortable: true
      }, {
        show: true,
        mobile: true,
        textAlign: 'left',
        width: 150,
        field: 'Line1',
        header: 'Address 1',
        filter: true,
        sortable: true
      }, {
        show: true,
        mobile: true,
        textAlign: 'left',
        width: 150,
        field: 'City',
        header: 'City',
        filter: true,
        sortable: true
      }, {
        show: true,
        mobile: true,
        textAlign: 'left',
        width: 150,
        field: 'Country',
        header: 'Country',
        filter: true,
        sortable: true
      }, {
        show: true,
        mobile: true,
        textAlign: 'left',
        width: 200,
        field: 'LocationName',
        header: 'Location Name',
        filter: true,
        sortable: true
      }, {
        show: true,
        mobile: true,
        textAlign: 'left',
        width: 150,
        field: 'LocationType',
        header: 'Location Type',
        filter: true,
        sortable: true
      }, {
        show: true,
        mobile: true,
        textAlign: 'left',
        width: 150,
        field: 'LocationStatus',
        header: 'Location Status',
        filter: true,
        sortable: true
      }, {
        show: true,
        mobile: true,
        textAlign: 'left',
        width: 150,
        field: 'StateProvince',
        header: 'State',
        filter: true,
        sortable: true
      }, {
        show: true,
        mobile: true,
        textAlign: 'left',
        width: 200,
        field: 'Zip',
        header: 'PostCode',
        filter: true,
        sortable: true
      },
      {
        show: true,
        mobile: true,
        textAlign: 'left',
        width: 200,
        field: 'ConnectedName',
        header: 'Connected Name',
        filter: true,
        // sortable: true
      },
    ];
    return data;
  }
  getPreAPAFields = () => {
    let data = [
      {
        "show": true,
        "textAlign": "left",
        "width": 120,
        "field": "CompanyID",
        "fieldType": "ParentCompany",
        "mobile": true,
        "getField": "CompanyID",
        "header": "Company Id",
        "displayInSettings": true,
        filter: true,
        "sortable": true,
      }, {
        "show": true,
        "textAlign": "left",
        "width": 220,
        "field": "CompanyName",
        "mobile": true,
        "header": "HQ Company Name",
        "fieldType": "Link",
        "displayInSettings": true,
        filter: true,
        "sortable": true,
      }, {
        "show": true,
        "textAlign": "left",
        "width": 220,
        "field": "CompanyLegalName",
        "mobile": true,
        "header": "Company Legal Name",
        "fieldType": "Link",
        "displayInSettings": true,
        filter: true,
        "sortable": true,
      }, {
        "show": true,
        "textAlign": "left",
        "width": 220,
        "field": "CompanyNameAliases",
        "mobile": true,
        "header": "Company Name Aliases",
        "fieldType": "Link",
        "displayInSettings": true,
        filter: true,
        "sortable": true,
      },
      {
        "show": true,
        "textAlign": "left",
        "width": 220,
        "field": "CompanyAlsoKnownAs",
        "mobile": true,
        "header": "Company Also Known As",
        "fieldType": "Link",
        "displayInSettings": true,
        filter: true,
        "sortable": true,
      }, {
        "show": true,
        "textAlign": "left",
        "width": 220,
        "field": "CompanyFormerName",
        "mobile": true,
        "header": "Company Former Name",
        "fieldType": "Link",
        "displayInSettings": true,
        filter: true,
        "sortable": true,
      },
      {
        "show": true,
        "textAlign": "left",
        "width": 150,
        "field": "ParentCompany",
        "mobile": true,
        // "getField": "CompanyID",
        "header": "Parent Company Name",
        "displayInSettings": true,
        filter: true,
        "sortable": true,
      }, {
        "show": true,
        "textAlign": "left",
        "width": 140,
        "field": "YearFounded",
        "mobile": true,
        "header": "Year Founded",
        "displayInSettings": true,
        filter: true,
        "sortable": true,
      }, {
        "show": true,
        "textAlign": "left",
        "width": 190,
        "field": "Description",
        "mobile": true,
        "header": "Description",
        filter: true,
        "sortable": true,
        "displayInSettings": true,
      },
      {
        "show": true,
        "textAlign": "left",
        "width": 160,
        "field": "Website",
        "mobile": true,
        "fieldType": "website",
        "header": "Website",
        filter: true,
        "sortable": false,
        "displayInSettings": true,
      },

    ];
    return data;
  }

  getPublicCompanies = () => {
    let data = [
      {
        "show": true,
        "textAlign": "left",
        "width": 150,
        "field": "CIK",
        "mobile": true,
        "header": "CIK",
        "displayInSettings": true,
        filter: true,
        "sortable": true,
      }, {
        "show": true,
        "textAlign": "left",
        "width": 140,
        "field": "Name",
        "fieldType": "Link",
        "mobile": true,
        "header": "Name",
        "displayInSettings": true,
        filter: true,
        "sortable": true,
      }, {
        "show": true,
        "textAlign": "left",
        "width": 190,
        "field": "StateofIncorporation",
        "mobile": true,
        "header": "State of Incorporation",
        filter: true,
        "sortable": true,
        "displayInSettings": true,
      },
      {
        "show": true,
        "textAlign": "left",
        "width": 160,
        "field": "BusinessAddressLine1",
        "mobile": true,
        "header": "Business Address Line1",
        filter: true,
        "sortable": true,
        "displayInSettings": true,
      }, {
        "show": true,
        "textAlign": "left",
        "width": 160,
        "field": "BusinessAddressCity",
        "mobile": true,
        "header": "Business Address City",
        filter: false,
        "sortable": true,
        "displayInSettings": true,
      }, {
        "show": true,
        "textAlign": "left",
        "width": 160,
        "field": "BusinessAddressStateOrCountry",
        "mobile": true,
        "header": "Business Address State Or Country",
        filter: true,
        "sortable": true,
        "displayInSettings": true,
      }, {
        "show": true,
        "textAlign": "left",
        "width": 160,
        "field": "MostRecent10KFormDate",
        "mobile": true,
        "fieldType": "Date",
        "filterType": "num",
        // "isLink": "yes",
        "dateFormat": 'MM-DD-yyyy',
        "header": "Most Recent 10K Form Date",
        filter: true,
        "sortable": true,
        "displayInSettings": true,
      },
    ]
    return data;
  }

  getPublicAffiliateCompanies = () => {
    let data = [

      {
        "show": true,
        "textAlign": "left",
        "width": 150,
        "field": "ParentCik",
        "mobile": true,
        "header": "Parent Cik",
        "displayInSettings": true,
        filter: true,
        "sortable": false,
      }, {
        "show": true,
        "textAlign": "left",
        "width": 250,
        "field": "Name",
        // "fieldType": "Link",
        "mobile": true,
        "header": "Name",
        "displayInSettings": true,
        filter: true,
        "sortable": true,
      }, {
        "show": true,
        "textAlign": "left",
        "width": 90,
        "field": "JurisdictionAbbr",
        "mobile": true,
        "header": "JDX",
        filter: true,
        "sortable": true,
        "displayInSettings": true,
      },
      {
        "show": true,
        "textAlign": "left",
        "width": 160,
        "field": "DateAcceptedAsSubsidiary",
        "mobile": true,
        "fieldType": "Date",
        "filterType": "num",
        "dateFormat": 'MM-DD-yyyy',
        "header": "Date Accepted As Subsidiary",
        filter: true,
        "sortable": true,
        "displayInSettings": true,
      }, {
        "show": true,
        "textAlign": "left",
        "width": 160,
        "field": "CreatedDateUTC",
        "mobile": true,
        "fieldType": "Date",
        "filterType": "num",
        "dateFormat": 'MM-DD-yyyy',
        "header": "Created Date UTC",
        filter: true,
        "sortable": true,
        "displayInSettings": true,
      }, {
        "show": true,
        "textAlign": "left",
        "width": 160,
        "field": "Parent10KUrl",
        "fieldType": "website",
        "mobile": true,
        "header": "Parent 10K Url",
        filter: true,
        "sortable": false,
        "displayInSettings": true,
      }, {
        "show": true,
        "textAlign": "left",
        "width": 160,
        "field": "Parent10KExhibit21Url",
        "fieldType": "website",
        "mobile": true,
        "header": "Parent 10K Exhibit 21 Url",
        filter: true,
        "sortable": false,
        "displayInSettings": true,
      },
    ]
    return data;
  }


  submit = async (item) => {
    await this.setState({
      openViewModal: true,
      rowData: [{ 'label': 'name', 'value': 'new name' }]
    })
  }

  render() {
    return (
      <span>
        <CompanyView {...this.props}
          // onRef={(ref) => (this.datatableref = ref)}
          getPublicCompanies={this.getPublicCompanies}
          getPublicAffiliateCompanies={this.getPublicAffiliateCompanies}
          getTableFields={this.getTableFields}
          getAcquisitionFields={this.getAcquisitionFields}
          getAffiliatesFields={this.getAffiliatesFields}
          getNewsFields={this.getNewsFields}
          getContactsFields={this.getContactsFields}
          getNamesAddressFields={this.getNamesAddressFields}
          getPreAPAFields={this.getPreAPAFields}
          formFields={this.getFormFields}
          statusFilter={false}
          className={true}
          exportRequried={false}
          printRequried={false}
          addRequried={false}
          editRequired={false}
          deleteRequired={false}
          viewRequired={true}
          settingsRequired={false}
          filterRequired={false}
          gridRequried={false}
          sample={false}
          globalSearchFieldName='user'
          globalSearch={'Company Name/Company ID'}
          type={this.state.type}
          displayright={true}
          icon='apartment'
          routeTo='company'
          // displayViewOfForm='modal'
          apiResponseKey='companies'
          apiUrl={apiCalls.Companies}
          entityType='employee'
          displayViewOfForm="screen"
        />
      </span>
    );
  }
}