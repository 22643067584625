import React from 'react';
import { Container } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import AvailableToMapConfiguration from './components/AvailableToMapConfiguration';
const availableToMapConfiguration = ({ t }) => (
  <Container >
    <AvailableToMapConfiguration />
  </Container>
);

availableToMapConfiguration.propTypes = {
  t: PropTypes.func.isRequired,
};

export default withTranslation('common')(availableToMapConfiguration);
