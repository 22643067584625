import React from 'react';
import { Container } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import AvailableDocuments from './components/AvailableDocuments';

const AvailableDocumentsPage = (props) => (
  // <Container>
    <AvailableDocuments {...props} />
  // </Container>
);

AvailableDocumentsPage.propTypes = {
  t: PropTypes.func.isRequired,
};

export default withTranslation('common')(AvailableDocumentsPage);