import React from 'react';
import { Button, Modal, ModalBody } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import { InputText } from 'primereact/inputtext';

// config file
class ConfirmationModalForCSV extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        };
    }

    onNameChange = async (e) => {
        await this.setState({
            FileName: e
        })
    }

    render() {
        const { t } = this.props
        return (
            <div>
                <Modal isOpen={this.props.openConfirmationModal} fade={false} className='deleteModalHeight'>
                    <ModalBody className='deleteModalBody'>
                        <div style={{ width: "500px", margin: "auto" }}><div style={{ marginBottom: 10, textAlign: "left" }}>{this.props.shownText}</div>
                            <div style={{ marginBottom: 10 }}>
                                <InputText
                                    type="name"
                                    name='FileName'
                                    id='FileName'
                                    style={{ width: "100%", height: 35, paddingLeft: "2.5rem", marginTop: 2 }}
                                    onChange={(e) => this.onNameChange(e.target.value)}
                                    placeholder={this.state.FileName ? this.state.FileName : 'Enter File Name'}
                                    size={"35"} />
                            </div>
                            <div style={{ textAlign: "right" }}>
                                <Button color="primary" disabled={this.state.FileName ? false : true} outline onClick={() => this.props.closeConfirmationModal(this.state.FileName)} className='deleteModalBtn marginRight'>{t('Yes')}</Button>
                                <Button color="primary" outline onClick={this.props.closeConfirmationModal}
                                    className='deleteModalBtn'>{t('No')}</Button>
                            </div>
                        </div>
                    </ModalBody>
                </Modal>
            </div>
        );
    }
}
export default withTranslation('common')(ConfirmationModalForCSV);
