import React, { useEffect, useState, useRef } from 'react';
import { Dialog } from 'primereact/dialog';
import { ProgressBar } from 'primereact/progressbar';
import { Button } from 'reactstrap';

export const ProgressResp = (props) => {
    const [progressValue, setProgressValue] = useState(0);
    const [showType, setShowType] = useState('progress');


    useEffect(() => {
        if (props.showProgressBar && !props.loadingCompleted) {
            startProgress();
        }
        if (props.showProgressBar && props.loadingCompleted) {
            setTimeout(() => { setProgressValue(100); setShowType('alert') }, 500)
        }
        if (!props.showProgressBar) {
            setProgressValue(0);
            setShowType('progress')
        }
    }, [progressValue, props.showProgressBar, props.loadingCompleted]);

    const startProgress = () => {
        if (progressValue < 90) {
            setTimeout(() => {
                setProgressValue(progressValue + 5);
            }, 500);
        }
    }
    
    return <>
        <Dialog header={'Claim Builder'} closeOnEscape={false} showHeader={false} visible={props.showProgressBar} style={{ width: '40vw' }} modal={true} onHide={() => { props.showHideProgressBar(false) }}>
            {showType === 'progress' ? <>
                <div className='d-flex'>

                    <div class="spinner-border spinner-border-sm text-secondary my-1" role="status">
                        <span class="sr-only">Loading...</span>
                    </div>
                    <div className='ml-1 mb-1'>
                        {props.progressType === 'Search' ? <>{
                            props.selectedDocType === 'Claim Packet' ? 
                            'Searching for existing claim packet.' : <>Searching for {props.selectedDocType}</>
                        }
                        </> : 'Loading the document.'}
                        {/* {
                            props.selectedDocType === 'Claim Packet' ? 
                            'Searching for existing claim packet.' : 'Loading the document.'
                        } */}
                        
                    </div>
                </div>
                <div className='custom-progress-bar'>
                    <ProgressBar value={progressValue}></ProgressBar>
                </div></> :
                <div className='w-100 text-center'>
                    <div className='text-success'>Document loaded successfully</div>
                    <div className='mt-2'>
                        <Button color='primary' outline onClick={() => { props.showHideProgressBar(false) }} >Close</Button>
                    </div>
                </div>

            }

        </Dialog>
    </>
}