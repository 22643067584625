import fetchMethodRequest from '../config/service';
import * as documentsAPIs from './documentsApis';

export const getChainOfTitle = async (dealId, mappedNameID) => {    
    const chainOfTitleHir = await getChainOfTitleHir(dealId, mappedNameID);
    if (chainOfTitleHir && chainOfTitleHir.length > 0) {
        const relevanceTypes = await documentsAPIs.getRelevenceTypes();
        const cotRelType = relevanceTypes.filter((elem) => elem.ChainOfTitleFlag).map((obj) => obj.Name).join(',');
        const docList = await documentsAPIs.getDocumentList(getBodyForDocListAPI(dealId,chainOfTitleHir,cotRelType));
        const cotData = formatChainOfTitleData(chainOfTitleHir,docList);
        return Promise.resolve(cotData);
    } else {
        return Promise.resolve({data: [], disable: true});
    }
}

const getChainOfTitleHir = async (dealId, mappedNameID) => {
    const url = `getCOTNamesHierarchy?DealID=${dealId}&BusinessNameID=${mappedNameID}`;
    const res = await fetchMethodRequest('POST', url);    
    if (res && res.outResponse && res.outResponse.details && res.outResponse.details.length > 0) {
        return Promise.resolve(res.outResponse.details);
    } else {
        return Promise.resolve([]);
    }

}

const getBodyForDocListAPI = (dealId, chainOfTitleHir, cotRelType) => {
    return {
        DealId: dealId,
        StateClaimID: null,
        State: null,
        BusinessNameId: chainOfTitleHir.map((elem) => elem.businessNamesID).join(','),
        RelevanceType: cotRelType
    }
}

const formatChainOfTitleData = (chainOfTitleHir, docList) => {
    let cotData = [];
    let disableCOTAdd = false;
    chainOfTitleHir.forEach((hir, i) => {
        const files = docList.filter((elem) => (elem.businessNameID.toString() === hir.businessNamesID.toString() && elem.sharePointDetails && elem.sharePointDetails.length > 0));
        if (files.length > 0) {
            const fileNamesArr = files.map((file) => file.sharePointDetails && file.sharePointDetails.length > 0 ? file.fileName : null);
            if (fileNamesArr.length === 0) {
                disableCOTAdd = true;
            }
            files.forEach((file, j) => {
                cotData.push({
                    ...hir,
                    fileName: fileNamesArr[j],
                    year: file.eventDateYear,
                    description: file.documentRelevanceNotes,
                    hideHir: (j > 0 ? true : false),
                    rowSpanCount: files.length,
                    colorCode: i,
                    docId: file.id
                });
            });
        } else {
            disableCOTAdd = true;
            cotData.push({ ...hir, colorCode: i });
        }
    });
    return { data: cotData, disable: disableCOTAdd };
}
