import React from 'react';
import { Container } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import OwnedNamesandAddresses from './components/OwnedNamesandAddresses';

const ownedNamesandAddresses = ({ t }) => (
  <Container
  // className="Custom_container"
  >
    <OwnedNamesandAddresses />
  </Container>
);

ownedNamesandAddresses.propTypes = {
  t: PropTypes.func.isRequired,
};

export default withTranslation('common')(ownedNamesandAddresses);
