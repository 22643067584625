import React, { Component } from "react";
import {
  Button
} from 'reactstrap';

class Onclick extends Component {

  handleClick() {
    const script = document.createElement("script");
    script.async = true;
    script.src = "/rule-engine/js/ruleengine.build.js";
    let elem = document.getElementById("rule-engine-app")
    let rulediv = document.getElementById("rule-engine-div");
    if (rulediv.style.display === "none") {
      rulediv.style.display = "block";
    } else {
      rulediv.style.display = "none";
    }
    if (rulediv.style.display === "block" && elem && elem.parentNode) {
      elem.parentNode.insertBefore(script, elem.nextSibling);
    }
  }

  getAttributes() { }

  render() {
    return (
      <div className="App" ref={el => (this.div = el)}>
        <br />
        {/* <input type="button" onClick={this.handleClick} value="Show Rule Engine" /> */}
        <Button color='primary' outline onClick={this.handleClick}>
          Show Rule Engine
        </Button>

        <br />
        <br />
        <div id="rule-engine-div">
          <div id="rule-engine-app" name="rules"
            init-attribute-data={JSON.stringify(this.props.attributeData)}
            init-rule-data={this.props["init-rule-data"]}></div>
        </div>
      </div >
    );
  }
}

export default Onclick;