import React from 'react';
import { Container } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import AvailableToMap from './components/AvailableToMap';
const availableToMap = ({ t }) => (
  <Container >
    <AvailableToMap />
  </Container>
);

availableToMap.propTypes = {
  t: PropTypes.func.isRequired,
};

export default withTranslation('common')(availableToMap);
