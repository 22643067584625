import React from 'react';
import { Container } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import Properties from './components/Properties';
import NSProperties from './components/NSProperties';
import DealProperties from './components/DealSizingPropertyEditor';
import PropertyEditor from './components/PropertyEditor';


const editors = ({ t }) => (
  <Container>
    {window.location.pathname === "/properties" ?
      <Properties />
      : window.location.pathname === "/dealproperties" ? <DealProperties />
        : window.location.pathname === "/nsproperties" ? <NSProperties />
          : <PropertyEditor />}
  </Container>
);
editors.propTypes = {
  t: PropTypes.func.isRequired,
};

export default withTranslation('common')(editors);
