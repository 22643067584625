import React from 'react';
import { Col, Container, Row } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import ValidatedPropertyUpdater from './components/ValidatedPropertyUpdater';

const validatedPropertyUpdater = () => (
  <div>
    <ValidatedPropertyUpdater />
  </div>
);

validatedPropertyUpdater.propTypes = {
  t: PropTypes.func.isRequired,
};

export default withTranslation('common')(validatedPropertyUpdater);
