import React from 'react';
import { Container } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import DealTypeConfiguration from './components/DealTypeConfiguration';
const dealTypeConfiguration = ({ t }) => (
  <Container className="Custom_container">
    <DealTypeConfiguration />
  </Container>
);

dealTypeConfiguration.propTypes = {
  t: PropTypes.func.isRequired,
};

export default withTranslation('common')(dealTypeConfiguration);
