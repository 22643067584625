import React, { PureComponent } from 'react';
import DatePicker from 'react-datepicker';
import PropTypes from 'prop-types';
import * as moment from 'moment-timezone';

import config from '../../../config/config';
import { withTranslation } from 'react-i18next';

class DatePickerField extends PureComponent {
  static propTypes = {
    onChange: PropTypes.func.isRequired,
    meta: PropTypes.shape({
      touched: PropTypes.bool,
      error: PropTypes.string,
    }),

  };

  constructor(props) {
    super(props);
    this.state = {
      startDate: null,
      isEditShow: false,
      value: this.props.input.value,
      popperPlacementObj: this.props.popperPlacement ? {
        popperPlacement: this.props.popperPlacement,
        popperModifiers: {
          flip: {
            behavior: [this.props.popperPlacement] // don't allow it to flip to be above
          },
          preventOverflow: {
            enabled: false // tell it not to try to stay within the view (this prevents the popper from covering the element you clicked)
          },
          hide: {
            enabled: false // turn off since needs preventOverflow to be enabled
          }
        }
      } : {}
    };
    this.handleChange = this.handleChange.bind(this);
  }

  componentDidMount = () => {
    if (this.props.currentDate) {
      let date = this.props.currentDate ? this.props.currentDate : null;
      this.handleChange(date);
    }
  }

  handleChange(date) {
    const { onChange } = this.props.input;
    if (date) {
      this.setState({
        startDate: moment(date).toDate(),
        isEditShow: this.props.isEditedShow ? true : false
      });
      if (this.props.ignoreTime && this.props.input.value) {
        if(new Date(date).getHours()>0){
        date = moment(date).subtract(12, "hours").toDate()
        }
      }
      date = moment(date).add(12, "hours").toDate()
      onChange(date);
    }
  }

  updateDatePickerValues = () => {
    this.setState({
      startDate: null,
    });
    const { onChange } = this.props.input;
    if (onChange) {
      onChange(null)
    }
  }

  render() {
    const { startDate } = this.state;
    const { meta, clear, t, editable } = this.props;
    const { name, onChange, value } = this.props.input;
    if ((name === 'startDate' || name === 'endDate') && this.props.minDate && !(startDate && value)) {
      onChange(moment(this.props.minDate).format(config.dateDBFormat));
    }
    const date = startDate && !editable ? startDate : value ? moment(value).toDate() : this.props.minDate ? this.props.minDate : null;

    return (
      <div className="date-picker mb-2">
        <DatePicker
          className={`form__form-group-datepicker mb-2 ${this.state.isEditShow ? 'datepicker_is_edit_show' : ''}`}
          selected={clear ? this.updateDatePickerValues() : date}
          onChange={this.handleChange}
          dateFormat="MM/dd/yyyy"
          peekNextMonth
          showMonthDropdown
          showYearDropdown
          // scrollableYearDropdown
          // yearDropdownItemNumber={150}
          disabled={this.props.isDisabled ? true : false}
          dropdownMode="select"
          maxDate={this.props.maxDate}
          minDate={this.props.minDate}
          placeholderText={this.props.placeholder ? this.props.placeholder : 'Select Date'}
          {...this.state.popperPlacementObj}
        />
        <div style={meta.touched && meta.error ? { height: "13px" } : {}}>
          {meta.touched && meta.error && <span className="form__form-group-error">{t(meta.error)}</span>}
        </div>
        {/* {meta.touched && meta.error && <span className="form__form-group-error">{meta.error}</span>} */}
      </div>
    );
  }
}

const renderDatePickerField = (props) => {
  const { input } = props;
  return <DatePickerField  {...input} {...props}
    clear={props.clear} />;

};

renderDatePickerField.propTypes = {
  input: PropTypes.shape({
    onChange: PropTypes.func,
    name: PropTypes.string,
  }).isRequired,
};

export default withTranslation('common')(renderDatePickerField);
