import React from 'react';
import { Container } from "reactstrap";
import { OpenCorporates } from "./components/OpenCorporates";
import PropTypes from 'prop-types';
import { withTranslation } from "react-i18next";


const OpenCorporatesPage = ({t}:any) => (
    <Container>
        <OpenCorporates />
    </Container>
);

OpenCorporatesPage.prototype = {
    t: PropTypes.func.isRequired,
};

export default withTranslation('common')(OpenCorporatesPage);