import React from 'react';
import { Container } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import PropertyReason from './components/PropertyReason';
const propertyReason = ({ t }) => (
  <Container className="Custom_container">
    <PropertyReason />
  </Container>
);

propertyReason.propTypes = {
  t: PropTypes.func.isRequired,
};

export default withTranslation('common')(propertyReason);
