import React from 'react';
import { Button, Modal, ModalBody, Row, ModalHeader, Label } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import ModalFooter from 'reactstrap/lib/ModalFooter';
import { ColumnGroup } from 'primereact/columngroup';
import classNames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { InputText } from 'primereact/inputtext';
import { Calendar } from 'primereact/calendar';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import * as moment from 'moment-timezone';
import { faArrowCircleLeft, faExpand, faUsers, faFolder, faArrowLeft, faPlus, faMinus, faSave, faExternalLinkAlt, faStarOfLife, faTimes, faTimesCircle, faToggleOff, faFilter, faBuilding } from '@fortawesome/free-solid-svg-icons';


const bodyStyle = {
  textOverflow: 'ellipsis',
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  width: 150,
  fontSize: 12,
  textAlign: "left",
  verticalAlign: "inherit"
}

const headerStyle = {
  width: 150,
  textAlign: "center",
}

// config file
class ClaimPaymentsModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      PaymentsArray: {},
      colored: false,
      header: true,
      clickSaved: false,
      recordData: {}
    };
  }

  componentDidMount = async (type) => {
    if (this.props.onRef) {
      this.props.onRef(this);
    }
    if (this.props.selectedInfo) {
      this.setState({
        checkAmount: this.props.selectedInfo.checkAmount,
        checkForm: this.props.selectedInfo.checkForm,
        checkNumber: this.props.selectedInfo.checkNumber,
        note: this.props.selectedInfo.note,
        checkDate: this.props.selectedInfo.checkDate
      })
    }
    // let PaymentsArray = this.state.PaymentsArray
    // PaymentsArray = await this.updateSlNoToData(this.props.PaymentsArray["Payments"])
    // this.setState({
    //   PaymentsArray: PaymentsArray,
    //   recordData: this.props.PaymentsArray,
    // })
  }

  componentWillUnmount() {
    if (this.props.onRef) {
      this.props.onRef(null);
    }
  }

  updateSlNoToData(itemdata) {
    let modifiedData = [];
    if (itemdata && itemdata.length > 0) {
      itemdata.forEach((item, index) => {
        item.Sno = index + 1;
        modifiedData.push(item);
      });
    }
    return modifiedData;
  }

  openEditModal = (item, rowData) => {
    this.setState({
      EditedIndex: rowData.Sno - 1
    })
    this.props.openFormModal(item, 'edit', "Payments", rowData, rowData.Sno - 1)
  }

  getUpdatedData = async (data) => {
    let PaymentsArray = await this.updateSlNoToData(data["Payments"])
    await this.setState({
      PaymentsArray: PaymentsArray,
      recordData: data
    })
  }

  handleUsersData = (updatedId) => {
    this.props.handleUsersData(updatedId, "paymentDetailedView")
    this.props.cancelReset()
  }

  actionTemplate = (rowData, column) => {
    return <div className='my-1'>
      <Button className='mb-0' color="primary" outline onClick={() => this.openEditModal(this.state.recordData, rowData)}>Edit</Button>

    </div>;
  }
  dateTemplate = (rowData, column) => {
    return <span className='d-flex justify-content-end'>
      {rowData["checkDate"] ? moment(rowData["checkDate"]).format("MM-DD-YYYY") : "N/A"}
    </span >
  }

  hoverTemplate = (rowData, column) => {
    return <span className='' data-toggle="tool-tip"
      title={rowData[column.field]} style={{ textOverflow: "ellipsis" }}>
      {rowData[column.field] ? rowData[column.field] : ""}
    </span >
  }

  cancelReset = () => {
    this.props.cancelReset("Payments", this.state.recordData["_id"].OppClaimID)
  }
  SavePaymentsData = async () => {
    await this.setState({
      clickSaved: true
    })
    let obj = {}
    obj.checkAmount = this.state.checkAmount
    obj.checkDate = this.state.checkDate
    obj.checkForm = this.state.checkForm
    obj.checkNumber = this.state.checkNumber
    obj.note = this.state.note
    if (this.state.checkAmount && this.state.checkDate) {
      this.props.paymentSubmit(obj)
    }

  }

  getreducedNumber() {
    let dollarUS = Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
    });
    let val
    let initialValue = 0
    let sum = this.state.recordData["Payments"].reduce(function (accumulator, curValue) {
      return accumulator + curValue.checkAmount
    }, initialValue)
    val = dollarUS.format(sum)

    return val;
  }
  SetValues = async (e, type) => {
    await this.setState({ [type]: e.target.value })
  }

  getSiteDiscoveryFooter() {
    return <ColumnGroup>
      <Row>
        <Column footer="Total Payment Amount:" colSpan={2} />
        <Column colSpan={4} footer={this.state.recordData && this.state.recordData["Payments"] ? this.getreducedNumber() : null} />
      </Row>
    </ColumnGroup>;
  }
  closeFormModal = async () => {
    await this.props.closeFormModal()
  }
  formatCurrency(rowData, column) {
    let dollarUS = Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
    });
    let val
    val = dollarUS.format(rowData["checkAmount"])
    // let val = item[column.field]
    return <span className='d-flex justify-content-end'>
      {rowData["checkAmount"] !== undefined && rowData["checkAmount"] !== null && rowData["checkAmount"] !== "" && val !== "$NaN" ? val : "N/A"}
    </span >
  }
  render() {
    const footerGroup = this.getSiteDiscoveryFooter()
    const modalClass = classNames({
      'modal-dialog--colored': this.state.colored,
      'modal-dialog--header': this.state.header,
    });
    return (
      < div >

        <Modal isOpen={this.props.openpaymentsModal} fade={true}
          className={`right modal-dialog-centered modal-dialog--primary  ${modalClass}`}
          style={{ maxWidth: 1050 }}

        >
          <ModalHeader className={"modal__header kanbanScreen d-flex "} style={{ justifyContent: "center" }}>
            <p className="bold-text  modal__title ml-2" > {this.props.formType == "add" ? 'Add Payment Details' : "Edit Payment Details"}</p>
          </ModalHeader>
          <ModalBody className='deleteModalBody' style={{
            padding: 10,
            // width: 110,
            maxHeight: "87vh",
            height: "auto",
            overflow: "auto",
            textAlign: "left"

          }}>
            <>

              <div className="form__form-group mb-3" style={{ height: '50px' }}>
                <Label className="form__form-group-label">Check From</Label>
                <InputText
                  type="search"
                  name='checkForm'
                  id='checkForm'
                  value={this.state['checkForm']}
                  style={{ width: "100%" }}
                  onChange={(e) => this.SetValues(e, "checkForm")}
                  placeholder={'Check From'}
                />
              </div>
              <div className="form__form-group mb-3" style={{ height: '50px' }}>
                <Label className="form__form-group-label">Note</Label>
                <InputText
                  type="note"
                  name='note'
                  id='Note'
                  value={this.state['note']}
                  style={{ width: "100%" }}
                  onChange={(e) => this.SetValues(e, "note")}
                  placeholder={'Note'}
                />
              </div>
              <div className="form__form-group mb-3" style={{ height: '50px' }}>
                <Label className="form__form-group-label">Check Number</Label>
                <InputText
                  type="number"
                  name='checkNumber'
                  id='Check Number'
                  value={this.state['checkNumber']}
                  style={{ width: "100%" }}
                  onChange={(e) => this.SetValues(e, "checkNumber")}
                  placeholder={'Check Number'}
                />
              </div>
              <div className="form__form-group mb-3" style={{ height: '50px' }}>
                {/* <Label className="form__form-group-label">Check Amount</Label> */}
                {"Check Amount"}
                <span >
                  <FontAwesomeIcon icon={faStarOfLife}
                    className='pl-1'
                    color='red'
                    data-toggle="tool-tip"
                    title="Mandotary Field"
                    style={{ width: 11 }}
                  /></span>
                <InputText
                  type="number"
                  name='checkAmount'
                  id='Check Amount'
                  value={this.state['checkAmount']}
                  style={{ width: "100%" }}
                  onChange={(e) => this.SetValues(e, "checkAmount")}
                  placeholder={'Check Amount'}
                />
                {!this.state.checkAmount && this.state.clickSaved && <small className='text-danger'>Please fill above field</small>}
              </div>
              <div className="form__form-group mb-3" style={{ height: '50px' }}>
                {"Check Date"}
                <span >
                  <FontAwesomeIcon icon={faStarOfLife}
                    className='pl-1'
                    color='red'
                    data-toggle="tool-tip"
                    title="Mandotary Field"
                    style={{ width: 11 }}
                  /></span>
                <Calendar
                  value={this.state.checkDate && this.state.checkDate !== undefined ? new Date(this.state.checkDate) : null}
                  style={{ width: "100%" }}
                  onChange={(e) => this.SetValues(e, "checkDate")}
                  monthNavigator={true} yearNavigator={true}
                  yearRange="2010:2030" />
                {!this.state.checkDate && this.state.clickSaved && <small className='text-danger'>Please fill above field</small>}
              </div>

            </>
          </ModalBody>
          <ModalFooter className="modalFooter " style={{ display: "block", width: "100%", padding: "0px", backgroundColor: "white", borderTop: "none" }}>
            <div>
              {/* <Button color="primary" outline
                // onClick={this.props.saveClaimNotes(this.state.comment)}
                onClick={() => this.handleUsersData(this.state.recordData)}
                className='deleteModalBtn marginRight'>Save</Button> */}
              <Button color='danger' outline type="button" className={this.props.displayViewOfForm === 'screen' ? "m-2" : "mr-auto mb-0"} onClick={this.closeFormModal}>
                <FontAwesomeIcon
                  style={{ width: "15px" }}
                // icon={faArrowCircleLeft}

                />
                {('Back') ? ('Cancel') : 'Cancel'} </Button>
              <Button
                style={{ float: "right" }}
                color='primary'
                className={this.props.displayViewOfForm === 'screen' ? "m-2" : "mb-0"}
                outline
                onClick={() => this.SavePaymentsData()}
                // type="submit"
                disabled={this.state.hidebtn}
              >
                <FontAwesomeIcon
                  style={{ width: "15px" }}
                  icon={faSave}
                />

                {this.props.formType == "add" ? 'Save' : "Update"}
              </Button>

            </div>

          </ModalFooter>
        </Modal >
      </div >
    );
  }
}
export default withTranslation('common')(ClaimPaymentsModal);
