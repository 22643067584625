import React, { useState, useEffect } from 'react';
import Moment from 'moment-timezone';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { Dropdown } from 'primereact/dropdown';
import { Calendar } from 'primereact/calendar';
import apiCalls from '../../../config/apiCalls';
import config from '../../../config/config'
import dateFormats from '../../UI/FormatDate/formatDate';
import ConfirmationModal from '../../Cruds/CommonModals/ConfirmationModal'
import { withTranslation } from 'react-i18next';
import fetchMethodRequest from '../../../config/service';
import { InputText } from 'primereact/inputtext';


const DeleteRowModal = (props) => {

  let today = new Date();
  let month = today.getMonth();
  let year = today.getFullYear();
  let prevMonth = (month === 0) ? 11 : month;
  let prevYear = (prevMonth === 11) ? year : year;


  const [scheduledDate, setscheduledDate] = useState("");
  const [selectedFrequency, setselectedFrequency] = useState("");
  const [Iteration, setIteration] = useState(0);
  const [pickupTime, setpickupTime] = useState(0);

  const [isOpenConfirmationModal, setisOpenConfirmationModal] = useState(false);
  let minDate = new Date();
  minDate.setMonth(prevMonth);
  minDate.setFullYear(prevYear);


  const setuploadToServer = (type) => {
    if (selectedFrequency === "ASAP") {
      closeConfirmationModal("close")
    } else {
      setisOpenConfirmationModal(true)
    }
  }

  const closeConfirmationModal = (type) => {
    if (type === "close") {
      let selectedRowsId = []
      let selectedRows = props.selectedRows
      if (selectedRows && selectedRows.length && selectedRows.length > 0) {
        selectedRows.forEach((item, index) => {
          selectedRowsId.push(item._id)
        })
      }
      let url = props.selectedvalue === "Queue" ? `${apiCalls.AddtoQueue}` : `${apiCalls.UpdatedScheduledDate}`;
      let userbody = {
        ids: selectedRowsId,
        frequency: selectedFrequency,

        iterations: parseInt(Iteration)
      }
      if (scheduledDate !== "") {
        userbody.NextScheduledDate =
          dateFormats.formatDate(
            scheduledDate,
            config.dbcstOnlyDateFormat, "nhg")
      }
      if (pickupTime !== "") {
        userbody.pickupTime =
          dateFormats.formatDate(
            pickupTime,
            config.timeFormatfull, "nhg")
      }
      return fetchMethodRequest("POST", url, userbody).then(async (response) => {
        if (response && response.respMessage) {
          props.closeAddtoQueueModal()
        } else if (response && response.errorMessage) {
          props.closeAddtoQueueModal(response)
        }
      });
    } else {
      setisOpenConfirmationModal(false)
    }
  }

  const getConfirmaionModel = () => {
    return (
      <ConfirmationModal
        openConfirmationModal={isOpenConfirmationModal}
        closeConfirmationModal={closeConfirmationModal}
        shownText={"Are you sure do you want to overide frequency with the existing frequency?"}
      // confirm={this.saveValidatedRecord}
      />
    )
  }


  return (
    <>
      <Modal isOpen={props.openAddtoQueueModal} fade={false} className='bulkUploadModalHeight'>
        <ModalHeader >
          {props.selectedvalue === "Queue" ? "Add to Queue" : "Update Scheduled Date"}
        </ModalHeader>

        <ModalBody >
          <div className='row'>
            <div className='d-flex' >
              <div className='col-sm-4' style={{ textAlign: "end" }}>
                Frequency:
              </div>
              <div className='col-sm-9' style={{ paddingLeft: 6 }}>
                <Dropdown
                  value={selectedFrequency}
                  appendTo={document.body}
                  options={config.shifts}
                  filter={true}
                  style={{ width: "100%" }}
                  onChange={(e) => setselectedFrequency(e.value)}
                />

              </div>
            </div>
            <div className='d-flex ml-2 mt-2 ' >
              <div className='col-sm-3' style={{ textAlign: "end" }}>
                {props.selectedvalue === "Queue" ? null : "Date:"}
              </div>
              <div className='col-sm-9' style={{ paddingLeft: 13 }}>
                {props.selectedvalue === "Queue" ? null : <Calendar
                  // showTime={true}
                  hourFormat="24"
                  monthNavigator={true}
                  yearNavigator={true}
                  minDate={new Date()}
                  yearRange="1940:2530"
                  value={scheduledDate}
                  // onChange={(e) => this.setState({ scheduledDate: e.value })}>
                  onChange={(e) => setscheduledDate(e.value)}>
                </Calendar>}
              </div>
            </div>
            <div className='d-flex ml-2 mt-2 ' >
              <div className='col-sm-3' style={{ textAlign: "end" }}>
                {props.selectedvalue === "Queue" ? null : "Pickup TIme:"}
              </div>
              <div className='col-sm-9' style={{ paddingLeft: 13 }}>
                {props.selectedvalue === "Queue" ? null : <Calendar
                  value={pickupTime}
                  onChange={(e) => setpickupTime(e.value)}
                  timeOnly={true} hourFormat="12"
                />
                }
              </div>
            </div>
            <div className='d-flex ml-2 mt-2' >
              <div className='col-sm-3' style={{ textAlign: "end" }}>
                {props.selectedvalue === "Queue" ? null : "Iterations:"}
              </div>
              <div className='col-sm-9' style={{ paddingLeft: 6 }}>
                {props.selectedvalue === "Queue" ? null : <InputText
                  value={Iteration}
                  onChange={(e) => setIteration(e.target.value)}
                />
                }
              </div>
            </div>
          </div>
        </ModalBody>
        <ModalFooter >
          <Button color='primary' className="mb-0" outline onClick={props.closeAddtoQueueModal}>Close</Button>
          <Button color='primary' className="mb-0" outline onClick={setuploadToServer}>        {props.selectedvalue === "Queue" ? "Add to Queue" : "Update Scheduled Date"}
          </Button>
        </ModalFooter>
      </Modal >
      {
        isOpenConfirmationModal ?
          getConfirmaionModel() : null
      }</>
  )
}
export default withTranslation('common')(DeleteRowModal);
