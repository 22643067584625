import React, { useEffect, useRef, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSortAmountDownAlt,faSortAmountUpAlt,faSort} from "@fortawesome/free-solid-svg-icons";
import { Paginator } from 'primereact/paginator';
import { Button } from 'reactstrap';

interface column {
    field: string,
    header: string,
    filter: boolean,
    filterPlaceHolder: string,
    sortable: boolean,
    style?: object,
    body?:any,
    formatter?:Function
    
}

interface props {
    data: Array<any>,
    pagination: boolean,
    rows: number,
    removableSort: boolean,
    sortMode: 'multiple' | 'single',
    columns: Array<column>,
    rowsPerPageOption: Array<number>
    emptyMessage: string,
    getDocumentsData:Function,
    closeModal:Function,
    disableEnableLoadButton:Function,
    setSelectedFilesInParent:Function,
    loadedDocuments?:any,    
    disableLoadButton?:boolean,
    disableSelection?:boolean,
    selectedFilesNumbers:Array<string>   
}


export const StaticGrid = ({ data, pagination, rows, removableSort, sortMode, columns, rowsPerPageOption, emptyMessage, getDocumentsData,closeModal,loadedDocuments,disableEnableLoadButton,disableLoadButton,setSelectedFilesInParent,disableSelection,selectedFilesNumbers }:props) => {
    let opData = useRef(JSON.parse(JSON.stringify(data)));    ;
    let lastSort = useRef({field: '',sortType:null});    
    let filteredRows = useRef(JSON.parse(JSON.stringify(data)));
    let filters:any = useRef([]);
    let selectedAll:any = useRef(false);
     
    const [displayData, setDisplayData] = useState<any>([]);
    const [rowsNum, setRows] = useState(rows);
    const [first, setFirst] = useState(0);
    const [sortDetails, setSortDetails] = useState<any>({});
    const [totalRecords, setTotalRecords] = useState<number>(data.length);
    const [selectedFiles, setSelectedFiles] = useState<any>({});
    const [disableButton, setDisableButton] = useState(true);   

    useEffect(() => {
        const num = getrowsPerPage() ;
        const numRows = num ? num : rows;
        setRows(numRows);
    }, [rows]);

    useEffect(() => {
        setDisplayData(data.slice(0, rowsNum));        
        setTotalRecords(data.length);
        opData.current = JSON.parse(JSON.stringify(data));
    }, [data]);

    useEffect(() => {
        let filteredResults = opData.current.slice(first, rowsNum + first);
        setDisplayData(filteredResults);                
    }, [first]);

    useEffect(() => {
        let filteredResults = opData.current.slice(0, rowsNum);
        setDisplayData(filteredResults);       
    }, [rowsNum]);

    useEffect(() => {
        checkSelection();     
    }, [selectedFiles]);

    useEffect(() => {        
        if(Object.keys(selectedFiles).length === 0 && selectedFilesNumbers && selectedFilesNumbers.length > 0){
            let selFiles:any = {};
            selectedFilesNumbers.forEach((e:string) => {
                selFiles[e] = true;
            });            
            setSelectedFiles(selFiles);            
        }
    },[selectedFilesNumbers])

    // useEffect(() => {       
    //     let selFiles:any = {}
    //     data.forEach((e:any) => {
    //         if (e.documentNumber && loadedDocuments[e.documentNumber]) {
    //             selFiles[e.documentNumber] = true;
    //         }
    //     });
    //     setSelectedFiles((prevSel:any) => ({...prevSel,...selFiles}));        
    //     setSelectedFilesInParent(selFiles);
    // },[loadedDocuments]);

       
   
   
    const onFilter = (field: string, value: any) => {
        let index = filters.current.findIndex((e: any) => e.field === field);
        if (value) {
            // Saving filtered field and value 
            if (index === -1) {
                filters.current.push({ field: field, value: value });
            } else {
                filters.current[index] = { field: field, value: value };
            }
            // Filtering data
            opData.current = opData.current.filter((e: any) => e[field] && e[field].toLowerCase().includes(value.toLowerCase()));
        } else {
            if (index > -1) {
                // Removing filter on user empty or remove value in search box.
                filters.current.splice(index, 1);
            } 
            //Filtering records based on saved filters on user empty or remove value in one of the search box            
            let fitered: Array<any> = JSON.parse(JSON.stringify(data));
            if (filters.current.length > 0) {
                filters.current.forEach((filter: any) => {
                    fitered = data.filter((e: any) => e[filter.field] && e[filter.field].toLowerCase().includes(filter.value.toLowerCase()));
                });
            }
            opData.current = JSON.parse(JSON.stringify(fitered));
        }
        setTotalRecords(opData.current.length);
        setDisplayData(opData.current.slice(0, rowsNum));
        setSelectedFilesOnFilterChanges(opData.current);
        setFirst(0);
        if (lastSort.current.sortType && lastSort.current.field) {
            sortData(lastSort.current.field, lastSort.current.sortType,lastSort);
        }
    }

    const onPageChange = (event: { first: number, rows: number }) => {
        if (first !== event.first) {
            setFirst(event.first);
        }
        if (rowsNum !== event.rows) {
            setRows(event.rows);
        }
    };

    const sortImplementation = (col:any) => {
        return <span onClick={() => { onClickSort(col.field) }} style={{cursor:'pointer'}}>
            {sortDetails[col.field] === 'asc' && <FontAwesomeIcon icon={faSortAmountUpAlt} className="cursor-pointer" />}
            {sortDetails[col.field] === 'dsc' && <FontAwesomeIcon icon={faSortAmountDownAlt} className="cursor-pointer" />}
            {!sortDetails[col.field] && <FontAwesomeIcon icon={faSort} className="cursor-pointer" />}
        </span>
    }

    const onClickSort = (field: string) => {
        let sortObj = JSON.parse(JSON.stringify(sortDetails));        
        if (sortObj[field]) {
            sortObj[field] = (sortObj[field] === 'asc') ? 'dsc' : null;
        } else {
            sortObj[field] = 'asc';
        }       
        sortData(field,sortObj[field],sortObj);
       
    }

    const sortData = (field: string, sortType: any,sortObj:object) => {
        let tempData = JSON.parse(JSON.stringify(opData.current));
        const emptyFieldRows = tempData.filter((e: any) => !e[field]);
        const availFieldRows = tempData.filter((e: any) => e[field]);
        if (sortType === 'asc') {
            availFieldRows.sort((a: any, b: any) => a[field].toLowerCase().localeCompare(b[field].toLowerCase()));
        } else if (sortType === 'dsc') {
            availFieldRows.sort((a: any, b: any) => b[field].toLowerCase().localeCompare(a[field].toLowerCase()));
        }
        if (sortType) {
            opData.current = [...availFieldRows, ...emptyFieldRows];
        } else {
            opData.current = JSON.parse(JSON.stringify(filteredRows.current));
        }
        lastSort.current = { field: field, sortType: sortType };
        setFirst(0);
        setDisplayData(opData.current.slice(0, rowsNum));
        setSortDetails(sortObj);
    }

   const onSelectCheckBox = (docNumber:any) => {
       let selFiles = JSON.parse(JSON.stringify(selectedFiles));             
       selFiles[docNumber] =  selFiles[docNumber] ? false : true;
       if (selFiles[docNumber] === false) {
           delete selFiles[docNumber];
       }
       setSelectedFiles(selFiles);
       setSelectedFilesInParent(selFiles);      
   }

    const onSelectAll = (checked:any) => {        
        let selFiles: any = {}
        selectedAll.current = checked;
        opData.current.forEach((e: any) => {
            if (e.documentNumber) {
                selFiles[e.documentNumber] = checked;
            }
        });
        setSelectedFiles(selFiles); 
        setSelectedFilesInParent(selFiles);         
    }

    const setSelectedFilesOnFilterChanges = (filteredRecords: Array<any>) => {
        if (selectedAll.current) {
            onSelectAll(true);
        } else {
            let selFiles = JSON.parse(JSON.stringify(selectedFiles));
            let filterRecordsDocNum:any = {};
            filteredRecords.forEach(element => {
                filterRecordsDocNum[element.documentNumber] = true;
            });
            for(const docNum in selFiles){
                selFiles[docNum] =  filterRecordsDocNum[docNum] ? true : false;
            }
            setSelectedFiles(selFiles);
            setSelectedFilesInParent(selFiles);  
        }
    }

    const checkSelection = () => {
        let disable = true;
        for (const prop in selectedFiles) {
            if(selectedFiles[prop] && !loadedDocuments[prop]){
                disable = false;
            }
        }        
        disableEnableLoadButton(disable)
        setDisableButton(disable);
    }

    const onClickLoad = () => {
        const selFiles = data.filter((row:any) => selectedFiles[row.documentNumber] && !loadedDocuments[row.documentNumber]);          
        getDocumentsData(selFiles);
    }

    const getrowsPerPage = () => {
        const loginDetails = localStorage.getItem('loginCredentials') ? JSON.parse(localStorage.getItem('loginCredentials') || '') : null;
        const limit = (loginDetails && loginDetails.pageLimit) ? parseInt(loginDetails.pageLimit) : localStorage.getItem("defaultPageOption") ? parseInt(localStorage.getItem("defaultPageOption") || '') : 10;
        return limit;
    }
 
    return <div className='p-2 chain-of-title text-center' style={{ height:'70vh',width:'93vw', overflowY: 'auto' }}>
        <div className='d-flex flex-column justify-content-center'>
            <table className="table table-bordered">
                <thead>
                    <tr>
                        <th><input type='checkBox' style={{ height: 'unset' }} value={'selected'} onChange={(e) => { onSelectAll(e.target.checked) }} /></th>
                        {columns.map((column) => <th>
                            <div>{column.header} {sortImplementation(column)} </div><div><input type="text" placeholder="Search" style={{ width: '100%', padding: '0.5rem' }} onChange={(e) => onFilter(column.field, e.target.value)}  /></div>
                        </th>)}
                    </tr>
                </thead>
                <tbody>
                    {displayData.map((row:any, i:number) => {
                        return <tr>
                            <td><input type='checkBox' style={{ height: 'unset' }} checked={selectedFiles[row.documentNumber]} onChange={() => {onSelectCheckBox(row.documentNumber)}} disabled={loadedDocuments[row.documentNumber] || disableSelection} /></td>
                            {columns.map((column) => <td style={column.style ? column.style:{}}>{column.formatter ? column.formatter(row) : row[column.field]}</td>)}
                        </tr>
                    })}
                </tbody>
            </table>
        </div>
        <Paginator first={first} rows={rowsNum} totalRecords={totalRecords} rowsPerPageOptions={rowsPerPageOption}  onPageChange={onPageChange}  rightContent={<>{`Number of Records : ${totalRecords}`} &nbsp;&nbsp;</>}></Paginator>
        
    </div>
}