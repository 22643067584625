import React from 'react';
import { Container } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import Attribute from './components/Attribute';
const attribute = ({ t }) => (
  <Container>
    <Attribute />
  </Container>
);

attribute.propTypes = {
  t: PropTypes.func.isRequired,
};

export default withTranslation('common')(attribute);
