import React from 'react';
import DataTables from '../../CommonDataTable/DataTable';
import apiCalls from '../../../../config/apiCalls'
import fetch from '../../../../config/service';
import store from '../../../App/store';
// import RolePermissions from '../../CommonModals/Permissions';

// config file
export default class AvailableToFIleStatus extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      type: 'Available To File - Statuses'
    };
  }

  static getDerivedStateFromProps(props, state) {
    let storeData = store.getState()
    let languageData = storeData && storeData.settingsData && storeData.settingsData.settings && storeData.settingsData.settings.languageTranslation ? storeData.settingsData.settings.languageTranslation : ""
    return { languageData: languageData };
  }



  componentDidMount = async () => {
    await this.getStatusOptions()
    //Screen permisions value can be edit,view, no view
    // let screenPermissions = RolePermissions.screenPermissions('Employees');
  }
  getStatusOptions = () => {
    let filterCriteria = {}
    fetch('GET', `${apiCalls.propertyStatusReason}?filter=${JSON.stringify(filterCriteria)}&dropdown=status`)
      .then(async (response) => {
        if (response) {
          let statuses = response.propertyStatusReasons;
          let modifiedStatuses = [];
          for (let status of statuses) {
            modifiedStatuses.push({
              label: status.status,
              value: {
                status: status.status,
                id: status._id,
              },
            })
          }
          await this.setState({
            Statuses: modifiedStatuses
          })
          // this.setUnitName()

        }
      }).catch((err) => {
        return err;
      });
  }

  getTableFields = () => {
    let data = [
      // {
      //   "show": true,
      //   textAlign: 'left',
      //   width: 6,
      //   field: 'reOrder',
      //   // "displayInSettings": true,
      //   // "displayInFilter": true,
      //   // sortable: true,
      // },
      {
        "show": true,
        textAlign: 'left',
        // width: 60,
        field: 'screen',
        fieldType: 'BoolText',
        "mobile": true,
        header: 'Screen',
        filter: true,
        "displayInSettings": true,
        "displayInFilter": true,
        sortable: true,
      },

      {
        "show": true,
        textAlign: 'left',
        // width: 60,
        field: 'status',
        fieldType: 'BoolText',
        "mobile": true,
        header: 'Status',
        filter: true,
        "displayInSettings": true,
        "displayInFilter": true,
        sortable: true,
      },
      {
        "show": true,
        textAlign: 'left',
        // width: 80,
        field: 'reasons',
        fieldType: 'Array',
        "mobile": true,
        header: 'Reason',
        "filter": true,
        "displayInSettings": true,
        "displayInFilter": true,
        sortable: true,
      },
      {
        "show": true,
        "textAlign": "left",
        // "width": 20,
        "fieldType": 'Actions',
        "field": "Actions",
        "header": "Actions",
        "filter": false,
        "displayInSettings": true,
        "sortable": false
      },

    ]
    return data;
  };

  getFormFields = () => {
    return ([
      {
        //'show': false,
        // 'isMultiSelect': true,
        "value": "",
        "type": "dropDown",
        "name": "screen",
        "label": "Screen",
        "options": [{ label: "Ready To File", value: "Ready To File" }, { label: "On Hold", value: "On Hold" }, { label: "Resource Needed", value: "Resource Needed" }, { label: "Seller Negative Notice", value: "Seller Negative Notice" }, { label: "CA-N Properties", value: "CA-N Properties" }],
        "id": "Screen",
        "placeholder": "Screen",
        "required": true
      },
      {
        //'show': false,
        // 'isMultiSelect': true,
        "value": "",
        "type": "dropDown",
        "name": "status",
        "label": "Available To File Status",
        "options": this.state.Statuses,
        "id": "Available To File Status",
        "placeholder": "Available To File Status",
        "required": true
      },
      {
        //'show': false,
        // 'isMultiSelect': true,
        "value": "",
        "type": "dropDown",
        "name": "reasons",
        "label": "Available To File Reason",
        isMultiSelect: true,
        // "options": this.state.Statuses,
        "id": "Available To File Reason",
        "placeholder": "Available To File Reason",
        "required": true
      },
      {
        //'show': false,
        "value": "",
        "type": "switch",
        "name": "activeIndicator",
        "label": "Active /Inactive Status",
        "id": "status",
        "placeholder": "status",
        // "required": true
      },
      // {
      //   //'show': false,
      //   'isMultiSelect': true,
      //   "value": "",
      //   "type": "dropDown",
      //   "name": "screenType",
      //   "label": "Screen",
      //   "id": "name",
      //   "options": [{ label: "Property Editor", value: "Property Editor" }, { label: "Claims", value: "Claims" }],
      //   "placeholder": "Screen",
      //   "required": true
      // },
    ]);
  }


  submit = async (item) => {

  }

  render() {
    return (
      <span>
        <DataTables
          onRef={(ref) => (this.datatableref = ref)}
          getTableFields={this.getTableFields}
          formFields={this.getFormFields}
          sortField={"sequence"}
          exportRequried={false}
          printRequried={false}
          addRequried={true}
          editRequired={true}
          isClearFilters={true}
          deleteRequired={false}
          viewRequired={true}
          settingsRequired={false}
          filterRequired={true}
          gridRequried={false}
          sample={false}
          globalSearchFieldName='user'
          globalSearch={'Search'}
          type='Available To File - Statuses'
          displayright={true}
          icon='tag'
          routeTo='statusReason'
          displayViewOfForm='modal'
          apiResponseKey={"statusConfigurations"}
          apiUrl={apiCalls.AvailableToFile}
          entityType='employee'
        />

      </span>
    );
  }
}