import React from 'react';
import { Container } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import ReportBug from './components/ReportBug';

const ReportBugPage = ({ t }) => (
    <Container>
        <ReportBug />
    </Container>
);

ReportBugPage.propTypes = {
    t: PropTypes.func.isRequired,
};

export default withTranslation('common')(ReportBugPage);
