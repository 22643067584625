import React from 'react';
import Loader from '../../../App/Loader';
import configMessages from '../../../../config/configMessages';
import { Table } from 'reactstrap';
import fetch from '../../../../config/service'
//config
import apiCalls from '../../../../config/apiCalls';
import dateFormats from '../../../UI/FormatDate/formatDate';
import config from '../../../../config/config';


// config file
class TaskHistory extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      activities: [],
      isLoading: false,
    };
  }


  componentWillUnmount() {
    if (this.props.onRef) {
      this.props.onRef(null);
    }
  }

  componentWillMount() {
    if (this.props.onRef) {
      this.props.onRef(null);
    }

  }

  componentDidMount = async () => {
    if (this.props.onRef) {
      this.props.onRef(this);
    }
    this.getTaskHistoryData()
  }

  handlePercentage = async (Val) => {
    let counter = 0;
    const interval = setInterval(async () => {
      if (this.state.isLoading) {
        counter = counter + 15;
        await this.setState({
          progress: counter,
        })
      } else {
        if (!this.state.isLoading) {
          clearInterval(interval);
        }
      }
    }, 100);
  }


  getTaskHistoryData = async () => {
    this.setState({
      isLoading: true
    })
    this.handlePercentage('1')
    fetch('GET', `${apiCalls.TaskHistoryEffort}/${this.props.rowData.taskID}`)
      .then(async (response) => {
        if (response && response.durations) {
          await this.setState({
            activities: response.durations,
            isLoading: false
          })
        } else if (response && response['errorMessage'] && response['errorMessage'] === configMessages.warningMessage) {
          await this.setState({
            sessionWarning: true,
            activities: [],
          })
        } else if ((response && response['errorMessage'] && response['errorMessage'] === configMessages.sessionExpired) || response['errorMessage'] === configMessages.tokenMessage) {
          await this.setState({
            sessionExpired: true,
            activities: [],
          })
        } else {
          await this.setState({
            activities: [],
          })
        }
      }).catch((err) => {
        return err;
      });
  }


  TaskhistoryTab() {
    return (
      <div className='col-12 px-0 splitTasks m-3' style={{ height: 400, width: "96%", overflow: "auto" }}>
        <Loader loader={this.state.isLoading} progress={this.state.progress} className="screen-loader mb-2" />
        <Table bordered={true} className={this.state.isLoading ? "mt-4" : ""}>
          <thead>
            <tr>
              <th>Date</th>
              <th>Label/Purpose</th>
              <th>Assigned To</th>
              <th>Effort</th>
              <th>Duration</th>
            </tr>
          </thead>
          <tbody style={{ maxHeight: 80 }}>
            {this.state.activities && this.state.activities.length > 0 ?
              this.state.activities.map((item, i) => {
                var hours = Math.floor(item.activeSpentInMinutes / 60);
                var minutes = item.activeSpentInMinutes % 60;
                var hoursDuration = Math.floor(item.durationInMinutes / 60 % 24);
                var minutesDuration = item.durationInMinutes % 60;
                var days = Math.floor(item.durationInMinutes / 24 / 60);

                // return hours + ":" + minutes;     
                return <tr key={i}>
                  <td >{dateFormats.formatDate(item.from, config.basicDateFromat)}</td>
                  <td> {item.label}/{item.purpose}</td>
                  <td> {item.assignTo}</td>
                  <td> {hours + " Hrs " + Math.round(minutes.toFixed(2)) + " Mins"}</td>
                  <td> {days + " Days " + hoursDuration + " Hrs " + Math.round(minutesDuration.toFixed(2)) + " Mins"}</td>

                </tr>
              }) : configMessages.NoActivityMessage}
          </tbody>

        </Table>
      </div>
    )

  }

  render() {
    return (
      <div>{this.TaskhistoryTab()}</div>
    )
  }
}

export default TaskHistory;
