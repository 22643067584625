import React from 'react';
import { Button, Modal, ModalHeader, ModalBody } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import fetchMethodRequest from '../../../config/service';
// import Loader from '../../App/Loader';
import { color } from 'highcharts';
import "../../../scss/summaryStats.scss"

// config file
class SummaryStatsModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        };
    }

    componentDidMount = async () => {
        this.getStatsFromServer()
    }

    getStatsFromServer = async () => {
        // this.setState({ isLoading: true })
        fetchMethodRequest('POST', this.props.statsUrl, this.props.statsBody)
            .then(async (response) => {
                if (response && response.summaryStats) {
                    const result = response.summaryStats.reduce((acc, curr) => {
                        acc[curr._id] = curr.totalRecords;
                        acc[curr._id + "_Value"] = curr.totalValue;
                        acc[curr._id + "_PropertyIds"] = curr.propertyIds;
                        return acc;
                    }, {});
                    this.setState({
                        summaryStats: result,
                        isLoading: false
                    })
                }
            }).catch((err) => {
                return err;
            });
        // this.setState({ isLoading: false })
    }

    closeTab = async (query) => {
        console.log(query)
        console.log(this.state.summaryStats[query])
        if (this.state.summaryStats[query] !== null && this.state.summaryStats[query] !== 0 && this.state.summaryStats[query] !== undefined) {
            this.props.queryUpdate(this.state.summaryStats[query + "_PropertyIds"].length > 0 ? this.state.summaryStats[query + "_PropertyIds"] : [])
        }
    }
    render() {
        const { t } = this.props;
        return (
            <div>
                {/* <Loader loader={this.state.isLoading} className={"progress-loader"} /> */}
                <Modal isOpen={this.props.openSummaryStatsModal} fade={false}
                    className={` modal-dialog--primary modal-dialog--header `}
                    style={{ maxWidth: "500px" }}
                >
                    <ModalHeader className="modal__header viewModalHeader" >
                        Summary of positively mapped properties
                        <button className="lnr lnr-cross modal__close-btn" type="button" style={{ color: "darkgray" }}
                            onClick={this.props.closeSummaryStatsModal} />
                    </ModalHeader>
                    <ModalBody>
                        <div className="scoring-table-container">
                            <table className="scoring-table">
                                <thead>
                                    <tr>
                                        <th>Value type</th>
                                        <th>Total Known Count</th>
                                        <th>Total Known Value</th>
                                    </tr>
                                </thead>
                                {this.state.summaryStats ?
                                    <thead>
                                        <tr>
                                            <th>Known Count</th>
                                            <th>{<a onClick={() => this.closeTab("Known")} ><u>{this.state.summaryStats["Known"] ? this.state.summaryStats["Known"] : 0}</u></a>}</th>
                                            <th>{this.state.summaryStats["Known_Value"] ? new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(this.state.summaryStats["Known_Value"]) : 0}</th>
                                        </tr>
                                    </thead>
                                    : null}
                                {this.state.summaryStats ?
                                    <thead>
                                        <tr>
                                            <th>Unknown Count</th>
                                            <th>{<a onClick={() => this.closeTab("Unknown")} ><u>{this.state.summaryStats["Unknown"] ? this.state.summaryStats["Unknown"] : 0}</u></a>}</th>
                                        </tr>
                                    </thead>
                                    : null}
                                {this.state.summaryStats ?

                                    <tbody>
                                        <tr>
                                            <td>{"Unknown < $100"}</td>
                                            <td>{<a onClick={() => this.closeTab("<100")} ><u>{this.state.summaryStats["<100"] ? this.state.summaryStats["<100"] : 0}</u></a>}</td>
                                        </tr>
                                        <tr>
                                            <td>{"Unknown > $100"}</td>
                                            <td>{<a onClick={() => this.closeTab(">100")} ><u>{this.state.summaryStats[">100"] ? this.state.summaryStats[">100"] : 0}</u></a>}</td>
                                        </tr>
                                        <tr>
                                            <td>{"Unknown $50 - $100"}</td>
                                            <td>{<a onClick={() => this.closeTab("50-100")} ><u>{this.state.summaryStats["50-100"] ? this.state.summaryStats["50-100"] : 0}</u></a>}</td>
                                        </tr>
                                        <tr>
                                            <td>{"Others"}</td>
                                            <td>{<a onClick={() => this.closeTab("Other")} ><u>{this.state.summaryStats["Other"] ? this.state.summaryStats["Other"] : 0}</u></a>}</td>
                                        </tr>
                                    </tbody>
                                    : null}
                            </table>
                        </div>
                    </ModalBody>
                </Modal>
            </div >
        );
    }
}
export default withTranslation('common')(SummaryStatsModal);
