import React from 'react';
import { Container } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import PropertyStatus from './components/PropertyStatus';
const propertyStatus = ({ t }) => (
  <Container className="Custom_container">
    <PropertyStatus />
  </Container>
);

propertyStatus.propTypes = {
  t: PropTypes.func.isRequired,
};

export default withTranslation('common')(propertyStatus);
