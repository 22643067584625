import React,{useState} from "react";
import { Dialog } from 'primereact/dialog';
import {InputTextarea} from 'primereact/inputtextarea';
import { Button } from 'reactstrap';
import dateFormats from '../../../UI/FormatDate/formatDate';

interface props {
    showNotes:boolean,
    selectedNotes:Array<any>,
    hideNotes:Function,
    onClickSaveNotes:Function,
    newNotes:string,
    setNewNotes:Function
}

export const NotesPopup = ({showNotes,hideNotes,selectedNotes,onClickSaveNotes,newNotes,setNewNotes}:props) => {
    const [showAddNotesOption, setSetshowAddNotesOption] = useState<boolean>(false)
    return <Dialog header={'Notes'} visible={showNotes} style={{ width: '50vw' }} onHide={() => hideNotes()}>
        
        <div className="notes-wrapper">
            {selectedNotes.reverse().map((notes:any) => <div className="notes-modal-single">
                <div ><b>{notes.noteAddedOnDate ? dateFormats.formatDate(notes.noteAddedOnDate, "MM/DD/YYYY hh:mm A", "nhg") : `${new Date().getMonth() + 1}/${new Date().getDate()}/${new Date().getFullYear()}`}: {notes.noteAddedBy}</b></div>
                <span >{notes.notes}</span>
            </div>)
            }           
        </div>        
        {showAddNotesOption &&
            <div>
                <div style={{ padding: '0.30rem' }}>
                    <label className="mb-0 font-weight-bold">Add New Notes</label>
                    <InputTextarea rows={3} style={{ width: '100%' }} value={newNotes} onChange={(e) => setNewNotes((e.target as HTMLInputElement).value)} />
                </div>
            </div>
           
        }

        <div className="text-center pt-4">
            {showAddNotesOption ?
                <>
                    <Button color='primary' outline disabled={!newNotes} onClick={onClickSaveNotes}>Save</Button>
                    <Button color='primary' outline onClick={() => setSetshowAddNotesOption(false)}>Cancel</Button>
                </>
                :
                <>
                    <Button color='primary' outline onClick={() => setSetshowAddNotesOption(true)}>Add New Notes</Button>
                    <Button color='primary' outline  onClick={() => hideNotes()}>Close</Button>
                </>
            }
        </div>
        
        
    </Dialog>
}