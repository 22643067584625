import React from 'react';
import { Container } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import DealConfigurations from './components/DealConfigurations';
const dealConfigurations = (props) => (

  <Container >
    <DealConfigurations {...props} />
  </Container>
);

dealConfigurations.propTypes = {
  t: PropTypes.func.isRequired,
};

export default withTranslation('common')(dealConfigurations);
