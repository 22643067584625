import React, { PureComponent } from 'react';
import {
  Card, CardBody, Col, Button, ButtonToolbar,
} from 'reactstrap';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import { load as loadAccount } from './../../../../redux/reducers/commonReducer';
import { Link, Redirect } from 'react-router-dom';
// file upload
import RenderFileInputField from '../../../Form/components/FileUpload';
import CalendarBlankIcon from 'mdi-react/CalendarBlankIcon';
import DatePicker from '../../../../shared/components/form/DatePicker';
import DefaultTextArea from '../../../../shared/components/form/DefaultTextArea';
import Select from '../../../../shared/components/form/Select';

// validate
import validate from './validate';

// config
import fetch from '../../../../config/service';
import apiCalls from '../../../../config/apiCalls';
import config from '../../../../config/config';
import { withTranslation } from 'react-i18next';
import { setUserAction } from '../../../../redux/actions/userActions';
import SessionWarningModal from '../../../Cruds/CommonModals/SessionWarningModal';
import configMessages from '../../../../config/configMessages';

const renderField = ({
  input, placeholder, type, isDisable, meta: { touched, error },
}) => (
  <div className="form__form-group-input-wrap">
    <input {...input} disabled={isDisable} placeholder={placeholder} type={type} />
    {touched && error && <span className="form__form-group-error">{error}</span>}
  </div>
);


renderField.propTypes = {
  input: PropTypes.shape().isRequired,
  placeholder: PropTypes.string,
  type: PropTypes.string,
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.string,
  }),
};

renderField.defaultProps = {
  placeholder: '',
  meta: null,
  type: 'text',
  isDisable: false
};

class Profile extends PureComponent {
  static propTypes = {
    t: PropTypes.func,
    handleSubmit: PropTypes.func.isRequired,
    reset: PropTypes.func.isRequired,
    pristine: PropTypes.bool.isRequired,
    submitting: PropTypes.bool.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      showPassword: false,
      locationProps: '',
      profileFields:
        [
          {
            name: 'fname',
            type: 'text',
            placeholder: 'FirstName',
            label: 'First Name'
          },
          {
            name: 'lname',
            type: 'text',
            placeholder: 'Last Name',
            label: 'Last Name'
          },
          {
            name: 'display_name',
            isDisable: true,
            type: 'text',
            placeholder: 'Display Name',
            label: 'Display Name'
          },
          {
            name: 'role',
            type: 'text',
            placeholder: 'Permissioned Role',
            isDisable: true,
            label: 'Role'
          },
          {
            name: 'status',
            type: 'text',
            placeholder: 'Status',
            label: 'Status'
          },
          {
            name: 'email',
            type: 'email',
            placeholder: 'Email',
            isDisable: true,
            label: 'UserName'
          },
          {
            name: 'secondaryEmail',
            type: 'email',
            placeholder: 'Email',
            label: 'Email'
          },
        ],
      PageOptions: [
        { label: '10', value: '10' },
        { label: '15', value: '15' },
        { label: '20', value: '20' },
        { label: '30', value: '30' },
        { label: '50', value: '50' },
        { label: '100', value: '100' },
        { label: '250', value: '250' },

      ],
    };
  }

  componentDidMount() {
    this.props.onRef(this);
    if (document.getElementsByClassName("layout layout--collapse").length === 0) {
      document.getElementsByClassName("topbar__button topbar__button--desktop")[0].click();
    }
    this.getUserData();

  }

  componentWillUnmount = () => {
    this.props.onRef(null);
  }

  // get data from server based on Id
  getUserData = async () => {
    let prevsPros = this.props.locationProps;
    this.setState({ locationProps: prevsPros })
    if (localStorage.getItem('loginCredentials')) {
      let user = JSON.parse(localStorage.getItem('loginCredentials'));
      await this.setState({ userId: user._id });
      let Url = `${apiCalls.Users}/${user._id}`;
      return fetch('GET', Url)
        .then(async (response) => {
          if (response && response.details) {
            if (response.details.image) {
              this.updateTopbarData()
              this.setState({ pic: response.details.image });
            }
            this.props.load(response.details);
          } else if (response && response.errorMessage && (response.errorMessage === configMessages.tokenMessage || response.errorMessage === configMessages.sessionExpired)) {
            this.setState({ LoggotSuccess: true });
          } else if (response && response.errorMessage && response.errorMessage === configMessages.warningMessage) {
            await this.setState({
              sessionWarning: true,
              // isLoading: false
            })

          }
        }).catch((err) => {
          return err;
        });
    }
    else {
      return;
    }
  }

  cancelSessionWarningModal = async () => {
    await this.setState({
      sessionWarning: false
    })
  }

  //stay signed in Modal
  getSessionWraningModal() {
    return (
      <SessionWarningModal
        openConfirmationModal={this.state.sessionWarning}
        cancelSessionWarningModal={this.cancelSessionWarningModal}
        getData={this.getKanbanCards}

      />
    )
  }

  updateTopbarData = () => {
    let data = JSON.parse(localStorage.getItem('loginCredentials'))
    this.props.setUserData(data)
  }

  getFileName = (file) => {
    this.updateTopbarData()
    this.setState({ pic: file });
  }

  render() {
    const { handleSubmit, t } = this.props;
    const { PageOptions } = this.state
    const profileFields = this.state.profileFields;
    return (
      <div>
        <Col md={12} lg={12}>
          <Card className="leftMargin" >
            <CardBody>
              <div className="card__title">
                <h5 className="bold-text">Profile</h5>
              </div>
              <form className="form" onSubmit={handleSubmit}>
                <div>
                  <div className='row'>
                    {profileFields && profileFields.length > 0 ? profileFields.map((item, index) => (
                      <div className='col-sm-12 col-md-6 pb-2' key={index}>
                        <div className="form__form-group" >
                          <span className="form__form-group-label">{t(item.label)}</span>
                          <div className="form__form-group-field">
                            {(item.type === 'text' || item.type === 'textarea' || item.type === 'password' || item.type === 'email' || item.type === 'url') ?
                              <Field
                                id={item.id}
                                name={item.name}
                                component={item.type === 'textarea' ? DefaultTextArea : renderField}
                                type={item.text}
                                placeholder={item.placeholder}
                                isDisable={item.isDisable ? item.isDisable : false}
                              />
                              : item.type === 'date' ?
                                <div className='w-100 d-flex'>
                                  <Field
                                    id={item.id}
                                    name={item.name}
                                    component={DatePicker}
                                    type={item.text}
                                    placeholder={item.placeholder}
                                    isDisable={item.isDisable ? item.isDisable : false}
                                  />
                                  <div className="iconstyle form__form-group-icon">
                                    <CalendarBlankIcon />
                                  </div>
                                </div>
                                : null}
                          </div>
                        </div>
                      </div>
                    )) : null}
                    <div className='col-sm-12 col-md-6'>
                      <div className="form__form-group">
                        <span className="form__form-group-label">{t('Profile Image')}</span>
                        <div className="form__form-group-field">
                          <Field
                            name="image"
                            component={RenderFileInputField}
                            onRef={(ref) => (this.profileUploadRef = ref)}
                            url={apiCalls.ProfileImagePath}
                            label='Upload photo'
                            type='profile'
                            acceptType="image/*"
                            getFileName={this.getFileName}
                          />
                        </div>
                        <div className='col-md-2' style={{ padding: '20px' }}>
                          <div style={{ justifyContent: 'center' }}>
                            {(this.state.pic) ?
                              <img src={`${config.imgUrl}/${this.state.pic}`} className='detailsImgStyle' alt="" />
                              : null
                            }
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className='col-sm-12 col-md-6 pb-2' >
                      <div className="row create-header">
                        <h4 className="col-md-6 offset-3 text-center py-3">Additional Settings</h4>
                      </div>
                      <div className="form-group pt-2">
                        <label>Select Pagination</label>
                        <div className="form__form-group-field">
                          <Field
                            name={'pageLimit'}
                            component={Select}
                            options={PageOptions}
                            // onChange={(e) => this.onPageChange(e)}
                            placeholder={'Select Pagination'}
                          />
                        </div>
                      </div>

                    </div>
                    <div className='col-sm-12 '>
                      <div className='float-right'>
                        <ButtonToolbar className="form__button-toolbar">
                          <Link to={'/taskView'}>
                            <Button style={{ marginLeft: '50px' }} color='primary'
                            >{t('Cancel')}</Button>
                          </Link>
                          <Button color="primary" type="submit">{t('Submit')}</Button>
                        </ButtonToolbar>
                      </div>
                    </div>
                  </div>

                </div>
              </form>
            </CardBody>
          </Card>
        </Col >
        {this.state.LoggotSuccess ? <Redirect to="/log_in" /> : null}
        {this.state.sessionWarning ? this.getSessionWraningModal() : null}
      </div>
    );
  }
}

// Decorate with reduxForm(). It will read the initialValues prop provided by connect()
Profile = reduxForm({
  form: "ProfileForm", // a unique identifier for this form
  enableReinitialize: true,
  validate
})(Profile);

// You have to connect() to any reducers that you wish to connect to yourself
Profile = connect(
  state => ({
    initialValues: state.commonData.data // pull initial values from account reducer
  }),
  {
    load: loadAccount,
    setUserData: setUserAction
  } // bind account loading action creator
)(Profile);

export default withTranslation('common')(Profile);
