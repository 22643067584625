import React from 'react';
import { Button, Modal, ModalBody } from 'reactstrap';
import fetch from '../../../config/service'
import configMessages from '../../../config/configMessages';
import { Redirect } from 'react-router-dom';
// config file
let timeInterval
export default class ExpiredSessionModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            openConfirmationModal: this.props.openConfirmationModal
        };
    }

    // componentDidUpdate = async () => {

    // }
    componentDidMount = async () => {
        localStorage.setItem('multiTabsOpen', false);
        timeInterval = setInterval(() => {
            let multiTabs = localStorage.getItem('multiTabsOpen');
            if (multiTabs && multiTabs === "true") {

                this.setState({ openConfirmationModal: false })
                if (timeInterval) {
                    clearInterval(timeInterval)
                }
            }
        }, 100);
    }

    deleteSelectedRow = () => {
        // this.props.deleteSelectedRow();
    }
    logout = async () => {
        this.setState({ sessionExpiryModal: true })


    }
    signedIn = () => {

        let sessionexpired = localStorage.getItem('sessionexpired')
        // if (sessionexpired === "true") {
        //     this.setState({ sessionExpiryModal: true })
        // }

        return fetch('POST', `Auth/refresh-AuthenticationToken`)
            .then((response) => {
                if (response) {
                    if (response.respCode && response.respCode === 200) {
                        localStorage.setItem('multiTabsOpen', true);
                        // display message
                        // showToasterMessage(response.respMessage, 'success');
                    } else if (response.errorMessage && (response.errorMessage === configMessages.tokenMessage || response.errorMessage === configMessages.sessionExpired)) {
                        this.setState({ sessionExpiryModal: true })
                        localStorage.setItem('multiTabsOpen', false);

                    }
                    this.setState({ isLoading: false });
                    this.props.cancelSessionWarningModal()

                    // this.props.getData()
                }
            }).catch((err) => {
                return err;
            });
    }

    render() {
        return (
            <div>
                <Modal isOpen={this.state.openConfirmationModal} fade={false} className='session modal-dialog-centered deleteModalHeight' >
                    <ModalBody className='deleteModalBody'>
                        <div style={{ marginBottom: 10 }}>Your session has timed-out due to inactivity.</div>
                        <Button color="primary" outline onClick={() => this.props.cancelSessionWarningModal("close")}
                            className='deleteModalBtn'>Stay Signed in</Button>
                        <Button color="primary" outline onClick={() => this.props.cancelSessionWarningModal("redirect")}
                            className='deleteModalBtn'>No</Button>
                    </ModalBody>
                </Modal>
                {this.state.sessionExpiryModal ?
                    <Redirect to="/log_in" /> : null}
            </div>

        );
    }
}