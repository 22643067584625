import React from 'react';
import { Modal, ModalBody } from 'reactstrap';
import { withTranslation } from 'react-i18next';

// config file
class NotesScreenProps extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }



  componentWillUnmount() {
    if (this.props.onRef) {
      this.props.onRef(null);
    }
  }

  componentDidMount = async () => {
    if (this.props.onRef) {
      this.props.onRef(this);
    }
  }
  /*
   For Getting required Fields to save or edit record
   */
  getrequiredFieldsforScreen() {
    let array = ["BusinessName", "NameSource", "NameConnection", "IssueName"]
    return array
  }

  /*
  For Adding new Record Setting Empty Obj
  */
  getnewRecord = () => {
    let newRec = {
      "CompanyLocationID": null,
      "CompanyID": "",
      "LocationName": null,
      "LocationType": "",
      "LocationStatus": "",
      "Line1": "",
      "Line2": null,
      "City": "",
      "StateProvince": "",
      "Zip": "",
      "Country": "",
      "OfficePhone": "",
      "OfficeFax": "",
      "AddressSource": null,
      "AddressNote": null,
      "IsKey": false,
      "LastUpdated": "",
      "BusinessID": null,
      "DataSourceID": null,
      "DWDateInserted": "",
      "DWDateLastModified": "",
      "DWLastOperation": "I",
      "DWLastModifiedBy": "",
      "DWDelete": false,
      "DWRowIsCurrent": true,
      "LegacyOPRAEstateToAddressID": null,
      "LegacyOPRAEstateID": null,
      "LegacyOPRAEstateNameID": null,
      "LegacyOPRAAddressID": null,
      "LegacyOriginalPropertyId": null,
      "IsHeadquarters": null,
      "LegacyOwnershipTypeId": null,
      "LegacyOwnershipSubtypeId": null,
      "LegacyIssues": null,
      "LegacyEstateToAddressStatusID": null,
      "LegacyEstateToAddressCreatedDate": null,
      "LegacyEstateToAddressUpdatedDate": null,
      "OPRAAddressId": null,
      "Attn": null,
      "Line3": null,
      "County": null,
      "LegacyIsComplete": null,
      "LegacyIsUseless": null,
      "LegacyAddressCreatedDate": null,
      "LegacyAddressUpdatedDate": null,
      "LegacySearchedAsPropertyDateUTC": null,
      "LegacySearchedAsEstateDateUTC": null,
      "LegacyGroomingFlag": null,
      "LegacyResidentialFlag": null,
      "LegacySS_AddressLine1": null,
      "LegacySS_AddressLine2": null,
      "LegacySS_City": null,
      "LegacySS_State": null,
      "LegacySS_ZipCode": null,
      "LegacySS_Latitude": null,
      "LegacySS_Longitude": null,
      "LegacySS_LastRanDateUTC": null,
      "LegacyAddressDetailID": null,
      "LegacyNameFormat": null,
      "LegacyAddressTypeID": null,
      "LegacyAddressSubTypeID": null,
      "LegacyAddressDetailStatusID": null,
      "LegacyExternalList": null,
      "LegacyCodeDate": null,
      "LegacyDateAdded": null,
      "CompanyName": null,
      "Store": null,
      "Attention": null,
      "SuiteUnit": null,
      "GeneralNotes": null,
      "GeneralNotesCreatedDateUTC": null,
      "GeneralNotesUpdatedDateUTC": null,
      "LegacyLandLordInfo": null,
      "LegacyBNCNotices": null,
      "PortfolioKeyAddress": null,
      "AssociatedCaseNumber": null,
      "MailForwardNotesEstate": null,
      "MailForwardNotesEstateCreatedDateUTC": null,
      "MailForwardNotesEstateUpdatedDateUTC": null,
      "MailForwardReviewEstate": null,
      "MailForwardNotesAddress": null,
      "MailForwardNotesAddressCreatedDateUTC": null,
      "MailForwardNotesAddressUpdatedDateUTC": null,
      "MailForwardReviewAddress": null,
      "LegacyImportLogId": null,
      "MailForwardEstateDate": null,
      "MailForwardAddressDate": null,
      "LegacyConnectedName": null,
      "CourtCaseKey": null,
      "LeadCourtCaseKey": null,
      "KeyAddress": null,
      "EntityName": ""
    }
    return newRec
  }

  /*
  For Getting table Fields
  */
  getNotesFields() {
    var data = [
      {
        textAlign: "center",
        width: 47,
        field: "",
        // fieldType: "multiple",
        header: "",
        selectionMode: "multiple",
        show: true,
        mobile: true,
        displayInSettings: false,
      },
      // {
      //     "show": true,
      //     "textAlign": "left",
      //     "width": 90,
      //     "fieldType": 'Actions',
      //     "field": "Actions",
      //     "header": "Actions",
      //     "filter": false,
      //     "displayInSettings": true,
      //     "sortable": false
      // },
      {
        show: true,
        mobile: true,
        textAlign: 'left',
        editField: "text",
        width: 130,
        field: 'CompanyID',
        getField: "EntityID",
        header: 'Company ID',
        fieldType: "EntityID",
        "filter": true,
        "sortable": true,                    // sortable: true,
      },
      {
        show: true,
        mobile: true,
        textAlign: 'left',
        editField: "text",
        width: 130,
        field: 'EntityName',
        getField: "EntityName",
        header: 'Entity Name',
        fieldType: "EntityName",
        "filter": true,
        "sortable": true,                    // sortable: true,
      },
      // {
      //     show: true,
      //     mobile: true,
      //     textAlign: 'left',
      // editField: "text",
      //     width: 130,
      //     field: 'LocationName',
      //     getField: "LocationName",
      //     header: 'Location Name',
      //     fieldType: "LocationName",
      //     filter: false,
      //     // sortable: true,
      // },
      {
        show: true,
        mobile: true,
        textAlign: 'left',
        editField: "text",
        width: 130,
        field: 'LocationType',
        getField: "LocationType",
        header: 'Location Type',
        fieldType: "LocationType",
        "filter": true,
        "sortable": true,                    // sortable: true,
      },
      {
        show: true,
        mobile: true,
        textAlign: 'left',
        editField: "text",
        width: 200,
        field: 'Address1',
        header: 'Address1',
        "filter": true,
        "sortable": true,                    // sortable: true
      },

      {
        show: true,
        mobile: true,
        textAlign: 'left',
        editField: "text",
        width: 200,
        field: 'City',
        header: 'City',
        "filter": true,
        "sortable": true,                    // sortable: true
      },
      {
        show: true,
        mobile: true,
        textAlign: 'left',
        editField: "text",
        width: 200,
        field: 'StateProvince',
        header: 'State Province',
        "filter": true,
        "sortable": true,                    // sortable: true
      },
      {
        show: true,
        mobile: true,
        textAlign: 'left',
        editField: "text",
        width: 200,
        field: 'PostCode',
        header: 'Zip Code',
        "filter": true,
        "sortable": true,                    // sortable: true
      },

      {
        show: true,
        mobile: true,
        textAlign: 'left',
        editField: "text",
        width: 200,
        field: 'Country',
        header: 'Country',
        "filter": true,
        "sortable": true,                    // sortable: true
      },
      {
        show: true,
        mobile: true,
        textAlign: 'left',
        editField: "text",
        width: 200,
        field: 'AddressSource',
        header: 'Address Source',
        "filter": true,
        "sortable": true,                    // sortable: true
      },
    ]
    return data;
  }

  render() {

    return (
      <></>
    );
  }
}
export default withTranslation('common')(NotesScreenProps);
