
import React from 'react';
import { Field, reduxForm } from 'redux-form';
import { Button, Card, CardBody, Modal, ModalHeader, ModalBody } from 'reactstrap';
import { connect } from 'react-redux';
import { Row } from 'react-bootstrap';

import { Accordion, AccordionTab } from 'primereact/accordion';
import { load as loadAccount } from '../../../redux/reducers/commonReducer';
import classNames from 'classnames';
// fecth method from service.js file
import fetch from '../../../config/service';
import configMessage from '../../../config/configMessages';
import { Editor } from 'primereact/editor';

// show message 
import DefaultInput from '../../../shared/components/form/DefaultInput';
import CheckBox from '../../../shared/components/form/CheckBox';
import validate from '../../Validations/validate';
import Loader from '../../App/Loader';
import { withTranslation } from 'react-i18next';
import Select from '../../../shared/components/form/Select';

import ModalFooter from 'reactstrap/lib/ModalFooter';
import { faArrowCircleLeft, faSave, faStarOfLife } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import apiCalls from '../../../config/apiCalls';

const required = value => (value || typeof value === 'string' ? undefined : configMessage.fillField)

//validations
// let newpassval = value => (value ? config.userPasswordRegex.test(value) ? undefined : 'Password must have at least 12 characters and contain at least 1 Uppercase and 1 special character.' : configMessage.fillField)

class AddressModal extends React.Component {
    constructor(props) {
        super(props);
        this.buttonActionType = null;
        this.state = {
            isLoginSuccess: false,
            isLoading: false,
            colored: false,
            showName: false,
            namesArray: [],
            setCIty: null,
            keyNameChecked: false,
            storeProcId: "",
            Addresstype: null,
            name: null,
            citiesArray: [],
            progress: 0,
            sendNameID: false,
            header: true,
            BusinessID: "",
            subCategory: { "Sourcing": [{ label: "Valuation", value: "Valuation" }], "Research": [{ label: "Corporate Tree", value: "Corporate Tree" }, { label: "Entity Details", value: "Entity Details" }, { label: "Property Details", value: "Property Details" }], "Legal": [{ label: "APA", value: "APA" }, { label: "Document", value: "Document" }, { label: "Information", value: "Information" }], "Transition": [{ label: "Entity", value: "Entity" }], "Recovery": [{ label: "General Note", value: "General Note" }, { label: "State", value: "State" }] }
        };
    }


    // clear input data
    clearInputFields = () => {
        this.props.reset();
    }
    setAddressDetails(itemDetails) {
        this.props.change("companyName", itemDetails.EntityName)
        this.props.change("Address1", itemDetails.Address1)
        this.props.change("Address2", itemDetails.Address2)
        this.props.change("Name", itemDetails.Name)
        this.props.change("City", itemDetails.City)
        this.props.change("StateProvince", itemDetails.StateProvince)
        this.props.change("LocationType", itemDetails.LocationType)
        this.props.change("PostCode", itemDetails.PostCode)
        this.props.change("Country", itemDetails.Country)
        this.props.change("OfficePhone", itemDetails.OfficePhone)
        this.props.change("OfficeFax", itemDetails.OfficeFax)
        this.props.change("AddressSource", itemDetails.AddressSource)
        let htmlString = itemDetails.AddressNote;
        this.props.change("Note", <div dangerouslySetInnerHTML={{ __html: htmlString }}>
        </div>)
        // this.props.change("Note", itemDetails.AddressNote)
        this.setState({ setCIty: itemDetails.City, name: itemDetails.Name, BusinessID: itemDetails.BusinessID, keyNameChecked: itemDetails.IsKey, DataSourceID: itemDetails.DataSourceID ? itemDetails.DataSourceID : null, showName: true })
        let z = this.state.namesArray.find(elem => elem.label === itemDetails.EntityName)
        if (z) {
            this.setState({ storeProcId: z.value })
        }
        this.setZipcode(itemDetails.PostCode)
        this.handleBusinessID(itemDetails.CompanyID)

    }
    submit = (values) => {

        let body = {}
        this.setState({
            isLoading: true,
        })
        if (this.props.activeTab === "5") {
            body.Category = this.state.Category
            body.SubCategory = this.state.SubCategory
            body.noteText = this.state.issueNoteHtml
            if (this.state.type === "edit") {
                body.sellerNotesID = this.props.itemDetails.SellerNotesID
            }
            body.dealID = this.props.dimSellerID
        } else {
            body.BusinessID = JSON.stringify(this.state.BusinessID)
            body.Address1 = values.Address1
            body.Address2 = values.Address2
            body.Name = this.state.name
            body.City = this.state.setCIty
            body.StateProvince = values.StateProvince
            body.LocationType = values.LocationType
            body.PostCode = values.PostCode
            body.Country = values.Country
            body.IsKey = this.state.keyNameChecked
            body.OfficePhone = values.OfficePhone
            body.OfficeFax = values.OfficeFax
            if (this.state.Addresstype === "edit") {
                body.companyLocationID = this.props.itemDetails.CompanyLocationID
                let z = this.state.namesArray.find(elem => elem.label === values.companyName)
                if (z) {
                    body.CompanyID = z.value
                }
                // body.EntityID = this.props.itemDetails.EntityID
                // body.EntityID = values.companyName
            } else {
                body.CompanyID = values.companyName
            }
            body.AddressSource = values.AddressSource
            body.AddressNote = this.state.generalNoteHtml

        }

        // this.props.reset();
        // this.props.cancelReset();

        let method, apiUrl
        if (this.state.type === 'edit' || this.state.Addresstype === "edit") {
            method = 'PUT'
            apiUrl = this.props.activeTab === "5" ? `${apiCalls.SellerNotes}` : apiCalls.addAddress;
        } else {
            method = 'POST'
            apiUrl = this.props.activeTab === "5" ? apiCalls.SellerNotes : apiCalls.addAddress
        }
        this.handlePercentage('1')
        return fetch(method, apiUrl, body)
            .then(async (response) => {
                let strArray = this.props.strArray ? this.props.strArray : null
                if (response && response.respCode && (response.respCode === 204 || response.respCode === 200 || response.respCode === 205)) {
                    if (this.props.activeTab === "4") {
                        let url1 = `${apiCalls.execute}?query=exec mbd.InsertUpdateCompanyNameAndAddress '${strArray}'`;

                        fetch("GET", url1)
                            .then(async (responseStr) => {

                            }).catch((err) => {
                                return err;
                            });
                    }
                    // display message
                    // showToasterMessage(response.respMessage, 'success');
                    this.cancelReset("save")
                    this.props.toggleNames(this.props.activeTab)
                } else if (response && response.errorMessage) {
                    // display message
                    await this.setState({ apierrorResponse: true, apierrorMessage: response.errorMessage })
                }
                this.setState({
                    isLoading: false,
                })
                // this.props.getKanbanCards()
            }).catch((err) => {
                return err;
            });
    }

    cancelReset = (type) => {
        // this.props.load({})
        // await this.props.reset();
        this.props.isCloseModal(type);

    }

    onChange = async (e) => {

    }
    handleName = async (e) => {
        let z = this.state.namesArray.find(elem => elem.value === e)
        if (z) {
            await this.setState({ name: e, sendNameID: true, sendBusinessId: z.BusinessID, sendCompanyId: z.CompanyID })
        }
    }
    handleCity = async (e) => {
        await this.setState({ setCIty: e })
    }
    setZipcode = async (e) => {
        let cityArray = []
        if (e && e.length > 3) {
            let zipcode = `${apiCalls.zipcode}?str=${e}`
            fetch("GET", zipcode)
                .then(async (response) => {
                    if (response && response.zipCodes && response.zipCodes.length > 0) {
                        let cityStates = response.zipCodes
                        let state = response.zipCodes[0]
                        for (let city of cityStates) {
                            cityArray.push({ label: city.PHYSICAL_CITY, value: city.PHYSICAL_CITY })
                        }
                        // this.props.change("City", x.city)
                        this.props.change("StateProvince", state.PHYSICAL_STATE)
                        this.setState({ citiesArray: cityArray })
                    } else {
                        this.props.change("StateProvince", "")
                        this.setState({ citiesArray: [] })
                    }
                }).catch((err) => {
                    return err;
                });
        }
    }
    handleBusinessID = async (e) => {
        let str = [];
        str.push(e)
        let z = this.state.namesArray.find(elem => elem.value === e)
        if (z) {
            await this.setState({ BusinessID: z.BusinessID, showName: true, storeProcId: z.value })
        }
        let filter = {}
        filter['sortfield'] = "BusinessName"
        filter['direction'] = "asc"
        // await this.setState({ isLoading: true })
        // let urlNames = `${apiCalls.AddressNames}?filter=${JSON.stringify(filter)}&str=${str}&type=exportToCsv`
        // fetch("GET", urlNames)
        //     .then(async (response) => {
        //         if (response && response.addressnames && response.addressnames.length > 0) {

        //             for (let name of response.addressnames) {
        //                 namesArray.push({ label: name.BusinessName, value: name.BusinessName, BusinessID: name.BusinessID, CompanyID: name.CompanyID })
        //             }
        //             this.setState({ namesArray: namesArray, isLoading: false })


        //         } else {
        //             await this.setState({ addressnames: [], totalRecordsLengthNames: 0 })
        //             await this.setState({ isLoading: false })
        //             this.setState({ namesArray: [] })

        //         }
        //     }).catch((err) => {
        //         return err;
        //     });

    }
    handleCategory = async (e) => {

        for (let subCate in this.state.subCategory) {
            if (e === subCate) {
                this.setState({ subCatg: this.state.subCategory[subCate], Category: e, SubCategory: null })
                this.props.change("SubCategory", null)
            }
        }


    }
    keyNameChecked = (e) => {
        this.setState({ keyNameChecked: this.state.keyNameChecked ? false : true })
    }

    handleSubCategory = async (e) => {
        await this.setState({ SubCategory: e })
    }
    setText = (e) => {
        this.setState({ issueNoteHtml: e })
    }
    handlePercentage = async (Val) => {
        let counter = 0;
        const interval = setInterval(async () => {
            if (this.state.isLoading) {
                counter = counter + 15;
                await this.setState({
                    progress: counter,
                })
            } else {
                if (!this.state.isLoading) {
                    clearInterval(interval);
                }
            }
        }, 100);
    }
    setGeneralNote = (e) => {
        this.setState({ generalNoteHtml: e })
    }
    getHierarchyData = async () => {
        let namesArray = []
        let urlNames = `${apiCalls.HeirarchyCompany}?str=${this.props.dimSellerID}`
        fetch("GET", urlNames)
            .then(async (response) => {
                if (response && response.companyIDs) {
                    for (let name of response.companyIDs) {
                        namesArray.push({ label: name.CompanyName, value: name.CompanyID, BusinessID: name.BusinessID })
                    }
                    this.setState({ namesArray: namesArray, isLoading: false })


                } else {
                    this.setState({ namesArray: [], isLoading: false })

                }
            }).catch((err) => {
                return err;
            });
    }

    componentDidMount = async () => {
        this.props.change("CompanyID", this.props.connectedBusiness.value)
        this.props.change("BusinessName", this.props.connectedBusiness.label)
        this.props.change("connectedName", this.props.connectedBusiness.label)
        await this.getHierarchyData()
        if (this.props.itemDetails && this.props.activeTab === "5") {
            this.setState({ type: "edit" })
            let htmlString = this.props.itemDetails.NoteText;
            this.props.change("issueNoteHtml", <div dangerouslySetInnerHTML={{ __html: htmlString }}>
            </div>)
            this.props.change("Category", this.props.itemDetails.Category)
            this.props.change("SubCategory", this.props.itemDetails.SubCategory)

        } else if (this.props.itemDetails && this.props.activeTab === "4") {
            this.setState({ Addresstype: "edit" })
            this.setAddressDetails(this.props.itemDetails)

            // this.props.load(this.props.itemDetails);

        } else {
            this.setState({ type: null, Addresstype: null })
        }

        // this.setState({ DataSourceID: this.props.itemDetails.DataSourceID ? this.props.itemDetails.DataSourceID : null })

        // this.setState({ CompanyID: this.props.connectedBusiness.label, BusinessName: this.props.connectedBusiness.value })

    }

    render() {
        const { handleSubmit, t, type } = this.props;
        const modalClass = classNames({
            'modal-dialog--colored': this.state.colored,
            'modal-dialog--header': this.state.header,
        });
        return (
            <Modal
                fade={false}
                isOpen={this.props.isOpenAddModal}
                style={{ maxWidth: "1000px" }}
                className={`modal-dialog modal-dialog--primary  ${modalClass}`}
            >
                <form className="form" onSubmit={handleSubmit(this.submit)}>
                    <ModalHeader className={"modal__header kanbanScreen"}>
                        <div className="d-flex">
                            <button className="lnr lnr-cross modal__close-btn" type="button" style={type === "edit" ? { color: "black", right: 40 } : { color: "white" }}
                                onClick={() => this.cancelReset("")} />
                            <span className={`modallnrIcon pt-1 lnr lnr-list`} />
                            <p className="bold-text  modal__title ml-2"> {this.state.type === "edit" || this.state.Addresstype ? "Edit" : 'Add'} {this.props.activeTab === "5" ? "Note" : "Address"} </p>
                            <Button color='danger' style={{ float: "right", marginLeft: "545px", height: "33px" }} outline type="button" onClick={() => this.cancelReset("")}>
                                <FontAwesomeIcon
                                    style={{ width: "15px" }}
                                    // color="red"
                                    icon={faArrowCircleLeft}
                                />
                                {t('Back') ? t('Cancel') : 'Cancel'} </Button>
                            <Button
                                style={{ float: "right", height: "33px" }}
                                color='primary'
                                className={"mb-0 "}
                                outline
                                type="submit"
                            >
                                <FontAwesomeIcon
                                    style={{ width: "15px" }}
                                    icon={faSave}
                                />
                                {type === 'edit' ? 'Update' : 'Save'}
                            </Button>
                        </div>

                    </ModalHeader>

                    {/* <ModalHeader className={"modal__header kanbanScreen"}>
                        <div className="d-flex">
                            <button className="lnr lnr-cross modal__close-btn" type="button" style={type === "edit" ? { color: "black", right: 40 } : { color: "white" }}
                                onClick={this.cancelReset} />
                            <span className={`modallnrIcon pt-1 lnr lnr-list`} />
                            <p className="bold-text  modal__title ml-2"> {this.state.type === "edit" || this.state.Addresstype ? "Edit" : 'Add'} {this.props.activeTab === "5" ? "Note" : "Address"} </p>
                        </div>
                    </ModalHeader> */}
                    <ModalBody className={"modal__body kanbanmodalCardBody mb-0 pt-0 "}>
                        <Card className='pb-0 '>
                            {/* <Loader loader={this.state.isLoading} /> */}
                            <CardBody className=' p-0 mt-3'
                            >
                                <span className="formError mt-2 mb-2" style={{ textAlign: "center", top: -14, color: "red" }}>{this.state.apierrorResponse ? <span style={{ height: 30 }}>{this.state.apierrorMessage}</span> : null}</span>


                                <Loader loader={this.state.isLoading} progress={this.state.progress} className="screen-loader" />
                                {this.props.activeTab === "4" ?

                                    <Accordion activeIndex={[0]} style={{ marginTop: "41px" }} multiple={true}>
                                        <AccordionTab class="in" header="Basic Information:">
                                            <Row>
                                                <div className='col-sm-6'>
                                                    <label className="form__form-group-label">Company Name{required ? <FontAwesomeIcon icon={faStarOfLife}
                                                        className='pl-1'
                                                        color='red'
                                                        data-toggle="tool-tip"
                                                        title="Mandotary Field"
                                                        style={{ width: 8 }}
                                                    /> : ''}</label>

                                                    {/* <Field className='EstateName'
                                                        type={'text'}
                                                        name="BusinessName"
                                                        component={DefaultInput}
                                                        // options={this.state.EstateNames}
                                                        // onChange={(e) => this.handleChange(e)}
                                                        validate={required}
                                                        placeholder='Business Name'
                                                    /> */}
                                                    <Field
                                                        // key={i}
                                                        name={"companyName"}
                                                        component={Select}
                                                        validate={required}
                                                        options={this.state.namesArray ? this.state.namesArray : []}
                                                        onChange={(e) => this.handleBusinessID(e)}
                                                        // onChange={(e) => this.handleChange(e)}
                                                        // defaultValue={this.props.formType && this.props.formType === 'add' ? item.defaultValue : null}
                                                        placeholder={"Company Name"}
                                                        isDisable={this.state.Addresstype === "edit" && this.state.DataSourceID !== this.props.dataSourceId ? true : false}
                                                    // isDisable={item.disable ? item.disable : false}
                                                    />
                                                </div>
                                                <div className='col-sm-6'>
                                                    <label className="form__form-group-label">Address Line1{required ? <FontAwesomeIcon icon={faStarOfLife}
                                                        className='pl-1'
                                                        color='red'
                                                        data-toggle="tool-tip"
                                                        title="Mandotary Field"
                                                        style={{ width: 8 }}
                                                    /> : ''}</label>

                                                    <Field className='EstateName'
                                                        type={'text'}
                                                        name="Address1"
                                                        component={DefaultInput}
                                                        isDisable={this.state.Addresstype === "edit" && this.state.DataSourceID !== this.props.dataSourceId ? true : false}

                                                        // options={this.state.EstateNames}
                                                        // onChange={(e) => this.handleChange(e)}
                                                        validate={required}
                                                        placeholder='Address Line1'
                                                    />
                                                </div>

                                                <div className='col-sm-6'>
                                                    <label className="form__form-group-label">Zip Code{required ? <FontAwesomeIcon icon={faStarOfLife}
                                                        className='pl-1'
                                                        color='red'
                                                        data-toggle="tool-tip"
                                                        title="Mandotary Field"
                                                        style={{ width: 8 }}
                                                    /> : ''}</label>

                                                    <Field className='EstateName'
                                                        type={'text'}
                                                        name="PostCode"
                                                        component={DefaultInput}
                                                        isDisable={this.state.Addresstype === "edit" && this.state.DataSourceID !== this.props.dataSourceId ? true : false}

                                                        // options={this.state.EstateNames}
                                                        onChange={(e) => this.setZipcode(e)}
                                                        validate={required}
                                                        placeholder='Zip Code'
                                                    />
                                                </div>
                                                <div className='col-sm-6'>
                                                    <label className="form__form-group-label">City
                                                        {required ? <FontAwesomeIcon icon={faStarOfLife}
                                                            className='pl-1'
                                                            color='red'
                                                            data-toggle="tool-tip"
                                                            title="Mandotary Field"
                                                            style={{ width: 8 }}
                                                        /> : ''}
                                                    </label>


                                                    <Field
                                                        // key={i}
                                                        name={"City"}
                                                        component={Select}
                                                        // validate={required}
                                                        options={this.state.citiesArray}
                                                        onChange={(e) => this.handleCity(e)}
                                                        isDisable={this.state.Addresstype === "edit" && this.state.DataSourceID !== this.props.dataSourceId ? true : false}

                                                        validate={required}
                                                        // onChange={(e) => this.handleChange(e)}
                                                        // defaultValue={this.props.formType && this.props.formType === 'add' ? item.defaultValue : null}
                                                        placeholder={"City"}
                                                    // isDisable={item.disable ? item.disable : false}
                                                    />
                                                </div>
                                                <div className='col-sm-6'>
                                                    <label className="form__form-group-label">State Province{required ? <FontAwesomeIcon icon={faStarOfLife}
                                                        className='pl-1'
                                                        color='red'
                                                        data-toggle="tool-tip"
                                                        title="Mandotary Field"
                                                        style={{ width: 8 }}
                                                    /> : ''}</label>
                                                    <Field className='EstateName'
                                                        type={'text'}
                                                        name="StateProvince"
                                                        component={DefaultInput}
                                                        isDisable={this.state.Addresstype === "edit" && this.state.DataSourceID !== this.props.dataSourceId ? true : false}

                                                        // options={this.state.EstateNames}
                                                        // onChange={(e) => this.handleChange(e)}
                                                        validate={required}
                                                        placeholder='State Province'
                                                    />
                                                </div>
                                                <div className='col-sm-6'>
                                                    <label className="form__form-group-label">Key Address</label>
                                                    <input
                                                        style={{ marginTop: 3, height: 16 }}
                                                        className='col-1 mr-0 pl-0'
                                                        onChange={(e) => this.keyNameChecked()}
                                                        component={CheckBox}
                                                        label={"Key Address"}
                                                        type="checkbox"
                                                        checked={this.state.keyNameChecked}
                                                        defaultChecked={this.state.keyNameChecked}
                                                    />
                                                </div>


                                                <div className='col-sm-6'>
                                                    <label className="form__form-group-label">Country{required ? <FontAwesomeIcon icon={faStarOfLife}
                                                        className='pl-1'
                                                        color='red'
                                                        data-toggle="tool-tip"
                                                        title="Mandotary Field"
                                                        style={{ width: 8 }}
                                                    /> : ''}</label>
                                                    <Field className='EstateName'
                                                        type={'text'}
                                                        name="Country"
                                                        component={DefaultInput}
                                                        isDisable={this.state.Addresstype === "edit" && this.state.DataSourceID !== this.props.dataSourceId ? true : false}

                                                        // options={this.state.EstateNames}
                                                        // onChange={(e) => this.handleChange(e)}
                                                        validate={required}
                                                        placeholder='Country'
                                                    />
                                                </div>

                                                <div className='col-sm-6'>
                                                    <label className="form__form-group-label">Source{required ? <FontAwesomeIcon icon={faStarOfLife}
                                                        className='pl-1'
                                                        color='red'
                                                        data-toggle="tool-tip"
                                                        title="Mandotary Field"
                                                        style={{ width: 8 }}
                                                    /> : ''}</label>
                                                    <Field className='EstateName'
                                                        type={'text'}
                                                        name="AddressSource"
                                                        component={DefaultInput}
                                                        isDisable={this.state.Addresstype === "edit" && this.state.DataSourceID !== this.props.dataSourceId ? true : false}

                                                        // options={this.state.EstateNames}
                                                        // onChange={(e) => this.handleChange(e)}
                                                        validate={required}
                                                        placeholder='Source'
                                                    />
                                                </div>


                                            </Row>
                                        </AccordionTab>
                                        <AccordionTab header="Additional Information:">
                                            <Row>
                                                <div className='col-sm-6'>
                                                    <label className="form__form-group-label">Address Line2</label>

                                                    <Field className='EstateName'
                                                        type={'text'}
                                                        name="Address2"
                                                        component={DefaultInput}
                                                        isDisable={this.state.Addresstype === "edit" && this.state.DataSourceID !== this.props.dataSourceId ? true : false}

                                                        // options={this.state.EstateNames}
                                                        // onChange={(e) => this.handleChange(e)}
                                                        // validate={required}
                                                        placeholder='Address Line2'
                                                    />
                                                </div>
                                                <div className='col-sm-6'>
                                                    <label className="form__form-group-label">Location Type</label>
                                                    <Field className='LocationType'
                                                        type={'text'}
                                                        name="LocationType"
                                                        component={DefaultInput}
                                                        // options={this.state.EstateNames}
                                                        // onChange={(e) => this.handleChange(e)}
                                                        // validate={required}
                                                        placeholder='LocationType'
                                                    />
                                                </div>
                                                <div className='col-sm-6'>
                                                    <label className="form__form-group-label">Office Phone</label>
                                                    <Field className='EstateName'
                                                        type={'text'}
                                                        name="OfficePhone"
                                                        component={DefaultInput}
                                                        // options={this.state.EstateNames}
                                                        // onChange={(e) => this.handleChange(e)}
                                                        // validate={required}
                                                        placeholder='Office Phone'
                                                    />
                                                </div>
                                                <div className='col-sm-6'>
                                                    <label className="form__form-group-label">Office Fax</label>
                                                    <Field className='EstateName'
                                                        type={'text'}
                                                        name="OfficeFax"
                                                        component={DefaultInput}
                                                        // options={this.state.EstateNames}
                                                        // onChange={(e) => this.handleChange(e)}
                                                        // validate={required}
                                                        placeholder='Office Fax'
                                                    />
                                                </div>
                                                <div className='col-sm-6'>
                                                    <label className="form__form-group-label">Note</label>
                                                    {/* <Field className='EstateName'
                                                    type='textArea'
                                                    name="generalNoteName"
                                                    component={DefaultTextArea}
                                                    // options={this.state.EstateNames}
                                                    // onChange={(e) => this.handleChange(e)}
                                                    // validate={required}
                                                    placeholder='General Note'
                                                /> */}
                                                    <Editor style={{ height: '280px' }}
                                                        value={this.props && this.props.itemDetails && this.props.itemDetails.AddressNote && this.state.Addresstype === "edit" ? this.props.itemDetails.AddressNote : ""}
                                                        // value={ }
                                                        name="Note"
                                                        onTextChange={(e) => this.setGeneralNote(e.htmlValue)}
                                                    />
                                                </div>
                                            </Row>
                                        </AccordionTab>
                                    </Accordion>
                                    :
                                    <div>
                                        <div className="col-sm-12 pl-0 pr-0">
                                            <div className="form__form-group pb-1">
                                                <label className="form__form-group-label">Category{required ? <FontAwesomeIcon icon={faStarOfLife}
                                                    className='pl-1'
                                                    color='red'
                                                    data-toggle="tool-tip"
                                                    title="Mandotary Field"
                                                    style={{ width: 8 }}
                                                /> : ''}</label>
                                                <div className='form__form-group-field'>
                                                    {/* <Field className='EstateName'
                                                        type={'text'}
                                                        name="BusinessName"
                                                        component={DefaultInput}
                                                        // options={this.state.EstateNames}
                                                        // onChange={(e) => this.handleChange(e)}
                                                        validate={required}
                                                        placeholder='Business Name'
                                                    /> */}
                                                    <Field
                                                        // key={i}
                                                        name={"Category"}
                                                        component={Select}
                                                        validate={required}

                                                        options={[{ label: "Sourcing", value: "Sourcing" }, { label: "Research", value: "Research" }, { label: "Legal", value: "Legal" }, { label: "Transition", value: "Transition" }, { label: "Recovery", value: "Recovery" }]}
                                                        onChange={(e) => this.handleCategory(e)}
                                                        // onChange={(e) => this.handleChange(e)}
                                                        // defaultValue={this.props.formType && this.props.formType === 'add' ? item.defaultValue : null}
                                                        placeholder={"Category"}
                                                    // isDisable={item.disable ? item.disable : false}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-sm-12 pl-0 pr-0">
                                            <div className="form__form-group pb-1">
                                                <label className="form__form-group-label">Sub Category{required ? <FontAwesomeIcon icon={faStarOfLife}
                                                    className='pl-1'
                                                    color='red'
                                                    data-toggle="tool-tip"
                                                    title="Mandotary Field"
                                                    style={{ width: 8 }}
                                                /> : ''}</label>
                                                <div className='form__form-group-field'>
                                                    {/* <Field className='EstateName'
                                                        type={'text'}
                                                        name="BusinessName"
                                                        component={DefaultInput}
                                                        // options={this.state.EstateNames}
                                                        // onChange={(e) => this.handleChange(e)}
                                                        validate={required}
                                                        placeholder='Business Name'
                                                    /> */}
                                                    <Field
                                                        // key={i}
                                                        name={"SubCategory"}
                                                        component={Select}
                                                        validate={required}
                                                        // value={this.state.SubCategory ? this.state.SubCategory : null}
                                                        options={this.state.subCatg}
                                                        onChange={(e) => this.handleSubCategory(e)}
                                                        // onChange={(e) => this.handleChange(e)}
                                                        // defaultValue={this.props.formType && this.props.formType === 'add' ? item.defaultValue : null}
                                                        placeholder={"SubCategory"}
                                                    // isDisable={item.disable ? item.disable : false}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-sm-12 pl-0 pr-0">
                                            <div className="form__form-group pb-1">
                                                <label className="form__form-group-label">Note</label>
                                                <div className='form__form-group-field'>
                                                    {/* <Field className='EstateName'
                                                    type='textArea'
                                                    name="issueNoteName"
                                                    component={DefaultTextArea}
                                                    // options={this.state.EstateNames}
                                                    // onChange={(e) => this.handleChange(e)}
                                                    // validate={required}
                                                    placeholder='Issue Note'
                                                /> */}
                                                    <Editor style={{ height: '320px' }}
                                                        value={this.props && this.props.itemDetails && this.props.itemDetails.NoteText && this.state.type === "edit" ? this.props.itemDetails.NoteText : ""}
                                                        name="issueNoteName"
                                                        // name="buhisjs"
                                                        readOnly={this.props.itemDetails ? true : false}
                                                        onTextChange={(e) => this.setText(e.htmlValue)}
                                                    />

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                }




                                {/* <div className="col-sm-12 pl-0 pr-0">
                                    <div className="form__form-group pb-1">
                                        <label className="form__form-group-label">City{required ? <FontAwesomeIcon icon={faStarOfLife}
                                            className='pl-1'
                                            color='red'
                                            data-toggle="tool-tip"
                                            title="Mandotary Field"
                                            style={{ width: 8 }}
                                        /> : ''}</label>
                                        <div className='form__form-group-field'>
                                            <Field className='EstateName'
                                                type={'text'}
                                                name="company"
                                                component={DefaultInput}
                                                // options={this.state.EstateNames}
                                                // onChange={(e) => this.handleChange(e)}
                                                validate={required}
                                                placeholder='City'
                                            />
                                        </div>
                                    </div>
                                </div> */}
                                {/* <div className="col-sm-12 pl-0 pr-0">
                                    <div className="form__form-group pb-1">
                                        <label className="form__form-group-label">State{required ? <FontAwesomeIcon icon={faStarOfLife}
                                            className='pl-1'
                                            color='red'
                                            data-toggle="tool-tip"
                                            title="Mandotary Field"
                                            style={{ width: 8 }}
                                        /> : ''}</label>
                                        <div className='form__form-group-field'>
                                            <Field className='EstateName'
                                                type={'text'}
                                                name="company"
                                                component={DefaultInput}
                                                // options={this.state.EstateNames}
                                                // onChange={(e) => this.handleChange(e)}
                                                validate={required}
                                                placeholder='State'
                                            />
                                        </div>
                                    </div>
                                </div> */}
                                {/* <div className="col-sm-12 pl-0 pr-0">
                                    <div className="form__form-group pb-1">
                                        <label className="form__form-group-label">Zip Code{required ? <FontAwesomeIcon icon={faStarOfLife}
                                            className='pl-1'
                                            color='red'
                                            data-toggle="tool-tip"
                                            title="Mandotary Field"
                                            style={{ width: 8 }}
                                        /> : ''}</label>
                                        <div className='form__form-group-field'>
                                            <Field className='EstateName'
                                                type={'text'}
                                                name="company"
                                                component={DefaultInput}
                                                // options={this.state.EstateNames}
                                                // onChange={(e) => this.handleChange(e)}
                                                validate={required}
                                                placeholder='Zip Code'
                                            />
                                        </div>
                                    </div>
                                </div> */}

                            </CardBody>
                        </Card>
                    </ModalBody>
                    <ModalFooter className="modalFooter " style={{ width: "100%", padding: "10px" }}>
                        <Button color='danger' outline type="button" className="mr-auto ml-1 mb-0 " onClick={this.cancelReset}>
                            <FontAwesomeIcon
                                style={{ width: "15px" }}
                                // color="red"
                                icon={faArrowCircleLeft}
                            // data-toggle="tool-tip"
                            // title={"save"}
                            />
                            {t('Back') ? t('Cancel') : 'Cancel'} </Button>
                        <Button
                            style={{ float: "right" }}
                            color='primary'
                            className={"mb-0 "}
                            outline
                            type="submit">
                            <FontAwesomeIcon
                                style={{ width: "15px" }}
                                icon={faSave}
                            />
                            {type === 'edit' ? 'Update' : 'Save'}
                        </Button>
                    </ModalFooter>
                </form >
            </Modal >
        );
    }
}


AddressModal = reduxForm({
    form: "Addressmodel", // a unique identifier for this form
    validate,
    enableReinitialize: true,
})(AddressModal);

// You have to connect() to any reducers that you wish to connect to yourself
AddressModal = connect(
    state => ({
        initialValues: state.commonData.data // pull initial values from account reducer
    }),
    { load: loadAccount } // bind account loading action creator
)(AddressModal);

export default withTranslation('common')(AddressModal);
