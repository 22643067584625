import React from 'react';
import DataTables from '../../CommonDataTable/DataTable';
import apiCalls from '../../../../config/apiCalls'
import store from '../../../App/store';
import RolePermissions from '../../CommonModals/Permissions';

// config file
export default class Employees extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      type: 'Company'
    };
  }

  static getDerivedStateFromProps(props, state) {
    let storeData = store.getState()
    let languageData = storeData && storeData.settingsData && storeData.settingsData.settings && storeData.settingsData.settings.languageTranslation ? storeData.settingsData.settings.languageTranslation : ""
    return { languageData: languageData };
  }



  componentDidMount = () => {
    //Screen permisions value can be edit,view, no view
    let screenPermissions = RolePermissions.screenPermissions(this.state.type);
    this.setState({
      screenPermissions: screenPermissions
    })

  }

  getTableFields = () => {
    let data = [
      {
        "show": true,
        "textAlign": "left",
        "width": 120,
        "field": "CompanyID",
        "mobile": true,
        "header": "Company Id",
        "displayInSettings": true,
        filter: true,
        "sortable": true,
      },
      {
        "show": true,
        "textAlign": "left",
        "width": 220,
        "field": "CompanyName",
        "mobile": true,
        "header": "Company",
        "fieldType": "Link",
        "displayInSettings": true,
        filter: true,
        "sortable": true,
      },
      // {
      //   "show": true,
      //   "textAlign": "left",
      //   "width": 180,
      //   "field": "cnt",
      //   // "fieldType": "CityField",
      //   "mobile": true,
      //   "header": "Subsidiary Count",
      //   "displayInSettings": true,
      //   filter: true,
      //   "sortable": true,
      // },
      {
        "show": true,
        "textAlign": "left",
        "width": 180,
        "field": "HQCity",
        "fieldType": "CityField",
        "mobile": true,
        "header": "City State",
        "displayInSettings": true,
        filter: true,
        "sortable": true,
      },
      {
        "show": true,
        "textAlign": "left",
        "width": 150,
        "mobile": true,
        "field": "HQAddressLine1",
        "header": "Address Line 1",
        filter: true,
        "displayInSettings": true,
        "displayInFilter": false,
        //"options": [{ "label": "Active", "value": 'Active' }, { "label": "Pending", "value": 'Pending' }, { "label": "Inactive", "value": 'Inactive' }],
        "sortable": true,
      },


      {
        "show": true,
        "textAlign": "left",
        "width": 160,
        "field": "Website",
        "mobile": true,
        "header": "Website",
        "filter": true,
        "sortable": false,
        "displayInSettings": true,
      }, {
        "show": true,
        "textAlign": "left",
        "width": 115,
        "field": "Employees",
        "mobile": true,
        "header": "Employees",
        "allowInt": true,
        "filterType": "num",
        "filter": true,
        "sortable": true,
        "displayInSettings": true,
      },

      // {
      //   "show": true,
      //   "textAlign": "left",
      //   "width": 100,
      //   "field": "YearFounded",
      //   "allowInt": true,
      //   "filterType": "num",
      //   "mobile": true,
      //   "header": "Year Founded",
      //   "filter": true,
      //   "sortable": true,
      //   "displayInSettings": true,
      // },
      // {
      //   "show": true,
      //   "textAlign": "left",
      //   "width": 100,
      //   "field": "Revenue",
      //   "allowInt": true,
      //   "fieldType": "currency",
      //   "mobile": true,
      //   "filterType": "num",
      //   "header": "Revenue(in MM of USD)",
      //   "filter": true,
      //   "sortable": true,
      //   "displayInSettings": true,
      // },
      // {
      //   "show": true,
      //   "textAlign": "left",
      //   "width": 140,
      //   "field": "ParentCompany",
      //   "mobile": true,
      //   "header": "Parent Company",
      //   filter: true,
      //   "sortable": true,
      //   "displayInSettings": true,
      // },
      // {
      //   "show": true,
      //   "textAlign": "left",
      //   "width": 140,
      //   "field": "Description",
      //   "mobile": true,
      //   "header": "Description",
      //   filter: true,
      //   "sortable": true,
      //   "displayInSettings": true,
      // },
      // {
      //   "show": true,
      //   "textAlign": "left",
      //   "width": 140,
      //   "field": "DataSourceName",
      //   "mobile": true,
      //   "header": "Data Src Name",
      //   filter: true,
      //   "sortable": true,
      //   "displayInSettings": true,
      // }, {

      //   "show": true,
      //   "textAlign": "left",
      //   "width": 130,
      //   "field": "LastUpdated",
      //   "filterType": "num",
      //   "fieldType": "Date",
      //   "dateFormat": 'MM-DD-yyyy',
      //   "mobile": true,
      //   "header": "Updated",
      //   filter: true,
      //   "sortable": true,
      //   "displayInSettings": true,
      // }, {
      //   "show": true,
      //   "textAlign": "left",
      //   "width": 120,
      //   "field": "HQPostCode",
      //   "mobile": true,
      //   "header": "Post Code",
      //   filter: true,
      //   "sortable": true,
      //   "displayInSettings": true,
      // },
      // {
      //   show: true,
      //   mobile: true,
      //   textAlign: 'left',
      //   width: 180,
      //   field: 'Industry',
      //   header: 'Industry',
      //   sortable: true
      // },

    ]
    return data;
  };


  getPublicCompanies = () => {
    let data = [
      {
        "show": true,
        "textAlign": "left",
        "width": 60,
        "field": "CIK",
        "mobile": true,
        "header": "CIK",
        "displayInSettings": true,
        filter: true,
        "sortable": true,
      }, {
        "show": true,
        "textAlign": "left",
        "width": 170,
        "field": "Name",
        "fieldType": "Link",
        "mobile": true,
        "header": "Name",
        "displayInSettings": true,
        filter: true,
        "sortable": true,
      }, {
        "show": true,
        "textAlign": "left",
        "width": 120,
        "field": "StateofIncorporation",
        "mobile": true,
        "header": "State of Incorporation",
        filter: true,
        "sortable": true,
        "displayInSettings": true,
      },
      {
        "show": true,
        "textAlign": "left",
        "width": 180,
        "field": "BusinessAddressLine1",
        "mobile": true,
        "header": "Business Address Line1",
        filter: true,
        "sortable": true,
        "displayInSettings": true,
      }, {
        "show": true,
        "textAlign": "left",
        "width": 140,
        "field": "BusinessAddressCity",
        "mobile": true,
        "header": "Business Address City",
        filter: true,
        "sortable": true,
        "displayInSettings": true,
      }, {
        "show": true,
        "textAlign": "left",
        "width": 130,
        "field": "BusinessAddressStateOrCountry",
        "mobile": true,
        "header": "Business Add State Or Country",
        filter: true,
        "sortable": true,
        "displayInSettings": true,
      }, {
        "show": true,
        "textAlign": "left",
        "width": 160,
        "field": "MostRecent10KFormDate",
        "mobile": true,
        "fieldType": "Date",
        filterType: "num",
        // "isLink": "yes",
        "dateFormat": 'MM-DD-yyyy',
        "header": "Most Recent 10K Form Date",
        filter: true,
        "sortable": true,
        "displayInSettings": true,
      },
    ]
    return data;
  }
  submit = async (item) => {
    await this.setState({
      openViewModal: true,
      rowData: [{ 'label': 'name', 'value': 'new name' }]
    })
  }

  render() {
    return (
      <span>
        <DataTables
          onRef={(ref) => (this.datatableref = ref)}
          getTableFields={this.getTableFields}
          sortField={"created"}
          getPublicCompanies={this.getPublicCompanies}
          formFields={this.getFormFields}
          statusFilter={false}
          className={true}
          sortDirection={"desc"}
          isSql={"yes"}
          exportRequried={false}
          printRequried={false}
          addRequried={false}
          editRequired={false}
          deleteRequired={false}
          viewRequired={true}
          settingsRequired={false}
          filterRequired={false}
          gridRequried={false}
          sample={false}
          isClearFilters={true}
          globalSearchFieldName='user'
          // globalSearch={'Company Name/Company ID'}
          type={this.state.type}
          displayright={true}
          icon='apartment'
          routeTo='company'
          // displayViewOfForm='modal'
          apiResponseKey='companies'
          apiUrl={apiCalls.Companies}
          entityType='employee'
          displayViewOfForm="screen"
        />
      </span>
    );
  }
}