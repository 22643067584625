import React from 'react';
import { Container } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import DealStatusConfiguration from './components/DealStatusConfiguration';
const dealStatusConfiguration = ({ t }) => (
  <Container className="Custom_container">
    <DealStatusConfiguration />
  </Container>
);

dealStatusConfiguration.propTypes = {
  t: PropTypes.func.isRequired,
};

export default withTranslation('common')(dealStatusConfiguration);
