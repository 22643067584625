

import React from 'react';
import { Card } from 'reactstrap';
import { load as loadAccount } from '../../redux/reducers/commonReducer';
import { settings } from '../../redux/actions/settingsAction'
import { Field, reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Select from '../../shared/components/form/Select';
import { Redirect } from 'react-router-dom';
import SessionWarningModal from '../Cruds/CommonModals/SessionWarningModal';
import Reason from '../Cruds/PropertyReason/index';
import ClaimReason from '../Cruds/ClaimReason/index';
import Status from '../Cruds/PropertyStatus/index';
import ClaimStatus from '../Cruds/ClaimStatus/index'
import NextActionDateProjection from '../Cruds/NextActionDateProjection/index'
import PropertyAndClaimStatus from '../Cruds/PropertyAndClaimStatus/index';
import RolePermissions from '../Cruds/CommonModals/Permissions'
import NoViewComponent  from '../Cruds/CommonDataTable/NoView'

// Toaster message
import validate from '../Validations/validate';
// Loader
// import Loader from '../App/Loader';
// // Calendar
// //session expiry modal
// import store from '../App/store';

class SettingsForm extends React.Component {
  static propTypes = {
    t: PropTypes.func,
    handleSubmit: PropTypes.func.isRequired,
    reset: PropTypes.func.isRequired,
    pristine: PropTypes.bool.isRequired,
    submitting: PropTypes.bool.isRequired,
  };
  constructor(props) {
    super(props);
    this.state = {
      settingsData: '',
      options: [
        // { label: 'Property Status', value: 'Status' },
        // { label: 'Property Reason', value: 'Reason' },
        // { label: 'Claim Status', value: 'ClaimStatus' },
        // { label: 'Claim Reason', value: 'ClaimReason' },
        { label: 'Property And Claim Status', value: 'PropertyAndClaimStatus' },
        { label: 'Next Action Date Projection', value: 'NextActionDateProjection' },


        // { label: 'MM-DD-YYYY', value: 'MM-DD-YYYY' },
      ],
      isLoading: false,
      isUserUpdated: false,
     screenPermissions:""
    };
  }

  componentDidMount = async () => {
    // let apiUrl=''
    let screenPermissions = RolePermissions.screenPermissions("Status Reason Screen");

    this.setState({
      isLoading: true,
      screenPermissions: screenPermissions,
    });

    // })
  }
  //send data to server

  cancelSessionWarningModal = async () => {
    await this.setState({
      sessionWarning: false
    })
  }


  //stay signed in Modal
  getSessionWraningModal() {
    return (
      <SessionWarningModal
        openConfirmationModal={this.state.sessionWarning}
        cancelSessionWarningModal={this.cancelSessionWarningModal}
        getData={this.getKanbanCards}

      />
    )
  }

  handleScreenChange = async (e) => {
    await this.setState({
      selectedScreen: e
    })
  }
  saveDataToServer() { }
  render() {
    const { handleSubmit } = this.props;
    const { options } = this.state
    //
    return (
      (this.state.screenPermissions==="Edit" ||this.state.screenPermissions==="View") ?
        <div style={{ overflowX: 'hidden', height: '88vh' }} onLoad={this.apple}>
          {/* <Loader loader={isLoading} /> */}

          <form onSubmit={handleSubmit(this.saveDataToServer)}>
            <Card className='settingsCard row px-5'>
              <div className="" >
                <div className='col-sm-3'>
                  <div className="row create-header">
                    <h4 className="col-md-6 text-center py-3">Status Reason</h4>
                  </div>
                  <div className="form-group pt-0">
                    <div className="form-group pt-2">
                      <label>Choose Screen </label>
                      <div className="form__form-group-field">
                        <Field
                          name={'dateFormat'}
                          component={Select}
                          options={options}
                          onChange={(e) => this.handleScreenChange(e)}
                          placeholder={'Select Screen'}
                        />
                      </div>
                    </div>
                  </div>

                </div>
                <div>
                  {this.state.selectedScreen === "Status" ?
                    <Status /> : this.state.selectedScreen === "Reason" ? <Reason /> : this.state.selectedScreen === "ClaimStatus" ? <ClaimStatus /> : this.state.selectedScreen === "ClaimReason" ? <ClaimReason /> : this.state.selectedScreen === "PropertyAndClaimStatus" ? <PropertyAndClaimStatus /> : this.state.selectedScreen === 'NextActionDateProjection' ? <NextActionDateProjection /> : null}
                </div>
              </div>

              <div className='pl-5'>
              </div>
            </Card>
          </form>
          {
            this.state.isUserUpdated ?
              <Redirect to='/taskView' /> : null

          }
          {this.state.LoggotSuccess ? <Redirect to="/log_in" /> : null}
          {this.state.sessionWarning ? this.getSessionWraningModal() : null}
        </div > :this.state.screenPermissions===""?null :  <NoViewComponent/>


    );
  }
}

// Decorate with reduxForm(). It will read the initialValues prop provided by connect()
SettingsForm = reduxForm({
  form: "Common Form", // a unique identifier for this form
  validate,
  enableReinitialize: true,
})(SettingsForm);

// You have to connect() to any reducers that you wish to connect to yourself
SettingsForm = connect(
  state => ({
    initialValues: state.commonData.data // pull initial values from account reducer
  }),

  {
    load: loadAccount,// bind account loading action creator
    settingsLoad: settings,
    // settingsData:auth
  }
)(SettingsForm);

export default SettingsForm;




