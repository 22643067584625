import React from 'react';
import DataTables from '../../CommonDataTable/DataTable';
import apiCalls from '../../../../config/apiCalls'
import fetch from '../../../../config/service';
import store from '../../../App/store';
// import RolePermissions from '../../CommonModals/Permissions';

// config file
export default class ApaTypeConfiguration extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      type: 'APA Type Configuration'
    };
  }

  static getDerivedStateFromProps(props, state) {
    let storeData = store.getState()
    let languageData = storeData && storeData.settingsData && storeData.settingsData.settings && storeData.settingsData.settings.languageTranslation ? storeData.settingsData.settings.languageTranslation : ""
    return { languageData: languageData };
  }



  componentDidMount = async () => {
    await this.getStatusOptions()
    //Screen permisions value can be edit,view, no view
    // let screenPermissions = RolePermissions.screenPermissions('Employees');
  }
  getStatusOptions = () => {
    fetch('GET', apiCalls.PropertyStatuses)
      .then(async (response) => {
        if (response) {
          let statuses = response.propertyStatuses;
          let modifiedStatuses = [];
          for (let status of statuses) {
            modifiedStatuses.push({
              label: status.status,
              value: {
                status: status.status,
                id: status._id,
              },
            })
          }
          await this.setState({
            Statuses: modifiedStatuses
          })
          // this.setUnitName()

        }
      }).catch((err) => {
        return err;
      });
  }

  getTableFields = () => {
    let data = [
      // {
      //   "show": true,
      //   textAlign: 'left',
      //   width: 6,
      //   field: 'reOrder',
      //   // "displayInSettings": true,
      //   // "displayInFilter": true,
      //   // sortable: true,
      // },
      {
        "show": true,
        textAlign: 'left',
        // width: 60,
        field: 'apaTypeID',
        // fieldType: 'BoolText',
        "mobile": true,
        header: 'APA Type ID',
        filter: true,
        "displayInSettings": true,
        "displayInFilter": true,
        sortable: true,
      },

      {
        "show": true,
        textAlign: 'left',
        // width: 60,
        field: 'apaTypeName',
        fieldType: 'BoolText',
        "mobile": true,
        header: 'APA Type Name',
        filter: true,
        "displayInSettings": true,
        "displayInFilter": true,
        sortable: true,
      },
      {
        "show": true,
        textAlign: 'left',
        // width: 60,
        field: 'dealType',
        // fieldType: 'BoolText',
        "mobile": true,
        header: 'Deal Type',
        filter: true,
        "displayInSettings": true,
        "displayInFilter": true,
        sortable: true,
      },
      {
        "show": true,
        "textAlign": "left",
        // "width": 20,
        "fieldType": 'Actions',
        "field": "Actions",
        "header": "Actions",
        "filter": false,
        "displayInSettings": true,
        "sortable": false
      }
    ]
    return data;
  };

  getFormFields = () => {
    return ([
      // {
      //   //'show': false,
      //   // 'isMultiSelect': true,
      //   "value": "",
      //   "type": "dropDown",
      //   "name": "screen",
      //   "label": "Screen",
      //   "options": [{ label: "Ready To File", value: "Ready To File" }, { label: "On Hold", value: "On Hold" }],
      //   "id": "Screen",
      //   "placeholder": "Screen",
      //   "required": true
      // },
      {
        //'show': false,
        "value": "",
        "type": "number",
        "name": "apaTypeID",
        "label": "APA Type ID",
        "id": "name",
        "placeholder": "APA Type ID",
        "required": true
      },
      {
        //'show': false,
        "value": "",
        "type": "text",
        "name": "apaTypeName",
        "label": "APA Type Name",
        "id": "name",
        "placeholder": "APA Type Name",
        "required": true
      },
      {
        //'show': false,
        // 'isMultiSelect': true,
        "value": "",
        "type": "dropDown",
        "name": "dealType",
        "label": "Deal Type",
        "options": [{ label: "BK", value: "BK" }, { label: "CR", value: "CR" }],
        "id": "DealType",
        "placeholder": "Deal Type",
        "required": true
      },
      {
        //'show': false,
        "value": "",
        "type": "switch",
        "name": "activeIndicator",
        "label": "Active /Inactive Status",
        "id": "status",
        "placeholder": "status",
        // "required": true
      },
      // {
      //   //'show': false,
      //   'isMultiSelect': true,
      //   "value": "",
      //   "type": "dropDown",
      //   "name": "screenType",
      //   "label": "Screen",
      //   "id": "name",
      //   "options": [{ label: "Property Editor", value: "Property Editor" }, { label: "Claims", value: "Claims" }],
      //   "placeholder": "Screen",
      //   "required": true
      // },
    ]);
  }


  submit = async (item) => {

  }

  render() {
    return (
      <span>
        <DataTables
          onRef={(ref) => (this.datatableref = ref)}
          getTableFields={this.getTableFields}
          formFields={this.getFormFields}
          sortField={"apaTypeID"}
          exportRequried={false}
          printRequried={false}
          addRequried={true}
          editRequired={true}
          isClearFilters={true}
          deleteRequired={false}
          viewRequired={true}
          settingsRequired={false}
          filterRequired={true}
          gridRequried={false}
          sample={false}
          globalSearchFieldName='user'
          globalSearch={'Search'}
          type='APA Type Configuration'
          displayright={true}
          icon='tag'
          routeTo='configurations'
          displayViewOfForm='modal'
          apiResponseKey={"apaTypeConfigurations"}
          apiUrl={apiCalls.Apatypeconfigurations}
          entityType='employee'
        />

      </span>
    );
  }
}