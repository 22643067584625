import React from 'react';
import { InputText } from 'primereact/inputtext'
import { Button, } from 'reactstrap';
import { faSave, faSync, faTimes, faClone, faPlus, faArrowsAlt, faUndo, faRedo, faCaretSquareDown, faCaretSquareUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Dropdown } from 'primereact/dropdown';
import { InputTextarea } from 'primereact/inputtextarea';
import SaveOrder from './SavePreset'
import ExcelModel from './ExcelModal'
import ExcelModeModal from './ExcelModeModal';
import { OverlayPanel } from 'primereact/overlaypanel';
import ShowPreset from './ShowPreset'
import configImages from '../../../config/configImages';
import { withTranslation } from 'react-i18next';
import { Calendar } from 'primereact/calendar';
import { MultiSelect } from 'primereact/multiselect';
import Label from '../Label';
import { faCopy } from '@fortawesome/free-regular-svg-icons';
import { async } from '@firebase/util';


// config file
class PresetBtnActions extends React.Component {
  constructor(props) {
    super(props);
    this.clearDateField = React.createRef();
    this.clearInputValue = React.createRef();
    this.cleardropDownValue = React.createRef();
    this.state = {
      saveOrderModal: false,
      showOrderModal: false,
      isExpanded: false,
      active_Preset: this.props.active_Preset ? this.state.active_Preset : null,
      isClickedDownload: false,
      value: null,
      openPanel: false,
      disableRedoButton: true,
      disableUndoButton: true,
      rowIndex: null,
      editedFieldoptions: null
    };
  }

  componentWillUnmount() {
    if (this.props.onRef) {
      this.props.onRef(null);
    }
    document.removeEventListener('mousedown', this.handleClickOutside)

  }

  componentDidMount = async () => {
    if (this.props.onRef) {
      this.props.onRef(this);
    }
    document.addEventListener('mousedown', this.handleClickOutside)
  }

  //sending message from the response to parent component
  showToasterMsg = (msg, type) => {
    this.props.getResponseMsgfromServer(msg, type)
  }

  /*
   when ever click on outside of the input value is clear
   */
  handleClickOutside = async (event) => {
    if (this.props.isEditableGrid) {
      if (this.clearInputValue.current && !this.clearInputValue.current.contains(event.target)) {
        if (document.getElementsByClassName("selectedGridPanel").length === 0 ||
          (document.getElementsByClassName("selectedGridPanel").length > 0 && document.getElementsByClassName("selectedGridPanel")[0] && document.getElementsByClassName("selectedGridPanel")[0].style.display === "none")) {
          if (document.getElementById("selectedGridValue").value) {
            document.getElementById(`selectedGridValue`).innerHTML = "";
            var selectedRow = document.getElementsByTagName("tr")[this.state.rowIndex + 1]
            if (selectedRow && selectedRow.children && selectedRow.children[this.state.columnindex]) {
              selectedRow.children[this.state.columnindex].style.removeProperty("border");
              selectedRow.children[this.state.columnindex].style.removeProperty("borderRadius");
              selectedRow.children[this.state.columnindex].style.removeProperty("background-color");
              selectedRow.children[this.state.columnindex].style.removeProperty("-webkit-box-shadow");
            }


            this.updateValue({
              editedRowField: "",
              editedRowIndex: "",
              editedField: "",
              editedFieldoptions: null,
              value: null,
              selectedFieldHeader: "",
              editColumnField: null,
            })
          }
          //For handle the multiselect and dropdown options
          if (!event.target.innerHTML.includes(this.state.editedFieldoptions) && event.target.children.length > 0 && !event.target.innerHTML.includes("p-checkbox-icon")) {
            document.getElementsByClassName("p-dropdown-label p-inputtext")[0].innerHTML = "";
            if (document.getElementsByClassName(`p-multiselect-label`)[0]) {
              document.getElementsByClassName(`p-multiselect-label`)[0].innerHTML = "";
            }
            var selectedRow = document.getElementsByTagName("tr")[this.state.rowIndex + 1]
            if (selectedRow && selectedRow.children && selectedRow.children[this.state.columnindex]) {
              selectedRow.children[this.state.columnindex].style.removeProperty("border");
              selectedRow.children[this.state.columnindex].style.removeProperty("borderRadius");
              selectedRow.children[this.state.columnindex].style.removeProperty("background-color");
              selectedRow.children[this.state.columnindex].style.removeProperty("-webkit-box-shadow");

            }


            this.updateValue({
              editedRowField: "",
              editedRowIndex: "",
              editedField: "",
              editedFieldoptions: null,
              value: null,
              selectedFieldHeader: "",
            })

          }


        }
      } else {
        let data = document.getElementsByTagName("tr")[this.state.rowIndex + 1]
        if (data && data.cells[this.state.columnindex]) {
          // setTimeout(() => data.cells[this.state.columnindex].click(), 1100);

        }
      }
    }
  };

  /*
   invoke when the action buttons are clicked
   those functions are getting from the edit datatable
  */
  handleSelectedAction = (selectedAction) => {
    if (selectedAction === "refresh") {
      this.props.refresh(selectedAction);
    } else if (selectedAction === "excelDownload") {
      // this.setState({
      //   isClickedDownload: true
      // })
      // this.props.isDownloadExcel()
      this.setState({ openExcelModal: true })
    } else if (selectedAction === "Save") {
      this.props.savingFunc()
    } else if (selectedAction === "selectAll") {
      this.props.selectingRecords()
    } else if (selectedAction === "next") {
      this.props.redoClick()
    } else if (selectedAction === "delete") {
      this.props.deletingRow()
    } else if (selectedAction === "previous") {
      this.props.undoClick("previous")
    } else if (selectedAction === "add") {
      this.props.addingRow("add")
    } else if (selectedAction === "duplicate") {
      this.props.duplicateRows()
    }
  }

  // setting true or false for Download clicked 
  handleDownloadclick(value) {
    this.setState({
      isClickedDownload: value
    })
  }

  //to change the value in the table
  setValueInGrid = async (data, e) => {
    console.log("eee",this.state.value, data,e)
    if (this.state.editedFieldoptions !== null || this.state.editType === "date") {
      await this.setState({
        value: e
      })
    }
    this.props.setValueInGrid(e.value, this.state.rowIndex, this.state.field, this.state.editType, this.state.updatedobj)
    data.stopPropagation()
  }


  //get the value when the table cell is edited
  updateValue = async (obj) => {

    let shownvalue = obj.editType && obj.editType === "date" && obj.editedField !== null && obj.editedField.length >= 10 ?
      new Date(JSON.parse(JSON.stringify(obj.editedField))) : obj.editedRowField === "IssueName" && typeof obj.editedField === "string" ? obj.editedField.split(",") :
        obj.editedField;
    // calenderDate: tableValue[e.rowIndex][e.field] && tableValue[e.rowIndex][e.field].length >= 10 ? new Date(JSON.parse(JSON.stringify(tableValue[e.rowIndex][e.field]))) : null
    await this.setState({
      updatedobj: obj,
      columnindex: obj.columnindex,
      value: shownvalue,
      rowIndex: obj.editedRowIndex,
      field: obj.editedRowField,
      editedFieldoptions: obj.editedFieldoptions && obj.editedFieldoptions.length > 0 ? obj.editedFieldoptions : null,
      editType: obj.editType,
      editColumnField: obj.editColumnField && obj.editColumnField.dataKey ? obj.editColumnField.dataKey : null
    })
    if (obj.editedFieldoptions) {
      this.setState({
        isExpanded: false
      })
    }

    if (document.getElementsByClassName("p-inputtext p-component ml-2")[0]) {
      document.getElementsByClassName("p-inputtext p-component ml-2")[0].value = obj.editedField ? obj.editedField : null
    }
  }

  setValueUpdate(isClick) {

    var selectedRow = document.getElementsByTagName("tr")[this.state.rowIndex + 1]
    if (this.props.type !== "ClaimsManagement" && selectedRow && selectedRow.children && selectedRow.children[this.state.columnindex]) {
      selectedRow.children[this.state.columnindex].style.border = "1px solid rgb(141 205 255)"
      selectedRow.children[this.state.columnindex].style.borderRadius = "3px"
      selectedRow.children[this.state.columnindex].style.backgroundColor = "white"
      selectedRow.children[this.state.columnindex].style["-webkit-box-shadow"] = "inset rgb(141 205 255) 0px 0px 0px 0.2em"
    }
  }


  //Edited Input for changing the Width
  getFilterFields() {
    return (
      <span className='w-70' style={{ width: "70%" }}>
        <div className='selectedField'>{this.state.updatedobj ? this.state.updatedobj.selectedFieldHeader : null}</div>
        <div className="center_filters d-flex" id='center_filters' ref={this.clearInputValue} onClick={(e) => this.setValueUpdate(e)} >
          <span><FontAwesomeIcon icon={faCopy}
            className='pl-1'
            color={this.state.value && this.state.value.toString().length > 0 ? '#354f6e' : 'lightgrey'}
            disabled={this.state.value && this.state.value.toString().length > 0 ? true : false}
            data-toggle="tool-tip"
            onClick={(e) => this.copyToClipBoard(this.state.value)}
            title="Refresh"
            style={{ width: 22, height: 18, marginRight: "4px" }}
          /></span>{
            this.state.editType === "date" ?
              <div ref={this.clearDateField} style={{ width: "100%" }}><Calendar style={{ zindex: 1004, width: '100%', lineHeight: 1.15, height: '25px' }}
                appendTo={document.body}
                monthNavigator={true}
                yearNavigator={true}
                panelClassName="selectedGridPanel"
                inputId="selectedGridValue"
                yearRange="1940:2530"
                readOnlyInput={true}
                // id="selectedGridValue"
                // minDate={this.props.type.includes("Scheduler") ? new Date() : ""}
                // name={item.field}
                placeholder='mm/dd/yyyy'
                dateFormat="mm/dd/yy"
                value={this.state.value}
                // value={props.value && props.value[props.rowIndex] && props.value[props.rowIndex][item.field] && (props.value[props.rowIndex][item.field].length >= 10 || props.value[props.rowIndex][item.field].length === undefined) ? new Date(props.value[props.rowIndex][item.field]) : props.value && props.value[props.rowIndex] && props.value[props.rowIndex][item.field] && props.value[props.rowIndex][item.field].length < 11 ? props.value[props.rowIndex][item.field] : ""}
                onSelect={(e) => this.setValueInGrid(e, e.value)}
              /></div>
              : this.state.field === "IssueName" ?
                <div style={{ width: "100%" }}>
                  <MultiSelect
                    value={this.state.value}
                    appendTo={document.body}
                    id="selectedGridValue"
                    filter={true}
                    className="selectedGridValue"
                    style={{ height: 32, width: "100%" }}
                    onChange={(e) => this.setValueInGrid(e, e.value)}
                    options={this.state.editedFieldoptions} /></div>
                :
                this.state.editedFieldoptions === null && this.state.isExpanded ?
                  <><div style={{ width: "100%" }}>

                    <InputTextarea
                      cols={120}
                      rows={7}
                      id="selectedGridValue"
                      onBlur={() => this.props.updateTableData()}
                      className='ml-2'
                      defaultValue={this.state.editType !== "isDisabled" && this.state.value ? this.state.value : ""}
                      disabled={this.props.type === "ClaimsManagement" || this.state.editType === "textDisable" ? true : false}
                      style={{ height: 32, width: "100%" }}
                      // onFocus={this.changeBorder}
                      onKeyUp={(e) => this.setValueInGrid(e, e.target.value)}
                    />
                  </div>
                    <div className='pl-1'>
                      <div >
                        <FontAwesomeIcon icon={faCaretSquareUp}
                          className='pl-1'
                          color='#354f6e'
                          data-toggle="tool-tip"
                          title="Collapse"
                          style={{ width: 22, height: 18, }}
                          onClick={() => this.getExpandedandFocus()}
                        />
                      </div>
                    </div></>


                  : this.state.editedFieldoptions === null && !this.state.isExpanded ?

                    <div className="d-flex" style={{ width: "100%" }}><div style={{ width: "100%" }}>
                      <InputText
                        // type="search"
                        className='ml-2'
                        id="selectedGridValue"
                        defaultValue={this.state.editType !== "isDisabled" && this.state.value ? this.state.value : ""}
                        style={{ width: "100%" }}
                        disabled={this.state.editType === "isDisabled" || this.state.editType === "textDisable" || this.props.type === "ClaimsManagement" ? true : false}
                        onKeyUp={(e) => this.setValueInGrid(e, e.target.value)}
                        // onFocus={this.changeBorder}
                        onBlur={() => this.props.updateTableData()}
                      // placeholder={"Search Business Name"}
                      // size={"20"} 
                      />
                    </div>
                      <div className='pl-1' >
                        <FontAwesomeIcon icon={faCaretSquareDown}
                          className='pl-1 toexpand_icon'
                          color='#354f6e'
                          data-toggle="tool-tip"
                          title="Expand"
                          style={{ width: 22, height: 18 }}
                          onClick={() => this.getExpandedandFocus()}
                        />
                      </div>

                    </div>
                    : this.state.editedFieldoptions ? <Dropdown

                    
                      ref={this.cleardropDownValue}
                      value={this.state.editType !== "isDisabled" ? this.state.value : ""}
                      appendTo={document.body}
                      id="selectedGridValue"
                      style={{ height: 32, width: "100%" }}
                      onChange={(e) => this.setValueInGrid(e, e.value)}
                      options={this.state.editedFieldoptions}
                      optionLabel={this.state.editColumnField ? 'label' : null}
                      dataKey={this.state.editColumnField ? "BusinessNamesID" : null}
                      optionValue={this.state.editColumnField ? "value" : null}
                    /> : null}

        </div>
      </span>
    )
  }

  /**
* Setting the active preset 
*/
  setActivePreset = async (preset) => {
    this.setState({ active_Preset: preset })
  }

  changeOrder = async (type, message, errorView, presetName) => {
    if (type.active_Preset || type) {
      await this.setState({
        isLoading: true,

      })
      // this.handlePercentage("1")
    }
    await this.setState({
      isshowToasterMsg: true,
      showToasterMsg: message,
      backgroundColor: errorView === "Error" ? "rgb(250 167 157)" : "#d1fbc1",

    })
    this.showToasterMsg(message, errorView)
    this.closeOrderModal()
    if (type !== "") {
      await this.props.loadAllPresets("save")
      await this.props.getDataFromServerToSetPreset(presetName)
    }
  }

  saveOrder = async () => {
    await this.setState({
      saveOrderModal: true
    })
  }

  closeOrderModal = async () => {
    await this.setState({
      saveOrderModal: false,
      showOrderModal: false,
      openDeleteModal: false,
      selectedRows: [],
      deleArray: [],
    })
  }

  showavailableOrderFormats = async () => {
    await this.setState({
      showOrderModal: true
    })
  }


  getExpandedandFocus = () => {
    this.setState({
      isExpanded: !this.state.isExpanded
    })

  }
  /**
 * Calling the Preset button view
 */

  getPresetButton() {
    return (
      <div className="row ml-1 mr-1" style={{ marginTop: "10px", }}>
        {/* <div className="preset_Functionality" id='preset_Functionality'> */}
        <Button
          color="primary"
          outline
          style={{
            height: "32px",
            //  marginLeft: "15px"
          }}
          // style={{ color: "#354f6e", backgroundColor: "white", borderColor: "#354f6e" }}
          onClick={this.saveOrder}
        >

          Save Preset
        </Button>
        <Button
          color="primary"
          outline
          style={{ height: "32px", }}
          // style={{ color: "#354f6e", backgroundColor: "white", borderColor: "#354f6e" }}
          onClick={() => this.showavailableOrderFormats()}
        >
          Load Preset
        </Button>
        <span class="btn active_preset" style={{ color: "grey", "font-weight": "bold", marginRight: "auto", }}>Active Preset :<span style={{ color: "black", "font-weight": "bold", marginTop: "6px", }}>{this.state.active_Preset}</span>
        </span>
        {/* </div> */}
        {/* <div className="record_counts"> */}
        <span style={{ color: "grey", "font-weight": "bold", marginTop: "6px" }}>Total Rows to Submit :<span style={{ color: "black", "font-weight": "bold", marginTop: "6px" }}>{this.props.submitedRecordsCount ? this.props.submitedRecordsCount.length : 0}</span>
        </span>
        <span style={{ color: "grey", "font-weight": "bold", marginLeft: "50px", marginTop: "6px" }}>Total Rows in Grid :<span style={{ color: "black", "font-weight": "bold", marginTop: "6px" }}>{this.props.totalRecords ? this.props.totalRecords : 0} </span>
        </span>
        {/* </div> */}
        <div style={{ marginLeft: "auto", display: "flex" }} >
          <InputText
            type="search"
            className="mx-2"
            name='globalSearch'
            id='globalSearch'
            // value={this.state.selectsearchFilterValue}
            style={{ height: 32, width: "103%" }}
            onKeyPress={(e) => this.props.filteredValues(e)}
            onChange={(e) => this.props.filteredValues(e)}
            placeholder={"Search"}
            size={25}
          //  disabled={true}
          />
        </div>

        <span>
          <Button color="primary"
            size="sm"
            outline
            style={{ height: 32 }}
            className="py-1 px-5 mb-0 mx-1"
            onClick={() => this.props.getremoveFilters("Clear Column")}>Clear Column Filters
          </Button>
        </span>
      </div>
    )
  }

  updateTableFields = async (tablefieldsToShow) => {

    await this.setState({ tablefieldsToShow: tablefieldsToShow })
  }

  closeModelOnDefault = async () => {
    await this.setState({
      showOrderModal: false
    }
    )
  }
  /**
  * disabling the redo button
  */
  enableRedoButton = async () => {
    this.setState({ disableRedoButton: true, })
  }
  /**
 * disabling the undo button
 */
  enableUndoButton = async () => {
    await this.setState({ disableUndoButton: true })
  }
  /**
* enabling the undo button
*/
  disableUndoButton = async () => {
    await this.setState({ disableUndoButton: false })
  }
  /**
* enabling the redo button
*/
  disableRedoButton = async () => {
    this.setState({ disableRedoButton: false, })
  }

  /**
   * Sending Undo Button status for short cut key disable
   */
  sendUndoButtonStatus = async () => {
    return this.state.disableUndoButton
  }
  /**
     * Sending Redo Button status for short cut key disable
     */
  sendRedoButtonStatus = async () => {
    return this.state.disableRedoButton
  }

  /**
* Calling only Preset button view
*/

  getOnlyPresetButton() {
    return (
      <div className="row" style={{ marginTop: "10px", }}>
        {/* <div className="preset_Functionality" id='preset_Functionality'> */}
        <Button
          color="primary"
          outline
          style={{ height: "32px", marginLeft: "15px" }}
          // style={{ color: "#354f6e", backgroundColor: "white", borderColor: "#354f6e" }}
          onClick={this.saveOrder}
        >

          Save Preset
        </Button>
        <Button
          color="primary"
          outline
          style={{ height: "32px", }}
          // style={{ color: "#354f6e", backgroundColor: "white", borderColor: "#354f6e" }}
          onClick={() => this.showavailableOrderFormats()}
        >
          Load Preset
        </Button>
      </div>
    )
  }



  /**
* open the preset model
*/
  showOrders() {
    return (
      <SaveOrder
        saveOrderModal={this.state.saveOrderModal}
        closeOrder={this.closeOrderModal}
        ActivePreset={this.state.active_Preset ? this.state.active_Preset : ""}
        activeTab={this.props.activeTab === "3" ? "Names" : this.props.activeTab === "4" ? "Addresses" : this.props.activeTab}
        EditorScreen={this.props.EditorScreen}
        type={"Sellers"}
        showToasterMsg={this.showToasterMsg}
        tablefieldsToShow={this.state.tablefieldsToShow}
        changeOrder={this.changeOrder}
      />
    )
  }
  /**
   * Clsoing Excel model
   */
  closeExcelModel = async () => {
    this.setState({ openExcelModal: false })
  }
  getExcelFile = async (namePreferences, addressPreferences, strArray, preferencesClaims, activeTab, mode) => {
    let namePreference = [], addressPreference = [], preferences = []

    for (let name of namePreferences) {
      if (name.field !== "") {
        if (name.field === "LegacyConnectedNameLabel") {
          namePreference.push("LegacyConnectedName")
        } else {
          namePreference.push(name.field)
        }
      }
    }
    for (let address of addressPreferences) {
      if (address.field !== "") {
        if (address.field === "LegacyConnectedNameLabel") {
          addressPreference.push("LegacyConnectedName")
        } else if (address.field === "LocationNameLabel") {
          addressPreference.push("LocationName")
        } else {
          addressPreference.push(address.field)
        }
      }
    }
    for (let preference of preferencesClaims) {
      if (preference.field !== "") {
        preferences.push({ label: preference.header, field: preference.field })
      }
    }



    const nameBusinessNamesID = namePreference.findIndex(a => a === 'BusinessNamesID')
    nameBusinessNamesID === -1 && namePreference.unshift("BusinessNamesID")

    const nameDealID = namePreference.findIndex(a => a === 'DealID')
    nameDealID === -1 && namePreference.unshift("DealID")

    const addressBusinessLocationID = addressPreference.findIndex(a => a === 'BusinessLocationID')
    addressBusinessLocationID === -1 && addressPreference.unshift("BusinessLocationID")

    const addressDealID = addressPreference.findIndex(a => a === 'DealID')
    addressDealID === -1 && addressPreference.unshift("DealID")



    // namePreference.unshift("DealID", "BusinessNamesID")
    // addressPreference.unshift("DealID", "BusinessLocationID")
    this.closeExcelModel()
    this.props.getExcelFileForDownload(namePreference, addressPreference, strArray, preferences, activeTab, {}, null, mode)
  }

  /**
   * Open Excel Model
   */

  excelModel() {
    return (
      <ExcelModel
        saveOrderModal={this.state.openExcelModal}
        closeExcelModel={this.closeExcelModel}
        getExcelFile={this.getExcelFile}
        strArray={this.props.strArray}
        ActivePreset={this.state.active_Preset ? this.state.active_Preset : ""}
        activeTab={this.props.activeTab === "3" ? "Names" : this.props.activeTab === "4" ? "Addresses" : this.props.activeTab ? this.props.activeTab : null}
        type={"Sellers"}
        tablefieldsToShow={this.state.tablefieldsToShow}
      // changeOrder={this.changeOrder}
      />
    )
  }
  /**
   * Open Excel Model
   */

  excelModeModel() {
    return (
      <ExcelModeModal
        saveOrderModal={this.state.openExcelModal}
        closeExcelModel={this.closeExcelModel}
        getExcelFile={this.getExcelFile}
        strArray={this.props.strArray}
        ActivePreset={this.state.active_Preset ? this.state.active_Preset : ""}
        activeTab={this.props.activeTab === "3" ? "Names" : this.props.activeTab === "4" ? "Addresses" : this.props.activeTab ? this.props.activeTab : null}
        type={"Sellers"}
        tablefieldsToShow={this.state.tablefieldsToShow}
      // changeOrder={this.changeOrder}
      />
    )
  }

  /**
* Close the preset model
*/
  closeOrderModal = async () => {
    await this.setState({
      saveOrderModal: false,
      showOrderModal: false,
      openDeleteModal: false,
      selectedRows: [],
      deleArray: [],
    })
  }
  /**
* Show the preset model
*/
  showavailableOrders() {
    return (<ShowPreset
      saveOrderModal={this.state.showOrderModal}
      closeOrder={this.closeOrderModal}
      closeModelOnDefault={this.closeModelOnDefault}
      setTableFieldsOrderEmpty={this.props.setTableFieldsOrderEmpty}
      getDataFromServerToSetPreset={this.props.getDataFromServerToSetPreset}
      activeTab={this.props.activeTab === "3" ? "Names" : this.props.activeTab === "4" ? "Addresses" : this.props.activeTab ? this.props.activeTab : null}
      type={"Sellers"}
      // keyname={keyname}
      tablefieldsToShow={this.state.tablefieldsToShow}
      changeOrder={this.changeOrder}
    />
    )

  }
  openPanel = async () => {
    this.setState({ openPanel: true })
  }
  openPanelPopup = async () => {

  }

  copyToClipBoard = async (value) => {
    navigator.clipboard.writeText(value)

  }

  render() {
    return (
      <div className='p-2 mt-2' style={{ background: "white" }} >
        {this.getPresetButton()}
        <div className="button_func sm-12 mx-1" style={{ display: "flex", width: "100%", justifyContent: "space-between" }} id='button_func'
        // style={{ justifyContent: "space-between" }}
        >
          <div className="left_buttons" >
            {this.props.type === "ClaimsManagement" ? null : <img
              src={configImages.sellectAllIcon}
              alt=""
              // icon="fa-solid fa-arrows-up-down-left-right"
              icon={faArrowsAlt}
              className='pl-1'
              color='#354f6e'
              data-toggle="tool-tip"
              title={"Select All Records"}
              style={{ width: 24, height: 22, marginLeft: "10px", marginTop: "0px", marginBottom: "6px" }}

              onClick={(e) => this.handleSelectedAction("selectAll")}
            />}

            {this.props.type === "ClaimsManagement" ? null :
              <FontAwesomeIcon icon={faTimes}
                className='pl-1'
                color={this.props.selectedRecords && this.props.selectedRecords.length === 0 ? 'lightgrey' : '#354f6e'}
                data-toggle="tool-tip"
                title="click to remove selected row"
                style={this.props.selectedRecords && this.props.selectedRecords.length === 0 ? { width: 22, height: 18, cursor: "pointer", pointerEvents: 'none' } : { width: 22, height: 18, cursor: "pointer", pointerEvents: "visible" }}
                onClick={(e) => this.handleSelectedAction("delete")}
                disabled={this.props.selectedRecords && this.props.selectedRecords.length === 0 ? false : true}

              />
            }
            {this.props.type === "ClaimsManagement" ? null :
              <FontAwesomeIcon icon={faClone}
                className='pl-1'
                color={this.props.selectedRecords && this.props.selectedRecords.length === 0 ? 'lightgrey' : '#354f6e'}
                data-toggle="tool-tip"
                title="Duplicate Row"
                style={this.props.selectedRecords && this.props.selectedRecords.length === 0 ? { width: 22, height: 18, cursor: "pointer", pointerEvents: 'none' } : { width: 22, height: 18, cursor: "pointer", pointerEvents: "visible" }}
                onClick={(e) => this.handleSelectedAction("duplicate")}
                disabled={this.props.selectedRecords && this.props.selectedRecords.length === 0 ? false : true}
              />
            } {this.props.type === "ClaimsManagement"&&this.props.screenPermissions==="Edit" ? <Button
              color="primary"
              outline
              style={{ height: "32px", marginBottom: '0px' }}
              // style={{ color: "#354f6e", backgroundColor: "white", borderColor: "#354f6e" }}
              onClick={(e) => this.handleSelectedAction("add")}
            ><FontAwesomeIcon
                icon='plus'
                className='Addbtn' size='sm'
                data-toggle="tool-tip" title={("Add")}
              // onClick={(e) => this.openFormModal('add')}
              />

              New Claim
            </Button> :
              <FontAwesomeIcon icon={faPlus}
                className='pl-1'
                color='#354f6e'
                data-toggle="tool-tip"
                id="Add Row"
                onClick={(e) => this.handleSelectedAction("add")}

                title="Add Row"
                style={this.props.screenPermissions==="Edit"?{ width: 22, height: 18, marginLeft: "10px" }:{display:"none"}}
              />
            }
            {/* {this.props.type === "ClaimsManagement" ? null :
              <FontAwesomeIcon icon={faUndo}
                className='pl-1'
                color={this.state.disableUndoButton ? 'lightgrey' : 'green'}
                data-toggle="tool-tip"
                onClick={(e) => this.handleSelectedAction("previous")}
                title="Undo"
                id="Undo"
                style={this.state.disableUndoButton ? { width: 22, height: 18, marginLeft: "10px", cursor: "pointer", pointerEvents: "none" } : { width: 22, height: 18, marginLeft: "10px", cursor: "pointer", pointerEvents: "visible" }}

              />
            }
            {this.props.type === "ClaimsManagement" ? null :
              <FontAwesomeIcon icon={faRedo}
                className='pl-1'
                color={this.state.disableRedoButton ? 'lightgrey' : 'green'}
                data-toggle="tool-tip"

                onClick={() => this.handleSelectedAction("next")}
                title="Redo"
                id="Redo"
                // style={{ width: 22, marginLeft: "10px" }}
                style={this.state.disableRedoButton ? { width: 22, height: 18, marginLeft: "10px", cursor: "pointer", pointerEvents: "none" } : { width: 22, height: 18, marginLeft: "10px", cursor: "pointer", pointerEvents: "visible" }}

              />
            } */}

          </div>
          {this.getFilterFields()}
          <div className='right_buttons sm-2 mt-1 mr-2' id=' right_buttons' style={{ display: "flex", justifyContent: "end" }} >
            {this.props.type === "ClaimsManagement" ?
              <div>
                <div class="btn-group-vertical tooltipClaims" style={{ marginRight: "0px", height: "30px" }} onClick={(e) => this.op.toggle(e)} data-toggle="tool-tip"
                  title="click to see definations" >

                  <button style={{ borderColor: "black", marginRight: "0px", marginBottom: "0px", width: "3%", backgroundColor: "#E2EFDA" }} type="button" class="btn btn-secondary"></button>
                  <button style={{ borderColor: "black", marginRight: "0px", marginBottom: "0px", width: "3%", backgroundColor: "#FFC7CE" }} type="button" class="btn btn-secondary"></button>
                  <button style={{ borderColor: "black", marginRight: "0px", width: "3%", backgroundColor: "#FFF2CC" }} type="button" class="btn btn-secondary"></button>

                </div>
                <OverlayPanel style={{ left: "1500px" }} ref={(el) => this.op = el} showCloseIcon={true} dismissable={true}>
                  <div>
                    <label style={{ width: "80%" }}>
                      Actual Reported Value
                    </label>
                    <button style={{ marginBottom: "0px", width: "3%", backgroundColor: "#E2EFDA" }} type="button" class="btn btn-secondary"></button>
                  </div>
                  <div>
                    <label style={{ width: "80%" }}>
                      Mix of Actual + Estimated Property Value
                    </label>
                    <button style={{ marginBottom: "0px", width: "3%", backgroundColor: "#FFC7CE" }} type="button" class="btn btn-secondary"></button>
                  </div>
                  <div>
                    <label style={{ width: "80%" }}>
                      Only Estimated Property Value
                    </label>
                    <button style={{ marginBottom: "0px", width: "3%", backgroundColor: "#FFF2CC" }} type="button" class="btn btn-secondary"></button>
                  </div>

                </OverlayPanel>
              </div>


              : null}


            {/* <div>
                            <InputSwitch checked={this.props.textAreaValue} onChange={(e) => this.setState({ textAreaValue: e.value })} />
                        </div> */}
            <div className='pl-2'>
              <FontAwesomeIcon icon={faSync}
                className='pl-1'
                color='#354f6e'
                data-toggle="tool-tip"
                onClick={(e) => this.handleSelectedAction("refresh")}
                title="Refresh"
                style={{ width: 22, height: 18 }}
              />
            </div>
            {this.state.isClickedDownload ?
              this.props.getExcelDownload()
              :
              <div className='pl-2'>
                <FontAwesomeIcon icon='download'

                  color='#354f6e'
                  data-toggle="tool-tip"
                  onClick={(e) => this.handleSelectedAction("excelDownload")}
                  title="Download Excel"
                  id="Download Excel"
                  style={{ width: 22, height: 18 }}
                />
              </div>
            }
            {this.props.type === "ClaimsManagement" ? null :
              <FontAwesomeIcon icon={faSave}
                className='pl-1'
                color={this.props.isClickSaved ? 'lightgrey' : '#354f6e'}

                data-toggle="tool-tip"
                id="Save Records"
                title="Save Records"
                onClick={(e) => this.handleSelectedAction("Save")}
                style={this.props.isClickSaved ? { width: 22, height: 18, marginLeft: "10px", cursor: "pointer", pointerEvents: "none" } : { width: 22, height: 18, marginLeft: "10px", cursor: "pointer", pointerEvents: "visible" }}
              />
            }
          </div>

        </div>
        {this.state.saveOrderModal ? this.showOrders() : null}
        {this.state.openExcelModal && this.props.type === "ClaimsManagement" ? this.excelModeModel() : this.state.openExcelModal ? this.excelModel() : null}
        {this.state.showOrderModal ? this.showavailableOrders() : null}
        {this.state.openPanel ? this.openPanelPopup() : null}
      </div >
    );
  }
}
export default withTranslation('common')(PresetBtnActions);