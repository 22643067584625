import React from 'react';
import { Container } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import DocumentUploadComponent from './components/DocumentUpload';

const DocumentUpload = (props) => (
  // <Container>
    <DocumentUploadComponent {...props} />
  // </Container>
);

DocumentUpload.propTypes = {
  t: PropTypes.func.isRequired,
};

export default withTranslation('common')(DocumentUpload);