import React, { PureComponent } from 'react';
import {
  Card, CardBody, Col, Button
} from 'reactstrap';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
// file upload
import { Dropdown } from 'primereact/dropdown';
import { MultiSelect } from 'primereact/multiselect';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import Loader from '../../../App/Loader';
import fetch from '../../../../config/service'
import dateFormats from '../../../../containers/UI/FormatDate/formatDate';
import configImages from '../../../../config/configImages';
import getFileSize from '../../../../containers/UI/FileUpload/fileSize';

const bodyStyle = {
  textOverflow: 'ellipsis',
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  width: 150,
  fontSize: 12,
  textAlign: "left",
  verticalAlign: "inherit"
}


const headerStyle = {
  width: 150,
  textAlign: "center",
}

var selectedColumnOptionsData = [
  { field: 'Property - DataSourceId', header: 'Property - DataSourceId' },
  { field: 'Property - StatePropertyId', header: 'Property - StatePropertyId' },
  { field: 'Property - StateId', header: 'Property - StateId' },
  { field: 'Property - County', header: 'Property - County' },
  { field: 'Property - CheckNumber', header: 'Property - CheckNumber' },
  { field: 'Property - OwnerName', header: 'Property - OwnerName' },
  { field: 'Owner Address - Line1', header: 'Owner Address - Line1' },
  { field: 'Owner Address - Line2', header: 'Owner Address - Line2' },
  { field: 'Owner Address - City', header: 'Owner Address - City' },
  { field: 'Owner Address - StateId', header: 'Owner Address - StateId' },
  { field: 'Owner Address - Zip', header: 'Owner Address - Zip' },
  { field: 'Owner Address - County', header: 'Owner Address - County' },
  { field: 'Owner Address - Country', header: 'Owner Address - Country' },
  { field: 'Owner - TaxID', header: 'Owner - TaxID' },
  { field: 'Property - PropertyDescription', header: 'Property - PropertyDescription' },
  { field: 'Property - PropertyType', header: 'Property - PropertyType' },
  //{ field: 'Property - HolderName', header: 'Property - HolderName' },
  { field: 'Property - PropertyValueMin', header: 'Property - PropertyValueMin' },
  { field: 'Property - PropertyValueMax', header: 'Property - PropertyValueMax' },
  { field: 'Property - DateEscheated', header: 'Property - DateEscheated' },
  { field: 'Property - DateOfLastContact', header: 'Property - DateOfLastContact' },
  { field: 'Property - AlternateID', header: 'Property - AlternateID' },
  //{ field: 'Property - HolderExtraInfo', header: 'Property - HolderExtraInfo' },
  //{ field: 'Property - Shares', header: 'Property - Shares' },
  { field: 'Property - NumOfShares', header: 'Property - NumOfShares' },
  { field: 'Property - StockName', header: 'Property - StockName' },
  { field: 'Property - StockSymbol', header: 'Property - StockSymbol' },
  { field: 'Property - EstimatedPerShareValue', header: 'Property - EstimatedPerShareValue' },
  { field: ' Property - DateWhenShareValueEstimated', header: ' Property - DateWhenShareValueEstimated' },
  { field: ' Property - TotalEstimatedSharesValue', header: ' Property - TotalEstimatedSharesValue' },
  { field: 'Property - StockCUSIP', header: 'Property - StockCUSIP' },
  { field: 'Holder - Name', header: 'Holder - Name' },
  { field: 'Holder - ExtraInfo', header: 'Holder - ExtraInfo' },
  { field: 'Holder - TaxID', header: 'Holder - TaxID' },
  { field: 'Holder - ReportID', header: 'Holder - ReportID' },
  { field: 'Holder - StateOfIncorporation', header: 'Holder - StateOfIncorporation' },
  { field: 'Holder Address - Line1', header: 'Holder Address - Line1' },
  { field: 'Holder Address - Line2', header: 'Holder Address - Line2' },
  { field: 'Holder Address - City', header: 'Holder Address - City' },
  { field: 'Holder Address - State', header: 'Holder Address - State' },
  { field: 'Holder Address - Zip', header: 'Holder Address - Zip' },
  { field: 'Holder Address - County', header: 'Holder Address - County' },
  { field: 'Holder Address - Country', header: 'Holder Address - Country' },
  { field: 'Holder - Phone', header: 'Holder - Phone' },
  { field: 'Holder - Email', header: 'Holder - Email' },
  { field: 'Claim - StateClaimID', header: 'Claim - StateClaimID' }
];

const custombodyTemplate = (rowData, col) => {
  if (col.field === "Property - StateId" || col.field === "Property - StatePropertyId" || col.field === "Property - County") {
    if (col.field === "Property - StateId" && rowData.InvalidStateId) {
      return <span className="errordatatablebgcolr">{rowData[col.field]}</span>
    }
    if (col.field === "Property - StatePropertyId" && rowData.InvalidStatePropertyId) {
      return <span className="errordatatablebgcolr">{rowData[col.field]}</span>
    }
    if (col.field === "Property - County" && rowData.EmptyCounty) {
      return <span className="errordatatablebgcolr">{rowData[col.field]}</span>
    }
    return <span>{rowData[col.field]}</span>

  } else {
    return <span>{rowData[col.field]}</span>
  }
};


class AddPropertiesLoader extends PureComponent {
  static propTypes = {
    t: PropTypes.func,
    handleSubmit: PropTypes.func.isRequired,
    reset: PropTypes.func.isRequired,
    pristine: PropTypes.bool.isRequired,
    submitting: PropTypes.bool.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      click: true,
      filename: "File Name",
      dataUploadMode: "Smart Overwrite",
      dataUploadModeOption: [{ label: "Retain Existing", value: "Retain Existing" }, { label: "Smart Overwrite", value: "Smart Overwrite" }, { label: "Confirm Onsite Only", value: "Confirm Onsite Only" }],
      displaySelectFieldsField: false,
      selectedColumns: [],
      selectedColumnsOptions: [],
      errorMessage: "Error Message",
      "verifiedRows": null,
      "uploadedRows": null,
      "failedRows": null,
      "duplicateRows": null,
      "rowsAfterDuplicates": null,
      selectedFile: null,
    };
  }
  getvalidation = () => {
    this.setState({ validation: true })
    this.fileUploadRef.clearFile();
  }

  onSelect = (e) => {
    if (e.target.files[0].name.endsWith(".xlsx") || e.target.files[0].name.endsWith(".xls")) {
      let fileSize = e.target.files[0].size ? getFileSize(e.target.files[0].size) : 0
      let todaydate = dateFormats.formatDate(
        new Date(),
        "MM/DD/YYYY", "nhg")
      this.setState({
        fileName: e.target.files[0].name,
        fileSize: fileSize,
        uploadedDate: todaydate,
        responseMessage: "",
        selectedFile: e.target.files[0],
        validation: false,
        errorMessageName: "",
        fileQueue: [],
        "verifiedRows": null,
        "uploadedRows": null,
        "failedRows": null,
        "duplicateRows": null,
        "rowsAfterDuplicates": null,
      })


    } else {
      this.setState({
        fileName: "",
        fileSize: null,
        selectedFile: null,
        validation: true
      })
    }
  }

  uploadFileDataToServer = () => {
    this.clearMessage();
    this.setState({ isLoading: true })
    let customFormData = {}
    let userData = localStorage.getItem('loginCredentials');
    userData = JSON.parse(userData);
    customFormData.uploadedByUsername = userData.displayName
    customFormData.uploadedByUserId = userData.azureAdUserId
    fetch('POST', "propertyloaderrepo/uiProperty/upload", this.state.selectedFile, 'upload', customFormData)
      .then(async (response) => {
        this.setState({
          fileName: this.state.selectedFile.name ? this.state.selectedFile.name : "",
          isLoading: false,
          selectedFile: null
        })
        var failedFileQueue = [];
        if (response && response.results) {
          if (response.results.invalidRows > 0) {
            var resultset = response.results.resultSet;
            if (resultset && resultset.length > 0) {
              for (let res of resultset) {
                if (res && (res.InvalidStateId || res.InvalidStatePropertyId || res.EmptyCounty)) {
                  failedFileQueue.push(res)
                }
              }
              this.setState({
                fileQueue: failedFileQueue, "verifiedRows": response.results.validRows,
                "uploadedRows": response.results.totalRows,
                "failedRows": response.results.invalidRows
              })
            }
          } else if (response.results.importResults) {
            this.setState({
              "duplicateRows": response.results.importResults.numberOfDuplicateProperties,
              "uploadedRows": response.results.importResults.numberOfPropertiesBeforeDedup,
              "rowsAfterDuplicates": response.results.importResults.numberOfPropertiesAfterDedup,
              "responseMessage": response.message
            })
          }
        } else if (response && response.errorMessage) {
          this.setState({
            isLoading: false,
            errorMessageName: response.errorMessage
          })
        } else if (response && response.message) {
          this.setState({
            responseMessage: response.message
          })
        }
      }).catch((err) => {
        return err;
      });
  }

  clearMessage = () => {
    this.setState({
      responseMessage: "",
      errorMessageName: "",
      validation: false,
      "verifiedRows": null,
      "uploadedRows": null,
      "failedRows": null,
      "duplicateRows": null,
      "rowsAfterDuplicates": null,
      fileName: "",
      fileSize: null,
      response: true,
      isLoading: false,
      fileQueue: []
    })
    document.getElementById("myFile").value = null
  }
  onFilterChanges = async (field, value) => {
    if (field && value) {
      await this.setState({
        [field]: value
      });
      if (field == "dataUploadMode") await this.setState({
        displaySelectFieldsField: value == "Retain Existing" ? true : false,
        selectedColumns: []
      });
    }
  }
  componentDidMount = async () => {
    //Screen permisions value can be edit,view, no view
    let fields = selectedColumnOptionsData;
    let selectedColumnsOptions = [];
    if (fields.length)
      for (let obj of fields) {
        selectedColumnsOptions.push({ label: obj.header, value: obj.field })
      }
    let loginCredentials = JSON.parse(localStorage.getItem("loginCredentials"));
    await this.setState({ loginCredentials, selectedColumnsOptions })
  }


  render() {

    return (
      <Card >

        <Loader loader={this.state.isLoading} />
        <CardBody>
          <div className='row'>

            {  /** Data upload mode*/}
            {/* <div className='col-lg-12 d-flex'>
              <div class="mt-3 col-lg-2 font-weight-bold" >{"Data Upload Mode"}</div>
              <div class="mt-2 ml-3 col-lg-3" >{<Dropdown className=''
                type={'text'}
                id="dataUploadMode"
                value={this.state.dataUploadMode}
                placeholder={""}
                name="dataUploadMode"
                style={{
                  minWidth: "100%",
                  maxWidth: "100%",
                  height: 32
                }}
                options={this.state.dataUploadModeOption}
                onChange={(e) => this.onFilterChanges("dataUploadMode", e.value)}
              />}</div>
            </div> */}
            {  /** Select column field */}
            {this.state.displaySelectFieldsField ? <div className='col-lg-12 d-flex'>
              <div class="mt-3 col-lg-2 font-weight-bold">{"Selected Columns"}</div>
              <div class="mt-2 ml-3 col-lg-3" s >{<MultiSelect
                name={"selectedColumns"}
                filter={true}
                value={this.state.selectedColumns}
                style={{ width: "100%", height: 32 }}
                maxSelectedLabels={1}
                options={this.state.selectedColumnsOptions}
                onChange={(e) => this.onFilterChanges('selectedColumns', e.value)}
              />}</div>
            </div> : null}
            {  /** Load an Excel */}
            <div className='col-lg-12 d-flex' style={{ textAlign: "left" }}>
              <div class="mt-3 col-lg-2 font-weight-bold" >{"Load an Excel"}</div>
              <div class="mt-1 ml-3 col-lg-7">
                <span >
                  <Button className="p-fileupload-choose btn btn-primary mt-1" style={{ width: "20%", height: 30 }} disabled={this.state.selectedFile ? true : false} >
                    <input
                      type="file"
                      id="myFile"
                      onChange={(e) => this.onSelect(e)}
                      accept={".xlsx, .xls"} />
                    Select File
                  </Button>
                </span >

                <span >
                  <Button
                    style={{ width: "20%", height: 30, }}
                    color="primary"
                    className="import_upload ml-2 mt-1 mt-1"
                    onClick={() => this.uploadFileDataToServer()}
                    disabled={this.state.selectedFile ? false : true}
                  >Upload</Button>
                </span>

              </div>
              {  /** For blank download template */}
              <div className='row'>
                <div className='col-5'>
                  {'Download blank template'}
                </div>
                <div className='col-7'>
                  <a className='btn btn-primary outline text-white' href={configImages.addpropertySamplefile} download={configImages.addpropertySamplefile.split("/").slice(-1)[0]}>Download</a>
                </div>
              </div>
            </div>
            {  /** Uploaded File */}
            <div className='col-lg-12 d-flex'>
              <div class="col-lg-2 font-weight-bold">{"Uploaded File"}</div>
              <div class="ml-3 col-lg-7"> {this.state.fileName ?
                <h5 style={{ fontWeight: "bold" }} >{`${this.state.fileName}`}</h5>
                : ""}</div>
            </div>
            {  /** Total Verified Rows */}
            {this.state.verifiedRows != null ?
              <div className='col-lg-12 d-flex'>
                <div class="mt-3 col-lg-2 font-weight-bold">{"Valid Properties"}</div>
                <div class="mt-3 ml-3 col-lg-7"> {this.state.fileName || true ?
                  <h5 style={{ fontWeight: "bold" }} >{this.state.verifiedRows}</h5>
                  : ""}</div>
              </div> : ""}
            {  /** Total Uploaded Rows */}
            {this.state.uploadedRows != null ?
              <div className='col-lg-12 d-flex'>
                <div class="mt-3 col-lg-2 font-weight-bold">{"Total Properties"}</div>
                <div class="mt-3 ml-3 col-lg-7"> {this.state.fileName || true ?
                  <h5 style={{ fontWeight: "bold" }} >{this.state.uploadedRows}</h5>
                  : ""}</div>
              </div> : ""}
            {  /** Total Verification Failed Rows" */}
            {this.state.failedRows != null ?
              <div className='col-lg-12 d-flex'>
                <div class="mt-3 col-lg-2 font-weight-bold">{"Invalid Properties"}</div>
                <div class="mt-3 ml-3 col-lg-7"> {this.state.fileName || true ?
                  <h5 style={{ fontWeight: "bold", color: "red" }} >{`Verification failed for the following `} {this.state.failedRows} {` rows. No data was loaded
                into OPRA. Please correct these errors and resubmit`}</h5>
                  : ""}</div>
              </div> : ""}
            {  /** Total Duplicate Properties */}
            {this.state.duplicateRows != null ?
              <div className='col-lg-12 d-flex'>
                <div class="mt-3 col-lg-2 font-weight-bold">{"Duplicate Properties"}</div>
                <div class="mt-3 ml-3 col-lg-7"> {this.state.fileName || true ?
                  <h5 style={{ fontWeight: "bold" }} >{this.state.duplicateRows}</h5>
                  : ""}</div>
              </div> : ""}
            {  /** Total Properties after Dedup */}
            {this.state.rowsAfterDuplicates != null ?
              <div className='col-lg-12 d-flex'>
                <div class="mt-3 col-lg-2 font-weight-bold">{"Properties after Dedup"}</div>
                <div class="mt-3 ml-3 col-lg-7"> {this.state.fileName || true ?
                  <h5 style={{ fontWeight: "bold" }} >{this.state.rowsAfterDuplicates}</h5>
                  : ""}</div>
              </div> : ""}
            <div>
              {this.state.validation ?
                <i> <h5 >*Please select only xlsx and xls file.</h5></i>
                : ""}
            </div>
          </div>
          {this.state.errorMessageName ?

            <div className='row text-center' style={{ borderTop: "2px solid #aaa" }}>
              <div class="mt-2 ml-3 col-sm-12" style={{ color: "red", fontWeight: "bold" }} >{this.state.errorMessageName}</div>
            </div>
            : ""}


          {this.state.responseMessage ?
            <div className='row text-center' style={{ borderTop: "2px solid #aaa" }}>
              <div class="mt-2 ml-3 col-sm-12" style={{ color: "green", fontWeight: "bold" }} >{this.state.responseMessage}</div>
            </div>
            : ""}
          <div>
            {this.state.failedRows > 0 ?
              <DataTable value={this.state.fileQueue ? this.state.fileQueue : []} scrollHeight="1000px" resizableColumns={true} columnResizeMode="expand" className='mt-2'
                paginator rows={20} rowsPerPageOptions={[5, 10, 20, 25, 50]} tableStyle={{ minWidth: '50rem' }}
              >


                {selectedColumnOptionsData.map((col, i) => (
                  <Column key={col.field} field={col.field} header={col.header}
                    bodyStyle={bodyStyle} headerStyle={headerStyle} body={custombodyTemplate} />
                ))}

              </DataTable>
              : ""}
            {this.state.validation ?
              <i> <h5 >*Please select only Zip file.</h5></i>
              : ""}
          </div>

        </CardBody>
      </Card >


    )
  }

}

export default withTranslation('common')(AddPropertiesLoader);