import React from 'react';
import { Col, Container, Row } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import CountDisplay from './components/CountDisplay';

class Dashboard extends React.Component {
  static propTypes = {
    t: PropTypes.func.isRequired,
    payload: PropTypes.arrayOf(PropTypes.shape({
      color: PropTypes.string,
      value: PropTypes.string,
    })).isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {};
  }





  componentDidMount = () => {
    let user = JSON.parse(localStorage.getItem('loginCredentials'));
    if (user) {
      this.setState({
        loginData: user,
        role: user.role
      })
    }
  }

  responseData = () => {

  }


  render() {
    return (
      <Container className="tableCardBody" style={{ width: '98%', marginLeft: '20px' }}>
        {/* <Loader loader={this.state.isLoading} /> */}

        <Row>
          <Col md={12}>
          </Col>
        </Row>
        <CountDisplay
          responseData={this.state.responseData}
          loginData={this.state.loginData}
        />




      </Container >
    );
  }
}

export default withTranslation('common')(Dashboard);


