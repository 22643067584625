
import fetch from '../config/service';
import Config from '../config/config';


const reload = async () => {
  fetch('GET', `settings`)
    .then(async (response) => {
      if (response && response.settings) {
        if (response.settings[0] && response.settings[0].version) {
          if ((response.settings[0].version > Config.version) || !Config.version) {
            setInterval(
              async function () {
                console.log("Successfully Uploaded New ")

                window.location.reload();
              },
              10000
            )
            console.log("Hello Get else config ")

          }
        } console.log("Hello response.settings[0] else config ")

      } else if (response && response.errorMessage) {
      }
    }).catch((err) => {
      return err;
    });

};

export default {
  reload,
};