import React from 'react';
import { Container } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import NextActionDateProjection from './components/NextActionDateProjection';

const nextActionDateProjection = ({ t }) => (
  <Container
  // className="Custom_container"
  >
    <NextActionDateProjection />
  </Container>
);

nextActionDateProjection.propTypes = {
  t: PropTypes.func.isRequired,
};

export default withTranslation('common')(nextActionDateProjection);
