import React from 'react';
import DataTables from '../../CommonDataTable/DataTable';
import apiCalls from '../../../../config/apiCalls'
import store from '../../../App/store';
import RolePermissions from '../../CommonModals/Permissions';

// config file
export default class Attribute extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      type: 'Attributes'
    };
  }

  static getDerivedStateFromProps(props, state) {
    let storeData = store.getState()
    let languageData = storeData && storeData.settingsData && storeData.settingsData.settings && storeData.settingsData.settings.languageTranslation ? storeData.settingsData.settings.languageTranslation : ""
    return { languageData: languageData };
  }



  componentDidMount = () => {
    //Screen permisions value can be edit,view, no view
    let screenPermissions = RolePermissions.screenPermissions(this.state.type);
    this.setState({
      screenPermissions: screenPermissions
    })

  }

  /**
   * 
   * @returns tableFieldsto show in table
   */
  getTableFields = () => {
    let data = [
      {
        "show": true,
        "textAlign": "left",
        "width": 180,
        "field": "attributeName",
        "mobile": true,
        "header": "Attribute Name",
        "displayInSettings": true,
        filter: true,
        "sortable": true,
      },
      {
        "show": true,
        "textAlign": "left",
        "width": 180,
        "field": "groupName",
        "mobile": true,
        "header": "Group Name",
        "displayInSettings": true,
        filter: true,
        "sortable": true,
      },

      {
        "show": true,
        "textAlign": "left",
        "width": 180,
        "field": "propertyName",
        "mobile": true,
        "header": "Property Name",
        "displayInSettings": true,
        filter: true,
        "sortable": true,
      },
      {
        "show": true,
        "textAlign": "left",
        "width": 150,
        "mobile": true,
        "field": "dataTypeCode",
        "header": "Type Code",
        filter: true,
        "displayInSettings": true,
        "displayInFilter": false,
        "sortable": true,
      },
      {
        "show": true,
        "textAlign": "left",
        "width": 180,
        "field": "dataValueTypeCode",
        "mobile": true,
        "header": "Value Code",
        filter: true,
        "sortable": true,
        "displayInSettings": true,
        "options": [{ "label": "Active", "value": 'Active' }, { "label": "Pending", "value": 'Pending' }, { "label": "Inactive", "value": 'Inactive' }],
      },
      {
        "show": true,
        "textAlign": "left",
        "width": 180,
        "fieldType": 'Actions',
        "field": "Actions",
        "header": "Actions",
        filter: true,
        "displayInSettings": true,
        "sortable": false
      }
    ]
    return data;
  };


  /**
   * 
   * @returns formFields show in table
   */
  getFormFields = () => {
    return ([
      {
        //'show': false,
        "value": "",
        "type": "text",
        "name": "attributeName",
        "label": "Attribute Name",
        "id": "name",
        "displayInSettings": true,
        "placeholder": "Attribute Name",
        "required": true
      },
      {
        //'show': false,
        "value": "",
        "type": "text",
        "name": "attributeCode",
        "label": "Attribute Code",
        "id": "attributeCode",
        "displayInSettings": true,
        "placeholder": "Attribute Code",
        "required": true
      },
      {
        "value": "",
        "type": "text",
        "name": "groupName",
        "label": "Group Name",
        "displayInSettings": true,
        "id": "email",
        "placeholder": "Group Name",
        "required": true
      },
      {
        "value": "",
        "type": "text",
        "name": "propertyName",
        "label": "Property Name",
        "displayInSettings": true,
        "id": "phone",
        // "formatType": 'US',
        "placeholder": "Property Name",
        "required": true
      },
      {
        "value": "",
        "type": "dropDown",
        "name": "dataTypeCode",
        "label": "Type Code",
        "id": "dataTypeCode",
        //"formatType": 'US',
        "placeholder": "Type Code",
        "options": [{ "label": "Integer", "value": 'NUM' }, { "label": "String", "value": 'CHR' }],
        "required": true
      },
      {
        "value": "",
        "type": "dropDown",
        "name": "dataValueTypeCode",
        "label": "Value Code",
        "id": "dataValueTypeCode",
        //"formatType": 'US',
        "placeholder": "Value Code",
        "options": [
          { "label": "List", "value": 'LST' },
          { "label": "Free Form Entity", "value": 'FFE' }],
        "required": true
      },
      {
        //'show': false,
        "value": "",
        "type": "switch",
        "name": "activeIndicator",
        "label": "Status",
        "id": "name",
        "placeholder": "Name",
        // "required": true
      },
    ]);
  }

  submit = async (item) => {
    await this.setState({
      openViewModal: true,
      rowData: [{ 'label': 'name', 'value': 'new name' }]
    })
  }

  render() {
    return (
      <span>
        <DataTables
          onRef={(ref) => (this.datatableref = ref)}
          getTableFields={this.getTableFields}
          formFields={this.getFormFields}
          statusFilter={false}
          className={true}
          exportRequried={false}
          printRequried={false}
          addRequried={true}
          editRequired={true}
          isClearFilters={true}
          deleteRequired={true}
          viewRequired={true}
          settingsRequired={false}
          filterRequired={false}
          gridRequried={false}
          sample={false}
          globalSearchFieldName='user'
          globalSearch={'Search'}
          type={this.state.type}
          displayright={true}
          icon='cog'
          routeTo='attribute'
          displayViewOfForm='modal'
          apiResponseKey='attributes'
          apiUrl={apiCalls[this.state.type]}
          entityType='employee'
        />
      </span>
    );
  }
}