import React from 'react';
import { Button } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import fetchMethodRequest from '../../../config/service';
import apiCalls from '../../../config/apiCalls';
import ConfirmationModal from '../CommonModals/ConfirmationModal';
import dateFormats from "../../UI/FormatDate/formatDate";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faTimesCircle, faPencilAlt } from '@fortawesome/free-solid-svg-icons';
import ClaimDetail from "../CommonModals/ClaimDetails"
import Loader from '../../App/Loader';
import EditDataTable from '../../Cruds/CommonModals/EditDatatable';

// import ClaimDetails from '../../Cruds/CommonModals/ClaimCreationfromPE/NewClaim'

const style = {
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  border: "1px solid gray"
};
const filter = { criteria: [], sortfield: "claimStatus_LastCheckedDate", direction: "desc", limit: 20 }
const followUpFilter = { criteria: [], sortfield: "followUpNumber,followUpUpdateDate", direction: "desc", limit: 20 }
  ;
// config file
class FollowUpHistoryModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      width: 1200,
      height: 600,
      x: 885,
      y: -950,
      allSelectedRows: {},
      connectedTaskArray: this.props.rowData
    };
  }

  componentDidMount = async () => {
    console.log("tgis.pssss", this.props)
    const { rowData } = this.props
    await this.centerElement()
    if (this.props.onRef) {
      this.props.onRef(this);
    }
    await this.getUsers()
    if (this.props.type === "followUpNumber" && this.props.viewType === "Edit") {
      let properties = this.props.rowData.latestFollow
      properties = await this.updateDateFormat(
        properties,
        this.props.getTableFieldsForHistory(),
        "MM/DD/YYYY"
      );
      this.setState({ properties: properties, isLoading: false })
      this.editdt.getValueforTable(properties, 1)
    }
    else if (this.props.type === "followUpNumber") {
      followUpFilter.criteria = [{ key: "oppClaimID", type: "eq", value: this.props.rowData.oppClaimID }]
      followUpFilter.sortfield = "followUpNumber,followUpUpdateDate"
      followUpFilter.direction = "desc"
      await this.getFollowUpDetails("", followUpFilter)
    } else {
      filter.criteria = [{ key: "StateClaimID", type: "eq", value: this.props.rowData.stateClaimID }, { key: "SourceState", type: "eq", value: this.props.rowData.sourceState }]
      filter.sortfield = "claimStatus_LastCheckedDate"
      filter.direction = "desc"
      await this.getPropertyDetails("", filter)
    }
    await this.getClaimStatusReason();
    await this.getClaimStatusReasonforReasonArray();
  }
  /**
 *
 * @returns the claim status reason array for claims filter dropdown
 */
  getClaimStatusReason = async () => {
    let claimStatusArray = [],
      claimReasonArray = [];
    let filterCriteria = {};
    filterCriteria["criteria"] = [
      { key: "activeIndicator", value: true, type: "eq", add_Filter: true },
      { key: "claimStatusFlag", value: true, type: "eq", add_Filter: true },
      { key: 'propertyStatusUsage', value: 'Property Editor', type: 'eq', add_Filter: true }
    ];
    filterCriteria.sortfield = "stageSequence, statusSequence, reasonSequence";
    filterCriteria.direction = "asc";
    await fetchMethodRequest(
      "GET",
      `${apiCalls.propertyStatusReason}?filter=${JSON.stringify(
        filterCriteria
      )}`
    ).then(async (response) => {
      if (
        response &&
        response["propertyStatusReasons"] &&
        response["propertyStatusReasons"].length > 0
      ) {
        let propertyStatusReasons = response["propertyStatusReasons"];
        for (let propertyStatusReason of propertyStatusReasons) {
          // if (propertyStatusReason.claimSequence) {
          if (
            !claimStatusArray.some(
              (obj) => obj.value == propertyStatusReason.status
            )
          ) {
            await claimStatusArray.push({
              label: propertyStatusReason.status,
              value: propertyStatusReason.status,
            });
          }
          await claimReasonArray.push({
            label: propertyStatusReason.reason,
            value: propertyStatusReason.reason,
          });
          // }
        }

        await this.setState({
          claimStatusArray: claimStatusArray,
          claimReasonArray: claimReasonArray,
          propertyStatusReasons: propertyStatusReasons,
        });
      } else if (response && response["errorMessage"]) {
        this.getErrorResponsefromServer(response);
      }
    });
    return this.state.claimStatusArray;
  };

  /**
   *
   * @returns the claim status reason array for claims filter dropdown
   */
  getClaimStatusReasonforReasonArray = async () => {
    let claimStatusArray = [],
      claimReasonArray = [];
    let filterCriteria = {};
    filterCriteria["criteria"] = [
      { key: "activeIndicator", value: true, type: "eq", add_Filter: true },
      { key: "claimStatusFlag", value: true, type: "eq", add_Filter: true },
      { key: 'propertyStatusUsage', value: 'Property Editor', type: 'eq', add_Filter: true }
    ];
    filterCriteria.sortfield = "stageSequence, statusSequence, reasonSequence";
    filterCriteria.direction = "asc";
    await fetchMethodRequest(
      "GET",
      `${apiCalls.propertyStatusReason}?filter=${JSON.stringify(
        filterCriteria
      )}`
    ).then(async (response) => {
      if (
        response &&
        response["propertyStatusReasons"] &&
        response["propertyStatusReasons"].length > 0
      ) {
        let propertyStatusReasons = response["propertyStatusReasons"];
        for (let propertyStatusReason of propertyStatusReasons) {
          // if (propertyStatusReason.claimSequence) {

          await claimReasonArray.push({
            label: propertyStatusReason.reason,
            value: propertyStatusReason.reason,
          });
          // }
        }

        await this.setState({ claimReasonArray: claimReasonArray });
      } else if (response && response["errorMessage"]) {
        this.getErrorResponsefromServer(response);
      }
    });
    return this.state.claimReasonArray;
  };
  componentDidUpdate() {
    if (this.state.successResponse) {
      setTimeout(() => this.setState({ successResponse: false, successMessage: '' }), 50000);
    }
    if (this.state.respMessage) {
      setTimeout(() => this.setState({ respMessage: '' }), 50000);
    }

  }

  setDataAfterSave = async (details) => {
    let properties = [details]
    properties = await this.updateDateFormat(
      properties,
      this.props.getTableFieldsForHistory(),
      "MM/DD/YYYY"
    );
    this.setState({ properties: properties, isLoading: false })
    this.editdt.getValueforTable(properties, 1)

  }
  getUsers = async () => {
    let userArray = []
    let filterCriteria = {};
    filterCriteria['criteria'] = [{ key: "role", value: ["Search Analyst", "Search Manager"], type: "nin" }];
    return fetchMethodRequest('GET', `${apiCalls.Users}?filter=${JSON.stringify(filterCriteria)}`)
      .then(async (response) => {
        if (response && response.users && response.users.length > 0) {
          for (let user of response.users) {
            if (user.display_name !== null) {
              userArray.push({ label: user.display_name, value: user.display_name })
            }
          }
          await this.setState({ userArray: userArray })
        }
      }).catch((err) => {
        return err;
      });
  }
  centerElement = async (element) => {
    // Get the dimensions of the viewport
    const viewportWidth = window.innerWidth;
    const viewportHeight = window.screen.height
    // Get the dimensions of the element
    const elementWidth = 1200;
    const elementHeight = 600;
    const leftPosition = (viewportWidth - elementWidth) / 2;
    const topPosition = (viewportHeight - elementHeight) / 2;
    await this.setState({ x: leftPosition, y: topPosition })
  }
  /**
  *
  * @param {*} itemdata
  * @param {*} tableFields
  * @param {*} dateFormat
  * @returns
  */
  updateDateFormat = async (itemdata, tableFields, dateFormat) => {
    let modifiedData = [];
    for (let i = 0; i < itemdata.length; i++) {
      for (let k = 0; k < tableFields.length; k++) {
        if ("dateTime" === tableFields[k]["fieldType"]) {
          if (itemdata[i][tableFields[k]["field"]] !== null) {
            itemdata[i][tableFields[k]["field"]] = dateFormats.formatDate(
              itemdata[i][tableFields[k]["field"]],
              "MM/DD/YYYY",
              "NS"
            );
            if (i === 0) {
            }
          }
        }
      }
      modifiedData.push(itemdata[i]);
    }
    return modifiedData;
  };
  /**
* 
* @returns getting the property details based on the property ids
*/
  getFollowUpDetails = async (activeTab, filterCriteria, type) => {
    await this.setState({ isLoading: true })
    let index = filterCriteria.criteria.findIndex(obj => obj.key === "oppClaimID")
    if (index === -1) {
      filterCriteria.criteria.push({ key: "oppClaimID", type: "eq", value: this.props.rowData.oppClaimID })
    }
    let followUpindex = filterCriteria.criteria.findIndex(obj => obj.key === "followUpType")
    if (this.props.screenType === "NextActionFollowUp") {
      if (followUpindex === -1) {
        filterCriteria.criteria.push({ key: "followUpType", type: "eq", value: "Past Due" })
      }
    }
    // if (type && type === "Clear Column" && filterCriteria) {
    //   filterCriteria.criteria = []
    // }
    // filterCriteria.sortfield = "propertyID"
    // filterCriteria.direction = "desc"
    let url
    await this.setState({ propertyUrl: `${apiCalls.followUpHistories}?filter=${JSON.stringify(filterCriteria)}&type=exportToCsv` })
    url = `${apiCalls.followUpHistories}?filter=${JSON.stringify(filterCriteria)}`
    await fetchMethodRequest('GET', url).then(async (response) => {
      if (response && response["followUpHistories"] && response["followUpHistories"].length > 0) {
        let properties = response["followUpHistories"]
        properties = await this.updateDateFormat(
          properties,
          this.props.getTableFieldsForHistory(),
          "MM/DD/YYYY"
        );
        this.setState({ properties: properties })
        this.editdt.getValueforTable(properties, response.pagination.totalCount)
      } else if (response && response["followUpHistories"] && response["followUpHistories"].length === 0) {
        this.editdt.getValueforTable(
          response["followUpHistories"],
          response.pagination.totalCount
        );
      }
    })
    await this.setState({ isLoading: false })
    return this.state.claimStatusArray;
  }
  componentWillUnmount() {
    if (this.props.onRef) {
      this.props.onRef(null);
    }
  }
  /**
* 
* @returns getting the property details based on the property ids
*/
  getPropertyDetails = async (activeTab, filterCriteria, type) => {
    await this.setState({ isLoading: true })
    // if (type && type === "Clear Column" && filterCriteria) {
    //   filterCriteria.criteria = []
    // }
    // filterCriteria.sortfield = "propertyID"
    // filterCriteria.direction = "desc"
    let url
    if (this.props.type === "onlineStatusCount") {
      if (filterCriteria && filterCriteria.criteria && filterCriteria.criteria.length === 0) {
        filterCriteria.criteria.push({ key: "StateClaimID", type: "eq", value: this.props.rowData.stateClaimID }, { key: "SourceState", type: "eq", value: this.props.rowData.sourceState })
      }
      await this.setState({ propertyUrl: `${apiCalls.stateUPOnlineClaimStatuss}?filter=${JSON.stringify(filterCriteria)}&type=exportToCsv` })
      url = `${apiCalls.stateUPOnlineClaimStatuss}?filter=${JSON.stringify(filterCriteria)}`
    } else {
      // await this.setState({ propertyUrl: `${apiCalls.nsscoredproperties}?sellerID=${this.state.apiSelectedDealID}&propertyId=${this.state.apiPropertyIdArray}&type=exportToCsv&filter=${JSON.stringify(filterCriteria)}` })
      url = `${apiCalls.nsscoredproperties}?sellerID=${this.state.apiSelectedDealID}&propertyId=${this.state.apiPropertyIdArray}&filter=${JSON.stringify(filterCriteria)}`
    }
    await fetchMethodRequest('GET', url).then(async (response) => {
      if (response && response["stateUPOnlineClaimStatuss"] && response["stateUPOnlineClaimStatuss"].length > 0) {
        let properties = response["stateUPOnlineClaimStatuss"]
        properties = await this.updateDateFormat(
          properties,
          this.props.getTableFieldsForHistory(),
          "MM/DD/YYYY"
        );
        this.setState({ properties: properties })
        this.editdt.getValueforTable(properties, response.pagination.totalCount)
      } else if (response && response["stateUPOnlineClaimStatuss"] && response["stateUPOnlineClaimStatuss"].length === 0) {
        this.editdt.getValueforTable(
          response["stateUPOnlineClaimStatuss"],
          response.pagination.totalCount
        );
      }
    })
    await this.setState({ isLoading: false })
    return this.state.claimStatusArray;
  }

  clearFilters = async () => {
    await this.editdt.getTableFieldsforTableColumns([])
    await this.editdt.getTableFieldsforTableColumns(await this.props.getTableFieldsForHistory())
    await this.editdt.clearDropDownOptions()
    if (this.props.type === "followUpNumber") {
      followUpFilter.criteria = [{ key: "oppClaimID", type: "eq", value: this.props.rowData.oppClaimID }]
      await this.getFollowUpDetails("", followUpFilter, "Clear Column")
    } else {
      filter.criteria = [{ key: "StateClaimID", type: "eq", value: this.props.rowData.stateClaimID }, { key: "SourceState", type: "eq", value: this.props.rowData.sourceState }]
      await this.getPropertyDetails("", filter, "Clear Column")
    }
  }
  downLoadExcel = async (propertyUrl) => {
    await this.editdt.getExcelFileForDownload(
      [],
      [],
      "",
      [
        { label: "State UP Online Claim Status - Last Checked Date", field: "claimStatus_LastCheckedDate" },
        { label: "State UP Online - Claim Status Language", field: "claimStatus_Language" },
        { label: "Summarized State UP Online - Claim Status (By OPP)", field: "summarizedClaimStatus_ByOpp" },
        { label: "State UP Online Claim Status - Last Activity Date", field: "claimStatus_LastActivityDate" },
        { label: "UP State Online - Additional Claim Details", field: "upStateOnline_AdditionalClaimDetails" },
        { label: "UP State Online Claim Status - URL", field: "claimStatus_URL" },
      ],
      "stateUPDetailsTable",
      {},
      propertyUrl
    );
  };
  downLoadExcelForFollowUP = async (propertyUrl) => {
    if (this.props.screenType === "NextActionFollowUp") {
      await this.editdt.getExcelFileForDownload(
        [],
        [],
        "",
        [
          { label: "Follow- up #", field: "followUpNumber" },
          { label: "Claim Status", field: "claimStatus" },
          { label: "Claim Reason", field: "claimReason" },
          { label: "Last Status Updated By", field: "updatedBy" },
          { label: "Next Action Date", field: "followUpDate" },
          { label: "Next Action User", field: "followUpAnalyst" },
          { label: "Follow-up Completion Date", field: "followUpCompletedDate" },
          { label: "No. of Days Follow-up Past Due", field: "daysPastDue" },
          { label: "Follow-up Notes", field: "followUpNotes" },
        ],
        "NextActionFollowUpsTable",
        {},
        propertyUrl
      );
    } else {
      await this.editdt.getExcelFileForDownload(
        [],
        [],
        "",
        [
          { label: "Follow- up #", field: "followUpNumber" },
          { label: "Scheduled No. of Days Between Follow-ups", field: "daysForNextFollowUp" },
          { label: "Follow-up Scheduled Date", field: "followUpDate" },
          { label: "Follow-up Completion Date", field: "followUpCompletedDate" },
          { label: "No. of Days Follow-up Past Due", field: "daysPastDue" },
          { label: "Follow-up Status", field: "followUpStatus" },
          { label: "Follow-up done by", field: "updatedBy" },
          { label: "Next Action User", field: "followUpAnalyst" },
          { label: "Claim Follow-up Notes", field: "followUpNotes" },
        ],
        "followUpDetailsTable",
        {},
        propertyUrl
      );
    }
  };

  /**
   * 
   * @param {*} type 
   * closing the model for debtors
   */
  cancelReset = async (type, item) => {
    await this.setState({
      isopenColumnModal: true,
      selectedDeleteItem: item
    })
  }
  /**
  * 
  * @param {*} type 
  * Clsoing the show hide model after confirmation
  */
  closeShowHideColumnsModal = async (type) => {
    await this.setState({
      isopenColumnModal: false
    })
    if (type === "close") {
      await this.deletePropertyFromTask()
      // this.props.closeShowHideColumnsModal('close', null)
    }

  }
  /**
      * Shows action buttons in table.
      * @param {Object} col 
      * @returns {HTMLElement} template with buttons.
      */
  getActions = (col) => {
    if (col && col.mostRecentFlag) {
      return <div className='dm-action-buttons'>
        <FontAwesomeIcon
          className={'tableheadericon'}
          color="#354f6e"
          style={{ marginTop: "13px", marginRight: "6px", }}
          // onClick={(e) => this.closeModal()}
          icon={faPencilAlt}
        />
      </div>
    } else { return true }
  }


  //   let data
  //   if (this.props.type === "followUpNumber" && this.props.viewType === "View") {
  //     data = [
  //       // {
  //       //   "show": true,
  //       //   "textAlign": "left",
  //       //   "width": 50,
  //       //   "fieldType": 'Actions',
  //       //   "field": "Actions",
  //       //   "header": "",
  //       //   filter: false,
  //       //   "displayInSettings": false,
  //       //   "sortable": false
  //       // },
  //       {
  //         "show": true,
  //         "textAlign": "center",
  //         "width": 50,
  //         "field": "expander",
  //         "header": "",
  //         "filter": false,
  //         "sortable": false,
  //         "placeholder": "Search"
  //       },
  //       {
  //         show: true,
  //         textAlign: "center",
  //         width: 70,
  //         header: "Follow-up #",
  //         mobile: true,
  //         field: "followUpNumber",
  //         fieldColor: true,
  //         filterType: "num",
  //         // "fieldType": "claimHistory",
  //         ////editField: "text",
  //         displayInSettings: true,
  //         filter: this.props.viewType === "Edit" ? false : true,
  //         sortable: this.props.viewType === "Edit" ? false : true,
  //       },
  //       {
  //         show: true,
  //         textAlign: "center",
  //         width: 100,
  //         header: "Scheduled No. of Days Between Follow-ups",
  //         mobile: true,
  //         filterType: "num",
  //         field: "daysForNextFollowUp",
  //         //editField: "text",
  //         displayInSettings: true,
  //         filter: this.props.viewType === "Edit" ? false : true,
  //         sortable: this.props.viewType === "Edit" ? false : true,
  //       },
  //       {
  //         show: true,
  //         textAlign: "center",
  //         width: 150,
  //         header: "Follow-up Scheduled Date",
  //         mobile: true,
  //         fieldType: "dateTime",
  //         fieldDate: "Date",
  //         dateFormat: "MM-DD-yyyy",
  //         field: "followUpDate",
  //         //editField: "text",
  //         displayInSettings: true,
  //         filter: this.props.viewType === "Edit" ? false : true,
  //         sortable: this.props.viewType === "Edit" ? false : true,
  //       },
  //       {
  //         show: true,
  //         textAlign: "center",
  //         width: 90,
  //         header: "Follow-up Completion Date",
  //         mobile: true,
  //         fieldType: "dateTime",
  //         fieldColor: true,
  //         fieldDate: "Date",
  //         dateFormat: "MM-DD-yyyy",
  //         field: "followUpCompletedDate",
  //         //editField: "text",
  //         displayInSettings: true,
  //         filter: this.props.viewType === "Edit" ? false : true,
  //         sortable: this.props.viewType === "Edit" ? false : true,
  //       },
  //       {
  //         show: true,
  //         textAlign: "center",
  //         width: 90,
  //         header: "No. of Days Follow-up Past Due",
  //         mobile: true,
  //         fieldColor: true,
  //         filterType: "num",
  //         field: "daysPastDue",
  //         //editField: "text",
  //         displayInSettings: true,
  //         filter: this.props.viewType === "Edit" ? false : true,
  //         sortable: this.props.viewType === "Edit" ? false : true,
  //       },
  //       {
  //         show: true,
  //         textAlign: "center",
  //         width: 90,
  //         header: "Follow-up Status",
  //         mobile: true,
  //         field: "followUpStatus",
  //         editField: "dropdown",
  //         options: [{ label: "Not Started", value: "Not Started" }, { label: "Completed", value: "Completed" }],
  //         displayInSettings: true,
  //         filter: this.props.viewType === "Edit" ? false : true,
  //         sortable: this.props.viewType === "Edit" ? false : true,
  //       },
  //       {
  //         show: true,
  //         textAlign: "center",
  //         width: 140,
  //         header: "Follow-up done by",
  //         mobile: true,
  //         field: "updatedBy",
  //         displayInSettings: true,
  //         filter: this.props.viewType === "Edit" ? false : true,
  //         sortable: this.props.viewType === "Edit" ? false : true,
  //       },
  //       {
  //         show: true,
  //         textAlign: "center",
  //         width: 140,
  //         header: "Next Action User",
  //         mobile: true,
  //         field: "followUpAnalyst",
  //         editField: "dropdown",
  //         options: this.state.userArray,
  //         displayInSettings: true,
  //         filter: this.props.viewType === "Edit" ? false : true,
  //         sortable: this.props.viewType === "Edit" ? false : true,
  //       },
  //       {
  //         show: true,
  //         textAlign: "left",
  //         width: 500,
  //         header: "Claim Follow-up Notes",
  //         mobile: true,
  //         field: "followUpNotes",
  //         editField: "text",
  //         displayInSettings: true,
  //         filter: this.props.viewType === "Edit" ? false : true,
  //         sortable: this.props.viewType === "Edit" ? false : true,
  //       },]
  //   } else if (this.props.type === "followUpNumber" && this.props.viewType === "Edit") {
  //     data = [
  //       {
  //         show: true,
  //         textAlign: "center",
  //         width: 70,
  //         header: "Follow-up #",
  //         mobile: true,
  //         field: "followUpNumber",
  //         fieldColor: true,
  //         filterType: "num",
  //         // "fieldType": "claimHistory",
  //         ////editField: "text",
  //         displayInSettings: true,
  //         filter: this.props.viewType === "Edit" ? false : true,
  //         sortable: this.props.viewType === "Edit" ? false : true,
  //       },
  //       {
  //         show: true,
  //         textAlign: "center",
  //         width: 150,
  //         header: "Scheduled No. of Days Between Follow-ups",
  //         mobile: true,
  //         filterType: "num",
  //         field: "daysForNextFollowUp",
  //         //editField: "text",
  //         displayInSettings: true,
  //         filter: this.props.viewType === "Edit" ? false : true,
  //         sortable: this.props.viewType === "Edit" ? false : true,
  //       },
  //       {
  //         show: true,
  //         textAlign: "center",
  //         width: 150,
  //         header: "Follow-up Scheduled Date",
  //         mobile: true,
  //         fieldType: "dateTime",
  //         fieldDate: "Date",
  //         dateFormat: "MM-DD-yyyy",
  //         field: "followUpDate",
  //         //editField: "text",
  //         displayInSettings: true,
  //         filter: this.props.viewType === "Edit" ? false : true,
  //         sortable: this.props.viewType === "Edit" ? false : true,
  //       },
  //       {
  //         show: true,
  //         textAlign: "center",
  //         width: 100,
  //         header: "Follow-up Completion Date",
  //         mobile: true,
  //         fieldType: "dateTime",
  //         fieldColor: true,
  //         fieldDate: "Date",
  //         dateFormat: "MM-DD-yyyy",
  //         field: "followUpCompletedDate",
  //         //editField: "text",
  //         displayInSettings: true,
  //         filter: this.props.viewType === "Edit" ? false : true,
  //         sortable: this.props.viewType === "Edit" ? false : true,
  //       },
  //       {
  //         show: true,
  //         textAlign: "center",
  //         width: 150,
  //         header: "No. of Days Follow-up Past Due",
  //         mobile: true,
  //         fieldColor: true,
  //         filterType: "num",
  //         field: "daysPastDue",
  //         //editField: "text",
  //         displayInSettings: true,
  //         filter: this.props.viewType === "Edit" ? false : true,
  //         sortable: this.props.viewType === "Edit" ? false : true,
  //       },
  //       {
  //         show: true,
  //         textAlign: "center",
  //         width: 90,
  //         header: "Follow-up Status",
  //         mobile: true,
  //         field: "followUpStatus",
  //         editField: "dropdown",
  //         options: [{ label: "Not Started", value: "Not Started" }, { label: "Completed", value: "Completed" }],
  //         displayInSettings: true,
  //         filter: this.props.viewType === "Edit" ? false : true,
  //         sortable: this.props.viewType === "Edit" ? false : true,
  //       },
  //       {
  //         show: true,
  //         textAlign: "center",
  //         width: 90,
  //         header: "Follow-up done by",
  //         mobile: true,
  //         field: "updatedBy",
  //         displayInSettings: true,
  //         filter: this.props.viewType === "Edit" ? false : true,
  //         sortable: this.props.viewType === "Edit" ? false : true,
  //       },
  //       {
  //         show: true,
  //         textAlign: "center",
  //         width: 90,
  //         header: "Next Action User",
  //         mobile: true,
  //         field: "followUpAnalyst",
  //         editField: "dropdown",
  //         options: this.state.userArray,
  //         displayInSettings: true,
  //         filter: this.props.viewType === "Edit" ? false : true,
  //         sortable: this.props.viewType === "Edit" ? false : true,
  //       },
  //       {
  //         show: true,
  //         textAlign: "left",
  //         width: 500,
  //         header: "Claim Follow-up Notes",
  //         mobile: true,
  //         field: "followUpNotes",
  //         editField: "text",
  //         displayInSettings: true,
  //         filter: this.props.viewType === "Edit" ? false : true,
  //         sortable: this.props.viewType === "Edit" ? false : true,
  //       },]
  //   } else {
  //     data = [{
  //       show: true,
  //       textAlign: "center",
  //       width: 180,
  //       allowInt: true,
  //       filterType: "date",
  //       header: "State UP Online Claim Status - Last Checked Date",
  //       mobile: true,
  //       // "//editField": "date",
  //       fieldType: "dateTime",
  //       fieldDate: "Date",
  //       dateFormat: "MM-DD-yyyy",
  //       field: "claimStatus_LastCheckedDate",
  //       displayInSettings: true,
  //       filter: true,
  //       sortable: true,
  //     },
  //     {
  //       show: true,
  //       textAlign: "center",
  //       width: 210,
  //       header: "State UP Online - Claim Status Language",
  //       mobile: true,
  //       field: "claimStatus_Language",
  //       //editField: "text",
  //       displayInSettings: true,
  //       filter: true,
  //       sortable: true,
  //     },
  //     {
  //       show: true,
  //       textAlign: "center",
  //       width: 260,
  //       header: "Summarized State UP Online - Claim Status (By OPP)",
  //       mobile: true,
  //       field: "summarizedClaimStatus_ByOpp",
  //       //editField: "text",
  //       displayInSettings: true,
  //       filter: true,
  //       sortable: true,
  //     },
  //     {
  //       show: true,
  //       textAlign: "right",
  //       width: 260,
  //       allowInt: true,
  //       filterType: "date",
  //       header: "State UP Online Claim Status - Last Activity Date",
  //       mobile: true,
  //       fieldDate: "Date",
  //       filterType: "num",
  //       // "//editField": "date",
  //       fieldType: "dateTime",
  //       field: "claimStatus_LastActivityDate",
  //       dateFormat: "MM-DD-yyyy",
  //       displayInSettings: true,
  //       filter: true,
  //       sortable: true,
  //     },
  //     {
  //       show: true,
  //       textAlign: "center",
  //       width: 260,
  //       header: "UP State Online - Additional Claim Details",
  //       mobile: true,
  //       field: "upStateOnline_AdditionalClaimDetails",
  //       //editField: "text",
  //       displayInSettings: true,
  //       filter: true,
  //       sortable: true,
  //     },
  //     {
  //       show: true,
  //       textAlign: "center",
  //       width: 260,
  //       header: "UP State Online Claim Status - URL",
  //       mobile: true,
  //       field: "claimStatus_URL",
  //       //editField: "text",
  //       displayInSettings: true,
  //       filter: true,
  //       sortable: true,
  //     },
  //       // {
  //       //   show: true,
  //       //   textAlign: "left",
  //       //   width: 260,
  //       //   header: "State UP Site - Claim History",
  //       //   mobile: true,
  //       //   field: "claimHistory",
  //       //   "fieldType": "claimHistory",
  //       //   //editField: "text",
  //       //   displayInSettings: true,
  //       //   filter: true,
  //       //   sortable: true,
  //       // },
  //     ]
  //   }
  //   return data;
  // };
  setRowCount = async (count) => {
    await this.setState({ selectedRows: count })
  }
  //Datatable properties sending to the Edit Datatable component(child component)
  getDataTable = () => {
    return (
      <div
        className={
          this.state.updatedRecords && this.state.updatedRecords.length > 0
            ? "disabledSelection disabledRow"
            : ""
        }
      >
        <EditDataTable
          onRef={(ref) => (this.editdt = ref)}
          selectedDeal={this.state.selectedDeal}
          selectedDealID={this.state.selectedDealID}
          // exportToCSVCriteria={this.state.filterCriteria.criteria}
          filteredURL={this.state.filteredURL}
          metaKeySelection={false}
          Object1={this.props.Object1}
          hidePreset={true}
          claimStatusArray={
            this.state.claimStatusArray ? this.state.claimStatusArray : []
          }
          claimReasonArray={
            this.state.claimReasonArray ? this.state.claimReasonArray : []
          }
          nonEditable={true}
          viewType={this.props.viewType}
          setDataAfterSave={this.setDataAfterSave}
          setRowCount={this.setRowCount}
          filter={this.props.filter}
          propertyStatusArray={this.state.propertyStatusArray}
          propertyStatusReasonArray={this.state.propertyStatusReasonArray}
          isEditableGrid={false}
          screenPermissionsRoute={this.props.screenType === "NextActionFollowUp" ? "Next Action User Report" : "Outstanding Claims Report"}
          getDataFromServer={this.getDataFromServer}
          checkFilterType={"Next Action User Report"}
          defSortField={this.state.defSortField}
          getTableFields={this.props.getTableFieldsForHistory}
          getActions={this.getActions}
          closeModal={this.closeModal}
          nextActionUserArray={this.state.userArray}
          Rowdetails={this.props.Rowdetails}
          setPropertyIdAfterDelete={this.setPropertyIdAfterDelete}
          getClaimRecord={this.getClaimRecord}
          getToggle={this.getToggle}
          setEditedRows={this.setEditedRows}
          showMessage={this.showMessage}
          showSuccessMessage={this.showSuccessMessage}
          setEditedRowsEmpty={this.setEditedRowsEmpty}
          strArray={this.state.strArray}
          showPreset={"true"}
          scrollHeight={
            (this.state.allSelectedRows &&
              this.state.allSelectedRows.length > 0) ||
              (this.state.activeTab &&
                this.state.activeTab !== "close" &&
                this.state.activeTab !== "closeByIcon")
              ? "400px"
              : "calc(100vh - 380px)"
          }
          disableInlineEdit={this.disableInlineEdit}
          disabled={false}
          tablefieldsToShow={this.props.getTableFieldsForHistory}
          getTableFieldsforData={this.props.getTableFieldsForHistory}
          deleArray={this.state.deleArray}
          openClaimDetails={this.openClaimDetails}
          shownFieldTab={this.state.shownFieldTab}
          removeUtc={true}
          anyOneField={
            this.state.CrudInputs &&
              this.state.CrudInputs.validationType === "or"
              ? this.state.CrudInputs.anyOneField
              : []
          }
          multiSaveUrl={
            this.state.CrudInputs ? this.state.CrudInputs.multiSaveUrl : ""
          } //multi save
          multiDeleteUrl={
            this.state.CrudInputs ? this.state.CrudInputs.multiDeleteUrl : ""
          } //multi delete
          newRecordEmptyObj={
            this.state.CrudInputs ? this.state.CrudInputs.newRecordObj : {}
          } //new record obj
          requiredFields={
            this.state.CrudInputs ? this.state.CrudInputs.requiredFields : []
          } //required field in array of strings
          uniqueDBField={
            this.state.CrudInputs ? this.state.CrudInputs.uniqueDBField : ""
          } //unique id for the rec
          deleteRestrictionField={
            this.state.CrudInputs
              ? this.state.CrudInputs.deleteRestrictionField
              : ""
          }
          deleteRestrictionFieldValues={
            this.state.CrudInputs
              ? this.state.CrudInputs.deleteRestrictionFieldValues
              : []
          }
          dontShowBelowPage={this.props.viewType === "Edit" ? true : false}
          // showAbovePage={true}
          type={this.props.type}
          activeTab={this.props.type} // onSort={this.sortChange}
          filterCriteria={this.props.type === "followUpNumber" ? followUpFilter : filter}
          toggle={this.props.type === "followUpNumber" ? this.getFollowUpDetails : this.getPropertyDetails}
          table_name={this.props.type}
          noShowHideColOpt={true}
          updateLimit={(limit) => { this.setState({ limit: limit }) }}
        />
      </div>
    );
  };

  /**
       * 
       * @returns Opening the confirmation model
       */
  getConfirmaionModel() {
    return (
      <ConfirmationModal
        openConfirmationModal={this.state.isopenColumnModal}
        closeConfirmationModal={this.closeShowHideColumnsModal}
        shownText={`Are you sure you want to remove OPRA PropertyId ${this.props.rowData.propertyID} from TaskID ${this.state.selectedDeleteItem.taskID}?`}
      />
    )
  }

  handlePercentage = async (Val) => {
    let counter = 0;
    const interval = setInterval(async () => {
      if (this.state.isLoading) {
        counter = counter + 15;
        await this.setState({
          progress: counter,
        })
      } else {
        if (!this.state.isLoading) {
          clearInterval(interval);
        }
      }
    }, 100);
  }
  closeModal = async () => {
    if (this.editdt && this.editdt.clearCriteria) {
      await this.editdt.clearCriteria()
    }
    await this.props.cancelReset()
    return true
  }
  UpdateFollowUpDetails = async () => {
    this.editdt.UpdateFollowUpDetails()
  }
  showMessage = async (response) => {
    await this.setState({ respMessage: response, successMessage: "", successResponse: false })

  }
  showSuccessMessage = async (response) => {
    await this.setState({ successMessage: response.respMessage, successResponse: true, respMessage: {} })
  }

  render() {
    const { t } = this.props
    // return (

    //   < div >

    //     <Modal isOpen={this.props.openTableModal} fade={false}
    //       className='modal-dialog-centered modal-dialog--primary m-auto'
    //     >
    //       <ModalBody className='deleteModalBody' style={{
    //         padding: 10,
    //         // width: 110,
    //         height: 250,
    //         overflow: "auto",
    //         textAlign: "left"
    //       }}>
    //         <span style={{ fontWeight: "bold" }}>Follow Up History</span>
    //         {this.getDataTable()}


    //       </ModalBody>

    //     </Modal >
    //   </div >
    // );
    return (

      <div className="followUp-modal">
        <div className="followUp-content">
          <Loader loader={this.state.isLoading} />
          <div className='row'>
            <span className='followupheader' style={{ background: this.props.bgColor }}>{this.props.header}</span>
            {/* <span className="ml-auto" style={{ color: "red" }} >{this.state.respMessage ? this.state.respMessage.respMessage : null}</span>
            <span className="topbar__centerresponse topBarImageAlignment ml-auto" >{this.state.successResponse ? this.state.successMessage : null}</span> */}
            <p className="topbar__centerresponse topBarImageAlignment successbgColor successTextColor px-3" >{this.state.successResponse ? this.state.successMessage : null}</p>
            <p className="topbar__centerresponse topBarImageAlignment errorbgColor errorTextColor px-3" >{this.state.respMessage ? this.state.respMessage.respMessage : null}</p>

            <div style={{ marginLeft: "auto", }}>
              <FontAwesomeIcon
                className={'tableheadericon'}
                color="#354f6e"
                style={{ marginTop: "13px", marginRight: "6px", cursor: "pointer" }}
                onClick={(e) => this.closeModal()}
                icon={faTimesCircle}
              />
            </div>
          </div>
          {this.state.properties && this.state.properties.length > 0 ?
            <>

              {this.props.type === "followUpNumber" && this.props.viewType === "Edit" ? null :
                <span>
                  {/* <Button style={{ float: "right" }} className="bg-warning text-white  p-button-rounded claim_detail_sub_navbar_buttons mr-2 mt-0" type="submit">{"Update"}</Button> */}
                  <Button color="primary"
                    outline
                    id={"submit"}
                    className="ml-2 mr-2 mt-0"
                    style={{ float: "right" }}
                    onClick={(e) => this.clearFilters()}>
                    Clear Column Filters
                  </Button>
                </span>}
              {this.props.type === "followUpNumber" && this.props.viewType === "Edit" ? null : this.props.type === "followUpNumber" ? <span>
                <FontAwesomeIcon icon='download'
                  className={'tableheadericon mt-1'}
                  color='#354f6e'
                  data-toggle="tool-tip"
                  onClick={(e) => this.downLoadExcelForFollowUP(this.state.propertyUrl)}
                  title="Download Excel"
                  id="Download Excel"
                  style={{ marginRight: "10px", float: "right" }} />
              </span> : <span>
                <FontAwesomeIcon icon='download'
                  className={'tableheadericon mt-1'}
                  color='#354f6e'
                  data-toggle="tool-tip"
                  onClick={(e) => this.downLoadExcel(this.state.propertyUrl)}
                  title="Download Excel"
                  id="Download Excel"
                  style={{ marginRight: "10px", float: "right" }} />
              </span>}

            </>
            : null}
          {this.getDataTable()}
        </div>
      </div>
    );
  }
}
export default withTranslation('common')(FollowUpHistoryModal);
