import React from 'react';
import DataTables from '../../CommonDataTable/DataTable';
import apiCalls from '../../../../config/apiCalls'
import store from '../../../App/store';

// config file
export default class Workflow extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      type: 'Properties',

    };
  }

  static getDerivedStateFromProps(props, state) {
    let storeData = store.getState()
    let languageData = storeData && storeData.settingsData && storeData.settingsData.settings && storeData.settingsData.settings.languageTranslation ? storeData.settingsData.settings.languageTranslation : ""
    return { languageData: languageData };
  }



  componentDidMount = () => {
    //Screen permisions value can be edit,view, no view
  }


  getTableFields = () => {
    let data = [

      {
        "show": true,
        textAlign: 'left',
        width: 80,
        field: 'name',
        // fieldType: 'BoolText',
        "mobile": true,
        header: 'Name',
        filter: true,
        sortable: true,
      },

      {
        "show": true,
        textAlign: 'left',
        width: 40,
        field: 'description',
        // fieldType: 'BoolText',
        "mobile": true,

        header: 'Description',
        filter: false,
        sortable: true,
      },
      {
        "show": true,
        textAlign: 'left',
        width: 40,
        "allowInt": true,
        filterType: "num",
        field: 'environment',
        // fieldType: 'BoolText',
        "mobile": true,
        header: 'Environment',
        filter: false,
        sortable: true,
      },
      {
        "show": true,
        textAlign: 'left',
        width: 80,
        field: 'role',
        // fieldType: 'BoolText',
        "mobile": true,
        header: 'Role',
        filter: false,
        sortable: true,
      },
      {
        "show": true,
        textAlign: 'left',
        width: 40,
        field: 'active',
        fieldType: "ActiveIndicator",
        "mobile": true,
        header: 'Active',
        filter: false,
        sortable: true,
      }, {
        "show": true,
        textAlign: 'left',
        width: 40,
        field: 'Actions',
        "fieldType": 'Actions',
        // fieldType: 'BoolText',
        "mobile": true,
        header: 'Actions',
        filter: false,
        sortable: true,
      },

    ]
    return data;
  };

  getFormFields = () => {

    return
  }



  submit = async (item) => {

  }

  render() {
    return (
      <span>
        <DataTables
          onRef={(ref) => (this.datatableref = ref)}
          getTableFields={this.getTableFields}
          formFields={this.getFormFields}
          exportRequried={false}
          printRequried={false}
          addRequried={true}
          editRequired={true}
          // scrollHeight={"580px"}
          dontShowTitle={false}
          sortField={"Name"}
          deleteRequired={false}
          viewRequired={true}
          isClearFilters={true}
          settingsRequired={false}
          filterRequired={true}
          gridRequried={false}
          sample={false}
          propertyFilterSortOptions={this.state.propertyFilterSortOptions}
          globalSearchFieldName='user'
          globalSearch={'Search'}
          type={"Rules Configuration Workflow"}
          displayright={true}
          icon='lnr magnifier"'
          routeTo='properties'
          displayViewOfForm='modal'
          apiResponseKey='workflowRulesDetails'
          className={true}
          apiUrl={"workflowRuleDetails"}
          entityType='employee'
        />

      </span>
    );
  }
}