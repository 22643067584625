import React from 'react';
import DataTables from '../../../containers/Cruds/CommonDataTable/DataTable';
import apiCalls from '../../../config/apiCalls'
import store from '../../App/store';
import Loader from '../../App/Loader';
import { SelectButton } from 'primereact/selectbutton';
import { Button } from 'reactstrap';
import {
    TabContent, TabPane, Nav, NavItem, NavLink
} from 'reactstrap';
import fetch from '../../../config/service';
import dateFormats from '../../UI/FormatDate/formatDate';
import classnames from 'classnames';
import { Accordion, AccordionTab } from 'primereact/accordion';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import * as documentsAPIs from '../../../shared/documentsApis';

const bodyStyle = {
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    width: 150,
    fontSize: 12,
    textAlign: "left",
    verticalAlign: "inherit"
}
const headerStyle = {
    width: 150,
    textAlign: "center",
}
const bodyStyleRight = {
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    width: 150,
    fontSize: 12,
    textAlign: "center",
    verticalAlign: "inherit"
}
// import fetchMethodRequest from '../../../../config/service';

// import RolePermissions from '../../CommonModals/Permissions';

// config file
export default class ClaimDocumentation extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            type: 'ClaimStatus',
            claimPacket: "Claim Packet",
            searchTermsRecords: [{}, {}]
        };
    }

    static getDerivedStateFromProps(props, state) {
        let storeData = store.getState()
        let languageData = storeData && storeData.settingsData && storeData.settingsData.settings && storeData.settingsData.settings.languageTranslation ? storeData.settingsData.settings.languageTranslation : ""
        return { languageData: languageData };
    }



    componentDidMount = async () => {
        await this.getClaimPacketDetails("ClaimPacket")
        //Screen permisions value can be edit,view, no view
        // let screenPermissions = RolePermissions.screenPermissions('Employees');
        // fetchMethodRequest("GET",'businessnames/execute?query=Select%20*%20%20from%20docs.DocumentRelevance & filter={%22limit%22:0,%22page%22:1,%22criteria%22:[],%22sortfield%22:%22sequence%22,%22direction%22:%22desc%22}')

    }
    /**
* 
* @returns the claim status reason array for claims filter dropdown
*/
    getClaimPacketDetails = async (type) => {
        await this.setState({ isLoading: true })
        let filterCriteria = { "limit": 50, "page": "1", "criteria": [], "sortfield": "FileName", "direction": "desc" }
        if (type) {
            filterCriteria.criteria.push({
                key: "BusinessNameID",
                value: this.props.Rowdetails.relevantSellerNameID,
                type: "eq",
                add_Filter: true
            },
                {
                    key: "State",
                    value: this.props.Rowdetails.sourceState,
                    type: "eq",
                    add_Filter: true
                },
                //     {
                //         key: "StateClaimID",
                //         value: this.props.Rowdetails.stateClaimID,
                //         type: "eq",
                //         add_Filter: true
                //     },
                // {
                //     key: "RelevanceTypeId",
                //     value: this.props.Rowdetails.relevantSellerNameID,
                //     type: "eq",
                //     add_Filter: true
                // },

            )
        }

        let api
        if (type) {
            api = `${apiCalls.GetDocumentsData}?DealId=${this.props.Rowdetails.companyID}&Screen=CP&SPData=${false}&filter=${JSON.stringify(filterCriteria)}`
        } else {
            api = `${apiCalls.GetDocumentsData}?DealId=${this.props.Rowdetails.companyID}&MappedBusinessNameID=${this.props.Rowdetails.mappedNameID}&BusinessNameID=${this.props.Rowdetails.relevantSellerNameID}&Screen=DR&SPData=${false}&filter=${JSON.stringify(filterCriteria)}`
        }
        await fetch('GET', api).then(async (response) => {
            if (response && response.outResponse && response.outResponse.details && response.outResponse.details.length > 0) {
                if (type) {
                    await this.setState({ claimPacketRecords: response.outResponse.details, claimRelevanceRecords: [] })

                } else {
                    await this.setState({ claimPacketRecords: [], claimRelevanceRecords: response.outResponse.details })

                }

            }
        })
        await this.setState({ isLoading: false })

        return this.state.claimStatusArray;
    }

    getClaimPacketTableFields = () => {
        let data = [
            // {
            //   "show": true,
            //   textAlign: 'left',
            //   width: 6,
            //   field: 'reOrder',
            //   // "displayInSettings": true,
            //   // "displayInFilter": true,
            //   // sortable: true,
            // },

            {
                "show": true,
                textAlign: 'left',
                width: 90,
                field: 'status',
                // fieldType: 'BoolText',
                "mobile": true,
                header: 'Document Name',
                filter: true,
                "displayInSettings": true,
                "displayInFilter": true,
                sortable: true,
            },
            {
                "show": true,
                textAlign: 'left',
                width: 30,
                field: 'statu',
                // fieldType: 'BoolText',
                "mobile": true,
                header: 'CreatedDate',
                filter: true,
                "displayInSettings": true,
                "displayInFilter": true,
                sortable: true,
                "dateFormat": "MM/DD/YY",
                "fieldType": 'Date',
            },
            {
                "show": true,
                textAlign: 'left',
                width: 30,
                field: 'updatedBy',
                // fieldType: 'BoolText',
                "mobile": true,
                header: 'UpdatedDate',
                filter: true,
                "displayInSettings": true,
                "displayInFilter": true,
                sortable: true,
                "dateFormat": "MM/DD/YY",
                "fieldType": 'Date',
            },

            {
                "show": true,
                "textAlign": "left",
                "width": 30,
                "fieldType": 'Actions',
                "field": "Actions",
                "header": "Action",
                "filter": false,
                "displayInSettings": true,
                "sortable": false
            }
        ]
        return data;
    };

    getRelevanceTableFields = () => {
        let data = [
            // {
            //   "show": true,
            //   textAlign: 'left',
            //   width: 6,
            //   field: 'reOrder',
            //   // "displayInSettings": true,
            //   // "displayInFilter": true,
            //   // sortable: true,
            // },

            {
                "show": true,
                textAlign: 'left',
                width: 60,
                field: 'status',
                // fieldType: 'BoolText',
                "mobile": true,
                header: 'Document Name',
                filter: true,
                "displayInSettings": true,
                "displayInFilter": true,
                sortable: true,
            },
            {
                "show": true,
                textAlign: 'left',
                width: 20,
                field: 'RelevanceTypeId',
                // fieldType: 'BoolText',
                "mobile": true,
                header: 'Relevance Type',
                filter: true,
                "displayInSettings": true,
                "displayInFilter": true,
                sortable: true,
            },
            {
                "show": true,
                textAlign: 'left',
                width: 30,
                field: 'PageNumberReference',
                // fieldType: 'BoolText',
                "mobile": true,
                header: 'Page Number Reference',
                filter: true,
                "displayInSettings": true,
                "displayInFilter": true,
                sortable: true,
            },
            {
                "show": true,
                textAlign: 'left',
                width: 30,
                field: 'DocumentRelevanceNotes',
                // fieldType: 'BoolText',
                "mobile": true,
                header: 'Document Relevance Notes',
                filter: true,
                "displayInSettings": true,
                "displayInFilter": true,
                sortable: true,
            },
            {
                "show": true,
                textAlign: 'left',
                width: 20,
                field: 'CreateDateUTC',
                // fieldType: 'BoolText',
                "mobile": true,
                header: 'CreatedDate',
                filter: true,
                "displayInSettings": true,
                "displayInFilter": true,
                sortable: true,
                "dateFormat": "MM/DD/YY",
                "fieldType": 'Date',
            },
            {
                "show": true,
                textAlign: 'left',
                width: 20,
                field: 'UpdatedDateUTC',
                // fieldType: 'BoolText',
                "mobile": true,
                header: 'UpdatedDate',
                filter: true,
                "displayInSettings": true,
                "displayInFilter": true,
                sortable: true,
                "dateFormat": "MM/DD/YY",
                "fieldType": 'Date',
            },
            {
                "show": true,
                "textAlign": "left",
                "width": 30,
                "fieldType": 'Actions',
                "field": "Actions",
                "header": "Actions",
                "filter": false,
                "displayInSettings": true,
                "sortable": false
            }
        ]
        return data;
    };

    getClaimsPacketFormFields = () => {
        return ([
            {
                //'show': false,
                "value": "",
                "type": "text",
                "name": "status",
                "label": "Document Name",
                "id": "status",
                "placeholder": "Document Name",
                "required": true
            },
            {
                //'show': false,
                "value": "",
                "type": "switch",
                "name": "activeIndicator",
                "label": "Active /Inactive Status",
                "id": "status",
                "placeholder": "status",
                // "required": true
            },
            // {
            //   //'show': false,
            //   'isMultiSelect': true,
            //   "value": "",
            //   "type": "dropDown",
            //   "name": "screenType",
            //   "label": "Screen",
            //   "id": "name",
            //   "options": [{ label: "Property Editor", value: "Property Editor" }, { label: "Claims", value: "Claims" }],
            //   "placeholder": "Screen",
            //   "required": true
            // },
        ]);
    }

    getRelevanceFormFields = () => {
        return ([
            {
                //'show': false,
                "value": "",
                "type": "text",
                "name": "status",
                "label": "Document Name",
                "id": "status",
                "placeholder": "Document Name",
                "required": true
            },
            {
                //'show': false,
                "value": "",
                "type": "number",
                "name": "RelevanceTypeId",
                "label": "Relevance Type",
                "id": "RelevanceTypeId",
                "placeholder": "Relevance Type",
                "required": true
            },
            {
                //'show': false,
                "value": "",
                "type": "text",
                "name": "PageNumberReference",
                "label": "Page Number Reference",
                "id": "status",
                "placeholder": "Page Number Reference",
                "required": true
            }, {
                //'show': false,
                "value": "",
                "type": "text",
                "name": "DocumentRelevanceNotes",
                "label": "Document Relevance Notes",
                "id": "DocumentRelevanceNotes",
                "placeholder": "Document Relevance Notes",
                "required": true
            },
            // {
            //     //'show': false,
            //     "value": "",
            //     "type": "switch",
            //     "name": "activeIndicator",
            //     "label": "Active /Inactive Status",
            //     "id": "status",
            //     "placeholder": "status",
            //     // "required": true
            // },
            // {
            //   //'show': false,
            //   'isMultiSelect': true,
            //   "value": "",
            //   "type": "dropDown",
            //   "name": "screenType",
            //   "label": "Screen",
            //   "id": "name",
            //   "options": [{ label: "Property Editor", value: "Property Editor" }, { label: "Claims", value: "Claims" }],
            //   "placeholder": "Screen",
            //   "required": true
            // },
        ]);
    }
    changeClaimPacket = async (val) => {
        await this.setState({ claimPacket: val })
        if (val === "Claim Packet") {
            await this.getClaimPacketDetails("Claim Packet")
        } else {
            await this.getClaimPacketDetails()
        }
    }
    actionTemplate =  (rowData, column) => {        
        return <div className='my-1'>
            <a className='btn btn-outline-primary bg-info text-white p-button-rounded claim_detail_sub_navbar_buttons'  onClick={()=>{this.onClickEditDoc(rowData,'CLM PACKET')}}>Edit</a>

        </div>;
    }
    actionTemplateForPDF = (rowData, column) => {       
        return <div className='my-1'>
            <a className='btn btn-outline-primary bg-info text-white p-button-rounded claim_detail_sub_navbar_buttons'  onClick={()=>{this.onClickEditDoc(rowData,'PDF Editor')}}>Edit</a>

        </div>;
    }

    /**
     * On click edit - Redirecting to pdf editor/claim builder after getting share point details
     * @param {Object} rowData 
     * @param {String} type 
     */
    onClickEditDoc = async (rowData, type) => {
        const sharePointDeatils = await documentsAPIs.getSharepointDetails(rowData.dealId, rowData.id);
        rowData.sharePointDetails = sharePointDeatils;
        rowData['SellerNickName'] = this.props.sellerNickName;
        rowData['DealType'] = this.props.dealType;
        if (type === 'CLM PACKET') {            
            window.open(`/claimBuilder?companyID=${rowData.dealId}&stateClaimID=${this.props.Rowdetails.stateClaimID}&state=${this.props.Rowdetails.sourceState}&relevantSellerBusinessNameID=${this.props.Rowdetails.relevantSellerNameID}&sharePointDetails=${JSON.stringify(rowData.sharePointDetails)}&releveantSellerName=${this.props.Rowdetails.relevantSellerName}&OPPClaimID=${this.props.Rowdetails.oppClaimID}&claimAmount=${this.props.Rowdetails.claimAmountPaid}&mappedNameID=${this.props.Rowdetails.mappedNameID}&mappedName=${this.props.Rowdetails.mappedName}`);
        } else {
            window.open(`/pdfEditor?details=${encodeURIComponent(JSON.stringify(rowData))}`);
        }
    }
    dateTemplateNew = (rowData, column) => {
        return <span className='' data-toggle="tool-tip"
            title={rowData[column.field]} style={{ textOverflow: "ellipsis" }}>
            {rowData[column.field] ? dateFormats.formatDate(rowData[column.field], "MM/DD/YY") : ""}
        </span >

    }

    submit = async (item) => {

    }



    render() {
        return (
            <span>
                <Loader loader={this.state.isLoading} />
                {/* <div className='d-flex justify-content-between row' style={{ backgroundColor: '#354f6e' }}>
                    <div className='d-flex text-bolder col-10'>
                        <h4 className='p-2 text-light '>Claim Details</h4>
                        <h4 className='p-2 text-light '>Properties</h4>
                        <h4 className='p-2 text-light '>Claim Documentation</h4>
                    </div>
                    <div className=' col-2 d-flex justify-content-between'>
                        <Button color="info" outline>ClaimsBuilder</Button>
                        <Button color="warning" outline>Save</Button>
                        <Button icon="pi pi-times" rounded text severity="danger" aria-label="Cancel" ></Button>
                    </div>
                </div> */}
                <SelectButton className='ml-2 mt-2' value={this.state.claimPacket} options={[{ label: "Claim Packet", value: "Claim Packet" }, { label: "Relevance", value: "Relevance" }]} onChange={(e) => this.changeClaimPacket(e.value)} />


                {this.state.claimPacket === "Claim Packet" ? <DataTable value={this.state.claimPacketRecords} scrollable={true} scrollHeight="1000px" resizableColumns={true} columnResizeMode="expand" style={{ marginTop: "14px", width: "1000px" }}
                    // onSort={(e) => this.handleDataChangingOptionSearch(e)}
                    globalFilter={this.state.globalFilter}
                // onFilter={this.isConfirmFilterSearch}
                >
                    <Column field="fileName" header="Document Name" bodyStyle={bodyStyle} headerStyle={{
                        width: 460,
                        textAlign: "center",
                    }} body={this.hoverTemplate} dataChangeOptions={this.state.dataChangeOptionswithFilter}
                        filterElement={
                            <select onChange={(e) => this.onSeletFilterTypeSearch("searchTerm")} name="viewroom" id="viewroom" className={`mb-2 p-1 p-column-filterMode p-column-filterMode-${"searchTerm"}`} style={{ width: "100%" }} >
                                <option value="regexOr">Includes</option>
                                <option value="eq">Equals</option>
                                <option value="nregexOr">Excludes</option>

                            </select>} />
                    <Column field="createdDateUTC" header="Created Date" bodyStyle={bodyStyleRight} dataChangeOptions={this.state.dataChangeOptionswithoutFilter} body={this.dateTemplateNew} headerStyle={{
                        width: 150,
                        textAlign: "center",
                    }} />
                    <Column field="updatedDateUTC" header="Updated Date" bodyStyle={bodyStyleRight} dataChangeOptions={this.state.dataChangeOptionswithoutFilter} body={this.dateTemplateNew} headerStyle={{
                        width: 150,
                        textAlign: "center",
                    }}
                    />
                    <Column body={this.actionTemplate} bodyStyle={{ textAlign: 'center', width: '8em' }} headerStyle={headerStyle} />


                </DataTable> : this.state.claimPacket === "Relevance" ? <DataTable value={this.state.claimRelevanceRecords} scrollable={true} scrollHeight="1000px" resizableColumns={true} columnResizeMode="expand" style={{ marginTop: "14px" }}
                    // onSort={(e) => this.handleDataChangingOptionSearch(e)}
                    globalFilter={this.state.globalFilter}
                // onFilter={this.isConfirmFilterSearch}
                >
                    <Column field="fileName" header="Document Name" bodyStyle={bodyStyle} headerStyle={{
                        width: 460,
                        textAlign: "center",
                    }} body={this.hoverTemplate} dataChangeOptions={this.state.dataChangeOptionswithFilter}
                        filterElement={
                            <select onChange={(e) => this.onSeletFilterTypeSearch("searchTerm")} name="viewroom" id="viewroom" className={`mb-2 p-1 p-column-filterMode p-column-filterMode-${"searchTerm"}`} style={{ width: "100%" }} >
                                <option value="regexOr">Includes</option>
                                <option value="eq">Equals</option>
                                <option value="nregexOr">Excludes</option>

                            </select>} />

                    <Column field="relevanceType" header="Relevance Type" bodyStyle={bodyStyleRight} dataChangeOptions={this.state.dataChangeOptionswithoutFilter} headerStyle={{
                        width: 150,
                        textAlign: "center",
                    }} />
                    <Column field="pageNumberReference" header="Page Number Reference" bodyStyle={bodyStyleRight} dataChangeOptions={this.state.dataChangeOptionswithoutFilter} headerStyle={{
                        width: 150,
                        textAlign: "center",
                    }} />
                    <Column field="documentRelevanceNotes" header="Document Relevance Notes" bodyStyle={bodyStyleRight} dataChangeOptions={this.state.dataChangeOptionswithoutFilter} headerStyle={{
                        width: 150,
                        textAlign: "center",
                    }} />
                    <Column field="createdDateUTC" header="Created Date" bodyStyle={bodyStyleRight} dataChangeOptions={this.state.dataChangeOptionswithoutFilter} body={this.dateTemplateNew} headerStyle={{
                        width: 150,
                        textAlign: "center",
                    }} />
                    <Column field="updatedDateUTC" header="Updated Date" bodyStyle={bodyStyleRight} dataChangeOptions={this.state.dataChangeOptionswithoutFilter} body={this.dateTemplateNew} headerStyle={{
                        width: 150,
                        textAlign: "center",
                    }} />
                    <Column body={this.actionTemplateForPDF} bodyStyle={{ textAlign: 'center', width: '8em' }} headerStyle={headerStyle} />

                </DataTable> : null}
            </span>
        );
    }
}