import React from 'react';
import { Container } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import RsQueue from './components/RsQueue';
const rsQueue = ({ t }) => (
  <Container >
    <RsQueue />
  </Container>
);

rsQueue.propTypes = {
  t: PropTypes.func.isRequired,
};

export default withTranslation('common')(rsQueue);
