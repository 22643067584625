import React from 'react';
import { useUrlScript, useInlineScript } from "../../../../_hooks";
import {
    Card, CardBody, Col, Container, ButtonToolbar, Button
  } from 'reactstrap';

const JiraIssues = [
    {
    name: "bug",
    url: "https://oak-point-trello-teams.atlassian.net/s/d41d8cd98f00b204e9800998ecf8427e-T/-onpk8x/b/7/b0105d975e9e59f24a3230a22972a71a/_/download/batch/com.atlassian.jira.collector.plugin.jira-issue-collector-plugin:issuecollector-embededjs/com.atlassian.jira.collector.plugin.jira-issue-collector-plugin:issuecollector-embededjs.js?locale=en-US&collectorId=a21ef0a7",
    token: "a21ef0a7",
    }
];

const ReportBug = () => {

    useUrlScript("https://code.jquery.com/jquery-3.5.1.min.js");

    useUrlScript(JiraIssues[0].url);

    useInlineScript(`
        function defer(method) {
            if (window.jQuery) {
                method();
            } else {
                setTimeout(function() { defer(method) }, 50);
            }
        }

        var jiraIssues = ${JSON.stringify(JiraIssues)};

        defer(function() {
        const extendedProps = Object.fromEntries(
            jiraIssues.map(j =>
            [j.token,
                {
                "triggerFunction": function (showCollectorDialog) {
                    $('#' + j.name + '-button').click(function (e) {
                    e.preventDefault();
                    showCollectorDialog();
                    });
                }
                }
            ]
            )
        );

        window.ATL_JQ_PAGE_PROPS = $.extend(window.ALT_JQ_PAGE_PROPS, extendedProps);
        });
    `);

    return (
        <>
            <Container style={{ margin: 15, marginTop: 40 }}>
                    <div>
                        <Col md={12} lg={10}>
                        <Card className="leftMargin" >
                            <CardBody>
                                <div className="card__title">
                                    <h4 className="bold-text">Send Bug Report</h4>
                                </div>
                                <div>
                                    <h5 style={{margin: 15}}>Oh no! Found a bug? Excellent... we like it when bugs are found so we can fix them!</h5>
                                    <h5 style={{margin: 15}}>Report a bug to the development team and we will take a look at it.</h5>
                                    <h5 style={{margin: 15}}>Click the button down below and a prompt will appear. Fill everything out and hit 'Submit'. </h5>
                                </div>
                                <div>
                                <ButtonToolbar className="form__button-toolbar">
                                    <Button id="bug-button" color="primary" variant="outlined">
                                        Report Bug
                                    </Button>
                                </ButtonToolbar>
                                    
                                </div>
                            </CardBody>
                        </Card>
                        </Col >
                    </div>
            </Container>
        </>
    );
};

export default ReportBug;