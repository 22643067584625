import React from 'react';
import { Container } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import ClaimStatus from './components/ClaimStatus';
const claimStatus = ({ t }) => (
  <Container className="Custom_container">
    <ClaimStatus />
  </Container>
);

claimStatus.propTypes = {
  t: PropTypes.func.isRequired,
};

export default withTranslation('common')(claimStatus);
