import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Checkbox, FormControlLabel, Grid, LinearProgress, Link } from '@material-ui/core';
import { DropdownProps } from 'primereact/dropdown';
import React, { useCallback, useRef, useState } from 'react';
import { CSVLink } from 'react-csv';
import { InputSearch } from './InputSearch';

type ToolBarProps = {
    errorMessage: string | undefined;
    type: string;
    inputSearchOnClick: (value: string | undefined, selectValue?: any, ofcType?: string | undefined) => void;
    exportData: any[] | undefined;
    getHeadersForExport: (exportDataReference?: object | undefined) => {
        label: string;
        key: string;
    }[] | undefined
    csvFileName: string;
    icon?: string;
    dropdownSelectProps?: DropdownProps;
    getAllRowsCallback?: () => Promise<any[]>;
    exportAllDataCallback?: (dataToExport: any[]) => any[];
    gettingDataProgress?: number;
};

export const ToolBar = ({
    errorMessage,
    type,
    inputSearchOnClick,
    exportData,
    getHeadersForExport,
    csvFileName,
    icon,
    dropdownSelectProps,
    getAllRowsCallback,
    exportAllDataCallback,
    gettingDataProgress
}: ToolBarProps) => {

    const csvLinkRef = useRef<CSVLink & HTMLAnchorElement & { link: HTMLAnchorElement }>(null);
    const [exportAllData, setExportAllData] = useState<boolean>(false);
    const [exportDataReference, setExportDataReference] = useState<object>();
    const [allDataToExport, setAllDataToExport] = useState<any[]>();
    const [officeType, setOfcType] = useState<undefined | string>();


    const exportOnClick = useCallback(async () => {
        if (allDataToExport && exportAllData) {
            csvLinkRef?.current?.link.click();
        }
        else if (exportAllData && getAllRowsCallback && exportAllDataCallback) {

            let data = await getAllRowsCallback();
            if (data && data[0]) {
                setAllDataToExport(exportAllDataCallback(data));
                setExportDataReference(data[0]);
                setTimeout(() => {
                    csvLinkRef?.current?.link.click();
                }, 250);
            }
        }
        else if (exportData) {
            csvLinkRef?.current?.link.click();
        }
    }, [exportData, exportAllData, getAllRowsCallback, exportAllDataCallback, allDataToExport]);

    const handleInputSearchOnClick = useCallback((value: string | undefined, selectValue?: any, ofcType?: string | undefined) => {
        setAllDataToExport(undefined);
        inputSearchOnClick(value, selectValue, ofcType);
        setOfcType(ofcType)
    }, [inputSearchOnClick]);

    return (
        <>
            <Grid container style={{ marginTop: 10 }}>
                <Grid item xs={3}>
                    <span className='postionRelative pt-2'>
                        <h4>
                            {icon && (icon.toLowerCase().includes(".jpg") || icon.toLowerCase().includes(".jpeg") || icon.toLowerCase().includes(".png")) ? (
                                <img className={''} src={require('../../../../assets/img/' + icon)} style={{ height: '35px', width: '35px', marginTop: 0, borderRadius: 0 }} alt="" />
                            ) : undefined}

                            <b>
                                <Link className="tableType" >
                                    {type}
                                </Link>
                            </b>
                        </h4>
                    </span>
                    <span style={{ color: "red" }}>{"Foreign and IL entities are NOT included"}</span>
                </Grid>
            </Grid>
            <Grid container style={{ marginTop: 10 }}>
                <Grid item xs={12}>
                    {errorMessage ? (
                        <h5 style={{ color: 'red', marginTop: 6 }}>{`*${errorMessage}`}</h5>
                    ) : undefined}
                </Grid>
            </Grid>
            <Grid container style={{ marginTop: 10 }}>
                <Grid item xs={8}>
                    <InputSearch
                        searchOnClick={handleInputSearchOnClick}
                        type={type}
                        dropdownSelectProps={dropdownSelectProps}
                    />
                </Grid>
                <Grid item xs={4}>
                    <span className='float-right'>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={exportAllData}
                                    size="small"
                                    color="primary"
                                    onChange={() => {
                                        setExportAllData(!exportAllData)
                                    }}
                                />}
                            label="Export all data in search."
                        />
                        <Button
                            color="primary"
                            variant="outlined"
                            size={"large"}
                            // disabled={!exportAllData && !officeType ? true : false}
                            onClick={exportOnClick}
                        >
                            <FontAwesomeIcon
                                icon='download'
                                data-toggle="tool-tip"
                                title={"Export To CSV"}
                                size='lg'
                            />
                        </Button>
                        <CSVLink
                            data={allDataToExport && exportAllData ? allDataToExport : exportData ? exportData : ""}
                            headers={exportDataReference && exportAllData ? getHeadersForExport(exportDataReference) : getHeadersForExport()}
                            filename={csvFileName}
                            className="hidden text-white"
                            ref={csvLinkRef}
                            target="_blank"
                            enclosingCharacter={`"`}
                        >
                        </CSVLink>
                    </span>
                </Grid>
            </Grid>
            {gettingDataProgress ? (
                <Grid container>
                    <Grid item xs={12}>
                        <span className='float-right' style={{ marginRight: 45 }}>
                            <div>
                                {gettingDataProgress < 10 ? (
                                    <span style={{ marginLeft: 245 }}>&nbsp;&nbsp;{gettingDataProgress}%</span>
                                ) : gettingDataProgress < 99 ? (
                                    <span style={{ marginLeft: 245 }}>&nbsp;{gettingDataProgress}%</span>
                                ) : (
                                    <span style={{ marginLeft: 245 }}>{gettingDataProgress}%</span>
                                )}
                            </div>
                            <LinearProgress
                                value={gettingDataProgress ? gettingDataProgress : 0}
                                variant="determinate"
                                style={{ maxWidth: 280, width: 280 }}
                            />
                        </span>
                    </Grid>
                </Grid>
            ) : undefined}

        </>
    );
};