

import React from 'react';
import {
    TabContent, TabPane, Nav, NavItem, NavLink, Card, CardBody,
} from 'reactstrap';
import classnames from 'classnames';
import { reduxForm } from 'redux-form';
import { Container } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import StateUpFiling from './components/StateUpFiling'
const stateUpFiling = ({ t }) => (
    <Container>
        <StateUpFiling />
    </Container>
);

stateUpFiling.propTypes = {
    t: PropTypes.func.isRequired,
};

export default withTranslation('common')(stateUpFiling);
// class ClaimsTabs extends React.Component {
//     static propTypes = {
//         t: PropTypes.func,
//         handleSubmit: PropTypes.func.isRequired,
//         reset: PropTypes.func.isRequired,
//         pristine: PropTypes.bool.isRequired,
//         submitting: PropTypes.bool.isRequired,
//     };
//     constructor(props) {
//         super(props);
//         this.state = {
//             settingsData: '',
//             filterCriteria: {
//                 limit: localStorage.getItem("defaultPageOption") ? parseInt(localStorage.getItem("defaultPageOption")) : 10, page: 1, criteria: [],
//                 sortfield: "created",
//                 direction: 'desc'
//             },
//             // isLoading: false,
//             isUserUpdated: false,
//             activeTab: '2'
//         };
//     }

//     componentDidMount = async () => {

//     }


//     toggle(tab) {
//         if (this.state.activeTab !== tab) {
//             this.setState({
//                 activeTab: tab
//             });
//         }

//     }

//     render() {
//         return (
//             <div style={{ margin: 20 }} >
//                 <Card className="cardForListMargin m-0 card">
//                     {/* <Loader loader={this.state.isLoading} progress={this.state.progress} className="screen-loader" /> */}

//                     <CardBody className="tableCardBody">
//                         <form className="form" >
//                             <div style={{ width: "100%" }}>
//                                 <Nav tabs >
//                                     {/* <NavItem >
//                                         <NavLink
//                                             className={classnames({ active: this.state.activeTab === '1' })}
//                                             onClick={() => { this.toggle('1'); }}
//                                             disabled={this.state.isTabdisabled || this.state.isChildTrue}
//                                         >
//                                             {"Claims Creation"}
//                                         </NavLink>
//                                     </NavItem> */}
//                                     <NavItem >
//                                         <NavLink
//                                             className={classnames({ active: this.state.activeTab === '2' })}
//                                             onClick={() => { this.toggle('2'); }}
//                                             disabled={this.state.isTabdisabled || this.state.isChildTrue}
//                                         >
//                                             {"Claims Managemant"}
//                                         </NavLink>
//                                     </NavItem>
//                                 </Nav>
//                                 <TabContent activeTab={this.state.activeTab}>
//                                     <TabPane tabId="1">
//                                         {/* <ClaimsCreation /> */}
//                                         <ClaimsManagement />
//                                     </TabPane>
//                                     <TabPane tabId="2">
//                                         <ClaimsManagement />
//                                     </TabPane>

//                                 </TabContent>
//                             </div >
//                         </form>
//                     </CardBody>
//                 </Card>
//             </div>
//         );
//     }
// }

// // Decorate with reduxForm(). It will read the initialValues prop provided by connect()
// ClaimsTabs = reduxForm({
//     form: "Common Form", // a unique identifier for this form
//     // validate,
//     enableReinitialize: true,
// })(ClaimsTabs);

// // You have to connect() to any reducers that you wish to connect to yourself
// ClaimsTabs = connect(
//     state => ({
//         initialValues: state.commonData.data // pull initial values from account reducer
//     }),
// )(ClaimsTabs);

// export default ClaimsTabs;





