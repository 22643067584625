import React from 'react';
import { Container, Row } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import CountDisplay from './components/Buckets';
import DealBuckets from './components/DealBuckets';
import Loader from '../App/Loader';
import RolePermissions from '../Cruds/CommonModals/Permissions'
import configMessages from '../../config/configMessages';
import NoViewComponent from '../../containers/Cruds/CommonDataTable/NoView';
class Dashboard extends React.Component {
  static propTypes = {
    t: PropTypes.func.isRequired,
    payload: PropTypes.arrayOf(PropTypes.shape({
      color: PropTypes.string,
      value: PropTypes.string,
    })).isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      dashboardData: {},
      isLoading: false,
      screenPermissions:""
    };
  }

  componentDidMount = async () => {
    if (this.props.onRef) {
      this.props.onRef(this);
    }
    let screenPermissions = RolePermissions.screenPermissions("Name/Address Import");
    this.setState({
      screenPermissions:screenPermissions
    });

  }


  handlePercentage = async (Val) => {
    let counter = 0;
    const interval = setInterval(async () => {
      if (this.state.isLoading) {
        counter = counter + 15;
        await this.setState({
          progress: counter,
        })
      } else {
        if (!this.state.isLoading) {
          clearInterval(interval);
        }
      }
    }, 100);
  }

  isShowLoader = async (value) => {
    this.setState({
      isLoading: value
    })
    if (value === true) {
      this.handlePercentage("1")
    }
  }



  render() {
    return (
      (this.state.screenPermissions==="Edit" ||this.state.screenPermissions==="View")?
      <div>
        <Loader loader={this.state.isLoading} progress={this.state.progress} className="progress-loader" />

        <Container className="tableCardBody mt-3" style={{ width: '98%', marginLeft: '20px', backgroundColor: "white" }}>
          <Row>
            <div className="topbar__left">
              <span className={`tableheadericon ml-3 lnr lnr-layers`} />
              <h4 className="Kanban_title_card " >{this.props.ScoringType === "RegularScoring" ? "Regular Scoring Bucket" : "Deal Sizing Bucket"}</h4></div>
          </Row>
          {this.props.ScoringType === "RegularScoring" ?
            <CountDisplay
              isShowLoader={this.isShowLoader}
              ScoringType={this.props.ScoringType}
            /> : <DealBuckets
              isShowLoader={this.isShowLoader}
              ScoringType={this.props.ScoringType}
            />}
        </Container >
      </div>:this.state.screenPermissions===""?null : <NoViewComponent/>
    );
  }
}

export default withTranslation('common')(Dashboard);


