import React from 'react';
import { Container } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import SellerNegativeNoticeViewers from './components/SellerNegativeNoticeViewers';
const sellerNegativeNoticeViewers = ({ t }) => (
  <Container>
    <SellerNegativeNoticeViewers />
  </Container>
);

sellerNegativeNoticeViewers.propTypes = {
  t: PropTypes.func.isRequired,
};

export default withTranslation('common')(sellerNegativeNoticeViewers);
