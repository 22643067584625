import React from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQuestionCircle } from '@fortawesome/free-solid-svg-icons';
import FileUpload from '../../Form/components/FileUpload';
// import readXlsxFile from 'read-excel-file'
import { Workbook } from 'exceljs';
import * as fs from 'file-saver';

// const Excel = require('exceljs');
// const XLSX = require('xlsx')
// import { Workbook } from 'exceljs';




// config file
export default class DeleteRowModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            click: false,
            namesArray: "",
            nameTypeArray: "",
            IssueCodeArray: "",
            headerAddress: ["EntityID", "Address1", "Address2", "City", "StateProvince", "PostCode", "Country", "OfficePhone", "OfficeFax", "AddressSource", "LocationType", "IsKey"],
            header: ["BusinessName", "CompanyID", "IssueName", "NameSource", "NameType", "issueNote", "generalNote", "IsKey", "nameConnection", "ConnectionYear", "PBConnectionType", "PBConnectionYear", "ProposedLegalNameSource", "ActiveInactiveNotes", "SOSId", "SOSLastFilingDate", "DateCheckedforSOSLastFilingDate", "SOSOfficers", "DateCheckedforSOSOfficers", "SEC10Kex21StartYear", "SECRegistrationStartYear", "SEC10Kex21LastYear", "SECRegistrationLastYear", "DatePreSalesUPResearch", "DatePreSalesUPRefresh", "DateIntegrityPreSaleResearch", "DateIntegrityPostSaleResearch", "IntegrityNotes", "LegalNotes", "APAExhibitPlacement", "SellerComments", "UPCountTotal", "UPValueTotal", "PaidRecoveries", "JudgmentFees", "JudgmentPreJudgmentInterest", "connectionTypeYear", "IncorporationDate", "InactivityDate", "EntityStatus", "EntityStatusSubType", "DateRestrictions", "NameAIStatus"],
            showHelp: false
        };
    }

    uploadToServer = () => {
        this.setState({ click: true })
        this.fileUploadRef.UploadFileToServer();
    }
    generateExcelAddress(list = [], header = []) {

        let namesArrays = this.props.namesArray.split(",").map(u => [u])

        header = this.state.headerAddress

        //Create workbook and worksheet
        let workbook = new Workbook();
        let worksheet = workbook.addWorksheet('Names');
        let worksheet1 = workbook.addWorksheet('Names1')
        // add new rows and return them as array of row objects
        const secondRows = worksheet1.addRows(namesArrays)
        worksheet1.state = 'hidden'
        //Add Header Row
        let headerRow = worksheet.addRow(header);

        // Cell Style : Fill and Border
        headerRow.eachCell((cell, number) => {
            cell.fill = {
                type: 'pattern',
                pattern: 'solid',
                fgColor: { argb: 'FFFFFF00' },
                bgColor: { argb: '#FFFFFF' }
            }
            cell.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } }
        })
        for (let i = 1; i <= 50; i++) {
            worksheet.getColumn(i).width = 30;
        }
        for (let i = 2; i <= 5000; i++) {

            worksheet.getCell('A' + i).dataValidation = {
                type: 'list',
                allowBlank: true,
                formulae: ['Names1!A$1:A$5000'],

            };
            worksheet.getCell('L' + i).dataValidation = {
                type: 'list',
                allowBlank: true,
                formulae: ['"false,true"']
            };

        }

        //Generate Excel File with given name
        workbook.xlsx.writeBuffer().then((data) => {
            let blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
            fs.saveAs(blob, 'Address.xlsx');
        })

    }
    generateExcel(list = [], header = []) {
        let namesArrays = this.props.namesArray.split(",").map(u => [u])
        let namesTypeArrays = this.props.nameTypeArray.split(",").map(u => [u])
        let IssueCodeArray = this.props.IssueCodeArray.split(",").map(u => [u])
        header = this.state.header

        //Create workbook and worksheet
        let workbook = new Workbook();
        let worksheet = workbook.addWorksheet('Names');
        let worksheet1 = workbook.addWorksheet('Names1')
        let worksheet2 = workbook.addWorksheet('Names2')
        let worksheet3 = workbook.addWorksheet('Names3')

        const rows = [
            ["Acqusition,Conversion(Non - Survivor)"], ["Conversion(Survivor)"], ["Division"], ["Former Name"], ["Joint Debtor"], ["Judegement Debtor"], ["Lead Debtor"], ["Merger(Non - Survivor)"], ["Merger(Survivor)"], ["Oak Point APA Lead Seller"], ["Oak Point APA Seller"], ["OPP Sale Contact’s Name"], ["Subsidiary"], ["Trade Name"], ["Acqusition,Conversion(Non - Survivor)"], ["Conversion(Survivor)"], ["Division"], ["Former Name"]
        ];
        // add new rows and return them as array of row objects
        const newRows = worksheet1.addRows(rows);
        const secondRows = worksheet1.addRows(namesArrays)
        const thirdRows = worksheet2.addRows(namesTypeArrays)
        const fourthRows = worksheet3.addRows(IssueCodeArray)

        worksheet1.state = 'hidden'
        worksheet2.state = "hidden"
        worksheet3.state = "hidden"

        //Add Header Row
        let headerRow = worksheet.addRow(header);

        // Cell Style : Fill and Border
        headerRow.eachCell((cell, number) => {
            cell.fill = {
                type: 'pattern',
                pattern: 'solid',
                fgColor: { argb: 'FFFFFF00' },
                bgColor: { argb: '#FFFFFF' }
            }
            cell.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } }
        })
        for (let i = 1; i <= 50; i++) {
            worksheet.getColumn(i).width = 30;
        }
        for (let i = 2; i <= 5000; i++) {
            // worksheet.getCell('B' + i).dataValidation = {
            //     type: 'list',
            //     allowBlank: true,
            //     formulae: ['"' + this.props.namesArray + '"']
            // };

            // worksheet.getCell('C' + i).dataValidation = {
            //     type: 'list',
            //     allowBlank: true,
            //     formulae: ['"0,1.1,1.2,1.3,1.4,1.7,2,3,7.1,7.2,7.3,7.4,7.5,7.6"']
            // };
            worksheet.getCell('H' + i).dataValidation = {
                type: 'list',
                allowBlank: true,
                formulae: ['"false,true"']
            };
            worksheet.getCell('I' + i).dataValidation = {
                type: 'list',
                allowBlank: true,
                formulae: ['Names1!A$1:A$14'],

            };
            worksheet.getCell('B' + i).dataValidation = {
                type: 'list',
                allowBlank: true,
                formulae: ['Names1!A$19:A$5000'],

            };
            worksheet.getCell('E' + i).dataValidation = {
                type: 'list',
                allowBlank: true,
                // formulae: ['"' + this.props.nameTypeArray + '"']
                formulae: ['Names2!A$1:A$5000'],
            };
            // worksheet.getCell('C' + i).dataValidation = {
            //     type: 'list',
            //     allowBlank: true,
            //     // formulae: ['"' + this.props.nameTypeArray + '"']
            //     formulae: ['Names3!A$1:A$5000'],
            // };
        }

        //Generate Excel File with given name
        workbook.xlsx.writeBuffer().then((data) => {
            let blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
            fs.saveAs(blob, 'Names.xlsx');
        })

    }


    showHelpContent = () => {
        this.setState({ showHelp: this.state.showHelp ? false : true })
    }
    render() {
        return (
            <Modal isOpen={this.props.openBulkUploadModal} fade={false} className='bulkUploadModalHeight'>
                <ModalHeader >
                    Bulk Upload
                    <FontAwesomeIcon icon={faQuestionCircle}
                        className='ml-2'
                        color='green'
                        data-toggle="tool-tip"
                        // onClick={() => { navigator.clipboard.writeText(this.state.exhibit1String) }}
                        onClick={() => { this.showHelpContent() }}
                        title={"Help"}
                        style={{ width: 18 }}
                    />
                    <div style={{ "font-weight": "bold", textAlign: "left" }}>
                        {this.state.showHelp ?
                            <ul>
                                <li>Only 5000 records needs to be uploaded at once.</li>
                                <li> It will take some time to process the data.So please refresh after sometime.</li>
                                <li>If any records are failed to upload then please check your email for the reason.</li>

                            </ul>



                            : ""}
                    </div>
                </ModalHeader>
                <ModalBody >
                    <div className='row'>
                        <div className='col-sm-5'>
                            Sample Document
                        </div>
                        <div className='col-sm-7'>
                            {this.props.activeTab === "3" ? <Button color='primary' outline onClick={() => { this.generateExcel() }}>Download</Button>
                                : this.props.activeTab === "4" ? <Button color='primary' outline onClick={() => { this.generateExcelAddress() }}>Download</Button>
                                    : <a className='btn btn-primary outline '
                                        href={this.props.sample}
                                    >Download</a>}
                            {/* <a className='btn btn-primary outline '
                                // href={this.props.sample}
                                onClick={this.setData()}
                            >Download</a> */}
                            {/* <Button color='primary' outline onClick={() => { this.setData() }}>Download</Button> */}

                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-sm-5'>
                            Please attach file
                        </div>
                        <div className='col-sm-7'>
                            <FileUpload type="bulkUpload"
                                onRef={(ref) => (this.fileUploadRef = ref)}
                                url={this.props.activeTab === "3" ? `uploads/bulkupload?type=sellerNames&dimSellerID=` + this.props.dimSellerID : this.props.activeTab === "4" ? `uploads/bulkupload?type=sellerAddresses&dimSellerID=` + this.props.dimSellerID : this.props.activeTab === "debtors" ? `uploads/bulkupload?type=sellerDebtors&dimSellerID=` + this.props.dimSellerID : `uploads/bulkupload?type=sellerAPANames&dimSellerID=` + this.props.dimSellerID}
                                activeTab={this.props.activeTab}
                                toggleNames={this.props.toggleNames}
                                setResponse={this.props.setResponse}
                                close={this.props.closeBulkModal}
                            />
                        </div>
                    </div>
                </ModalBody>
                <ModalFooter style={{ width: this.props.activeTab === "3" || this.props.activeTab === "4" ? '308px' : "309px" }}>
                    <Button color='primary' outline onClick={this.props.closeBulkModal}>Close</Button>
                    <Button disabled={this.state.click} color='primary' outline onClick={this.uploadToServer}>{this.props.activeTab === "3" ? "Upload Names" : this.props.activeTab === "4" ? "Upload Address" : this.props.activeTab === "debtors" ? "Upload Debtors" : "Upload APA Names"}</Button>
                </ModalFooter>
            </Modal >
        );
    }
}