import React from 'react';
import store from '../../../App/store';
import apiCalls from '../../../../config/apiCalls'
import DataTables from '../../CommonDataTable/DataTable';
import Loader from '../../../App/Loader';
import fetchMethodRequest from '../../../../config/service'
// config file
export default class NextActionDate extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      type: 'Next Action Date Projection',
    };
  }

  static getDerivedStateFromProps(props, state) {
    let storeData = store.getState()
    let languageData = storeData && storeData.settingsData && storeData.settingsData.settings && storeData.settingsData.settings.languageTranslation ? storeData.settingsData.settings.languageTranslation : ""
    return { languageData: languageData };
  }

  componentDidMount = () => {
    //Screen permisions value can be edit,view, no view
    // let screenPermissions = RolePermissions.screenPermissions('Employees');
    this.getMctSource()
    this.getNewStatusReasonsForEditor()
  }

  /**
   * 
   * @returns getting the claim status fro the api
   */
  getNewStatusReasonsForEditor = async () => {
    let claimStatusArray = [], claimReasonArray = [];
    let filterCriteria = {}
    filterCriteria['criteria'] = [{ key: 'activeIndicator', value: true, type: 'eq', add_Filter: true },      { key: 'propertyStatusUsage', value: 'Property Editor', type: 'eq', add_Filter: true }
  ];
    filterCriteria.sortfield = "stageSequence, statusSequence, reasonSequence"
    filterCriteria.direction = "asc"
    await fetchMethodRequest('GET', `${apiCalls.propertyStatusReason}?filter=${JSON.stringify(filterCriteria)}`).then(async (response) => {
      if (response && response["propertyStatusReasons"] && response["propertyStatusReasons"].length > 0) {
        let propertyStatusReasons = response["propertyStatusReasons"]
        for (let propertyStatusReason of propertyStatusReasons) {
          // if (propertyStatusReason.claimSequence) {
          if (!claimStatusArray.some((obj) => obj.value == propertyStatusReason.status)) {
            await claimStatusArray.push({ label: propertyStatusReason.status, value: propertyStatusReason.status })
          }
          // }
        }

        await this.setState({ propertyStatusArray: claimStatusArray, claimReasonArray: claimReasonArray, propertyStatusReasons: propertyStatusReasons })
      }
    })
    return this.state.claimStatusArray;
  }

  /**
   * 
   * @returns mct Sources getting from API
   */
  getMctSource = async () => {
    let mctSourceArray = [], dataArray = [];
    let filterCriteria = {}
    filterCriteria.sortfield = "SourceInfo_Sort_Code, SourceInfo_Source"
    filterCriteria.direction = 'asc'

    await fetchMethodRequest('GET', `${apiCalls.mctSources}?filter=${JSON.stringify(filterCriteria)}&isDropdown=true`).then(async (response) => {
      if (response && response["claimSources"] && response["claimSources"].length > 0) {
        let mctSources = response["claimSources"]
        for (let mctSource of mctSources) {
          mctSourceArray.push({ label: mctSource.sourceInfo_Source, value: mctSource.sourceInfo_Source, mcT_SourceInfo_SQL_Unique_ID: mctSource.mcT_SourceInfo_SQL_Unique_ID, sourceInfo_Source_Type: mctSource.sourceInfo_Source_Type, sourceInfo_State: mctSource.sourceInfo_State })
        }

        this.setState({ mctSourceArray: mctSourceArray })
      }
    })
    return this.state.mctSourceArray;
  }

  //  Table Column Fields
  getTableFields = () => {
    let data = [

      {
        "show": true,
        textAlign: 'left',
        width: 50,
        field: 'claimStatus',
        fieldType: 'BoolText',
        "mobile": true,
        header: 'Claim Status',
        filter: true,
        "displayInSettings": true,
        "displayInFilter": true,
        sortable: true,

      },
      {
        "show": true,
        textAlign: 'left',
        width: 50,
        field: 'source',
        // fieldType: 'BoolText',
        "mobile": true,
        header: 'Source',
        filter: true,
        "displayInSettings": true,
        "displayInFilter": true,
        sortable: true,
      },
      {
        "show": true,
        textAlign: 'left',
        width: 80,
        filterType: "num",
        field: 'nextActionDateDaysDefault',
        "mobile": true,
        header: 'Next Action Date Days Default',
        filter: true,
        fieldType: "number",
        "displayInSettings": true,
        "displayInFilter": true,
        sortable: true,
      },
      {
        "show": true,
        textAlign: 'left',
        width: 80,
        field: 'Actions',
        fieldType: 'Actions',
        "mobile": true,
        header: 'Actions',
        filter: true,
        "displayInSettings": true,
        "displayInFilter": true,
        sortable: true,
      },

    ]
    return data;
  };

  // Form Input Fields
  getFormFields = () => {
    return ([

      {
        //'show': false,
        "value": "",
        "type": "dropDown",
        "options": this.state.propertyStatusArray,
        "name": "claimStatus",
        "label": "Status",
        "id": "name",
        "placeholder": "Claim Status",
        "required": true
      },
      {
        //'show': false,
        "value": "",
        "type": "text",
        "type": "dropDown",
        "options": this.state.mctSourceArray,
        "name": "source",
        "label": "Source",
        "id": "name",
        "placeholder": "Source",
        "required": true
      },
      {
        //'show': false,
        "value": "",
        "type": "number",
        "name": "nextActionDateDaysDefault",
        "label": "Next Action Date Days Default",
        "id": "NextActionDateDaysDefault",
        "placeholder": "Next Action Date Days Default",
        "required": true
      },
      {
        //'show': false,
        "value": "",
        "type": "switch",
        "name": "activeIndicator",
        "label": "Active /Inactive Status",
        "id": "active",
        "placeholder": "active",
        // "required": true
      },
    ]);
  }


  // submit = async (item) => {


  // }

  render() {
    return (
      <span>
        <Loader loader={this.state.isLoading} />
        <DataTables
          onRef={(ref) => (this.datatableref = ref)}
          getTableFields={this.getTableFields}
          formFields={this.getFormFields}
          exportRequried={false}
          printRequried={false}
          sortField={"claimStatus"}
          addRequried={true}
          editRequired={true}
          isClearFilters={true}
          deleteRequired={false}
          viewRequired={true}
          settingsRequired={false}
          filterRequired={true}
          gridRequried={false}
          sample={false}
          expandTableFields={this.expandTableFields}
          globalSearchFieldName='user'
          globalSearch={'Search'}
          type='Next Action Date Projection'
          displayright={true}
          icon='user'
          routeTo='statusReason'
          displayViewOfForm='modal'
          apiResponseKey="nextActionDateProjections"
          apiUrl={apiCalls.nextActionDateProjection}
          entityType='employee'
        />
      </span>
    );
  }
}