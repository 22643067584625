import React from 'react';
import { Container } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import NextActionUserReportConfiguration from './components/NextActionUserReportConfiguration';

const nextActionUserReportConfiguration = ({ t }) => (
  <Container
  // className="Custom_container"
  >
    <NextActionUserReportConfiguration />
  </Container>
);

nextActionUserReportConfiguration.propTypes = {
  t: PropTypes.func.isRequired,
};

export default withTranslation('common')(nextActionUserReportConfiguration);
