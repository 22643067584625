import React from 'react';
import { Col, Container, Row } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import CountDisplay from './components/CountDisplay';

class Dashboard extends React.Component {
  static propTypes = {
    t: PropTypes.func.isRequired,
    payload: PropTypes.arrayOf(PropTypes.shape({
      color: PropTypes.string,
      value: PropTypes.string,
    })).isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      dashboardData: {},
      isLoading: false,
      expirationsList: [],
      meetingsData: [],

    };
  }

  componentDidMount = () => {
    let user = JSON.parse(localStorage.getItem('loginCredentials'));
    if (user) {
      this.setState({
        loginData: user,
        role: user.role
      })
    }
    if (document.getElementsByClassName("layout layout--collapse").length === 0) {
      document.getElementsByClassName("topbar__button topbar__button--desktop")[0].click();
    }
    // this.getExpirationsList();
    this.getCountData();
  }

  // Get dashboard counts
  getCountData() {
    // this.setState({ isLoading: true });
    // fetch('GET', '')
    //   .then(async (response) => {
    //     if (response && response.details) {
    //       this.setState({
    //         dashboardData: response.details,
    //         meetingsData: response.todaymeetings
    //       });
    //     }
    //     this.setState({ isLoading: false });
    //   }).catch((err) => {
    //     return err;
    //   });
  }

  // Get Last 30 days expirations list
  getExpirationsList = () => {
    //this.setState({ isLoading: true });
    // fetch('GET', 'locations')
    //   .then(async (response) => {
    //     if (response && response.locations) {
    //       this.setState({
    //         expirationsList: response.locations
    //       });
    //     }
    //     this.setState({
    //       isLoading: false,
    //     });
    //   }).catch((err) => {
    //     return err;
    //   });

  }

  render() {
    return (
      <Container className="tableCardBody" style={{ width: '98%', marginLeft: '20px' }}>
        {/* <Loader loader={this.state.isLoading} /> */}

        <Row>
          <Col md={12} className="d-flex">
            <span className={`tableheadericon lnr lnr-chart-bars mt-2 mr-2`} />
            <h3 className="page-title" style={{ marginBottom: '5px' }}>Reports</h3>
          </Col>
        </Row>
        {/* Counts */}
        <CountDisplay
          dashboardData={this.state.dashboardData}
          meetingsData={this.state.meetingsData}
        />
      </Container >
    );
  }
}

export default withTranslation('common')(Dashboard);


