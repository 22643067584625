import React from 'react';
import { Col, Container, Row } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import CountDisplay from './components/CountDisplay';
import ExpiredSessionModal from '../Cruds/CommonModals/ExpiredSessionModal';
import NoViewComponent from '../../containers/Cruds/CommonDataTable/NoView';

// import fetch from '../../config/service';
// import config from '../../config/config'
// import apiCalls from '../../config/apiCalls'
// import Loader from '../App/Loader';
// import CardTitle from 'reactstrap/lib/CardTitle';



// const renderLegend = ({ payload }) => (
//   <ul className="dashboard__chart-legend">
//     {payload.map(entry => (
//       <li key={entry.id}><span style={{ backgroundColor: entry.color }} />{entry.value}</li>
//     ))}
//   </ul>
// );
let timeout
class Dashboard extends React.Component {
  static propTypes = {
    t: PropTypes.func.isRequired,
    payload: PropTypes.arrayOf(PropTypes.shape({
      color: PropTypes.string,
      value: PropTypes.string,
    })).isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      screenPermissions:""
    };
  }

  componentDidMount = () => {
    let user = JSON.parse(localStorage.getItem('loginCredentials'));
    let permissions = localStorage.getItem('rolePermissions')
    permissions = permissions ? JSON.parse(permissions)["Weekly Recovery Plan"] : "NoView"
    if (user) {
      this.setState({
        loginData: user,
        role: user.role,
        screenPermissions: permissions
      })
    }
    localStorage.removeItem("Weekly Recovery Plan_column_order")
  }
  componentDidUpdate() {

    // if (localStorage.getItem('loggedTime')) {
    //   let loggedTime = localStorage.getItem('loggedTime')
    //   if (timeout) {
    //     clearTimeout(timeout)
    //   }
    //   timeout = setTimeout(() => this.setState({ expiredSessionModal: true, openedMultiTabs: true }), loggedTime);
    // }

  }
  cancelexpiredSessionModal = async () => {
    await this.setState({
      expiredSessionModal: false
    })
  }
  expiredSessionModal() {
    return (
      <ExpiredSessionModal
        openConfirmationModal={this.state.expiredSessionModal}
        cancelSessionWarningModal={this.cancelexpiredSessionModal}
        getData={this.getDataFromServer}

      />
    )
  }



  render() {
    return (
      this.state.screenPermissions === "Edit" || this.state.screenPermissions === "View" ?

        <Container className="tableCardBody" style={{ width: '98%', marginLeft: '20px' }}>
          {/* <Loader loader={this.state.isLoading} /> */}

          <Row>
            <Col md={12} className="d-flex">
              <span className={`tableheadericon lnr lnr-calendar-full mt-2 mr-2`} />
              <h3 className="page-title" style={{ marginBottom: '5px' }}>Weekly Recovery Plan</h3>
            </Col>
          </Row>

          {/* Counts */}
          <CountDisplay
            dashboardData={this.state.dashboardData}
            meetingsData={this.state.meetingsData}
          />

          {this.state.expiredSessionModal ? this.expiredSessionModal() : null}


        </Container >:this.state.screenPermissions===""?null
        : <NoViewComponent />
    );
  }
}

export default withTranslation('common')(Dashboard);


