import React from 'react';
import { Container } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import ReadyToReview from './components/Readytoreview';

const readyToReview = ({ t }) => (
  <Container >
    <ReadyToReview />
  </Container>
);

readyToReview.propTypes = {
  t: PropTypes.func.isRequired,
};

export default withTranslation('common')(readyToReview);
