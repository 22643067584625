import React from 'react';
import { Container } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import ScheduledJobLog from './components/ScheduledJobLog';
const scheduledJobLog = ({ t }) => (
  <Container >
    <ScheduledJobLog />
  </Container>
);

scheduledJobLog.propTypes = {
  t: PropTypes.func.isRequired,
};

export default withTranslation('common')(scheduledJobLog);
