import React from 'react';
import { Container } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import PropertyAndClaimStatus from './components/PropertyAndClaimStatus';

const propertyAndClaimStatus = ({ t }) => (
  <Container 
  // className="Custom_container"
  >
    <PropertyAndClaimStatus />
  </Container>
);

propertyAndClaimStatus.propTypes = {
  t: PropTypes.func.isRequired,
};

export default withTranslation('common')(propertyAndClaimStatus);
