import React from 'react';
import DataTables from '../../CommonDataTable/DataTable';
import store from '../../../App/store';
import fetchMethodRequest from '../../../../config/service';
import apiCalls from '../../../../config/apiCalls';

//Loader
import Loader from '../../../App/Loader';
// config file
export default class DealStatistics extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      type: 'Properties',
      tabType: "Summary",
      apiUrl: 'nsScoredProperties/DealStatisticsSummary',
      responseKey: "result",
      ReasonsArray: [],
      propertyFilterSortOptions: [
        { label: 'Property Name', value: "propertyName" },
        { label: 'Prop.Address', value: "propertyAddressLine1" },
        { label: 'Business Name', value: "businessName" },
        { label: 'Property Zip Code', value: "propertyZipCode" },
        { label: 'Property City', value: "propertyCity" },
        { label: 'Property State', value: "propertyState" },
      ],
    };
  }

  static getDerivedStateFromProps(props, state) {
    let storeData = store.getState()
    let languageData = storeData && storeData.settingsData && storeData.settingsData.settings && storeData.settingsData.settings.languageTranslation ? storeData.settingsData.settings.languageTranslation : ""
    return { languageData: languageData };
  }



  componentDidMount = async () => {

    if (this.props.onRef) {
      this.props.onRef(this);
    }
    await this.getSettingsData();
    await this.getReasonForEditor();
    await this.getNewStatusReasonsForEditor()
  }
  getSettingsData = async () => {
    let StatusArray = [];
    let filterCriteria = {}
    filterCriteria['criteria'] = [{ key: 'activeIndicator', value: true, type: 'eq', add_Filter: true },
    //  { key: 'screenType', value: ["Property Editor"], type: 'in', add_Filter: true }
    ];
    fetchMethodRequest('GET', `PropertyStatus?filter=${JSON.stringify(filterCriteria)}&type=exportToCsv`).then(async (response) => {
      if (response && response["propertyStatuses"] && response["propertyStatuses"].length > 0) {
        let propertyStatuses = response["propertyStatuses"]
        for (let status of propertyStatuses) {
          StatusArray.push({ label: status.status, value: status.status })
        }
        await this.setState({ settingsData: StatusArray })
      }
    })
    return this.state.settingsData;
  }

  getReasonForEditor = async () => {
    let ReasonsArray = [];
    let filterCriteria = {};
    filterCriteria['criteria'] = [{ key: 'activeIndicator', value: true, type: 'eq', add_Filter: true }];
    return fetchMethodRequest('GET', `${apiCalls.PropertyReasons}?filter=${JSON.stringify(filterCriteria)}&type=exportToCsv`)
      .then(async (response) => {
        if (response && response["propertyReasons"].length > 0) {
          let propertyReasons = response["propertyReasons"]
          for (let reason of propertyReasons) {
            if (ReasonsArray.findIndex(obj => obj.value === reason.reason) === -1) {
              ReasonsArray.push({ label: reason.reason, value: reason.reason })
            }
          }
          await this.setState({ shownreasons: ReasonsArray })
        } else if (response && response['errorMessage'] === "Session is about to expire in 5 minutes.") {
          await this.setState({
            sessionWarning: true,

          })
        }
      }).catch((err) => {
        return err;
      })
  }

  /**
 * 
 * @returns get reasons from propertyStatusandReasonField 
 */
  getNewStatusReasonsForEditor = async (val) => {
    var propertyStatusArray = [], filterCriteria = {}
    var propertyStatusReasonArray = []
    filterCriteria.sortfield = "propertySequence"
    filterCriteria.direction = "asc"

    return fetchMethodRequest('GET', `${apiCalls.propertyStatusReason}?filter=${JSON.stringify(filterCriteria)}`)
      .then(async (response) => {
        if (response && response["propertyStatusReasons"].length > 0) {
          let propertyStatusReasons = response["propertyStatusReasons"]
          for (let reason of propertyStatusReasons) {
            let statusIndex = propertyStatusArray.findIndex(obj => obj.label === reason.status)
            if (statusIndex === -1) {
              propertyStatusArray.push({ label: reason.status, value: reason.status })
            }
            let reasonIndex = propertyStatusReasonArray.findIndex(obj => obj.label === reason.reason)
            if (reasonIndex === -1) {
              propertyStatusReasonArray.push({ label: reason.reason, value: reason.reason })
            }
          }
          await this.setState({ propertyStatusArray: propertyStatusArray, propertyStatusReasonArray: propertyStatusReasonArray })

        } else if (response && response["propertyStatusReasons"].length == 0) {
          await this.setState({ propertyStatusArray: [], propertyStatusReasonArray: [] })
        } else if (response && response['errorMessage'] === "Session is about to expire in 5 minutes.") {
          await this.setState({
            sessionWarning: true,

          })
        }
      }).catch((err) => {
        return err;
      })
  }

  componentWillUnmount() {
    if (this.props.onRef) {
      this.props.onRef(null);
    }
  }


  getTableFields = (tabType) => {
    if (tabType) {
      let apiUrl = this.state.apiUrl;
      let responseKey = this.state.responseKey;
      if (tabType === 'Summary') {
        apiUrl = "nsScoredProperties/DealStatisticsSummary"
        responseKey = "result"
      } else if (tabType === 'Data') {
        apiUrl = "nsscoredproperties";
        responseKey = "ns_scored_propertys"
      } else {
        apiUrl = 'nsScoredProperties/DealStatisticsSummary'
        responseKey = "result"
      }
      this.setState({
        tabType: tabType,
        apiUrl: apiUrl,
        type: tabType,
        responseKey: responseKey
      })
    }

    let data = [];
    if (tabType === 'Summary') {
      data = this.getDealSizedTableFields();
    }
    else if (tabType === 'Data') {
      data = this.getSellerTableFields();
    } else {
      data = this.getDealSizedTableFields();


    }
    return data;
  };
  getDealSizedTableFields = () => {

    let data = [
      {
        "show": false,
        "textAlign": "left",
        "width": 110,
        "field": "EstateName",
        "mobile": true,
        "header": "Deal Name",
        "displayInSettings": true,
        "filter": true,
        "sortable": false,
      },
      {
        "show": false,
        "textAlign": "left",
        "width": 110,
        "field": "BusinessName",
        "mobile": true,
        "header": "Business Name",
        "displayInSettings": true,
        "filter": true,
        "sortable": false,
      },
      {
        "show": false,
        "textAlign": "left",
        "width": 80,
        "field": "PropertyName",
        "allowInt": true,
        "mobile": true,
        // "fieldType": "ShowData",
        "header": "Property Name",
        "displayInSettings": true,
        "filter": true,
        "sortable": false,
      }, {
        "show": false,
        "textAlign": "left",
        "width": 80,
        "field": "PropertyCity",
        "allowInt": true,
        "mobile": true,
        // "fieldType": "ShowData",
        "header": "Property City",
        "displayInSettings": true,
        "filter": true,
        "sortable": false,
      },
      {
        "show": false,
        "textAlign": "left",
        "width": 80,
        "field": "SourceState",
        "mobile": true,
        "header": "Source State",
        "displayInSettings": true,
        "filter": true,
        "sortable": false,
      }, {
        "show": true,
        "textAlign": "center",
        "width": 80,
        "field": "KnownPropertyCount",
        // "filterType": "num",
        "mobile": true,
        // "fieldType": "ShowData",
        "header": "Known Property Count",
        "displayInSettings": true,
        "filter": false,
        "sortable": false,
      }, {
        "show": true,
        "textAlign": "right",
        "width": 80,
        "field": "KnownPropertyValue",
        "mobile": true,
        "filterType": "num",
        "fieldType": "formatCurrency",
        "header": "Known Property Value",
        "displayInSettings": true,
        "filter": true,
        "sortable": false,
      }, {
        "show": true,
        "textAlign": "center",
        "width": 80,
        "field": "EstimatedPropertyCount",
        // "filterType": "num",
        "mobile": true,
        // "fieldType": "ShowData",
        "header": "Est. Property Count",
        "displayInSettings": true,
        "filter": false,
        "sortable": false,
      }, {
        "show": true,
        "textAlign": "right",
        "width": 80,
        "field": "EstimatedPropertyValue",
        "mobile": true,
        "fieldType": "formatCurrency",
        "header": "Est. Property Value",
        "displayInSettings": true,
        "filter": true,
        "sortable": false,
      }, {
        "show": true,
        "textAlign": "center",
        "width": 80,
        "field": "TotalPropertyCount",
        // "filterType": "num",
        "mobile": true,
        // "fieldType": "ShowData",
        "header": "Total Property Count",
        "displayInSettings": true,
        "filter": false,
        "sortable": false,
      }, {
        "show": true,
        "textAlign": "right",
        "width": 80,
        "field": "TotalPropertyValue",
        "mobile": true,
        "filterType": "num",
        "fieldType": "formatCurrency", "header": "Total Property Value",
        "displayInSettings": true,
        "filter": true,
        "sortable": false,
      },
    ]
    return data;
  };


  getSellerTableFields = () => {
    let data = [

      {
        "show": false,
        "textAlign": "left",
        "width": 110,
        "field": "bucket",
        "mobile": true,
        "header": "Bucket",
        "displayInSettings": true,
        "filter": true,
        "sortable": true,
      },
      {
        "show": true,
        "textAlign": "left",
        "width": 110,
        "field": "businessName",
        isFuzzy: true,
        "mobile": true,
        "header": "Business Name",
        "displayInSettings": true,
        "filter": true,
        "sortable": true,
      },
      {
        "show": true,
        "textAlign": "left",
        "width": 80,
        "field": "statePropertyId",
        "allowInt": true,
        "mobile": true,
        "filterType": "num",
        // "fieldType": "ShowData",
        "header": "St. Prop. ID",
        "displayInSettings": true,
        "filter": true,
        "sortable": true,
      },
      {
        "show": true,
        "textAlign": "left",
        "width": 80,
        "allowInt": true,
        "filterType": "num",
        "field": "propertyID",
        "mobile": true,
        "fieldType": "ShowData",
        "header": "OPP Prop. ID",
        "displayInSettings": true,
        "filter": true,
        "sortable": true,
      }, {
        "show": true,
        "textAlign": "left",
        "width": 80,
        "field": "alternateID",
        // "filterType": "num",
        "mobile": true,
        // "fieldType": "ShowData",
        "header": "Alternate ID",
        "displayInSettings": true,
        "filter": false,
        "sortable": true,
      }, {
        "show": true,
        "textAlign": "left",
        "width": 80,
        "field": "addressId",
        "mobile": true,
        "filterType": "num",
        // "fieldType": "ShowData",
        "header": "Address ID",
        "displayInSettings": true,
        "filter": true,
        "sortable": true,
      }, {
        "show": true,
        "textAlign": "left",
        "width": 80,
        "field": "nameId",
        "filterType": "num",
        "mobile": true,
        // "fieldType": "ShowData",
        "header": "Name ID",
        "displayInSettings": true,
        "filter": true,
        "sortable": true,
      },
      {
        "show": true,
        "textAlign": "left",
        "width": 110,
        "field": "propertyName",
        "isFuzzy": true,
        "mobile": true,
        "header": "Property Name",
        "displayInSettings": true,
        "filter": true,
        "sortable": true,
      }, {
        "show": true,
        "textAlign": "left",
        "width": 110,
        "field": "holderName",
        isFuzzy: true,
        "mobile": true,
        "header": "Holder Name",
        "displayInSettings": true,
        "filter": true,
        "sortable": true,
      }, {
        "show": true,
        "textAlign": "left",
        "width": 110,
        isFuzzy: true,
        "field": "propertyDescription",
        "mobile": true,
        "header": "Property Description",
        "displayInSettings": true,
        "filter": true,
        "sortable": true,
      },
      // {
      //   "show": true,
      //   "textAlign": "left",
      //   "width": 100,
      //   "field": "nameMatchScore",
      //   "mobile": true,
      //   "header": "Name Match score",
      //   "filter": false,
      //   "sortable": true,
      //   "displayInSettings": true,
      //   // "options": [{ "label": "Active", "value": 'Active' }, { "label": "Pending", "value": 'Pending' }, { "label": "Inactive", "value": 'Inactive' }],
      // },
      // {
      //   "show": true,
      //   "textAlign": "left",
      //   "width": 100,
      //   "field": "addressLine1MatchScore",
      //   "mobile": true,
      //   "header": "Add Line1 MatchScore",
      //   "displayInSettings": true,
      //   "filter": false,
      //   "sortable": true,
      // }, 
      {
        "show": true,
        "textAlign": "left",
        "width": 110,
        isFuzzy: true,
        "field": "businessAddressLine1",
        "mobile": true,
        "header": "Business Add Line1",
        "displayInSettings": true,
        "filter": true,
        "sortable": true,
      }, {
        "show": true,
        "textAlign": "left",
        "width": 110,
        isFuzzy: true,
        "field": "propertyAddressLine1",
        "mobile": true,
        "header": "Prop. Address Line1",
        "displayInSettings": true,
        "filter": true,
        "sortable": true,
      },
      {
        "show": true,
        "textAlign": "left",
        "width": 100,
        "field": "businessCity",
        "mobile": true,
        // "fieldType": "Link",
        "header": "Biz City",
        "displayInSettings": true,
        "filter": false,
        "sortable": true,
      }, {
        "show": true,
        "textAlign": "right",
        "width": 60,
        "field": "businessState",
        "mobile": true,
        "header": "Biz St",
        "filter": false,
        "sortable": true,
        "displayInSettings": true,
        // "options": [{ "label": "Active", "value": 'Active' }, { "label": "Pending", "value": 'Pending' }, { "label": "Inactive", "value": 'Inactive' }],
      }, {
        "show": true,
        "textAlign": "right",
        "width": 60,
        "field": "sourceState",
        "mobile": true,
        "header": "Src. St",
        "filter": true,
        "isFuzzy": true,
        "sortable": true,
        "displayInSettings": true,
        // "options": [{ "label": "Active", "value": 'Active' }, { "label": "Pending", "value": 'Pending' }, { "label": "Inactive", "value": 'Inactive' }],
      },
      {
        "show": true,
        "textAlign": "left",
        "width": 100,
        "field": "prospectiveStatus",
        "mobile": true,
        "header": "Prospective Status",
        "filter": true,
        "sortable": true,
        "displayInSettings": true,
        "isFuzzy": true
        // "options": [{ "label": "Active", "value": 'Active' }, { "label": "Pending", "value": 'Pending' }, { "label": "Inactive", "value": 'Inactive' }],
      },
      {
        "show": true,
        "textAlign": "left",
        "width": 100,
        "field": "businessZipCode",
        "mobile": true,
        "header": "Biz Zip",
        "filter": false,
        "sortable": true,
        "displayInSettings": true,
        // "options": [{ "label": "Active", "value": 'Active' }, { "label": "Pending", "value": 'Pending' }, { "label": "Inactive", "value": 'Inactive' }],
      },
      //  {
      //   "show": true,
      //   "textAlign": "left",
      //   "width": 100,
      //   "field": "createdBy",
      //   "mobile": true,
      //   "header": "Created By",
      //   "filter": false,
      //   "sortable": true,
      //   "displayInSettings": true,
      //   // "options": [{ "label": "Active", "value": 'Active' }, { "label": "Pending", "value": 'Pending' }, { "label": "Inactive", "value": 'Inactive' }],
      // },createdByUser
      {
        "show": true,
        "textAlign": "left",
        "width": 100,
        "field": "createdByUser",
        "mobile": true,
        "header": "Created By User",
        "filter": false,
        "sortable": true,
        "displayInSettings": true,
        // "options": [{ "label": "Active", "value": 'Active' }, { "label": "Pending", "value": 'Pending' }, { "label": "Inactive", "value": 'Inactive' }],
      },
      {
        "show": true,
        "textAlign": "left",
        "width": 60,
        "field": "propertyState",
        "mobile": true,
        "header": "Prop. St",
        "displayInSettings": true,
        "filter": false,
        "sortable": true,
      }, {
        "show": true,
        "textAlign": "left",
        "width": 110,
        "field": "propertyCity",
        "mobile": true,
        "header": "Prop. City",
        "displayInSettings": true,
        "filter": false,
        "sortable": true,
      },
      // {
      //   "show": true,
      //   "textAlign": "left",
      //   "width": 100,
      //   "field": "cityStateZipScore",
      //   "mobile": true,
      //   "header": "City St. Zip Score",
      //   "displayInSettings": true,
      //   "filter": false,
      //   "sortable": true,
      // },
      {
        "show": true,
        "textAlign": "left",
        "width": 80,
        "field": "propertyZipCode",
        "mobile": true,
        "header": "Prop. Zip",
        "displayInSettings": true,
        "filter": false,
        "sortable": true,
      },
      // {
      //   "show": true,
      //   "textAlign": "left",
      //   "width": 100,
      //   "field": "userEmailID",
      //   "mobile": true,
      //   // "fieldType": "Link",
      //   "header": "User Email",
      //   "displayInSettings": true,
      //   "filter": false,
      //   "sortable": true,
      // },
      {
        "show": true,
        "textAlign": "left",
        "width": 80,
        "filterType": "num",
        "field": "scoreDate",
        "mobile": true,
        "fieldType": "Date",
        "dateFormat": 'MM/DD/YYYY',
        "header": "Score Dt",
        "filter": false,
        "sortable": true,
        "displayInSettings": true,
      },
      {
        "show": false,
        "textAlign": "left",
        "width": 100,
        "field": "scoreUpdateDate",
        "mobile": true,
        "fieldType": "Date",
        "dateFormat": 'MM-DD-yyyy',
        "header": "Src Update Dt",
        "filter": false,
        "sortable": true,
        "displayInSettings": true,
      },
      {
        "show": true,
        "textAlign": "right",
        "width": 80,
        "field": "propertyMinValueAmount",
        "allowInt": true,
        "mobile": true,
        "header": "Min Amt",
        "filterType": "num",
        "fieldType": "formatCurrency",
        "filter": true,
        "sortable": true,
        "displayInSettings": true,
      },
      {
        "show": true,
        "textAlign": "right",
        "width": 80,
        "field": "propertyMaxValueAmount",
        "allowInt": true,
        "fieldType": "formatCurrency",
        "filterType": "num",
        "mobile": true,
        "header": "Max Amt",
        "displayInSettings": true,
        "filter": true,
        "sortable": true,
      },
      // {
      //   "show": true,
      //   "textAlign": "left",
      //   "width": 100,
      //   "field": "batchName",
      //   "mobile": true,
      //   "header": "Batch Name",
      //   "displayInSettings": true,
      //   "filter": false,
      //   "sortable": true,
      // },
      {
        "show": true,
        "textAlign": "left",
        "width": 100,
        "field": "propertyDataSource",
        isFuzzy: true,
        "mobile": true,
        // "fieldType": "Percentage",
        "header": " Prop. Source",
        "displayInSettings": true,
        "filter": true,
        "sortable": true,
      },
      {
        "show": true,
        "textAlign": "left",
        "width": 80,
        "field": "nameIssueCode",
        "mobile": true,
        // "filterType": "num",
        "fieldType": "nameIssue",
        "header": "Name Iss",
        "displayInSettings": true,
        "filter": true,
        "sortable": true,
      }, {
        "show": true,
        "textAlign": "left",
        "width": 80,
        "field": "newNameIssueCode",
        "mobile": true,
        // "filterType": "num",
        // "fieldType": "Percentage",
        "header": "New Name Iss",
        "displayInSettings": true,
        "filter": true,
        "sortable": true,
      }, {
        "show": true,
        "textAlign": "left",
        "width": 80,
        "field": "nameSource",
        "mobile": true,
        // "filterType": "num",
        // "fieldType": "Percentage",
        "header": "Name Src",
        "displayInSettings": true,
        "filter": true,
        "sortable": true,
      }, {
        "show": true,
        "textAlign": "left",
        "width": 220,
        "field": "matchMatchStatus",
        "filterType": "multiSelect",
        isFuzzy: true,
        "mobile": true,
        "header": "Old Status",
        "filterOptions": this.state.settingsData ? this.state.settingsData : [],
        "displayInSettings": true,
        "filter": true,
        "sortable": true,
      },
      {
        "show": true,
        "textAlign": "left",
        "width": 215,
        "field": "matchStatusReason",
        "mobile": true,
        "filterType": "multiSelect",
        "filterOptions": this.state.shownreasons,
        isFuzzy: true,
        // "fieldType": "Link",
        "header": "Old. Reason",
        "displayInSettings": true,
        "filter": true,
        "sortable": true,
      },
      {
        "show": true,
        "textAlign": "left",
        "width": 220,
        "field": "propertyStatus",
        "filterType": "multiSelect",
        isFuzzy: true,
        "mobile": true,
        "header": "Prop. Status",
        "filterOptions": this.state.propertyStatusArray ? this.state.propertyStatusArray : [],
        "displayInSettings": true,
        "filter": true,
        "sortable": true,
      },
      {
        "show": true,
        "textAlign": "left",
        "width": 215,
        "field": "propertyStatusReason",
        "mobile": true,
        "filterType": "multiSelect",
        "filterOptions": this.state.propertyStatusReasonArray ? this.state.propertyStatusReasonArray : [],
        isFuzzy: true,
        // "fieldType": "Link",
        "header": "Prop. Reason",
        "displayInSettings": true,
        "filter": true,
        "sortable": true,
      },
      {
        "show": true,
        "textAlign": "left",
        "width": 110,
        "field": "fileFor",
        "mobile": true,
        "header": "Mapped Name",
        "isFuzzy": true,
        "filter": true,
        "displayInSettings": true,
        "sortable": true,
      },
      {
        "show": true,
        "textAlign": "left",
        "width": 360,
        "field": "matchStatusNotes",
        "mobile": true,
        "fieldType": "Array",
        "filter": true,
        "openNotes": true,
        // "isFuzzy": true,
        "header": "Prop. Status Notes",
        "displayInSettings": true,
        // "filter": false,
        "sortable": true,
      },
      {
        "show": true,
        "textAlign": "left",
        "width": 110,
        "field": "claimID",
        "mobile": true,
        // "fieldType": "Link",
        isFuzzy: true,
        "header": "St.Claim ID",
        "displayInSettings": true,
        "filter": true,
        "sortable": true,
      },
      {
        "show": true,
        "textAlign": "left",
        "width": 115,
        "field": "propertyAlternateId",
        "filterType": "num",
        "mobile": true,
        "header": "Prop. Alt Id",
        "displayInSettings": true,
        "filter": false,
        "sortable": true,
      },
      // {
      //   "show": true,
      //   "textAlign": "left",
      //   "width": 115,
      //   "field": "created",
      //   "mobile": true,
      //   // "fieldType": "Link",
      //   "header": "Created",
      //   "displayInSettings": true,
      //   "filter": false,
      //   "sortable": true,
      // },
      {
        "show": true,
        "textAlign": "left",
        "width": 115,
        "field": "lastUpdatedBy",
        "mobile": true,
        "header": "Last Updated By",
        "displayInSettings": true,
        "filter": true,
        "sortable": true,
      }, {
        "show": true,
        "textAlign": "left",
        "width": 115,
        "field": "validatedBy",
        "mobile": true,
        "header": "Validated By",
        "displayInSettings": true,
        "filter": true,
        "sortable": true,
      }, {
        "show": true,
        "textAlign": "left",
        "width": 115,
        "field": "filedBy",
        "mobile": true,
        "header": "Filed By",
        "displayInSettings": true,
        "filter": true,
        "sortable": true,
      },
      {
        "show": true,
        "textAlign": "left",
        "width": 115,
        "field": "validatedDate",
        "filterType": "num",
        "fieldType": "Date",
        "dateFormat": 'MM/DD/YYYY',
        "mobile": true,
        "header": "Dt Validated",
        "displayInSettings": true,
        "filter": false,
        "sortable": true,
      },
      {
        "show": true,
        "textAlign": "left",
        "width": 115,
        "field": "createdDate",
        "filterType": "num",
        "fieldType": "Date",
        "dateFormat": 'MM/DD/YYYY',
        "mobile": true,
        "header": "Dt Created",
        "displayInSettings": true,
        "filter": false,
        "sortable": true,
      }, {
        "show": true,
        "textAlign": "left",
        "width": 115,
        "field": "dateIndexedInElastic",
        "filterType": "num",
        "fieldType": "Date",
        "dateFormat": 'MM/DD/YYYY',
        "mobile": true,
        "header": "Dt Indexed In Elastic",
        "displayInSettings": true,
        "filter": false,
        "sortable": true,
      }, {
        "show": true,
        "textAlign": "left",
        "width": 115,
        "field": "updatedDate",
        "filterType": "num",
        "fieldType": "Date",
        "dateFormat": 'MM/DD/YYYY',
        "mobile": true,
        "header": "Dt Updated",
        "displayInSettings": true,
        "filter": false,
        "sortable": true,
      }, {
        "show": true,
        "textAlign": "left",
        "width": 115,
        "field": "dateOfLastContact",
        "filterType": "num",
        "fieldType": "Date",
        "dateFormat": 'MM/DD/YYYY',
        "mobile": true,
        "header": "Dt Of Last Contact",
        "displayInSettings": true,
        "filter": false,
        "sortable": true,
      },
      {
        "show": true,
        "textAlign": "left",
        "width": 115,
        "field": "nameMatchScore",
        "allowInt": true,
        "filterType": "num",
        "mobile": true,
        "header": "Name Score",
        "displayInSettings": true,
        "filter": false,
        "sortable": true,
      }, {
        "show": true,
        "textAlign": "left",
        "width": 115,
        "field": "opraAddedDate",
        "filterType": "num",
        "fieldType": "Date",
        "dateFormat": 'MM/DD/YYYY',
        "mobile": true,
        "header": "Dt Opra Added",
        "displayInSettings": true,
        "filter": false,
        "sortable": true,
      }, {
        "show": true,
        "textAlign": "left",
        "width": 115,
        "field": "escheatmentDate",
        "filterType": "num",
        "fieldType": "Date",
        "dateFormat": 'MM/DD/YYYY',
        "mobile": true,
        "header": "Dt Escheatment",
        "displayInSettings": true,
        "filter": false,
        "sortable": true,
      },
      {
        "show": true,
        "textAlign": "left",
        "width": 115,
        "field": "addressLine1MatchScore",
        "allowInt": true,
        "filterType": "num",
        "mobile": true,
        "header": "Line1 Score",
        "displayInSettings": true,
        "filter": false,
        "sortable": true,
      },
      {
        "show": true,
        "textAlign": "left",
        "width": 115,
        "allowInt": true,
        "filterType": "num",
        "field": "propertyResidentialFlag",
        "mobile": true,
        "header": "Prop. Residential Flag",
        "displayInSettings": true,
        "filter": false,
        "sortable": true,
      },
      {
        "show": true,
        "textAlign": "left",
        "width": 115,
        "filterType": "num",
        "allowInt": true,
        "field": "cityStateZipScore",
        "mobile": true,
        "header": "CSZ Score",
        "displayInSettings": true,
        "filter": false,
        "sortable": true,
      },
      {
        "show": true,
        "textAlign": "left",
        "width": 115,
        "field": "propertyCoOwnerNames",
        // "allowInt": true,
        // "filterType": "num",
        "mobile": true,
        "header": "Co Owner Names",
        "displayInSettings": true,
        "filter": false,
        "sortable": true,
      },
      {
        "show": true,
        "textAlign": "left",
        "width": 115,
        "filterType": "dropdown",
        // "allowInt": true,
        "field": "hasPropertyCoOwner",
        "fieldType": "hasPropertyCoOwner",
        "mobile": true,
        "header": "Co-Owner",
        "displayInSettings": true,
        "filter": true,
        "sortable": true,
      },
      {
        "show": true,
        "textAlign": "left",
        "width": 115,
        "filterType": "dropdown",
        // "allowInt": true,
        "field": "confirmedOnsite",
        "fieldType": "confirmedOnSite",
        "mobile": true,
        "header": "Confirmed Onsite",
        "displayInSettings": true,
        "filter": true,
        "sortable": true,
      },
      {
        "show": true,
        "textAlign": "left",
        "width": 115,
        "field": "confirmedOnsiteDate",
        "filterType": "num",
        "fieldType": "Date",
        "dateFormat": 'MM/DD/YYYY',
        "mobile": true,
        "header": "Confirmed Onsite Date",
        "displayInSettings": true,
        "filter": false,
        "sortable": true,
      },
      {
        "show": true,
        "textAlign": "left",
        "width": 115,
        "field": "confirmedOnsiteBy",
        // "allowInt": true,
        // "filterType": "num",
        "mobile": true,
        "header": "Confirmed Onsite By",
        "displayInSettings": true,
        // "filter": true,
        "sortable": true,
      },





    ]
    return data;
  };


  getFormFields = () => {

    return
  }

  submit = async (item) => {

  }

  render() {
    return (
      <div>
        <Loader loader={this.state.isLoading} />
        <span>
          <DataTables
            onRef={(ref) => (this.datatableref = ref)}
            getTableFields={this.getTableFields}
            getSellerTableFields={this.getSellerTableFields}
            formFields={this.getFormFields}
            dontShowTitle={true}
            exportRequried={false}
            printRequried={false}
            addRequried={false}
            editRequired={true}
            frozen={true}
            sortField={"propertyMinValueAmount"}
            deleteRequired={false}
            viewRequired={true}
            settingsRequired={false}
            filterRequired={true}
            xlsxExport={true}
            gridRequried={false}
            sample={false}
            globalSearchFieldName='user'
            globalSearch={''}
            type={"Deal Statistics"}
            displayright={true}
            icon='faCity'
            scrollHeight={"580px"}
            activeTab={"Summary"}
            isClearFilters={this.state.tabType === "Data" ? true : false}
            tabType={true}
            tabOptions={["Summary", "Data"]}
            fontAwesome={true}
            routeTo='dealstatistics'
            propertyStatusArray={this.state.propertyStatusArray}
            propertyStatusReasonArray={this.state.propertyStatusReasonArray}
            displayViewOfForm='modal'
            apiResponseKey={this.state.responseKey}
            className={true}
            settingsData={this.state.settingsData}
            shownreasons={this.state.shownreasons}
            // isShownnFieldType={true}
            apiUrl={this.state.apiUrl}
            entityType='employee'
          />
          {this.state.isOpenMatchModel ? this.getOpenPropertyMatchModel() : null
          }
        </span>
      </div>
    );
  }
}

// https://opraapi2.dosystemsinc.com/api/nsScoredProperties/DealStatisticsSummary?filter={criteria:[{key