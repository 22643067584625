import React from 'react';
import { Container } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import RFIView from './components/RFIView';
const rfiView = ({ t }) => (
  <Container>
    <RFIView />
  </Container>
);

rfiView.propTypes = {
  t: PropTypes.func.isRequired,
};

export default withTranslation('common')(rfiView);
