import React from 'react';
import { withTranslation } from 'react-i18next';
import { Field, reduxForm } from 'redux-form';
import ConfirmationModal from '../../containers/Cruds/CommonModals/ConfirmationModal';
import Moment from 'moment-timezone';
import fetchMethodRequest from '../../config/service';
import { Col, Container, Row, Button } from 'reactstrap';
import { Dropdown } from 'primereact/dropdown';
// import { RadioButton } from 'primereact/radiobutton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faStarOfLife } from '@fortawesome/free-solid-svg-icons';
import apiCalls from '../../config/apiCalls';

class StateUP extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpenConfirmationModal: false,
      categories: [
        // { label: "Claim Filing", value: "Claim Filing" },
        // { label: "Claim Generation", value: "Claim Generation" },
        // { label: "State Correspondence", value: "State Correspondence" },
      ],
      subcategories: [],
      claimFiling: [
        { label: "Co-Owner Filing", value: "Co-Owner Filing" },
        { label: "Cover Letter", value: "Cover Letter" },
        { label: "Online Claim Submission", value: "Online Claim Submission" },
      ],
      ClaimGeneration: [
        { label: "Claim Form Completion", value: "Claim Form Completion" },
        { label: "Claim Form Generation", value: "Claim Form Generation" },
        { label: "Packet Generation", value: "Packet Generation" },
      ],
      StateCorrespondence: [
        { label: "Correspondence Restrictions", value: "Correspondence Restrictions" },
        { label: "Online Status Check", value: "Online Status Check" },
      ],
      dealTypes: [
        { label: "BK", value: "BK" },
        { label: "CR", value: "CR" },
      ],
      States: [
        { label: "No State", value: "No State" },
        { label: "AL", value: "AL" },
        { label: "AK", value: "AK" },
        { label: "AZ", value: "AZ" },
        { label: "AR", value: "AR" },
        { label: "CA", value: "CA" },
        { label: "CO", value: "CO" },
        { label: "CT", value: "CT" },
        { label: "DE", value: "DE" },
        { label: "FL", value: "FL" },
        { label: "GA", value: "GA" },
        { label: "HI", value: "HI" },
        { label: "ID", value: "ID" },
        { label: "IL", value: "IL" },
        { label: "IN", value: "IN" },
        { label: "IA", value: "IA" },
        { label: "KS", value: "KS" },
        { label: "KY", value: "KY" },
        { label: "LA", value: "LA" },
        { label: "ME", value: "ME" },
        { label: "MD", value: "MD" },
        { label: "MA", value: "MA" },
        { label: "MI", value: "MI" },
        { label: "MN", value: "MN" },
        { label: "MS", value: "MS" },
        { label: "MO", value: "MO" },
        { label: "MT", value: "MT" },
        { label: "NE", value: "NE" },
        { label: "NV", value: "NV" },
        { label: "NH", value: "NH" },
        { label: "NJ", value: "NJ" },
        { label: "NM", value: "NM" },
        { label: "NY", value: "NY" },
        { label: "NC", value: "NC" },
        { label: "ND", value: "ND" },
        { label: "OH", value: "OH" },
        { label: "OK", value: "OK" },
        { label: "OR", value: "OR" },
        { label: "PA", value: "PA" },
        { label: "PR", value: "PR" },
        { label: "RI", value: "RI" },
        { label: "SC", value: "SC" },
        { label: "SD", value: "SD" },
        { label: "TN", value: "TN" },
        { label: "TX", value: "TX" },
        { label: "UT", value: "UT" },
        { label: "VT", value: "VT" },
        { label: "VA", value: "VA" },
        { label: "DC", value: "DC" },
        { label: "WA", value: "WA" },
        { label: "WV", value: "WV" },
        { label: "WI", value: "WI" },
        { label: "WY", value: "WY" },
      ],
      selectedcategory: "",
      selecteddealtype: "",
      selectedsubcategory: "",
      selectedState: "",
      loginData: {},
      attributeFields: []
    };
  }

  componentDidUpdate() {
    if (this.state.errorResponse) {
      setTimeout(() => this.setState({ errorResponse: false, errorMessage: '' }), 30000);
    }
  }

  componentDidMount = async () => {
    let user = JSON.parse(localStorage.getItem('loginCredentials'));
    if (user) {
      this.setState({
        loginData: user,
        role: user.role
      })
    }
    await this.getcategoriesOptions()
  }

  setCategory = async (e) => {
    await this.setState({
      selectedcategory: e,
      selectedsubcategory: "",
      attributeFields: []
    })
    await this.getsubCategoriesOptions()
  }

  getcategoriesOptions = () => {
    let filter = {}
    filter.criteria = [{ key: "activeIndicator", value: true, type: "eq" }]
    fetchMethodRequest('GET', `${apiCalls.Category}?filter=${JSON.stringify(filter)}`)
      .then(async (response) => {
        if (response) {
          let labels = response.categories;
          let modifiedCategories = [];
          for (let label of labels) {
            modifiedCategories.push({
              label: label.categoryName,
              value: label.categoryName,
            })
          }
          await this.setState({
            categories: modifiedCategories
          })
          // this.setUnitName()

        }
      }).catch((err) => {
        return err;
      });
  }
  getsubCategoriesOptions = () => {
    let filter = {}
    filter.criteria = [{ key: "categoryName", value: this.state.selectedcategory, type: "eq" }, { key: "activeIndicator", value: true, type: "eq" }]
    fetchMethodRequest('GET', `${apiCalls.SubCategory}?filter=${JSON.stringify(filter)}`)
      .then(async (response) => {
        if (response) {
          let labels = response.subCategories;
          let modifiedSubCategories = [];
          for (let label of labels) {
            modifiedSubCategories.push({
              label: label.subCategoryName,
              value: label.subCategoryName,
            })
          }
          await this.setState({
            subcategories: modifiedSubCategories
          })
          // this.setUnitName()

        }
      }).catch((err) => {
        return err;
      });
  }

  submit = async (values) => {
    let arraylist = []
    let array = this.state.attributeFields
    for (let i = 0; i < array.length; i++) {
      arraylist.push({
        question: array[i][array[i].name],
        answer: array[i][array[i].value],
        isActive: array[i][array[i].isActive] === "Yes" ? true : false,
        type: array[i][array[i].type],
        createdBy: array[i]["createdBy"],
        created: array[i]["created"],
        updatedBy: array[i]["updatedBy"],
        updated: array[i]["updated"],
      })
    }

    let obj = {
      "state": this.state.selectedState,
      "dealType": this.state.selecteddealtype,
    }
    obj.attributes = arraylist
    let method, url;
    await this.getFilingData()
    if (this.state.editedindex !== null) {
      method = "PUT"
      url = `StateUpFiling/${this.state.editedindex}`
    } else {
      method = "POST"
      url = `StateUpFiling`
    }
    return fetchMethodRequest(method, url, obj)
      .then(async (response) => {
        if (response) {
          this.getErrorMessage(response.respMessage, "success")
        } else if (response && response.errorMessage) {
          this.getErrorMessage(response.errorMessage, "error")
        }

      }).catch((err) => {
        return err;
      });
  }

  clearAttributesData = async () => {
    await this.setState({
      selectedcategory: "",
      selectedsubcategory: "",
      selectedState: "",
      selecteddealtype: ""
    })
    this.props.showTable()
  }
  getFilingData = async (type) => {
    let filter = { criteria: [] }
    // if (this.state.selectedsubcategory) {

    filter.criteria = [
      { key: "state", value: this.state.selectedState, type: "eq", addFilter: true },
      { key: "dealType", value: this.state.selecteddealtype, type: "eq", addFilter: true }]

    this.props.showTable("false")
    this.props.getFilteredValues(filter, this.state.selectedsubcategory, this.state.selectedcategory, this.state.subcategories, this.state.selecteddealtype, this.state.selectedState, type)

  }

  addRow = async (index) => {
    let attributeFields = this.state.attributeFields
    attributeFields.push({
      name: `attributequestion${index}`,
      value: `attributeanswer${index}`,
      type: `attributetype${index}`,
      isActive: `attributeisActive${[index]}`,
      [`attributetype${index}`]: "Text",
      [`attributeisActive${[index]}`]: "No"
    })
    await this.setState({
      attributeFields: attributeFields
    })
  }

  closeConfirmationModal = async (type) => {
    if (type === "close") {
      this.adduserSelectedField(this.state.fieldType, this.state.index)
      await this.setState({
        isOpenConfirmationModal: false,
        fieldType: "",
        index: "",
      })
    } else {
      await this.setState({
        isOpenConfirmationModal: false,
        fieldType: "",
        index: "",
      })
    }
  }

  getConfirmaionModel() {
    return (
      <ConfirmationModal
        openConfirmationModal={this.state.isOpenConfirmationModal}
        closeConfirmationModal={this.closeConfirmationModal}
        shownText={"Are you sure to change the Attribute Type? If Yes, then the existing values for this attribute will be lost."}
        confirm={this.saveValidatedRecord}
      />
    )
  }
  isOpenConfirmationModal = async (fieldType, index) => {
    let attributeFields = this.state.attributeFields
    let isExits = attributeFields[index][`attributetype${index}`]

    if (isExits !== undefined) {
      await this.setState({
        isOpenConfirmationModal: true,
        fieldType: fieldType,
        index: index
      })
    } else {
      this.adduserSelectedField(fieldType, index)
    }
  }


  adduserSelectedField = async (fieldType, index, isActive) => {

    let attributeFields = this.state.attributeFields

    attributeFields[index][`attributeanswer${index}`] = ""
    attributeFields[index][`attributetype${index}`] = fieldType

    await this.setState({
      attributeFields: attributeFields
    })
  }

  onChangeValue = (item, value, index) => {
    if (value && value.name) {
      index = value.name.splice(0, -1)
    }
    let attributeFields = this.state.attributeFields
    let loginData = this.state.loginData
    let todayDate = new Date().toLocaleDateString('zh-Hans-CN', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
    })
    if (attributeFields[index][`attributetype${index}`] === "Date" && value === `attributeanswer${index}`) {
      // if (item && item.value) {
      attributeFields[index][value] = Moment(item).format('MM/DD/YYYY');
      // }
    } else {
      attributeFields[index][value] = item
    }
    if (attributeFields[index]["createdBy"] && attributeFields[index]["createdBy"].length > 0) {
      attributeFields[index]["updatedBy"] = loginData.displayName
      attributeFields[index]["updated"] = todayDate
    } else {
      attributeFields[index]["createdBy"] = loginData.displayName
      attributeFields[index]["created"] = todayDate
    }

    this.setState({
      attributeFields: attributeFields
    })
  }

  onDragEnd = async (result) => {
    let attributeFields = this.state.attributeFields
    let sourceObj = this.state.attributeFields[result.source.index]
    let destinationObj = this.state.attributeFields[result.destination.index]

    attributeFields[result.source.index] = destinationObj
    attributeFields[result.destination.index] = sourceObj

    this.setState({
      attributeFields: attributeFields
    })
    // dropped outside the list
    // if (!result.destination) {
    //     return;
    // }
  }

  getattributesData = async () => {
    // if (this.state.selectedsubcategory.length > 0 && this.state.selectedcategory.length > 0 &&
    //   this.state.selectedState.length > 0 && this.state.selecteddealtype.length > 0) {
    //   let obj = {
    //     "subCategory": this.state.selectedsubcategory,
    //     "category": this.state.selectedcategory,
    //     "state": this.state.selectedState,
    //     "dealType": this.state.selecteddealtype,
    //     attributes: this.state.attributeFields
    //   }
    await this.getFilingData("exists")
    //   let method, url;
    //   if (this.state.editedindex !== null) {
    //     let attributeFields = this.state.attributeFields
    //     this.setState({
    //       attributeFields: attributeFields
    //     })
    //     obj.attributes = this.state.attributeFields
    //     console.log("Record with this combination is already exists",)
    //     this.getErrorMessage("Record with this combination is already exists", "Error")
    //   } else {
    //     method = "POST"
    //     url = `StateUpFiling`
    //     return fetchMethodRequest(method, url, obj)
    //       .then(async (response) => {
    //         if (response && response.respCode === 200) {
    //           this.getErrorMessage(response.respMessage, "success")
    //         } else if (response && response.errorMessage) {
    //           this.getErrorMessage(response.errorMessage, "error")

    //           // this.setState({
    //           //   isUserUpdated: true
    //           // })
    //           // showToasterMessage(response.errorMessage, 'error');
    //         }
    //       }).catch((err) => {
    //         return err;
    //       });
    //   }

    // } else {
    //   this.getErrorMessage("Please select all the fields to get Attributes", "error")
    // }
  }
  //getting Error Message from formModa;
  getErrorMessage = (errorMessage, type) => {
    if (errorMessage && errorMessage.length > 0) {
      this.setState({
        errorMessage: errorMessage,
        errorResponse: true,
        errorcolor: type === "success" ? "green" : "red"
      })
    } else {
      this.setState({
        errorMessage: "",
        errorResponse: false
      })
    }
  }

  cancelAll = async () => {
    await this.setState({
      errorMessage: "",
      errorResponse: false,
      selectedsubcategory: "",
      selecteddealtype: "",
      selectedState: "",
      selectedcategory: "",
      attributeFields: [],
      subcategories: []
    })
  }

  render() {
    const { handleSubmit } = this.props;
    return (
      <Container className=" "  >
        <Row className="mt-2">
          <Col sm={4} className="d-flex">
            <label style={{ width: "30%", display: "flex" }}>Deal Type<FontAwesomeIcon icon={faStarOfLife}
              className='pl-1'
              color='red'
              data-toggle="tool-tip"
              title="Mandotary Field"
              style={{ width: 8 }}
            />

            </label>
            <Dropdown
              style={{ marginLeft: "12px", lineHeight: 2.1, width: "100%" }}
              name={"dealType"}
              value={this.state.selecteddealtype}
              options={this.state.dealTypes}
              onChange={(e) => this.setState({ selecteddealtype: e.value, attributeFields: [] })}
            />
          </Col>
          <Col sm={4} className="d-flex">
            <label style={{ width: "30%", display: "flex" }}>State
              <FontAwesomeIcon icon={faStarOfLife}
                className='pl-1'
                color='red'
                data-toggle="tool-tip"
                title="Mandotary Field"
                style={{ width: 8 }}
              /> </label>
            <Dropdown
              style={{ marginLeft: "12px", lineHeight: 2.1, width: "100%" }}
              // placeholder={"Exhibit Type"}
              // appendTo={document.body}
              name={"state"}
              value={this.state.selectedState}
              options={this.state.States}
              onChange={(e) => this.setState({ selectedState: e.value, attributeFields: [] })}
            />
          </Col>
          <Col>
            <Button
              color='primary'
              className="ml-auto mb-0"
              outline
              style={this.state.selectedState && this.state.selecteddealtype ? { color: "white", backgroundColor: '#354f6e', width: 115 } : { color: "white", backgroundColor: '#a0a2a5', width: 115 }}
              // disabled={(this.state.selectedSeller || this.state.Property_Id || this.state.OpraStId) ? false : true}
              disabled={this.state.selectedState && this.state.selecteddealtype ? false : true}

              onClick={() => this.getattributesData()}>
              Go
            </Button>
            <Button
              color='primary'
              className="ml-auto mb-0"
              outline
              // style={this.state.selectedState && this.state.selectedcategory && this.state.selecteddealtype ? { color: "white", backgroundColor: '#354f6e', width: 115 } : { color: "white", backgroundColor: '#a0a2a5', width: 115 }}
              // disabled={(this.state.selectedSeller || this.state.Property_Id || this.state.OpraStId) ? false : true}
              // disabled={this.state.selectedState && this.state.selectedcategory && this.state.selecteddealtype ? false : true}

              onClick={() => this.clearAttributesData()}>
              Clear
            </Button>
            <Button
              color='primary'
              className="ml-auto mb-0  mr-2"
              outline
              // style={this.state.selectedState && this.state.selectedcategory && this.state.selecteddealtype ? { color: "white", backgroundColor: '#354f6e', width: 115 } : { color: "white", backgroundColor: '#a0a2a5', width: 115 }}
              // disabled={(this.state.selectedSeller || this.state.Property_Id || this.state.OpraStId) ? false : true}
              // disabled={this.state.selectedState && this.state.selectedcategory && this.state.selecteddealtype ? false : true}
              onClick={() => {this.props.toggleSideBar(true,'Add',this.props.filterCriteria)}}>

              ADD
            </Button>
            <Button
              color='primary'
              className="ml-auto mb-0 float-right"
              outline
              // style={this.state.selectedState && this.state.selectedcategory && this.state.selecteddealtype ? { color: "white", backgroundColor: '#354f6e', width: 115 } : { color: "white", backgroundColor: '#a0a2a5', width: 115 }}
              // disabled={(this.state.selectedSeller || this.state.Property_Id || this.state.OpraStId) ? false : true}
              // disabled={this.state.selectedState && this.state.selectedcategory && this.state.selecteddealtype ? false : true}
              onClick={() => this.getFilingData("clear")}>

              Clear Column Filters
            </Button>
          </Col>
        </Row>
        {/* {this.state.errorResponse ? <p>{this.state.errorMessage}</p> : null} */}

        {/* <Row style={{ width: "95%" }}>
          <Button
            color='primary'
            className="ml-auto mt-3"
            outline
            onClick={() => this.addRow(this.state.attributeFields.length)}>
            <FontAwesomeIcon
              style={{ width: "15px" }}
              icon={faPlus}
              onClick={() => this.addRow(this.state.attributeFields.length)}
            />
            Add Attribute
          </Button>
          <Button
            color='primary'
            className="ml-2 mt-3"
            outline
            onClick={() => this.cancelAll()}>

            Clear
          </Button>
          {this.state.attributeFields.length > 0 ?
            <Button
              style={{ float: "right", height: 34 }}
              color='primary'
              className={"mb-0 mt-3"}
              outline
              onClick={() => this.submit(this.state.attributeFields.length)}>
              {'Save'}
            </Button>
            : null}
        </Row> */}

        {this.state.isOpenConfirmationModal ?
          this.getConfirmaionModel() : null
        }
      </Container >
    )
  };


}

// Decorate with reduxForm(). It will read the initialValues prop provided by connect()
StateUP = reduxForm({
  form: "StateUP Form", // a unique identifier for this form
  enableReinitialize: true,
})(StateUP);

export default withTranslation('common')(StateUP);
