import React from 'react';
import { Button, Modal, ModalBody } from 'reactstrap';
import { withTranslation } from 'react-i18next';


// config file
class isApplyFilter extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  deleteSelectedRow = () => {
    this.props.onFilterChange(this.props.filterInput)
    let fieldName = Object.keys(this.props.filterInput.filters)[0];
    this.props.closeConfirmationModal(fieldName, "clear")
  }

  render() {
    const { t } = this.props
    return (
      <div>
        <Modal isOpen={this.props.openConfirmationModal} fade={false} className='deleteModalHeight'>
          <ModalBody className='deleteModalBody'>
            <div style={{ marginBottom: 10 }}> {"Are you sure you want to continue with empty data"}?</div>
            <Button color="primary" outline onClick={() => this.props.closeConfirmationModal()} className='deleteModalBtn marginRight'>{t('No')}</Button>
            <Button color="primary" outline onClick={this.deleteSelectedRow}
              className='deleteModalBtn'>{t('Yes')}</Button>
          </ModalBody>
        </Modal>
      </div >
    );
  }
}
export default withTranslation('common')(isApplyFilter);
