import React from 'react';
import { Field, reduxForm } from 'redux-form';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import config from '../../../../config/config'
import RenderFileInputField from '../../../Form/components/FileUpload';
import apiCalls from '../../../../config/apiCalls';
import fetch from '../../../../config/service'
import { withTranslation } from 'react-i18next';


// config file
class UploadFiles extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      removedFiles: [],
    };
  }

  componentWillUnmount() {
    if (this.props.onRef) {
      this.props.onRef(null);
    }
    
  }

  componentWillMount() {
    if (this.props.onRef) {
      this.props.onRef(null);
    }

  }

  componentDidMount = async () => {
    if (this.props.onRef) {
      this.props.onRef(this);
    }
    let data = document.getElementsByClassName("p-fileupload-buttonbar");
    if (data) {
      data[0].children[1].style.display="none"
    }
  }

  uploadProgress = async (value) => {
    await this.setState({ isLoading: value })
    if (value && value === true) {
      // this.handlePercentage('1')
    }
  }

  onCancelDocuments = async () => {
    let responseDocuments = this.props.responsesdocuments
    if (responseDocuments && responseDocuments.length > 0) {
      this.deleteImagesCall(responseDocuments, 'cancelDocuments')
    }
  }

  deleteImagesCall = async (values, type) => {
    let body = {}
    if (type) {
      body.fileNames = values
      await this.setState({ isLoading: false, responsesdocuments: [] })
    } else {
      body.fileNames = this.state.removedFiles
    }

    fetch('POST', 'uploads/delete?uploadPath=task', body)
      .then(async (response) => {
        if (response && response.respCode) {
          if (!type) this.props.saveDataFormat(values)
        } else if (response && response.errorMessage) {
          this.getErrorMessage(response.errorMessage)
        }
      }).catch((err) => {
        return err;
      });
  }

  removeMultipleUploadedImages = async (item, index) => {
    var removedFiles = this.state.removedFiles;
    var documents = this.props.documents;
    removedFiles.push(item);
    documents.splice(index, 1);
    this.setState({
      removedFiles: removedFiles,
      documents: documents
    })
    this.props.setRemovedFiles(documents, removedFiles)
  }

  getFileName = async (responses, type) => {
    var responsesdocuments = [];
    for (var file of responses) {
      responsesdocuments.push(file.fileName)
    }
    if (this.props.submitedValues && Object.keys(this.props.submitedValues).length > 0) {
      this.props.saveDataFormat(this.props.submitedValues, responsesdocuments)
    }
  }


  render() {
    const {  documents } = this.props
    return (
      <div className='w-100'>
        { documents && documents.length > 0 ?
          <div className='col-sm-12 ' 
          // style={type === "add" || (type === "edit" && (showFields.length === 0 || showFields.notesHistory === "show")) ? { display: "block" } : { display: "none" }}
          >
            <div className="form__form-group" >
              <div className="d-flex" style={{ alignItems: "center" }}>
                <label className="form__form-group-label mr-auto">Existed Documents({documents && documents.length > 0 ? documents.length : "0"})</label>
              </div>
              <div className="getFilesContainer scroll_filesContainer">
                {documents && documents.length > 0 ? documents.map((imagLocationItem, imagLocationIndex) => {
                  imagLocationItem = imagLocationItem.slice(16);
                  return <div className='d-flex' data-toggle="tool-tip"
                  title={imagLocationItem} style={{ padding: '5px', justifyContent: " space-between" }}>
                    <a className='fileNamestyled' style={{zIndex:0}}href={`${config.multipleimgUrl}task/${documents[imagLocationIndex]}`} target="_blank" download>{imagLocationItem}</a>
                    <FontAwesomeIcon icon={faTimes} className='timesCircleIcon errorColor' style={{ margin: 10 }}
                      onClick={() => this.removeMultipleUploadedImages(imagLocationItem, imagLocationIndex)}
                    />
                  </div>
                }) : null}

              </div>
            </div>
          </div>
          : null}
        <div>
          <Field
            name="image"
            component={RenderFileInputField}
            onRef={(ref) => (this.profileUploadRef = ref)}
            url={apiCalls.ProfileImagePath}
             label='Attach File'
            type='profile'
            multiple={true}
            customUpload={true}
            onCancelDocuments={this.onCancelDocuments}
            // maxFileSize={5000000}
            accept="image,application/pdf,application/vnd.ms-excel"
            getFileName={this.getFileName}
            uploadProgress={this.uploadProgress}
          />

        </div>
      </div>

    );
  }
}
export default withTranslation('common')(UploadFiles);
