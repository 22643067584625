import React from 'react';
import { Container } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import AvailableToFile from './components/AvailableToFile';
const availableToFile = ({ t }) => (
  <Container>
    <AvailableToFile />
  </Container>
);

availableToFile.propTypes = {
  t: PropTypes.func.isRequired,
};

export default withTranslation('common')(availableToFile);
