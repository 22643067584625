import React, { useCallback, useEffect, useState } from 'react';
import { PageState } from 'primereact/paginator';
import apiCalls from '../../../../../src/config/apiCalls';
import fetchMethodRequest from '../../../../../src/config/service';
import { OpraDataTable, OpraDataTableColumn, PaginationInfo } from '../../../Components/OPRA Grid/Opra Data Table/OpraDataTable';
import config from '../../../../config/config';

type Jurisdiction = {
  code: string;
  name: string;
  country: string;
  full_name: string;
}

type JurisdictionObjectWrapper = {
  jurisdiction: Jurisdiction;
};

type Jurisdictions = {
  jurisdictions: JurisdictionObjectWrapper[];
}

type RegisteredAddress = {
  street_address: string;
  locality: string;
  region: string;
  postal_code: string;
  country: string;
};

type Source = {
  publisher: string;
  url: string;
  retrieved_at: Date;
  terms?: string;
};

type PreviousNames = {
  company_name: string;
  end_date: Date;
  uid: string;
};

type Company = {
  company_number: string;
  name: string;
  jurisdiction_code: string;
  incorporation_date: Date;
  dissolution_date: Date,
  company_type: string,
  registry_url: string;
  branch: string,
  branch_status: string,
  inactive: String;
  current_status: string;
  created_at: Date;
  updated_at: Date;
  retrieved_at: Date;
  opencorporates_url: string;
  previous_names: PreviousNames[];
  source: Source;
  registered_address: RegisteredAddress;
  registered_address_in_full: string;
  industry_codes: any[];
  restricted_for_marketing: any;
  native_company_number: any;
  //        id?: number;
};
type Officer = {
  name: string;
  inactive: String;
  jurisdiction_code: string;
  company_number: string;
  company: any;
  incorporation_date: Date;
  dissolution_date: Date,
  company_type: string,
  registry_url: string;
  branch: string,
  branch_status: string,

  current_status: string;
  created_at: Date;
  updated_at: Date;
  retrieved_at: Date;
  opencorporates_url: string;
  previous_names: PreviousNames[];
  source: Source;
  registered_address: RegisteredAddress;
  registered_address_in_full: string;
  industry_codes: any[];
  restricted_for_marketing: any;
  native_company_number: any;
}
type CompanyObjectWrapper = {
  company: Company;
};
type OfficerObjectWrapper = {
  officer: Officer;
};

type CompanySearch = {
  companies: CompanyObjectWrapper[];
  page: number;
  per_page: number;
  total_pages: number;
  total_count: number;
};
type OfficerSearch = {
  officers: OfficerObjectWrapper[];
  page: number;
  per_page: number;
  total_pages: number;
  total_count: number;
};

type OpenCorporatesResponses = {
  api_version: string;
  results: Jurisdictions | CompanySearch | OfficerSearch;
};


type StateSelectOption = {
  state: string;
  juriCode: string;
};

type AccountStatus = {
  results: {
    account_status: {
      plan: string;
      expiry_date: Date;
      status: string;
      usage: {
        today: number;
        this_month: number;
      };
      calls_remaining: {
        today: number;
        this_month: number;
      }
      surge_enabled: boolean;
    };
  };
};


export const OpenCorporates = () => {
  const type: string = 'Open Corporates';
  const objectArraysToDefineHeadersFor = ["previous_names", "industry_codes"]; //the properties in the response data that are objects which also contain arrays that need to be flattened out.
  const apiToken = config.openCorporatesApiToken;
  const removeHeaders = ["previous_names1_start_date", "previous_names3_company_name", "restricted_for_marketing", "registered_address", "previous_names2_company_name", "registered_address_postal_code", "registered_address_country", "registered_address_locality", "registered_address_region", "registered_address_street_address", "native_company_number", "branch_status", "created_at", "updated_at", "retrieved_at", "source_publisher", "source_retrieved_at", "source_url"]; //the properties in the response data that are objects which also contain arrays that need to be flattened out.
  const freezeRow: string = 'name';
  const freezeRowJDX: string = "JDX"
  const filterThroughApi = true
  const needToolTip = true
  const [companies, setCompanies] = useState<Company[]>();
  const [officers, setOfficers] = useState<Officer[]>();
  const [search, setSearch] = useState<boolean>(false);
  const [apiError, setApiError] = useState<undefined | string>();
  const [searchValue, setSearchValue] = useState<undefined | string>();
  const [loading, setLoading] = useState<boolean>(false);
  const [officeType, setOfcType] = useState<undefined | string>();
  const [paginationInfo, setPaginationInfo] = useState<PaginationInfo | undefined>();
  const [first, setFirst] = useState<number>(0);
  const [selectedState, setSelectedState] = useState<StateSelectOption | undefined>();
  const [gettingDataProgress, setGettingDataProgress] = useState<number>();
  const [apiCallsRemaining, setApiCallsRemaining] = useState<string>();
  const [exportPagesThatFailed, setExportPagesThatFailed] = useState<number[]>();

  const customGridColumns: Map<string, OpraDataTableColumn> = new Map([

    [
      "name", {
        width: ("name".length * 7.5 + 75) * 3,
        location: 1
      }
    ],
    [
      "company_number", {
        location: 3
      }
    ],
    [
      "jurisdiction_code", {
        location: 2
      }
    ],
    [
      "current_status", {
        location: 4
      }
    ],
    [
      "inactive", {
        location: 5
      }
    ],
    [
      "company_type", {
        location: 7
      }
    ],
    [
      "source_publisher", {
        location: 7
      }
    ],
    [
      "source_url", {
        location: 10
      }
    ],

    [
      "dissolution_date", {
        location: 8
      }
    ],
    [
      "incorporation_date", {
        location: 9
      }
    ],
    [
      "previous_names1_company_name", {
        location: 11
      }
    ],
    [
      "previous_names2_company_name", {
        location: 12
      }
    ],
    [
      "previous_names3_company_name", {
        location: 13
      }
    ],

    [
      "registry_url", {
        location: 14
      }
    ],
    [
      "branch", {
        location: 6
      }
    ],
    [
      "openCorporates_url", {
        location: 15
      }
    ],
  ]);

  const usJurisdictionCodes: string = "us_ak|us_al|us_ar|us_az|us_ca|us_co|us_ct|us_dc|us_de|us_fl|us_ga|us_hi|us_ia|us_id|us_il|us_in|us_ks|us_ky|us_la|us_ma|us_md|us_me|us_mi|us_mn|us_mo|us_ms|us_mt|us_nc|us_nd|us_ne|us_nh|us_nj|us_nm|us_nv|us_ny|us_oh|us_ok|us_or|us_pa|us_ri|us_sc|us_sd|us_tn|us_tx|us_ut|us_va|us_vt|us_wa|us_wi|us_wv|us_wy";

  const usJurisdictionCodesValueArr = [
    {
      state: "All States",
      juriCode: "us_ak|us_al|us_ar|us_az|us_ca|us_co|us_ct|us_dc|us_de|us_fl|us_ga|us_hi|us_ia|us_id|us_il|us_in|us_ks|us_ky|us_la|us_ma|us_md|us_me|us_mi|us_mn|us_mo|us_ms|us_mt|us_nc|us_nd|us_ne|us_nh|us_nj|us_nm|us_nv|us_ny|us_oh|us_ok|us_or|us_pa|us_ri|us_sc|us_sd|us_tn|us_tx|us_ut|us_va|us_vt|us_wa|us_wi|us_wv|us_wy"
    },
    {
      state: "AK",
      juriCode: "us_ak"
    },
    {
      state: "AL",
      juriCode: "us_al"
    },
    {
      state: "AR",
      juriCode: "us_ar"
    },
    {
      state: "AZ",
      juriCode: "us_az"
    },
    {
      state: "CA",
      juriCode: "us_ca"
    },
    {
      state: "CO",
      juriCode: "us_co"
    },
    {
      state: "CT",
      juriCode: "us_ct"
    },
    {
      state: "DC",
      juriCode: "us_dc"
    },
    {
      state: "DE",
      juriCode: "us_de"
    },
    {
      state: "FL",
      juriCode: "us_fl"
    },
    {
      state: "GA",
      juriCode: "us_ga"
    },
    {
      state: "HI",
      juriCode: "us_hi"
    },
    {
      state: "IA",
      juriCode: "us_ia"
    },
    {
      state: "ID",
      juriCode: "us_id"
    },
    {
      state: "IL",
      juriCode: "us_il"
    },
    {
      state: "IN",
      juriCode: "us_in"
    },
    {
      state: "KS",
      juriCode: "us_ks"
    },
    {
      state: "KY",
      juriCode: "us_ky"
    },
    {
      state: "LA",
      juriCode: "us_la"
    },
    {
      state: "MA",
      juriCode: "us_ma"
    },
    {
      state: "MD",
      juriCode: "us_md"
    },
    {
      state: "ME",
      juriCode: "us_me"
    },
    {
      state: "MI",
      juriCode: "us_mi"
    },
    {
      state: "MN",
      juriCode: "us_mn"
    },
    {
      state: "MO",
      juriCode: "us_mo"
    },
    {
      state: "MS",
      juriCode: "us_ms"
    },
    {
      state: "MT",
      juriCode: "us_mt"
    },
    {
      state: "NC",
      juriCode: "us_nc"
    },
    {
      state: "ND",
      juriCode: "us_nd"
    },
    {
      state: "NE",
      juriCode: "us_ne"
    },
    {
      state: "NH",
      juriCode: "us_nh"
    },
    {
      state: "NJ",
      juriCode: "us_nj"
    },
    {
      state: "NM",
      juriCode: "us_nm"
    },
    {
      state: "NV",
      juriCode: "us_nv"
    },
    {
      state: "NY",
      juriCode: "us_ny"
    },
    {
      state: "OH",
      juriCode: "us_oh"
    },
    {
      state: "OK",
      juriCode: "us_ok"
    },
    {
      state: "OR",
      juriCode: "us_or"
    },
    {
      state: "PA",
      juriCode: "us_pa"
    },
    {
      state: "RI",
      juriCode: "us_ri"
    },
    {
      state: "SC",
      juriCode: "us_sc"
    },
    {
      state: "SD",
      juriCode: "us_sd"
    },
    {
      state: "TN",
      juriCode: "us_tn"
    },
    {
      state: "TX",
      juriCode: "us_tx"
    },
    {
      state: "UT",
      juriCode: "us_ut"
    },
    {
      state: "VA",
      juriCode: "us_va"
    },
    {
      state: "VT",
      juriCode: "us_vt"
    },
    {
      state: "WA",
      juriCode: "us_wa"
    },
    {
      state: "WI",
      juriCode: "us_wi"
    },
    {
      state: "WV",
      juriCode: "us_wv"
    },
    {
      state: "WY",
      juriCode: "us_wy"
    }
  ]

  const [apiUrl, setApiUrl] = useState<string>(`https://api.opencorporates.com/v0.4/companies/search?jurisdiction_code=${usJurisdictionCodes}`);

  const getAccountStatusFromApi = useCallback(async (url?: string) => {
    let request = fetch(`https://api.opencorporates.com/v0.4/account_status?api_token=${apiToken}`, {
      method: 'GET',
    });

    return request.then(res => res.json())
      .then(responseJson => {
        return responseJson as AccountStatus;
      }).catch(err => {
        setApiError(err);
      })
  }, [apiToken]);

  const getCompaniesFromApi = useCallback(async (url?: string) => {
    setApiError(undefined);
    let body = { url: url ? url : apiUrl }
    return fetchMethodRequest('POST', apiCalls.OpenCorporates, body)
      .then((response) => {
        if (response && response.error) throw response.error;
        if (response) {
          return response as OpenCorporatesResponses
        }
      }).catch((err) => {
        console.log(err); //log so devs can see error if needed.
        setApiError(err.message);
        setLoading(false);
        return undefined;
      });
  }, [apiUrl]);

  const getCompaniesFromApiFilter1 = useCallback(async (filterArray: any[] = [], url?: string) => {

    setApiError(undefined);
    let criteria = []

    for (let filterArr of filterArray) {
      if (filterArr.field.includes("date")) {
        criteria.push({ "key": filterArr.field, type: "dateeq", "value": filterArr.value })
      } else {
        criteria.push({ "key": filterArr.field, type: "regexOr", "value": filterArr.value })
      }
    }
    let existUrl = apiUrl
    if (apiUrl) {
      if (officeType === "Office") {
        existUrl = existUrl.replace("companies", "officers")
      }
    }
    let body = {
      url: url ? url : existUrl,
      type: officeType,
      "filter": {
        "criteria": criteria,
        "limit": 0,
        "page": 0,
        "sortfield": "name",
        "direction": "asc",
      }
    }
    return fetchMethodRequest('POST', apiCalls.OpenCorporates, body)
      .then((response) => {
        if (response) {
          return response as OpenCorporatesResponses
        }
      }).catch((err) => {
        return err;
      });

  }, [apiUrl]);


  const getCompaniesFromApiFilter = useCallback(async (filterArray: any[] = [], url?: string) => {
    setLoading(true);

    let response = getCompaniesFromApiFilter1(filterArray, url);
    response.then(res => {
      if (res) {
        if (officeType === "Company") {
          let results = res.results as CompanySearch;

          setCompanies(results.companies.map((c/*, index*/) => {
            //c.company.id = index+1;
            if (c.company.jurisdiction_code) {
              //make juri code just state abbreviation capitalized
              c.company.jurisdiction_code = c.company.jurisdiction_code.replace("us_", "").toUpperCase();
            }
            if (c.company.inactive) {
              c.company.inactive = "Inactive";
            } else if (c.company.inactive === null) {
              c.company.inactive = ""
            } else {
              c.company.inactive = "Active"
            }
            if (c.company.branch == "F") {
              c.company.branch = "Foreign"
            } else {
              c.company.branch = "Domestic"
            }


            return c.company;
          }));

          setPaginationInfo({
            page: results.page,
            per_page: results.per_page,
            total_count: results.total_count,
            total_pages: results.total_pages
          });
          setLoading(false);
        } else {
          let results = res.results as OfficerSearch;

          setOfficers(results.officers.map((c/*, index*/) => {
            //c.company.id = index+1;
            if (c.officer.jurisdiction_code) {
              //make juri code just state abbreviation capitalized
              c.officer.jurisdiction_code = c.officer.jurisdiction_code.replace("us_", "").toUpperCase();
            }
            if (c.officer.company.jurisdiction_code) {
              //make juri code just state abbreviation capitalized
              c.officer.company.jurisdiction_code = c.officer.company.jurisdiction_code.replace("us_", "").toUpperCase();
            }
            if (c.officer.inactive) {
              c.officer.inactive = "Inactive";
            } else if (c.officer.inactive === null) {
              c.officer.inactive = ""
            } else {
              c.officer.inactive = "Active"
            }

            return c.officer;
          }));

          setPaginationInfo({
            page: results.page,
            per_page: results.per_page,
            total_count: results.total_count,
            total_pages: results.total_pages
          });
          setLoading(false);
        }
      }
    })
  }, [getCompaniesFromApi]);


  const getJurisdictionCodesFromApi = useCallback(async () => {
    setApiError(undefined);
    let request = fetch('https://api.opencorporates.com/v0.4/jurisdictions', {
      method: 'GET',
    });

    return request.then(res => res.json())
      .then(responseJson => {
        return responseJson as OpenCorporatesResponses;
      }).catch(err => {
        setApiError(err);
        return undefined;
      })
  }, []);

  const getAccountStatus = useCallback(async () => {
    try {
      let response = await getAccountStatusFromApi();

      if (response) {
        return response;
      }
    } catch (err: any) {
      setApiError(err);
      return undefined;
    }
  }, [getAccountStatusFromApi]);

  const getJurisdictionCodes = useCallback(() => {
    let response = getJurisdictionCodesFromApi();
    response.then(res => {
      if (res) {
        let usJuriCodes: string[] = [];
        let results = res.results as Jurisdictions;

        results.jurisdictions.forEach((j) => {
          if (j.jurisdiction.country === "United States") {
            usJuriCodes.push(j.jurisdiction.code);
          }
        })

        let usJuriCodesString: string = usJuriCodes.join("|");

        return usJuriCodes;
      }
    })
  }, [getJurisdictionCodesFromApi]);

  const getCompanies = useCallback((url?: string) => {
    setLoading(true);

    let response = getCompaniesFromApi(url);
    response.then(res => {
      if (res) {
        if (officeType === "Company") {
          let results = res.results as CompanySearch;

          setCompanies(results.companies.map((c/*, index*/) => {
            //c.company.id = index+1;
            if (c.company.jurisdiction_code) {
              //make juri code just state abbreviation capitalized
              c.company.jurisdiction_code = c.company.jurisdiction_code.replace("us_", "").toUpperCase();
            }
            if (c.company.inactive) {
              c.company.inactive = "Inactive";
            } else if (c.company.inactive === null) {
              c.company.inactive = ""
            } else {
              c.company.inactive = "Active"
            }
            if (c.company.branch == "F") {
              c.company.branch = "Foreign"
            } else {
              c.company.branch = "Domestic"
            }


            return c.company;
          }));

          setPaginationInfo({
            page: results.page,
            per_page: results.per_page,
            total_count: results.total_count,
            total_pages: results.total_pages
          });
          setLoading(false);
        } else {
          let results = res.results as OfficerSearch;

          setOfficers(results.officers.map((c/*, index*/) => {

            //c.company.id = index+1;
            if (c.officer.jurisdiction_code) {
              //make juri code just state abbreviation capitalized
              c.officer.jurisdiction_code = c.officer.jurisdiction_code.replace("us_", "").toUpperCase();
            }
            if (c.officer.company.jurisdiction_code) {
              //make juri code just state abbreviation capitalized
              c.officer.company.jurisdiction_code = c.officer.company.jurisdiction_code.replace("us_", "").toUpperCase();
            }
            if (c.officer.inactive) {
              c.officer.inactive = "Inactive";
            } else if (c.officer.inactive === null) {
              c.officer.inactive = ""
            } else {
              c.officer.inactive = "Active"
            }

            return c.officer;
          }));

          setPaginationInfo({
            page: results.page,
            per_page: results.per_page,
            total_count: results.total_count,
            total_pages: results.total_pages
          });
          setLoading(false);
        }
      }

      //get account status to display remaining calls.
      getStats();
    })
  }, [getCompaniesFromApi]);

  const getAllPromises = useCallback(async (url: string, totalPages: number) => {
    //NOTE: can only get first 100 pages from API.
    let promises = [];
    for (let i = 1; i <= totalPages; i++) {
      if (url.includes("&page=")) {
        url = url.replace(/(&page=\d*)/g, "&page=" + (i));
      }
      else {
        url = url + "&page=" + (i);
      }
      if (officeType === "Office") {
        url = url.replace(/companies/g, "officers");
      }
      promises.push(getCompaniesFromApi(url));

      //sleep after 10 request to prevent spamming the api
      if (i % 10 === 0) {
        await new Promise(r => setTimeout(r, 1000));
      }

      if (i % 5 === 0) {
        setGettingDataProgress(Math.round((i / (totalPages * 2)) * 100));
      }
    }

    return promises;
  }, [getCompaniesFromApi]);



  const buildAllCompanies = useCallback(async (responses: (undefined | OpenCorporatesResponses)[]): Promise<Company[]> => {
    let allCompaniesFromSearch: Company[] = [];
    let allOfficersFromSearch: Officer[] = [];
    let failedPages: number[] = [];
    for (let i = 0; i < responses.length; i++) {
      let response = responses[i];
      if (response) {

        let results = response.results as CompanySearch;

        let companiesFromPage = results.companies.map((c/*, i*/) => {
          //c.company.id = i+1;
          if (c.company.jurisdiction_code) {
            //make juri code just state abbreviation capitalized
            c.company.jurisdiction_code = c.company.jurisdiction_code.replace("us_", "").toUpperCase();
          }

          return c.company;
        });

        allCompaniesFromSearch = allCompaniesFromSearch.concat(companiesFromPage);

        if (i % 2 === 0 || i + 1 === responses.length) {
          //console.log(Math.round((((i+1+responses.length)/(responses.length * 2))*100)));
          setGettingDataProgress(Math.round((((i + 1 + responses.length) / (responses.length * 2)) * 100)));
          await new Promise(r => setTimeout(r, 250));
        }

      }
      else {
        failedPages.push(i + 1);
      }
    }

    setExportPagesThatFailed(failedPages);

    return allCompaniesFromSearch;
  }, []);
  const buildAllOfficers = useCallback(async (responses: (undefined | OpenCorporatesResponses)[]): Promise<Officer[]> => {
    let allOfficersFromSearch: Officer[] = [];
    let failedPages: number[] = [];
    for (let i = 0; i < responses.length; i++) {
      let response = responses[i];
      if (response) {

        let results = response.results as OfficerSearch;

        let officersFromPage = results.officers.map((c/*, i*/) => {
          //c.company.id = i+1;
          if (c.officer.jurisdiction_code) {
            //make juri code just state abbreviation capitalized
            c.officer.jurisdiction_code = c.officer.jurisdiction_code.replace("us_", "").toUpperCase();
          }

          return c.officer;
        });

        allOfficersFromSearch = allOfficersFromSearch.concat(officersFromPage);

        if (i % 2 === 0 || i + 1 === responses.length) {
          //console.log(Math.round((((i+1+responses.length)/(responses.length * 2))*100)));
          setGettingDataProgress(Math.round((((i + 1 + responses.length) / (responses.length * 2)) * 100)));
          await new Promise(r => setTimeout(r, 250));
        }

      }
      else {
        failedPages.push(i + 1);
      }
    }

    setExportPagesThatFailed(failedPages);

    return allOfficersFromSearch;

  }, []);

  const getAllCompanies = useCallback(async () => {
    let allCompaniesFromSearch: Company[] = [];

    let totalPages = paginationInfo?.total_pages;
    let promises = [];

    if (totalPages) {
      //can only get first 100 pages, so make totalPages 100 if greater than.
      if (totalPages > 100) {
        totalPages = 100;
      }
      let url: string = apiUrl;

      promises = await getAllPromises(url, totalPages);

      let responses = await Promise.all(promises);

      allCompaniesFromSearch = await buildAllCompanies(responses);

    }

    return allCompaniesFromSearch;

  }, [apiUrl, paginationInfo, buildAllCompanies, getAllPromises]);
  const getAllOfficers = useCallback(async () => {

    let allOfficersFromSearch: Officer[] = [];
    let totalPages = paginationInfo?.total_pages;
    let promises = [];

    if (totalPages) {
      //can only get first 100 pages, so make totalPages 100 if greater than.
      if (totalPages > 100) {
        totalPages = 100;
      }
      let url: string = apiUrl;

      promises = await getAllPromises(url, totalPages);

      let responses = await Promise.all(promises);

      allOfficersFromSearch = await buildAllOfficers(responses);


    }

    return allOfficersFromSearch


  }, [apiUrl, paginationInfo, buildAllOfficers, getAllPromises]);

  useEffect(() => {
    if (search) {
      setFirst(0);
      getCompanies();
      setSearch(false);
      setGettingDataProgress(undefined);
      setExportPagesThatFailed(undefined);
    }
  }, [search, getCompanies]);

  useEffect(() => {
    if (searchValue) {
      setApiUrl(`https://api.opencorporates.com/v0.4/companies/search?q=${searchValue.replaceAll(" ", "+")}&jurisdiction_code=${selectedState?.juriCode ? selectedState.juriCode : usJurisdictionCodes}&api_token=${apiToken}&per_page=100`); //need to put us codes at end like us_pa|us_ca....
    }
    else {
      setApiUrl(`https://api.opencorporates.com/v0.4/companies/search?jurisdiction_code=${selectedState?.juriCode ? selectedState.juriCode : usJurisdictionCodes}&api_token=${apiToken}&per_page=100`);
    }
  }, [searchValue, selectedState]);

  const getStats = useCallback(async () => {
    let accountStatus = await getAccountStatus();

    if (accountStatus) {
      let apiCallsRemaining = `Api calls remaining today: ${accountStatus.results.account_status.calls_remaining.today}/${accountStatus.results.account_status.calls_remaining.today + accountStatus.results.account_status.usage.today} | Api calls remaining this month: ${accountStatus.results.account_status.calls_remaining.this_month}/${accountStatus.results.account_status.calls_remaining.this_month + accountStatus.results.account_status.usage.this_month}`;
      setApiCallsRemaining(apiCallsRemaining);
    }
  }, [getAccountStatus]);

  /**
   * Update account status to display when ever we get an apiError
   */
  useEffect(() => {
    if (apiError) {
      getStats();
    }

  }, [apiError, getAccountStatus]);

  const paginationPageChange = useCallback((pageState: PageState) => {
    let newUrl;
    let currentPage = pageState.page;

    if (apiUrl.includes("&page=")) {
      newUrl = apiUrl.replace(/(&page=\d*)/g, "&page=" + (currentPage + 1));
    }
    else {
      newUrl = apiUrl + "&page=" + (currentPage + 1);
    }
    if (officeType === "Office") {
      newUrl = newUrl.replace("companies", "officers")
    }

    setFirst(pageState.first);
    setApiUrl(newUrl);
    getCompanies(newUrl);
  }, [apiUrl, getCompanies]);

  const handleSearchOnClick = useCallback((value: string | undefined, selectValue?: StateSelectOption, ofcType?: string | undefined) => {
    localStorage.setItem('officeTypeFilter', ofcType);

    setSearchValue(value);

    let juriCode;
    if (selectValue) {
      juriCode = selectValue.juriCode;
      setSelectedState(selectValue);
    }

    if (value) {

      setApiUrl(`https://api.opencorporates.com/v0.4/${ofcType === "Office" ? 'officers' : "companies"}/search?q=${value.replaceAll(" ", "+")}&jurisdiction_code=${juriCode ? juriCode : usJurisdictionCodes}&api_token=${apiToken}&per_page=100`); //need to put us codes at end like us_pa|us_ca....
    }
    else {
      setApiUrl(`https://api.opencorporates.com/v0.4/${ofcType === "Office" ? 'officers' : "companies"}/search?jurisdiction_code=${juriCode ? juriCode : usJurisdictionCodes}&api_token=${apiToken}&per_page=100`);
    }
    setOfcType(ofcType)

    setSearch(true);
  }, []);

  return (
    <>
      <OpraDataTable
        rows={officeType === "Company" ? companies : officers}
        errorMessage={apiError ?
          apiCallsRemaining ?
            exportPagesThatFailed && exportPagesThatFailed.length > 0 ?
              "Pages failed to grab for export: " + exportPagesThatFailed.toString() + " | " + apiError + " " + apiCallsRemaining
              : apiError + " " + apiCallsRemaining
            : exportPagesThatFailed && exportPagesThatFailed.length > 0 ?
              "Pages failed to grab for export: " + exportPagesThatFailed.toString() + " | " + apiError
              : apiError
          : apiCallsRemaining ?
            apiCallsRemaining
            : undefined}
        loading={loading}
        paginationInfo={paginationInfo}
        removeHeaders={removeHeaders}
        filterThroughApi={filterThroughApi}
        needToolTip={needToolTip}
        // freezeRow={freezeRow}
        // freezeRowJDX={freezeRowJDX}
        first={first}
        paginationPageChange={paginationPageChange}
        emptyGridMessage={"Try searching a company name or different company name or officer name"}
        objectArraysToDefineHeadersFor={objectArraysToDefineHeadersFor}
        toolbarProps={{
          type: type,
          getCompaniesFromApiFilter: getCompaniesFromApiFilter,
          inputSearchOnClick: handleSearchOnClick,
          csvFileName: `${type} Search Results - ${searchValue} - ${selectedState?.state}.csv`,
          icon: 'open_corporates_icon.jpg',
          dropdownSelect: {
            options: usJurisdictionCodesValueArr,
            tooltip: "Select a state to use in the search"
          },
          getAllRowsCallback: officeType === "Company" ? getAllCompanies : getAllOfficers,
          gettingDataProgress: gettingDataProgress
        }}
        customGridColumns={customGridColumns}
      />
    </>
  );
};