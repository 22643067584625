import React from 'react';
import { Container } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import BusinessNameIssueCode from './components/BusinessNameIssueCode';
const businessNameIssueCode = ({ t }) => (
  <Container className="">
    <BusinessNameIssueCode />
  </Container>
);

businessNameIssueCode.propTypes = {
  t: PropTypes.func.isRequired,
};

export default withTranslation('common')(businessNameIssueCode);
