

import React from 'react';
import { Card } from 'reactstrap';
import { load as loadAccount } from '../../redux/reducers/commonReducer';
import { settings } from '../../redux/actions/settingsAction'
import { Field, reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Select from '../../shared/components/form/Select';
import { Redirect } from 'react-router-dom';
import SessionWarningModal from '../Cruds/CommonModals/SessionWarningModal';
import Abbrevations from '../Cruds/Abbrevations/index';
import AvailableToFileStatus from '../Cruds/AvailableToFIleStatus/index'
import ResearchRequestType from '../Cruds/ResearchRequestType/index';
import DataSourceGroupings from '../Cruds/DataSourceGroupings/index'
import RfiReasons from '../Cruds/RfiReasons/index';
import ApaTypeConfiguration from '../Cruds/ApaTypeConfiguration/index';
import RuleConfiguration from '../Cruds/RulesConfigurations/index'
import AvailableToMapConfiguration from '../Cruds/AvailableToMapConfiguration/index'
import MenuConfiguration from '../Cruds/TopMenuConfiguration/index'
import RolePermissions from '../Cruds/CommonModals/Permissions'
import configMessages from '../../config/configMessages';
import UnreviewedCondition from '../Cruds/UnreviewedCondition/index';
import DataFeedInclusion from '../Cruds/DataFeedInclusion/index'
import ClaimValueHintBucketAvg from '../Cruds/ClaimValueHintBucketAvg/index';
// import Filters from '../Cruds/Filters/index'
import Suffix from '../Cruds/Suffix/index';
import SubCategory from '../Cruds/SubCategory/index';
import Category from '../Cruds/Category/index';
import Buckets from '../Buckets/index';
// Toaster message
import validate from '../Validations/validate';
import Reload from '../reload'
import NoViewComponent from '../../containers/Cruds/CommonDataTable/NoView'
// Loader
// import Loader from '../App/Loader';
// // Calendar
// //session expiry modal
// import store from '../App/store';
import DealSizingThreshold from '../DealSizingThreshold/index';

class SettingsForm extends React.Component {
  static propTypes = {
    t: PropTypes.func,
    handleSubmit: PropTypes.func.isRequired,
    reset: PropTypes.func.isRequired,
    pristine: PropTypes.bool.isRequired,
    submitting: PropTypes.bool.isRequired,
  };
  constructor(props) {
    super(props);
    this.state = {
      settingsData: '',
      options: [
        { label: 'Legal Entity Suffix', value: 'Legal Entity Suffix' },
        { label: 'Legal Entity Abbreviation Screen', value: 'Legal Entity Abbreviation Screen' },
        { label: 'Regular Scoring Bucket', value: 'Regular Scoring Bucket' },
        { label: 'Deal Sizing Bucket', value: 'Deal Sizing Bucket' },
        { label: 'Deal Sizing Threshold', value: 'Deal Sizing Threshold' },
        { label: 'State UP-Filing Category', value: 'Category' },
        { label: 'State UP-Filing Sub Category', value: 'SubCategory' },
        { label: 'Available To File - Statuses', value: 'Available To File - Statuses' },
        { label: 'Rule Configuration(SP)', value: 'Rule Configuration(SP)' },
        { label: 'Unreviewed Condition(SP)', value: 'Unreviewed Condition(SP)' },
        { label: 'Research Request Type', value: 'Research Request Type' },
        { label: 'Data Source Groupings', value: 'Data Source Groupings' },
        { label: 'RFI Reasons', value: 'RFI Reasons' },
        { label: 'Data Feed Inclusions', value: 'Data Feed Inclusions' },
        { label: 'Claim Value Hint Bucket Avg', value: 'Claim Value Hint Bucket Avg' },
        { label: 'Menu Configuration', value: 'Menu Configuration' },
        { label: 'Available To Map Rule Configuration', value: 'Available To Map Rule Configuration' },


        // { label: 'APA Type Configuration', value: 'APA Type Configuration' },
        // { label: 'Filters', value: 'Filters' },

      ],
     screenPermissions:"",
      isLoading: false,
      isUserUpdated: false,

    };
  }

  componentDidMount = async () => {
    // let apiUrl=''
    let screenPermissions = RolePermissions.screenPermissions("Configurations");

    this.setState({
      isLoading: true,
      screenPermissions:screenPermissions
    });
    Reload.reload()
    // })
  }
  //send data to server

  cancelSessionWarningModal = async () => {
    await this.setState({
      sessionWarning: false
    })
  }


  //stay signed in Modal
  getSessionWraningModal() {
    return (
      <SessionWarningModal
        openConfirmationModal={this.state.sessionWarning}
        cancelSessionWarningModal={this.cancelSessionWarningModal}
        getData={this.getKanbanCards}

      />
    )
  }

  handleScreenChange = async (e) => {
    await this.setState({
      selectedScreen: e
    })
  }
  saveDataToServer() { }
  render() {
    const { handleSubmit } = this.props;
    const { options } = this.state
    //
    return (
      (this.state.screenPermissions==="Edit" ||this.state.screenPermissions==="View")?
      <div style={{ overflowX: 'hidden', height: '88vh' }} onLoad={this.apple}>
        {/* <Loader loader={isLoading} /> */}

        <form onSubmit={handleSubmit(this.saveDataToServer)}>
          <Card className='settingsCard row px-5'>
            <div className="" >
              <div className='col-sm-3'>
                <div className="row create-header">
                  <h4 className="col-md-6 offset-3 text-center py-3">Configurations</h4>
                </div>
                <div className="form-group pt-0">
                  <div className="form-group pt-2">
                    <label>Choose Screen </label>
                    <div className="form__form-group-field">
                      <Field
                        name={'dateFormat'}
                        component={Select}
                        options={options}
                        onChange={(e) => this.handleScreenChange(e)}
                        placeholder={'Select Screen'}
                      />
                    </div>
                  </div>
                </div>

              </div>
              <div>
                {this.state.selectedScreen === "Deal Sizing Threshold" ?
                  <DealSizingThreshold /> : this.state.selectedScreen === "Regular Scoring Bucket" ? <Buckets ScoringType={"RegularScoring"} /> :
                    this.state.selectedScreen === "Deal Sizing Bucket" ? <Buckets ScoringType={"DealSizing"} /> :
                      this.state.selectedScreen === "Legal Entity Suffix" ?
                        <Suffix /> : this.state.selectedScreen === "Legal Entity Abbreviation Screen" ? <Abbrevations /> :
                          this.state.selectedScreen === "Category" ? <Category />
                            // : this.state.selectedScreen === "Filters" ? <Filters />
                            : this.state.selectedScreen === "SubCategory" ? <SubCategory /> :
                              this.state.selectedScreen === "Available To File - Statuses" ? <AvailableToFileStatus /> :
                                this.state.selectedScreen === "Research Request Type" ? <ResearchRequestType /> :
                                  this.state.selectedScreen === "Data Source Groupings" ? <DataSourceGroupings /> :
                                    this.state.selectedScreen === "RFI Reasons" ? <RfiReasons /> :
                                      this.state.selectedScreen === "APA Type Configuration" ? <ApaTypeConfiguration /> :
                                        this.state.selectedScreen === "Rule Configuration(SP)" ? <RuleConfiguration /> :
                                          this.state.selectedScreen === "Available To Map Rule Configuration" ? <AvailableToMapConfiguration /> :
                                            this.state.selectedScreen === "Unreviewed Condition(SP)" ? <UnreviewedCondition /> :
                                              this.state.selectedScreen === "Data Feed Inclusions" ? <DataFeedInclusion /> :
                                                this.state.selectedScreen === "Claim Value Hint Bucket Avg" ? <ClaimValueHintBucketAvg /> :
                                                  this.state.selectedScreen === "Menu Configuration" ? <MenuConfiguration /> :
                                                    null}

              </div>
            </div>

            <div className='pl-5'>
            </div>
          </Card>
        </form>
        {
          this.state.isUserUpdated ?
            <Redirect to='/taskView' /> : null

        }
        {this.state.LoggotSuccess ? <Redirect to="/log_in" /> : null}
        {this.state.sessionWarning ? this.getSessionWraningModal() : null}
      </div >:this.state.screenPermissions===""?null :  <NoViewComponent/>
    );
  }
}

// Decorate with reduxForm(). It will read the initialValues prop provided by connect()
SettingsForm = reduxForm({
  form: "Common Form", // a unique identifier for this form
  validate,
  enableReinitialize: true,
})(SettingsForm);

// You have to connect() to any reducers that you wish to connect to yourself
SettingsForm = connect(
  state => ({
    initialValues: state.commonData.data // pull initial values from account reducer
  }),

  {
    load: loadAccount,// bind account loading action creator
    settingsLoad: settings,
    // settingsData:auth
  }
)(SettingsForm);

export default SettingsForm;




