import React from 'react';
import store from '../../../App/store';
import apiCalls from '../../../../config/apiCalls'
import DataTables from '../../CommonDataTable/DataTable';
import fetch from '../../../../config/service';
import Loader from '../../../App/Loader';


// config file
export default class Employees extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      type: 'Purposes',
      dealTypes: [{ "label": "BK", "value": "BK" }, { "label": "CR", "value": "CR" }],
      scopes:[],
      gotScopes:false,
    };
  }

  static getDerivedStateFromProps(props, state) {
    let storeData = store.getState()
    let languageData = storeData && storeData.settingsData && storeData.settingsData.settings && storeData.settingsData.settings.languageTranslation ? storeData.settingsData.settings.languageTranslation : ""
    return { languageData: languageData };
  }



  componentDidMount = () => {
    this.getDocScopes();
    this.getLabelOptions();
    //Screen permisions value can be edit,view, no view
    // let screenPermissions = RolePermissions.screenPermissions('Employees');
  }

  getDocScopes = async () => {
    const res = await fetch('GET', `${'documentScope'}?type=exportToCsv`);
    if (res && res.documentScopes) {
      this.setState({ scopes: res.documentScopes.map((e) => {return {label:e.name,value:e.name}}) });
    }
    this.setState({gotScopes:true});
  }

  //user Task label options
  getLabelOptions = () => {
    let filter = {}
    filter.criteria = [{ key: "activeIndicator", value: true, type: "eq" }]
    fetch('GET', `${apiCalls.Category}?filter=${JSON.stringify(filter)}`)
      .then(async (response) => {
        if (response) {
          let labels = response.categories;
          let modifiedLabels = [];
          for (let label of labels) {
            modifiedLabels.push({
              label: label.categoryName,
              value: { categoryName: label.categoryName, categoryId: label._id }

            })
          }
          await this.setState({
            company: modifiedLabels
          })
          // this.setUnitName()

        }
      }).catch((err) => {
        return err;
      });
  }

  getTableFields = () => {
    let data = [
      // {
      //   "show": true,
      //   "textAlign": "center",
      //   "width": 6,
      //   "fieldType": "expander",
      //   "header": "",
      //   "filter": false,
      //   "sortable": false,
      //   "placeholder": "Search"
      // },

      // {
      //   "show": true,
      //   textAlign: 'left',
      //   width: 40,
      //   field: 'sequence',
      //   "allowInt": true,
      //   "filterType": 'num',
      //   "mobile": true,
      //   header: 'Sequence',
      //   "filter": true,
      //   "displayInSettings": true,
      //   "displayInFilter": true,
      //   sortable: true,
      // },
      {
        "show": true,
        textAlign: 'left',
        // width: 80,
        field: 'categoryName',
        fieldType: 'BoolText',
        "mobile": true,
        header: 'Category',
        "filter": true,
        "displayInSettings": true,
        "displayInFilter": true,
        sortable: true,
      },
      {
        "show": true,
        textAlign: 'left',
        // width: 80,
        field: 'subCategoryName',
        // fieldType: 'BoolText',
        "mobile": true,
        header: 'Sub Category',
        "filter": true,
        "displayInSettings": true,
        "displayInFilter": true,
        sortable: true,
      },
      {
        "show": true,
        textAlign: 'left',
        // width: 80,
        field: 'dealType',
        fieldType: 'Array',
        "mobile": true,
        header: 'Deal Type',
        "filter": true,
        "displayInSettings": true,
        "displayInFilter": true,
        sortable: true,
      },
      {
        "show": true,
        "textAlign": "left",
        "width": 150,
        "field": "documentScopeName",
        "mobile": true,
        "header": "Document Scope",
        "displayInSettings": true,
        filter: true,
        fieldType:'StrArray',
        "sortable": true,
      },

      {
        "show": true,
        "textAlign": "left",
        // "width": 40,
        "fieldType": 'Actions',
        "field": "Actions",
        "header": "Actions",
        "filter": false,
        "displayInSettings": true,
        "sortable": false
      }
    ]
    return data;
  };


  getFormFields = () => {
    return ([

      {
        //'show': false,
        "value": "",
        "type": "text",
        "name": "subCategoryName",
        "label": "Sub Category",
        "id": "name",
        "placeholder": "Sub Category",
        "required": true
      },
      {
        //'show': false,
        // 'isMultiSelect': true,
        "value": "",
        "type": "dropDown",
        "name": "categoryName",
        "label": "Category",
        "options": this.state.company,
        "id": "name",
        "placeholder": "Category",
        "required": true
      },
      // {
      //   //'show': false,
      //   // 'isMultiSelect': Number,
      //   "value": "",
      //   "type": "text",
      //   "formatType": "Number",
      //   "name": "sequence",
      //   "label": "Sequence",
      //   // "options": this.state.company,
      //   // "id": "name",
      //   "placeholder": "Sequence",
      //   // "required": true
      // },
      {
        //'show': false,
        'isMultiSelect': true,
        "value": "",
        "type": "dropDown",
        "name": "dealType",
        "label": "Deal Type",
        "id": "name",
        "options": this.state.dealTypes,
        "placeholder": "Name",
        "required": true
      },
      {
        //'show': false,
        'isMultiSelect': true,
        "value": "",
        "type": "dropDown",
        "name": "documentScopeName",
        "label": "Document Scope",
        "id": "DocumentScopeName",
        "options": this.state.scopes,
        "placeholder": "Document Scope",
        "required": false
      },
      {
        //'show': false,
        "value": "",
        "type": "switch",
        "name": "activeIndicator",
        "label": "Active /Inactive Status",
        "id": "name",
        "placeholder": "Name",
        // "required": true
      },


    ]);
  }


  submit = async (item) => {


  }

  render() {
    return (
      <span>
        <Loader loader={this.state.isLoading} />
        {this.state.gotScopes &&
        <DataTables
          onRef={(ref) => (this.datatableref = ref)}
          getTableFields={this.getTableFields}
          formFields={this.getFormFields}
          exportRequried={false}
          printRequried={false}
          sortField={"created"}
          addRequried={true}
          editRequired={true}
          deleteRequired={false}
          viewRequired={true}
          settingsRequired={false}
          filterRequired={true}
          gridRequried={false}
          sample={false}
          isClearFilters={true}
          expandTableFields={this.expandTableFields}
          globalSearchFieldName='user'
          globalSearch={'Search'}
          type='State-UP Filing Sub Category'
          displayright={true}
          icon='user'
          routeTo='configurations'
          displayViewOfForm='modal'
          apiResponseKey='subCategories'
          apiUrl={apiCalls.SubCategory}
          entityType='employee'
        />
  }
      </span>
    );
  }
}