import React from "react";
import apiCalls from "../../../../config/apiCalls";
import store from "../../../App/store";
import DataTables from "../../CommonDataTable/DataTable";

// config file
export default class AuditLog extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      languageData: "",
    };
  }

  static getDerivedStateFromProps(props, state) {
    let storeData = store.getState();
    let languageData =
      storeData &&
        storeData.settingsData &&
        storeData.settingsData.settings &&
        storeData.settingsData.settings.languageTranslation
        ? storeData.settingsData.settings.languageTranslation
        : "";
    return { languageData: languageData };
  }

  /**
   * 
   * @returns tableFields to show in grid
   */
  getTableFields = () => {
    let data = [

      {
        textAlign: "left",
        show: true,
        mobile: true,
        width: 130,
        field: "created",
        fieldType: "Time",
        dateFormat: 'MM-DD-yyyy hh:mm a',
        header: "Created Date",
        "filterType": "num",
        filter: true,
        sortable: true,
      },
      {
        textAlign: "left",
        width: 210,
        field: "company",
        mobile: true,
        header: "Business Name",
        filter: true,
        sortable: true,
        show: true,
        textCapitalize: true,
      },
      {
        textAlign: "left",
        width: 140,
        field: "context",
        mobile: true,
        header: "Object Name",
        filter: true,
        sortable: true,
        show: true,
        textCapitalize: true,
      },
      {
        textAlign: "left",
        width: 120,
        field: "contextName",
        mobile: true,
        header: "Object Id",
        filter: true,
        sortable: true,
        show: true,
        textCapitalize: true,
      }, {
        textAlign: "left",
        width: 140,
        field: "contextType",
        mobile: true,
        header: "Context Type",
        filter: true,
        sortable: true,
        show: true,
        textCapitalize: true,
      },
      {
        textAlign: "left",
        width: 140,
        field: "email",
        mobile: true,
        header: "Email",
        filter: true,
        sortable: true,
        show: true,
      },
      {
        show: true,
        textAlign: "left",
        width: 140,
        mobile: true,
        field: "user_email",
        header: "Affected user",
        // fieldType: 'text',
        filter: true,
        sortable: true,
        textCapitalize: true,
      },
      {
        show: true,
        textAlign: "left",
        width: 120,
        mobile: true,
        field: "description",
        header: "Description",
        fieldType: 'Array',
        filter: true,
        sortable: true,
        textCapitalize: true,
      },
    ];
    return data;
  };
  /**
   * 
   * @returns formfields to show in form
   */
  getFormFields = () => {
    return [

    ];
  };

  render() {
    return (
      <DataTables
        onRef={(ref) => (this.datatableref = ref)}
        getTableFields={this.getTableFields}
        formFields={this.getFormFields}
        statusFilter={false}
        className={true}
        exportRequried={false}
        printRequried={false}
        addRequried={false}
        editRequired={false}
        deleteRequired={false}
        viewRequired={false}
        settingsRequired={false}
        filterRequired={false}
        gridRequried={false}
        sample={false}
        globalSearchFieldName='user'
        globalSearch={'Search'}
        type='Audit Logs'
        isClearFilters={true}
        displayright={true}
        icon='history'
        routeTo='activities'
        displayViewOfForm='modal'
        apiResponseKey='activities'
        apiUrl={apiCalls.Activities}
        entityType='employee'
      />
    );
  }
}
