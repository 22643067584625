import React from 'react';
import { Container } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import Suffixes from './components/Suffix';
const suffixes = ({ t }) => (
  <Container className="Custom_container">
    <Suffixes />
  </Container>
);

suffixes.propTypes = {
  t: PropTypes.func.isRequired,
};

export default withTranslation('common')(suffixes);
