import React from 'react';
import { Container } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import Seller from './components/Sellers';
const seller = ({ t }) => (
  <Container>
    <Seller />
  </Container>
);

seller.propTypes = {
  t: PropTypes.func.isRequired,
};

export default withTranslation('common')(seller);
