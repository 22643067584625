import React, { PureComponent } from 'react';
import {
  Card, CardBody, Col, Button, Row
} from 'reactstrap';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import Loader from '../../../App/Loader';
import StatesList from '../../../../shared/states.json'
import fetch from '../../../../config/service'
import apiCalls from '../../../../config/apiCalls';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext'
import { Calendar } from 'primereact/calendar';
import { InputTextarea } from 'primereact/inputtextarea';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStarOfLife } from '@fortawesome/free-solid-svg-icons';

class OnlineClaimStatusConfiguration extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      StatusCheckedDate: new Date(),
      isSubmitted: false,
      selectedUrl: "",
      respMessage: "",
      respCode: "",
      states: [...StatesList.map((e) => { return { label: e.shortCode, value: e.shortCode } })],
    };
  }

  componentDidMount = async () => {
  }

  getUrlbasedonstate = (stateVal) => {
    this.setState({ selectedState: stateVal,isSubmitted:false })
    let filterCriteria = {}, StatusArray = [], ResourceNeededStatus = []
    filterCriteria['criteria'] = [{ key: 'Category', value: "State UP Claim Attributes", type: 'eq' }, { key: 'dealType', value: "CR", type: 'eq' }, { key: 'SubCategory', value: "Claim Status Check URL", type: "eq" }, { key: 'state', value: stateVal.value, type: "eq" }];
    fetch('GET', `${apiCalls.StateUpFiling}?filter=${JSON.stringify(filterCriteria)}`).then(async (response) => {
      if (response && response["stateUpFilings"] && response["stateUpFilings"].length > 0) {
        this.setState({ selectedUrl: response["stateUpFilings"][0].AttributeValue })
      }
    })
  }


  submitData = () => {
    const { stClaimID, selectedState, StatusCheckedDate, selectedUrl, onlineClaimStatus, onlineClaimDetail, LastActivityDate } = this.state
    this.setState({ isSubmitted: true })
    var timeouttoShowErrorMessage
    if (stClaimID && selectedState && onlineClaimStatus && StatusCheckedDate) {
      let body = {
        "stateClaimID": stClaimID,
        "sourceState": selectedState ? selectedState.value : null,
        "claimStatus_LastCheckedDate": StatusCheckedDate,
        "claimStatus_URL": selectedUrl,
        "claimStatus_Language": onlineClaimStatus,
        "upStateOnline_AdditionalClaimDetails": onlineClaimDetail,
        "claimStatus_LastActivityDate": LastActivityDate
      }
      fetch('POST', apiCalls.stateOnlineClaimStatussCreate, body).then(async (response) => {
        if (response && response.respCode === 200) {
          this.setState({ respMessage: response.respMessage, respCode: 200 })
          if (timeouttoShowErrorMessage) {
            clearTimeout(timeouttoShowErrorMessage)
          }
          timeouttoShowErrorMessage = setTimeout(() => this.setState({ respMessage: "", respCode: "" }), 30000);
          this.cancelData()
        } else if (response && response.errorMessage) {
          this.setState({ respMessage: response.errorMessage, respCode: "" })
          if (timeouttoShowErrorMessage) {
            clearTimeout(timeouttoShowErrorMessage)
          }
          timeouttoShowErrorMessage = setTimeout(() => this.setState({ respMessage: "", respCode: "" }), 30000);
          
        }

      })
    }
  }



  cancelData = () => {
    this.setState({
      isSubmitted: false, stClaimID: "", selectedState: null
      , selectedUrl: null, StatusCheckedDate: new Date(), LastActivityDate: null, onlineClaimStatus: "", onlineClaimDetail: "",
    })

  }

  getMandatoryIcon() {
    return (
      <FontAwesomeIcon icon={faStarOfLife}
        className='pl-1'
        color='red'
        data-toggle="tool-tip"
        title="Mandatory Field"
        style={{ width: 11 }}
      />
    )
  }

  render() {
    const { states } = this.state
    return (
      <Card >

        <Loader loader={this.state.isLoading} />
        <CardBody>
          {this.state.respMessage ?
            <p className="d-flex justify-content-center" style={this.state.respCode ? { color: "green" } : { color: "red" }}>{this.state.respMessage}</p>
            : null}
          <Row>

            <div className="col-md-3 mt-2">
              <label className="mb-0">State Claim ID
                {this.getMandatoryIcon()}</label>
              <InputText value={this.state.stClaimID} onChange={(e) => { this.setState({ stClaimID: e.target.value,isSubmitted:false }) }}
                style={{ minWidth: '100%' }} filter={true} />
              <p style={{ color: "red" }}>{!this.state.stClaimID && this.state.isSubmitted ? "Please fill the field" : null}</p>

            </div>
            <div className="col-md-3 mt-2">
              <label className="mb-0">State               {this.getMandatoryIcon()}</label>
              <Dropdown value={this.state.selectedState} options={states} optionLabel="label" onChange={(e) => { this.getUrlbasedonstate(e.value) }}
                style={{ minWidth: '100%' }} filter={true} />
              <p style={{ color: "red" }}>{!this.state.selectedState && this.state.isSubmitted ? "Please fill the field" : null}</p>

            </div>
          </Row>
          <Row>
            <div className="col-sm-6 mt-2">
              <label className="mb-0">Online Claim Status Check URL</label>
              {/* <InputText value={this.state.selectedUrl} onChange={(e) => { this.setState({ selectedUrl: e.target.value }) }}
                style={{ minWidth: '100%' }} filter={true} /> */}
              <a className="p-inputtext p-component onlineUrlField" href={this.state.selectedUrl} target='_blank'>{this.state.selectedUrl}</a>
            </div>
          </Row>
          <Row>
            <div className="col-md-3 mt-2">
              <label className="mb-0">Status Checked Date                {this.getMandatoryIcon()}</label>
              <Calendar
                style={{ zindex: 1004, width: "100%", lineHeight: 1.15, height: '25px' }}
                appendTo={document.body}
                monthNavigator={true}
                yearNavigator={true}
                hourFormat="24"
                yearRange="1940:2530"
                name={"calendar1"}
                placeholder={"Select Date"}
                dateFormat="mm/dd/yy"
                value={this.state.StatusCheckedDate}
                onChange={(e) => this.setState({ StatusCheckedDate: e.value ,isSubmitted:false})}
              />
              <p style={{ color: "red" }}>{!this.state.StatusCheckedDate && this.state.isSubmitted ? "Please fill the field" : null}</p>
            </div>
            <div className="col-md-3 mt-2">
              <label className="mb-0">Last Activity Date / Status Date</label>
              <Calendar
                style={{ zindex: 1004, width: "100%", lineHeight: 1.15, height: '25px' }}
                appendTo={document.body}
                monthNavigator={true}
                yearNavigator={true}
                hourFormat="24"
                yearRange="1940:2530"
                name={"calendar1"}
                placeholder={"Select Date"}
                dateFormat="mm/dd/yy"
                value={this.state.LastActivityDate}
                onChange={(e) => this.setState({ LastActivityDate: e.value ,isSubmitted:false})}
              />
            </div>
          </Row>
          <Row className="col-sm-6 mt-2">
            <label className="mb-0">Online Claim Status                {this.getMandatoryIcon()} </label>
            <InputTextarea rows={6} value={this.state.onlineClaimStatus} onChange={(e) => { this.setState({ onlineClaimStatus: e.target.value,isSubmitted:false }) }}
              style={{ minWidth: '100%' }} filter={true} />
            <p style={{ color: "red" }}>{!this.state.onlineClaimStatus && this.state.isSubmitted ? "Please fill the field" : null}</p>

          </Row>
          <Row className="col-sm-6 mt-2">
            <label className="mb-0">Online Claim Detail </label>
            <InputTextarea rows={10} value={this.state.onlineClaimDetail} onChange={(e) => { this.setState({ onlineClaimDetail: e.target.value,isSubmitted:false }) }}
              style={{ minWidth: '100%' }} filter={true} />

          </Row>
          <Row className="col-sm-6 mt-2 centerAlign">
            <Button
              outline
              // disabled={!this.state.holderName && !this.state.propertyAddressLine1 && !this.state.sourceState && !this.state.propertyOwnerName ? true : false}
              style={this.props.screenPermissions==="Edit"?{ cursor: "pointer", height: "34px", marginBottom: "0px", backgroundColor: "#4ea250", color: "white" }:{display:"none"}}
              onClick={(e) => this.submitData()}
              disabled={this.state.isSubmitted?true:false}
            >
              Save
            </Button> <Button
              outline
              // disabled={!this.state.holderName && !this.state.propertyAddressLine1 && !this.state.sourceState && !this.state.propertyOwnerName ? true : false}
              style={{ cursor: "pointer", height: "34px", marginBottom: "0px", backgroundColor: "#354f6e", color: "white" }}
              onClick={(e) => this.cancelData()}
            >
              Cancel
            </Button>
          </Row>
        </CardBody>
      </Card >


    )
  }

}

export default withTranslation('common')(OnlineClaimStatusConfiguration);