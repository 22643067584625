import React from 'react';
import { Container } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import ManuallyLoadedProp from './components/ManualMatchTable';

const manuallyLoadedProp = (props) => (
  <Container>
    <ManuallyLoadedProp
    />
  </Container>
);

manuallyLoadedProp.propTypes = {
  t: PropTypes.func.isRequired,
};

export default withTranslation('common')(manuallyLoadedProp);