import React from 'react';
import { Button, Modal, ModalBody } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import { RadioButton } from "primereact/radiobutton"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import ConfirmaionModelorSave from '../../Cruds/CommonModals/ConfirmationModal'
import { Accordion, AccordionTab } from 'primereact/accordion';
import fetchMethodRequest from '../../../config/service'
import ConfirmationModal from './ConfirmationModal';
import Loader from '../../App/Loader';
import { async } from '@firebase/util';
// import { Accordion } from 'react-bootstrap';

class LoadPreset extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      presetName: "",
      viewsforScreens: [],
      presets: [],
      progress: 0,
      isLoading: false,
      defaultPresetForUser: "",
      ViewType: ["Default View", "Custom View"],
      isopenColumnModal: false
    };
  }

  componentDidMount = () => {
    // this.sendColumnDataToServer()
    let loginCredentials = localStorage.loginCredentials ? JSON.parse(localStorage.loginCredentials) : false;
    let roleName = loginCredentials.roleName
    this.setState({ roleName: roleName })
    this.loadAllPresets()
  }

  /**
* To get all presets from server
*/
  loadAllPresets = async () => {
    let filterCriteria = {}
    this.setState({ isLoading: true })
    filterCriteria['criteria'] = [{ key: 'Screen', value: this.props.activeTab ? this.props.activeTab : this.props.activeTab, type: 'eq' }];
    await fetchMethodRequest('GET', `preset?filter=${JSON.stringify(filterCriteria)}`).then(async (response) => {
      if (response && response["presets"] && response["presets"].length > 0) {
        let presets = response["presets"]

        await this.setState({
          presets: presets
        })
      }
      if (response.defaultPreset && response.defaultPreset !== null) {
        await this.setState({
          defaultPresetForUser: response.defaultPreset,
        })
      }
      this.setState({ isLoading: false })

    })
    return this.state.defaultPresetForUser;
  }

  /**
   * 
   * @param {Object} item 
   * @param {String} type 
   * Sending the default preset to server to set for user
   */

  sendDefaultPreset = async (item, type) => {
    this.setState({ isopenConfirmaionModelforSave: true, makeDefaultItem: item, })
    if (type === "Delete") {
      this.setState({ isDelete: true })
    }
    // this.props.closeModelOnDefault()
    // this.getConfirmaionModelforSave()
  }

  /**
* Change Preset on click for user
*/

  changePreset = async (item, type) => {
    // await this.props.setTableFieldsOrderEmpty(item.preferences)
    await this.props.getDataFromServerToSetPreset(item.name)
  }

  /**
* Make my default API
*/
  makeMyDefaultAPi = async () => {
    let item = this.state.makeDefaultItem
    await fetchMethodRequest('PUT', `preset/makeDefault/${item._id}`, {}).then(async (response) => {
      if (response && response.respMessage) {
      }

    })
  }

  /**
* Delete API for preset
*/
  makeDeleteApi = async () => {
    let item = this.state.makeDefaultItem
    await fetchMethodRequest('DELETE', `preset/${item._id}`, {}).then(async (response) => {
      if (response && response.respMessage) {
      } else {
        this.setState({ errorResponse: true, errorMessage: "Only Admins can Delete the Preset" })
      }

    })
  }


  /**
   * Setting the preset and closing the preset model and hitting api call function
   * @param {String} type 
   */
  sendDefaultPresetToServer = async (type) => {
    this.setState({ isopenConfirmaionModelforSave: false })
    if (type && type === "close") {
      this.makeMyDefaultAPi()
      this.props.closeModelOnDefault()
    }
  }

  /**
   * 
   * @param {String} type 
   * Handling the Delete functionality for Preset
   */
  sendDeleteToServer = async (type) => {
    this.setState({ isopenConfirmaionModelforSave: false })
    if (type && type === "close") {
      this.makeDeleteApi()
      this.props.closeModelOnDefault()
    }
  }
  //user preference col order
  // getColumnDataToServer = async (type) => {
  //   await this.deleteColumnDatafromServer(this.state.viewsforthistype[this.state.deleteItem])
  // };

  /**
   * 
   * @returns opening the model for confirmation for delete and make my default
   */

  getConfirmaionModelforSave() {
    return (
      <ConfirmaionModelorSave
        openConfirmationModal={this.state.isopenConfirmaionModelforSave}
        closeConfirmationModal={this.state.isDelete ? this.sendDeleteToServer : this.sendDefaultPresetToServer}
        shownText={this.state.isDelete ? "Are you sure you want to delete the Preset?" : "Are you sure you would like to make as Make my Default"}
      // confirm={this.handleCheckValidations}
      />
    )
  }

  /**
   * 
   * @param {String} item 
   * @returns css for placing the accordion tab for preset showing
   */
  showPresetHeader(item) {
    return <Accordion activeIndex={[0]} multiple={true} className="mt-3">
      <AccordionTab class="in" header={item}>

        <div >
          <div className='row' >
          </div>
          <span className="ml-3" style={item === this.state.defaultView ? { display: "block", textAlign: "initial", fontFamily: "serif" } : { display: "none" }}>{"Default View"}</span>
          {this.state.presets && this.state.presets.length > 0 && this.state.presets.map((presetItem, i) => {
            return this.showPreset(presetItem, item)
          })
          }
        </div>
      </AccordionTab>
    </Accordion>
  }

  /**
   * 
   * @param {Object} item 
   * @param {String} presetName 
   * @returns returning the preset names along with delete and make my default check boxes
   */
  showPreset = (item, presetName) => {
    if (item.viewType === presetName) {
      return <div >
        <div className='row' >
          <div className='ml-4' onClick={() => this.setState({ deleteItem: item })}
          >
            <FontAwesomeIcon
              icon={faTrashAlt}
              // color="red"
              data-toggle="tool-tip"
              color={(item.name === "Original Grid" || item.name === "All") || !(this.state.roleName.includes("Admin") || this.state.roleName.includes("Manager")) ? 'lightgrey' : 'red'}
              style={(item.name === "Original Grid" || item.name === "All") || !(this.state.roleName.includes("Admin") || this.state.roleName.includes("Manager")) ? { cursor: "pointer", pointerEvents: "none" } : { cursor: "pointer", pointerEvents: "visible" }}
              title={"Delete Preset"}

              onClick={() => this.sendDefaultPreset(item, "Delete")}
            />
            <input type="checkbox"
              class="ml-3"
              checked={item.name === this.state.defaultPresetForUser ? true : false}
              disabled={item.name === this.state.defaultPresetForUser ? true : false}
              onChange={() => this.sendDefaultPreset(item)}
              data-toggle="tool-tip"
              title={"Make My Default"}
            />
          </div>
          <div onClick={() => this.changePreset(item)}>
            <label className="d-flex ml-3" >{item.name}

            </label>
          </div>

        </div>
        <span className="ml-3" style={item === this.state.defaultView ? { display: "block", textAlign: "initial", fontFamily: "serif" } : { display: "none" }}>{"Default View"}</span>

      </div>
    }
  }

  render() {
    const { t } = this.props
    const { viewsforScreens } = this.state

    return (
      <div>
        <Modal isOpen={this.props.saveOrderModal} fade={false} className='deleteModalHeight'>
          <ModalBody className='deleteModalBody' style={{ display: "grid" }}>
            <Loader loader={this.state.isLoading} progress={0} />
            <div style={{ marginBottom: 10 }}><h4 style={{ fontWeight: "bold" }}> {"Load Preset"}</h4>
              <span className="topbar__centerresponse topBarImageAlignment" style={{ color: "red" }}>{this.state.errorResponse ? this.state.errorMessage : null}</span>
              <button className="lnr lnr-cross modal__close-btn" type="button" style={{ color: "black", right: 40 }}
                onClick={this.props.closeOrder} />
            </div>
            <div>

              {this.state.ViewType && this.state.ViewType.length > 0 ?
                this.state.ViewType.map((item, i) => {

                  return this.showPresetHeader(item)

                }) : ""}
            </div>

          </ModalBody>
        </Modal>
        {
          this.state.isopenConfirmaionModelforSave ?
            this.getConfirmaionModelforSave() : null
        }
      </div >
    );
  }
}
export default withTranslation('common')(LoadPreset);
