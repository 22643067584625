import React from 'react';
import { Button, Modal, ModalBody } from 'reactstrap';
import { withTranslation } from 'react-i18next';

// config file
class LogoutModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        };
    }

    logout = () => {
        this.props.closeLogoutModal('close');
    }

    render() {
        const { t } = this.props
        return (
            <div >
                <Modal isOpen={this.props.openLogoutModal} fade={false}
                    className={` modal-dialog-centered modal-dialog--primary m-auto`}
                    style={{ maxWidth: 400 }}>
                    <ModalBody style={{ padding: 10 }}>
                        <div style={{ marginBottom: 10 }}>{t('Are you sure you want to Logout')}?</div>
                        <Button color="primary" outline onClick={this.props.closeLogoutModal}
                            className='deleteModalBtn marginRight'>{t('No')}</Button>
                        <Button color="primary" outline onClick={this.logout}
                            className='deleteModalBtn'>{t('Yes')}</Button>
                    </ModalBody>

                </Modal>

            </div>
        );
    }
}
export default withTranslation('common')(LogoutModal);
