import React from 'react';
import { Button, } from 'reactstrap';
import SaveOrder from './SavePreset'
import ShowPreset from './ShowPreset'
import { withTranslation } from 'react-i18next';
import fetchMethodRequest from "../../../config/service"


// config file
class PresetBtnActions extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      saveOrderModal: false,
      showOrderModal: false,
      isExpanded: false,
      active_Preset: this.props.active_Preset ? this.state.active_Preset : null,
      isClickedDownload: false,
      value: null,
      openPanel: false,
      presets: [],
      rowIndex: null,
      editedFieldoptions: null,
      activePreset: "",
      newCreate: false
    };
    this.loadAllPresets()
  }

  componentWillUnmount() {
    if (this.props.onRef) {
      this.props.onRef(null);
    }
    document.removeEventListener('mousedown', this.handleClickOutside)

  }

  componentDidMount = async () => {
    if (this.props.onRef) {
      this.props.onRef(this);
    }
    await this.loadAllPresets()

  }

  //sending message from the response to parent component
  showToasterMsg = (msg, type) => {
    // this.props.getResponseMsgfromServer(msg, type)
  }


  /**
* Setting the active preset 
*/
  setActivePreset = async (preset) => {
    this.setState({ active_Preset: preset })
  }

  changeOrder = async (type, message, errorView, presetName) => {
    if (type.active_Preset || type) {
      await this.setState({
        isLoading: true,

      })
      // this.handlePercentage("1")
    }
    this.setActivePreset(presetName)
    await this.setState({
      isshowToasterMsg: true,
      newCreate: true,
      showToasterMsg: message,
      backgroundColor: errorView === "Error" ? "rgb(250 167 157)" : "#d1fbc1",

    })
    // this.showToasterMsg(message, errorView)
    this.closeOrderModal()
    if (type !== "") {
      await this.loadAllPresets()

    }
  }

  saveOrder = async () => {
    await this.setState({
      saveOrderModal: true,
      newPresetCreated: true
    })
  }

  closeOrderModal = async () => {
    await this.setState({
      saveOrderModal: false,
      showOrderModal: false,
      newPresetCreated: false,
      openDeleteModal: false,
      selectedRows: [],
      deleArray: [],
    })
  }

  showavailableOrderFormats = async () => {
    await this.setState({
      showOrderModal: true,
      newCreate:false
    })
  }


  getExpandedandFocus = () => {
    this.setState({
      isExpanded: !this.state.isExpanded
    })

  }

  loadAllPresets = async (name) => {
    let filterCriteria = {}
    let presets = []
    let screen = this.props.type
    this.props.getisLoading()
    filterCriteria['criteria'] = [{ key: 'Screen', value: screen, type: 'eq' }];
    await fetchMethodRequest('GET', `preset?filter=${JSON.stringify(filterCriteria)}`).then(async (response) => {
      if (response && response["presets"] && response["presets"].length > 0) {
        presets = response["presets"]
        await this.setState({
          presets: presets
        })
        this.getDataFromServerToSetPreset(this.state.newCreate?this.state.active_Preset:name)
        this.props.getisLoading("false")
      }
      if (!this.state.newCreate) {
        this.setActivePreset(name ? name : response &&response.defaultPreset? response.defaultPreset : "")
      }
      if (response.defaultPreset && response.defaultPreset !== null) {
        await this.setState({
          defaultPresetForUser: response.defaultPreset,
        })
      }
    })
  }
  /**
 * Calling the Preset button view
 */

  getPresetButton() {
    return (
      <div className="row ml-1 mr-1 mb-1 d-flex justify-content-between" >
        <div className="" id=''>
          <Button
            color="primary"
            outline
            style={{
              height: "32px", marginBottom: 5
              //  marginLeft: "15px"
            }}
            onClick={this.saveOrder}
          >

            Save Preset
          </Button>
          <Button
            color="primary"
            outline
            style={{ height: "32px", marginBottom: 5 }}
            onClick={() => this.showavailableOrderFormats()}
          >
            Load Preset
          </Button>
        </div>
        {/* <div className="record_counts"> */}
        <div className='alignCenter'><span className="current_preset">Active Preset:</span>{this.state.active_Preset}</div>

      </div>
    )
  }

  updateTableFields = async (tablefieldsToShow) => {

    await this.setState({ tablefieldsToShow: tablefieldsToShow })
  }

  closeModelOnDefault = async () => {
    await this.setState({
      showOrderModal: false
    }
    )
  }




  /**
* open the preset model
*/
  showOrders() {
    let data = JSON.parse(localStorage.getItem(`${this.props.storedKey}_column_order`))
    return (
      <SaveOrder
        saveOrderModal={this.state.saveOrderModal}
        closeOrder={this.closeOrderModal}
        ActivePreset={this.state.active_Preset ? this.state.active_Preset : ""}
        activeTab={this.props.activeTab === "3" ? "Names" : this.props.activeTab === "4" ? "Addresses" : this.props.activeTab}
        EditorScreen={this.props.EditorScreen}
        type={"Sellers"}
        showToasterMsg={this.showToasterMsg}
        tablefieldsToShow={data}
        changeOrder={this.changeOrder}
      />
    )
  }


  /**
 * 
 * @param {String} name 
 */
  getDataFromServerToSetPreset = async (name) => {
    await this.setState({ isLoading: true })
    // await this.changebackgroundColortoDefault()
    let DefaultPreset
    if (name) {
      DefaultPreset = name
    } else {
      DefaultPreset = this.state.defaultPresetForUser ? this.state.defaultPresetForUser : null
    }
    if (DefaultPreset) {
      let currentPreset = this.state.presets.find(elem => elem.name === DefaultPreset)
      if (currentPreset && currentPreset.preferences) {
        localStorage.setItem(`${this.props.storedKey}_column_order`, JSON.stringify(currentPreset.preferences));
        await this.props.getTableFieldsOrder(currentPreset.preferences)
      }
    }
    this.closeOrderModal()
  }

  /**
* Close the preset model
*/
  closeOrderModal = async () => {
    await this.setState({
      saveOrderModal: false,
      showOrderModal: false,
      openDeleteModal: false,
      selectedRows: [],
      deleArray: [],
    })
  }
  /**
* Show the preset model
*/
  showavailableOrders() {
    return (<ShowPreset
      saveOrderModal={this.state.showOrderModal}
      closeOrder={this.closeOrderModal}
      closeModelOnDefault={this.closeModelOnDefault}
      setTableFieldsOrderEmpty={this.props.setTableFieldsOrderEmpty}
      getDataFromServerToSetPreset={this.loadAllPresets}
      activeTab={this.props.activeTab}
      type={"Sellers"}
      // keyname={keyname}
      tablefieldsToShow={this.props.tablefieldsToShow}
      changeOrder={this.changeOrder}
    />
    )

  }
  openPanel = async () => {
    this.setState({ openPanel: true })
  }
  openPanelPopup = async () => {

  }

  render() {
    return (
      <div className='' style={{ background: "white" }} >
        {this.getPresetButton()}
        {this.state.saveOrderModal ? this.showOrders() : null}
        {this.state.openExcelModal ? this.excelModel() : null}
        {this.state.showOrderModal ? this.showavailableOrders() : null}
        {this.state.openPanel ? this.openPanelPopup() : null}
      </div >
    );
  }
}
export default withTranslation('common')(PresetBtnActions);