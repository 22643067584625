import React from 'react';
import { Container } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import ClaimsPacket from './components/ClaimsPacket';

const ClaimsPacketPage = ({ t }) => (
  <Container>
    <ClaimsPacket />
  </Container>
);

ClaimsPacket.propTypes = {
  t: PropTypes.func.isRequired,
};

export default withTranslation('common')(ClaimsPacketPage);