
import React from 'react';
import { Field, reduxForm } from 'redux-form';
import { Button, Card, CardBody } from 'reactstrap';
import { connect } from 'react-redux';
import { load as loadAccount } from '../../../redux/reducers/commonReducer';
// fecth method from service.js file
import fetch from '../../../config/service';
import configMessage from '../../../config/configMessages';
// show message 
import DefaultInput from '../../../shared/components/form/DefaultInput';
import ExpiredSessionModal from '../../../containers/Cruds/CommonModals/ExpiredSessionModal';
import NoViewComponent from '../../../containers/Cruds/CommonDataTable/NoView';
import validate from '../../Validations/validate';
import Loader from '../../App/Loader';
import { withTranslation } from 'react-i18next';
import config from '../../../config/config'
import { Redirect } from 'react-router';
const required = value => (value || typeof value === 'string' ? undefined : configMessage.fillField)

//validations
// let newpassval = value => (value ? config.userPasswordRegex.test(value) ? undefined : 'Password must have at least 12 characters and contain at least 1 Uppercase and 1 special character.' : configMessage.fillField)
let timeout

class NameScoring extends React.Component {
	constructor(props) {
		super(props);
		this.buttonActionType = null;
		this.state = {
			isLoginSuccess: false,
			isLoading: false,
			BucketFromScore: "",
			screenPermissions:"",
		};
	}

	componentDidMount = async () => {
		let permissions = localStorage.getItem('rolePermissions')
		permissions=permissions?JSON.parse(permissions)["Bucket Check"] :"NoView"
		this.setState({ isLoading: false ,screenPermissions:permissions});
	}

	componentDidUpdate() {
		if (this.state.errorResponse) {
			setTimeout(() => this.setState({ errorResponse: false, errorMessage: '' }), 30000);
		}
		if (!this.state.errorResponse && localStorage.getItem('serverError') === "true") {
			localStorage.removeItem('serverError')
		}
		// if (localStorage.getItem('loggedTime')) {
		// 	let loggedTime = localStorage.getItem('loggedTime')
		// 	if (timeout) {
		// 		clearTimeout(timeout)
		// 	}
		// 	timeout = setTimeout(() => this.setState({ expiredSessionModal: true, openedMultiTabs: true }), loggedTime);
		// }
		if (this.state.progress === 100) {
			setTimeout(() => this.setState({ progress: 0 }), 200);
		}
	}
	// clear input data
	clearInputFields = () => {
		this.props.reset();
	}
	//Get THreshold Values from SQL
	getDataFromServer = async (values) => {
		// this.props.isShowLoader(true)
		fetch('GET', 'businessnames/bucket?scoringType=RegularScoring')
			.then(async (response) => {
				if (response && response.details) {
					await this.setState({
						dashboardData: response.details,
						greenNameOne: response.details.greenNameOne,
						greenNameTwo: response.details.greenNameTwo,
						greenAddOne: response.details.greenAddOne,
						greenAddTwo: response.details.greenAddTwo,
						greenCSZ_One: response.details.greenCSZ_One,
						greenCSZ_Two: response.details.greenCSZ_Two,
						blueNameOne: response.details.blueNameOne,
						blueAddOne_1: response.details.blueAddOne_1,
						blueAddOne_2: response.details.blueAddOne_2,
						blueCSZ_One_1: response.details.blueCSZ_One_1,
						blueCSZ_One_2: response.details.blueCSZ_One_2,
						yellowNameOne_1: response.details.yellowNameOne_1,
						yellowNameOne_2: response.details.yellowNameOne_2,
						yellowNameTwo_1: response.details.yellowNameTwo_1,
						yellowNameTwo_2: response.details.yellowNameTwo_2,
						yellowAddOne: response.details.yellowAddOne,
						yellowAddTwo: response.details.yellowAddTwo,
						yellowCSZ_One: response.details.yellowCSZ_One,
						yellowCSZ_Two: response.details.yellowCSZ_Two,
						brownNameOne: response.details.brownNameOne,
						brownAddOne: response.details.brownAddOne,
						brownCSZ_One: response.details.brownCSZ_One,
					});
					this.getBucketDataFromServer(values)

				} else if (response && response.errorMessage && (response.errorMessage === config.tokenMessage || response.errorMessage === config.sessionExpired)) {
					await this.setState({
						sessionExpiryModal: true
					})
				} else if (response && response.errorMessage && response.errorMessage === config.warningMessage) {
					await this.setState({
						sessionWarningModal: true
					})
				}
				this.props.isShowLoader(false)
			}).catch((err) => {
				return err;
			});
	}

	getBucketDataFromServer = async (values) => {
		this.setState({
			BucketFromScore: '',
			// isLoadingsearch: true
		});
		let nameScore = Number(values.nameScore) ? Number(values.nameScore) / 100 : 0
		let addressLineScore = Number(values.addLine) ? Number(values.addLine) / 100 : 0
		let CityStateZipScore = Number(values.cityZip) ? Number(values.cityZip) / 100 : 0


		var bucket = "";
		if (((nameScore * 100 >= this.state.greenNameOne) && ((addressLineScore * 100 >= this.state.greenAddOne) && (CityStateZipScore * 100 >= this.state.greenCSZ_One)))
			|| ((nameScore * 100 >= this.state.greenNameTwo) && ((addressLineScore * 100 >= this.state.greenAddTwo) || (CityStateZipScore * 100 >= this.state.greenCSZ_Two)))) {
			bucket = "Green";
		} else if (nameScore * 100 >= this.state.blueNameOne && ((addressLineScore * 100 >= this.state.blueAddOne_1 && addressLineScore * 100 <= this.state.blueAddOne_2)
			|| (CityStateZipScore * 100 >= this.state.blueCSZ_One_1 && CityStateZipScore * 100 <= this.state.blueCSZ_One_2))) {
			bucket = "Blue";
		} else if (((nameScore * 100 >= this.state.yellowNameOne_1 && nameScore * 100 <= this.state.yellowNameOne_2) && (addressLineScore * 100 >= this.state.yellowAddOne && CityStateZipScore * 100 >= this.state.yellowCSZ_One))
			|| ((nameScore * 100 >= this.state.yellowNameTwo_1 && nameScore * 100 <= this.state.yellowNameTwo_2) && (addressLineScore * 100 >= this.state.yellowAddTwo || CityStateZipScore * 100 >= this.state.yellowCSZ_Two))) {
			bucket = "Yellow";
		} else if ((nameScore * 100 < this.state.brownNameOne) && (addressLineScore * 100 < this.state.brownAddOne) && (CityStateZipScore * 100 < this.state.brownCSZ_One)) {
			bucket = "Brown";
		}
		else {
			bucket = "Black";
		}
		this.setState({
			BucketFromScore: bucket,
			isLoadingsearch: false
		})
	}

	submit = (values) => {
		this.getDataFromServer(values)
	}

	cancelexpiredSessionModal = async () => {
		await this.setState({
			expiredSessionModal: false
		})
	}
	expiredSessionModal() {
		return (
			<ExpiredSessionModal
				openConfirmationModal={this.state.expiredSessionModal}
				cancelSessionWarningModal={this.cancelexpiredSessionModal}
				getData={this.getDataFromServer}

			/>
		)
	}
	onChange = async (e) => {

	}

	render() {
		const { handleSubmit } = this.props;
		return (
			this.state.screenPermissions==="Edit" ||this.state.screenPermissions==="View" ?

			<div>
				<form className="form" onSubmit={handleSubmit(this.submit)} style={{ height: 300 }}>
					<Card className='pb-0 '>
						<Loader loader={this.state.isLoading} />
						<CardBody className='p-0 mt-3'
						>
							<Loader loader={this.state.isLoading} />
							<h3 className="topBarImageAlignment" style={{ margin: '10px 0px' }}>Bucket Check</h3>
							<div className='d-flex'>
								<div className="mt-4">
									<div className="d-flex">
										<h6 className="ml-4" style={{ marginTop: '6px', width: "350px" }}>Name Score</h6>
										<div className='form__form-group-field'>
											<Field type="text" className='inputLogin'
												type={'text'}
												name="nameScore"
												component={DefaultInput}
												placeholder='Percentage'
												validate={required}
											/>
										</div>
									</div>
								</div>
							</div>
							<div className='d-flex'>
								<div className="d-flex">
									<h6 className="ml-4" style={{ marginTop: '6px', width: "350px" }}>Address Line 1 Score</h6>
									<div className='form__form-group-field'>
										<Field type="text" className='inputLogin'
											type={'text'}
											name="addLine"
											component={DefaultInput}
											placeholder='Percentage'
											validate={required}
										/>
									</div>
								</div>
								<div className="d-flex">
									<h2 className="ml-4" style={{ marginTop: '6px', width: "350px" }}>{this.state.BucketFromScore}</h2>
								</div>
							</div>
							<div className='d-flex'>
								<div >
									<div className="d-flex">
										<h6 className="ml-4" style={{ marginTop: '6px', width: "350px" }}>City, State & Zip Score</h6>
										<div className='form__form-group-field'>
											<Field type="text" className='inputLogin'
												type={'text'}
												name="cityZip"
												component={DefaultInput}
												placeholder='Percentage'
												validate={required}
											/>
										</div>
									</div>
								</div>
							</div>
							<h6 className='ml-4'>Note: Teal bucket will not be displayed.
							</h6>
							<div className="justify-content-center" style={{ textAlignLast: "center" }}><Button
								className="justify-content-center"
								color="primary"
								type="submit"
							>Get Related Bucket</Button></div>
						</CardBody>
					</Card>
				</form >
				{this.state.expiredSessionModal ? this.expiredSessionModal() : null}
				{this.state.forceLogout || this.state.sessionExpiryModal ? <Redirect to={'/log_in'} /> : null}
				{this.state.sessionWarningModal ? <Redirect to={'/log_in'} /> : null}
			</div>:this.state.screenPermissions===""?null:<NoViewComponent/>
		);
	}
}


NameScoring = reduxForm({
	form: "Name Scoring Form", // a unique identifier for this form
	validate,
	enableReinitialize: true,
})(NameScoring);

// You have to connect() to any reducers that you wish to connect to yourself
NameScoring = connect(
	state => ({
		initialValues: state.commonData.data // pull initial values from account reducer
	}),
	{ load: loadAccount } // bind account loading action creator
)(NameScoring);

export default withTranslation('common')(NameScoring);
