import React, { PureComponent } from 'react';
import {
    Card, CardBody, Col, Button
} from 'reactstrap';
import PropTypes from 'prop-types';
import { reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import { load as loadAccount } from '../../../redux/reducers/commonReducer';
import { Redirect } from 'react-router-dom';
// file upload
import { Checkbox } from 'primereact/checkbox';
import { Calendar } from 'primereact/calendar';
import { MultiSelect } from 'primereact/multiselect';
import { AutoComplete } from 'primereact/autocomplete';
import apiCalls from '../../../config/apiCalls';
import fetch from '../../../config/service';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { withTranslation } from 'react-i18next';
import { setUserAction } from '../../../redux/actions/userActions';
import configMessages from '../../../config/configMessages';
import AvailableToMap from './components/AvailableToMap';
import statesList from '../../../shared/states.json'
import RolePermissions from '../../Cruds/CommonModals/Permissions'
import NoViewComponent from '../../Cruds/CommonDataTable/NoView'


const renderField = ({
    input, placeholder, type, isDisable, meta: { touched, error },
}) => (
    <div className="form__form-group-input-wrap">
        <input {...input} disabled={isDisable} placeholder={placeholder} type={type} />
        {touched && error && <span className="form__form-group-error">{error}</span>}
    </div>
);


renderField.propTypes = {
    input: PropTypes.shape().isRequired,
    placeholder: PropTypes.string,
    type: PropTypes.string,
    meta: PropTypes.shape({
        touched: PropTypes.bool,
        error: PropTypes.string,
    }),
};

renderField.defaultProps = {
    placeholder: '',
    meta: null,
    type: 'text',
    isDisable: false
};

class AvailableToMapFilters extends PureComponent {
    static propTypes = {
        t: PropTypes.func,
        handleSubmit: PropTypes.func.isRequired,
        reset: PropTypes.func.isRequired,
        pristine: PropTypes.bool.isRequired,
        submitting: PropTypes.bool.isRequired,
    };

    constructor(props) {
        super(props);
        this.state = {
            showPassword: false,
            locationProps: '',
            States: [...statesList.map((e) => { return { label: e.shortCode, value: e.shortCode } })],
            filterCriteria: { criteria: [] },
            SelectedStates: [],
            DealType: [],
            DealTypeV2: ["BK", "CR"],
            isGreen25: true,
            screenPermissions:"",
            propertyStatusArray: [],
            isExcludeRoyalties: true,
            excludeCoOwners: false,
            isexcludePossiblyPaid: true,
            // excludePossiblyPaid: true,
            ranges: [">$25k"],
            selectedBK: true,
            selectedCR: true,
            defaultNameIssueCodes: []
        };
    }

    componentDidMount = async () => {
        this.searchsellerItems()
        await this.getDepartments()
        await this.getIssueNameCode()
        await this.getLegacyIssueNameCode()
        await this.getNewStatusForEditor()
        let screenPermissions = RolePermissions.screenPermissions("Available To Map V2");
        await this.setState({
            screenPermissions: screenPermissions
        })
        let user = JSON.parse(localStorage.getItem('loginCredentials'));
        let DepartmentArray = [], departmentIdArray = []
        // let user = JSON.parse(localStorage.getItem('loginCredentials'));
        if (user && user.department && user.roleName && user.roleName.includes("CoreOps")) {
            DepartmentArray.push(user.department)
            departmentIdArray.push(user.departmentId)
            await this.setState({ Department: DepartmentArray, departmentIdArray: departmentIdArray })
            // this.submit()
        }
        let userDetails = JSON.parse(localStorage.getItem('loginCredentials'))
        // let apiUrl=''
        this.setState({
            isLoading: true
        });
        let filterCriteria = {};
        filterCriteria['criteria'] = [{ key: 'userObjId', value: userDetails['_id'], type: 'eq' }];
        fetch('GET', `settings`).then(async (response) => {
            if (response && response.settings) {
                let settingsData = response.settings[0]
                this.setState({
                    isLoading: false,
                    settingsData: settingsData,
                    excludePossiblyDefaultEnable: settingsData.excludePossiblyDefaultEnable,
                    excludePossiblyPaid: settingsData.excludePossiblyDefaultEnable
                });
            }
        })
    }
    getNewStatusForEditor = async (val) => {

        let propertyStatusArray = [];
        let filterCriteria = {}

        filterCriteria['criteria'] = filterCriteria['criteria'] = [
            { key: 'activeIndicator', value: true, type: 'eq', add_Filter: true },
            { key: 'propertyStatusFlag', value: true, type: 'eq', add_Filter: true },
            { key: 'propertyStatusUsage', value: 'Property Editor', type: 'eq', add_Filter: true }

        ]

        filterCriteria.sortfield = "stageSequence, statusSequence, reasonSequence"
        filterCriteria.direction = "asc"

        return fetch('GET', `${apiCalls.propertyStatusReason}?filter=${JSON.stringify(filterCriteria)}&dropdown=${val ? "reason" : "status"}`)
            .then(async (response) => {
                if (response && response["propertyStatusReasons"].length > 0) {
                    let propertyStatusReasons = response["propertyStatusReasons"]
                    for (let reason of propertyStatusReasons) {
                        propertyStatusArray.push({ label: reason.status, value: { status: reason.status, tooltip: reason.statusDefinition } })
                    }
                    await this.setState({ propertyStatusArray: propertyStatusArray })
                } else if (response && response["propertyStatusReasons"].length == 0) {

                    await this.setState({ propertyStatusArray: [], propertyReasonArray: [] })
                } else if (response && response['errorMessage'] === configMessages.warningMessage) {
                    await this.setState({
                        sessionWarning: true,

                    })
                }
            }).catch((err) => {
                return err;
            })
    }
    cancelSessionWarningModal = async () => {
        await this.setState({
            sessionWarning: false
        })
    }
    //Issue Names Dropdown
    getIssueNameCode = async () => {
        let issueNameCodes = [], newNameIssueCode = [], defaultNameIssueCodes = []
        let filter = { "limit": 200, "page": 1, "criteria": [{ key: "DWRowIsCurrent", value: true, type: "eq" }, { key: "BusinessNameIssueCode", value: "N/A", type: "ne" }] }
        let urlNames = `${apiCalls.businessnameissuecodeconfiguration}?filter=${JSON.stringify(filter)}`
        await fetch("GET", urlNames)
            .then(async (response) => {
                if (response && response.businessNameIssueCodeConfigurations) {
                    for (let name of response.businessNameIssueCodeConfigurations) {
                        let index = issueNameCodes.findIndex(obj => obj.label === "Blank")
                        let codeIndex = issueNameCodes.findIndex(obj => obj.label === name.BusinessNameIssueCode)
                        if (codeIndex === -1) {
                            if (name.BusinessNameIssueCode === "" && index === -1) {
                                issueNameCodes.push({ label: "Blank", value: name.BusinessNameIssueCode, title: name.BusinessNameIssueCodeDescription, BusinessNameIssueCodeId: name.BusinessNameIssueCodeId })
                                newNameIssueCode.push(name.BusinessNameIssueCode)

                            }
                            else if (name.BusinessNameIssueCode && name.BusinessNameIssueCode !== null && name.BusinessNameIssueCode !== "2" && name.BusinessNameIssueCode.split(".")[0] !== "3") {
                                issueNameCodes.push({ label: name.BusinessNameIssueCode, value: name.BusinessNameIssueCode, title: name.BusinessNameIssueCodeDescription, BusinessNameIssueCodeId: name.BusinessNameIssueCodeId })
                                if (name.BusinessNameIssueCode !== "9.9") {
                                    newNameIssueCode.push(name.BusinessNameIssueCode)
                                }
                            }
                        }
                    }

                    let obj = {
                        dealType: ["BK", "CR"],
                        newNameIssueCode: newNameIssueCode,
                    }
                    await this.setState({
                        issueNameOptions: issueNameCodes, newNameIssueCode: newNameIssueCode, defaultNameIssueCodes: newNameIssueCode,
                        body: obj,
                    })
                    let filterCriteria = this.state.filterCriteria
                    filterCriteria = await this.setNewnameIssue(filterCriteria)

                    console.log("criteria", filterCriteria)
                    await this.setState({
                        filterCriteria: filterCriteria
                    })
                } else {
                    this.setState({ issueNameOptions: [] })
                }
            }).catch((err) => {
                return err;
            });
    }
    //Issue Names Dropdown
    getLegacyIssueNameCode = async () => {
        let LegacyIssueNameCodes = []
        let filter = { "limit": 200, "page": 1, "criteria": [{ key: "DWRowIsCurrent", value: true, type: "eq" }] }
        let urlNames = `${apiCalls.businessnameissuecodeconfiguration}?filter=${JSON.stringify(filter)}`
        await fetch("GET", urlNames)
            .then(async (response) => {
                if (response && response.businessNameIssueCodeConfigurations) {
                    for (let name of response.businessNameIssueCodeConfigurations) {
                        let index = LegacyIssueNameCodes.findIndex(obj => obj.label === name.LegacyIssueID)
                        let blankIndex = LegacyIssueNameCodes.findIndex(obj => obj.label === "Blank")
                        if (index === -1) {
                            if (name.LegacyIssueID === "" && blankIndex === -1) {
                                LegacyIssueNameCodes.push({ label: "Blank", value: name.LegacyIssueID, title: name.BusinessNameIssueCodeDescription, BusinessNameIssueCodeId: name.BusinessNameIssueCodeId })
                            }
                            else if (name.LegacyIssueID && name.LegacyIssueID !== null) {
                                LegacyIssueNameCodes.push({ label: name.LegacyIssueID, value: name.LegacyIssueID, title: name.BusinessNameIssueCodeDescription, BusinessNameIssueCodeId: name.BusinessNameIssueCodeId })
                            }
                        }
                    }

                    await this.setState({ LegacyIssueNameCodes: LegacyIssueNameCodes })
                } else {
                    this.setState({ LegacyIssueNameCodes: [] })
                }
            }).catch((err) => {
                return err;
            });
    }
    onChangeDepartment = async (e) => {
        let DepartmentArray = [], departmentIdArray = []
        for (let id of e.value) {
            let z = this.state.taskDepartmentItems.find(elem => elem.value === id)
            if (z) {
                DepartmentArray.push(z.department)
                departmentIdArray.push(z.departmentId)
            }
        }
        this.setState({ Department: DepartmentArray, departmentIdArray: departmentIdArray, Department: e.value, })
        await this.availableToMapV2Ref.onChangeDepartment(e.value, 'selectedDepartment', departmentIdArray)

        // await this.getDataFromServer(this.state.filterCriteria)
    }
    /*
     Fetch all Departments from the api 
     */
    getDepartments = () => {
        let apiUrl;
        let filterCriteria = {}
        filterCriteria.criteria = [{ key: "CoreOpsTeam", value: true, type: "eq" }, { key: 'activeIndicator', value: true, type: 'eq' }]
        return fetch('GET', `${apiCalls.Departments}?type=exportToCsv&filter=${JSON.stringify(filterCriteria)}`)
            .then(async (response) => {
                if (response && response.departments) {
                    let labels = response.departments;
                    let modifiedLabels = [];
                    for (let label of labels) {
                        modifiedLabels.push({
                            label: label.departmentName,
                            value: label.departmentName,
                            departmentId: label._id
                        })
                    }
                    /* Sorting the response based on the Alphabetical descending*/
                    const sorted = modifiedLabels.sort((a, b) => {
                        let fa = a.label.toLowerCase(),
                            fb = b.label.toLowerCase();
                        if (fa < fb) {
                            return -1;
                        }
                        if (fa > fb) {
                            return 1;
                        }
                        return 0;
                    });
                    await this.setState({
                        taskDepartmentItems: sorted
                    })
                } else {
                    this.setState({
                        taskDepartmentItems: []
                    });
                    // let perObj = this.state.perObj
                    if (response) {
                        if (response['errorMessage'] === configMessages.warningMessage) {
                            this.setState({
                                sessionWarning: true
                            })
                        } else if (response['errorMessage'] === configMessages.tokenMessage || response['errorMessage'] === configMessages.sessionExpired) {
                            this.setState({
                                sessionExpiryModal: true
                            })

                        } else if (localStorage.getItem('sessionexpired') === true) {
                            let sessionexpired = await localStorage.getItem('sessionexpired')
                            if (sessionexpired === "true") {
                                await this.setState({ sessionExpiryModal: true })
                            }
                        }
                    }
                }
            }).catch((err) => {
                return err;
            });
    }
    getReasonForEditor = async (val, key) => {
        var ReasonsArray = [], data = []
        let filterCriteria = {}
        let array = []
        if (val && val.length > 0) {
            // if (key) {
            //   data = val.split(",")
            // } else {
            for (var obj of val) {
                if (!array.includes(obj.status)) {
                    if (obj.status) {
                        data.push(obj.status)
                    }
                }
            }

            filterCriteria['criteria'] = [{ key: 'status', value: data, type: 'in', add_Filter: true }, { key: 'activeIndicator', value: true, type: 'eq', add_Filter: true },
            { key: 'propertyStatusUsage', value: 'Property Editor', type: 'eq', add_Filter: true }
            ];
        }
        filterCriteria.sortfield = "stageSequence, statusSequence, reasonSequence"
        filterCriteria.direction = "asc"
        return fetch('GET', `${apiCalls.propertyStatusReason}?filter=${JSON.stringify(filterCriteria)}&dropdown=${val ? "reason" : "status"}`)
            .then(async (response) => {
                if (response && response["propertyStatusReasons"].length > 0) {
                    let propertyStatusReasons = response["propertyStatusReasons"]
                    for (let reason of propertyStatusReasons) {
                        ReasonsArray.push({ label: reason.status + " - " + reason.reason, value: { reason: reason.reason, tooltip: reason.statusReasonDefinition } })
                    }
                    await this.setState({ shownreasons: ReasonsArray })
                } else if (response && response["propertyStatusReasons"].length == 0) {

                    await this.setState({ shownreasons: [] })
                } else if (response && response['errorMessage'] === configMessages.warningMessage) {
                    await this.setState({
                        sessionWarning: true,

                    })
                }
            }).catch((err) => {
                return err;
            })
    }
    editorTemplateForDeal(option) {
        return (`${option.DealType} - ${option.SellerNickName} (${option.DealYear ? option.DealYear : ""})`)
    }

    handleChange = async (e, type, key) => {
        var ranges = this.state.ranges
        let filtercriteria = await this.availableToMapV2Ref.getFiltercriteria()
        var index;
        if (type === "selectedPropertyStatus") {
            await this.setState({
                selectedPropertyStatus: e.value,
                selectedPropertyReasons: "",
            })
            let dataforStatus = []
            await this.getReasonForEditor(e.value, key)

        } else if (type === "selectedPropertyReasons") {
            await this.setState({
                selectedPropertyReasons: e.value
            })
            let dataforReasons = []
            if (e.value) {

                for (var obj of e.value) {
                    if (!dataforReasons.includes(obj.reason)) {
                        if (obj.reason) {
                            dataforReasons.push(obj.reason)
                        }
                    }
                }
            }


            // this.submit()
        } else if (type === "newNameIssueCode") {
            await this.setState({
                newNameIssueCode: e
            })
        } else if (type === "legacyNameIssueCode") {
            await this.setState({
                legacyNameIssueCode: e
            })
        } else if (type === "Seller") {
            await this.setState({
                selectedSeller: e.value,
            })
        } else if (type === "DealType") {
            await this.setState({
                DealTypeV2: e
            })
        } else {
            await this.setState({
                SelectedStates: e
            })
        }
        await this.setState({
            ranges: ranges
        })
    }

    searchsellerItems = async (event, criteria) => {
        let apiUrl
        if (event && event.query.length > 0) {
            apiUrl = `${apiCalls.propertysellers}?str=${event.query}`
        } else {
            apiUrl = `${apiCalls.propertysellers}`

        }
        return fetch('GET', apiUrl)
            .then(async (response) => {
                if (response) {
                    let dropdownData = [], dropdownDataVal = [];
                    if (response["sellers"] && response["sellers"].length && response["sellers"].length > 0) {
                        dropdownDataVal = response["sellers"]
                    } else if (response && response.errorMessage && (response.errorMessage === configMessages.warningMessage || response.errorMessage === configMessages.tokenMessage)) {
                        this.setState({
                            sessionExpiryModal: true
                        });
                    } else if (response && response['errorMessage'] === configMessages.warningMessage) {
                        this.setState({
                            SessionWarningModal: true
                        })
                    }
                    if (dropdownDataVal && dropdownDataVal.length === 0) {
                        this.setState({
                            filteredSuggestions: [],
                            noData: true
                        });
                    } else {
                        dropdownData = dropdownDataVal;
                    }
                    await this.setState({
                        filteredSuggestions: dropdownDataVal
                    });
                    if (criteria) {
                        await this.setState({
                            selectedSeller: dropdownData[0]
                        })
                        this.searchnameItems(criteria)
                    }
                }
            }).catch((err) => {
                return err;
            });
    }


    handleChangePossiblyPaid = (type) => {
        this.setState({
            isexcludePossiblyPaid: !this.state.isexcludePossiblyPaid
        })
    }
    handleExcludePossiblyPaid = async (type) => {
        // this.setState({
        //   excludePossiblyPaid: !this.state.excludePossiblyPaid
        // })
        this.setState(prevState => ({
            excludePossiblyPaid: !prevState.excludePossiblyPaid
        }))
    }



    resetFilters = async () => {
        let filterCriteria = this.availableToMapV2Ref.getFiltercriteria();
        // filterCriteria["criteria"] = []
        fetch('GET', `settings`).then(async (response) => {
            if (response && response.settings) {
                let settingsData = response.settings[0]
                this.setState({
                    settingsData: settingsData,
                    excludePossiblyDefaultEnable: settingsData.excludePossiblyDefaultEnable,
                    excludePossiblyPaid: settingsData.excludePossiblyDefaultEnable
                });
            }
        })
        await this.setState({
            SelectedStates: [],
            DealType: [],
            DealTypeV2: ["BK", "CR"],
            selectedSeller: "",
            Department: [],
            departmentIdArray: [],
            excludeCoOwners: false,
            isexcludePossiblyPaid: true,
            // excludePossiblyPaid: true,
            isExcludeRoyalties: true,
            selectedPropertyStatus: [],
            fromDate: "",
            ScoreDate: "",
            selectedPropertyReasons: [],
            newNameIssueCode: this.state.defaultNameIssueCodes,
            legacyNameIssueCode: [],
            ranges: [">$25k"],
        })
        let Obj = {
            ranges: this.state.ranges,
            fromSummaries: true
        }
        let user = JSON.parse(localStorage.getItem('loginCredentials'));
        let DepartmentArray = [], departmentIdArray = []
        // let user = JSON.parse(localStorage.getItem('loginCredentials'));
        if (user && user.department && user.roleName && user.roleName.includes("CoreOps")) {
            DepartmentArray.push(user.department)
            departmentIdArray.push(user.departmentId)
            await this.availableToMapV2Ref.onChangeDepartment(DepartmentArray, 'selectedDepartment', departmentIdArray)

            // this.submit()
        } else {
            await this.availableToMapV2Ref.onChangeDepartment([], 'selectedDepartment', [])

        }
        this.setState({ Department: DepartmentArray.length > 0 ? DepartmentArray : null, departmentIdArray: departmentIdArray.length > 0 ? departmentIdArray : null })

        if (this.state.selectedSeller)
            Obj.sellerId = this.state.selectedSeller.EstateID
        if (this.state.SelectedStates && this.state.SelectedStates.length > 0) {
            Obj.states = this.state.SelectedStates
        }
        if (this.state.DealType && this.state.DealType.length > 0) {
            Obj.DealType = this.state.DealType
        }
        if (this.state.DealTypeV2 && this.state.DealTypeV2.length > 0) {
            Obj.DealType = this.state.DealTypeV2
        }
        this.submit("clear")

    }

    setNewnameIssue = (filterCriteria) => {
        let newNameIssueCodeindex = filterCriteria["criteria"].findIndex(obj => obj.key === "NewNameIssueCode")
        if (newNameIssueCodeindex !== -1) { filterCriteria["criteria"].splice(newNameIssueCodeindex, 1) }
        if (this.state.newNameIssueCode && this.state.newNameIssueCode.length > 0) {
            filterCriteria.criteria.push({ key: "NewNameIssueCode", value: this.state.newNameIssueCode, type: "in" })
        }
        // filterCriteria=await this.setConfirmedSite()
        return filterCriteria
    }

    setConfirmedSite = (filterCriteria) => {

        let index = filterCriteria["criteria"].findIndex(obj => obj.key === "ConfirmedOnsite")
        if (index !== -1) {
            filterCriteria.criteria.splice(index, 1)
        }
        if (this.state.isexcludePossiblyPaid) {
            filterCriteria.criteria.push({ key: "ConfirmedOnsite", value: this.state.isexcludePossiblyPaid, type: "eq" })
        }
        return filterCriteria
    }


    submit = async (clear) => {
        let filterCriteria = {
            // criteria: this.props.filterCriteria && this.props.filterCriteria.criteria ? this.props.filterCriteria.criteria : [],
            criteria: [],
            sortfield: "SP1EstimatedValue",
            direction: "desc"
        }
        filterCriteria = await this.availableToMapV2Ref.getFiltercriteria()
        let Obj = {
        }
        let deals = [], dataforStatus = [], dataforReasons = []
        if (this.state.selectedSeller)
            Obj.sellerId = this.state.selectedSeller.EstateID
        if (this.state.SelectedStates && this.state.SelectedStates.length > 0) {
            Obj.states = this.state.SelectedStates
        }
        if (this.state.DealTypeV2) {
            Obj.dealType = this.state.DealTypeV2
        }
        if (this.state.Department) {
            Obj.departMentIdArray = this.state.departmentIdArray
        }
        let statusindex = filterCriteria["criteria"].findIndex(obj => obj.key === "PropertyStatus")
        if (statusindex !== -1) {
            filterCriteria.criteria.splice(statusindex, 1)
            Obj.status = null
        }
        if (this.state.selectedPropertyStatus && this.state.selectedPropertyStatus.length > 0) {
            for (var obj of this.state.selectedPropertyStatus) {
                if (!dataforStatus.includes(obj.status)) {
                    if (obj.status) {
                        dataforStatus.push(obj.status)
                    }
                }
            }
            Obj.status = dataforStatus
            filterCriteria.criteria.push({ key: "PropertyStatus", value: dataforStatus, type: "in" })
        }
        let ConfirmedOnsiteDateindex = filterCriteria["criteria"].findIndex(obj => obj.key === "ConfirmedOnsiteDate")
        if (ConfirmedOnsiteDateindex !== -1) {
            filterCriteria.criteria.splice(ConfirmedOnsiteDateindex, 1)
            Obj.ConfirmedOnsiteDate = null
        }
        if (this.state.fromDate) {
            filterCriteria.criteria.push({ key: "ConfirmedOnsiteDate", value: this.state.fromDate, type: "dategte", add_Filter: true })
            Obj.ConfirmedOnsiteDate = this.state.fromDate
        }
        let ScoreDateindex = filterCriteria["criteria"].findIndex(obj => obj.key === "ScoreDate")
        if (ScoreDateindex !== -1) {
            filterCriteria.criteria.splice(ScoreDateindex, 1)
            Obj.ScoreDate = null
        }
        if (this.state.ScoreDate) {
            filterCriteria.criteria.push({ key: "ScoreDate", value: this.state.ScoreDate, type: "dategte", add_Filter: true })
            Obj.ScoreDate = this.state.ScoreDate

        }

        let resonindex = filterCriteria["criteria"].findIndex(obj => obj.key === "PropertyStatusReason")
        if (resonindex !== -1) {
            filterCriteria.criteria.splice(resonindex, 1)
        }
        if (this.state.selectedPropertyReasons && this.state.selectedPropertyReasons.length > 0) {
            for (var obj of this.state.selectedPropertyReasons) {
                if (!dataforReasons.includes(obj.reason)) {
                    if (obj.reason) {
                        dataforReasons.push(obj.reason)
                    }
                }
            }
            Obj.reason = dataforReasons
            filterCriteria.criteria.push({ key: "PropertyStatusReason", value: dataforReasons, type: "in" })
        }
        let legacyNameIssueCodeindex = filterCriteria["criteria"].findIndex(obj => obj.key === "NameIssueCode")
        if (legacyNameIssueCodeindex !== -1) { filterCriteria["criteria"].splice(legacyNameIssueCodeindex, 1) }
        if (this.state.legacyNameIssueCode && this.state.legacyNameIssueCode.length > 0) {
            filterCriteria.criteria.push({ key: "NameIssueCode", value: this.state.legacyNameIssueCode, type: "in" })
            Obj.legacyNameIssueCode = this.state.legacyNameIssueCode
        }
        let index = filterCriteria["criteria"].findIndex(obj => obj.key === "ConfirmedOnsite")
        if (index !== -1) {
            filterCriteria.criteria.splice(index, 1)
        }
        if (this.state.isexcludePossiblyPaid) {
            filterCriteria.criteria.push({ key: "ConfirmedOnsite", value: this.state.isexcludePossiblyPaid, type: "eq" })
        }
        Obj.excludeNotConfirmedOnSite = this.state.isexcludePossiblyPaid ? "true" : false
        Obj.newNameIssueCode = this.state.newNameIssueCode

        filterCriteria = await this.setNewnameIssue(filterCriteria)

        this.setState({ body: Obj })
        this.availableToMapV2Ref.getBasicCall(filterCriteria, Obj, clear)

    };

    render() {
        const { handleSubmit } = this.props;
        return (
            <>
            {this.state.screenPermissions === "Edit" || this.state.screenPermissions === "View"?

                <div>
                    <div style={{ border: "1px solid lightgrey", marginBottom: 10 }}>
                        <Card className="py-2 ">
                            <CardBody className="p-0">
                                <form className="form" onSubmit={handleSubmit(this.submit)}>
                                    <div className='w-100 '>
                                        <div className='row mx-0'>
                                            <div className='col-lg-4'>
                                                <label className='statusReason'>Deal</label>
                                                <div >
                                                    <AutoComplete
                                                        value={this.state.selectedSeller && this.state.selectedSeller.EstateName ? `${this.state.selectedSeller.DealType} - ${this.state.selectedSeller.SellerNickName} (${this.state.selectedSeller && this.state.selectedSeller.DealYear ? this.state.selectedSeller.DealYear : ""})` : this.state.selectedSeller}
                                                        // value={this.state.selectedSeller && this.state.selectedSeller.EstateName ? this.state.selectedSeller.EstateName : this.state.selectedSeller}
                                                        suggestions={this.state.filteredSuggestions}
                                                        completeMethod={this.searchsellerItems}
                                                        minLength={1}
                                                        itemTemplate={this.editorTemplateForDeal}
                                                        field={"EstateName"}
                                                        style={{ width: "100%" }}
                                                        inputStyle={{ width: "85%" }}
                                                        placeholder='Select Deal'
                                                        dropdown={true}
                                                        onChange={(e) => this.handleChange(e, 'Seller')}
                                                        appendTo={document.body} />
                                                </div>
                                            </div>
                                            <div className='d-flex col-lg-4'>
                                                <div className='col-lg-6'>
                                                    <label className='statusReason'>Deal Type</label>
                                                    <div className="form__form-group-field">
                                                        <MultiSelect
                                                            // id={item.id}
                                                            filter={true}
                                                            name={"dealType"}
                                                            component={MultiSelect}
                                                            onChange={(e) => this.handleChange(e.value, "DealType", "in")}
                                                            type={"dropDown"}
                                                            value={this.state.DealTypeV2}
                                                            style={{ width: "100%" }}
                                                            options={[{ label: "BK", value: "BK" }, { label: "CR", value: "CR" }]}
                                                            placeholder={""}
                                                        // isDisable={item.isDisable ? item.isDisable : false}
                                                        />
                                                    </div>
                                                </div>
                                                <div className='col-lg-6'>
                                                    <label className="mr-1 kanban_filter_label" id="department" style={{ marginTop: 3 }}>
                                                        Team:
                                                    </label>
                                                    <MultiSelect
                                                        id="department"
                                                        filter={true}
                                                        className={"form__form-group-field "}
                                                        style={{ width: "100%", height: 32 }}
                                                        value={this.state.Department}
                                                        options={this.state.taskDepartmentItems}
                                                        onChange={(e) => this.onChangeDepartment(e, "Department")}
                                                        placeholder='Select Team'
                                                    />
                                                </div>
                                            </div>
                                            <div className='col-lg-2 ml-3 pl-4'>
                                                <label className='statusReason'>State</label>
                                                <div className="form__form-group-field">
                                                    <MultiSelect
                                                        // id={item.id}
                                                        name={"states"}
                                                        component={MultiSelect}
                                                        filter={true}
                                                        onChange={(e) => this.handleChange(e.value, "States", "in")}
                                                        type={"dropDown"}
                                                        value={this.state.SelectedStates}
                                                        style={{ width: "100%" }}
                                                        options={this.state.States}
                                                        placeholder={"Select State"}
                                                    // isDisable={item.isDisable ? item.isDisable : false}
                                                    />
                                                </div>
                                            </div>
                                            <div className='d-flex ml-4'>
                                                <Button color="primary" style={{ marginTop: "25px" }} outline type="submit">Go</Button>
                                                <div className="mt-2" >
                                                    <span className=" " style={{ cursor: "pointer" }}>
                                                        <FontAwesomeIcon
                                                            icon={faTimes}
                                                            style={{
                                                                width: 16,
                                                                height: 16,
                                                                margin: 5,
                                                                marginTop: 20,
                                                            }}
                                                            color="red"
                                                            onClick={() => this.resetFilters()}
                                                        />
                                                    </span>
                                                </div>
                                            </div>

                                        </div>

                                        <div className='row mt-1 mx-0'>
                                            <div className='col-lg-4'>
                                                <label className='statusReason'>Property Status</label>
                                                <div className="form__form-group-field">
                                                    <MultiSelect
                                                        // id={item.id}
                                                        name={"dealType"}
                                                        filter={true}
                                                        component={MultiSelect}
                                                        onChange={(e) => this.handleChange(e, "selectedPropertyStatus")}
                                                        type={"dropDown"}
                                                        value={this.state.selectedPropertyStatus}
                                                        style={{ width: "92%" }}
                                                        options={this.state.propertyStatusArray}
                                                        placeholder='Select Status'
                                                    // isDisable={item.isDisable ? item.isDisable : false}
                                                    />
                                                </div>
                                            </div>
                                            <div className='col-lg-4'>
                                                <label className='statusReason'>Property Reason</label>
                                                <div className="form__form-group-field">
                                                    <MultiSelect
                                                        // id={item.id}
                                                        name={"dealType"}
                                                        filter={true}
                                                        component={MultiSelect}
                                                        type={"dropDown"}
                                                        value={this.state.selectedPropertyReasons}
                                                        style={{ width: "92%" }}
                                                        disabled={this.state.selectedPropertyStatus && this.state.selectedPropertyStatus.length > 0 ? false : true}
                                                        options={this.state.shownreasons && this.state.shownreasons.length > 0 ? this.state.shownreasons : []}
                                                        placeholder={"Select Reason"}
                                                        onChange={(e) => this.handleChange(e, "selectedPropertyReasons")}
                                                    // isDisable={item.isDisable ? item.isDisable : false}
                                                    />
                                                </div>
                                            </div>
                                            <div className='col-lg-3'>
                                                <label className='statusReason'>New Name Issue Code</label>
                                                <div className="form__form-group-field">
                                                    <MultiSelect
                                                        // id={item.id}
                                                        name={"states"}
                                                        component={MultiSelect}
                                                        filter={true}
                                                        onChange={(e) => this.handleChange(e.value, "newNameIssueCode", "in")}
                                                        type={"dropDown"}
                                                        value={this.state.newNameIssueCode}
                                                        style={{ width: "100%" }}
                                                        options={this.state.issueNameOptions}
                                                        placeholder={"Select New Name Issue Code"}
                                                    // isDisable={item.isDisable ? item.isDisable : false}
                                                    />
                                                </div>
                                            </div>
                                        </div>

                                        <div className='row mt-2 mx-0'>
                                            <div className='row col-lg-4'>

                                                <div className='col-lg-6'>
                                                    <label htmlFor="cb3 col-sm-3 ml-0" className="p-checkbox-label" style={{ marginRight: "10px", fontSize: 14 }}>{"Conf. Onsite Properties Only"}</label>
                                                    <div className='mt-2 ml-5'>
                                                        <Checkbox
                                                            label='Unassigned Only'
                                                            type='checkbox'
                                                            // component={renderCheckBoxField}
                                                            name={'isexcludePossiblyPaid'}
                                                            className=""
                                                            checked={this.state.isexcludePossiblyPaid}
                                                            onChange={(e) => this.handleChangePossiblyPaid(e, "isexcludePossiblyPaid")}
                                                        />
                                                    </div>
                                                </div>
                                                {/* <div className='col-lg-6'>
                                                <label htmlFor="cb3 col-sm-3 ml-0" className="p-checkbox-label" style={{ marginRight: "10px", fontSize: 14 }}>{"Excl. Possibly Paid"}</label>
                                                <div className='mt-2 ml-5'>
                                                    <Checkbox
                                                        label='Unassigned Only'
                                                        type='checkbox'
                                                        // component={renderCheckBoxField}
                                                        name={'excludePossiblyPaid'}
                                                        className=""
                                                        checked={this.state.excludePossiblyPaid}
                                                        onChange={(e) => this.handleExcludePossiblyPaid(e, "excludePossiblyPaid")}
                                                    />
                                                </div>
                                            </div> */}

                                            </div>
                                            <div className='row col-lg-4' style={{ marginLeft: "1.2rem" }}>
                                                <div className='col-lg-6'>
                                                    <label className='statusReason'>Date Prop. Added Since</label>
                                                    <div >

                                                        <Calendar
                                                            style={{ zindex: 1004, width: "92%", lineHeight: 1.15, height: '25px' }}
                                                            appendTo={document.body}
                                                            monthNavigator={true}
                                                            yearNavigator={true}
                                                            hourFormat="24"
                                                            // minDate={new Date()}
                                                            yearRange="1940:2530"
                                                            name={"calendar1"}
                                                            placeholder={"Select Date"}
                                                            // placeholder='mm/dd/yyyy'
                                                            dateFormat="mm/dd/yy"
                                                            value={this.state.ScoreDate}
                                                            onChange={(e) => this.setState({ ScoreDate: e.value })}
                                                        >
                                                        </Calendar>
                                                    </div>
                                                </div>
                                                <div className='col-lg-6'>
                                                    <label className='statusReason'>Confirmed On Site Since</label>
                                                    <div >

                                                        <Calendar
                                                            style={{ zindex: 1004, width: "92%", lineHeight: 1.15, height: '25px' }}
                                                            appendTo={document.body}
                                                            monthNavigator={true}
                                                            yearNavigator={true}
                                                            hourFormat="24"
                                                            // minDate={new Date()}
                                                            yearRange="1940:2530"
                                                            name={"calendar1"}
                                                            placeholder={"Select Date"}
                                                            // placeholder='mm/dd/yyyy'
                                                            dateFormat="mm/dd/yy"
                                                            value={this.state.fromDate}
                                                            onChange={(e) => this.setState({ fromDate: e.value })}
                                                        >
                                                        </Calendar>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='col-lg-3 ml-4'>
                                                <label className='statusReason'>Legacy Name Issue Code</label>
                                                <div className="form__form-group-field">
                                                    <MultiSelect
                                                        // id={item.id}
                                                        name={"states"}
                                                        component={MultiSelect}
                                                        filter={true}
                                                        onChange={(e) => this.handleChange(e.value, "legacyNameIssueCode", "in")}
                                                        type={"dropDown"}
                                                        placeholder={"Select Legacy Name Issue Code"}
                                                        value={this.state.legacyNameIssueCode}
                                                        style={{ width: "100%" }}
                                                        options={this.state.LegacyIssueNameCodes}
                                                    // isDisable={item.isDisable ? item.isDisable : false}
                                                    />
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </form>
                                <AvailableToMap
                                    onRef={(ref) => this.availableToMapV2Ref = ref}
                                    body={this.state.body}
                                    filterCriteria={this.state.filterCriteria}
                                />
                            </CardBody>

                        </Card>
                    </div >
                    {this.state.LoggotSuccess ? <Redirect to="/log_in" /> : null}
                </div >  :this.state.screenPermissions===""?null :
                <NoViewComponent/> }
            </>
        );
    }
}
// Decorate with reduxForm(). It will read the initialValues prop provided by connect()
AvailableToMapFilters = reduxForm({
    form: "SummaryForm", // a unique identifier for this form
    enableReinitialize: true,
    // validate
})(AvailableToMapFilters);

// You have to connect() to any reducers that you wish to connect to yourself
AvailableToMapFilters = connect(
    state => ({
        initialValues: state.commonData.data // pull initial values from account reducer
    }),
    {
        load: loadAccount,
        setUserData: setUserAction
    } // bind account loading action creator
)(AvailableToMapFilters);

export default withTranslation('common')(AvailableToMapFilters);