import React from 'react';
import DataTables from '../../CommonDataTable/DataTable';
import apiCalls from '../../../../config/apiCalls'
import store from '../../../App/store';
import Loader from '../../../App/Loader';
import fetchMethodRequest from '../../../../config/service';

// config file
export default class ClaimSummary extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      type: 'Claim Summary',
      loading: false,
      sessionWarning: false,
    };
  }

  static getDerivedStateFromProps(props, state) {
    let storeData = store.getState()
    let languageData = storeData && storeData.settingsData && storeData.settingsData.settings && storeData.settingsData.settings.languageTranslation ? storeData.settingsData.settings.languageTranslation : ""
    return { languageData: languageData };
  }





  componentDidMount = async () => {
    //Screen permisions value can be edit,view, no view

  }

  getTableFields = () => {
    let data = [
      // {
      //   textAlign: "center",
      //   width: 47,
      //   field: "",
      //   // fieldType: "multiple",
      //   header: "",
      //   selectionMode: "multiple",
      //   show: true,
      //   mobile: true,
      //   displayInSettings: false,
      // },
      // {
      //   "show": true,
      //   "mobile": true,
      //   "textAlign": 'left',
      //   "field": "expander",
      //   "width": 60,
      //   "fieldType": 'expander',
      //   "header": "expander"
      // },

      {
        "show": true,
        "textAlign": "left",
        "width": 150,
        "header": "Claim ID",
        "mobile": true,
        "field": "ClaimID",
        "displayInSettings": true,
        filter: true,
        "sortable": true,
      },
      {
        "show": true,
        "textAlign": "left",
        "width": 150,
        "header": "Property ID",
        "mobile": true,
        filterType: "num",
        "field": "PropertyID",
        "displayInSettings": true,
        filter: true,
        "sortable": true,
      },
      {
        "show": true,
        "textAlign": "left",
        "width": 150,
        "header": "Claim Status",
        "mobile": true,
        "field": "ClaimStatus",
        "displayInSettings": true,
        filter: true,
        "sortable": true,
      },
      {
        "show": true,
        "textAlign": "left",
        "width": 140,
        "field": "ReportedName",
        "mobile": true,
        // "fieldType": "dateTime",

        // "dateFormat": 'MM-DD-yyyy',
        "header": "Reported Owner Name",
        "displayInSettings": true,
        filter: true,
        "sortable": true,
      },
      {
        "show": true,
        "textAlign": "left",
        "width": 140,
        "field": "ReportedAddress1",
        "mobile": true,
        // "fieldType": "dateTime",

        // "dateFormat": 'MM-DD-yyyy',
        "header": "Reported Address1",
        "displayInSettings": true,
        filter: true,
        "sortable": true,
      },
      {
        "show": true,
        "textAlign": "left",
        "width": 140,
        "field": "ReportedAddress2",
        "mobile": true,
        // "fieldType": "dateTime",

        // "dateFormat": 'MM-DD-yyyy',
        "header": "Reported Address2",
        "displayInSettings": true,
        // filter: true,
        // "sortable": true,
      },
      {
        "show": true,
        "textAlign": "left",
        "width": 140,
        "field": "ReportedCity",
        "mobile": true,
        // "fieldType": "dateTime",

        // "dateFormat": 'MM-DD-yyyy',
        "header": "Reported City",
        "displayInSettings": true,
        filter: true,
        "sortable": true,
      },
      {
        "show": true,
        "textAlign": "left",
        "width": 140,
        "field": "ReportedState",
        "mobile": true,
        // "fieldType": "dateTime",

        // "dateFormat": 'MM-DD-yyyy',
        "header": "Reported State",
        "displayInSettings": true,
        filter: true,
        "sortable": true,
      },
      {
        "show": true,
        "textAlign": "left",
        "width": 140,
        "field": "ReportedZipCode",
        "mobile": true,
        // "fieldType": "dateTime",

        // "dateFormat": 'MM-DD-yyyy',
        "header": "Reported Zip Code",
        "displayInSettings": true,
        filter: true,
        "sortable": true,
      },
      // {
      //   "show": true,
      //   "textAlign": "left",
      //   "width": 70,
      //   "fieldType": "rowEditor",
      //   "header": "Edit",
      //   "filter": false,
      //   "sortable": false
      // },
      {
        "show": true,
        "textAlign": "left",
        "width": 120,
        "field": "entityEntitlementStateEntityIdNO",
        "mobile": true,
        "allowInt": true,
        // filterType: "num",
        "header": "Entity Entitlement State Entity ID No",
        "displayInSettings": true,
        // filter: true,
        // "sortable": true,
      },
      {
        "show": true,
        "textAlign": "left",
        "width": 150,
        "field": "EntityEntitlementExhibitNo",
        "mobile": true,
        // "editField": "dropDown",
        // options: [

        // ],
        // "allowInt": true,
        // filterType: "num",
        "header": "Entity Entitlement Exhibit No",
        "displayInSettings": true,
        // filter: true,
        // "sortable": true,
      },
      {
        "show": true,
        "textAlign": "left",
        "width": 150,
        "field": "OfficerEntitlementExhibitNo",
        "mobile": true,
        "editField": "text",
        filterType: "num",
        "header": "Officer Entitlement Exhibit No",
        "displayInSettings": true,
        // filter: true,
        // "sortable": true,
      },
      {
        "show": true,
        "textAlign": "left",
        "width": 150,
        "header": "FEIN",
        "mobile": true,
        "field": "FEIN",

        "displayInSettings": true,
        // "fieldType": "ActiveIndicator"
        // filter: true,
        // "sortable": true,
      },
      {
        "show": true,
        "textAlign": "left",
        "width": 150,
        "field": "FEINEvidenceExhibitNo",
        "mobile": true,
        "editField": "text",

        filterType: "num",
        "header": "FEIN Evidence Exhibit No",
        "displayInSettings": true,
        // filter: true,
        // "sortable": true,
      },
      {
        "show": true,
        "textAlign": "left",
        "width": 100,
        "header": "Address Exhibit No",
        "mobile": true,
        "field": "AddressExhibitNo",
        "displayInSettings": true,
        "editField": "date",
        "fieldType": "dateTime",
        "dateFormat": 'MM-DD-yyyy',
        // filter: true,
        // "sortable": true,
      },



    ]
    return data;
  };


  getFormFields = () => {
    return ([
      {
        //'show': false,
        "value": "",
        "type": "text",
        "name": "EstateName",
        "label": "Seller Name",
        "id": "EstateName",
        "placeholder": "Seller Name",
        "required": true,
        "disable": true
      },
      {
        //'show': false,
        "value": "",
        "type": "text",
        "name": "SourceState",
        "label": "Source State",
        "id": "SourceState",
        "placeholder": "Source State",
        "required": true,
        "disable": true
      },
      {
        //'show': false,
        "value": "",
        "type": "text",
        "name": "BusinessName",
        "label": "Business Name",
        "id": "BusinessName",
        "placeholder": "Business Name",
        "required": true,
        "disable": true
      },
      {
        //'show': false,
        "value": "",
        "type": "text",
        "name": "checkForm",
        "label": "Check From",
        "id": "checkForm",
        "placeholder": "Check From",
        "required": true
      },
      {
        //'show': false,
        "value": "",
        "type": "textArea",
        "name": "note",
        "label": "Note",
        "id": "note",
        "placeholder": "Note",
        "required": true
      },
      {
        //'show': false,
        "value": "",
        "type": "text",
        "name": "checkNumber",
        "label": "Check Number",
        "id": "checkNumber",
        "placeholder": "Check Number",
        "required": true
      },
      {
        //'show': false,
        "value": "",
        "type": "text",
        "name": "checkAmount",
        "label": "Check Amount",
        "id": "checkAmount",
        "placeholder": "Check Amount",
        "required": true
      },
      {
        //'show': false,
        "value": "",
        "type": "date",
        "name": "checkDate",
        "label": "Check Date",
        "id": "checkDate",
        "placeholder": "Check Date",
        "required": true
      },
    ]);
  }

  getClaimStatusFormFields = () => {
    return ([{
      "value": "",
      "type": "text",
      "name": "ClaimStatus",
      "label": "Claim Status",
      "id": "ClaimStatus",
      "placeholder": "Claim Status",
      "required": false,
      // "disable": true
    },])
  }

  submit = async (item) => {

  }

  render() {
    return (
      <span>
        <Loader loader={this.state.isLoading} />
        <DataTables
          onRef={(ref) => (this.datatableref = ref)}
          getTableFields={this.getTableFields}
          formFields={this.getFormFields}
          // claimStatusFormFields={this.getClaimStatusFormFields}
          statusFilter={false}
          className={true}
          exportRequried={false}
          printRequried={false}
          addRequried={false}
          isClearFilters={false}
          editRequired={false}
          deleteRequired={false}
          viewRequired={false}
          settingsRequired={false}
          filterRequired={false}
          isEdit={false}
          // multiApi={`${apiCalls.StateUpFiling}/multi`}
          // multiApiKey={"Claims"}
          gridRequried={false}
          sample={false}
          // dataKey={"OppClaimID"}
          // criteria={[{ "key": "PropertyStatus", "value": "Filed", "type": "eq" }]}
          criteria={[]}
          // globalSearchFieldName='user'
          // globalSearch={'Search'}
          type='ClaimSummary'
          loading={true}
          dontShowTitle={true}
          displayright={true}
          settingsData={this.state.settingsData}
          icon='lnr lnr-menu-circle'
          routeTo='claimsummary'
          // groupField='claimID'
          sortField={"ClaimID,PropertyID,ReportedName,ReportedAddress1,ReportedAddress2,ReportedCity,ReportedState,ReportedZipCode,ClaimStatus"}
          displayViewOfForm='modal'
          apiResponseKey='claimSummary'
          apiUrl={apiCalls.ClaimSummary}
          entityType='employee'
          sessionWarning={this.state.sessionWarning}
        />

      </span>
    );
  }
}