import React from 'react';
import { withTranslation } from 'react-i18next';
import { Field, reduxForm } from 'redux-form';
import DefaultInput from '../../shared/components/form/DefaultInput';
import renderRadioButtonField from '../../shared/components/form/RadioButton';
import TextArea from '../../shared/components/form/DefaultTextArea';
import ConfirmationModal from '../Cruds/CommonModals/ConfirmationModal';
import Moment from 'moment-timezone';
import fetchMethodRequest from '../../config/service';
import { Col, Container, Row, Button } from 'reactstrap';
import { Dropdown } from 'primereact/dropdown';
import Select from '../../shared/components/form/Select';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd'
import styled from 'styled-components'
// import { RadioButton } from 'primereact/radiobutton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faStarOfLife } from '@fortawesome/free-solid-svg-icons';
import { Calendar } from 'primereact/calendar';
import apiCalls from '../../config/apiCalls';
import { async } from '@firebase/util';
const grid = 8;
const Container1 = styled.div`
  display: flex;
  
`

const ContainerBody = styled.div`
  border: 1px solid #e5e5e5;
  border-radius: 10px;
  padding: 8px;
  margin-bottom: 8px;
  transition: background-color 0.2s ease;
  background-color: ${props =>
    props.isDragging
      ? 'skyblue' : 'white'};
`
const Container5 = styled.div`
  margin: 8px;
  border: 1px solid #e5e5e5;
  border-radius: 2px;
  display: flex;
  flex-direction: column;
   width: 200%;

`
const getItemStyle = (isDragging, draggableStyle) => ({
  // some basic styles to make the items look a bit nicer
  userSelect: 'none',
  // paddingTop: 2,
  // paddingBottom: 2,
  paddingLeft: 2,
  margin: `0 ${grid}px 0 0`,

  // change background colour if dragging
  // background: isDragging ? 'grey' : 'lightgrey',
  border: '1px solid white',
  borderRadius: 10,
  // styles we need to apply on draggables
  ...draggableStyle,

});
const TaskList = styled.div`
  padding: 8px;
  transition: background-color 0.2s ease;
  background-color: ${props =>
    props.isDraggingOver ? 'skyblue' : 'black'}
  flex-grow: 1;
  min-height: 100px;
`
const getListStyle = isDraggingOver => ({
  background: isDraggingOver ? 'lightblue' : 'white',
  display: 'flex',
  flexWrap: 'wrap',
  width: '100%',
  padding: grid,
  overflow: 'auto',
  border: '1px solid black',
  borderRadius: 10,
  wordBreak: 'break-word',

  textOverflow: 'ellipsis',
});
let id2List = {
  droppable: 'selectTableFields',
  droppable2: 'notSelectedTableFields'
};
class ShowHideColumnsModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpenConfirmationModal: false,
      categories: [
        // { label: "Claim Filing", value: "Claim Filing" },
        // { label: "Claim Generation", value: "Claim Generation" },
        // { label: "State Correspondence", value: "State Correspondence" },
      ],
      subcategories: [],
      claimFiling: [
        { label: "Co-Owner Filing", value: "Co-Owner Filing" },
        { label: "Cover Letter", value: "Cover Letter" },
        { label: "Online Claim Submission", value: "Online Claim Submission" },
      ],
      ClaimGeneration: [
        { label: "Claim Form Completion", value: "Claim Form Completion" },
        { label: "Claim Form Generation", value: "Claim Form Generation" },
        { label: "Packet Generation", value: "Packet Generation" },
      ],
      StateCorrespondence: [
        { label: "Correspondence Restrictions", value: "Correspondence Restrictions" },
        { label: "Online Status Check", value: "Online Status Check" },
      ],
      filterCriteria: {
        limit: localStorage.getItem("defaultPageOption") ? parseInt(localStorage.getItem("defaultPageOption")) : 10,
        page: 1,
        criteria: [],
        sortfield: ("claimSummary"),
        direction: "desc"
      },
      dealTypes: [
        { label: "BK", value: "BK" },
        { label: "CR", value: "CR" },
      ],
      groupBy: [{ label: "Claim ID", value: "ClaimID" },
      { label: "Property ID", value: "PropertyID" },
      { label: "Reported Owner Name", value: "ReportedName" },
      { label: "Reported Address1", value: "ReportedAddress1" },
      { label: "Reported Address2", value: "ReportedAddress2" },
      { label: "Reported City", value: "ReportedCity" },
      { label: "Reported State", value: "ReportedState" },
      { label: "Reported Zip Code", value: "ReportedZipCode" },
      { label: "Claim Status", value: "ClaimStatus" },],
      States: [
        { label: "AL", value: "AL" },
        { label: "AK", value: "AK" },
        { label: "AZ", value: "AZ" },
        { label: "AR", value: "AR" },
        { label: "CA", value: "CA" },
        { label: "CO", value: "CO" },
        { label: "CT", value: "CT" },
        { label: "DE", value: "DE" },
        { label: "FL", value: "FL" },
        { label: "GA", value: "GA" },
        { label: "HI", value: "HI" },
        { label: "ID", value: "ID" },
        { label: "IL", value: "IL" },
        { label: "IN", value: "IN" },
        { label: "IA", value: "IA" },
        { label: "KS", value: "KS" },
        { label: "KY", value: "KY" },
        { label: "LA", value: "LA" },
        { label: "ME", value: "ME" },
        { label: "MD", value: "MD" },
        { label: "MA", value: "MA" },
        { label: "MI", value: "MI" },
        { label: "MN", value: "MN" },
        { label: "MS", value: "MS" },
        { label: "MO", value: "MO" },
        { label: "MT", value: "MT" },
        { label: "NE", value: "NE" },
        { label: "NV", value: "NV" },
        { label: "NH", value: "NH" },
        { label: "NJ", value: "NJ" },
        { label: "NM", value: "NM" },
        { label: "NY", value: "NY" },
        { label: "NC", value: "NC" },
        { label: "ND", value: "ND" },
        { label: "OH", value: "OH" },
        { label: "OK", value: "OK" },
        { label: "OR", value: "OR" },
        { label: "PA", value: "PA" },
        { label: "PR", value: "PR" },
        { label: "RI", value: "RI" },
        { label: "SC", value: "SC" },
        { label: "SD", value: "SD" },
        { label: "TN", value: "TN" },
        { label: "TX", value: "TX" },
        { label: "UT", value: "UT" },
        { label: "VT", value: "VT" },
        { label: "VA", value: "VA" },
        { label: "DC", value: "DC" },
        { label: "WA", value: "WA" },
        { label: "WV", value: "WV" },
        { label: "WI", value: "WI" },
        { label: "WY", value: "WY" },
      ],
      selectedcategory: "",
      selecteddealtype: "",
      selectedsubcategory: "",
      selectedState: "",
      loginData: {},
      changeGroupBy: false,
      attributeFields: []
    };
  }

  componentDidUpdate() {
    if (this.state.errorResponse) {
      setTimeout(() => this.setState({ errorResponse: false, errorMessage: '' }), 30000);
    }
  }

  componentDidMount = async () => {
    let user = JSON.parse(localStorage.getItem('loginCredentials'));
    if (user) {
      this.setState({
        loginData: user,
        role: user.role
      })
    }
    // await this.getcategoriesOptions()
  }






  // submit = async (values) => {
  //   let arraylist = []
  //   let array = this.state.attributeFields
  //   for (let i = 0; i < array.length; i++) {
  //     arraylist.push({
  //       question: array[i][array[i].name],
  //       answer: array[i][array[i].value],
  //       isActive: array[i][array[i].isActive] === "Yes" ? true : false,
  //       type: array[i][array[i].type],
  //       createdBy: array[i]["createdBy"],
  //       created: array[i]["created"],
  //       updatedBy: array[i]["updatedBy"],
  //       updated: array[i]["updated"],
  //     })
  //   }

  //   let obj = {
  //     "subCategory": this.state.selectedsubcategory,
  //     "category": this.state.selectedcategory,
  //     "state": this.state.selectedState,
  //     "dealType": this.state.selecteddealtype,
  //   }
  //   obj.attributes = arraylist
  //   let method, url;
  //   await this.getFilingData()
  //   if (this.state.editedindex !== null) {
  //     method = "PUT"
  //     url = `StateUpFiling/${this.state.editedindex}`
  //   } else {
  //     method = "POST"
  //     url = `StateUpFiling`
  //   }
  //   return fetchMethodRequest(method, url, obj)
  //     .then(async (response) => {
  //       if (response) {
  //         this.getErrorMessage(response.respMessage, "success")
  //       } else if (response && response.errorMessage) {
  //         this.getErrorMessage(response.errorMessage, "error")
  //       }

  //     }).catch((err) => {
  //       return err;
  //     });
  // }
  changeGroupBy = async () => {
    this.setState({ changeGroupBy: this.state.changeGroupBy ? false : true })
  }

  clearAttributesData = async () => {
    await this.setState({
      claimType: "",
      changeGroupBy: false
    })
    this.props.showTable()
  }
  // getFilingData = async (type) => {
  //   let filter = { criteria: [] }
  //   if (this.state.selectedsubcategory) {

  //     filter.criteria = [{ key: "category", value: this.state.selectedcategory, type: "eq" },
  //     { key: "subCategory", value: this.state.selectedsubcategory, type: "eq" },
  //     { key: "state", value: this.state.selectedState, type: "eq" },
  //     { key: "dealType", value: this.state.selecteddealtype, type: "eq" }]
  //   } else {
  //     filter.criteria = [{ key: "category", value: this.state.selectedcategory, type: "eq" },
  //     { key: "state", value: this.state.selectedState, type: "eq" },
  //     { key: "dealType", value: this.state.selecteddealtype, type: "eq" }]
  //   }

  //   this.props.getFilteredValues(filter, this.state.selectedsubcategory, this.state.selectedcategory, this.state.subcategories, this.state.selecteddealtype, this.state.selectedState)

  // }
  submit = (values) => {

    let Object = {
      // selectedSeller: {},
    }
    if (this.state.claimType) {
      Object.claimType = this.state.claimType
    }
    if (this.props.DealId) {
      Object.DealId = this.props.DealId
    }
    if (this.state.groupBy) {
      Object.groupBy = this.state.groupBy.map(u => u.value).join()
    }


    this.props.getFilteredValues(Object, this.state.filterCriteria)
    this.setState({ changeGroupBy: false })
  }
  changeClaimType = async (e) => {
    await this.setState({ claimType: e })
  }

  onDragEnd = async (result) => {
    let groupBy = this.state.groupBy
    let groupBySource = groupBy[result.source.index]
    let groupByDest = groupBy[result.destination.index]
    if (result.source.index > result.destination.index) {
      groupBy.splice(result.source.index, 1)
      groupBy.splice(result.destination.index, 0, groupBySource);
    } else {
      groupBy.splice(result.source.index, 1)
      groupBy.splice(result.destination.index, 0, groupBySource);
    }
    await this.setState({ groupBy: groupBy, sortDone: true })
  }

  getattributesData = async () => {
    await this.getFilingData("exists")
  }



  render() {
    const { handleSubmit } = this.props;
    return (
      <div className=""  >

        <div>
          <Row className="mt-2">
            <Col sm={3} className="ml-4 d-flex" style={{
              height: "36px"
            }}>
              <label className='mt-2' style={{ width: "25%", display: "flex", "font-weight": "bold" }}>Claim Type

              </label>
              <Dropdown
                value={this.state.claimType}
                appendTo={document.body}
                options={[{ label: "Not Paid Claims (Only Active)", value: "notpaid" }, { label: "All Claims (Paid + Active)", value: "all" }]}
                onChange={(e) => this.changeClaimType(e.value)}
              />
            </Col>
            <Col sm={3} className="d-flex">
              <Button
                color='primary'
                className="mb-0"
                outline
                style={{ height: "34px" }}
                onClick={() => this.submit()}>
                Go
              </Button>
              <Button
                color='primary'
                className="mb-0"
                outline
                style={{ height: "34px" }}

                // style={{ color: "white", backgroundColor: '#354f6e', width: 115 }}
                onClick={() => this.clearAttributesData()}>
                Clear
              </Button>

              <Button
                color='primary'
                className="mb-0"
                outline
                style={{ height: "34px" }}

                // style={{ color: "white", backgroundColor: '#354f6e', width: 115 }}
                // disabled={(this.state.selectedSeller || this.state.Property_Id || this.state.OpraStId) ? false : true}
                // disabled={this.state.selectedState && this.state.selectedcategory && this.state.selecteddealtype ? false : true}

                onClick={() => this.changeGroupBy()}>
                {/* Sort By */}
                {this.state.changeGroupBy ? "Close Sort By" : "Sort By"}
              </Button>
            </Col>
            {this.state.changeGroupBy ?
              <Col sm={4} className="d-flex">
                <label style={{ width: "15%", display: "flex" }}>Sort By
                </label>
                <DragDropContext onDragEnd={this.onDragEnd} >
                  <div className='col-12 col-lg-7 col-md-10 col-sm-12'>
                    <Droppable droppableId="droppable" direction="vertical" >
                      {(provided, snapshot) => (
                        <div
                          ref={provided.innerRef}
                          style={getListStyle(snapshot.isDraggingOver)}
                          {...provided.droppableProps}
                        >
                          {this.state.groupBy && this.state.groupBy.map((item, index) => {

                            return <div className='col-sm-12 px-0 pb-2'>
                              <Draggable key={item.label} draggableId={item.label} index={index}>
                                {(provided, snapshot) => (
                                  <div
                                    ref={provided.innerRef}
                                    {...provided.draggableProps}
                                    {...provided.dragHandleProps}
                                    style={getItemStyle(
                                      snapshot.isDragging,
                                      provided.draggableProps.style
                                    )}
                                  >

                                    {item.label}
                                  </div>
                                )}

                              </Draggable>
                            </div>

                          })}
                          {provided.placeholder}
                        </div>
                      )}
                    </Droppable>
                  </div>



                </DragDropContext>
              </Col> : null}
          </Row>
          {/* 
          <Row className="mt-2">

            <Col style={{ marginLeft: "800px" }}>
              <Button
                color='primary'
                className="ml-auto mb-0"
                outline
                // style={{ color: "white", backgroundColor: '#354f6e', width: 115 }}
                onClick={() => this.submit()}>
                Go
              </Button>
              <Button
                color='primary'
                className="ml-auto mb-0"
                outline
                // style={{ color: "white", backgroundColor: '#354f6e', width: 115 }}
                onClick={() => this.clearAttributesData()}>
                Clear
              </Button>

              <Button
                color='primary'
                className="ml-auto mb-0"
                outline
                // style={{ color: "white", backgroundColor: '#354f6e', width: 115 }}
                // disabled={(this.state.selectedSeller || this.state.Property_Id || this.state.OpraStId) ? false : true}
                // disabled={this.state.selectedState && this.state.selectedcategory && this.state.selecteddealtype ? false : true}

                onClick={() => this.changeGroupBy()}>
                Group By
              </Button>

            </Col>
          </Row> */}
          {/* {this.state.errorResponse ? <p>{this.state.errorMessage}</p> : null} */}
        </div>

        <Row style={{ width: "100%" }}>
          <form className="form" onSubmit={handleSubmit(this.submit)} className='form d-block col-sm-12'>
          </form>
        </Row>
        {this.state.isOpenConfirmationModal ?
          this.getConfirmaionModel() : null
        }
      </div >
    )
  };


}

// Decorate with reduxForm(). It will read the initialValues prop provided by connect()
ShowHideColumnsModal = reduxForm({
  form: "ShowHideColumnsModal Form", // a unique identifier for this form
  enableReinitialize: true,
})(ShowHideColumnsModal);

export default withTranslation('common')(ShowHideColumnsModal);
