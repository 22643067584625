import React from 'react';
import store from '../../../App/store';
import apiCalls from '../../../../config/apiCalls'
import DataTables from '../../CommonDataTable/DataTable';
import fetch from '../../../../config/service';
import Loader from '../../../App/Loader';


// config file
export default class PreviousSearches extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      type: 'Purposes',
      dealTypes: [{ "label": "BK", "value": "BK" }, { "label": "CR", "value": "CR" }],
    };
  }

  static getDerivedStateFromProps(props, state) {
    let storeData = store.getState()
    let languageData = storeData && storeData.settingsData && storeData.settingsData.settings && storeData.settingsData.settings.languageTranslation ? storeData.settingsData.settings.languageTranslation : ""
    return { languageData: languageData };
  }



  componentDidMount = () => {
    this.getLabelOptions()
    //Screen permisions value can be edit,view, no view
    // let screenPermissions = RolePermissions.screenPermissions('Employees');
  }

  //user Task label options
  getLabelOptions = () => {
    fetch('GET', apiCalls.Labels)
      .then(async (response) => {
        if (response) {
          let labels = response.labels;
          let modifiedLabels = [];
          for (let label of labels) {
            modifiedLabels.push({
              label: label.labelName,
              value: {
                labelName: label.labelName,
                id: label._id,
              },
            })
          }
          await this.setState({
            company: modifiedLabels
          })
          // this.setUnitName()

        }
      }).catch((err) => {
        return err;
      });
  }
  getTableFields = () => {
    let data = [
      // {
      //   "show": true,
      //   "textAlign": "center",
      //   "width": 6,
      //   "fieldType": "expander",
      //   "header": "",
      //   "filter": false,
      //   "sortable": false,
      //   "placeholder": "Search"
      // },
      {
        "show": true,
        textAlign: 'left',
        width: 80,
        field: 'labelName',
        // fieldType: 'BoolText',
        "mobile": true,
        header: 'Label Name',
        "filter": true,
        "displayInSettings": true,
        "displayInFilter": true,
        sortable: true,
      },
      {
        "show": true,
        textAlign: 'left',
        width: 40,
        field: 'sequence',
        "allowInt": true,
        "filterType": 'num',
        "mobile": true,
        header: 'Sequence',
        "filter": true,
        "displayInSettings": true,
        "displayInFilter": true,
        sortable: true,
      },
      {
        "show": true,
        textAlign: 'left',
        width: 80,
        field: 'purposeName',
        fieldType: 'BoolText',
        "mobile": true,
        header: 'Purpose Name',
        "filter": true,
        "displayInSettings": true,
        "displayInFilter": true,
        sortable: true,
      },

      {
        "show": true,
        "textAlign": "left",
        "width": 40,
        "fieldType": 'Actions',
        "field": "Actions",
        "header": "Actions",
        "filter": false,
        "displayInSettings": true,
        "sortable": false
      }
    ]
    return data;
  };


  getFormFields = () => {
    return ([]);
  }


  submit = async (item) => {


  }

  render() {
    return (
      <span>
        <Loader loader={this.state.isLoading} />
        <DataTables
          onRef={(ref) => (this.datatableref = ref)}
          getTableFields={this.getTableFields}
          formFields={this.getFormFields}
          exportRequried={false}
          printRequried={false}
          sortField={"sequence"}
          criteria={this.props.criteria}
          addRequried={false}
          editRequired={false}
          deleteRequired={false}
          viewRequired={true}
          dontShowTitle={true}
          settingsRequired={false}
          filterRequired={true}
          gridRequried={false}
          sample={false}
          isClearFilters={false}
          expandTableFields={this.expandTableFields}
          type='property Searches'
          displayright={true}
          icon='user'
          routeTo='property Searches'
          displayViewOfForm='modal'
          apiResponseKey='property Searches'
          apiUrl={apiCalls.propertySearches}
          entityType='employee'
        />
      </span>
    );
  }
}