import React from 'react';
import DataTables from '../../CommonDataTable/DataTable';
import apiCalls from '../../../../config/apiCalls'
import fetch from '../../../../config/service';
import store from '../../../App/store';
// import RolePermissions from '../../CommonModals/Permissions';

// config file
export default class SPLogs extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        };
    }

    static getDerivedStateFromProps(props, state) {
        let storeData = store.getState()
        let languageData = storeData && storeData.settingsData && storeData.settingsData.settings && storeData.settingsData.settings.languageTranslation ? storeData.settingsData.settings.languageTranslation : ""
        return { languageData: languageData };
    }



    componentDidMount = async () => {
        //Screen permisions value can be edit,view, no view
        // let screenPermissions = RolePermissions.screenPermissions('Employees');
    }

    getTableFields = () => {
        let data = [
            {
                "show": true,
                textAlign: 'left',
                width: 60,
                field: 'dealID',
                // fieldType: 'Text',
                "allowInt": true,
                "mobile": true,
                "filterType": "num",
                header: 'Deal ID',
                filter: true,
                sortable: true,
            },
            {
                "show": true,
                textAlign: 'left',
                width: 60,
                field: 'dealName',
                // fieldType: 'Text',
                "mobile": true,
                header: 'Deal Name',
                filter: true,
                sortable: true,
            },
            {
                "show": true,
                textAlign: 'left',
                width: 60,
                field: 'sP_StartDate',
                fieldType: 'Time',
                "filterType": "num",
                "mobile": true,
                header: 'Start Date',
                filter: true,
                sortable: true,
                "dateFormat": 'MM/DD/YYYY',
            },
            {
                "show": true,
                textAlign: 'left',
                width: 60,
                field: 'completedCount',
                fieldType: 'completedCount',
                "mobile": true,
                header: 'Completed Validated Properties',
                filter: true,
                sortable: true,
            },
            {
                "show": true,
                textAlign: 'left',
                width: 60,
                field: 'sP_EndDate',
                fieldType: 'Time',
                "filterType": "num",
                "mobile": true,
                header: 'End Date',
                filter: true,
                sortable: true,
                "dateFormat": 'MM/DD/YYYY',
            },
            {
                "show": true,
                textAlign: 'left',
                width: 60,
                field: 'sP_Status',
                // fieldType: 'Badge',
                "mobile": true,
                header: 'Status',
                filter: true,
                sortable: true,
            }
        ]
        return data;
    };

    getFormFields = () => {
        return ([
        ]);
    }


    submit = async (item) => {

    }

    render() {
        return (
            <span>
                <DataTables
                    onRef={(ref) => (this.datatableref = ref)}
                    getTableFields={this.getTableFields}
                    formFields={this.getFormFields}
                    sortField={"sP_StartDate"}
                    exportRequried={false}
                    printRequried={false}
                    addRequried={false}
                    editRequired={false}
                    isClearFilters={true}
                    deleteRequired={false}
                    viewRequired={true}
                    settingsRequired={false}
                    filterRequired={false}
                    gridRequried={false}
                    sample={false}
                    globalSearchFieldName='DealName'
                    globalSearch={'Search'}
                    type='SP Logs'
                    displayright={true}
                    icon='tag'
                    routeTo='spLogs'
                    displayViewOfForm='modal'
                    apiResponseKey={"spLogs"}
                    apiUrl={apiCalls.SPLogs}
                    entityType='employee'
                />

            </span>
        );
    }
}