import React from 'react';
import { Button } from 'reactstrap';
import { Dropdown } from 'primereact/dropdown';
import { OverlayPanel } from 'primereact/overlaypanel';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClipboardList } from '@fortawesome/free-solid-svg-icons';
import store from '../../../App/store';
import Loader from '../../../App/Loader';
import fetchMethodRequest from '../../../../config/service';
import apiCalls from '../../../../config/apiCalls';
import config from '../../../../config/config';
import configMessages from '../../../../config/configMessages';
import configImages from '../../../../config/configImages';
import DataTables from '../components/PEDatatable';
import TableModal from '../../CommonDataTable/TableModal';
import NewClaim from '../../../Cruds/CommonModals/ClaimCreationfromPE/NewClaim'
import MatchPropertyModal from '../../../Cruds/CommonModals/MatchPropertyModal';
import ExistingClaim from '../../../Cruds/CommonModals/ClaimCreationfromPE/ExistingClaim'
import MultiSortModal from '../components/MultisortModal'
import ValidatedPropertyUpdaterModal from '../../../Cruds/CommonModals/ValidatedPropertyUpdaterModal'
import PropertyTrailViewerModal from '../../../Cruds/CommonModals/PropertyTrailViewerModal'
import { faEnvelope } from '@fortawesome/free-regular-svg-icons';

export default class NewPropertyEditor extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      type: 'Property Editor',
      ReasonsArray: [],
      isDisableActions: false,
      defSortOrder: [
        {
          "key": "propertyName",
          "direction": "asc"
        },
        {
          "key": "propertyAddressLine1",
          "direction": "asc"
        }
      ],
      selectedfieldsforSort: [
        {
          "value": {
            "label": "Reported Owner Name",
            "value": "propertyName",
            "type": "string",
            "disabled": true
          },
          "fieldType": "string",
          "type": "asc"
        },
        {
          "value": {
            "value": "propertyAddressLine1",
            "label": "Property Address Line1",
            "type": "string",
            "disabled": true
          },
          "fieldType": "string",
          "type": "asc"
        }
      ],
      activeTab: "1",
      createTaskAdd: "",
      createTask: "",
      selectedViewType: "Default",
      loginCredentials: JSON.parse(localStorage.getItem('loginCredentials')),
      propertyFilterSortOptions: [
        { label: 'Property Name', value: "propertyName" },
        { label: 'Prop.Address', value: "propertyAddressLine1" },
        { label: 'Business Name', value: "businessName" },
        { label: 'Property Zip Code', value: "propertyZipCode" },
        { label: 'Property City', value: "propertyCity" },
        { label: 'Property State', value: "propertyState" },
      ],
      multiSortFields: [
        { value: "SourceState", label: "Source State", type: "string" },
        { label: "Reported Owner Name", value: "propertyName", type: "string" },
        { value: "propertyAddressLine1", label: "Property Address Line1", type: "string" },
        { value: "propertyCity", label: "Property City", type: "string" },
        { value: "addressLine1MatchScore", label: "Line1 Score", type: "num", index: "4" },
        { value: "nameMatchScore", label: "Name Score", type: "num", index: "5" },
     
      ],

    };
  }

  static getDerivedStateFromProps(props, state) {
    let storeData = store.getState()
    let languageData = storeData && storeData.settingsData && storeData.settingsData.settings && storeData.settingsData.settings.languageTranslation ? storeData.settingsData.settings.languageTranslation : ""
    return { languageData: languageData };
  }



  componentDidMount = async () => {
    await this.setState({
      createTaskAdd: "",
      createTask: "",
      loginCredentials: JSON.parse(localStorage.getItem('loginCredentials')),
    })
    await this.getSettingsData();
    this.getDataFromServer()
    await this.getReasonForEditor();
    await this.getNewStatusForReMap()
    await this.getNewStatusReasonsForEditor()
    await this.getNewStatusForEditor()
    if (this.props.onRef) {
      this.props.onRef(this);
    }


    const urlSearchParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlSearchParams.entries());
  }


  handleCheckisInlineFilterApplied = (filterCriteria) => {
    const foundRecord = filterCriteria && filterCriteria.criteria && filterCriteria.criteria.filter(record => record.add_Filter === false);
    if (foundRecord && foundRecord.length > 0) {
      // this.setState({isInlineApplied:false})
      return "Apply"
    } else if (foundRecord && foundRecord.length === 0) {
      // this.setState({isInlineApplied:true})
      return "Remove"
    } else {
      // this.setState({isInlineApplied:false})
      return "Remove"
    }
  }
  componentWillUnmount() {
    if (this.props.onRef) {
      this.props.onRef(null);
    }
  }
  getNewStatusForReMap = async (val) => {

    let propertyStatusArray = [];
    let filterCriteria = {}

    filterCriteria['criteria'] = filterCriteria['criteria'] = [
      { key: 'activeIndicator', value: true, type: 'eq', add_Filter: true },
      { key: 'availableToReMap', value: true, type: 'eq', add_Filter: true },
    ]

    filterCriteria.sortfield = "stageSequence, statusSequence, reasonSequence"
    filterCriteria.direction = "asc"
    return fetchMethodRequest('GET', `${apiCalls.propertyStatusReason}?filter=${JSON.stringify(filterCriteria)}&dropdown=${val ? "reason" : "status"}`)
      .then(async (response) => {
        if (response && response["propertyStatusReasons"].length > 0) {
          let propertyStatusReasons = response["propertyStatusReasons"]
          for (let reason of propertyStatusReasons) {
            propertyStatusArray.push(reason.status)
          }
          await this.setState({ propertyStatusArrayReMap: propertyStatusArray })
        } else if (response && response["propertyStatusReasons"].length == 0) {
          await this.setState({ propertyStatusArrayReMap: [], propertyReasonArray: [] })
        } else if (response && response['errorMessage'] === configMessages.warningMessage) {
          await this.setState({
            sessionWarning: true,

          })
        }
      }).catch((err) => {
        return err;
      })
  }

  /**
   * get SPStatus based on selectedSeller
   */
  getSPData = () => {
    if (this.state.selectedSeller) {
      let filterCriteria = {}
      filterCriteria['criteria'] = [{ key: 'sellerID', value: this.state.selectedSeller.EstateID, type: 'eq', add_Filter: true }, { key: 'scoringType', value: "RegularScoring", type: 'eq', add_Filter: true }];
      fetchMethodRequest('GET', `NSScoredSeller?filter=${JSON.stringify(filterCriteria)}`)
        .then(async (response) => {
          if (response && response["nsScoredSellers"] && response["nsScoredSellers"].length > 0) {
            let SPStatus = response["nsScoredSellers"][0].status
            this.setState({ SPStatus: SPStatus })
          }
        })
    }
  }

  /**
   * 
   * @returns settings Data
   */
  getSettingsData = async () => {
    let StatusArray = [];
    let filterCriteria = {}

    filterCriteria['criteria'] = [{ key: 'activeIndicator', value: true, type: 'eq', add_Filter: true },
      // { key: 'screenType', value: ["Property Editor"], type: 'in', add_Filter: true }
    ];
    fetchMethodRequest('GET', `PropertyStatus?filter=${JSON.stringify(filterCriteria)}&type=exportToCsv`).then(async (response) => {
      if (response && response["propertyStatuses"] && response["propertyStatuses"].length > 0) {
        let propertyStatuses = response["propertyStatuses"]
        for (let status of propertyStatuses) {
          StatusArray.push({ label: status.status, value: status.status })
        }
        await this.setState({ settingsData: StatusArray })
      }
    })
    return this.state.settingsData;
  }
  /**
   * 
   * @returns get reasons 
   */
  getReasonForEditor = async () => {
    let ReasonsArray = [];
    let filterCriteria = {};
    filterCriteria['criteria'] = [{ key: 'activeIndicator', value: true, type: 'eq', add_Filter: true }];
    return fetchMethodRequest('GET', `${apiCalls.PropertyReasons}?filter=${JSON.stringify(filterCriteria)}&type=exportToCsv`)
      .then(async (response) => {
        if (response && response["propertyReasons"].length > 0) {
          let propertyReasons = response["propertyReasons"]
          for (let reason of propertyReasons) {
            if (ReasonsArray.findIndex(obj => obj.value === reason.reason) === -1) {
              ReasonsArray.push({ label: reason.reason, value: reason.reason })
            }
          }
          await this.setState({ shownreasons: ReasonsArray })
        } else if (response && response['errorMessage'] === configMessages.warningMessage) {
          await this.setState({
            sessionWarning: true,

          })
        }
      }).catch((err) => {
        return err;
      })
  }

  /**
   * 
   * @returns get reasons from propertyStatusandReasonField 
   */
  getNewStatusForEditor = async (val) => {
    var propertyStatusArray = [], filterCriteria = {}
    var propertyStatusReasonArray = [], defaultArray = []
    filterCriteria.sortfield = "stageSequence, statusSequence, reasonSequence"
    filterCriteria.direction = "asc"
    filterCriteria['criteria'] = [
      { key: 'activeIndicator', value: true, type: 'eq', add_Filter: true },
      { key: 'propertyStatusFlag', value: true, type: 'eq', add_Filter: true },
      { key: 'propertyStatusUsage', value: 'Deal Sizing Property Editor', type: 'eq', add_Filter: true }
    ]
    return fetchMethodRequest('GET', `${apiCalls.propertyStatusReason}?filter=${JSON.stringify(filterCriteria)}`)
      .then(async (response) => {
        if (response && response["propertyStatusReasons"].length > 0) {
          let propertyStatusReasons = response["propertyStatusReasons"]
          for (let reason of propertyStatusReasons) {
            let statusIndex = propertyStatusArray.findIndex(obj => obj.label === reason.status)
            if (statusIndex === -1) {
              if (reason.status.startsWith("MATCHING") || reason.status.startsWith("POST-FILING") || reason.status.startsWith("MAPPING")) {
                defaultArray.push(reason.status)
              }
              propertyStatusArray.push({ label: reason.status, value: reason.status })
            }
            let reasonIndex = propertyStatusReasonArray.findIndex(obj => obj.label === reason.reason)
            if (reasonIndex === -1) {
              propertyStatusReasonArray.push({ label: reason.reason, value: reason.reason })
            }
          }
          await this.setState({ propertyStatusArray: propertyStatusArray, propertyStatusReasonArray: propertyStatusReasonArray })

        } else if (response && response["propertyStatusReasons"].length == 0) {
          await this.setState({ propertyStatusArray: [], propertyStatusReasonArray: [] })
        } else if (response && response['errorMessage'] === configMessages.sessionWarning) {
          await this.setState({
            sessionWarning: true,

          })
        }
      }).catch((err) => {
        return err;
      })
  }

  /**
  * 
  * @returns get reasons from propertyStatusandReasonField 
  */
  getNewStatusReasonsForEditor = async (val) => {
    var propertyStatusArray = [], filterCriteria = {}
    var propertyStatusReasonArray = [], defaultArray = []
    filterCriteria.sortfield = "stageSequence, statusSequence, reasonSequence"
    filterCriteria.direction = "asc"
    filterCriteria['criteria'] = [
      { key: 'activeIndicator', value: true, type: 'eq', add_Filter: true },
      { key: 'propertyStatusFlag', value: true, type: 'eq', add_Filter: true },
      { key: 'propertyStatusUsage', value: 'Property Editor', type: 'eq', add_Filter: true }
    ]

    return fetchMethodRequest('GET', `${apiCalls.propertyStatusReason}?filter=${JSON.stringify(filterCriteria)}`)
      .then(async (response) => {
        if (response && response["propertyStatusReasons"].length > 0) {
          let propertyStatusReasons = response["propertyStatusReasons"]
          for (let reason of propertyStatusReasons) {
            let reasonIndex = propertyStatusReasonArray.findIndex(obj => obj.label === reason.reason)
            if (reasonIndex === -1) {
              propertyStatusReasonArray.push({ label: reason.reason, value: reason.reason })
            }
          }
          await this.setState({ propertyStatusReasonArray: propertyStatusReasonArray, propertyStatusesandReasonsResponse: response["propertyStatusReasons"] })
        } else if (response && response["propertyStatusReasons"].length == 0) {
          await this.setState({ propertyStatusArray: [], propertyStatusReasonArray: [], propertyStatusesandReasonsResponse: [] })
        } else if (response && response['errorMessage'] === configMessages.warningMessage) {
          await this.setState({
            sessionWarning: true,
            propertyStatusesandReasonsResponse: []
          })
        }
      }).catch((err) => {
        return err;
      })
  }
  /**
   * 
   * @param refresh SP Call
   */
  getSPUpdate = async () => {
    this.setState({
      isLoading: true,
      blockSP: true
    })
    let url = `seller/updateSimilarProperties?ESTATEID=${this.state.selectedSeller.EstateID}&EstateName='${encodeURIComponent(this.state.selectedSeller.EstateName)}'&ScoringType=DS`
    this.setState({
      flag: true
    })
    fetchMethodRequest("GET", url)
      .then(async (response) => {
        if (response) {
          if (response.respCode && response.respMessage) {
            this.setState({
              flag: false
            })
            // this.datatableref.getErrorMessage(response.respMessage, "", 'success')
            await this.setState({
              data: response.details,
              isLoading: false,
              blockSP: false
            })
          } else if (response.errorMessage) {
            this.datatableref.getErrorMessage(response.errorMessage, "", 'error')
            this.setState({
              isLoading: false,
              blockSP: false
            })
          }
        }
      });

    setTimeout(() => {
      if (this.state.flag) {
        this.datatableref.getErrorMessage("Process Taking Too long, please click GO button after 5 minutes.", 'success')
        this.setState({
          isLoading: false
        })
      }
    }
      , 60000);
  }

  isOpenMatchModel = async (e) => {
    e.stopPropagation()
    e.preventDefault()
    await this.setState({
      isOpenMatchModel: true
    })

  }

  isOpenMatchCreateModel = async () => {
    await this.setState({
      isOpenMatchCreateModel: true
    })

  }

  isCloseMatchModel = async (response) => {
    await this.setState({
      isOpenMatchModel: false
    })
  }

  isCloseMatchCreateModel = async () => {
    await this.setState({
      isOpenMatchCreateModel: false
    })
  }
  getSellerName = async (seller) => {
    await this.setState({
      selectedSeller: seller
    })
    this.getSPData();
  }

  getUpdatedResponseforParent = async (response, groupFlag) => {
    let modifiedData = [];
    if (groupFlag && !groupFlag.includes("ReMap")) {
      let modifiedData = [];
      let field = groupFlag.substring(0, 6).toLowerCase();
      field = field + "ParentPropertyID"
      response.forEach((item, index) => {
        if (item && item[field] === item.propertyID && !item.propertyStatus.includes("MATCHING")) {
          let parentItem = item.propertyID
          let childLength = []
          childLength = response.filter((obj => parentItem === obj[field] && obj.propertyStatus.includes("MATCHING")))
          if (childLength.length >= 1) {
            modifiedData.push(item);
            modifiedData = [...modifiedData, ...childLength]
          }
        }
      });
      return modifiedData;
    } else {
      await this.getNewStatusForReMap()
      var field = ""
      if (groupFlag === "ReMapSPGroup3Flag") {
        field = "reMapGroup3ParentPropertyID";
      } else if (groupFlag === "ReMapSPGroup2Flag") {
        field = "reMapGroup2ParentPropertyID";
      } else if (groupFlag === "ReMapSPGroup1Flag") {
        field = "reMapGroup1ParentPropertyID";
      }
      // field = field + "ParentPropertyID"
      let reMap = this.state.propertyStatusArrayReMap
      response.forEach((item, index) => {
        if (item && item[field] === item.propertyID && !reMap.includes(item.propertyStatus)) {
          let parentItem = item.propertyID
          let childLength = []

          // childLength = response.filter((obj => parentItem === obj[field] && reMap.includes(item.propertyStatus)))
          for (let obj of response) {
            if (parentItem === obj[field] && parentItem != obj.propertyID) {
              childLength.push(obj)
            }
          }
          if (childLength.length >= 1) {
            modifiedData.push(item);
            modifiedData = [...modifiedData, ...childLength]
          }
        }
      });

    }
    return modifiedData;
  }

  /**
   * 
   * @param {String} getErrorMessage 
   */
  getErrorMessage = (getErrorMessage) => {
    if (getErrorMessage.errorMessage || getErrorMessage.title) {
      if (getErrorMessage && getErrorMessage.title) {
        this.datatableref.getErrorMessage(getErrorMessage.title, "", "error")
      }
      if (getErrorMessage && getErrorMessage.errorMessage) {
        this.datatableref.getErrorMessage(getErrorMessage.errorMessage, "", "error")
      }
    } else {
      this.datatableref.getErrorMessage(getErrorMessage.respMessage, "", "success")
    }
  }

  /**
   * 
   * @returns Match Property Modal
   */
  getOpenPropertyMatchModel() {
    return (
      <MatchPropertyModal
        isOpenMatchmodel={this.state.isOpenMatchModel}
        isCloseModal={this.isCloseMatchModel}
        isShowErrorMessage={this.getErrorMessage}
        getTableFields={this.getTableFields}
        selectedOpra={this.state.selectedOpra}
        seller={this.state.selectedSeller}
        businessNames={this.state.businessNames}
        // screen={"propertyEditorV2"}
        type={"Deal Sizing Property Editor"}
      />
    )
  }

  getFileforOptions = async (data) => {
    await this.setState({
      businessNames: data
    })
  }
  selectedOpra = async (val) => {
    await this.setState({ selectedOpra: val })
  }
  removePropagation(e) {
    e.stopPropagation()
    e.preventDefault()
  }

  getTaskChange(event, type) {
    if (type === "add") {
      this.setState({
        createTask: event.value
      })
    } else {
      this.setState({
        createTaskAdd: event.value
      })
    }
    this.datatableref.changeCreateTask(event, type)
  }

  clearValues = async () => {
    await this.setState({
      createTaskAdd: "",
      createTask: ""
    })
  }

  handleCalltoPreventParentProp(e) {
    e.stopPropagation()
    e.preventDefault()
  }

  redirectionBtns = (type) => {
    document.getElementsByClassName("redirectToOpra")[0].click();
  }

  setValidatedPropertyUpdaterModal = async (e, selectedSeller) => {
    await this.setState({
      ValidatedPropertyUpdaterModal: true,
      PropertyData: selectedSeller
    })
  }

  closeValidatedPropertyUpdaterModal = async () => {
    await this.setState({
      ValidatedPropertyUpdaterModal: false
    })
  }

  getValidatedPropertyUpdaterModal() {
    return (
      <ValidatedPropertyUpdaterModal
        estate={this.state.PropertyData.selectedSeller}
        openValidatedPropertyUpdaterModal={this.state.ValidatedPropertyUpdaterModal}
        closeValidatedPropertyUpdaterModal={this.closeValidatedPropertyUpdaterModal} />
    )
  }
  selectedViewType = async (e) => {
    await this.setState({
      selectedViewType: e
    })
    await this.datatableref.selectedViewType(e)
  }
  getHeaderForCustumBtns = (properData, selectedRows) => {
    let isEditted = localStorage.getItem("ISEdited") ? true : false
    return (
      <div className='ml-2 d-flex custum_btns_for_PE accordian_custum_header w-100' onClick={(e) => this.handleCalltoPreventParentProp(e)}>
        <div className='mr-2 ml-auto'>
          <Dropdown
            placeholder={"Create"}
            value={""}
            appendTo={document.body}
            disabled={selectedRows && selectedRows.length > 0 && properData && properData.selectedSeller && !this.state.createTask ? false : true}
            options={[
              // { label: "New Claim", value: "New Claim" },
              // { label: "Research Request", value: "Research Request" },
              // { label: "Seller Negative Notice", value: "Seller Negative Notice" },
              { label: "General Task", value: "General Task" },
              // { label: "Site Confirmation", value: "Site Confirmation" }
            ]}
            onChange={(e) => this.getTaskChange(e, "add")}
          />
        </div>
        <div className='mr-2'>
          <Dropdown
            value={""}
            appendTo={document.body}
            placeholder={"Add To"}
            disabled={selectedRows && selectedRows.length > 0 && properData && properData.selectedSeller && !this.state.createTaskAdd ? false : true}
            options={[
              // { label: "Existing Claim", value: "Existing Claim" },
              // { label: "Research Request", value: "Research Request" },
              // { label: "Seller Negative Notice", value: "Seller Negative Notice" },
              { label: "General Task", value: "General Task" },
              // { label: "Site Confirmation", value: "Site Confirmation" }
            ]}
            onChange={(e) => this.getTaskChange(e, "addToTask")}
          />
        </div>
        <div className='mr-2'>
          <Dropdown
            value={this.state.selectedViewType}
            style={{ borderLeft: "1px solid lightgray" }}
            options={ [
              { label: "Default", value: "Default" },
              { label: "All Validated", value: "All Validated" },
          ] }
            // disabled={this.props.allUsersData.length === 0 || this.props.isDisabledTab ? true : false}
            onChange={(e) => this.selectedViewType(e.target.value)}
          /></div>
        <Button color="primary"
          outline
          id="column order"
          className={"mx-1 p-1 py-0 mt-1 mb-0 "}
          // className={this.props.totalRecordsLength < 10000 && this.props.totalRecordsLength > 0 && (this.props.type === "Property Editor" || this.props.type === "Deal Sizing-Properties" || this.props.type === "OPRA2 Property Editor") && this.props.PropertyData && !this.props.PropertyData.isValidate ? "mt-4" : "mt-4"}
          style={{ cursor: "pointer", height: "38px" }}
          disabled={isEditted}
          onClick={this.datatableref ? this.datatableref.openShowHideColumnsModal : null}
        >
          Column Order
        </Button>
        <Button color="primary"
          className='p-1 py-0 mb-0 mt-1 mx-1' style={{ height: "38px" }}
          disabled={this.state.SPStatus == "In Progress" || this.state.blockSP ? true : false}
          onClick={(e) => this.getSPUpdate(e)}
          outline
        >Refresh SP </Button><Button color="primary"
          className='p-1 py-0 mb-0 mt-1 mx-1' style={{ height: "38px" }}
          disabled={properData && properData.selectedSeller ? false : true}
          onClick={(e) => this.isOpenMatchModel(e)}
          outline
        // disabled={!this.props.apidataforExport && !this.state.apiUrlforExport ? true : false}
        >Manually Match </Button>
        {/* {this.props.type !== "Deal Sizing Property Editor" ? <Button color="primary"
          className='p-1 py-0 mb-0 mt-1 mx-1' style={{ height: "38px" }}
          disabled={properData && properData.selectedSeller ? false : true}
          onClick={(e) => this.redirectionBtns()}
          outline
        // disabled={!this.props.apidataforExport && !this.state.apiUrlforExport ? true : false}
        >Create Property </Button> : ""} */}
        {/* <span> */}

        {/* </span> */}
        <Button color="primary"
          size="sm"
          outline
          style={{ height: 38 }}
          className="p-1 mx-1 mt-1 mb-0"
          disabled={properData && properData.selectedSeller ? false : true}
          onClick={() => this.datatableref.getisLoading("", "clear")}>Clear Col. Filters
        </Button>
        {this.datatableref ? this.datatableref.getExcelDownload() : null}

        <Button color="primary"
          className='p-1 py-0 mb-0 mt-1 mr-1' style={{ height: "38px" }}
          onClick={(e) => this.datatableref.handleSelectedAction(e)}
          outline
          disabled={properData && properData.selectedSeller ? false : true}
        >
          <FontAwesomeIcon
            icon='download'
            data-toggle="tool-tip" title={"Export To excel"}
            className='pl-1' size='lg' />
        </Button>
        <div>
          <div class="btn-group-vertical tooltipClaims" style={{ marginRight: "0px", height: "30px" }} onClick={(e) => this.op.toggle(e)} data-toggle="tool-tip"
            title="click to see definations" >
            <div className='mb-2'
              style={{ display: "block" }}
              data-toggle="tool-tip"
              title={"Click to see info"}
            >
              <span ><FontAwesomeIcon
                style={{ fontSize: "20px", float: "left" }}
                icon={faClipboardList}
                className='mr-1'
                color={"#354f6e"}
              /></span>
              <span><FontAwesomeIcon
                style={{ fontSize: "20px", float: "left" }}
                icon={faClipboardList}
                className='mr-1'
                color={"brown"}
              /></span>
            </div>
          </div>
          <OverlayPanel appendTo={document.body} ref={(el) => this.op = el} showCloseIcon={true} dismissable={true}>
            <p><span><FontAwesomeIcon
              style={{ fontSize: "20px", float: "left" }}
              icon={faClipboardList}
              className='mr-2'
              color={"brown"}
            /></span>Property has a connected Claim and also may be connected Task</p>
            <p><span><FontAwesomeIcon
              style={{ fontSize: "20px", float: "left" }}
              icon={faClipboardList}
              className='mr-2'
              color={"#354f6e"}
            /></span>Property has only connected task</p>

          </OverlayPanel>
        </div>
        {/* {this.props.type !== "Deal Sizing Property Editor" ?
          <div style={this.state.loginCredentials && this.state.loginCredentials.roleName === "Admin" && properData && properData.selectedSeller ? { display: "block" } : { display: "none" }}>
            <img
              src={configImages.ResearchIcon}
              onClick={this.setPropertyTrailViewerModal}
              style={{ margin: 0, height: '40px', width: '40px' }}
            />
          </div> : ""} */}
        <FontAwesomeIcon
          style={{ fontSize: "20px", float: "left" }}
          icon={faEnvelope}
          onClick={() => this.getUrlbyParams()}
          className='shareIcon'
          color={"#354f6e"}
        />
        {/* <a className="lnr lnr-envelope shareIcon" onClick={() => this.getUrlbyParams()} href={`mailto:${this.state.customerEmail}?subject=${this.state.ProertyShareSubject}&body=Hi%0APlease%20review%20these%20properties.%0A%0A${encodeURIComponent(this.state.PropertySharebody)}%0A%0A%0AThanks%0AOPRA2%0Aopra@oakpointpartners.com`} >
        </a> */}
        {/* {properData && properData.selectedSeller ? */}
        {/* {this.props.type !== "Deal Sizing Property Editor" ?
          <div style={this.state.loginCredentials && properData && properData.selectedSeller && (this.state.loginCredentials.roleName === "Admin" || this.state.loginCredentials.roleName === config.roleNames.coreOpsManager) ? { display: "block" } : { display: "none" }}>
            <Button className="px-0"
              style={{ backgroundColor: "white", margin: 0, padding: "0px!important", border: 0 }}>
              <img
                src={configImages.ValidatedPropertyUpdaterIcon}
                onClick={(e) => this.setValidatedPropertyUpdaterModal(e, properData)}
                style={{ borderRadius: 0, margin: 0, height: '30px', width: '30px' }}
              />
            </Button>
          </div> : ""} */}
        {/* : "" */}
        {/* } */}


      </div>
    )
  }

  setPropertyTrailViewerModal = async () => {
    await this.setState({
      PropertyTrailViewerModal: true
    })
  }

  closePropertyTrailViewerModal = async () => {
    await this.setState({
      PropertyTrailViewerModal: false
    })
  }

  getPropertyTrailViewerModal() {
    return (
      <PropertyTrailViewerModal
        openPropertyTrailViewerModal={this.state.PropertyTrailViewerModal}
        closePropertyTrailViewerModal={this.closePropertyTrailViewerModal} />
    )
  }


  getUrlbyParams = async () => {
    let SharedUrl = await this.datatableref.getUrlbyParams()
    await this.setState({
      PropertySharebody: SharedUrl.url,
      ProertyShareSubject: SharedUrl.Subject,
      customerEmail: ""
    })
    document.getElementsByClassName("openEnvelop")[0].click();

  }


  /**
   * 
   * @returns table fields to show in grid
   */
  getTableFields = () => {

    let data = [
      {
        textAlign: "center",
        width: 47,
        field: "",
        // fieldType: "multiple",
        header: "",
        selectionMode: "multiple",
        frozen: true,
        show: true,
        mobile: true,
        displayInSettings: false,
      },
      {
        "show": false,
        "textAlign": "left",
        "width": 110,
        "field": "bucket",
        "mobile": true,
        "header": "Bucket",
        "displayInSettings": true,
        "filter": false,
        "sortable": false,
      }, {
        "show": true,
        "textAlign": "left",
        "width": 110,
        "field": "group1ParentPropertyID",
        "mobile": true,
        "header": "Group1 Parent Prop. ID",
        "displayInSettings": true,
        "filter": false,
        "sortable": false,
      },
      {
        "show": true,
        "textAlign": "left",
        "width": 80,
        "field": "group2ParentPropertyID",
        "mobile": true,
        // "filterType": "num",
        "header": "Group2 Parent Prop.ID",
        "displayInSettings": true,
        "filter": false,
        "sortable": false,
      },
      {
        "show": true,
        "textAlign": "left",
        "width": 80,
        "field": "group3ParentPropertyID",
        "mobile": true,
        // "filterType": "text",
        "header": "Group3 Parent Prop.ID",
        "displayInSettings": true,
        "filter": false,
        "sortable": false,
      },
      // {
      //   "show": true,
      //   "textAlign": "left",
      //   "width": 150,
      //   "field": "reMapGroup1ParentPropertyID",
      //   "mobile": true,
      //   "header": "ReMap Group1 Parent Prop. ID",
      //   "displayInSettings": true,
      //   "filter": true,
      //   "sortable": true,
      // },
      // {
      //   "show": true,
      //   "textAlign": "left",
      //   "width": 150,
      //   "field": "reMapGroup2ParentPropertyID",
      //   "mobile": true,
      //   // "filterType": "num",
      //   "header": "ReMap Group2 Parent Prop.ID",
      //   "displayInSettings": true,
      //   "filter": true,
      //   "sortable": true,
      // },
      // {
      //   "show": true,
      //   "textAlign": "left",
      //   "width": 150,
      //   "field": "reMapGroup3ParentPropertyID",
      //   "mobile": true,
      //   // "filterType": "text",
      //   "header": "ReMap Group3 Parent Prop.ID",
      //   "displayInSettings": true,
      //   "filter": true,
      //   "sortable": true,
      // },
      // {
      //   "show": true,
      //   "textAlign": "left",
      //   "width": 110,
      //   "field": "connectedTaskID",
      //   "mobile": true,
      //   fieldType: "Array",
      //   "header": "Connected TaskID",
      //   "displayInSettings": true,
      //   "filter": true,
      //   "sortable": true,
      // }, {
      //   "show": true,
      //   "textAlign": "left",
      //   "width": 110,
      //   "field": "connectedClaimID",
      //   "mobile": true,
      //   "header": "Connected ClaimId",
      //   "displayInSettings": true,
      //   "filter": true,
      //   "sortable": true,
      // },
      {
        "show": true,
        "textAlign": "left",
        "width": 110,
        "field": "businessName",
        isFuzzy: true,
        "mobile": true,
        "header": "Scored Name",
        "displayInSettings": true,
        "filter": true,
        "sortable": true,
      },
      {
        "show": true,
        "textAlign": "left",
        "width": 80,
        "field": "statePropertyId",
        "allowInt": true,
        "mobile": true,
        "filterType": "num",
        // "fieldType": "ShowData",
        "header": "St. Prop. ID",
        "displayInSettings": true,
        "filter": false,
        "sortable": false,
      },
      // {
      //   "show": true,
      //   "textAlign": "left",
      //   "width": 80,
      //   "field": "connectedClaimID",
      //   "mobile": true,
      //   // "filterType": "text",
      //   // "fieldType": "ShowData",
      //   "header": "OPP ClaimID",
      //   "displayInSettings": true,
      //   "filter": true,
      //   "sortable": true,
      // },
      {
        "show": true,
        "textAlign": "left",
        "width": 80,
        "allowInt": true,
        "filterType": "num",
        "field": "propertyID",
        "mobile": true,
        "fieldType": "ShowData",
        "header": "OPP Prop. ID",
        "displayInSettings": true,
        "filter": false,
        "sortable": false,
      }, {
        "show": true,
        "textAlign": "left",
        "width": 80,
        "field": "alternateID",
        // "filterType": "num",
        "mobile": true,
        // "fieldType": "ShowData",
        "header": "Alternate ID",
        "displayInSettings": true,
        "filter": false,
        "sortable": false,
      }, {
        "show": true,
        "textAlign": "left",
        "width": 80,
        "field": "addressId",
        "mobile": true,
        "filterType": "num",
        // "fieldType": "ShowData",
        "header": "Address ID",
        "displayInSettings": true,
        "filter": false,
        "sortable": false,
      }, {
        "show": true,
        "textAlign": "left",
        "width": 80,
        "field": "nameId",
        "filterType": "num",
        "mobile": true,
        // "fieldType": "ShowData",
        "header": "Name ID",
        "displayInSettings": true,
        "filter": false,
        "sortable": false,
      },
      {
        "show": true,
        "textAlign": "left",
        "width": 110,
        "field": "propertyName",
        "isFuzzy": true,
        "mobile": true,
        "header": "Reported Owner Name",
        "displayInSettings": true,
        "filter": true,
        "sortable": true,
      }, {
        "show": true,
        "textAlign": "left",
        "width": 110,
        "field": "holderName",
        isFuzzy: true,
        "mobile": true,
        "header": "Holder Name",
        "displayInSettings": true,
        "filter": false,
        "sortable": false,
      }, {
        "show": true,
        "textAlign": "left",
        "width": 110,
        isFuzzy: true,
        "field": "propertyDescription",
        "mobile": true,
        "header": "Property Description",
        "displayInSettings": true,
        "filter": false,
        "sortable": false,
      },

      {
        "show": true,
        "textAlign": "left",
        "width": 110,
        isFuzzy: true,
        "field": "businessAddressLine1",
        "mobile": true,
        "header": "Business Add Line1",
        "displayInSettings": true,
        "filter": true,
        "sortable": true,
      }, {
        "show": true,
        "textAlign": "left",
        "width": 110,
        isFuzzy: true,
        "field": "propertyAddressLine1",
        "mobile": true,
        "header": "Prop. Address Line1",
        "displayInSettings": true,
        "filter": true,
        "sortable": true,
      },
      {
        "show": true,
        "textAlign": "left",
        "width": 100,
        "field": "businessCity",
        "mobile": true,
        // "fieldType": "Link",
        "header": "Biz City",
        "displayInSettings": true,
        "filter": false,
        "sortable": false,
      }, {
        "show": true,
        "textAlign": "right",
        "width": 60,
        "field": "businessState",
        "mobile": true,
        "header": "Biz St",
        "filter": false,
        "sortable": false,
        "displayInSettings": true,
        // "options": [{ "label": "Active", "value": 'Active' }, { "label": "Pending", "value": 'Pending' }, { "label": "Inactive", "value": 'Inactive' }],
      }, {
        "show": true,
        "textAlign": "right",
        "width": 60,
        "field": "sourceState",
        "mobile": true,
        "header": "Src. St",
        "filter": true,
        "isFuzzy": true,
        "sortable": true,
        "displayInSettings": true,
        // "options": [{ "label": "Active", "value": 'Active' }, { "label": "Pending", "value": 'Pending' }, { "label": "Inactive", "value": 'Inactive' }],
      },
      {
        "show": true,
        "textAlign": "left",
        "width": 100,
        "field": "prospectiveStatus",
        "mobile": true,
        "header": "Prospective Status",
        "filter": false,
        "sortable": false,
        "displayInSettings": true,
        "isFuzzy": true
        // "options": [{ "label": "Active", "value": 'Active' }, { "label": "Pending", "value": 'Pending' }, { "label": "Inactive", "value": 'Inactive' }],
      },
      {
        "show": true,
        "textAlign": "left",
        "width": 100,
        "field": "businessZipCode",
        "mobile": true,
        "header": "Biz Zip",
        "filter": false,
        "sortable": false,
        "displayInSettings": true,
        // "options": [{ "label": "Active", "value": 'Active' }, { "label": "Pending", "value": 'Pending' }, { "label": "Inactive", "value": 'Inactive' }],
      },

      {
        "show": true,
        "textAlign": "left",
        "width": 100,
        "field": "createdByUser",
        "mobile": true,
        sorted: true,
        "header": "Created By User",
        "filter": false,
        "sortable": false,
        "displayInSettings": true,
        // "options": [{ "label": "Active", "value": 'Active' }, { "label": "Pending", "value": 'Pending' }, { "label": "Inactive", "value": 'Inactive' }],
      },
      {
        "show": true,
        "textAlign": "left",
        "width": 60,
        "field": "propertyState",
        "mobile": true,
        "header": "Prop. St",
        "displayInSettings": true,
        "filter": false,
        "sortable": true,
      }, {
        "show": true,
        "textAlign": "left",
        "width": 110,
        "field": "propertyCity",
        "mobile": true,
        "isFuzzy": true,

        "header": "Prop. City",
        "displayInSettings": true,
        "filter": true,
        "sortable": true,
      },

      {
        "show": true,
        "textAlign": "left",
        "width": 80,
        "field": "propertyZipCode",
        "mobile": true,
        "header": "Prop. Zip",
        "displayInSettings": true,
        "filter": false,
        "sortable": true,
      },

      {
        "show": true,
        "textAlign": "left",
        "width": 80,
        "filterType": "num",
        "field": "scoreDate",
        "mobile": true,
        "fieldType": "Date",
        "dateFormat": 'MM-DD-yyyy',
        "header": "Score Dt",
        "filter": false,
        "sortable": false,
        "displayInSettings": true,
      },

      {
        "show": true,
        "textAlign": "right",
        "width": 80,
        "field": "propertyMinValueAmount",
        "allowInt": true,
        "mobile": true,
        "header": "Min Amt",
        "filterType": "num",
        "fieldType": "formatCurrencywithdec",
        "filter": true,
        "sortable": true,
        "displayInSettings": true,
      },
      {
        "show": true,
        "textAlign": "right",
        "width": 80,
        "field": "propertyMaxValueAmount",
        "allowInt": true,
        "fieldType": "formatCurrencywithdec",
        "filterType": "num",
        "mobile": true,
        "header": "Max Amt",
        "displayInSettings": true,
        "filter": true,
        "sortable": true,
      },

      {
        "show": true,
        "textAlign": "left",
        "width": 100,
        "field": "propertyDataSource",
        isFuzzy: true,
        "mobile": true,
        // "fieldType": "Percentage",
        "header": " Prop. Source",
        "displayInSettings": true,
        "filter": false,
        "sortable": false,
      },
      {
        "show": true,
        "textAlign": "left",
        "width": 80,
        "field": "nameIssueCode",
        "mobile": true,
        // "filterType": "num",
        "fieldType": "nameIssue",
        "header": "Legacy Name Iss",
        "displayInSettings": true,
        "filter": false,
        "sortable": false,
      }, {
        "show": true,
        "textAlign": "left",
        "width": 80,
        "field": "newNameIssueCode",
        "mobile": true,
        // "filterType": "num",
        // "fieldType": "Percentage",
        "header": "New Name Iss",
        "displayInSettings": true,
        "filter": false,
        "sortable": false,
      }, {
        "show": true,
        "textAlign": "left",
        "width": 80,
        "field": "nameSource",
        "mobile": true,
        // "filterType": "num",
        // "fieldType": "Percentage",
        "header": "Name Src",
        "displayInSettings": true,
        "filter": false,
        "sortable": false,
      },
      // {
      //   "show": true,
      //   "textAlign": "left",
      //   "width": 220,
      //   "field": "matchMatchStatus",
      //   "filterType": "multiSelect",
      //   isFuzzy: true,
      //   "mobile": true,
      //   "header": "Old Status",
      //   "filterOptions": this.state.settingsData ? this.state.settingsData : [],
      //   "displayInSettings": true,
      //   "filter": true,
      //   "sortable": true,
      // },
      // {
      //   "show": true,
      //   "textAlign": "left",
      //   "width": 215,
      //   "field": "matchStatusReason",
      //   "mobile": true,
      //   "filterType": "multiSelect",
      //   "filterOptions": this.state.shownreasons,
      //   isFuzzy: true,
      //   // "fieldType": "Link",
      //   "header": "Old Reason",
      //   "displayInSettings": true,
      //   "filter": true,
      //   "sortable": true,
      // },
      {
        "show": true,
        "textAlign": "left",
        "width": 220,
        "field": "propertyStatus",
        "filterType": "multiSelect",
        isFuzzy: true,
        "mobile": true,
        "header": "Prop. Status",
        "filterOptions": this.state.propertyStatusArray ? this.state.propertyStatusArray : [],
        "displayInSettings": true,
        "filter": true,
        "sortable": true,
      },
      {
        "show": true,
        "textAlign": "left",
        "width": 215,
        "field": "propertyStatusReason",
        "mobile": true,
        "filterType": "multiSelect",
        "filterOptions": this.state.propertyStatusReasonArray ? this.state.propertyStatusReasonArray : [],
        isFuzzy: true,
        // "fieldType": "Link",
        "header": "Prop. Reason",
        "displayInSettings": true,
        "filter": true,
        "sortable": true,
      },

      {
        "show": true,
        "textAlign": "left",
        "width": 110,
        "field": "fileFor",
        "mobile": true,
        "header": "Mapped Name",
        // "isFuzzy": true,
        "filter": true,
        "displayInSettings": true,
        "sortable": false,
      },
      {
        "show": true,
        "textAlign": "left",
        "width": 110,
        "field": "relevantSellerName",
        "mobile": true,
        "header": "Relevant Seller Name",
        // "isFuzzy": true,
        "filter": true,
        "displayInSettings": true,
        "sortable": false,
      },
      {
        "show": true,
        "textAlign": "left",
        "width": 360,
        "field": "matchStatusNotes",
        "mobile": true,
        "fieldType": "Array",
        "filter": false,
        "openNotes": true,
        // "isFuzzy": true,
        "header": "Mapping Note",
        "displayInSettings": true,
        // "filter": false,
        "sortable": false,
      },
      // {
      //   "show": true,
      //   "textAlign": "left",
      //   "width": 110,
      //   "field": "claimID",
      //   "mobile": true,
      //   // "fieldType": "Link",
      //   isFuzzy: true,
      //   "header": "St.Claim ID",
      //   "displayInSettings": true,
      //   "filter": true,
      //   "sortable": true,
      // },
      {
        "show": true,
        "textAlign": "left",
        "width": 115,
        "field": "propertyAlternateId",
        "filterType": "num",
        "mobile": true,
        "header": "Prop. Alt Id",
        "displayInSettings": true,
        "filter": false,
        "sortable": false,
      },

      // {
      //   "show": true,
      //   "textAlign": "left",
      //   "width": 115,
      //   "field": "lastUpdatedBy",
      //   "mobile": true,
      //   "isFuzzy": true,
      //   "header": "Last Updated By",
      //   "displayInSettings": true,
      //   "filter": true,
      //   "sortable": true,
      // },
      {
        "show": true,
        "textAlign": "left",
        "width": 115,
        "field": "validatedBy",
        "mobile": true,
        "header": "Validated By",
        "displayInSettings": true,
        "filter": false,
        "sortable": false,
      },
      // {
      //   "show": true,
      //   "textAlign": "left",
      //   "width": 115,
      //   "field": "filedBy",
      //   "mobile": true,
      //   "header": "Filed By",
      //   "displayInSettings": true,
      //   "filter": true,
      //   "sortable": true,
      // },
      {
        "show": true,
        "textAlign": "left",
        "width": 115,
        "field": "validatedDate",
        "filterType": "num",
        "fieldType": "Date",
        "dateFormat": 'MM-DD-yyyy',
        "mobile": true,
        "header": "Dt Validated",
        "displayInSettings": true,
        "filter": false,
        "sortable": false,
      },
      {
        "show": true,
        "textAlign": "left",
        "width": 115,
        "field": "createdDate",
        "filterType": "num",
        "fieldType": "Date",
        "dateFormat": 'MM-DD-yyyy',
        "mobile": true,
        "header": "Dt Created",
        "displayInSettings": true,
        "filter": false,
        "sortable": false,
      }, {
        "show": true,
        "textAlign": "left",
        "width": 115,
        "field": "dateIndexedInElastic",
        "filterType": "num",
        "fieldType": "Date",
        "dateFormat": 'MM-DD-yyyy',
        "mobile": true,
        "header": "Dt Indexed In Elastic",
        "displayInSettings": true,
        "filter": false,
        "sortable": false,
      }, {
        "show": true,
        "textAlign": "left",
        "width": 115,
        "field": "updatedDate",
        "filterType": "num",
        "fieldType": "Date",
        "dateFormat": 'MM-DD-yyyy',
        "mobile": true,
        "header": "Dt Updated in OPRA2",
        "displayInSettings": true,
        "filter": false,
        "sortable": false,
      }, {
        "show": true,
        "textAlign": "left",
        "width": 115,
        "field": "dateOfLastContact",
        "filterType": "num",
        "fieldType": "Date",
        "dateFormat": 'MM-DD-yyyy',
        "mobile": true,
        "header": "Dt Of Last Contact",
        "displayInSettings": true,
        "filter": false,
        "sortable": false,
      },
      {
        "show": true,
        "textAlign": "left",
        "width": 115,
        "field": "nameMatchScore",
        "allowInt": true,
        "filterType": "num",
        "mobile": true,
        "header": "Name Score",
        "displayInSettings": true,
        "filter": false,
        "sortable": true,
      }, {
        "show": true,
        "textAlign": "left",
        "width": 115,
        "field": "opraAddedDate",
        "filterType": "num",
        "fieldType": "Date",
        "dateFormat": 'MM-DD-yyyy',
        "mobile": true,
        "header": "Dt Opra Added",
        "displayInSettings": true,
        "filter": false,
        "sortable": false,
      }, {
        "show": true,
        "textAlign": "left",
        "width": 115,
        "field": "escheatmentDate",
        "filterType": "num",
        "fieldType": "Date",
        "dateFormat": 'MM-DD-yyyy',
        "mobile": true,
        "header": "Dt Escheatment",
        "displayInSettings": true,
        "filter": false,
        "sortable": false,
      },
      {
        "show": true,
        "textAlign": "left",
        "width": 115,
        "field": "addressLine1MatchScore",
        "allowInt": true,
        "filterType": "num",
        "mobile": true,
        "header": "Line1 Score",
        "displayInSettings": true,
        "filter": false,
        "sortable": true,
      },
      // {
      //   "show": true,
      //   "textAlign": "left",
      //   "width": 115,
      //   "allowInt": true,
      //   "filterType": "num",
      //   "field": "propertyResidentialFlag",
      //   "mobile": true,
      //   "header": "Prop. Residential Flag",
      //   "displayInSettings": true,
      //   "filter": false,
      //   "sortable": true,
      // },
      {
        "show": true,
        "textAlign": "left",
        "width": 115,
        "filterType": "num",
        "allowInt": true,
        "field": "cityStateZipScore",
        "mobile": true,
        "header": "CSZ Score",
        "displayInSettings": true,
        "filter": false,
        "sortable": false,
      },
      {
        "show": true,
        "textAlign": "left",
        "width": 115,
        "field": "propertyCoOwnerNames",
        // "allowInt": true,
        // "filterType": "num",
        sorted: true,
        "mobile": true,
        "header": "Co Owner Names",
        "displayInSettings": true,
        "filter": false,
        "sortable": false,
      },
      {
        "show": true,
        "textAlign": "left",
        "width": 115,
        "filterType": "dropdown",
        // "allowInt": true,
        "field": "hasPropertyCoOwner",
        "fieldType": "hasPropertyCoOwner",
        "mobile": true,
        "header": "Co-Owner",
        "displayInSettings": true,
        "filter": false,
        "sortable": false,
      },
      {
        "show": true,
        "textAlign": "left",
        "width": 115,
        "filterType": "dropdown",
        // "allowInt": true,
        "field": "confirmedOnsite",
        "fieldType": "confirmedOnSite",
        "mobile": true,
        "header": "Confirmed Onsite",
        "displayInSettings": true,
        "filter": false,
        "sortable": false,
      },
      {
        "show": true,
        "textAlign": "left",
        "width": 115,
        "field": "confirmedOnsiteDate",
        "filterType": "num",
        "fieldType": "Date",
        "dateFormat": 'MM-DD-yyyy',
        "mobile": true,
        "header": "Confirmed Onsite Date",
        "displayInSettings": true,
        "filter": false,
        "sortable": false,
      },
      {
        "show": true,
        "textAlign": "left",
        "width": 115,
        "field": "confirmedOnsiteBy",
        // "allowInt": true,
        // "filterType": "num",
        "mobile": true,
        "header": "Confirmed Onsite By",
        "displayInSettings": true,
        // "filter": true,
        "sortable": false,
      },
      // {
      //   "show": true,
      //   "textAlign": "left",
      //   "width": 110,

      //   "field": "nameProof",
      //   "mobile": true,
      //   "header": "Name Proof",
      //   "displayInSettings": true,
      //   "filter": true,
      //   "sortable": true,
      // },
      // {
      //   "show": true,
      //   "textAlign": "left",
      //   "width": 110,

      //   "field": "addressProof",
      //   "mobile": true,
      //   "header": "Address Proof",
      //   "displayInSettings": true,
      //   "filter": true,
      //   "sortable": true,
      // },
      // {
      //   "show": true,
      //   "textAlign": "left",
      //   "width": 110,

      //   "field": "feinProof",
      //   "mobile": true,
      //   "header": "Fein Proof",
      //   "displayInSettings": true,
      //   "filter": true,
      //   "sortable": true,
      // },
      // {
      //   "show": true,
      //   "textAlign": "left",
      //   "width": 110,

      //   "field": "holderProof",
      //   "mobile": true,
      //   "header": "Holder Proof",
      //   "displayInSettings": true,
      //   "filter": true,
      //   "sortable": true,
      // },
      // {
      //   "show": true,
      //   "textAlign": "left",
      //   "width": 110,
      //   "field": "sellerProof",
      //   "mobile": true,
      //   "header": "Seller Proof",
      //   "displayInSettings": true,
      //   "filter": true,
      //   "sortable": true,
      // },
      {
        "show": true,
        "textAlign": "left",
        "width": 100,
        "field": "pendingConfirmation",
        "editField": "dropDown",
        options: [{ label: "Confirmed", value: "Confirmed" }, { label: "Missing", value: "Missing" }],
        "mobile": true,
        "header": "Pending Confirmation",
        "displayInSettings": true,
        "filter": false,
        "sortable": false,
      },
      {
        "show": true,
        "textAlign": "left",
        "width": 115,
        "filterType": "dropdown",
        // "allowInt": true,
        "field": "reportedMissing",
        "fieldType": "reportedMissing",
        "mobile": true,
        "header": "Reported Missing",
        "displayInSettings": true,
        "filter": false,
        "sortable": false,
      },
      {
        "show": true,
        "textAlign": "left",
        "width": 115,
        "field": "reportedMissingBy",
        "mobile": true,
        "header": "reported Missing By",
        "displayInSettings": true,
        // "filter": true,
        "sortable": false,
      },
      {
        "show": true,
        "textAlign": "left",
        "width": 115,
        "field": "reportedMissingDate",
        "filterType": "num",
        "fieldType": "Date",
        "dateFormat": 'MM-DD-yyyy',
        "mobile": true,
        "header": "Reported Missing Date",
        "displayInSettings": true,
        "filter": false,
        "sortable": false,
      },
    ]
    return data;
  };

  getFormFields = () => {

    return
  }

  submit = async (item) => {

  }

  //Get THreshold Values from SQL
  getDataFromServer = async () => {
    fetch('GET', `ruleConfiguration`)
      .then(async (response) => {
        if (response && response.ruleConfigurations) {
          await this.setState({
            RulesListArray: response.ruleConfigurations,
          });
          let labels = response.ruleConfigurations, modifiedLabels = []
          for (let i = 0; i < labels.length; i++) {
            modifiedLabels.push({
              label: labels[i].name,
              value: { flagName: labels[i].flagName, name: labels[i].name, description: labels[i].description },
            })
          }
          localStorage.setItem('ruleGroups', JSON.stringify(modifiedLabels))

        } else if (response && response.errorMessage && (response.errorMessage === config.tokenMessage || response.errorMessage === config.sessionExpired)) {
          await this.setState({
            sessionExpiryModal: true
          })
          localStorage.setItem('ruleGroups', JSON.stringify([]))
        } else if (response && response.errorMessage && response.errorMessage === config.warningMessage) {
          await this.setState({
            sessionWarningModal: true
          })
        }
        this.isShowLoader(false)
      }).catch((err) => {
        return err;
      });
  }
  /**
   * 
   * @returns Existing Claim Component calling
   */
  getExistingClaimfromPE = () => {
    return (
      <ExistingClaim
        isOpenMatchmodel={this.state.isExistingMatchClaim}
        isCloseModal={this.isCloseExistingMatchClaim}
        getTableFields={this.getTableFields}
        seller={this.state.selectedSeller}
        selectedRows={this.state.selectedRows}
      />
    )
  }


  getColorbasedonProp = (item) => {
    if (item.connectedClaimID && item.connectedClaimID.length > 0) {
      return "#72261a"
    } else if (item.connectedTaskID && item.connectedTaskID.length > 0) {
      return "#354f6e"
    }
  }

  getDefaultArray = async () => {
    await this.getNewStatusReasonsForEditor()
    return this.state.defaultstatusArray
  }

  /**
   * 
   * @returns New Claim Component calling
   */
  getNewClaimfromPE = () => {
    return (
      <NewClaim
        isOpenMatchmodel={this.state.isNewClaimCreation}
        isCloseModal={this.isCloseExistingMatchClaim}
        saveClaim={this.saveClaim}
        getTableFields={this.getTableFields}
        seller={this.state.selectedSeller}
        selectedRows={this.state.selectedRows}
      />
    )
  }

  /**
   * close the components 
   */
  isCloseExistingMatchClaim = (response) => {
    this.setState({
      isExistingMatchClaim: false,
      isNewClaimCreation: false,
      isDisableActions: false,
      createTaskAdd: "",
      createTask: ""
    })
    if (response) {
      this.getErrorMessage(response)
    }
    let criteria = this.datatableref.getFiltercriteria()
    this.datatableref.isCloseClaimfromPE()
    this.datatableref.getreturnSelectedRows("remove")
    this.datatableref.clearEditorSectionData()
    this.datatableref.getDataFromServer(criteria)

  }


  /**
   * 
   * @param {Object} seller 
   * @param {Array||Object} selectedRows 
   * @param {String} Claimtype 
   * Get the values from the parent component
   */
  getClaimsTabDetails = (seller, selectedRows, Claimtype) => {
    if (Claimtype === "addToTask") {
      this.setState({
        isExistingMatchClaim: true,
        selectedRows: selectedRows,
        seller: seller
      })
    } else {
      this.setState({
        isNewClaimCreation: true,
        isDisableActions: true,
        selectedRows: selectedRows,
        seller: seller
      })
    }
  }

  openTableModel = async (item, seller) => {
    await this.setState({
      connectedClaimID: item.connectedClaimID,
      selectedItemforTableShow: item,
      seller: seller,
      isOpenTable: true,
    })
  }


  cancelTable = async () => {
    await this.setState({
      isOpenTable: false
    })
    let criteria = await this.datatableref.getFiltercriteria()
    await this.datatableref.getDataFromServer(criteria)
  }

  getTableModal = () => {
    return (
      <TableModal
        onRef={(ref) => this.tableModalRef = ref}
        openTableModal={this.state.isOpenTable}
        cancelReset={this.cancelTable}
        rowData={this.state.selectedItemforTableShow}
        seller={this.state.seller}
        type={"Property Editor"}
        getClaimsTabDetails={this.getClaimsTabDetails}
        openFormModal={this.datatableref.openFormModal}
      />
    )
  }

  //Call for row color change
  custumisedRowHighlights = (item) => {
    let isChildData = this.state.propertyStatusesandReasonsResponse.filter((obj) => obj.availableToReMap === true)
    let ispositivelyValidatedStatusReasons = this.state.propertyStatusesandReasonsResponse.filter((obj) => obj.positiveValidation === true)
    let isParentProp = ispositivelyValidatedStatusReasons.some(rec => rec.status === item.propertyStatus && rec.reason === item.propertyStatusReason);
    let isChildprop = isChildData.some(rec => rec.status === item.propertyStatus && rec.reason === item.propertyStatusReason);
    if (item) {
      if (item && item.duplicateStateProperty === true) {
        return ({
          'p-isHighlightedDuplicateRow': item.duplicateStateProperty === true
        })
      } else if (item.propertyStatus) {
        return (
          {
            'p-highlighteddarkRow': (isParentProp === true),
            'p-highlightRow': (isChildprop === true),
            'p-disabledRow': (((item.propertyStatus === 'FILING-CLM FILED' || item.propertyStatus === "RESULT-APPROVED")) && this.state.activeTab === "SP")//Done changes
          }
        )
      }
    }
  }

  openMultiSortModel = async (e, seller) => {
    e.preventDefault()
    e.stopPropagation()
    await this.setState({
      isOpenMultiSortModal: true,
      isDisableActions: true
    })
  }


  cancelMultiSortModel = async (sortOrder, selectedfieldsforSort) => {
    await this.setState({
      isOpenMultiSortModal: false,
      isDisableActions: false
    })
    if (selectedfieldsforSort && selectedfieldsforSort.length > 0) {
      await this.setState({
        selectedfieldsforSort: selectedfieldsforSort,
        sortOrder: sortOrder
      })
    }
    if (sortOrder !== "close") {
      // this.setState({ multiSortFlag: `&multiSort=${JSON.stringify(sortOrder)}` })
      let criteria = await this.datatableref.getFiltercriteria()
      this.datatableref.clearsortfield()
      this.datatableref.getDataFromServer(criteria)
    }
  }
  removeMultiSortFilter = async () => {
    this.setState({
      sortOrder: this.state.defSortOrder, selectedfieldsforSort: [
        {
          "value": {
            "label": "Reported Owner Name",
            "value": "propertyName",
            "type": "string",
            "disabled": true
          },
          "fieldType": "string",
          "type": "asc"
        },
        {
          "value": {
            "value": "propertyAddressLine1",
            "label": "Property Address Line1",
            "type": "string",
            "disabled": true
          },
          "fieldType": "string",
          "type": "asc"
        }
      ],
    })
    let criteria = await this.datatableref.getFiltercriteria()
    // this.datatableref.getDataFromServer(criteria)
  }

  moveElement = (arr, fromIndex, toIndex) => {
    const element = arr.splice(fromIndex, 1)[0];
    arr.splice(toIndex, 0, element);
    return arr;
  };

  applyMultisort = async (sortOrder, selectedfieldsforSort) => {
    var stringsArray
    await this.setState({ isLoading: true })
    if (typeof sortOrder !== "string" && sortOrder && sortOrder.length > 0) {
      stringsArray = sortOrder.map(obj => obj.key);
      console.log(stringsArray);
      var tableFields = await this.datatableref.tablefieldsforGrid()
      for (let i = 0; i < stringsArray.length; i++) {
        let fromIndex = ""
        fromIndex = tableFields.findIndex(function (item) { return item.field == stringsArray[i]; });
        console.log(tableFields[fromIndex]);
        tableFields = this.moveElement(tableFields, fromIndex, i + 1)
        tableFields = tableFields
      }
      await this.datatableref.tablefieldsforGrid(tableFields)
      // document.getElementsByClassName("p-column-filter-ResultsbusinessName")[0].style.display="block"
      // document.getElementsByClassName("p-column-filter-ResultsbusinessName")[0].children[0].value="eq"
      // document.getElementsByClassName("p-column-filter-ResultsbusinessName")[0].children[1].value="eq"
    }
    let criteria = await this.datatableref.getFiltercriteria()
    this.datatableref.clearsortfield()
    this.datatableref.handlefiltersafterreorder()
    this.datatableref.getDataFromServer(criteria)
    if (selectedfieldsforSort && selectedfieldsforSort.length > 0) {
      this.setState({
        selectedfieldsforSort: selectedfieldsforSort,
        sortOrder: sortOrder,
        isLoading: false
      })
    }

  }



  multiSortingLevelOrder() {
    return (
      <div className='multiSort'>
        <MultiSortModal
          selectedfieldsforSort={this.state.selectedfieldsforSort}
          isOpenMultiSortModal={this.state.isOpenMultiSortModal}
          multiSortFields={this.state.multiSortFields}
          cancelMultiSortModel={this.cancelMultiSortModel}
          applyMultisort={this.applyMultisort}

        /></div>
    )
  }


  render() {
    const { sortOrder, defSortOrder, isDisableActions } = this.state
    let tabData = JSON.parse(localStorage.getItem("ruleGroups"))
    return (
      <div>
        <Loader loader={this.state.isLoading} />
        {this.state.isOpenMatchModel ? this.getOpenPropertyMatchModel() : null}
        {this.state.isExistingMatchClaim ? this.getExistingClaimfromPE() : null}
        {this.state.isNewClaimCreation ? this.getNewClaimfromPE() : null}
        {this.state.isOpenTable ? this.getTableModal() : null}
        {this.state.ValidatedPropertyUpdaterModal ? this.getValidatedPropertyUpdaterModal() : null}
        {this.state.isOpenMultiSortModal ? this.multiSortingLevelOrder() : null}
        {this.state.PropertyTrailViewerModal ? this.getPropertyTrailViewerModal() : null}


        <span style={isDisableActions ? { pointerEvents: "none" } : {}}>
          <DataTables
            onRef={(ref) => (this.datatableref = ref)}
            getTableFields={this.getTableFields}
            formFields={this.getFormFields}
            dontShowTitle={true}
            exportRequried={false}
            excelDownload={false}
            printRequried={false}
            additionalFlag={`&multiSort=${JSON.stringify(sortOrder && sortOrder !== "close" ? sortOrder : defSortOrder)}`}
            addRequried={false}
            editRequired={true}
            getClaimsDetails={this.getClaimsTabDetails}
            frozen={true}
            sortField={"BusinessName, PropertyState, NameMatchScore"}
            deleteRequired={false}
            viewRequired={true}
            settingsRequired={false}
            getFileOptionsforMatching={this.getFileforOptions}
            filterRequired={true}
            gridRequried={false}
            sample={false}
            getModaltoCreateProperty={this.getHeaderForCustumBtns}
            propertyFilterSortOptions={this.state.propertyFilterSortOptions}
            globalSearchFieldName='user'
            globalSearch={''}
            type={"Deal Sizing Property Editor"}
            clearValues={this.clearValues}
            isSelectMode={"multiple"}
            tabDescriptionArray={tabData && tabData.length > 0 ? tabData : []}
            dataKey={"_id"}//for data key on selection
            displayright={true}
            redirectToNewProperty={this.state.redirectToNewProperty}
            selectedOpra={this.selectedOpra}
            sortDirection={"desc"}
            customisedExport={true}
            openMultiSortModel={this.openMultiSortModel}
            icon='faCity'
            handleCheckisInlineFilterApplied={this.handleCheckisInlineFilterApplied}
            resize={true}
            getDefaultStatusArray={this.getDefaultArray}
            scrollHeight={"580px"}
            settingsData={this.state.settingsData}
            propertyStatusArray={this.state.propertyStatusArray}
            propertyStatusReasonArray={this.state.propertyStatusReasonArray}
            shownreasons={this.state.shownreasons}
            fontAwesome={true}
            removeMultiSortFilter={this.removeMultiSortFilter}
            getUpdatedResponseforParent={this.getUpdatedResponseforParent}
            routeTo='nsproperties'
            getSellerName={this.getSellerName}
            displayViewOfForm='modal'
            activeTab={"Green"}
            apiResponseKey='ns_scored_propertys'
            style={{ marginTop: 5 }}
            className={true}
            getColorbasedonProp={this.getColorbasedonProp}
            openTableModel={this.openTableModel}
            // isShownnFieldType={true}
            apiUrl={"nsScoredProperties/DealSizingPostListV2"}
            entityType='employee'
            custumisedRowHighlights={this.custumisedRowHighlights}
          />
          <a className="redirectToOpra" href={config.opraPropertyCreationUrl} target="blank"></a>
          <a className="openEnvelop" href={`mailto:${this.state.customerEmail}?subject=${this.state.ProertyShareSubject}&body=Hi%0APlease%20review%20these%20properties.%0A%0A${encodeURIComponent(this.state.PropertySharebody)}%0A%0A%0AThanks%0AOPRA2%0Aopra@oakpointpartners.com`} >
          </a>
        </span>
      </div>
    );
  }
}