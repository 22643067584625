import React from 'react';
import { withTranslation } from 'react-i18next';

// config file
class DefaultTextArea extends React.Component {

    static isEditedShow = false;

    handleChange = (event) => {
        if (this.props.handlerFromParent) {
            this.props.handlerFromParent(event.target.value, event.target.name);
        }
    }
    onChange = (event, type) => {
        if (event && event.target) {
            const { onChange } = this.props.input;
            // if (this.props.type === 'number') {
            //     if (event.target.value) {
            //         let num = event.target.value;
            //         if (name === 'noofDrawers' && num > 10) {
            //             num = 10;
            //         } else if (num <= 0) {
            //             num = 0;
            //         }
            //         onChange(num);
            //     } else {
            //         onChange('');
            //     }
            // } else {
            //     if (this.props.handleComments) {
            //         this.props.handleComments(event.target.value)
            //     }
            //     onChange(event.target.value);
            // }
            if (type === 'nocaptalize') {
                onChange(event.target.value);
            } else if (type === 'firstcaptalize') {
                let value = event.target.value.charAt(0).toUpperCase() + event.target.value.slice(1);
                onChange(value);
            } else if (type === 'captalize') {
                let value = event.target.value.replace(/\w\S*/g, function (txt) { return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase(); });
                onChange(value);
            }
            this.isEditedShow = this.props.isEditedShow && event.target.value ? true : false
        }
    }
    render() {
        const { input, placeholder, t, type, isDisable, meta: { touched, error }, row } = this.props;

        return (
            <div className="form__form-group-input-wrap m-0">
                <textarea
                    className='form-control'
                    rows={row ? row : '5'}
                    style={{ background: this.isEditedShow ? "#f8ac8bab" : 'white', fontSize: 12,height:'unset',minHeight:'unset' }}
                    {...input} disabled={isDisable} placeholder={placeholder}
                    type={type}
                    onChange={
                        (e) => {
                            this.onChange(e, 'firstcaptalize')
                        }}
                />
                {/* {touched && error && <span className="form__form-group-error">{error}</span>} */}
                <div style={{ height: '13px', }}>
                    {touched && error && <span className="form__form-group-error">{t(error)}</span>}
                </div>
            </div>
        );
    }
}
export default withTranslation('common')(DefaultTextArea);
