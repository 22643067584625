import React from 'react';
import { Container } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import MemoryCache from './components/MemoryCache';
const memoryCache = ({ t }) => (
  <Container className="">
    <MemoryCache />
  </Container>
);

memoryCache.propTypes = {
  t: PropTypes.func.isRequired,
};

export default withTranslation('common')(memoryCache);
