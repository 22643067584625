

import React from 'react';
import { Card } from 'reactstrap';
import { load as loadAccount } from '../../../../redux/reducers/commonReducer';
import { settings } from '../../../../redux/actions/settingsAction'
import { Field, reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Select from '../../../../shared/components/form/Select';
import { Redirect } from 'react-router-dom';
import SessionWarningModal from '../../../Cruds/CommonModals/SessionWarningModal';
import Abbrevations from '../../../Cruds/Abbrevations/index';
import BusinessNameIssueCode from '../../../Cruds/BusinessNameIssueCode/index'
import configMessages from '../../../../config/configMessages';
import AvailableToFileStatus from '../../../Cruds/AvailableToFIleStatus/index'
import ApaTypeConfiguration from '../../../Cruds/ApaTypeConfiguration/index';
import DealStageConfiguration from '../../../Cruds/DealStageConfiguration/index'
import DealStatusConfiguration from '../../../Cruds/DealStatusConfiguration'
import DealTypeConfiguration from '../../../Cruds/DealTypeConfiguration'
import RuleConfiguration from '../../../Cruds/RulesConfigurations/index'
import UnreviewedCondition from '../../../Cruds/UnreviewedCondition/index'
// import Filters from '../Cruds/Filters/index'
import Suffix from '../../../Cruds/Suffix/index';
import SubCategory from '../../../Cruds/SubCategory/index';
import Category from '../../../Cruds/Category/index';
import Buckets from '../../../Buckets/index';
import RolePermissions from "../../../Cruds/CommonModals/Permissions"
import NoViewComponent from '../../../Cruds/CommonDataTable/NoView'

// Toaster message
import validate from '../../../Validations/validate';

// Loader
// import Loader from '../App/Loader';
// // Calendar
// //session expiry modal
// import store from '../App/store';
import DealSizingThreshold from '../../../DealSizingThreshold/index';

class DealConfiguration extends React.Component {
  static propTypes = {
    t: PropTypes.func,
    handleSubmit: PropTypes.func.isRequired,
    reset: PropTypes.func.isRequired,
    pristine: PropTypes.bool.isRequired,
    submitting: PropTypes.bool.isRequired,
  };
  constructor(props) {
    super(props);
    this.state = {
      settingsData: '',
      options: [
        { label: 'APA Type Configuration', value: 'APA Type Configuration' },
        { label: 'Deal Stage Configuration', value: 'Deal Stage Configuration' },
        { label: 'Deal Status Configuration', value: 'Deal Status Configuration' },
        { label: 'Deal Type Configuration', value: 'Deal Type Configuration' },
        { label: 'New Name Issue Code', value: 'BusinessNameIssueCode' },

        // { label: 'Filters', value: 'Filters' },

      ],
      screenPermissions:"",
      isLoading: false,
      isUserUpdated: false,

    };
  }

  componentDidMount = async () => {
    // let apiUrl=''
    let screenPermissions = RolePermissions.screenPermissions('Deal Configurations');
    this.setState({
      isLoading: true,
      screenPermissions: screenPermissions
    });
    // })
  }
  //send data to server

  cancelSessionWarningModal = async () => {
    await this.setState({
      sessionWarning: false
    })
  }


  //stay signed in Modal
  getSessionWraningModal() {
    return (
      <SessionWarningModal
        openConfirmationModal={this.state.sessionWarning}
        cancelSessionWarningModal={this.cancelSessionWarningModal}
        getData={this.getKanbanCards}

      />
    )
  }

  handleScreenChange = async (e) => {
    await this.setState({
      selectedScreen: e
    })
  }
  saveDataToServer() { }
  render() {
    const { handleSubmit } = this.props;
    const { options } = this.state
    //
    return (
      this.state.screenPermissions === "Edit" || this.state.screenPermissions === "View" ?

        <div style={{ overflowX: 'hidden', height: '88vh' }} onLoad={this.apple}>
          {/* <Loader loader={isLoading} /> */}

          <form onSubmit={handleSubmit(this.saveDataToServer)}>
            <Card className='settingsCard row px-5'>
              <div className="" >
                <div className='col-sm-3'>
                  <div className="row create-header">
                    <h4 className="col-md-7 offset-3 text-center py-3">Deal Configurations</h4>
                  </div>
                  <div className="form-group pt-0">
                    <div className="form-group pt-2">
                      <label>Choose Screen </label>
                      <div className="form__form-group-field">
                        <Field
                          name={'dateFormat'}
                          component={Select}
                          options={options}
                          onChange={(e) => this.handleScreenChange(e)}
                          placeholder={'Select Screen'}
                        />
                      </div>
                    </div>
                  </div>

                </div>
                <div>
                  {this.state.selectedScreen === "Deal Sizing Threshold" ?
                    <DealSizingThreshold /> : this.state.selectedScreen === "Regular Scoring Bucket" ? <Buckets ScoringType={"RegularScoring"} /> :
                      this.state.selectedScreen === "Deal Sizing Bucket" ? <Buckets ScoringType={"DealSizing"} /> :
                        this.state.selectedScreen === "Legal Entity Suffix" ?
                          <Suffix /> : this.state.selectedScreen === "Legal Entity Abbreviation Screen" ? <Abbrevations /> :
                            this.state.selectedScreen === "Category" ? <Category />
                              // : this.state.selectedScreen === "Filters" ? <Filters />
                              : this.state.selectedScreen === "SubCategory" ? <SubCategory /> :
                                this.state.selectedScreen === "Available To File - Statuses" ? <AvailableToFileStatus /> :
                                  this.state.selectedScreen === "APA Type Configuration" ? <ApaTypeConfiguration /> :
                                    this.state.selectedScreen === "Deal Stage Configuration" ? <DealStageConfiguration /> :
                                      this.state.selectedScreen === "Deal Status Configuration" ? <DealStatusConfiguration /> :
                                        this.state.selectedScreen === "Deal Type Configuration" ? <DealTypeConfiguration /> :
                                          this.state.selectedScreen === "Rule Configuration(SP)" ? <RuleConfiguration /> :
                                            this.state.selectedScreen === "Unreviewed Condition(SP)" ? <UnreviewedCondition /> :
                                              this.state.selectedScreen === "BusinessNameIssueCode" ? <BusinessNameIssueCode /> : null}
                </div>
              </div>

              <div className='pl-5'>
              </div>
            </Card>
          </form>
          {
            this.state.isUserUpdated ?
              <Redirect to='/taskView' /> : null

          }
          {this.state.LoggotSuccess ? <Redirect to="/log_in" /> : null}
          {this.state.sessionWarning ? this.getSessionWraningModal() : null}
        </div > :this.state.screenPermissions===""?null : <NoViewComponent/> 

    );
  }
}

// Decorate with reduxForm(). It will read the initialValues prop provided by connect()
DealConfiguration = reduxForm({
  form: "Common Form", // a unique identifier for this form
  validate,
  enableReinitialize: true,
})(DealConfiguration);

// You have to connect() to any reducers that you wish to connect to yourself
DealConfiguration = connect(
  state => ({
    initialValues: state.commonData.data // pull initial values from account reducer
  }),

  {
    load: loadAccount,// bind account loading action creator
    settingsLoad: settings,
    // settingsData:auth
  }
)(DealConfiguration);

export default DealConfiguration;




