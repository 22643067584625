import React from 'react';
import { Container } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import DealSizingReport from './components/DealSizingReport';


const DealSizing = ({ t }) => (
  <Container >
    <DealSizingReport />
  </Container>
);
DealSizing.propTypes = {
  t: PropTypes.func.isRequired,
};

export default withTranslation('common')(DealSizing);
