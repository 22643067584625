import { useMsal } from "@azure/msal-react";
import React from 'react';
import { loginRequest } from "../../../../config/authConfig";
import LogInButton from '../components/LogInButton';

const LogIn = (props) => {
    console.log("propsssss.closeemodalllll", props)
    const { instance } = useMsal();
    const handleLogin = async () => {
        const response = await instance.loginPopup(loginRequest)
        if (!response || !response.accessToken) {
            console.warning(response)
        }
        return response.accessToken;
    }


    return (
        <div className="account">
            <div className="mt-3 " style={{ margin: "0px", position: "absolute", top: "40%", left: "45%", }}>
                <LogInButton closeModal={props.closeModal} handleSubmit={handleLogin} />
                {/* <LogInForm onSubmit /> */}
            </div>
        </div>
    )
};
export default LogIn;

