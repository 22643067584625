import React from 'react';
import { Container } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import Fields from './components/Fields';

const fields = ({ t }) => (
  <Container >
    <Fields />
  </Container>
);

fields.propTypes = {
  t: PropTypes.func.isRequired,
};

export default withTranslation('common')(fields);
