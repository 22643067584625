import React from 'react';
import Loader from '../../../App/Loader';
import { RadioButton } from 'primereact/radiobutton'
import configMessages from '../../../../config/configMessages';
import configImages from '../../../../config/configImages';
import {
  Button, Card, CardBody, ModalBody, Row, Table
} from 'reactstrap';
import fetch from '../../../../config/service'



// config file
class SplitTask extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      type: 'Suffixes',
      ismoveAlltoleft: false,
      upCount: 0,
      downCount: 0,
      leftCount: 0,
      rightCount: 0,
      isSelectAll: false,
      isSplitBy: "",
      ismoveAll: false,
      additionalselectedStates: [],
      statestab2Data: [],
      selectedValues2: [],
      downtoleft: [],
      getValues1: [],
      states: [
        { label: "Alabama - AL", value: "Alabama - AL", show: false },
        { label: "Alaska - AK", value: "Alaska - AK", show: false },
        { label: "Arizona - AZ", value: "Arizona - AZ", show: false },
        { label: "Arkansas - AR", value: "Arkansas - AR", show: false },
        { label: "California - CA", value: "California - CA", show: false },
        { label: "Colorado - CO", value: "Colorado - CO", show: false },
        { label: "Connecticut - CT", value: "Connecticut - CT", show: false },
        { label: "Delaware - DE", value: "Delaware - DE", show: false },
        { label: "Florida - FL", value: "Florida - FL", show: false },
        { label: "Georgia - GA", value: "Georgia - GA", show: false },
        { label: "Hawaii - HI", value: "Hawaii - HI", show: false },
        { label: "Idaho - ID", value: "Idaho - ID", show: false },
        { label: "Illinois - IL", value: "Illinois - IL", show: false },
        { label: "Indiana - IN", value: "Indiana - IN", show: false },
        { label: "Iowa - IA", value: "Iowa - IA", show: false },
        { label: "Kansas - KS", value: "Kansas - KS", show: false },
        { label: "Kentucky - KY", value: "Kentucky - KY", show: false },
        { label: "Louisiana - LA", value: "Louisiana - LA", show: false },
        { label: "Maine - ME", value: "Maine - ME", show: false },
        { label: "Maryland - MD", value: "Maryland - MD", show: false },
        { label: "Massachusetts - MA", value: "Massachusetts - MA", show: false },
        { label: "Michigan - MI", value: "Michigan - MI", show: false },
        { label: "Minnesota - MN", value: "Minnesota - MN", show: false },
        { label: "Mississippi - MS", value: "Mississippi - MS", show: false },
        { label: "Missouri - MO", value: "Missouri - MO", show: false },
        { label: "Montana - MT", value: "Montana - MT", show: false },
        { label: "Nebraska - NE", value: "Nebraska - NE", show: false },
        { label: "Nevada - NV", value: "Nevada - NV", show: false },
        { label: "New Hampshire - NH", value: "New Hampshire - NH", show: false },
        { label: "New Jersey - NJ", value: "New Jersey - NJ", show: false },
        { label: "New Mexico - NM", value: "New Mexico - NM", show: false },
        { label: "New York - NY", value: "New York - NY", show: false },
        { label: "North Carolina - NC", value: "North Carolina - NC", show: false },
        { label: "North Dakota - ND", value: "North Dakota - ND", show: false },
        { label: "Ohio - OH", value: "Ohio - OH", show: false },
        { label: "Oklahoma - OK", value: "Oklahoma - OK", show: false },
        { label: "Oregon - OR", value: "Oregon - OR", show: false },
        { label: "Pennsylvania - PA", value: "Pennsylvania - PA", show: false },
        { label: "Puerto Rico - PR", value: "Puerto Rico - PR", show: false },
        { label: "Rhode Island - RI", value: "Rhode Island - RI", show: false },
        { label: "South Carolina - SC", value: "South Carolina - SC", show: false },
        { label: "South Dakota - SD", value: "South Dakota - SD", show: false },
        { label: "Tennessee - TN", value: "Tennessee - TN", show: false },
        { label: "Texas - TX", value: "Texas - TX", show: false },
        { label: "Utah - UT", value: "Utah - UT", show: false },
        { label: "Vermont - VT", value: "Vermont - VT", show: false },
        { label: "Virginia - VA", value: "Virginia - VA", show: false },
        { label: "Washington - DC", value: "Washington - DC", show: false },
        { label: "Washington - WA", value: "Washington - WA", show: false },
        { label: "West Virginia - WV", value: "West Virginia - WV", show: false },
        { label: "Wisconsin - WI", value: "Wisconsin - WI", show: false },
        { label: "Wyoming - WY", value: "Wyoming - WY", show: false },
      ],
      getValues: [],
      ismoveAlltoleft: false,
      upCount: 0,
      downCount: 0,
      leftCount: 0,
      rightCount: 0,
      actions: [],
      isSelectAll: false,
      isSplitData: [],
      lengthNote: 0,
      taskNote: "",
      last2Notes: [],
      showFields: [],
      otherStates: [],
      issplitAll: false,
      tableFields: '',
      selectedValues: [],
      totalFields: [],
      exportData: [],
      apierrorResponse: false,
      disabledField: false,
      assignedValue: false,
      issplitbyStatus: false,
      isSelecteddefinedStates: false,
      // rowData: this.props.rowData,
    };
  }

  componentDidMount = () => {
    this.getSubTasksDataResponse()
    if (this.props.onRef) {
      this.props.onRef(this);
    }
    //Screen permisions value can be edit,view, no view
    // let screenPermissions = RolePermissions.screenPermissions('Employees');
  }

  componentWillUnmount() {
    if (this.props.onRef) {
      this.props.onRef(null);
    }
  }

  componentWillMount() {
    if (this.props.onRef) {
      this.props.onRef(null);
    }

  }


  //POST Call for the SPlit task
  submitSplitTask = async () => {
    let userBody = {
      splitBy: this.state.isSplitBy === "States" ? "state" : "name",
      isSplitAll: this.state.issplitAll
    }
    let unSelectedStates = [];
    let selectedStates = [];
    let unselect_list = [];
    let select_list = [];
    let data = this.state.isSplittedData
    if (this.state.isSplittedData && this.state.isSplittedData.length > 0) {
      unSelectedStates = data.filter(function (el) {
        return el.show === false
      });
      selectedStates = data.filter(function (el) {
        return el.show === true
      });
      for (var obj of unSelectedStates) {
        unselect_list.push(obj.label)
      }
      for (var obj2 of selectedStates) {
        select_list.push(obj2.label)
      }
      if (this.state.isSplitBy === "States") {
        userBody.unSelectedStates = unselect_list;
        userBody.selectedStates = select_list;
      } else {
        userBody.unSelectedNames = unselect_list;
        userBody.selectedNames = select_list;
      }
    }
    await this.setState({ splitBody: userBody })
    if (this.state.issplitAll) {
      await this.setState({ splitbyeachCard: true, apierrorResponse: false, spliterrorMessage: `Are you sure want to split one task per ${this.state.isSplitBy === "States" ? "State" : "Name"}?`, hideSplitBtn: false })
      this.props.isHideSplitBtn(false)
    }
    else if (userBody && select_list.length > 0 && unselect_list.length === 0) {
      await this.setState({ splitbyeachCard: true, apierrorResponse: false, spliterrorMessage: `Are you sure want to create one task with all Selected ${this.state.isSplitBy === "States" ? "States" : "Names"} ?`, hideSplitBtn: false })
      this.props.isHideSplitBtn(false)
    }
    else if (userBody && unselect_list.length > 0 && select_list.length === 0) {
      await this.setState({ splitbyeachCard: true, apierrorResponse: false, spliterrorMessage: `Are you sure want to create one task with all Unselected ${this.state.isSplitBy === "States" ? "States" : "Names"} ?`, hideSplitBtn: false })
      this.props.isHideSplitBtn(false)
    }
    else if (userBody && (select_list.length > 0 && unselect_list.length > 0)) {
      await this.setState({ splitbyeachCard: true, apierrorResponse: false, spliterrorMessage: `Are you sure want to create one task with Selected ${this.state.isSplitBy === "States" ? "States" : "Names"} and one with Unselected ${this.state.isSplitBy === "States" ? "States" : "Names"}?`, hideSplitBtn: false })
      this.props.isHideSplitBtn(false)
    }
    else {
      this.props.isHideSplitBtn(false)
      this.isSplitSucces()
    }
  }

  //Get SUbtasks for the Parent card 

  getSubTasksDataResponse = async () => {
    fetch('GET', `tasks/getsubtasks/${this.props.rowData._id}`)
      .then(async (response) => {
        if (response && response.tasks) {
          this.setState({
            isLoading: false,
            subTasksArray: response.tasks
          })
        } else if (response && response['errorMessage'] === configMessages.warningMessage) {
          await this.setState({
            sessionWarning: true,
            subTasksArray: []
          })
        } else if (response && (response['errorMessage'] === configMessages.sessionExpired || response['errorMessage'] === configMessages.tokenMessage)) {
          await this.setState({
            sessionExpiryModal: true,
            subTasksArray: []
          })
        } else {
          this.setState({
            isLoading: false,
            subTasksArray: []
          })
        }
      })
      .catch((err) => {
        return err;
      });
  }

  flattenArray = (arrayVal) => {
    let val = '';
    arrayVal = arrayVal.slice(0, 3);
    if (arrayVal) {
      val = JSON.stringify(arrayVal);
      val = val.replace(/"/g, '')
        .replace(/\[/g, '')
        .replace(/]/g, '');
    }
    val = val + "..."
    return val;
  }

  //Get Selected and Total states/names 
  handleTab2State = async (e) => {
    let selectedStates = []
    if (e === "States") {
      if (this.props.rowData.states && this.props.rowData.states.length > 0) {
        this.props.rowData.states.forEach(item => {
          selectedStates.push({
            label: item,
            value: item,
            // show: true
          })
        })
      }
      let datastates = this.state.states
      selectedStates.forEach(item => {
        for (var i = 0; i < datastates.length; i++) {
          if (datastates[i].label === item.label) {
            datastates.splice(i, 1);
            break;
          }
          this.setState({
            states: datastates
          })
        }
      })
      await this.setState({
        statestab2Data: datastates,
        hideSplitBtn: false,
        isSplittedData: [],
      })
      this.props.isHideSplitBtn(false)

    } else {
      this.setState({
        isLoading: true,
        selectedStates: [],
      })
      selectedStates.push({
        label: this.props.rowData.company,
        value: this.props.rowData.company,
        show: false
      })
      // this.handlePercentage('1')
      let str = this.props.rowData.opraEstateID
      let data = encodeURIComponent(str)
      fetch('GET', `businessNames/estatesforsplit?str=${data}`)
        .then(async (response) => {
          if (response && response.estateNames) {
            let bussinessNames = response.estateNames;
            let modifiedNames = [];
            for (let app of bussinessNames) {
              if (app.BusinessName_Text !== this.props.rowData.company) {
                modifiedNames.push({
                  label: app.BusinessName_Text,
                  value: app.BusinessName_Text,
                  show: false
                })
              }
            }
            await this.setState({
              statestab2Data: modifiedNames,
              isLoading: false,
              hideSplitBtn: false,
              isSplittedData: [],
              getValues10: []
            })
            this.props.isHideSplitBtn(false)

          } else if (response && response['errorMessage'] === configMessages.warningMessage) {
            await this.setState({
              sessionWarning: true,

            })
          }
        }).catch((err) => {
          return err;
        });
    }
    let states2 = this.state.states;
    await this.setState({
      isSplitBy: e,
      selectedStates, selectedStates,
      states: states2,
      isSplittedData: [],
      isSelectAll: false,
      issplitAll: false,
      ismoveAll: false,
      ismoveAlltoleft: false,
    })
  }

  //Change the selected names/states to left
  onChangeMoveAlltoleft = () => {
    if (this.state.ismoveAlltoleft) {
      this.setState({ ismoveAlltoleft: false });
    } else {
      this.setState({ ismoveAlltoleft: true, apierrorMessage: "", apierrorResponse: false });
    }
    if (!this.state.ismoveAlltoleft) {
      let select_list = this.state.selectedStates
      for (var obj of select_list) {
        obj.show = true
      }
      this.setState({ selectedStates: select_list })
    } else {
      let select_list = this.state.selectedStates
      for (var obj2 of select_list) {
        obj2.show = false
      }
      this.setState({ selectedStates: select_list })
    }
    // if (this.state.isSelecteddefinedStates) {
    //     this.moveRighttoLeft()
    // }
  }

  removedStates = async () => {
    this.setState({
      downCount: this.state.downCount + 1
    })
    let selectt4 = this.state.selectedStates;
    let selectt5 = JSON.parse(JSON.stringify(this.state.selectedStates));
    let split_arr2 = this.state.statestab2Data;
    var newArray = selectt4.filter(function (el) {
      return el.show === true
    });
    if (newArray && newArray.length > 0) {
      for (var obj2 of selectt4) {
        var obj3 = Object.assign({}, obj2)
        if (obj2.show === true) {
          obj3.show = false
          split_arr2.push(obj3)
          selectt5.splice(selectt5.findIndex(a1 => a1.label === obj2.label), 1)
        }
      }
      this.setState({ selectedStates: selectt5, statestab2Data: split_arr2 })
    } else {
      this.setState({ apierrorResponse: true, apierrorMessage: `Please select ${this.state.isSplitBy === "States" ? 'states' : 'names'} under Selected ${this.state.isSplitBy} before clicking` })
    }
    if (this.state.ismoveAlltoleft) {
      await this.setState({ ismoveAlltoleft: false });
    }
    // }

  }

  newSelectedStates = async () => {
    this.setState({
      upCount: this.state.upCount + 1
    })

    let selectt5 = this.state.statestab2Data;
    let selectt6 = JSON.parse(JSON.stringify(this.state.statestab2Data));
    let split_arr3 = this.state.selectedStates;
    var newArray = selectt5.filter(function (el) {
      return el.show === true
    });
    if (newArray && newArray.length > 0) {
      for (var obj2 of selectt5) {
        var obj3 = Object.assign({}, obj2)
        if (obj2.show === true) {
          obj3.show = false
          split_arr3.push(obj3)
          selectt6.splice(selectt6.findIndex(a1 => a1.label === obj2.label), 1)
        }
      }
      this.setState({ selectedStates: split_arr3, statestab2Data: selectt6 })
    } else {
      this.setState({ apierrorResponse: true, apierrorMessage: `Please select ${this.state.isSplitBy === "States" ? 'states' : 'names'} under All Unselected ${this.state.isSplitBy} before clicking` })
    }
    if (this.state.ismoveAll) {
      await this.setState({ ismoveAll: false });
    }
    // if(this.state.onChangeMoveAlltoleft){
    // if (this.state.isSelecteddefinedStates) {
    //     this.onChangeMoveAlltoleft("ismoveAlltoleft")
    // }
    // }
  }

  onChangeMoveAll = async () => {
    // this.setState(prevState => ({ ismoveAll: !prevState.ismoveAll }));
    if (this.state.ismoveAll) {
      this.setState({ ismoveAll: false });
    } else {
      this.setState({ ismoveAll: true, apierrorMessage: "", apierrorResponse: false });
    }
    if (!this.state.ismoveAll) {
      let select_list = this.state.statestab2Data
      for (var obj of select_list) {
        obj.show = true
      }
      this.setState({ statestab2Data: select_list })
    } else {
      let select_list = this.state.statestab2Data
      for (var obj2 of select_list) {
        obj2.show = false
      }
      this.setState({ statestab2Data: select_list })
    }
  }

  getValuesfromAdditionalStates = async (cardtype, value) => {
    let selectTab2_States = this.state.statestab2Data
    if (value && value.label && selectTab2_States && selectTab2_States.length > 0) {
      selectTab2_States.map(col => {
        if (col.label === value.label) {
          col.show = !col.show
        }
        return col
      })
    }
    this.setState({
      statestab2Data: selectTab2_States,
      apierrorResponse: false,
      apierrorMessage: ""
    })
  }

  getValuesfromExistingStates = async (cardtype, value) => {
    let select_States = this.state.selectedStates
    if (value && value.label && select_States && select_States.length > 0) {
      select_States.map(col => {
        if (col.label === value.label) {
          col.show = !col.show
        }
        return col
      })
    }
    this.setState({
      selectedStates: select_States,
      apierrorResponse: false,
      apierrorMessage: ""
    })
  }

  getNewExistingValues = async (cardtype, value, data, dataType) => {
    let isSplitt_States = this.state.isSplittedData
    if (value && value.label && isSplitt_States && isSplitt_States.length > 0) {
      isSplitt_States.map(col => {
        if (col.label === value.label) {
          col.show = !col.show
        }
        return col
      })
    }
    this.setState({
      isSplittedData: isSplitt_States,
      apierrorResponse: false,
      apierrorMessage: ""
    })

  }

  getSplitedStates = async () => {
    this.setState({
      leftCount: this.state.leftCount + 1
    })

    let selectt = this.state.selectedStates;
    let split = this.state.statestab2Data;

    let selectt1 = JSON.parse(JSON.stringify(this.state.selectedStates));
    let split_arr = this.state.isSplittedData;

    if (this.state.isSelecteddefinedStates) {
      for (var obj of split) {
        var obj1 = Object.assign({}, obj)
        if (obj.show === true) {
          obj1.show = false
          split_arr.push(obj1)
          split.splice(split.findIndex(a => a.label === obj.label), 1)
        }
      }
    }
    var newArray = selectt.filter(function (el) {
      return el.show === true
    });
    if (newArray && newArray.length > 0) {
      for (var obj of selectt) {
        var obj1 = Object.assign({}, obj)
        if (obj.show === true) {
          obj1.show = false
          split_arr.push(obj1)
          selectt1.splice(selectt1.findIndex(a => a.label === obj.label), 1)
        }
      }
      this.props.isHideSplitBtn(true)

      this.setState({ selectedStates: selectt1, isSplittedData: split_arr, hideSplitBtn: true })
    } else {
      this.setState({ apierrorResponse: true, apierrorMessage: `Please select ${this.state.isSplitBy === "States" ? 'states' : 'names'} under Selected ${this.state.isSplitBy} before clicking` })
    }
    if (this.state.ismoveAlltoleft) {
      await this.setState({ ismoveAlltoleft: false });
    }
  }

  onChangesplitAll = async (e) => {
    this.setState(prevState => ({ issplitAll: !prevState.issplitAll }))
  }

  onChangeMoveAll = async () => {
    // this.setState(prevState => ({ ismoveAll: !prevState.ismoveAll }));
    if (this.state.ismoveAll) {
      this.setState({ ismoveAll: false });
    } else {
      this.setState({ ismoveAll: true, apierrorMessage: "", apierrorResponse: false });
    }
    if (!this.state.ismoveAll) {
      let select_list = this.state.statestab2Data
      for (var obj of select_list) {
        obj.show = true
      }
      this.setState({ statestab2Data: select_list })
    } else {
      let select_list = this.state.statestab2Data
      for (var obj2 of select_list) {
        obj2.show = false
      }
      this.setState({ statestab2Data: select_list })
    }
  }
  moveRighttoLeft = async () => {
    this.setState({
      upCount: this.state.upCount + 1
    })
    let selectt2 = this.state.isSplittedData;
    let selectt3 = JSON.parse(JSON.stringify(this.state.isSplittedData));
    let split_arr1 = this.state.selectedStates;
    var newArray = selectt2.filter(function (el) {
      return el.show === true
    });
    if (newArray && newArray.length > 0) {
      for (var obj2 of selectt2) {
        var obj3 = Object.assign({}, obj2)
        if (obj2.show === true) {
          obj3.show = false
          split_arr1.push(obj3)
          selectt3.splice(selectt3.findIndex(a1 => a1.label === obj2.label), 1)
        }
      }
      this.setState({ selectedStates: split_arr1, isSplittedData: selectt3, hideSplitBtn: selectt3.length > 0 ? true : false })
      this.props.isHideSplitBtn(selectt3.length > 0 ? true : false)

    } else {
      this.setState({ apierrorResponse: true, apierrorMessage: `Please select ${this.state.isSplitBy === "States" ? 'states' : 'names'} under  ${this.state.isSplitBy} For Splitting before clicking` })
    }
    if (this.state.isSelectAll) {
      await this.setState({ isSelectAll: false });
    }
    if (this.state.isSelecteddefinedStates) {
      this.onChangeDefinedStates()
    }


  }


  onChangeSelectAll() {
    if (this.state.isSelectAll) {
      this.setState({ isSelectAll: false });
    } else {
      this.setState({ isSelectAll: true, apierrorMessage: "", apierrorResponse: false });
    }
    if (!this.state.isSelectAll) {
      let select_list = this.state.isSplittedData
      for (var obj of select_list) {
        obj.show = true
      }
      this.setState({ isSplittedData: select_list })
    } else {
      let select_list = this.state.isSplittedData
      for (var obj2 of select_list) {
        obj2.show = false
      }
      this.setState({ isSplittedData: select_list })
    }
  }

  onChangeDefinedStates = async (type) => {
    if (type) {
      await this.setState({
        isSelecteddefinedStates: this.state.isSelecteddefinedStates ? false : true
      })
    }// statestab2Data

    let states = this.state.statestab2Data
    let select_list = this.state.selectedStates
    var select_desiredStates = ["Alabama - AL",
      "Arizona - AZ", "California - CA", "Colorado - CO", "Washington - DC", "Delaware - DE", "Iowa - IA", "Idaho - ID", "Illinois - IL", "Kansas - KS", "Kentucky - KY",
      "Maine - ME",
      "Michigan - MI",
      "Mississippi - MS",
      "Montana - MT",
      "New Hampshire - NH",
      "Nevada - NV",
      "Puerto Rico - PR",
      "South Dakota - SD",
      "Utah - UT",
      "Virginia - VA",
      "Vermont - VT",
      "Washington - WA",
      "Wyoming - WY",
    ]
    if (this.state.isSelecteddefinedStates) {
      for (var obj of select_desiredStates) {
        for (var item of states) {
          if (item.label === obj) {
            item.show = true
          }
        }
        for (var item of select_list) {
          if (item.label === obj) {
            item.show = true
          }
        }
        await this.setState({ selectedStates: select_list, statestab2Data: states })
      }

    } else {
      for (var obj of select_desiredStates) {
        for (var item of states) {
          if (item.label === obj) {
            item.show = false
          }
        }
        for (var item of select_list) {
          if (item.label === obj) {
            item.show = false
          }
        }
        await this.setState({ selectedStates: select_list, statestab2Data: states })
      }
    }

    // let selectt5 = this.state.statestab2Data;
    // let selectt6 = JSON.parse(JSON.stringify(this.state.statestab2Data));
    // let split_arr3 = this.state.selectedStates;
  }


  /*Spliiting Api */
  isSplitSucces = async () => {
    await this.setState({ isLoading: true, splitbyeachCard: false })
    // this.handlePercentage('1')
    return fetch('POST', `tasks/split/${this.props.rowData._id}`, this.state.splitBody)
      .then(async (response) => {
        if (response && response.respMessage) {
          // this.cancelReset()
          await this.setState({ splitbyeachCard: false })
          this.props.getErrorMessage(response.respMessage)
        } else if (response && response.errorMessage) {
          await this.setState({ splitbyeachCard: false })
          this.props.getErrorMessage(response.errorMessage)
        } else if (response && response['errorMessage'] === configMessages.warningMessage) {
          await this.setState({
            sessionWarning: true,
          })
        }
        await this.setState({ isLoading: false })
      }).catch((err) => {
        return err;
      });
  }



  isSelectNo = () => {
    if (this.props.type === "add" || this.props.type === "edit") {
      this.setState({
        issplitbyStatus: false,
        // hideSplitBtn: true
      })
    }
    this.setState({
      splitbyeachCard: false,
      hideSplitBtn: true
    })
    this.props.isHideSplitBtn(true)

  }


  getTab2Body() {
    const { rowData } = this.props
    return (
      rowData && rowData.subTaskCount > 0 ?
        <div className='col-12 px-0 splitTasks m-3' style={{ height: 400, width: "96%", overflow: "auto" }}>
          <Loader loader={this.state.isLoading} progress={this.state.progress} className="screen-loader" />
          <Table bordered={true} className={this.state.isLoading ? "mt-2" : ""} >
            <thead>
              <tr>
                <th>Split CardId</th>
                <th>Label</th>
                <th>Purpose</th>
                <th>Priority</th>
                <th>Assign To</th>
                <th>Status</th>
                <th>States/Names</th>
              </tr>
            </thead>
            <tbody style={{ maxHeight: 80 }}>
              {this.state.subTasksArray && this.state.subTasksArray.length > 0 ?
                this.state.subTasksArray.map((item, i) => {
                  let val = this.flattenArray(item.subsidiaryEstates ? item.subsidiaryEstates : item.states);
                  return <tr key={i}>
                    <td onClick={() => this.gettaskData(item._id)} style={{ color: "#354f6e", cursor: "pointer" }} data-toggle="tool-tip"
                      title={"click to see the details of child card"}>{item.taskID}</td>
                    <td> {item.title}</td>
                    <td> {item.purpose}</td>
                    <td> {item.priority}</td>
                    <td> {item.assignTo}</td>
                    <td >{item.status}</td>
                    <td >{val}</td>
                  </tr>
                }) : ""}
            </tbody>
          </Table>
        </div> :
        <div>
          <ModalBody className='modal__body edit_modal' style={{
            padding: 10,
            // overflowX: "hidden",
            // overflowY: "clip",
            height: 580
          }}>
            <Card className='pb-0 ml-4'>
              <CardBody className='row p-0 d-block'>
                {/* <Loader loader={this.state.isLoading} progress={this.state.progress} className="screen-loader" /> */}
                <div className={this.state.isLoading ? " row m-0 mt-3" : "row m-0"} >
                  <div className='col-sm-5 px-2 pb-2 d-flex'>
                    <RadioButton
                      id='States'
                      name='states2'
                      label="States"
                      value="States"
                      radioValue="States"
                      onChange={() => this.handleTab2State('States')}
                    // disabled={this.state.isSplitBy ? true : false}
                    />
                    <label>States</label>
                    <RadioButton
                      id='Names'
                      name='states2'
                      label="Names"
                      value="Names"
                      radioValue="Names"
                      onChange={() => this.handleTab2State('Names')}
                    // disabled={this.state.isSplitBy ? true : false}
                    />
                    <label>Names</label>
                  </div>
                  <div className="col-sm-2"></div>
                  <div className='col-sm-5 px-0 pb-2' >

                  </div>
                </div>
                <div style={this.state.isSplitBy ? { display: "block" } : { display: "none" }}>
                  <div className="col-sm-12 row" style={{ width: "100%" }}>
                    <div className="col-sm-5 pl-0 pr-0">
                      <Row className=" ml-0 mr-0" >
                        <label><b>{this.state.isSplitBy === "States" ? "Selected States" : "Selected Names"}</b></label>
                        <span className='d-flex  split ml-auto' >
                          <label className='d-flex float-right' ><input type="checkbox"
                            checked={this.state.ismoveAlltoleft}
                            onChange={(e) => this.onChangeMoveAlltoleft("ismoveAlltoleft")}
                          /> Select All</label>

                        </span>
                        {this.state.selectedStates && this.state.selectedStates.length > 40 ?
                          <span className='d-flex float-right split ml-auto' >
                            <label className='d-flex float-right' ><input type="checkbox"
                              checked={this.state.isSelecteddefinedStates}
                              onChange={(e) => this.onChangeDefinedStates("isSelecteddefinedStates")}
                            /> Select Defined States</label>

                          </span> : null}
                        <Card className="pb-0" style={{ height: '170px', overflow: 'auto', border: "1px solid grey" }}>
                          {this.state.selectedStates && this.state.selectedStates.length > 0 ? this.state.selectedStates.map((item, index) => {
                            return <div className='d-flex m-1'>
                              <input key={`up-${index}`}
                                style={{ marginTop: 3, height: 16 }}
                                className='col-1 mr-0 pl-0'
                                onChange={(e) => this.getValuesfromExistingStates("existing", item, item.label, this.state.isSplittedStates)}
                                name={`up-${this.state.upCount}-${index}`}
                                id={`up-${this.state.upCount}-${index}`}
                                label={item.label}
                                type="checkbox"
                                //value={item.show}
                                checked={item.show}
                                defaultChecked={item.show}
                              /><label className='mb-0'>{item.label}</label>
                            </div>
                          }) : null}
                        </Card>
                      </Row>
                      <span className="d-flex mt-3">
                        <span style={{ marginLeft: "42%" }}><img
                          src={configImages.downIcon}
                          alt=""
                          className="mt-2 mb-2"
                          style={{ width: "20px", height: "18px" }}
                          onClick={() => this.removedStates()}
                          data-toggle="tool-tip"
                          title={`click to move selected ${this.state.isSplitBy === "States" ? "States" : "Names"} to down`} /></span>
                        <span style={{ marginLeft: "5px" }}><img
                          src={configImages.upIcon}
                          alt=""
                          className="mt-2 mb-2"
                          style={{ width: "15px", height: "15px" }}
                          data-toggle="tool-tip"
                          title={`click to move selected ${this.state.isSplitBy === "States" ? "States" : "Names"} to top`}
                          onClick={() => this.newSelectedStates()} /></span>
                      </span>
                      <Row className="ml-0 mr-0" >
                        <label><b>{this.state.isSplitBy === "States" ? "All Unselected States" : "All UnSelected Names"}</b></label>
                        <span className='d-flex float-right split ml-auto' >
                          <label className='d-flex float-right' ><input type="checkbox"
                            checked={this.state.ismoveAll}
                            onChange={(e) => this.onChangeMoveAll("ismoveAll")}
                          />Select All</label>
                        </span>
                        <Card className="pb-0" style={{ border: "1px solid grey", height: '170px', overflow: 'auto' }}>
                          {this.state.statestab2Data && this.state.statestab2Data.length > 0 ? this.state.statestab2Data.map((item1, index1) => {
                            return <div className='d-flex m-1'>
                              <input key={`down-${index1}`}
                                style={{ marginTop: 3, height: 16 }}
                                className='col-1 mr-0 pl-0'
                                // name={`down-${this.state.downCount}-${index1}`}
                                id={`down-${this.state.downCount}-${index1}`}
                                type="checkbox"
                                label={item1.label}
                                onChange={(e) => this.getValuesfromAdditionalStates("additional", item1, item1.label)}
                                checked={item1.show}
                                // value={item1.show}
                                defaultChecked={item1.show}
                              /><label className='mb-0'>{item1.label}</label>
                            </div>
                          }) : null}
                        </Card>
                      </Row>
                    </div>
                    <div className="col-sm-2 mt-5">
                      <Row className="">
                        <span style={{ width: "100%", marginLeft: "43%" }}>
                          <p className="ml-auto mr-auto"><img
                            src={configImages.leftIcon}
                            alt=""
                            className="mt-2 mb-2"
                            style={{ width: "15px", height: "15px" }}
                            data-toggle="tool-tip"
                            title={`click to move selected ${this.state.isSplitBy === "States" ? "States" : "Names"} to right`}
                            onClick={() => this.getSplitedStates()} /></p>
                          <p className="ml-auto mr-auto mt-0"><img
                            src={configImages.rightIcon}
                            alt=""
                            className="mb-2"
                            style={{ width: "15px", height: "15px" }}
                            data-toggle="tool-tip"
                            title={`click to move selected ${this.state.isSplitBy === "States" ? "States" : "Names"} to left`}
                            onClick={() => this.moveRighttoLeft()} /></p>
                        </span>
                      </Row>
                    </div>
                    <div className="col-sm-5 pl-0 pr-0">
                      <div className="d-flex">
                        <label className="split_label mb-0"><b>{this.state.isSplitBy === "States" ? "States For Splitting" : "Names For Splitting"}</b></label>
                        <span className='d-flex float-right split ml-auto' >
                          <label className='d-flex float-right' ><input type="checkbox"
                            checked={this.state.isSelectAll}
                            onChange={() => this.onChangeSelectAll('remember')}
                          /> Select All</label>

                        </span>
                        <span className='d-flex float-right split ml-auto' >
                          <label className='d-flex float-right' ><input type="checkbox"
                            checked={this.state.issplitAll}
                            onChange={() => this.onChangesplitAll('remember')}
                          /> Split All</label>

                        </span>
                      </div>
                      <Card style={{ minHeight: "160px", height: '427px', overflow: 'auto', border: "1px solid grey" }}>
                        {this.state.isSplittedData && this.state.isSplittedData.length > 0 ? this.state.isSplittedData.map((item2, index2) => {
                          return <div className='d-flex m-1'>
                            <input key={`left-${index2}`}
                              className='col-1 mr-0 pl-0'
                              style={{ marginTop: 3, height: 16 }}
                              // name={`left-${this.state.leftCount}-${index2}`}
                              id={`left-${this.state.leftCount}-${index2}`}
                              type="checkbox"
                              label={item2.label}
                              //value={item2.show}
                              checked={item2.show}
                              defaultChecked={item2.show}
                              onChange={(e) => this.getNewExistingValues("newExisting", item2, this.state.isSplittedData)}

                            /><label className='mb-0'>{item2.label}</label>

                          </div>

                        }) : null}
                      </Card>
                    </div>
                  </div>
                </div>
              </CardBody>
            </Card>
            <span className="formError mt-2 mb-2" style={{ textAlign: "center", bottom: -8 }}>{this.state.apierrorResponse ? <span style={{ height: 30 }}>{this.state.apierrorMessage}</span> : null}</span>
            <span className="formError mt-2 mb-2" style={{ textAlign: "center", bottom: -8 }}>{this.state.splitbyeachCard ?
              <span>
                <span>{this.state.spliterrorMessage}</span>
                <p> <Button
                  color='primary'
                  outline
                  type="button"
                  className=" ml-1 mb-0 mt-0" onClick={() => this.isSplitSucces()}>Yes
                </Button><Button
                  color='primary'
                  outline
                  type="button"
                  className=" ml-1 mb-0 mt-0" onClick={() => this.isSelectNo()}>No
                  </Button></p>
              </span> : null}</span>

          </ModalBody>


        </div >

    )

  }

  render() {
    return (
      <div>{this.getTab2Body()}</div>
      // <>{this.getTab2Body()}</>
    )
  }
}

export default SplitTask;
