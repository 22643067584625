import React from 'react';
import { Button, Modal, ModalBody, Row, ModalHeader } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import ModalFooter from 'reactstrap/lib/ModalFooter';
import { ColumnGroup } from 'primereact/columngroup';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import * as moment from 'moment-timezone';

const bodyStyle = {
  textOverflow: 'ellipsis',
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  width: 150,
  fontSize: 12,
  textAlign: "left",
  verticalAlign: "inherit"
}

const headerStyle = {
  width: 150,
  textAlign: "center",
}

// config file
class PaymentsModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      PaymentsArray: {},
      recordData: {}
    };
  }

  componentDidMount = async (type) => {
    if (this.props.onRef) {
      this.props.onRef(this);
    }
    let PaymentsArray = this.state.PaymentsArray
    PaymentsArray = await this.updateSlNoToData(this.props.PaymentsArray["Payments"])
    this.setState({
      PaymentsArray: PaymentsArray,
      recordData: this.props.PaymentsArray,
    })
  }

  componentWillUnmount() {
    if (this.props.onRef) {
      this.props.onRef(null);
    }
  }

  updateSlNoToData(itemdata) {
    let modifiedData = [];
    if (itemdata && itemdata.length > 0) {
      itemdata.forEach((item, index) => {
        item.Sno = index + 1;
        modifiedData.push(item);
      });
    }
    return modifiedData;
  }

  openEditModal = (item, rowData) => {
    this.setState({
      EditedIndex: rowData.Sno - 1
    })
    this.props.openFormModal(item, 'edit', "Payments", rowData, rowData.Sno - 1)
  }

  getUpdatedData = async (data) => {
    let PaymentsArray = await this.updateSlNoToData(data["Payments"])
    await this.setState({
      PaymentsArray: PaymentsArray,
      recordData: data
    })
  }

  handleUsersData = (updatedId) => {
    this.props.handleUsersData(updatedId, "paymentDetailedView")
    this.props.cancelReset()
  }

  actionTemplate = (rowData, column) => {
    return <div className='my-1'>
      <Button className='mb-0' color="primary" outline onClick={() => this.openEditModal(this.state.recordData, rowData)}>Edit</Button>

    </div>;
  }
  dateTemplate = (rowData, column) => {
    return <span className='d-flex justify-content-end'>
      {rowData["checkDate"] ? moment(rowData["checkDate"]).format("MM-DD-YYYY") : "N/A"}
    </span >
  }

  hoverTemplate = (rowData, column) => {
    return <span className='' data-toggle="tool-tip"
      title={rowData[column.field]} style={{ textOverflow: "ellipsis" }}>
      {rowData[column.field] ? rowData[column.field] : ""}
    </span >
  }

  cancelReset = () => {
    this.props.cancelReset("Payments", this.state.recordData["_id"].OppClaimID)
  }


  getreducedNumber() {
    let dollarUS = Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
    });
    let val
    let initialValue = 0
    let sum = this.state.recordData["Payments"].reduce(function (accumulator, curValue) {
      return accumulator + curValue.checkAmount
    }, initialValue)
    val = dollarUS.format(sum)

    return val;
  }

  getSiteDiscoveryFooter() {
    return <ColumnGroup>
      <Row>
        <Column footer="Total Payment Amount:" colSpan={2} />
        <Column colSpan={4} footer={this.state.recordData && this.state.recordData["Payments"] ? this.getreducedNumber() : null} />
      </Row>
    </ColumnGroup>;
  }
  formatCurrency(rowData, column) {
    let dollarUS = Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
    });
    let val
    val = dollarUS.format(rowData["checkAmount"])
    // let val = item[column.field]
    return <span className='d-flex justify-content-end'>
      {rowData["checkAmount"] !== undefined && rowData["checkAmount"] !== null && rowData["checkAmount"] !== "" && val !== "$NaN" ? val : "N/A"}
    </span >
  }
  render() {
    const footerGroup = this.getSiteDiscoveryFooter()
    return (
      < div >

        <Modal isOpen={this.props.openpaymentsModal} fade={false}
          className='modal-dialog-centered modal-dialog--primary m-auto'
          style={{ maxWidth: 1050 }}

        >
          <ModalHeader className={"modal__header kanbanScreen d-flex "} style={{ justifyContent: "center" }}>
            <p className="bold-text  modal__title ml-2" > {"Payment Details"}</p>
          </ModalHeader>
          <ModalBody className='deleteModalBody' style={{
            padding: 10,
            // width: 110,
            maxHeight: 800,
            height: "auto",
            overflow: "auto",
            textAlign: "left"

          }}>
            <>
              <div className='d-flex col-sm-12 mt-1 pl-0 pr-0'>
                <div className='d-flex col-sm-6 '>
                  <span className='col-sm-5 pl-0'><b>Opp ClaimID</b></span>
                  <span className='col-sm-7'>{this.state.recordData ? this.state.recordData["OppClaimID"] : ""}</span>
                </div>
                <div className='d-flex col-sm-6'>
                  <span className='col-sm-5 pl-0'><b>St ClaimID</b></span>
                  <span className='col-sm-7'>{this.state.recordData ? this.state.recordData["StateClaimID"] : ""}</span>
                </div>
              </div>
              <div className='d-flex col-sm-12 mt-1 pl-0 pr-0'>
                <div className='d-flex col-sm-6 mt-1'>
                  <span className='col-sm-5 pl-0'><b>Source State</b></span>
                  <span className='col-sm-7'>{this.state.recordData ? this.state.recordData["SourceState"] : ""}</span>
                </div>
                <div className='d-flex col-sm-6'>
                  <span className='col-sm-5 pl-0'><b>Claim Status</b></span>
                  <span className='col-sm-7'>{this.state.recordData ? this.state.recordData["ClaimStatus"] : ""}</span>
                </div>

              </div>
              <div className='d-flex col-sm-6 pl-0 pr-0 mt-1 mb-4'>
                <span className='col-sm-5 pr-0'><b>Claim Reason</b></span>
                <span className='col-sm-7 '>{this.state.recordData ? this.state.recordData["ClaimReason"] : ""}</span>
              </div>
              <DataTable value={this.state.PaymentsArray} header="Checks Details" scrollable={true} scrollHeight="650px" footerColumnGroup={footerGroup}
              >
                <Column field="checkNumber" header="Number" bodyStyle={bodyStyle} headerStyle={headerStyle} body={this.hoverTemplate} />
                <Column field="checkAmount" header="Amount" bodyStyle={{ fontSize: "12px" }} body={this.formatCurrency} headerStyle={headerStyle} />
                <Column field="checkForm" header="From" bodyStyle={bodyStyle} body={this.hoverTemplate} headerStyle={headerStyle} />
                <Column field="note" header="Note" bodyStyle={bodyStyle} body={this.hoverTemplate} headerStyle={headerStyle} />
                <Column field="checkDate" header="Date" body={this.dateTemplate} bodyStyle={bodyStyle} headerStyle={headerStyle} />
                <Column body={this.actionTemplate} bodyStyle={{ textAlign: 'center', width: '8em' }} headerStyle={headerStyle} />

              </DataTable>

            </>
          </ModalBody>
          <ModalFooter className="modalFooter " style={{ width: "100%", padding: "0px", backgroundColor: "white", borderTop: "none" }}>
            <div>
              <Button color="primary" outline
                // onClick={this.props.saveClaimNotes(this.state.comment)}
                onClick={() => this.handleUsersData(this.state.recordData)}
                className='deleteModalBtn marginRight'>Save</Button>

            </div>
            <Button color="primary" outline
              onClick={this.cancelReset}
              className='deleteModalBtn marginRight'>Back</Button>
          </ModalFooter>
        </Modal >
      </div >
    );
  }
}
export default withTranslation('common')(PaymentsModal);
