import React from 'react';
import DataTables from '../../CommonDataTable/DataTable';
import store from '../../../App/store';
import fetchMethodRequest from '../../../../config/service';

// config file
export default class Employees extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      type: 'OPRA2 Property Editor',
      propertyFilterSortOptions: [
        { label: 'Property Name', value: "propertyName" },
        { label: 'Prop.Address', value: "propertyAddressLine1" },
        { label: 'Business Name', value: "businessName" },
        { label: 'Property Zip Code', value: "propertyZipCode" },
        { label: 'Property City', value: "propertyCity" },
        { label: 'Property State', value: "propertyState" },
      ],
    };
  }

  static getDerivedStateFromProps(props, state) {
    let storeData = store.getState()
    let languageData = storeData && storeData.settingsData && storeData.settingsData.settings && storeData.settingsData.settings.languageTranslation ? storeData.settingsData.settings.languageTranslation : ""
    return { languageData: languageData };
  }



  componentDidMount = () => {
    this.getSettingsData()
  }

  getSettingsData = () => {
    let filterCriteria = {}, StatusArray = []
    filterCriteria['criteria'] = [{ key: 'activeIndicator', value: true, type: 'eq', add_Filter: true }];

    fetchMethodRequest('GET', `PropertyStatus?filter=${JSON.stringify(filterCriteria)}`).then(async (response) => {
      if (response && response["propertyStatuses"].length > 0) {
        let propertyStatuses = response["propertyStatuses"]
        for (let status of propertyStatuses) {
          StatusArray.push({ label: status.status, value: status.status })
        }
        this.setState({ settingsData: StatusArray })
      }
    })
    return this.state.settingsData;
  }



  getTableFields = () => {

    let data = [
      {
        textAlign: "center",
        width: 47,
        field: "",
        // fieldType: "multiple",
        header: "",
        selectionMode: "multiple",
        show: true,
        mobile: true,
        displayInSettings: false,
      },
      // {
      //   "show": true,
      //   "textAlign": "left",
      //   "width": 100,
      //   "field": "bucket",
      //   "mobile": true,
      //   "header": "Bucket",
      //   "displayInSettings": true,
      //   "filter": true,
      //   "sortable": false,
      // },
      // {
      //   "show": true,
      //   "textAlign": "left",
      //   "width": 90,
      //   "field": "oprA_AddressDetailID",
      //   "mobile": true,
      //   // "getField": "Property",
      //   "header": "Address ID",
      //   "displayInSettings": true,
      //   "filter": true,
      //   "sortable": true,
      // },
      // {
      //   "show": true,
      //   "textAlign": "right",
      //   "width": 90,
      //   "field": "companyID",
      //   "mobile": true,
      //   // "getField": "Property",
      //   "header": "Company ID",
      //   "displayInSettings": true,
      //   "filter": true,
      //   "sortable": true,
      // }, {
      //   "show": true,
      //   "textAlign": "right",
      //   "width": 120,
      //   "field": "businessNameID",
      //   "mobile": true,
      //   // "getField": "Property",
      //   "header": "Business Name ID",
      //   "displayInSettings": true,
      //   "filter": true,
      //   "sortable": true,
      // }, \
      {
        "show": true,
        "textAlign": "left",
        "width": 240,
        "field": "businessName",
        "mobile": true,
        "header": "Business Name",
        "displayInSettings": true,
        "filter": true,
        "sortable": true,
      },
      {
        "show": true,
        "textAlign": "left",
        "width": 105,
        "field": "statePropertyId",
        "allowInt": true,
        "mobile": true,
        "filterType": "num",
        // "fieldType": "ShowData",
        "header": "St. Prop. ID",
        "displayInSettings": true,
        "filter": true,
        "sortable": true,
      },
      {
        "show": true,
        "textAlign": "left",
        "width": 105,
        "allowInt": true,
        "filterType": "num",
        "field": "propertyID",
        "mobile": true,
        "fieldType": "ShowData",
        "header": "OPP Prop. ID",
        "displayInSettings": true,
        "filter": true,
        "sortable": true,
      }, {
        "show": true,
        "textAlign": "left",
        "width": 105,
        "field": "alternateID",
        "filterType": "num",
        "mobile": true,
        // "fieldType": "ShowData",
        "header": "Alternate ID",
        "displayInSettings": true,
        "filter": true,
        "sortable": true,
      }, {
        "show": true,
        "textAlign": "left",
        "width": 105,
        "field": "addressId",
        "mobile": true,
        "filterType": "num",
        // "fieldType": "ShowData",
        "header": "Address ID",
        "displayInSettings": true,
        "filter": true,
        "sortable": true,
      }, {
        "show": true,
        "textAlign": "left",
        "width": 105,
        "field": "nameId",
        "filterType": "num",
        "mobile": true,
        // "fieldType": "ShowData",
        "header": "Name ID",
        "displayInSettings": true,
        "filter": true,
        "sortable": true,
      },
      {
        "show": true,
        "textAlign": "left",
        "width": 240,
        "field": "propertyName",
        "mobile": true,
        "header": "Property Name",
        "displayInSettings": true,
        "filter": true,
        "sortable": true,
      }, {
        "show": true,
        "textAlign": "left",
        "width": 240,
        "field": "holderName",
        "mobile": true,
        "header": "Holder Name",
        "displayInSettings": true,
        "filter": true,
        "sortable": true,
      }, {
        "show": true,
        "textAlign": "left",
        "width": 240,
        "field": "propertyDescription",
        "mobile": true,
        "header": "Property Description",
        "displayInSettings": true,
        "filter": true,
        "sortable": true,
      },
      // {
      //   "show": true,
      //   "textAlign": "left",
      //   "width": 120,
      //   "field": "nameMatchScore",
      //   "mobile": true,
      //   "header": "Name Match score",
      //   "filter": true,
      //   "sortable": true,
      //   "displayInSettings": true,
      //   // "options": [{ "label": "Active", "value": 'Active' }, { "label": "Pending", "value": 'Pending' }, { "label": "Inactive", "value": 'Inactive' }],
      // },
      // {
      //   "show": true,
      //   "textAlign": "left",
      //   "width": 120,
      //   "field": "addressLine1MatchScore",
      //   "mobile": true,
      //   "header": "Add Line1 MatchScore",
      //   "displayInSettings": true,
      //   "filter": true,
      //   "sortable": true,
      // }, 
      {
        "show": true,
        "textAlign": "left",
        "width": 240,
        "field": "businessAddressLine1",
        "mobile": true,
        "header": "Business Add Line1",
        "displayInSettings": true,
        "filter": true,
        "sortable": true,
      }, {
        "show": true,
        "textAlign": "left",
        "width": 240,
        "field": "propertyAddressLine1",
        "mobile": true,
        "header": "Prop. Address Line1",
        "displayInSettings": true,
        "filter": true,
        "sortable": true,
      },
      {
        "show": true,
        "textAlign": "left",
        "width": 120,
        "field": "businessCity",
        "mobile": true,
        // "fieldType": "Link",
        "header": "Biz City",
        "displayInSettings": true,
        "filter": true,
        "sortable": true,
      }, {
        "show": true,
        "textAlign": "left",
        "width": 120,
        "field": "businessState",
        "mobile": true,
        "header": "Biz St",
        "filter": true,
        "sortable": true,
        "displayInSettings": true,
        // "options": [{ "label": "Active", "value": 'Active' }, { "label": "Pending", "value": 'Pending' }, { "label": "Inactive", "value": 'Inactive' }],
      }, {
        "show": true,
        "textAlign": "left",
        "width": 120,
        "field": "sourceState",
        "mobile": true,
        "header": "Source St",
        "filter": true,
        "sortable": true,
        "displayInSettings": true,
        // "options": [{ "label": "Active", "value": 'Active' }, { "label": "Pending", "value": 'Pending' }, { "label": "Inactive", "value": 'Inactive' }],
      },
      {
        "show": true,
        "textAlign": "left",
        "width": 120,
        "field": "prospectiveStatus",
        "mobile": true,
        "header": "Prospective Status",
        "filter": true,
        "sortable": true,
        "displayInSettings": true,
        // "options": [{ "label": "Active", "value": 'Active' }, { "label": "Pending", "value": 'Pending' }, { "label": "Inactive", "value": 'Inactive' }],
      },
      {
        "show": true,
        "textAlign": "left",
        "width": 100,
        "field": "businessZipCode",
        "mobile": true,
        "header": "Biz Zip",
        "filter": true,
        "sortable": true,
        "displayInSettings": true,
        // "options": [{ "label": "Active", "value": 'Active' }, { "label": "Pending", "value": 'Pending' }, { "label": "Inactive", "value": 'Inactive' }],
      }, {
        "show": true,
        "textAlign": "left",
        "width": 100,
        "field": "createdBy",
        "mobile": true,
        "header": "Created By",
        "filter": true,
        "sortable": true,
        "displayInSettings": true,
        // "options": [{ "label": "Active", "value": 'Active' }, { "label": "Pending", "value": 'Pending' }, { "label": "Inactive", "value": 'Inactive' }],
      },
      {
        "show": true,
        "textAlign": "left",
        "width": 80,
        "field": "propertyState",
        "mobile": true,
        "header": "Prop. St",
        "displayInSettings": true,
        "filter": true,
        "sortable": true,
      }, {
        "show": true,
        "textAlign": "left",
        "width": 110,
        "field": "propertyCity",
        "mobile": true,
        "header": "Prop. City",
        "displayInSettings": true,
        "filter": true,
        "sortable": true,
      },
      // {
      //   "show": true,
      //   "textAlign": "left",
      //   "width": 120,
      //   "field": "cityStateZipScore",
      //   "mobile": true,
      //   "header": "City St. Zip Score",
      //   "displayInSettings": true,
      //   "filter": true,
      //   "sortable": true,
      // },
      {
        "show": true,
        "textAlign": "left",
        "width": 80,
        "field": "propertyZipCode",
        "mobile": true,
        "header": "Prop. Zip",
        "displayInSettings": true,
        "filter": true,
        "sortable": true,
      },
      // {
      //   "show": true,
      //   "textAlign": "left",
      //   "width": 120,
      //   "field": "userEmailID",
      //   "mobile": true,
      //   // "fieldType": "Link",
      //   "header": "User Email",
      //   "displayInSettings": true,
      //   "filter": true,
      //   "sortable": true,
      // },
      {
        "show": true,
        "textAlign": "left",
        "width": 80,
        "filterType": "num",
        "field": "scoreDate",
        "mobile": true,
        "fieldType": "Date",
        "dateFormat": 'MM-DD-yyyy',
        "header": "Score Dt",
        "filter": true,
        "sortable": true,
        "displayInSettings": true,
      },
      // {
      //   "show": true,
      //   "textAlign": "left",
      //   "width": 120,
      //   "field": "scoreUpdateDate",
      //   "mobile": true,
      //   "fieldType": "Date",
      //   "dateFormat": 'MM-DD-yyyy',
      //   "header": "Src Update Dt",
      //   "filter": true,
      //   "sortable": true,
      //   "displayInSettings": true,
      // },
      {
        "show": true,
        "textAlign": "right",
        "width": 80,
        "field": "propertyMinValueAmount",
        "allowInt": true,
        "mobile": true,
        "header": "Min Amt",
        "filterType": "num",
        "fieldType": "formatCurrency",
        "filter": true,
        "sortable": true,
        "displayInSettings": true,
      },
      {
        "show": true,
        "textAlign": "right",
        "width": 80,
        "field": "propertyMaxValueAmount",
        "allowInt": true,
        "fieldType": "formatCurrency",
        "filterType": "num",
        "mobile": true,
        "header": "Max Amt",
        "displayInSettings": true,
        "filter": true,
        "sortable": true,
      },
      // {
      //   "show": true,
      //   "textAlign": "left",
      //   "width": 100,
      //   "field": "batchName",
      //   "mobile": true,
      //   "header": "Batch Name",
      //   "displayInSettings": true,
      //   "filter": true,
      //   "sortable": true,
      // },
      {
        "show": true,
        "textAlign": "left",
        "width": 120,
        "field": "propertyDataSource",
        "mobile": true,
        // "fieldType": "Percentage",
        "header": " Prop. Source",
        "displayInSettings": true,
        "filter": true,
        "sortable": true,
      },
      {
        "show": true,
        "textAlign": "left",
        "width": 80,
        "field": "matchingEstateNameIssues",
        "mobile": true,
        "fieldType": "Percentage",
        "header": "Name Iss",
        "displayInSettings": true,
        "filter": true,
        "sortable": true,
      }, {
        "show": true,
        "textAlign": "left",
        "width": 220,
        "field": "matchMatchStatus",
        "mobile": true,
        "header": "Prop. Status",
        "displayInSettings": true,
        "filter": true,
        "sortable": true,
      },
      {
        "show": true,
        "textAlign": "left",
        "width": 215,
        "field": "matchStatusReason",
        "mobile": true,
        // "fieldType": "Link",
        "header": "Prop. Reason",
        "displayInSettings": true,
        "filter": true,
        "sortable": true,
      },
      {
        "show": true,
        "textAlign": "left",
        "width": 240,
        "field": "fileFor",
        "mobile": true,
        "header": "Mapped Name",
        "displayInSettings": true,
        "filter": true,
        "sortable": true,
      },
      {
        "show": true,
        "textAlign": "left",
        "width": 360,
        "field": "matchStatusNotes",
        "mobile": true,
        // "fieldType": "Link",
        "header": "Prop. Status Notes",
        "displayInSettings": true,
        "filter": true,
        "sortable": true,
      }, {
        "show": true,
        "textAlign": "left",
        "width": 150,
        "field": "claimID",
        "mobile": true,
        // "fieldType": "Link",
        "header": "Claim ID",
        "displayInSettings": true,
        "filter": true,
        "sortable": true,
      },
      {
        "show": true,
        "textAlign": "left",
        "width": 115,
        "field": "propertyAlternateId",
        "filterType": "num",
        "mobile": true,
        "header": "Prop. Alt Id",
        "displayInSettings": true,
        "filter": true,
        "sortable": true,
      },
      // {
      //   "show": true,
      //   "textAlign": "left",
      //   "width": 115,
      //   "field": "created",
      //   "mobile": true,
      //   // "fieldType": "Link",
      //   "header": "Created",
      //   "displayInSettings": true,
      //   "filter": true,
      //   "sortable": true,
      // },
      {
        "show": true,
        "textAlign": "left",
        "width": 115,
        "field": "validatedDate",
        "filterType": "num",
        "fieldType": "Date",
        "dateFormat": 'MM-DD-yyyy',
        "mobile": true,
        "header": "Dt Validated",
        "displayInSettings": true,
        "filter": true,
        "sortable": true,
      },
      {
        "show": true,
        "textAlign": "left",
        "width": 115,
        "field": "createdDate",
        "filterType": "num",
        "fieldType": "Date",
        "dateFormat": 'MM-DD-yyyy',
        "mobile": true,
        "header": "Dt Created",
        "displayInSettings": true,
        "filter": true,
        "sortable": true,
      }, {
        "show": true,
        "textAlign": "left",
        "width": 115,
        "field": "dateIndexedInElastic",
        "filterType": "num",
        "fieldType": "Date",
        "dateFormat": 'MM-DD-yyyy',
        "mobile": true,
        "header": "Dt Indexed In Elastic",
        "displayInSettings": true,
        "filter": true,
        "sortable": true,
      }, {
        "show": true,
        "textAlign": "left",
        "width": 115,
        "field": "updatedDate",
        "filterType": "num",
        "fieldType": "Date",
        "dateFormat": 'MM-DD-yyyy',
        "mobile": true,
        "header": "Dt Updated",
        "displayInSettings": true,
        "filter": true,
        "sortable": true,
      }, {
        "show": true,
        "textAlign": "left",
        "width": 115,
        "field": "dateOfLastContact",
        "filterType": "num",
        "fieldType": "Date",
        "dateFormat": 'MM-DD-yyyy',
        "mobile": true,
        "header": "Dt Of Last Contact",
        "displayInSettings": true,
        "filter": true,
        "sortable": true,
      },
      {
        "show": true,
        "textAlign": "left",
        "width": 115,
        "field": "nameMatchScore",
        "allowInt": true,
        "filterType": "num",
        "mobile": true,
        "header": "Name Score",
        "displayInSettings": true,
        "filter": true,
        "sortable": true,
      }, {
        "show": true,
        "textAlign": "left",
        "width": 115,
        "field": "opraAddedDate",
        "filterType": "num",
        "fieldType": "Date",
        "dateFormat": 'MM-DD-yyyy',
        "mobile": true,
        "header": "Dt Opra Added",
        "displayInSettings": true,
        "filter": true,
        "sortable": true,
      }, {
        "show": true,
        "textAlign": "left",
        "width": 115,
        "field": "escheatmentDate",
        "filterType": "num",
        "fieldType": "Date",
        "dateFormat": 'MM-DD-yyyy',
        "mobile": true,
        "header": "Dt Escheatment",
        "displayInSettings": true,
        "filter": true,
        "sortable": true,
      },
      {
        "show": true,
        "textAlign": "left",
        "width": 115,
        "field": "addressLine1MatchScore",
        "allowInt": true,
        "filterType": "num",
        "mobile": true,
        "header": "Line1 Score",
        "displayInSettings": true,
        "filter": true,
        "sortable": true,
      },
      {
        "show": true,
        "textAlign": "left",
        "width": 115,
        "field": "propertyResidentialFlag",
        "mobile": true,
        "header": "Prop. Residential Flag",
        "displayInSettings": true,
        "filter": true,
        "sortable": true,
      },
      {
        "show": true,
        "textAlign": "left",
        "width": 115,
        "filterType": "num",
        "allowInt": true,
        "field": "cityStateZipScore",
        "mobile": true,
        "header": "CSZ Score",
        "displayInSettings": true,
        "filter": true,
        "sortable": true,
      },
    ]
    return data;
  };
  getFormFields = () => {

    return
  }



  submit = async (item) => {

  }

  render() {
    return (
      <span>
        <DataTables
          onRef={(ref) => (this.datatableref = ref)}
          getTableFields={this.getTableFields}
          formFields={this.getFormFields}
          exportRequried={false}
          printRequried={false}
          addRequried={false}
          editRequired={true}
          sortField={"EntityName"}
          deleteRequired={false}
          viewRequired={true}
          settingsRequired={false}
          filterRequired={true}
          gridRequried={false}
          // isShownnFieldType={true}
          sample={false}
          propertyFilterSortOptions={this.state.propertyFilterSortOptions}
          globalSearchFieldName='user'
          scrollHeight={"580px"}
          datakey={"_id"}
          isSelectMode={"multiple"}
          globalSearch={'Search'}
          type={"OPRA2 Property Editor"}
          displayright={true}
          icon='faCity'
          settingsData={this.state.settingsData}
          fontAwesome={true}
          routeTo='propertyEditor'
          displayViewOfForm='modal'
          activeTab={"Green"}
          apiResponseKey='ns_scored_propertys'
          className={true}
          apiUrl={"nsscoredproperties"}
          entityType='employee'
        />

      </span>
    );
  }
}