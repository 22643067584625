import React from 'react';
import {
    Button, Modal, ModalHeader, ModalBody, ModalFooter,
    Card, CardBody, ButtonToolbar, ButtonGroup, Container, Row, Col, Table,
} from 'reactstrap';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import Loader from '../../../App/Loader';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import DefaultInput from '../../shared/components/form/DefaultInput';
import DefaultInput from '../../../../shared/components/form/DefaultTextArea';
import { faArrowCircleLeft, faUsers, faFolder, faArrowLeft, faPlus, faMinus, faSave, faStarOfLife, faTimes, faTimesCircle, faToggleOff, faFilter, faBuilding } from '@fortawesome/free-solid-svg-icons';
import config from '../../../../config/configMessages'
import fetch from '../../../../config/service';
import fetchMethodRequest from '../../../../config/service';
import DeleteRowModal from '../../CommonModals/DeleteRowModal';
import showToasterMessage from '../../../UI/ToasterMessage/toasterMessage';
class UnreviewedCondition extends React.Component {
    static propTypes = {
        t: PropTypes.func.isRequired,
        payload: PropTypes.arrayOf(PropTypes.shape({
            color: PropTypes.string,
            value: PropTypes.string,
        })).isRequired,
    };

    constructor(props) {
        super(props);
        this.state = {
            dashboardData: {},
            isLoading: false,
        };
    }

    componentDidMount = () => {
        let user = JSON.parse(localStorage.getItem('loginCredentials'));
        if (user) {
            this.setState({
                loginData: user,
                role: user.role,
                RulesListArray: []
            })
            this.getDataFromServer();
        }
    }

    handlePercentage = async (Val) => {
        let counter = 0;
        const interval = setInterval(async () => {
            if (this.state.isLoading) {
                counter = counter + 15;
                await this.setState({
                    progress: counter,
                })
            } else {
                if (!this.state.isLoading) {
                    clearInterval(interval);
                }
            }
        }, 100);
    }

    isShowLoader = async (value) => {
        this.setState({
            isLoading: value
        })
        if (value === true) {
            this.handlePercentage("1")
        }
    }


    //Get THreshold Values from SQL
    getDataFromServer = async () => {
        this.isShowLoader(true)
        fetch('GET', `unreviewedCondition`)
            .then(async (response) => {
                // console.log("response", response)
                if (response && response.unreviewedConditions) {
                    let unReviewedCondition = response.unreviewedConditions;
                    for (let condition of unReviewedCondition) {
                        condition.searchQueryCondition = JSON.stringify(JSON.parse(condition.searchQueryCondition), null, 5)
                    }
                    // console.log("unReviewedCondition", unReviewedCondition)
                    await this.setState({
                        RulesListArray: unReviewedCondition,
                    });
                    let labels = response.unreviewedConditions, modifiedLabels = []
                    for (let i = 0; i < labels.length; i++) {
                        modifiedLabels.push({
                            label: labels[i].name,
                            value: { name: labels[i].name, description: labels[i].description },
                        })
                    }
                    localStorage.setItem('ruleGroups', JSON.stringify(modifiedLabels))

                } else if (response && response.errorMessage && (response.errorMessage === config.tokenMessage || response.errorMessage === config.sessionExpired)) {
                    await this.setState({
                        sessionExpiryModal: true
                    })
                    localStorage.setItem('ruleGroups', JSON.stringify([]))
                } else if (response && response.errorMessage && response.errorMessage === config.warningMessage) {
                    await this.setState({
                        sessionWarningModal: true
                    })
                }
                this.isShowLoader(false)
            }).catch((err) => {
                return err;
            });
    }

    handlePercentage = async (Val) => {
        let counter = 0;
        const interval = setInterval(async () => {
            if (this.state.isLoading) {
                counter = counter + 15;
                await this.setState({
                    progress: counter,
                })
            } else {
                if (!this.state.isLoading) {
                    clearInterval(interval);
                }
            }
        }, 100);
    }

    isShowLoader = async (value) => {
        this.setState({
            isLoading: value
        })
        if (value === true) {
            this.handlePercentage("1")
        }
    }

    handlekeyValueChange = (e, key, index) => {
        let RulesListArray = this.state.RulesListArray
        RulesListArray[index][key] = e
        this.setState({
            documentFieldErr: false,
            validationExists: false,
            errordocMessage: "",
            errorShownindex: "",
            RulesListArray: this.state.RulesListArray
        })
    }

    deleteKeyValueItem = async () => {
        this.isShowLoader(true)

        let apiUrl = "unreviewedCondition";
        let method = "DELETE", url, body = {}
        let id = this.state.deleteRowDataId._id
        url = `${apiUrl}/${id}`;
        if (id) {
            return fetchMethodRequest(method, url, body)
                .then(async (response) => {
                    let sessionexpired = localStorage.getItem('sessionexpired')
                    if (sessionexpired === "true") {
                        this.setState({ sessionExpiryModal: true })
                    }
                    await this.setState({
                        openDeleteModal: false
                    });
                    if (response && response.respCode) {
                        //showToasterMessage(response.respMessage, 'success');
                        this.getDataFromServer(this.state.filterCriteria);
                    } else if (response && response.errorMessage) {
                        showToasterMessage(response.errorMessage, 'error');
                        // this.getErrorMessage(response.errorMessage, "", "error")
                        await this.setState({
                            errorMessage: response.errorMessage,
                            errorResponse: false,
                            errorcolor: "red"
                        })
                    }
                }).catch((err) => {
                    return err;
                });
        }
        else {
            let list_arr = this.state.RulesListArray;
            list_arr.splice(this.state.deleteRowIndex, 1)
            this.isShowLoader(false)
            await this.setState({
                RulesListArray: JSON.parse(JSON.stringify(list_arr)),
                openDeleteModal: false,
                deleteRowDataId: null,
                deleteRowIndex: -1,
            })
        }


        // let index = this.state.deleteRowDataId
        // let list_arr = this.state.RulesListArray;
        // list_arr.splice(index, 1)

        // await this.setState({
        //   // RulesListArray: JSON.parse(JSON.stringify(afterdelarr))
        //   RulesListArray: JSON.parse(JSON.stringify(list_arr))
        // })
        // this.isShowLoader(false)
        // this.closeDeleteModal()
    }

    // conformation for delete item
    deleteConfirmAction = async (item, i, selectActions) => {

        this.setState({
            deleteRowDataId: item,
            deleteRowIndex: i,
            openDeleteModal: true,
        });
    }

    //close delete modal
    closeDeleteModal = async () => {
        this.setState({
            openDeleteModal: false,
        })
    }

    // Delete Modal
    getDeleteRowModal() {
        return (
            <DeleteRowModal
                openDeleteModal={this.state.openDeleteModal}
                closeDeleteModal={this.closeDeleteModal}
                deleteSelectedRow={this.deleteKeyValueItem}
                confirmModalText={"Are you sure want to delete"}
                type={this.props.type}
            />
        )
    }

    getKeyValueButton() {
        // const { t } = this.props
        return (
            <div className='d-flex col-12 pl-0 pr-0 keylist'>
                <div className='col-12 px-0'>
                    <div>
                        {this.getKeyValueTable()}
                    </div>
                    <div className='row ml-0 mr-0 pt-4'>
                        <h5 className={"mr-auto mt-auto mb-auto"} style={{ paddingLeft: "15px", fontWeight: "400" }}>{""}</h5>
                        {
                            <span className='float-right' style={{ marginRight: "16px" }}>
                                <ButtonToolbar>
                                    <Button color='primary' outline onClick={() => this.KeyValueTable()}>
                                        Add Rules
                                    </Button>
                                </ButtonToolbar>
                            </span>}
                    </div>

                </div>
            </div>
        )
    }

    KeyValueTable = async (keyCount) => {
        // console.log("entered")
        await this.setState({
            KeyValueTable: true,
            keyCount: keyCount
        })
        // if (this.state.validationExists && this.state.documentFieldErr) {
        //   await this.setState({
        //     errordocMessage: "Please add atleast one document",
        //     documentFieldErr: false,
        //     validationExists: false
        //   })
        // }
        let RulesListArray = this.state.RulesListArray;
        await this.setState({
            KeyValueTable: true,
            keyCount: RulesListArray.length > 0 ? RulesListArray.length : 0
        })
        for (let i = 0; i < RulesListArray.length; i++) {
            let key = RulesListArray[i][`name`]
            let value = RulesListArray[i][`description`]
            let searchQueryCondition = RulesListArray[i][`searchQueryCondition`]

            if (!key || !value || !searchQueryCondition || !key === null || value === null || searchQueryCondition === null) {
                // validationExists = true
                await this.setState({
                    errordocMessage: "Please fill the fields before saving the Rule",
                    documentFieldErr: true,
                    validationExists: true
                })
            } else {
                await this.setState({
                    errordocMessage: "",
                    documentFieldErr: false,
                    validationExists: false
                })
            }
        }
        if (!this.state.validationExists) {
            RulesListArray.push({
                name: ``,
                description: ``,
                searchQueryCondition: ``,
                new: true
            })

        }
        //RulesListArray.push([])
        await this.setState({
            RulesListArray: RulesListArray,
        });
    }

    CreateOrUpdateData = async (item, index) => {

        this.setState({
            errorMessage: "err.message",
            errorResponse: false,
            errorcolor: "red",
            isLoading: true,
            errorRule: `Rule ${index + 1}`
        })

        if (item.name !== undefined && item.name.length > 0 && item.description !== undefined && item.description.length > 0) {
            this.isShowLoader(true)
            let apiUrl = "unreviewedCondition";
            let method = "PUT", url, body = {}
            let id = item._id

            // hari why this crud
            if (item.new) {
                method = "POST"
                url = `${apiUrl}`;
                body = item
            } else {
                method = "PUT"
                url = `${apiUrl}/${id}`;
                body = item
            }
            try {
                body.searchQueryCondition = JSON.stringify(JSON.parse(body.searchQueryCondition), null, 5)
            } catch (err) {
                return this.setState({
                    errorMessage: err.message,
                    errorResponse: true,
                    errorcolor: "red",
                    isLoading: false
                })
            }
            // console.log("body>>", body)

            return fetchMethodRequest(method, url, body)
                .then(async (response) => {
                    let sessionexpired = localStorage.getItem('sessionexpired')
                    if (sessionexpired === "true") {
                        this.setState({ sessionExpiryModal: true })
                    }
                    await this.setState({
                        openDeleteModal: false
                    });
                    if (response && response.respCode) {
                        //showToasterMessage(response.respMessage, 'success');
                        this.getDataFromServer(this.state.filterCriteria);
                    } else if (response && response.errorMessage) {
                        showToasterMessage(response.errorMessage, 'error');
                        // this.getErrorMessage(response.errorMessage, "", "error")
                        await this.setState({
                            errorMessage: response.errorMessage,
                            errorResponse: false,
                            errorcolor: "red"
                        })
                    }
                }).catch((err) => {
                    return err;
                });
        } else {
            await this.setState({
                errordocMessage: "Please fill the fields before saving the Rule",
                errorShownindex: index,
                documentFieldErr: true,
                validationExists: true
            })
        }

    }

    getKeyValueTable() {
        return (
            <div className='d-flex col-12 ' style={{ width: "100%" }}>
                <div className='col-12 px-0'>
                    {/* <Table bordered={true}> */}
                    {/* <thead>
              <tr>
                <th>{this.props.type === "Process Documents" ? "Name" : "Key"}</th>
                <th>{this.props.type === "Process Documents" ? "Url" : "Value"}</th>
                {<th>Actions</th>}
              </tr>
            </thead> */}
                    <div >
                        {
                            this.state.RulesListArray && this.state.RulesListArray.length > 0 ?
                                this.state.RulesListArray.map((item, i) => {
                                    return <div key={i} style={{ paddingBottom: "10px", justifyContent: "space-between", display: "flex", borderBottom: "2px solid #616161" }}>
                                        <div style={{ width: "100%" }}>
                                            <p>{this.state.validationExists && this.state.errorShownindex === i ? <h5 style={{ color: "red", justifyContent: "center", display: "flex" }}>{`${this.state.errordocMessage}${i + 1}`}</h5> : ""}
                                            </p>
                                            <p><h3>
                                                {`Rule ${i + 1}`}
                                            </h3>
                                            </p>
                                            <p>
                                                <p>
                                                    {`Name`}
                                                    <FontAwesomeIcon icon={faStarOfLife}
                                                        className='pl-1'
                                                        color='red'
                                                        data-toggle="tool-tip"
                                                        title="Mandotary Field"
                                                        style={{ width: 8 }}
                                                    />
                                                </p>
                                                <span style={{ padding: "3px" }}>
                                                    <input key={i}
                                                        style={{ height: "30px", width: "100%" }}
                                                        className="key_table"
                                                        name={item.name ? item.name : `name${i}`}
                                                        component={DefaultInput}
                                                        value={item.name ? item.name : ""}
                                                        type={"textArea"}
                                                        placeholder={"name"}
                                                        isDisable={!this.state.attributeCode && this.state.formType === 'edit' ? true : false}
                                                        onChange={(e) => this.handlekeyValueChange(e.target.value, `name`, i)}
                                                    /></span>
                                            </p>
                                            <p>
                                                <p>
                                                    {`Description`}<FontAwesomeIcon icon={faStarOfLife}
                                                        className='pl-1'
                                                        color='red'
                                                        data-toggle="tool-tip"
                                                        title="Mandotary Field"
                                                        style={{ width: 8 }}
                                                    />
                                                </p>
                                                <span style={{ padding: "3px" }}>
                                                    <input key={i}
                                                        style={{ height: "30px", width: "100%" }}
                                                        className="key_table"
                                                        name={item.description ? item.description : `description${i}`}
                                                        component={DefaultInput}
                                                        value={item.description ? item.description : ""}
                                                        type={"textArea"}
                                                        placeholder={"Description"}
                                                        isDisable={!this.state.attributeCode && this.state.formType === 'edit' ? true : false}
                                                        onChange={(e) => this.handlekeyValueChange(e.target.value, `description`, i)}
                                                    /></span>
                                            </p>
                                            <p>
                                                <p>
                                                    {`Search Query`}<FontAwesomeIcon icon={faStarOfLife}
                                                        className='pl-1'
                                                        color='red'
                                                        data-toggle="tool-tip"
                                                        title="Mandotary Field"
                                                        style={{ width: 8 }}
                                                    />
                                                </p>
                                                <span style={{ padding: "3px" }}>
                                                    <textarea key={i}
                                                        style={{ height: "200px", width: "100%" }}
                                                        name={item.searchQueryCondition ? item.searchQueryCondition : `searchQueryCondition${i}`}
                                                        component={DefaultInput}
                                                        className="key_table"
                                                        type={"text"}
                                                        placeholder={"Search Query"}
                                                        value={item.searchQueryCondition ? item.searchQueryCondition : ""}
                                                        isDisable={!this.state.attributeCode && this.state.formType === 'edit' ? true : false}
                                                        onChange={(e) => this.handlekeyValueChange(e.target.value, `searchQueryCondition`, i)}
                                                    /></span>
                                            </p>
                                            <span style={{ color: this.state.errorcolor }}>
                                                {this.state.errorRule == `Rule ${i + 1}` && this.state.errorResponse ? this.state.errorMessage : ""}
                                            </span>
                                            <p>
                                                <div className='col-sm-12' style={{ textAlign: "right", alignSelf: 'right' }}>
                                                    <Button
                                                        outline
                                                        color="primary"
                                                        size="sm"
                                                        // disabled={this.state.searchValue ? false : true}
                                                        className={'p-1 m-2'}
                                                        style={{ width: "85px" }}
                                                        onClick={() => this.CreateOrUpdateData(item, i)}>
                                                        {item.new ? "Save" : "Update"}
                                                    </Button>
                                                    <Button
                                                        outline
                                                        color="danger"
                                                        size="sm"
                                                        // disabled={this.state.searchValue ? false : true}
                                                        className={'p-1 m-2'}
                                                        style={{ width: "85px" }}
                                                        onClick={() => this.deleteConfirmAction(item, i, "Delete")}
                                                    // onClick={() => this.deleteKeyValueItem(i, "Delete")}
                                                    >
                                                        Delete
                                                    </Button>
                                                </div>
                                            </p>
                                        </div>
                                    </div>
                                }) : ""}
                    </div>

                    {/* </Table> */}
                </div>
            </div >
        )
    }

    render() {
        return (
            <div>
                <Loader loader={this.state.isLoading} progress={this.state.progress} className="progress-loader" />

                <Container className="tableCardBody mt-3" style={{ width: '98%', marginLeft: '20px', backgroundColor: "white" }}>
                    <Row>
                        <div className="topbar__left">
                            <span className={`tableheadericon ml-3 lnr lnr-layers`} />
                            <h4 className="Kanban_title_card " >{"UnReviewed Condition"}</h4>
                        </div>
                    </Row>
                    {/* <CountDisplay
            isShowLoader={this.isShowLoader}
          /> */}
                    {this.getKeyValueButton()}
                    {this.state.openDeleteModal ? this.getDeleteRowModal() : null}
                </Container >
            </div>
        );
    }
}

export default withTranslation('common')(UnreviewedCondition);


