import React from 'react';
import store from '../../../App/store';
import apiCalls from '../../../../config/apiCalls';
import fetch from '../../../../config/service'
import DataTables from '../../CommonDataTable/DataTable';
import config from '../../../../config/config';
import MenuModal from '../../CommonModals/MenuModal'

// config file
export default class MenuConfiguration extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      languageData: '',
      menuList: [],
      reportsArr: [],
      openpaymentsModal: false

    };
  }
  componentDidMount = async () => {
    this.getMenuListFromServer();
    this.getMenuItems()
  }



  /**
   * 
   * @returns get the list from the menus api
   */
  getMenuListFromServer = async () => {
    let filterCriteria = {}, url;
    filterCriteria.sortfield = 'sequenceNo';
    filterCriteria.direction = 'asc';
    url = `${apiCalls.MenuList}?filter=${JSON.stringify(filterCriteria)}`;
    let neWmenuList = [], neWsubmenuList = [], routes = [];
    var sorted = []
    return fetch('GET', url)
      .then(async (res) => {
        if (res && res.menulists && res.menulists.length > 0) {
          if (res.menulists) {
            res.menulists.forEach((item) => {
              // console.log("item", item)
              if (item.submenus && item.submenus.length > 0) {
                item.submenus.forEach(sitem => {
                  routes.push({ label: sitem.displayTitle, value: sitem.route })
                  neWmenuList.push({ label: sitem.displayTitle, value: sitem.displayTitle, data: { displayTitle: sitem.displayTitle, route: sitem.route } });
                  neWsubmenuList.push({ label: sitem.displayTitle, value: { label: sitem.displayTitle, url: sitem.route, items: [] } });

                })
              } else {
                routes.push({ label: item.displayTitle, value: item.route })
                neWmenuList.push({ label: item.displayTitle, value: item.displayTitle, data: { displayTitle: item.displayTitle, route: item.route } });
                neWsubmenuList.push({ label: item.displayTitle, value: { label: item.displayTitle, url: item.route, items: [] } });
              }
            });

          }
          this.setState({
            menuList: sorted,
            routes: routes,
            subMenuList: neWsubmenuList
          })
        } else {
          this.setState({
            menuList: [],
            subMenuList: [],
            routes: routes
          })
        }
      })
  }

  getMenuItems() {
    let filterCriteria = {}, url;
    filterCriteria.sortfield = 'sequenceNo';
    filterCriteria.direction = 'asc';
    url = `${apiCalls.topBarMenulists}?filter=${JSON.stringify(filterCriteria)}`;
    let mainLevels = [], subLevels = [];
    return fetch('GET', url)
      .then(async (res) => {
        if (res && res.topBarMenulists && res.topBarMenulists.length > 0) {
          for (var obj of res.topBarMenulists) {
            let index = mainLevels.findIndex(item => item.label === obj.label)
            if (index === -1) {
              mainLevels.push({ label: obj.label, value: obj.label })
            }
            if (obj.items && obj.items[0] && obj.items[0]) {
              let subindex = subLevels.findIndex(item2 => item2.label === obj.items[0].label)
              if (subindex === -1) {
                subLevels.push({ label: obj.subLabel, value: obj.subLabel })
              }
            }
          }
          this.setState({
            mainLevels: mainLevels,
            subLevels: subLevels
          })
        } else {
          this.setState({
            items: []
          })
        }
      })
  }

  static getDerivedStateFromProps(props, state) {
    let storeData = store.getState()
    let languageData = storeData && storeData.settingsData && storeData.settingsData.settings && storeData.settingsData.settings.languageTranslation ? storeData.settingsData.settings.languageTranslation : ""
    return { languageData: languageData };
  }

  /**
   * 
   * @returns tableFIelds to show
   */
  getTableFields = () => {
    let data = [
      {
        "show": true,
        "textAlign": "left",
        "mobile": true,
        // "width": 50,
        "field": "label",
        fieldType: "BoolText",
        "header": "Top Menu",
        "filter": true,
        "sortable": true
      }, {
        "show": true,
        "textAlign": "left",
        "mobile": true,
        // "width": 50,
        "field": "sequence",
        "header": "Top Menu Sequence",
        "filter": true,
        "sortable": true
      }, {
        "show": true,
        "textAlign": "left",
        "mobile": true,
        // "width": 50,
        "field": "subLabel",
        "header": "First Depth",
        "filter": false,
        "sortable": false
      }, {
        "show": true,
        "textAlign": "left",
        "mobile": true,
        // "width": 50,
        "fieldType": "thirdlevel",
        "field": "items",
        "header": "Second Depth",
        "filter": false,
        "sortable": false
      }, {
        "show": true,
        "textAlign": "left",
        "mobile": true,
        // "width": 50,
        fieldType: "Actions",
        "field": "Actions",
        "header": "Actions",
        "filter": true,
        "sortable": false
      },

    ]
    return data;
  };

  /**
   * 
   * @returns formFields to show in the form
   */
  getFormFields = () => {
    return ([
      // {
      //   "show": true,
      //   "textAlign": "left",
      //   "mobile": true,
      //   type: "text",
      //   // "width": 50,
      //   "name": "topLevel",
      //   options: [{ "label": "Admin", "value": null }, { "label": "Claim", "value": 'Active' }, { "label": "Deals", "value": 'Pending' }, { "label": "Documents", "value": 'Inactive' }],
      //   // filterElement: [{ "label": "All", "value": null }, { "label": "Active", "value": 'Active' }, { "label": "Pending", "value": 'Pending' }, { "label": "Inactive", "value": 'Inactive' }],
      //   "label": "Top Level",

      // },
      {
        'show': true,
        // 'isMultiSelect': true,
        "value": "",
        "type": "dropDown",
        "name": "firstDepth",
        "label": "First Level Menus",
        options: this.state.mainLevels,
        "id": "Screen",
        "placeholder": "Screen",
        //"required": true
      }, {
        'show': true,
        // 'isMultiSelect': true,
        "value": "",
        "type": "dropDown",
        "name": "sequence",
        "label": "First Level Menus",
        options: this.state.mainLevels,
        "id": "Screen",
        "placeholder": "Screen",
        //"required": true
      }, {
        'show': true,
        'isMultiSelect': true,
        "value": "",
        "type": "number",
        "name": "1Sequence",
        "label": "Sequence",
        options: this.state.subMenuList,
        "id": "Screen",
        "placeholder": "Screen",
        //"required": true
      },
      {
        "show": true,
        // 'isMultiSelect': true,
        "textAlign": "left",
        "mobile": true,
        type: "dropDown",
        // "width": 50,
        "name": "secondDepth",
        // options: this.state.menuList,
        options: this.state.subLevels,
        "label": "Parent Level",

      }, {
        'show': true,
        'isMultiSelect': true,
        "value": "",
        "type": "number",
        "name": "2Sequence",
        "label": "Sequence",
        "id": "Screen",
        "placeholder": "Screen",
        //"required": true
      },
      {
        'show': true,
        'isMultiSelect': true,
        "value": "",
        "type": "dropDown",
        "name": "thirdDepth",
        "label": "Second Level Menus",
        options: this.state.subMenuList,
        "id": "Screen",
        "placeholder": "Screen",
        "required": false
      },
      {
        'show': true,
        'isMultiSelect': true,
        "value": "",
        "type": "number",
        "name": "3Sequence",
        "label": "Sequence",
        options: this.state.subMenuList,
        "id": "Screen",
        "placeholder": "Screen",
        //"required": true
      },

      {
        "value": "",
        "type": "switch",
        "name": "activeIndicator",
        "label": "Active /Inactive Status",
        "id": "name",
        "placeholder": "Name",
      }]
    );
  }

  openmenuModal = async (selectedRowData) => {
    await this.setState({
      openmenuModal: true,
      selectedRowData: selectedRowData
    })
  }

  closeMenuModal = () => {
    this.setState({
      openmenuModal: false
    })
    // window.re
    if (this.datatableref) {
      this.datatableref.getDataFromServer()
    }
  }

  getMenuModal = () => {
    return (
      <MenuModal
        openmenuModal={this.state.openmenuModal}
        closeMenuModal={this.closeMenuModal}
        subLevels={this.state.subLevels}
        mainLevels={this.state.mainLevels}
        finalLevels={this.state.subMenuList}
        routes={this.state.routes}
        selectedRowData={this.state.selectedRowData}
      />
    )
  }

  render() {
    return (
      <>
        <DataTables
          onRef={(ref) => (this.datatableref = ref)}
          getTableFields={this.getTableFields}
          formFields={this.getFormFields}
          exportRequried={false}
          customForm={this.openmenuModal}
          sortfield={"sequence"}
          printRequried={false}
          addRequried={true}
          editRequired={true}
          deleteRequired={true}
          viewRequired={true}
          settingsRequired={false}
          filterRequired={false}
          gridRequried={false}
          isClearFilters={true}
          sample={false}
          globalSearchFieldName='user'
          globalSearch={'Search'}
          type='Menu Configuration'
          icon='user'
          routeTo='roles'
          displayViewOfForm='modal'
          apiResponseKey='topBarMenulists'
          apiUrl={apiCalls.topBarMenulists}
          entityType='employee'
          menuList={this.state.menuList}
        />
        {this.state.openmenuModal ? this.getMenuModal() : null}
      </>
    );
  }
}