import React from 'react';
import DataTables from '../../CommonDataTable/DataTable';
import apiCalls from '../../../../config/apiCalls'
import store from '../../../App/store';
import RolePermissions from '../../CommonModals/Permissions';

// config file
export default class Employees extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      type: 'Investor'
    };
  }

  static getDerivedStateFromProps(props, state) {
    let storeData = store.getState()
    let languageData = storeData && storeData.settingsData && storeData.settingsData.settings && storeData.settingsData.settings.languageTranslation ? storeData.settingsData.settings.languageTranslation : ""
    return { languageData: languageData };
  }



  componentDidMount = () => {
    //Screen permisions value can be edit,view, no view
    let screenPermissions = RolePermissions.screenPermissions(this.state.type);
    this.setState({
      screenPermissions: screenPermissions
    })

  }

  getTableFields = () => {
    let data = [
      {
        "show": true,
        "textAlign": "left",
        "width": 180,
        "field": "InvestorID",
        "mobile": true,
        "header": "Investor Id",
        "displayInSettings": true,
        "filter": false,
        "sortable": true,
      },
      {
        "show": true,
        "textAlign": "left",
        "width": 180,
        "field": "InvestorName",
        "mobile": true,
        "fieldType": "Link",
        "header": "Investor Name",
        "displayInSettings": true,
        "filter": false,
        "sortable": true,
      }, {
        "show": true,
        "textAlign": "left",
        "width": 180,
        "field": "InvestorWebsite",
        "fieldType": "website",
        "mobile": true,
        "header": "Website",
        "filter": false,
        "sortable": true,
        "displayInSettings": true,
        // "options": [{ "label": "Active", "value": 'Active' }, { "label": "Pending", "value": 'Pending' }, { "label": "Inactive", "value": 'Inactive' }],
      },

    ]
    return data;
  };


  getFormFields = () => {
    return ([
      {
        //'show': false,
        "value": "",
        "type": "text",
        "name": "attributeName",
        "label": " Name",
        "id": "name",
        "displayInSettings": true,
        "placeholder": "Attribute Name",
        "required": true
      },

    ]);
  }

  submit = async (item) => {
    await this.setState({
      openViewModal: true,
      rowData: [{ 'label': 'name', 'value': 'new name' }]
    })
  }

  render() {
    return (
      <span>
        <DataTables
          onRef={(ref) => (this.datatableref = ref)}
          getTableFields={this.getTableFields}
          getDetailedViewFields={this.getDetailedViewFields}
          formFields={this.getFormFields}
          statusFilter={false}
          sortField={"InvestorName"}
          className={true}
          exportRequried={false}
          printRequried={false}
          addRequried={false}
          editRequired={false}
          deleteRequired={false}
          viewRequired={true}
          settingsRequired={false}
          filterRequired={false}
          gridRequried={false}
          sample={false}
          globalSearchFieldName='user'
          globalSearch={'Investor search'}
          type={this.state.type}
          displayright={true}
          icon='users'
          routeTo='investor'
          displayViewOfForm='screen'
          apiResponseKey='investors'
          apiUrl={apiCalls.uniqueInvestors}
          entityType='employee'
        />
      </span>
    );
  }
}