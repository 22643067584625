import React from "react";
import { Dropdown } from "primereact/dropdown";
import { render } from "react-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";

export default class AddRow extends React.Component {
  state = {
    rows: [],
    sorted: [],
    reportsArr: [
      { label: "Available to File", value: { label: "Available to File", url: "/availabletoFile", items: [] } },
      {
        label: "Deal Sizing Report For Private Equity Portfolio", value: {
          label: "Report - Deal Sizing Report For Private Equity Portfolio", url: "/dealsizingreportforpe", items: []
        }
      },
      { label: "Report - Deal Sizing Report For Companies", value: { label: "Report - Deal Sizing Report For Companies", url: "/dealsizingreport", items: [] } },
      { label: "Report - Seller Summary Report", value: { label: "Report - Seller Summary Report", url: "/sellerSummaryReport", items: [] } },
      { label: "Report - Activity Details Report", value: { label: "Report - Activity Details Report", url: "/reportslistView", items: [] } },
      { label: "Report - Utilization – Integrity", value: { label: "Report - Utilization – Integrity", url: "/integrity", items: [] } },
      { label: "Report - Utilization – US Team", value: { label: "Report - Utilization – US Team", url: "/executive", items: [] } },
      { label: "Report - Required Evidence", value: { label: "Report - Required Evidence", url: "/reportsrequiredevidence", items: [] } },
      { label: "Report - Available to Confirm Onsite", value: { label: "Report - Available to Confirm Onsite", url: "/availabletoConfirmonSite", items: [] } },
      { label: "Report List - All Owned Names and Addresses", value: { label: "Report List - All Owned Names and Addresses", url: "/ownedNamesandAddresses", items: [] } },
      { label: "Change Report", value: { label: "Change Report", url: "/namesReport", items: [] } },
    ]
  };

  componentWillUnmount() {
    if (this.props.onRef) {
      this.props.onRef(null);
    }
  }

  componentDidMount = async (type) => {
    if (this.props.onRef) {
      this.props.onRef(this);
    }
    if (this.props.loadRows) {
      this.loadRowsData()
    }

    if (this.props.options) {
      let data = this.props.options
      for (var obj of this.state.reportsArr) {
        let index = data.findIndex(item => item.label === obj.label)
        if (index === -1) {
          data.push(obj)
        }
      }
      var sorted = data.sort((a, b) => {
        let fa = a.label,
          fb = b.label;
        if (fa < fb) {
          return -1;
        }
        if (fa > fb) {
          return 1;
        }
        return 0;
      });
      this.setState({
        sorted: sorted
      })
    }
  }

  handleValidate() {
    let obj = this.getreturnRows().find(x => x.sequence === "" || x.label === "");
    if (obj) {
      this.validationErrors("true")
    } else {
      return this.getreturnRows("false")
    }

  }
  getreturnRows() {
    let modifiedData = []
    if (this.state.rows.length > 0) {
      this.state.rows.forEach((item, index) => {
        item.url = item.label.url ? item.label.url : item.url
        item.label = item.label.label ? item.label.label : item.label
        modifiedData.push(item);

      });

      const sorted = modifiedData.sort((a, b) => {
        let fa = a.sequence,
          fb = b.sequence;
        if (fa < fb) {
          return -1;
        }
        if (fa > fb) {
          return 1;
        }
        return 0;
      });
      return sorted
    } else {
      return []
    }
  }
  getdatareturnRows() {
    let modifiedData = []
    this.props.loadRows.forEach((item, index) => {
      let data = { label: item.label, url: item.url, items: [] }
      item.label = data
      modifiedData.push(item);

    });
    return modifiedData
  }

  loadRowsData = async () => {
    const data = await this.getdatareturnRows()

    await this.setState({
      rows: data
    })
  }

  validationErrors = () => {
    let obj = this.state.rows.find(x => x.sequence === "" || x.label === "");
    if (obj) {
      this.setState({
        isDisable: true,
        isShowError: true,
        shownText: "Please select all the fields"
      })
      return false
    } else {
      this.setState({
        isDisable: false,
        isShowError: false,
        shownText: ""

      })
      return true
    }
  }

  handleChange = (name, idx, e) => {
    const rows = [...this.state.rows];
    // this.validationErrors()
    if (name === "label") {
      let obj = this.state.rows.find(x => x.label.label === e.target.value.label);
      if (obj) {
        this.setState({
          isDisable: true,
          isShowError: true,
          shownText: "The selected item is already in the list.Please check once"
        })
      } else {
        rows[idx][name] = e.target.value
        this.setState({
          isDisable: false,
          isShowError: false,
          shownText: ""

        })
      }
    } else {
      rows[idx][name] = e.target.value
    }
    this.setState({
      rows
    });
  };

  handleAddRow = () => {
    const item = {
      label: "",
      url: "",
      sequence: ""
    };
    this.setState({
      rows: [...this.state.rows, item]
    });
  };

  handleRemoveSpecificRow = (idx) => {
    const rows = [...this.state.rows]
    rows.splice(idx, 1)
    this.setState({ rows })

  }
  render() {
    return (
      <div>
        <div className="container">
          <div className="row clearfix">
            <div className="col-md-12 column">
              <table
                className="table table-bordered table-hover"
                id="tab_logic"
              >
                <thead>
                  <tr>
                    <th className="text-center"> # </th>
                    <th className="text-center"> Item </th>
                    <th className="text-center"> Sequence </th>
                    <th />
                  </tr>
                </thead>
                <tbody>
                  {this.state.rows.map((item, idx) => (
                    <tr id="addr0" key={idx}>
                      <td>{idx}</td>
                      <td style={{ width: 30 }}>
                        <Dropdown
                          options={this.state.sorted}
                          type="text"
                          style={{ width: 30 }}
                          name="label"
                          filter={true}
                          value={this.state.rows[idx].label}
                          onChange={(e) => this.handleChange("label", idx, e)}
                          className="form-control"
                        />
                      </td>
                      <td>
                        <input
                          type="text"
                          name="sequence"
                          value={this.state.rows[idx].sequence}
                          onChange={(e) => this.handleChange("sequence", idx, e)}
                          className="form-control"
                        />
                      </td>
                      <td>
                        <FontAwesomeIcon
                          icon={faTimes}
                          style={{ color: "red" }}
                          onClick={(e) => this.handleRemoveSpecificRow(idx)}
                        />


                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
              <button onClick={this.handleAddRow} disabled={this.state.isDisable} className="btn btn-primary">
                Add Row
              </button>
              {this.state.isShowError ? <span style={{ color: "red", display: "flex" }}>{this.state.shownText}</span> : null}
            </div>
          </div>
        </div>
      </div >
    );
  }
}
