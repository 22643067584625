import React from 'react';
import DataTables from '../../CommonDataTable/DataTable';
import apiCalls from '../../../../config/apiCalls'
import store from '../../../App/store';

// config file
export default class crMatrix extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      type: 'crMatrix'
    };
  }

  static getDerivedStateFromProps(props, state) {
    let storeData = store.getState()
    let languageData = storeData && storeData.settingsData && storeData.settingsData.settings && storeData.settingsData.settings.languageTranslation ? storeData.settingsData.settings.languageTranslation : ""
    return { languageData: languageData };
  }



  componentDidMount = () => {
    //Screen permisions value can be edit,view, no view
  }

  getTableFields = () => {

    let data = [
      {
        "show": true,
        "textAlign": "left",
        "width": 100,
        "fieldType": 'Actions',
        "field": "Actions",
        "header": "Actions",
        "filter": false,
        "displayInSettings": true,
        "sortable": false
      },
      {
        "show": true,
        "textAlign": "left",
        "width": 100,
        "field": "Version",
        "mobile": false,
        "header": "Version",
        "filter": false,
        // "fieldType": 'Badge',
        "displayInSettings": true,
        "sortable": true
      },
      {
        "show": true,
        "textAlign": "left",
        "width": 100,
        "field": "VersionDate",
        "mobile": false,
        "header": "Version Date",
        "filter": false,
        "dateFormat": 'MM/DD/YYYY',
        "fieldType": 'Date',
        "displayInSettings": true,
        "sortable": true
      },
      {
        "show": true,
        "textAlign": "left",
        "width": 100,
        "field": "AdministeredBy",
        "mobile": false,
        "header": "Created By",
        "filter": false,
        // "fieldType": 'Badge',
        "displayInSettings": true,
        "sortable": true
      },


    ]
    return data;
  };



  getFormFields = () => {

    return
  }



  submit = async (item) => {

  }

  render() {
    return (
      <span>
        <DataTables
          onRef={(ref) => (this.datatableref = ref)}
          getTableFields={this.getTableFields}
          formFields={this.getFormFields}
          exportRequried={false}
          printRequried={false}
          addRequried={true}
          editRequired={true}
          deleteRequired={false}
          viewRequired={true}
          settingsRequired={false}
          filterRequired={true}
          gridRequried={false}
          sample={false}
          globalSearchFieldName='user'
          globalSearch={'Version'}
          type='crMatrix'
          displayright={true}
          icon='layers'
          routeTo='crmatrix'
          displayViewOfForm='modal'
          apiResponseKey='crMatrixVersionsList'
          className={true}
          apiUrl={apiCalls.versionList}
          entityType='employee'
        />

      </span>
    );
  }
}