import React from 'react';
import { MultiSelect } from 'primereact/multiselect';
import PropTypes from 'prop-types';

export default class MultiSelectDropDown extends React.Component {
    static propTypes = {
        onChange: PropTypes.func,
        disabled: PropTypes.bool,
        meta: PropTypes.shape({
            touched: PropTypes.bool,
            error: PropTypes.string,

        })
    };

    onChange = (event) => {
        if (this.props.input) {
            const { onChange } = this.props.input;
            onChange(event.value, this.props.name);
        }
    }
    render() {
        const {
            filterElement, input, filter, disabled,placeholder
        } = this.props;
        const { touched, error } = this.props.meta;
        return (
            <div className="date-picker">
                <span className="p-fluid">
                    <MultiSelect
                        style={{ minWidth: '15%', height: 30,lineHeight:1 }}
                        appendTo={document.body}
                        value={(input && input.value) ? input.value : null}
                        options={filterElement}
                        filter={filter}
                        onChange={(e) => this.onChange(e)}
                        placeholder={placeholder}
                        disabled={disabled}
                    />
                </span>
                <div className="" style={{ height: '10px' }}>
                    {touched && error && !this.props.values && <span className="form__form-group-error">{error}</span>}
                </div>
            </div>
        )
    }
}