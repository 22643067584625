import config from '../../../../config/config';
import configMessages from '../../../../config/configMessages';
import PaginatorComponent from '../../../Cruds/CommonDataTable/PaginatorComponent';
import { DataTable } from 'primereact/datatable';
import { PageState } from 'primereact/paginator';
import React, { useCallback, useEffect, useState } from 'react';
import { Column, ColumnProps } from 'primereact/column';
import { makeStyles } from "@material-ui/styles";
import Loader from '../../../App/Loader';
import { ToolBar } from './ToolBar';
import dateFormats from '../../../UI/FormatDate/formatDate';
import { Dropdown } from 'primereact/dropdown';


import { Card, CardBody } from 'reactstrap';
import { DropdownProps } from 'primereact/dropdown';
import { any } from 'prop-types';

export type PaginationInfo = {
    page: number;
    per_page: number;
    total_pages: number;
    total_count: number;
};

type OpraDataTableToolbarProps = {
    type: string;
    inputSearchOnClick: (value: string | undefined, selectValue?: any, ofcType?: string | undefined) => void;
    getCompaniesFromApiFilter: (filterArray: any[], url?: string | undefined) => void
    csvFileName: string;
    icon?: string;
    dropdownSelect?: DropdownProps;
    getAllRowsCallback?: () => Promise<any[]>;
    gettingDataProgress?: number;
};

export type OpraDataTableColumn = ColumnProps & {
    width?: number;
    textAlign?: string;
    mobile?: boolean;
    show?: boolean;
    textCapitalize?: boolean;
    location?: number; //if used to reorder a column's position, should be 1 less than the actual index location in which the column should lay, starting at index 0. if you want it at 3rd index, location needs to be 2
};
let filterArray: any[] = []
let OfcFilterArray: any[] = []

type OpraDataTableProps = {
    rows: any[] | undefined;
    removeHeaders: string[];
    filterThroughApi: boolean;
    needToolTip: boolean;
    freezeRow: String;
    freezeRowJDX: String;
    errorMessage: string | undefined;
    loading: boolean;
    paginationInfo: PaginationInfo | undefined;
    first: number;
    paginationPageChange: (pageState: PageState) => void;
    emptyGridMessage: string;
    objectArraysToDefineHeadersFor: string[];
    toolbarProps: OpraDataTableToolbarProps;
    customGridColumns?: Map<string, OpraDataTableColumn>;
};

export const OpraDataTable = ({
    rows,
    removeHeaders,
    filterThroughApi,
    needToolTip,
    freezeRow,
    freezeRowJDX,
    errorMessage,
    loading,
    paginationInfo,
    first,
    paginationPageChange,
    emptyGridMessage,
    objectArraysToDefineHeadersFor,
    toolbarProps,
    customGridColumns,
}: OpraDataTableProps) => {

    const useStyles = makeStyles(theme => ({
        root: {
            "& div.react-grid-Container": {
                backgroundColor: "white",
            }
        }
    }));
    const styles = useStyles();

    const [exportData, setExportData] = useState<any[]>();
    const [dataForDisplay, setDataForDisplay] = useState<any[]>();
    const [columns, setColumns] = useState<OpraDataTableColumn[]>();
    const [filterField, setfilterField] = useState<undefined | string>();


    /**
     * Takes am object in the data and builds the grid headers based off of that object's properties.
     */
    const buildGridColumns = useCallback((o: object): OpraDataTableColumn[] => {

        if (o === undefined) {
            return [];
        }

        let cols = Object.getOwnPropertyNames(o).map((propertyName, index) => {
            let customColumn = customGridColumns?.get(propertyName);
            let column: OpraDataTableColumn = {
                field: propertyName,
                // header: customColumn?.header ? customColumn.header : propertyName,
                header: customColumn?.header ? customColumn.header : customColumn && customColumn.location === 2 ? "JDX" : customColumn && customColumn.location === 5 ? "Inactive/Active" : customColumn && customColumn.location === 8 ? "Dissolv Dt" : customColumn && customColumn.location === 9 ? "Incorp Dt" : customColumn && customColumn.location === 4 ? "Listed Status" : customColumn && customColumn.location === 3 ? "Company No." : propertyName,
                textAlign: customColumn?.textAlign ? customColumn.textAlign : "left",
                mobile: customColumn?.mobile ? customColumn.mobile : true,
                width: customColumn?.width ? customColumn.width : propertyName.length * 3.5 + 110,
                filter: customColumn?.filter ? customColumn.filter : false,
                sortable: customColumn?.sortable ? customColumn.sortable : true,
                show: customColumn?.show ? customColumn.show : false,
                textCapitalize: customColumn?.textCapitalize ? customColumn.textCapitalize : false,
                location: customColumn?.location ? customColumn.location : index,
                sortField: propertyName,
                // filterPlaceholder: ""
                filterPlaceholder: customColumn?.filterPlaceholder ? customColumn.filterPlaceholder : customColumn && customColumn.location === 6 ? "" : "",

            };
            return column
        });


        let adjustedCols: string[] = [];



        //reorder columns to custom ordering, if any.
        cols.sort((a, b) => {
            if (a.location && b.location) {
                let difference = a.location - b.location;
                if (a.location === b.location && b.field && !adjustedCols.includes(b.field)) { //if same location, increase location of other column that has its spot being taken
                    b.location = b.location + 1;
                    adjustedCols.push(b.field);
                }
                return difference;
            }
            return -1;
        });
        return cols;
    }, [customGridColumns]);

    function linkOpenCorporate(name: string) {
        return window.open(name)
    }
    /**
     * Takes the data for the grid and puts it in a flattened format. Flattens all arrays, objects, and object arrays within the data set into a single object array as the new data set.
     */
    const prepDataForGrid = useCallback((data: any[] | undefined, objectArraysPropertyNames?: string[]) => {
        if (data === undefined) {
            return [];
        }

        //will store the object arrays in the object so we can reference their properties for objects who dont have the object arrays.
        let largestObjectArraysInData = new Map<string, any[]>();

        //get largest object arrays in data to help build headers to the appropriate amount of object in the array and their properties.
        if (objectArraysPropertyNames) {
            objectArraysPropertyNames.forEach((pn) => {
                data.forEach((d) => {
                    let largestObjectArray = largestObjectArraysInData.get(pn);
                    if (Object.prototype.toString.call(d[pn]) === '[object Array]' && ((largestObjectArray && largestObjectArray.length < d[pn].length) || largestObjectArray === undefined)) {
                        largestObjectArraysInData.set(pn, d[pn]);
                    }
                });
            });
        }

        //returns the data where the objects are flattened out. So any objects in the data that contained objects or object arrays, will now be represented in the single data object as properties.
        return data.map((d) => {
            let propertyNames = Object.getOwnPropertyNames(d);
            let flattenedObject: any = {};
            //go through each property name of the data object to find which properties are objects, object arrays, and any other type we need to handle differently.
            propertyNames.forEach((pn) => {
                let propertyValue = d[pn];

                if (Object.prototype.toString.call(propertyValue) === '[object Object]') {
                    //handle if found object. will flatten out to add the properties: Source_publisher, Source_url ... if the object found was say a 'Source object', to the data object
                    let pvPropertynames = Object.getOwnPropertyNames(propertyValue);
                    pvPropertynames.forEach((pvpn) => {
                        flattenedObject[pn + "_" + pvpn] = propertyValue[pvpn];
                    });
                }
                else if (Object.prototype.toString.call(propertyValue) === '[object Array]') {
                    //handle if array contains objects, flatten it out to include each property of array as property in flattenedObject. 
                    //IE: if multiple previousNames, will be previousName1_company_name and previousName2_company_name for the company_name property of PreviousName
                    if (Object.prototype.toString.call(propertyValue[0]) === '[object Object]') {
                        propertyValue.forEach((pv: any, index: number) => {
                            let oPropertyNames = Object.getOwnPropertyNames(pv);
                            oPropertyNames.forEach((opn) => {
                                if (Object.prototype.toString.call(pv[opn]) === '[object Object]') {
                                    let wrapperObj = pv[opn]
                                    let wrapperPropertyNames = Object.getOwnPropertyNames(wrapperObj);
                                    wrapperPropertyNames.forEach((wpn) => {
                                        flattenedObject[pn + (index + 1) + "_" + wpn] = wrapperObj[wpn];
                                    });
                                }
                                else {
                                    flattenedObject[pn + (index + 1) + "_" + opn] = pv[opn];
                                }
                            });
                        });
                    }
                    else if (Object.prototype.toString.call(propertyValue[0]) === '[object String]' || Object.prototype.toString.call(propertyValue[0]) === '[object Number]') {
                        //make any string or number arrays a single string.
                        flattenedObject[pn] = propertyValue.join("|");
                    }
                    else if (objectArraysPropertyNames?.includes(pn)) {
                        //if a null array, but the data has a row else where that does have values in the array, then add the headers to the row with the null array.
                        let objectArray = largestObjectArraysInData.get(pn);
                        if (objectArray) {
                            objectArray.forEach((o, index) => {
                                let oPropertyNames = Object.getOwnPropertyNames(o);
                                oPropertyNames.forEach((opn) => {
                                    if (Object.prototype.toString.call(o[opn]) === '[object Object]') {
                                        let wrapperObj = o[opn]
                                        let wrapperPropertyNames = Object.getOwnPropertyNames(wrapperObj);
                                        wrapperPropertyNames.forEach((wpn) => {
                                            flattenedObject[pn + (index + 1) + "_" + wpn] = null;
                                        });
                                    }
                                    else {
                                        flattenedObject[pn + (index + 1) + "_" + opn] = null;
                                    }
                                });
                            });
                        }
                    }
                }
                else if (Object.prototype.toString.call(propertyValue) === '[object Boolean]') {
                    //put boolean value in string form
                    flattenedObject[pn] = "" + propertyValue;
                }
                else if (objectArraysPropertyNames?.includes(pn)) {
                    //cover edge case incase the property that is suppose to be an array is null.
                    //if a null array, but the data has a row that does have values in the array, then add the headers to the row with the null array.
                    let objectArray = largestObjectArraysInData.get(pn);
                    if (objectArray) {
                        objectArray.forEach((o, index) => {
                            let oPropertyNames = Object.getOwnPropertyNames(o);
                            oPropertyNames.forEach((opn) => {
                                if (Object.prototype.toString.call(o[opn]) === '[object Object]') {
                                    let wrapperObj = o[opn]
                                    let wrapperPropertyNames = Object.getOwnPropertyNames(wrapperObj);
                                    wrapperPropertyNames.forEach((wpn) => {
                                        flattenedObject[pn + (index + 1) + "_" + wpn] = null;
                                    });
                                }
                                else {
                                    flattenedObject[pn + (index + 1) + "_" + opn] = null;
                                }
                            });
                        });
                    }
                }
                else {
                    flattenedObject[pn] = propertyValue;
                }
            });
            return flattenedObject;
        });
    }, []);

    /**
     * Prepares the headers for the export csv file.
     */
    const getHeadersForExport = useCallback((exportDataReference?: object) => {
        if (exportDataReference) {
            let propertyNames = Object.getOwnPropertyNames(exportDataReference);
            return propertyNames.map((pn) => {
                return {
                    label: pn,
                    key: pn
                };
            });
        }
        else if (exportData && exportData[0]) {
            let propertyNames = Object.getOwnPropertyNames(exportData[0]);
            return propertyNames.map((pn) => {
                return {
                    label: pn,
                    key: pn
                };
            });
        }
    }, [exportData]);

    const makeExportData = useCallback((dataForGrid: any[]) => {
        return dataForGrid.map((d) => {
            let propertyNames = Object.getOwnPropertyNames(d);
            let newData: any = {};
            propertyNames.forEach((pn) => {
                if (typeof d[pn] === 'string') {
                    newData[pn] = d[pn].replace(/"/g, '""');
                }
                else if (typeof d[pn] === 'boolean') {
                    newData[pn] = "" + d[pn];
                }
                else {
                    newData[pn] = d[pn];
                }
            });
            return newData;
        });
    }, []);



    const setSearchValues = (e: any) => {
        let flag: any
        let ofcTypeFilter = localStorage.getItem('officeTypeFilter');
        if (ofcTypeFilter === "Company") {
            if (e && e.target && (e.target.value == "ACTIVE" || e.target.value == "INACTIVE" || e.target.value == "ALL")) {
                let index = filterArray.findIndex(filterarr => filterarr.field === "inactive")
                if (index != -1) {
                    filterArray.splice(index, 1)
                }
                if (e.target.value == "ACTIVE") {
                    setfilterField("ACTIVE")
                    filterArray.push({ field: "inactive", value: false })
                } else if (e.target.value == "INACTIVE") {
                    setfilterField("INACTIVE")
                    filterArray.push({ field: "inactive", value: true })
                } else {
                    setfilterField("ALL")
                }
            } else {
                let searchfieldArr = Object.keys(e.filters)

                if (searchfieldArr[0] == "branch") {
                    if ("foreign".includes(e.filters[Object.keys(e.filters)[0]].value.toLowerCase())) {
                        e.filters[Object.keys(e.filters)[0]].value = "F"
                    } else {
                        e.filters[Object.keys(e.filters)[0]].value = "null"
                    }
                }
                if (filterArray.length > 0) {
                    let index = filterArray.findIndex(filterarr => filterarr.field === searchfieldArr[0])
                    if (index != -1) {
                        if (e.filters[Object.keys(e.filters)[0]].value == "") {
                            filterArray.splice(index, 1)
                        } else {
                            filterArray[index].value = e && e.filters && Object.keys(e.filters).length > 0 && e.filters[Object.keys(e.filters)[0]].value ? e.filters[Object.keys(e.filters)[0]].value : ""
                        }
                    } else {
                        filterArray.push({ field: searchfieldArr[0], value: e && e.filters && Object.keys(e.filters).length > 0 && e.filters[Object.keys(e.filters)[0]].value ? e.filters[Object.keys(e.filters)[0]].value : "" })
                    }
                } else {
                    filterArray.push({ field: searchfieldArr[0], value: e && e.filters && Object.keys(e.filters).length > 0 && e.filters[Object.keys(e.filters)[0]].value ? e.filters[Object.keys(e.filters)[0]].value : "" })
                }
            }
            return toolbarProps.getCompaniesFromApiFilter(filterArray)

        } else if (ofcTypeFilter === "Office") {
            if (e && e.target && (e.target.value == "ACTIVE" || e.target.value == "INACTIVE" || e.target.value == "ALL")) {
                let index = OfcFilterArray.findIndex(filterarr => filterarr.field === "inactive")
                if (index != -1) {
                    OfcFilterArray.splice(index, 1)
                }
                if (e.target.value == "ACTIVE") {
                    setfilterField("ACTIVE")
                    OfcFilterArray.push({ field: "inactive", value: false })
                } else if (e.target.value == "INACTIVE") {
                    setfilterField("INACTIVE")
                    OfcFilterArray.push({ field: "inactive", value: true })
                } else {
                    setfilterField("ALL")
                }
            } else {
                let searchfieldArr = Object.keys(e.filters)

                if (OfcFilterArray.length > 0) {
                    let companyNameIndex, companyNumberIndex, companyUrl, companyJdxIndex
                    let index = OfcFilterArray.findIndex(filterarr => filterarr.field === searchfieldArr[0])
                    if (searchfieldArr[0] === "company_name") {
                        companyNameIndex = OfcFilterArray.findIndex(filterarr => filterarr.field === "company.name")
                    }
                    if (searchfieldArr[0] === "company_company_number") {
                        companyNumberIndex = OfcFilterArray.findIndex(filterarr => filterarr.field === "company.company_number")
                    }
                    if (searchfieldArr[0] === "company_opencorporates_url") {
                        companyUrl = OfcFilterArray.findIndex(filterarr => filterarr.field === "company.opencorporates_url")
                    }
                    if (searchfieldArr[0] === "company_jurisdiction_code") {
                        companyJdxIndex = OfcFilterArray.findIndex(filterarr => filterarr.field === "company.jurisdiction_code")
                    }
                    if (index != -1 || companyNameIndex != -1 || companyNumberIndex != -1 || companyUrl != 1 || companyJdxIndex != 1) {
                        if (index != -1) {
                            if (e.filters[Object.keys(e.filters)[0]].value == "") {
                                OfcFilterArray.splice(index, 1)
                            } else {
                                OfcFilterArray[index].value = e && e.filters && Object.keys(e.filters).length > 0 && e.filters[Object.keys(e.filters)[0]].value ? e.filters[Object.keys(e.filters)[0]].value : ""
                            }
                        } else if (companyNameIndex != -1 && companyNameIndex != undefined) {
                            if (e.filters[Object.keys(e.filters)[0]].value == "") {
                                OfcFilterArray.splice(companyNameIndex, 1)
                            } else {
                                OfcFilterArray[companyNameIndex].value = e && e.filters && Object.keys(e.filters).length > 0 && e.filters[Object.keys(e.filters)[0]].value ? e.filters[Object.keys(e.filters)[0]].value : ""
                            }
                        } else if (companyNumberIndex != -1 && companyNumberIndex != undefined) {
                            if (e.filters[Object.keys(e.filters)[0]].value == "") {
                                OfcFilterArray.splice(companyNumberIndex, 1)
                            } else {
                                OfcFilterArray[companyNumberIndex].value = e && e.filters && Object.keys(e.filters).length > 0 && e.filters[Object.keys(e.filters)[0]].value ? e.filters[Object.keys(e.filters)[0]].value : ""
                            }

                        } else if (companyUrl != -1 && companyUrl != undefined) {
                            if (e.filters[Object.keys(e.filters)[0]].value == "") {
                                OfcFilterArray.splice(companyUrl, 1)
                            } else {
                                OfcFilterArray[companyUrl].value = e && e.filters && Object.keys(e.filters).length > 0 && e.filters[Object.keys(e.filters)[0]].value ? e.filters[Object.keys(e.filters)[0]].value : ""
                            }

                        } else if (companyJdxIndex != -1 && companyJdxIndex != undefined) {
                            if (e.filters[Object.keys(e.filters)[0]].value == "") {
                                OfcFilterArray.splice(companyJdxIndex, 1)
                            } else {
                                OfcFilterArray[companyJdxIndex].value = e && e.filters && Object.keys(e.filters).length > 0 && e.filters[Object.keys(e.filters)[0]].value ? e.filters[Object.keys(e.filters)[0]].value : ""
                            }

                        }
                        else {
                            if (searchfieldArr[0] === "company_name") {
                                OfcFilterArray.push({ field: "company.name", value: e && e.filters && Object.keys(e.filters).length > 0 && e.filters[Object.keys(e.filters)[0]].value ? e.filters[Object.keys(e.filters)[0]].value : "" })
                            } else if (searchfieldArr[0] === "company_company_number") {
                                OfcFilterArray.push({ field: "company.company_number", value: e && e.filters && Object.keys(e.filters).length > 0 && e.filters[Object.keys(e.filters)[0]].value ? e.filters[Object.keys(e.filters)[0]].value : "" })
                            } else if (searchfieldArr[0] === "company_opencorporates_url") {
                                OfcFilterArray.push({ field: "company.opencorporates_url", value: e && e.filters && Object.keys(e.filters).length > 0 && e.filters[Object.keys(e.filters)[0]].value ? e.filters[Object.keys(e.filters)[0]].value : "" })
                            } else if (searchfieldArr[0] === "company_jurisdiction_code") {
                                OfcFilterArray.push({ field: "company.jurisdiction_code", value: e && e.filters && Object.keys(e.filters).length > 0 && e.filters[Object.keys(e.filters)[0]].value ? e.filters[Object.keys(e.filters)[0]].value : "" })
                            } else {
                                OfcFilterArray.push({ field: searchfieldArr[0], value: e && e.filters && Object.keys(e.filters).length > 0 && e.filters[Object.keys(e.filters)[0]].value ? e.filters[Object.keys(e.filters)[0]].value : "" })
                            }
                        }
                    } else {
                        if (searchfieldArr[0] === "company_name") {
                            OfcFilterArray.push({ field: "company.name", value: e && e.filters && Object.keys(e.filters).length > 0 && e.filters[Object.keys(e.filters)[0]].value ? e.filters[Object.keys(e.filters)[0]].value : "" })
                        } else if (searchfieldArr[0] === "company_company_number") {
                            OfcFilterArray.push({ field: "company.company_number", value: e && e.filters && Object.keys(e.filters).length > 0 && e.filters[Object.keys(e.filters)[0]].value ? e.filters[Object.keys(e.filters)[0]].value : "" })
                        } else if (searchfieldArr[0] === "company_opencorporates_url") {
                            OfcFilterArray.push({ field: "company.opencorporates_url", value: e && e.filters && Object.keys(e.filters).length > 0 && e.filters[Object.keys(e.filters)[0]].value ? e.filters[Object.keys(e.filters)[0]].value : "" })
                        } else if (searchfieldArr[0] === "company_jurisdiction_code") {
                            OfcFilterArray.push({ field: "company.jurisdiction_code", value: e && e.filters && Object.keys(e.filters).length > 0 && e.filters[Object.keys(e.filters)[0]].value ? e.filters[Object.keys(e.filters)[0]].value : "" })
                        }
                        else {
                            OfcFilterArray.push({ field: searchfieldArr[0], value: e && e.filters && Object.keys(e.filters).length > 0 && e.filters[Object.keys(e.filters)[0]].value ? e.filters[Object.keys(e.filters)[0]].value : "" })

                        }
                    }
                } else {
                    if (searchfieldArr[0] === "company_name") {
                        OfcFilterArray.push({ field: "company.name", value: e && e.filters && Object.keys(e.filters).length > 0 && e.filters[Object.keys(e.filters)[0]].value ? e.filters[Object.keys(e.filters)[0]].value : "" })
                    } else if (searchfieldArr[0] === "company_company_number") {
                        OfcFilterArray.push({ field: "company.company_number", value: e && e.filters && Object.keys(e.filters).length > 0 && e.filters[Object.keys(e.filters)[0]].value ? e.filters[Object.keys(e.filters)[0]].value : "" })
                    }
                    else if (searchfieldArr[0] === "company_opencorporates_url") {
                        OfcFilterArray.push({ field: "company.opencorporates_url", value: e && e.filters && Object.keys(e.filters).length > 0 && e.filters[Object.keys(e.filters)[0]].value ? e.filters[Object.keys(e.filters)[0]].value : "" })
                    } else if (searchfieldArr[0] === "company_jurisdiction_code") {
                        OfcFilterArray.push({ field: "company.jurisdiction_code", value: e && e.filters && Object.keys(e.filters).length > 0 && e.filters[Object.keys(e.filters)[0]].value ? e.filters[Object.keys(e.filters)[0]].value : "" })
                    }
                    else {
                        OfcFilterArray.push({ field: searchfieldArr[0], value: e && e.filters && Object.keys(e.filters).length > 0 && e.filters[Object.keys(e.filters)[0]].value ? e.filters[Object.keys(e.filters)[0]].value : "" })
                    }
                }
            }
            return toolbarProps.getCompaniesFromApiFilter(OfcFilterArray)


        }

    }


    const changeFieldValues = (item: any, column: any) => {
        return (
            <div>
                {column.header === "incorporation date" || column.header === "dissolution date" || column.header === "start date" ?
                    <div>
                        {dateFormats.formatDate(item[column.field], config.dateDBFormat)}
                    </div>
                    : <span data-toggle="tool-tip"
                        title={item[column.field]}
                    >
                        {item[column.field]}
                    </ span >}

            </div>
        )

    }

    /**
     * When rows change, take the data set and prepare it for the grid by re formatting the data set into a form for the grid and getting the columns built for the grid.
     * Also will set up the data in a format for export files.
     */
    useEffect(() => {

        if (rows) {
            let dataForGrid = prepDataForGrid(rows, objectArraysToDefineHeadersFor);
            setDataForDisplay(dataForGrid);
            setColumns(buildGridColumns(dataForGrid[0]));
            // elem.style.color = newColor;
            //setup data for exporting. Needs to be double quoted so commas in strings dont get seperated into wrong header columns in csv file.
            setExportData(makeExportData(dataForGrid));
        }

    }, [rows, prepDataForGrid, buildGridColumns]); // eslint-disable-line

    const exportAllData = useCallback((dataToExport: any[]) => {
        dataToExport = prepDataForGrid(dataToExport, objectArraysToDefineHeadersFor);
        return makeExportData(dataToExport);
    }, [makeExportData, objectArraysToDefineHeadersFor, prepDataForGrid]);

    return (
        <>

            <Card style={{ paddingBottom: "10px", paddingTop: "15px", margin: 0 }} className={"customCard cardForListMargin m-0 customColorRows"}>
                <CardBody className='tableCardBody' style={{ borderRadius: "0px" }}>
                    <ToolBar
                        errorMessage={errorMessage}
                        type={toolbarProps.type}
                        inputSearchOnClick={toolbarProps.inputSearchOnClick}
                        exportData={exportData}
                        getHeadersForExport={getHeadersForExport}
                        csvFileName={toolbarProps.csvFileName}
                        icon={toolbarProps.icon}
                        dropdownSelectProps={toolbarProps.dropdownSelect}
                        getAllRowsCallback={toolbarProps.getAllRowsCallback}
                        exportAllDataCallback={exportAllData}
                        gettingDataProgress={toolbarProps.gettingDataProgress}
                    />
                    <br />

                    <Loader loader={loading} />
                    <span className={styles.root}>

                        {dataForDisplay && dataForDisplay.length > 0 ? dataForDisplay.map((data, index) => {
                            if (data && data.opencorporates_url) {
                                data.opencorporates_url = <a target="_blank" href={data.opencorporates_url}>{data.opencorporates_url}</a>
                            }
                            if (data && data.company_opencorporates_url) {
                                data.company_opencorporates_url = <a target="_blank" href={data.company_opencorporates_url}>{data.company_opencorporates_url}</a>
                            }

                        })
                            : dataForDisplay && dataForDisplay.length === 0 ? filterArray = OfcFilterArray = []
                                : null
                        }
                        < DataTable
                            value={dataForDisplay}
                            scrollable
                            frozenWidth={columns && columns.length > 0 && (freezeRow || freezeRowJDX) ? "600px" : ""}
                            scrollHeight="700px"
                            resizableColumns={true}
                            onFilter={filterThroughApi ? setSearchValues : undefined}
                            columnResizeMode="expand"
                            totalRecords={dataForDisplay ? dataForDisplay.length : 0}
                            emptyMessage={errorMessage ? errorMessage : configMessages.noRecords}
                            loading={loading}
                        >

                            {columns && columns.length > 0 ? columns.map((c, index) => {
                                if (removeHeaders.indexOf(c.header) > -1) {
                                } else {
                                    return <Column
                                        field={c.field}
                                        header={c.header == "opencorporates_url" ? "Open Corporates Url" : c.header == "company_opencorporates_url" ? "Company Open Corporates Url" : c.header == "branch" ? "Domestic/Foreign" : c.header == "date_of_birth" ? "Date Of Birth" : c.header == "company_jurisdiction_code" ? "Company JDX" : c.header == "previous_names1_company_name" ? "Previous Names Company Name" : c.header == "company_company_number" ? "Company Number" : c.header == "registered_address_in_full" ? "Registered Address" : c.header.includes("_") ? c.header.replace("_", " ") : c.header}
                                        headerStyle={{
                                            padding: "6px 15px", fontWeight: 500, width: c.width, fontSize: 13,
                                            color: config.blackColor, backgroundColor: config.templateColor, textAlign: 'left', height: freezeRow && c.header === freezeRow ? "83.5px" : "0px"
                                        }}
                                        bodyStyle={{
                                            textOverflow: 'ellipsis',
                                            overflow: 'hidden',
                                            whiteSpace: 'nowrap',
                                            fontSize: 14,
                                            textAlign: "left",
                                            verticalAlign: "inherit"
                                        }}
                                        key={c.field ? c.field + index : index}
                                        sortable={c.sortable}
                                        sortField={c.sortField}
                                        frozen={(freezeRow || freezeRowJDX) && (c.header === freezeRow || c.header === freezeRowJDX) ? true : false}
                                        filter={c.header === "registered_address_in_full" || c.header === "registry_url" || c.header === "opencorporates_url" ? false : true}
                                        // filter={c.header == "JDX" || c.header === "Inactive/Active" || c.header === "registry_url" || c.header === "opencorporates_url" ? false : true}
                                        filterElement={c && (c.header == "Inactive/Active") ?
                                            < div style={{ height: 26 }}>
                                                <Dropdown
                                                    style={{ minWidth: '10%', lineHeight: 1.1 }}
                                                    appendTo={document.body}
                                                    name={"name"}
                                                    value={filterField ? filterField : "ALL"}
                                                    options={[{ label: "Active", value: "ACTIVE" }, { label: "Inactive", value: "INACTIVE" }, { label: "All", value: "ALL" }]}
                                                    onChange={(e) => setSearchValues(e)}
                                                />
                                            </div>
                                            : null
                                        }
                                        body={needToolTip ? changeFieldValues : null}
                                        filterPlaceholder={c.filterPlaceholder}

                                    />
                                }

                            }) : (
                                < Column
                                    header={emptyGridMessage}
                                    headerStyle={{
                                        padding: "6px 15px", fontWeight: 500, fontSize: 13,
                                        color: config.blackColor, backgroundColor: config.templateColor, textAlign: 'left'
                                    }}

                                    key={"No Data :(" + 1}
                                />
                            )}

                        </DataTable>
                        <PaginatorComponent
                            totalRecords={paginationInfo ? paginationInfo.total_count : 0}
                            first={first}
                            rows={dataForDisplay ? dataForDisplay.length : 0}
                            isWeb={true}
                            enablePerPageSelect={false}
                            onPageChange={(event: PageState) => paginationPageChange(event)}
                        />
                    </span>
                </CardBody>
            </Card>
        </>
    );
};

