import React, { PureComponent } from 'react';
import {
    Col, Row, Button, Modal, ModalHeader, ModalBody,
} from 'reactstrap';
import { Link } from 'react-router-dom';
import { Redirect } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import Login from '../../../../src/containers/Landing/LogIn/components/ButtonForSessionExpire'

class SessionExpiryModal extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            SessionExpired: false,
            date: new Date(),
            pen: false,
            count: 60,
            SessionExpired1: false,
            Sopen: this.props.SOpen

        };
    }


    componentDidMount = async () => {

        // let spen = await this.props.adjustForm();
        // await this.setState({ pen: spen })
        // this.timerID = setInterval(
        //     () => this.tick(),
        //     1000
        // );

    }

    componentWillUnmount() {
        clearInterval(this.timerID);
    }
    redirectMethod = async () => {

        await this.setState({ SessionExpired: true })

    }
    logoutYes = async () => {
        localStorage.removeItem('loginBody');
        localStorage.removeItem('loginCredentials');
        localStorage.removeItem("sessionexpired")

        sessionStorage.clear()
        await this.setState({ SessionExpired1: true, date: '', count: 60 })


    }
    closeSModal = async () => {
        await this.setState({ SessionExpired1: false, date: '', count: 60, pen: false })
        this.props.closeSModal();
    }
    closeModal = async () => {
        await this.setState({ Sopen: false })
    }
    logoutfunc = () => {
        localStorage.clear();
        sessionStorage.clear()
        this.setState({ SessionExpired: true, })
    }
    render() {
        const { t } = this.props;
        return (
            <div>

                <Modal isOpen={this.state.Sopen} centered={true} fade={false} className='session modal-dialog-centered'>

                    {/* {!this.state.SessionExpired ? <ModalHeader className="bold-text" style={{ backgroundColor: '#0e4768', color: 'white', textAlign: 'center' }}  >
                        {t("You're being timed out due to inactivity")}
                    </ModalHeader> : null} */}
                    <ModalBody className='content__modal' style={{ height: "175px", left: "4px" }}>
                        {/* <h3 className="py-3">{t('We’re Sorry')} </h3> */}
                        <h3 className="py-3">{'Your session has expired.'}</h3>
                        {/* <h1 >{this.state.date ? this.state.date.toLocaleTimeString() : null}</h1> */}

                        <Login closeModal={this.closeModal}></Login>
                        {/* <Row className="justify-content-center">
                            <Link className="btn btn-primary" to="/log_in">{t('Login again')}</Link>
                        </Row> */}
                    </ModalBody>
                </Modal>
            </div>
        )
    }
}
export default withTranslation('common')(SessionExpiryModal);
