import React from 'react';
import { Container } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import DsQueue from './components/DsQueue';
const dsQueue = ({ t }) => (
  <Container >
    <DsQueue />
  </Container>
);

dsQueue.propTypes = {
  t: PropTypes.func.isRequired,
};

export default withTranslation('common')(dsQueue);
