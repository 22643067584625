import React from 'react';
import store from '../../../App/store';
import apiCalls from '../../../../config/apiCalls'
import DataTables from '../../CommonDataTable/DataTable';
import Loader from '../../../App/Loader';

// config file
export default class PropertyAndClaimStatus extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      type: 'Property And Claim Status',
    };
  }

  static getDerivedStateFromProps(props, state) {
    let storeData = store.getState()
    let languageData = storeData && storeData.settingsData && storeData.settingsData.settings && storeData.settingsData.settings.languageTranslation ? storeData.settingsData.settings.languageTranslation : ""
    return { languageData: languageData };
  }

  componentDidMount = () => {
    //Screen permisions value can be edit,view, no view
    // let screenPermissions = RolePermissions.screenPermissions('Employees');
  }

  //  Table Column Fields
  getTableFields = () => {
    let data = [
      // {
      //   "show": true,
      //   "textAlign": "center",
      //   "width": 6,
      //   "fieldType": "expander",
      //   "header": "",
      //   "filter": false,
      //   "sortable": false,
      //   "placeholder": "Search"
      // },
      {
        "show": true,
        textAlign: 'left',
        width: 40,
        field: 'status',
        fieldType: 'BoolText',
        activeField: "active",
        "mobile": true,
        header: 'Status',
        filter: true,
        "displayInSettings": true,
        "displayInFilter": true,
        sortable: true,
      },
      {
        "show": true,
        textAlign: 'left',
        width: 40,
        field: 'reason',
        // "fieldType": 'number',
        "mobile": true,
        header: 'Reason',
        filter: true,
        "displayInSettings": true,
        "displayInFilter": true,
        sortable: true,
      },
      {
        "show": true,
        textAlign: 'left',
        width: 50,
        field: 'stageSequence',
        // fieldType: 'BoolText',
        "mobile": true,
        header: 'Stage Sequence',
        filter: true,
        "filterType": "num",
        "displayInSettings": true,
        "displayInFilter": true,
        sortable: true,
      },
      {
        "show": true,
        textAlign: 'left',
        width: 50,
        field: 'statusSequence',
        // fieldType: 'BoolText',
        "mobile": true,
        header: 'Status Sequence',
        filter: true,
        "filterType": "num",
        "displayInSettings": true,
        "displayInFilter": true,
        sortable: true,
      }, {
        "show": true,
        textAlign: 'left',
        width: 50,
        field: 'reasonSequence',
        // fieldType: 'BoolText',
        "mobile": true,
        "filterType": "num",
        header: 'Reason Sequence',
        filter: true,
        "displayInSettings": true,
        "displayInFilter": true,
        sortable: true,
      }, {
        "show": true,
        textAlign: 'left',
        width: 50,
        field: 'propertyStatusFlag',
        fieldType: 'Boolean',
        "mobile": true,
        header: 'Property Status Flag',
        filter: false,
        "displayInSettings": true,
        "displayInFilter": true,
        sortable: true,
      }, {
        "show": true,
        textAlign: 'left',
        width: 50,
        field: 'propertyStatusUsage',
        "mobile": true,
        header: 'Property Status Usage',
        filter: true,
        "displayInSettings": true,
        "displayInFilter": true,
        sortable: true,
      }, {
        "show": true,
        textAlign: 'left',
        width: 50,
        field: 'isPropertyStatusSync',
        fieldType: 'Boolean',
        "mobile": true,
        header: 'Prop.Status Sync',
        filter: false,
        "displayInSettings": true,
        "displayInFilter": true,
        sortable: true,
      }, {
        "show": true,
        textAlign: 'left',
        width: 50,
        field: 'claimStatusFlag',
        fieldType: 'Boolean',
        "mobile": true,
        header: 'Claim Status Flag',
        filter: false,
        "displayInSettings": true,
        "displayInFilter": true,
        sortable: true,
      }, {
        "show": true,
        textAlign: 'left',
        width: 50,
        field: 'positiveValidation',
        fieldType: 'Boolean',
        "mobile": true,
        header: 'Positive Validation',
        filter: false,
        "displayInSettings": true,
        "displayInFilter": true,
        sortable: true,
      },
      {
        "show": true,
        textAlign: 'left',
        width: 50,
        field: 'availableToReMap',
        fieldType: 'Boolean',
        "mobile": true,
        header: 'Available To Re-Map',
        filter: false,
        "displayInSettings": true,
        "displayInFilter": true,
        sortable: true,
      },
      {
        "show": true,
        textAlign: 'left',
        width: 50,
        field: 'availableToFileFlag',
        fieldType: 'Boolean',
        "mobile": true,
        header: 'Available To File Flag',
        filter: false,
        "displayInSettings": true,
        "displayInFilter": true,
        sortable: true,
      }, {
        "show": true,
        textAlign: 'left',
        width: 50,
        field: 'hideInOpra2UI',
        fieldType: 'Boolean',
        "mobile": true,
        header: 'Hide in Opra2 UI',
        filter: false,
        "displayInSettings": true,
        "displayInFilter": true,
        sortable: true,
      },
      {
        "show": true,
        textAlign: 'left',
        width: 80,
        field: 'isPropertySyncIgnoredForProperties',
        fieldType: 'Boolean',
        "mobile": true,
        header: "Property Sync Ignored For Properties",
        filter: false,
        "displayInSettings": true,
        "displayInFilter": true,
        sortable: true,
      },
      {
        "show": true,
        textAlign: 'left',
        width: 80,
        field: 'statusDefinition',
        // fieldType: 'BoolText',
        "mobile": true,
        header: 'Status Definition',
        filter: true,
        "displayInSettings": true,
        "displayInFilter": true,
        sortable: true,
      }, {
        "show": true,
        textAlign: 'left',
        width: 80,
        field: 'statusReasonDefinition',
        // fieldType: 'BoolText',
        "mobile": true,
        header: 'Status Reason Definition',
        filter: true,
        "displayInSettings": true,
        "displayInFilter": true,
        sortable: true,
      }, {
        "show": true,
        textAlign: 'left',
        width: 80,
        field: 'oldPropertyStatus',
        // fieldType: 'BoolText',
        "mobile": true,
        header: 'Old Property Status',
        filter: true,
        "displayInSettings": true,
        "displayInFilter": true,
        sortable: true,
      }, {
        "show": true,
        textAlign: 'left',
        width: 80,
        field: 'oldPropertyStatusReason',
        // fieldType: 'BoolText',
        "mobile": true,
        header: 'Old Property Status Reason',
        filter: true,
        "displayInSettings": true,
        "displayInFilter": true,
        sortable: true,
      }, {
        "show": true,
        textAlign: 'left',
        width: 40,
        field: 'mctStatus',
        // fieldType: 'BoolText',
        "mobile": true,
        header: 'MCT Status',
        filter: true,
        "displayInSettings": true,
        "displayInFilter": true,
        sortable: true,
      }, {
        "show": true,
        textAlign: 'left',
        width: 40,
        field: 'assetTrackingStatus',
        // fieldType: 'BoolText',
        "mobile": true,
        header: 'Asset Tracking Status',
        filter: true,
        "displayInSettings": true,
        "displayInFilter": true,
        sortable: true,
      },

      {
        "show": true,
        "textAlign": "left",
        "width": 40,
        "fieldType": 'Actions',
        "field": "Actions",
        "header": "Actions",
        "filter": false,
        "displayInSettings": true,
        "sortable": false
      }
    ]
    return data;
  };

  // Form Input Fields
  getFormFields = () => {
    return ([

      {
        //'show': false,
        "value": "",
        "type": "text",
        "name": "status",
        "label": "Status",
        "id": "name",
        "placeholder": "Status",
        "required": true
      },
      {
        //'show': false,
        "value": "",
        "type": "text",
        "name": "reason",
        "label": "Reason",
        "id": "name",
        "placeholder": "Reason",
        "required": true
      }, {
        //'show': false,
        "value": "",
        "type": "number",
        "name": "stageSequence",
        "label": "Stage Sequence",
        "id": "name",
        "placeholder": "Stage Sequence",
        // "required": true
      },
      {
        //'show': false,
        "value": "",
        "type": "number",
        "name": "statusSequence",
        "label": "Status Sequence",
        "id": "name",
        "placeholder": "Property Sequence",
        // "required": true
      },
      {
        //'show': false,
        "value": "",
        "type": "number",
        "name": "reasonSequence",
        "label": "Reason Sequence",
        "id": "name",
        "placeholder": "Reason Sequence",
        // "required": true
      }, {
        //'show': false,
        "value": "",
        "type": "text",
        "name": "statusDefinition",
        "label": "Status Definition",
        "id": "name",
        "placeholder": "Status Definition",
        "required": true
      }, {
        //'show': false,
        "value": "",
        "type": "text",
        "name": "statusReasonDefinition",
        "label": "Status Reason Definition",
        "id": "name",
        "placeholder": "Status Reason Definition",
        "required": true
      }, {
        //'show': false,
        "value": "",
        "type": "text",
        "name": "oldPropertyStatus",
        "label": "Old Property Status",
        "id": "name",
        "placeholder": "Old Property Status",
        // "required": true
      }, {
        //'show': false,
        "value": "",
        "type": "text",
        "name": "oldPropertyStatusReason",
        "label": "Old Property Status Reason",
        "id": "name",
        "placeholder": "Old Property Status Reason",
        // "required": true
      }, {
        //'show': false,
        "value": "",
        "type": "text",
        "name": "mctStatus",
        "label": "MCT Status",
        "id": "name",
        "placeholder": "MCT Status",
        // "required": true
      },
      {
        //'show': false,
        "value": "",
        "type": "dropDown",
        "name": "assetTrackingStatus",
        "label": "Asset Tracking Status",
        "id": "name",
        "options": [{ label: "Abandoned", value: "Abandoned" }, { label: "Active", value: "Active" }, { label: "Paid", value: "Paid" }],
        "placeholder": "Asset Tracking Status",
        "required": false
      },
      {
        //'show': false,
        "value": "",
        "type": "dropDown",
        "name": "propertyStatusUsage",
        "label": "Property Status Usage",
        "id": "name",
        "options": [{ label: "Property Editor", value: "Property Editor" }, { label: "Deal Sizing Property Editor", value: "Deal Sizing Property Editor" }],
        "placeholder": "Property Status Usage",
        "required": false
      },
      {
        //'show': false,
        "value": "",
        "type": "switch",
        "name": "positiveValidation",
        "label": "Positive Validation",
        "id": "positiveValidation",
        "placeholder": "positiveValidation",
        // "required": true
      },
      {
        //'show': false,
        "value": "",
        "type": "switch",
        "name": "availableToReMap",
        "label": "Available To Re-Map",
        "id": "AvailableToReMap",
        "placeholder": "AvailableToReMap",
        // "required": true
      },

      {
        //'show': false,
        "value": "",
        "type": "switch",
        "name": "propertyStatusFlag",
        "label": "Property Status Flag",
        "id": "name",
        "placeholder": "Property Status Flag",
        // "required": true  
      },
      {
        //'show': false,
        "value": "",
        "type": "switch",
        "name": "availableToFileFlag",
        "label": "Available To File Flag",
        "id": "availableToFileFlag",
        "placeholder": "availableToFileFlag",
        // "required": true
      }, {
        //'show': false,
        "value": "",
        "type": "switch",
        "name": "claimStatusFlag",
        "label": "Claim Status Flag",
        "id": "name",
        "placeholder": "Claim Status Flag",
        // "required": true
      },
      {
        //'show': false,
        "value": "",
        "type": "switch",
        "name": "activeIndicator",
        "label": "Active /Inactive Status",
        "id": "active",
        "placeholder": "active",
        // "required": true
      },
      {
        //'show': false,
        "value": "",
        "type": "switch",
        "name": "isPropertyStatusSync",
        "label": "Is Property Status Sync",
        "id": "active",
        "placeholder": "active",
        // "required": true
      },
      {
        //'show': false,
        "value": "",
        "type": "switch",
        "name": "hideInOpra2UI",
        "label": "Hide in Opra UI",
        "id": "active",
        "placeholder": "active",
        // "required": true
      },
      {
        //'show': false,
        "value": "",
        "type": "switch",
        "name": "isPropertySyncIgnoredForProperties",
        "label": "Property Sync Ignored For Properties",
        "id": "active",
        "placeholder": "active",
        // "required": true
      },



    ]);
  }


  // submit = async (item) => {


  // }

  render() {
    return (
      <span>
        <Loader loader={this.state.isLoading} />
        <DataTables
          onRef={(ref) => (this.datatableref = ref)}
          getTableFields={this.getTableFields}
          additionalFlag={"&hideInOpraUIFlagRecords=true"}
          formFields={this.getFormFields}
          exportRequried={false}
          printRequried={false}
          sortField={"stageSequence, statusSequence, reasonSequence"}
          addRequried={true}
          editRequired={true}
          isClearFilters={true}
          deleteRequired={false}
          viewRequired={true}
          excelDownload={true}
          settingsRequired={false}
          filterRequired={true}
          gridRequried={false}
          sample={false}
          expandTableFields={this.expandTableFields}
          globalSearchFieldName='user'
          globalSearch={'Search'}
          type='Property And Claim Status'
          displayright={true}
          icon='user'
          routeTo='statusReason'
          displayViewOfForm='modal'
          apiResponseKey="propertyStatusReasons"
          apiUrl={apiCalls.propertyStatusReason}
          entityType='employee'
        />
      </span>
    );
  }
}