
import React from 'react';
import { Field, reduxForm } from 'redux-form';
import { Button, Card, CardBody, Modal, ModalHeader, ModalBody } from 'reactstrap';
import { connect } from 'react-redux';
import { Row } from 'react-bootstrap';

import { Accordion, AccordionTab } from 'primereact/accordion';
import { load as loadAccount } from '../../../redux/reducers/commonReducer';
import classNames from 'classnames';
// fecth method from service.js file
import fetch from '../../../config/service';
import configMessage from '../../../config/configMessages';
import { Editor } from 'primereact/editor';

// show message 
import DefaultInput from '../../../shared/components/form/DefaultInput';
import CheckBox from '../../../shared/components/form/CheckBox';
import validate from '../../Validations/validate';
import Loader from '../../App/Loader';
import { withTranslation } from 'react-i18next';
import Select from '../../../shared/components/form/Select';

import ModalFooter from 'reactstrap/lib/ModalFooter';
import { faArrowCircleLeft, faSave, faStarOfLife } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import apiCalls from '../../../config/apiCalls';

const required = value => (value || typeof value === 'string' ? undefined : configMessage.fillField)

//validations
// let newpassval = value => (value ? config.userPasswordRegex.test(value) ? undefined : 'Password must have at least 12 characters and contain at least 1 Uppercase and 1 special character.' : configMessage.fillField)

class SellerSignatoryModal extends React.Component {
    constructor(props) {
        super(props);
        this.buttonActionType = null;
        this.state = {
            isLoginSuccess: false,
            isLoading: false,
            colored: false,
            showName: false,
            namesArray: [],
            setCIty: null,
            keyNameChecked: false,
            storeProcId: "",
            Addresstype: null,
            name: null,
            citiesArray: [],
            progress: 0,
            sendNameID: false,
            header: true,
            BusinessID: "",
            subCategory: { "Sourcing": [{ label: "Valuation", value: "Valuation" }], "Research": [{ label: "Corporate Tree", value: "Corporate Tree" }, { label: "Entity Details", value: "Entity Details" }, { label: "Property Details", value: "Property Details" }], "Legal": [{ label: "APA", value: "APA" }, { label: "Document", value: "Document" }, { label: "Information", value: "Information" }], "Transition": [{ label: "Entity", value: "Entity" }], "Recovery": [{ label: "General Note", value: "General Note" }, { label: "State", value: "State" }] }
        };
    }


    // clear input data
    clearInputFields = () => {
        this.props.reset();
    }
    setAddressDetails(itemDetails) {
        this.props.change("companyName", itemDetails.EntityName)
        this.props.change("Address1", itemDetails.Address1)
        this.props.change("Address2", itemDetails.Address2)
        this.props.change("Name", itemDetails.Name)
        this.props.change("City", itemDetails.City)
        this.props.change("StateProvince", itemDetails.StateProvince)
        this.props.change("LocationType", itemDetails.LocationType)
        this.props.change("PostCode", itemDetails.PostCode)
        this.props.change("Country", itemDetails.Country)
        this.props.change("OfficePhone", itemDetails.OfficePhone)
        this.props.change("OfficeFax", itemDetails.OfficeFax)
        this.props.change("AddressSource", itemDetails.AddressSource)
        let htmlString = itemDetails.AddressNote;
        this.props.change("Note", <div dangerouslySetInnerHTML={{ __html: htmlString }}>
        </div>)
        // this.props.change("Note", itemDetails.AddressNote)
        this.setState({ setCIty: itemDetails.City, name: itemDetails.Name, BusinessID: itemDetails.BusinessID, keyNameChecked: itemDetails.IsKey, DataSourceID: itemDetails.DataSourceID ? itemDetails.DataSourceID : null, showName: true })
        let z = this.state.namesArray.find(elem => elem.label === itemDetails.EntityName)
        if (z) {
            this.setState({ storeProcId: z.value })
        }
        this.setZipcode(itemDetails.PostCode)
        this.handleBusinessID(itemDetails.EntityID)

    }
    submit = (values) => {

        let body = {}
        this.setState({
            isLoading: true,
        })

        body.BusinessID = JSON.stringify(this.state.BusinessID)
        let z = this.props.connectedBusiness.find(elem => elem.value === values.companyName)
        if (z) {
            body.companyID = z.value
        } else {
            let y = this.props.connectedBusiness.find(elem => elem.label === values.companyName)
            if (y) {
                body.companyID = y.value
            }
        }
        // body.EntityID = this.props.itemDetails.EntityID
        // body.EntityID = values.companyName

        body.PersonName = values.PersonName
        body.FullTitle = values.FullTitle
        if (this.props.editsignatoryData.CompanyBoardTeamID) {
            body.CompanyBoardTeamID = this.props.editsignatoryData.CompanyBoardTeamID
        }


        // this.props.reset();
        // this.props.cancelReset();

        let method, apiUrl
        if (!this.props.editsignatoryData || Object.keys(this.props.editsignatoryData).length === 0) {
            method = 'POST'
        } else {
            method = 'PUT'

        }
        apiUrl = `${apiCalls.signatories}?DimSellerID=${this.props.dimSellerID}`
        this.handlePercentage('1')
        return fetch(method, apiUrl, body)
            .then(async (response) => {
                let strArray = this.props.strArray ? this.props.strArray : null
                if (response && response.respCode && (response.respCode === 204 || response.respCode === 200 || response.respCode === 205)) {

                    // display message
                    // showToasterMessage(response.respMessage, 'success');
                    this.cancelReset("save")
                    this.props.toggleNames()
                } else if (response && response.errorMessage) {
                    // display message
                    await this.setState({ apierrorResponse: true, apierrorMessage: response.errorMessage })
                }
                this.setState({
                    isLoading: false,
                })
                // this.props.getKanbanCards()
            }).catch((err) => {
                return err;
            });
    }

    cancelReset = (type) => {
        // this.props.load({})
        // await this.props.reset();
        this.props.isCloseModal(type);

    }

    onChange = async (e) => {

    }
    handleName = async (e) => {
        let z = this.state.namesArray.find(elem => elem.value === e)
        if (z) {
            await this.setState({ name: e, sendNameID: true, sendBusinessId: z.BusinessID, sendCompanyId: z.CompanyID })
        }
    }
    handleCity = async (e) => {
        await this.setState({ setCIty: e })
    }
    setZipcode = async (e) => {
        let cityArray = []
        if (e && e.length > 3) {
            let zipcode = `${apiCalls.zipcode}?str=${e}`
            fetch("GET", zipcode)
                .then(async (response) => {
                    if (response && response.zipCodes && response.zipCodes.length > 0) {
                        let cityStates = response.zipCodes
                        let state = response.zipCodes[0]
                        for (let city of cityStates) {
                            cityArray.push({ label: city.PHYSICAL_CITY, value: city.PHYSICAL_CITY })
                        }
                        // this.props.change("City", x.city)
                        this.props.change("StateProvince", state.PHYSICAL_STATE)
                        this.setState({ citiesArray: cityArray })
                    } else {
                        this.props.change("StateProvince", "")
                        this.setState({ citiesArray: [] })
                    }
                }).catch((err) => {
                    return err;
                });
        }
    }
    handleBusinessID = async (e) => {
        let str = [];
        str.push(e)
        let z = this.props.connectedBusiness.find(elem => elem.value === e)
        if (z) {
            await this.setState({ BusinessID: z.BusinessID, showName: true, storeProcId: z.value })
        }
        let filter = {}
        filter['sortfield'] = "BusinessName"
        filter['direction'] = "asc"
        // await this.setState({ isLoading: true })
        // let urlNames = `${apiCalls.AddressNames}?filter=${JSON.stringify(filter)}&str=${str}&type=exportToCsv`
        // fetch("GET", urlNames)
        //     .then(async (response) => {
        //         if (response && response.addressnames && response.addressnames.length > 0) {

        //             for (let name of response.addressnames) {
        //                 namesArray.push({ label: name.BusinessName, value: name.BusinessName, BusinessID: name.BusinessID, CompanyID: name.CompanyID })
        //             }
        //             this.setState({ namesArray: namesArray, isLoading: false })


        //         } else {
        //             await this.setState({ addressnames: [], totalRecordsLengthNames: 0 })
        //             await this.setState({ isLoading: false })
        //             this.setState({ namesArray: [] })

        //         }
        //     }).catch((err) => {
        //         return err;
        //     });

    }
    handleCategory = async (e) => {

        for (let subCate in this.state.subCategory) {
            if (e === subCate) {
                this.setState({ subCatg: this.state.subCategory[subCate], Category: e, SubCategory: null })
                this.props.change("SubCategory", null)
            }
        }


    }
    keyNameChecked = (e) => {
        this.setState({ keyNameChecked: this.state.keyNameChecked ? false : true })
    }

    handleSubCategory = async (e) => {
        await this.setState({ SubCategory: e })
    }
    setText = (e) => {
        this.setState({ issueNoteHtml: e })
    }
    handlePercentage = async (Val) => {
        let counter = 0;
        const interval = setInterval(async () => {
            if (this.state.isLoading) {
                counter = counter + 15;
                await this.setState({
                    progress: counter,
                })
            } else {
                if (!this.state.isLoading) {
                    clearInterval(interval);
                }
            }
        }, 100);
    }
    setGeneralNote = (e) => {
        this.setState({ generalNoteHtml: e })
    }
    getHierarchyData = async () => {
        let namesArray = []
        let urlNames = `${apiCalls.HeirarchyCompany}?str=${this.props.dimSellerID}`
        fetch("GET", urlNames)
            .then(async (response) => {
                if (response && response.companyIDs) {
                    for (let name of response.companyIDs) {
                        namesArray.push({ label: name.CompanyName, value: name.CompanyID, BusinessID: name.BusinessID })
                    }
                    this.setState({ namesArray: namesArray, isLoading: false })


                } else {
                    this.setState({ namesArray: [], isLoading: false })

                }
            }).catch((err) => {
                return err;
            });
    }

    componentDidMount = async () => {
        if (this.props.editsignatoryData) {
            this.setState({ type: "edit", BusinessID: this.props.editsignatoryData.BusinessID })
            let z = this.props.connectedBusiness.find(elem => elem.value === this.props.editsignatoryData.CompanyID)
            if (z) {
                this.props.change("companyName", z.label)
            }
            this.props.change("PersonName", this.props.editsignatoryData.PersonName)
            this.props.change("FullTitle", this.props.editsignatoryData.FullTitle)

        }

        // this.setState({ DataSourceID: this.props.itemDetails.DataSourceID ? this.props.itemDetails.DataSourceID : null })

        // this.setState({ CompanyID: this.props.connectedBusiness.label, BusinessName: this.props.connectedBusiness.value })

    }

    render() {
        const { handleSubmit, t, type } = this.props;
        const modalClass = classNames({
            'modal-dialog--colored': this.state.colored,
            'modal-dialog--header': this.state.header,
        });
        return (
            <Modal
                // fade={false}
                isOpen={this.props.isOpenAddModal}
                // style={{ maxWidth: "1000px" }}
                className={`modal-dialog modal-dialog--primary  ${modalClass}`}
            >
                <form className="form" onSubmit={handleSubmit(this.submit)}>
                    <ModalHeader style={{ width: "100%" }} >
                        <button className=" modal__close-btn" type="button" onClick={this.cancelReset} >
                            <FontAwesomeIcon
                                style={{ width: "15px" }}
                                // color="red"
                                icon={faArrowCircleLeft}
                            // data-toggle="tool-tip"
                            // title={"save"}
                            />
                        </button>
                        <div style={{ display: "flex" }}>
                            <span className={`tableheadericon lnr lnr-lnr lnr-layers`} />
                            <p className="bold-text  modal__title ml-2"> {!this.props.editsignatoryData || Object.keys(this.props.editsignatoryData).length === 0 ? "Add" : "Edit"} {"Seller Signatory"} </p>

                        </div>


                    </ModalHeader>
                    {/* <ModalHeader className={"modal__header kanbanScreen"}>
                        <div className="d-flex">
                            <button className="lnr lnr-cross modal__close-btn" type="button" style={type === "edit" ? { color: "black", right: 40 } : { color: "white" }}
                                onClick={() => this.cancelReset("")} />
                            <span className={`modallnrIcon pt-1 lnr lnr-list`} />
                            <p className="bold-text  modal__title ml-2"> {!this.props.editsignatoryData || Object.keys(this.props.editsignatoryData).length === 0 ? "Add" : "Edit"} {"Seller Signatory"} </p>
                            {/* <Button color='danger' style={{ float: "right", marginLeft: "545px", height: "33px" }} outline type="button" onClick={() => this.cancelReset("")}>
                                <FontAwesomeIcon
                                    style={{ width: "15px" }}
                                    // color="red"
                                    icon={faArrowCircleLeft}
                                />
                                {t('Back') ? t('Cancel') : 'Cancel'} </Button>
                            <Button
                                style={{ float: "right", height: "33px" }}
                                color='primary'
                                className={"mb-0 "}
                                outline
                                type="submit"
                            >
                                <FontAwesomeIcon
                                    style={{ width: "15px" }}
                                    icon={faSave}
                                />
                                {type === 'edit' ? 'Update' : 'Save'}
                            </Button> */}
                    {/* </div> */}

                    {/* </ModalHeader>  */}

                    {/* <ModalHeader className={"modal__header kanbanScreen"}>
                        <div className="d-flex">
                            <button className="lnr lnr-cross modal__close-btn" type="button" style={type === "edit" ? { color: "black", right: 40 } : { color: "white" }}
                                onClick={this.cancelReset} />
                            <span className={`modallnrIcon pt-1 lnr lnr-list`} />
                            <p className="bold-text  modal__title ml-2"> {this.state.type === "edit" || this.state.Addresstype ? "Edit" : 'Add'} {this.props.activeTab === "5" ? "Note" : "Address"} </p>
                        </div>
                    </ModalHeader> */}
                    <ModalBody className={"modal__body kanbanmodalCardBody mb-0 pt-0 "}>
                        <Card className='pb-0 '>
                            {/* <Loader loader={this.state.isLoading} /> */}
                            <CardBody className=' p-0 mt-3'
                            >
                                <span className="formError mt-2 mb-2" style={{ textAlign: "center", top: -14, color: "red" }}>{this.state.apierrorResponse ? <span style={{ height: 30 }}>{this.state.apierrorMessage}</span> : null}</span>


                                <Loader loader={this.state.isLoading} progress={this.state.progress} className="screen-loader" />

                                <div>
                                    <div>
                                        <label className="form__form-group-label">Company Name{required ? <FontAwesomeIcon icon={faStarOfLife}
                                            className='pl-1'
                                            color='red'
                                            data-toggle="tool-tip"
                                            title="Mandotary Field"
                                            style={{ width: 8 }}
                                        /> : ''}</label>

                                        {/* <Field className='EstateName'
                                                        type={'text'}
                                                        name="BusinessName"
                                                        component={DefaultInput}
                                                        // options={this.state.EstateNames}
                                                        // onChange={(e) => this.handleChange(e)}
                                                        validate={required}
                                                        placeholder='Business Name'
                                                    /> */}
                                        <Field
                                            // key={i}
                                            name={"companyName"}
                                            component={Select}
                                            validate={required}
                                            options={this.props.connectedBusiness ? this.props.connectedBusiness : []}
                                            onChange={(e) => this.handleBusinessID(e)}
                                            // onChange={(e) => this.handleChange(e)}
                                            // defaultValue={this.props.formType && this.props.formType === 'add' ? item.defaultValue : null}
                                            placeholder={"Company Name"}
                                        // isDisable={this.state.Addresstype === "edit" && this.state.DataSourceID !== this.props.dataSourceId ? true : false}
                                        // isDisable={item.disable ? item.disable : false}
                                        />
                                    </div>

                                    <div className="col-sm-12 pl-0 pr-0">
                                        <div className="form__form-group pb-1">
                                            <label className="form__form-group-label">Seller Signatory{required ? <FontAwesomeIcon icon={faStarOfLife}
                                                className='pl-1'
                                                color='red'
                                                data-toggle="tool-tip"
                                                title="Mandotary Field"
                                                style={{ width: 8 }}
                                            /> : ''}</label>
                                            <Field className='inputLogin'
                                                type={'text'}
                                                name="PersonName"
                                                component={DefaultInput}
                                                validate={required}
                                                maxLength={100}
                                                // isDisable={this.state.type === "edit" ? true : false}
                                                // isDisable={this.state.type === "edit" && this.state.DataSourceID !== this.props.dataSourceId ? true : false}
                                                placeholder='Seller Signatory'

                                            // options={this.state.company}
                                            />

                                        </div>
                                    </div>
                                    <div className="col-sm-12 pl-0 pr-0">
                                        <div className="form__form-group pb-1">
                                            <label className="form__form-group-label">Seller Signatory’s Title{required ? <FontAwesomeIcon icon={faStarOfLife}
                                                className='pl-1'
                                                color='red'
                                                data-toggle="tool-tip"
                                                title="Mandotary Field"
                                                style={{ width: 8 }}
                                            /> : ''}</label>
                                            <Field className='inputLogin'
                                                type={'text'}
                                                name="FullTitle"
                                                component={DefaultInput}
                                                validate={required}
                                                maxLength={1000}
                                                // isDisable={this.state.type === "edit" ? true : false}
                                                // isDisable={this.state.type === "edit" && this.state.DataSourceID !== this.props.dataSourceId ? true : false}
                                                placeholder='Seller Signatory’s Title'

                                            // options={this.state.company}
                                            />

                                        </div>
                                    </div>
                                </div>





                                {/* <div className="col-sm-12 pl-0 pr-0">
                                    <div className="form__form-group pb-1">
                                        <label className="form__form-group-label">City{required ? <FontAwesomeIcon icon={faStarOfLife}
                                            className='pl-1'
                                            color='red'
                                            data-toggle="tool-tip"
                                            title="Mandotary Field"
                                            style={{ width: 8 }}
                                        /> : ''}</label>
                                        <div className='form__form-group-field'>
                                            <Field className='EstateName'
                                                type={'text'}
                                                name="company"
                                                component={DefaultInput}
                                                // options={this.state.EstateNames}
                                                // onChange={(e) => this.handleChange(e)}
                                                validate={required}
                                                placeholder='City'
                                            />
                                        </div>
                                    </div>
                                </div> */}
                                {/* <div className="col-sm-12 pl-0 pr-0">
                                    <div className="form__form-group pb-1">
                                        <label className="form__form-group-label">State{required ? <FontAwesomeIcon icon={faStarOfLife}
                                            className='pl-1'
                                            color='red'
                                            data-toggle="tool-tip"
                                            title="Mandotary Field"
                                            style={{ width: 8 }}
                                        /> : ''}</label>
                                        <div className='form__form-group-field'>
                                            <Field className='EstateName'
                                                type={'text'}
                                                name="company"
                                                component={DefaultInput}
                                                // options={this.state.EstateNames}
                                                // onChange={(e) => this.handleChange(e)}
                                                validate={required}
                                                placeholder='State'
                                            />
                                        </div>
                                    </div>
                                </div> */}
                                {/* <div className="col-sm-12 pl-0 pr-0">
                                    <div className="form__form-group pb-1">
                                        <label className="form__form-group-label">Zip Code{required ? <FontAwesomeIcon icon={faStarOfLife}
                                            className='pl-1'
                                            color='red'
                                            data-toggle="tool-tip"
                                            title="Mandotary Field"
                                            style={{ width: 8 }}
                                        /> : ''}</label>
                                        <div className='form__form-group-field'>
                                            <Field className='EstateName'
                                                type={'text'}
                                                name="company"
                                                component={DefaultInput}
                                                // options={this.state.EstateNames}
                                                // onChange={(e) => this.handleChange(e)}
                                                validate={required}
                                                placeholder='Zip Code'
                                            />
                                        </div>
                                    </div>
                                </div> */}

                            </CardBody>
                        </Card>
                    </ModalBody>
                    <ModalFooter className="modalFooter " style={{ width: "100%", padding: "10px" }}>
                        <Button color='danger' outline type="button" className="mr-auto ml-1 mb-0 " onClick={this.cancelReset}>
                            <FontAwesomeIcon
                                style={{ width: "15px" }}
                                // color="red"
                                icon={faArrowCircleLeft}
                            // data-toggle="tool-tip"
                            // title={"save"}
                            />
                            {t('Back') ? t('Cancel') : 'Cancel'} </Button>
                        <Button
                            style={{ float: "right" }}
                            color='primary'
                            className={"mb-0 "}
                            outline
                            type="submit">
                            <FontAwesomeIcon
                                style={{ width: "15px" }}
                                icon={faSave}
                            />
                            {type === 'edit' ? 'Update' : 'Save'}
                        </Button>
                    </ModalFooter>
                </form >
            </Modal >
        );
    }
}


SellerSignatoryModal = reduxForm({
    form: "Addressmodel", // a unique identifier for this form
    validate,
    enableReinitialize: true,
})(SellerSignatoryModal);

// You have to connect() to any reducers that you wish to connect to yourself
SellerSignatoryModal = connect(
    state => ({
        initialValues: state.commonData.data // pull initial values from account reducer
    }),
    { load: loadAccount } // bind account loading action creator
)(SellerSignatoryModal);

export default withTranslation('common')(SellerSignatoryModal);
