import React from 'react';
import store from '../../../App/store';
import apiCalls from '../../../../config/apiCalls'
import DataTables from '../../CommonDataTable/DataTable';
import fetch from '../../../../config/service';
import Loader from '../../../App/Loader';


// config file
export default class ClaimValueHintBucketAvg extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            type: 'Purposes',
        };
    }

    static getDerivedStateFromProps(props, state) {
        let storeData = store.getState()
        let languageData = storeData && storeData.settingsData && storeData.settingsData.settings && storeData.settingsData.settings.languageTranslation ? storeData.settingsData.settings.languageTranslation : ""
        return { languageData: languageData };
    }



    componentDidMount = () => {
        this.getDataGroupSources()
    }


    /**
     * 
     * @returns dataArray
     */
    getDataGroupSources = async () => {
        let dataArray = [];
        let filterCriteria = {}
        let loginCredentials = JSON.parse(localStorage.getItem("loginCredentials"));
        let loginRole = loginCredentials.roleName
        // filterCriteria['criteria'] = [{ key: 'activeIndicator', value: true, type: 'eq' }];
        filterCriteria['criteria'] = [{ key: 'activeIndicator', value: true, type: 'eq' }, { key: "roles", value: loginRole, type: "eq" }];
        await fetch('GET', `${apiCalls.DataSource}?type=group&filter=${JSON.stringify(filterCriteria)}`).then(async (response) => {
            if (response && response["dataSources"] && response["dataSources"].length > 0) {
                let propertyStatuses = response["dataSources"]
                for (let status of propertyStatuses) {
                    dataArray.push({ label: status.group, value: status.group })
                }
                this.setState({ dataArray: dataArray })
            }
        })
        return this.state.settingsData;
    }

    /**
     * 
     * @returns tableFields
     */
    getTableFields = () => {
        let data = [
            // {
            //   "show": true,
            //   "textAlign": "center",
            //   "width": 6,
            //   "fieldType": "expander",
            //   "header": "",
            //   "filter": false,
            //   "sortable": false,
            //   "placeholder": "Search"
            // },
            {
                "show": true,
                textAlign: 'left',
                // width: 80,
                field: 'hintBucket',
                // fieldType: 'BoolText',
                "mobile": true,
                header: 'Hint Bucket',
                "filter": true,
                "displayInSettings": true,
                "displayInFilter": true,
                sortable: true,
            },
            {
                "show": true,
                textAlign: 'left',
                // width: 80,
                field: 'hintBucketMinimum',
                "filterType": 'num',
                "mobile": true,
                header: 'Hint Bucket Minimum',
                "filter": true,
                "displayInSettings": true,
                "displayInFilter": true,
                sortable: true,
            },
            {
                "show": true,
                textAlign: 'left',
                // width: 80,
                field: 'hintBucketMaximum',
                "filterType": 'num',
                "mobile": true,
                header: 'Hint Bucket Maximum',
                "filter": true,
                "displayInSettings": true,
                "displayInFilter": true,
                sortable: true,
            },
            {
                "show": true,
                textAlign: 'left',
                // width: 80,
                field: 'hintBucketFriendly',
                // fieldType: 'BoolText',
                "mobile": true,
                header: 'Hint Bucket Friendly',
                "filter": true,
                "displayInSettings": true,
                "displayInFilter": true,
                sortable: true,
            },
            {
                "show": true,
                textAlign: 'left',
                // width: 80,
                field: 'hintBucketCountHeader',
                // fieldType: 'BoolText',
                "mobile": true,
                header: 'Hint Bucket Count Header',
                "filter": true,
                "displayInSettings": true,
                "displayInFilter": true,
                sortable: true,
            }, {
                "show": true,
                textAlign: 'left',
                // width: 80,
                field: 'hintBucketAvgHeader',
                // fieldType: 'BoolText',
                "mobile": true,
                header: 'Hint Bucket Avg Header',
                "filter": true,
                "displayInSettings": true,
                "displayInFilter": true,
                sortable: true,
            }, {
                "show": true,
                textAlign: 'left',
                // width: 80,
                field: 'hintBucketOPAMDataCount',
                "filterType": 'num',
                "mobile": true,
                header: 'Hint Bucket OPAM Data Count',
                "filter": true,
                "displayInSettings": true,
                "displayInFilter": true,
                sortable: true,
            }, {
                "show": true,
                textAlign: 'left',
                // width: 80,
                field: 'opamAsPercentOfTotalDataThatHasValueGreaterThanZero',
                // fieldType: 'BoolText',
                "mobile": true,
                header: 'OPAM as Percent of Total Data that has Value Greater Than Zero',
                "filter": true,
                "displayInSettings": true,
                "displayInFilter": true,
                sortable: true,
            }, {
                "show": true,
                textAlign: 'left',
                // width: 80,
                field: 'hintBucketOPAMAverage',
                "filterType": 'num',
                "mobile": true,
                header: 'Hint Bucket OPAM Average',
                "filter": true,
                "displayInSettings": true,
                "displayInFilter": true,
                sortable: true,
            },
            {
                "show": true,
                "textAlign": "left",
                // "width": 40,
                "fieldType": 'Actions',
                "field": "Actions",
                "header": "Actions",
                "filter": false,
                "displayInSettings": true,
                "sortable": false
            }
        ]
        return data;
    };

    /**
     * 
     * @returns formFields
     */
    getFormFields = () => {
        return ([

            {
                //'show': false,
                // 'isMultiSelect': true,
                "value": "",
                "type": "text",
                "name": "hintBucket",
                "label": "Hint Bucket",
                "id": "name",
                "placeholder": "Hint Bucket",
                "required": true
            },
            {
                //'show': false,
                // 'isMultiSelect': true,
                "value": "",
                "type": "text",
                "name": "hintBucketFriendly",
                "label": "Hint Bucket Friendly",
                "id": "name",
                "placeholder": "Hint Bucket Friendly",
                "required": true
            },
            {
                //'show': false,
                // 'isMultiSelect': true,
                "value": "",
                "type": "number",
                "name": "hintBucketMinimum",
                "label": "Hint Bucket Minimum",
                "id": "name",
                "placeholder": "Hint Bucket Minimum",
                // "required": true
            },
            {
                //'show': false,
                // 'isMultiSelect': true,
                "value": "",
                "type": "number",
                "name": "hintBucketMaximum",
                "label": "Hint Bucket Maximum",
                "id": "name",
                "placeholder": "Hint Bucket Maximum",
                // "required": true
            },
            {
                //'show': false,
                // 'isMultiSelect': true,
                "value": "",
                "type": "text",
                "name": "hintBucketCountHeader",
                "label": "Hint Bucket Count Header",
                "id": "name",
                "placeholder": "Hint Bucket Count Header",
                "required": true
            },
            {
                //'show': false,
                // 'isMultiSelect': true,
                "value": "",
                "type": "text",
                "name": "hintBucketAvgHeader",
                "label": "Hint Bucket Avg Header",
                "id": "name",
                "placeholder": "Hint Bucket Avg Header",
                "required": true
            },
            {
                //'show': false,
                // 'isMultiSelect': true,
                "value": "",
                "type": "text",
                "name": "hintBucketOPAMDataCount",
                "label": "Hint Bucket OPAM Data Count",
                "id": "name",
                "placeholder": "Hint Bucket OPAM Data Count",
                "required": true
            },
            {
                //'show': false,
                // 'isMultiSelect': true,
                "value": "",
                "type": "text",
                "name": "opamAsPercentOfTotalDataThatHasValueGreaterThanZero",
                "label": "OPAM as Percent of Total Data that has Value Greater Than Zero",
                "id": "name",
                "placeholder": "OPAM as Percent of Total Data that has Value Greater Than Zero",
                "required": true
            },
            {
                //'show': false,
                // 'isMultiSelect': true,
                "value": "",
                "type": "text",
                "name": "hintBucketOPAMAverage",
                "label": "Hint Bucket OPAM Average",
                "id": "name",
                "placeholder": "Hint Bucket OPAM Average",
                "required": true
            },
            {
                //'show': false,
                "value": "",
                "type": "switch",
                "name": "activeIndicator",
                "label": "Active /Inactive Status",
                "id": "name",
                "placeholder": "Name",
                // "required": true
            },
        ]);
    }


    submit = async (item) => {


    }

    render() {
        return (
            <span>
                <Loader loader={this.state.isLoading} />
                <DataTables
                    onRef={(ref) => (this.datatableref = ref)}
                    getTableFields={this.getTableFields}
                    formFields={this.getFormFields}
                    exportRequried={false}
                    printRequried={false}
                    sortField={"hintBucketMaximum"}
                    addRequried={true}
                    editRequired={true}
                    deleteRequired={false}
                    viewRequired={true}
                    settingsRequired={false}
                    filterRequired={true}
                    gridRequried={false}
                    sample={false}
                    isClearFilters={true}
                    expandTableFields={this.expandTableFields}
                    globalSearchFieldName='user'
                    globalSearch={'Search'}
                    type='Claim Value Hint Bucket Avg'
                    displayright={true}
                    icon='user'
                    routeTo='configurations'
                    displayViewOfForm='modal'
                    apiResponseKey='claimValueHintBucketAvgs'
                    apiUrl="claimValueHintBucketAvg"
                    entityType='employee'
                />
            </span>
        );
    }
}