import React,{useState,useEffect} from 'react';
import 'froala-editor/css/froala_style.min.css';
import 'froala-editor/css/froala_editor.pkgd.min.css';
import FroalaEditorComponent from 'react-froala-wysiwyg';
import 'froala-editor/js/plugins.pkgd.min.js';
import { renderToStaticMarkup} from 'react-dom/server';
import companyLogo from '../../../../assets/logo.png';


export const TransmittalLetter = (props) => {
	
    const [content, setContent] = useState(``);

	useEffect(() => {
		props.getChainOfTitle(true);
		setInitialContentInEditor();
	},[]);
	useEffect(() => {
		if (props.chainOfTitleData && props.chainOfTitleData.length > 0) {
			setInitialContentInEditor();
		}
	},[props.chainOfTitleData]);

	const setInitialContentInEditor = () => {
		const content = `${getHeaders()} ${getSubject()} ${getOwnershipDoc()} ${getCOTTable()} ${getPrevPaid()} ${getFooter()} ${getSignOff()}`;
		setContent(content)
	}

	const getHeaders = () => {
		return `<div style="margin:5% 10%;">
		<div style="display: flex;justify-content: space-between; align-items: center;">
			<div><span style="font-size: 16px; font-family: Verdana, Geneva, sans-serif; color: rgb(0, 0, 0);"><img src=${companyLogo} style="width: 300px;" class="fr-fic fr-dib"></span></div>
			<div>
				<div class="s3" style="text-align: left;"><span style="font-size: 16px; font-family: Verdana, Geneva, sans-serif; color: rgb(0, 0, 0);">847-483-8001</span></div>
				<div class="s3" style="text-align: left;margin: 0px;"><span style="font-size: 16px; font-family: Verdana, Geneva, sans-serif; color: rgb(0, 0, 0);">info@oakpointpartners.com</span></div></div></div>
		<div class="s1" style="padding-top: 8pt;margin: 20px;text-indent: 0pt;line-height: 13pt;text-align: left;"><span style="font-size: 16px; font-family: Verdana, Geneva, sans-serif; color: rgb(0, 0, 0);">COMPTROLLER OF PUBLIC ACCOUNTS<br>Unclaimed Property Claims Section<br>PO Box 12046<br>Austin, TX 78711-2046</span></div>
		<div style="text-indent: 0pt;text-align: left;"><span style="font-size: 16px; font-family: Verdana, Geneva, sans-serif; color: rgb(0, 0, 0);"><br></span></div>`
	}

	const getSubject= () => {
		return `<div class="s2" style="padding-left: 56pt;text-indent: 0pt;text-align: left;font-weight: bolder;"><span style="font-size: 16px; font-family: Verdana, Geneva, sans-serif; color: rgb(0, 0, 0);">Re: &nbsp; Texas Unclaimed Property Claim No. 24342269</span></div>
		<div style="text-indent: 0pt;text-align: left;"><span style="font-size: 16px; font-family: Verdana, Geneva, sans-serif; color: rgb(0, 0, 0);"><br></span></div>
		<div class="s1" style="padding-top: 2pt;padding-left: 20pt;text-indent: 0pt;text-align: left;"><span style="font-size: 16px; font-family: Verdana, Geneva, sans-serif; color: rgb(0, 0, 0);">Please see the attached business claim by owner and claimant Premise Health Employer Solutions, LLC:&nbsp;</span></div>
		<div style="text-indent: 0pt;text-align: left;"><span style="font-size: 16px; font-family: Verdana, Geneva, sans-serif; color: rgb(0, 0, 0);"><br></span></div>`
	}

	const getOwnershipDoc = () => {
		return `<div style="text-indent: 0pt;text-align: center;"><span style="font-size: 16px; font-family: Verdana, Geneva, sans-serif; color: rgb(0, 0, 0);"><em><strong>Claimant&rsquo;s Ownership Documentation</strong></em></span></div>

		<table style="width:100%;">
			<tbody>
				<tr>
					<td style="text-align:center;">
						<div class="s4"><span style="font-size: 16px; font-family: Verdana, Geneva, sans-serif; color: rgb(0, 0, 0);">Page(s)</span></div>
					</td>
					<td>
						<div class="s4" style="padding-left: 5pt;text-indent: 0pt;line-height: 12pt;text-align: left;"><span style="font-size: 16px; font-family: Verdana, Geneva, sans-serif; color: rgb(0, 0, 0);">Document Description</span></div>
					</td>
				</tr>
				<tr style="height:14pt;">
					<td style="width:45pt;border-top-style:solid;border-top-width:1pt;border-left-style:solid;border-left-width:1pt;border-bottom-style:solid;border-bottom-width:1pt;border-right-style:solid;border-right-width:1pt;">
						<div class="s5" style="padding-left: 4pt;padding-right: 4pt;text-indent: 0pt;line-height: 12pt;text-align: center;"><span style="font-size: 16px; font-family: Verdana, Geneva, sans-serif; color: rgb(0, 0, 0);">3-5</span></div>
					</td>
					<td style="width:459pt;border-top-style:solid;border-top-width:1pt;border-left-style:solid;border-left-width:1pt;border-bottom-style:solid;border-bottom-width:1pt;border-right-style:solid;border-right-width:1pt;">
						<div class="s5" style="padding-left: 5pt;text-indent: 0pt;line-height: 12pt;text-align: left;"><span style="font-size: 16px; font-family: Verdana, Geneva, sans-serif; color: rgb(0, 0, 0);">Claim Form and property list</span></div>
					</td>
				</tr>
				<tr style="height:27pt;">
					<td style="width:45pt;border-top-style:solid;border-top-width:1pt;border-left-style:solid;border-left-width:1pt;border-bottom-style:solid;border-bottom-width:1pt;border-right-style:solid;border-right-width:1pt;">
						<div class="s5" style="padding-left: 4pt;padding-right: 4pt;text-indent: 0pt;line-height: 13pt;text-align: center;"><span style="font-size: 16px; font-family: Verdana, Geneva, sans-serif; color: rgb(0, 0, 0);">6-11</span></div>
					</td>
					<td style="width:459pt;border-top-style:solid;border-top-width:1pt;border-left-style:solid;border-left-width:1pt;border-bottom-style:solid;border-bottom-width:1pt;border-right-style:solid;border-right-width:1pt;">
						<div class="s5" style="padding-left: 5pt;text-indent: 0pt;line-height: 13pt;text-align: left;"><span style="font-size: 16px; font-family: Verdana, Geneva, sans-serif; color: rgb(0, 0, 0);">Premise Health Holding Corp. and each party scheduled in Exhibit A, including Premise Health</span></div>
						<div class="s5" style="padding-left: 5pt;text-indent: 0pt;line-height: 12pt;text-align: left;"><span style="font-size: 16px; font-family: Verdana, Geneva, sans-serif; color: rgb(0, 0, 0);">Employer Solutions, LLC, sold certain of its assets to Oak Point Partners, LLC.</span></div>
					</td>
				</tr>
				<tr style="height:14pt;">
					<td style="width:45pt;border-top-style:solid;border-top-width:1pt;border-left-style:solid;border-left-width:1pt;border-bottom-style:solid;border-bottom-width:1pt;border-right-style:solid;border-right-width:1pt;">
						<div class="s5" style="padding-left: 4pt;padding-right: 4pt;text-indent: 0pt;line-height: 13pt;text-align: center;"><span style="font-size: 16px; font-family: Verdana, Geneva, sans-serif; color: rgb(0, 0, 0);">12</span></div>
					</td>
					<td style="width:459pt;border-top-style:solid;border-top-width:1pt;border-left-style:solid;border-left-width:1pt;border-bottom-style:solid;border-bottom-width:1pt;border-right-style:solid;border-right-width:1pt;">
						<div class="s5" style="padding-left: 5pt;text-indent: 0pt;line-height: 13pt;text-align: left;"><span style="font-size: 16px; font-family: Verdana, Geneva, sans-serif; color: rgb(0, 0, 0);">Kevin Clemons &ndash; IL driver&rsquo;s license</span></div>
					</td>
				</tr>
			</tbody>
		</table>
		<br>`
	}



	const getCOTTable = () => {
		return `<div style="text-indent: 0pt;text-align: left;"><span style="font-size: 16px; font-family: Verdana, Geneva, sans-serif; color: rgb(0, 0, 0);"><br></span></div>
		<div style="text-indent: 0pt; text-align: center; line-height: 1;"><span style="font-size: 16px; font-family: Verdana, Geneva, sans-serif; color: rgb(0, 0, 0);"><strong><em>Acquisition Name: Premise Health Employer Solutions, LLC&nbsp;</em></strong></span></div>
		<div style="text-indent: 0pt; text-align: center; line-height: 1;"><span style="font-size: 16px; font-family: Verdana, Geneva, sans-serif; color: rgb(0, 0, 0);"><strong><em>Chain of Title and Supporting Documentation</em></strong></span><span class="s6" style="font-size: 16px; font-family: Verdana, Geneva, sans-serif; color: rgb(0, 0, 0);"><em><strong><sup>1,2</sup></strong></em></span></div>
	
		<table style="width:100%;">
			<tbody>
				<tr>
					<td style="width:45pt;border-top-style:solid;border-top-width:1pt;border-left-style:solid;border-left-width:1pt;border-bottom-style:solid;border-bottom-width:1pt;border-right-style:solid;border-right-width:1pt;">
						<div class="s4" style="padding-left: 4pt;padding-right: 4pt;text-indent: 0pt;line-height: 12pt;text-align: center;"><span style="font-size: 16px; font-family: Verdana, Geneva, sans-serif; color: rgb(0, 0, 0);">Page(s)</span></div>
					</td>
					<td style="width:36pt;border-top-style:solid;border-top-width:1pt;border-left-style:solid;border-left-width:1pt;border-bottom-style:solid;border-bottom-width:1pt;border-right-style:solid;border-right-width:1pt;">
						<div class="s4" style="padding-left: 5pt;text-indent: 0pt;line-height: 12pt;text-align: left;"><span style="font-size: 16px; font-family: Verdana, Geneva, sans-serif; color: rgb(0, 0, 0);">Date</span></div>
					</td>
					<td style="width:423pt;border-top-style:solid;border-top-width:1pt;border-left-style:solid;border-left-width:1pt;border-bottom-style:solid;border-bottom-width:1pt;border-right-style:solid;border-right-width:1pt;">
						<div class="s4" style="padding-left: 5pt;text-indent: 0pt;line-height: 12pt;text-align: left;"><span style="font-size: 16px; font-family: Verdana, Geneva, sans-serif; color: rgb(0, 0, 0);">Document Description</span></div>
					</td>
				</tr>
				${props.chainOfTitleData && props.chainOfTitleData.map((elem) => renderToStaticMarkup(<tr style={{fontSize:'16px',fontFamily:'Verdana, Geneva, sans-serif',color:'black',textAlign:'center'}}>
						<td style={{ width: '45pt'}}>
							<div class="s5" >{elem.page}</div>
						</td>
						<td >
							<div class="s5" >{elem.year}</div>
						</td>
						<td style={{textAlign:'left',paddingLeft:'5',paddingRight:'5'}}>
							<div class="s5" >{elem.description}</div>

						</td>
					</tr>)
				)}				
			</tbody>
		</table><span style="font-family: Verdana, Geneva, sans-serif; color: rgb(0, 0, 0);"><br></span>`
	}
	const getPrevPaid = () => {
		return `<div style="text-indent: 0pt; text-align: left; line-height: 1;"><span style="font-size: 16px; font-family: Verdana, Geneva, sans-serif; color: rgb(0, 0, 0);"><br></span></div>
		<div style=" text-indent: 0pt; text-align: center; line-height: 1;"><span style="font-size: 16px; font-family: Verdana, Geneva, sans-serif; color: rgb(0, 0, 0);"><strong><em>Previously filed and/or paid claims related to the same acquisition</em></strong></span></div>
	
		<table style="width: 100%;">
			<tbody>
				<tr>
					<td style="width: 100.0000%;"><span style="font-size: 16px; font-family: Verdana, Geneva, sans-serif; color: rgb(0, 0, 0);"><br></span></td>
				</tr>
			</tbody>
		</table>`
	}

	const getFooter = () => {
		return `<p><span style="font-family: Verdana, Geneva, sans-serif; color: rgb(0, 0, 0);">&nbsp;_________________________</span></p>
		<div class="s7" style="padding-top: 4pt;padding-left: 20pt;text-indent: 0pt;text-align: left;"><span style="font-size: 14px; font-family: Verdana, Geneva, sans-serif; color: rgb(0, 0, 0);"><sup>1</sup></span><span class="p" style="font-size: 14px; font-family: Verdana, Geneva, sans-serif; color: rgb(0, 0, 0);">Please note that the reported owner is part of a large organization that shares similar company names, officers, and addresses, both physical and electronic (e.g., lockboxes). Because certain properties have been reported in a tradename, misnomer, or truncated name, we have endeavored to provide the evidence for the appropriate company. Certain of the reported addresses appear to have been inaccurate in the first instance, or inadvertently sent to a third-party address, not authorized by the reported owner for billing purposes.</span></div>
		<div class="s7" style="padding-top: 4pt;padding-left: 20pt;text-indent: 0pt;text-align: left;"><span style="font-size: 14px; font-family: Verdana, Geneva, sans-serif; color: rgb(0, 0, 0);"><sup>2</sup></span><span class="p" style="font-size: 14px; font-family: Verdana, Geneva, sans-serif; color: rgb(0, 0, 0);">Please note that this claim does not seek to include assets that are held in solely in a custodial manner and for the benefit of a third party, including, abandoned and escheated property reported by, for example, (a) a county recorder or tax assessor office for the benefit of a customer pursuant to a title insurance transaction, (b) a transfer agent for the benefit of a shareholder or registrant; or (c) a financial institution for the benefit of a client or account holder. Where unclear from the public data, please provide additional information or a holder contact so that we may conduct a further inquiry.</span></div>
	
		<p><span style="font-family: Verdana, Geneva, sans-serif; color: rgb(0, 0, 0);"><br></span></p>
		<div style="text-indent: 0pt;text-align: left;"><span style="font-size: 16px; font-family: Verdana, Geneva, sans-serif; color: rgb(0, 0, 0);"><br></span></div>
		<div class="s1" style="padding-top: 2pt;text-indent: 0pt;text-align: center;"><span style="font-size: 16px; font-family: Verdana, Geneva, sans-serif; color: rgb(0, 0, 0);">P O B O X 1 0 3 3 &bull; N O R T H B R O O K , I L 6 0 0 6 5 - 1033</span></div>
	
		<p><span style="font-family: Verdana, Geneva, sans-serif; color: rgb(0, 0, 0);"><br></span></p>`
	}

	const getSignOff = () => {
		return `<div class="s1" style="padding-top: 1pt;padding-left: 20pt;text-indent: 0pt;text-align: left;"><span style="font-size: 14px; font-family: Verdana, Geneva, sans-serif; color: rgb(0, 0, 0);">Oak Point Partners, LLC Page 2 of 2</span></div>
		<div style="text-indent: 0pt;text-align: left;"><span style="font-size: 16px; font-family: Verdana, Geneva, sans-serif; color: rgb(0, 0, 0);"><br></span></div>
		<div class="s1" style="padding-left: 20pt;text-indent: 0pt;line-height: 108%;text-align: left;"><span style="font-size: 16px; font-family: Verdana, Geneva, sans-serif; color: rgb(0, 0, 0);">We will gladly provide the attached information in electronic form upon your request. If your office is aware of additional properties that could be added to this claim, please provide any available information about such properties so that we may review the information and confirm our ownership interest. You are authorized to communicate with any member of our staff concerning this claim, and we encourage you to reach us with questions.</span></div>
		<div style="text-indent: 0pt;text-align: left;"><span style="font-size: 16px; font-family: Verdana, Geneva, sans-serif; color: rgb(0, 0, 0);"><br></span></div>
		<div class="s1" style="padding-left: 236pt;text-indent: 0pt;text-align: left;"><span style="font-size: 16px; font-family: Verdana, Geneva, sans-serif; color: rgb(0, 0, 0);">Very truly yours,</span></div>
		<div class="s1" style="padding-top: 1pt;padding-left: 236pt;text-indent: 0pt;text-align: left;"><span style="font-size: 16px; font-family: Verdana, Geneva, sans-serif; color: rgb(0, 0, 0);">OAK POINT PARTNERS, LLC</span></div></div>`
	}
    const onChange = (event) => {
        // console.log(event)
		// setContent(event.editor.getData())
		setContent(event)
    }
    return <div className='pdf-container'>
		<FroalaEditorComponent tag='textarea'
		config={{fontSize:['8', '9', '10', '11', '12', '14','16', '18','20', '24', '30', '36', '48', '60', '72', '96']}}
		model={content}
		onModelChange={onChange}
		 />
        {/* <CKEditor 
		data={content} 
		config={ckConfig}
		onChange={( event, editor ) => onChange(event, editor)} 
		// onBeforeLoad={ CKEDITOR => {
		// 	CKEDITOR.plugins.addExternal('exportpdf', '/node_modules/ckeditor4-plugin-exportpdf/');
        // } }
		 /> */}
    </div>;

}