import React from 'react';

import { Card, CardBody, Button } from 'reactstrap';
import Select from '../../../../shared/components/form/MakeValueSelect';
import CheckBox from '../../../../shared/components/form/CheckBox';
import DefaultInput from '../../../../shared/components/form/DefaultClearInput';
import { connect } from 'react-redux';
import { load as loadAccount } from '../../../../redux/reducers/commonReducer';
import { Field, reduxForm } from 'redux-form';
import DropDown from '../../../../shared/components/form/Select';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import fetch from '../../../../config/service';

// config file
class Fields extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedName: 'Employee',
      fieldTypeOptions: [
        { value: "text", label: "text" },
        { value: "password", label: "password" },
        { value: "email", label: "email" },
        { value: "textarea", label: "textarea" },
        { value: "dropDown", label: "dropDown" },
        { value: "date", label: "date" },
        { value: "checkbox", label: "checkbox" },
        { value: "autoComplete", label: "autoComplete" },
        { value: "multiSelect", label: "multiSelect" },
        { value: "radio", label: "radio" },
      ],
      tableOptions: [
        { value: "Link", label: "Link" },
        { value: "Role", label: "Role" },
        { value: "Badge", label: "Badge" },
      ],
      typeOptions: [
        { value: "center", label: "center" },
        { value: "left", label: "left" },
        { value: "right", label: "right" },
      ],
      dataTypeOptions: [
        { value: "String", label: "String" },
        { value: "Number", label: "Number" },
        { value: "Boolean", label: "Boolean" },
        { value: "Array", label: "Array" },
        { value: "Object", label: "Object" },
      ],
      testController: [
        {
          controllerName: 'Employee',
          fields: [
            {
              "textAlign": 'center',
              "name": 'firstName',
              "label": 'First Name',
              "type": 'text',
              "required": true,
              "sortable": true,
              "dataType": 'String',
            },
            {
              "textAlign": 'center',
              "name": 'firstName',
              "label": 'First Name',
              "type": 'autoComplete',
              "required": true,
              "sortable": true,
              "dataType": 'String',
              "searchField": 'school_name',
              "searchApi": 'schools'
            },
          ],
        },
        {
          controllerName: 'User',
          fields: [
            {
              "textAlign": 'center',
              "name": 'status',
              "label": 'Status',
              "type": 'dropDown',
              "required": true,
              "sortable": true,
              "dataType": 'String',
              "options": [
                { 'label': 'Active', 'value': 'Active' },
                { 'label': 'Pending', 'value': 'Pending' },
              ]
            },
            {
              "textAlign": 'center',
              "name": 'firstName',
              "label": 'First Name',
              "type": 'autoComplete',
              "required": true,
              "sortable": true,
              "dataType": 'String',
              "searchField": 'class_name',
              "searchApi": 'class'
            },
          ],
        }
      ],
      controllers: [
        {
          controllerName: 'Employee',
          controllerFields: [
            {
              "Type": "controller_0controllerFieldstype0eteled",
              "Name": "controller_0controllerFieldsname0eteled",
              "Label": "controller_0controllerFieldslabel0eteled",
              "Required": 'controller_0controllerFieldsrequired0eteled',
              "Align": "controller_0controllerTableFieldstextAlign0eteled",
              "FieldType": 'controller_0controllerTableFieldsfieldType0eteled',
              "Sortable": "controller_0controllerTableFieldssortable0eteled",
              'DataType': "controller_0controllerTableFieldsdataType0eteled",
              "sortable": false,
              "required": false,
            },
          ],
        },
        {
          controllerName: 'User',
          controllerFields: [
            {
              "Type": "controller_1controllerFieldstype0eteled",
              "Name": "controller_1controllerFieldsname0eteled",
              "Label": "controller_1controllerFieldslabel0eteled",
              "Required": 'controller_1controllerFieldsrequired0eteled',
              "Align": "controller_1controllerTableFieldstextAlign0eteled",
              "FieldType": 'controller_1controllerTableFieldsfieldType0eteled',
              "Sortable": "controller_1controllerTableFieldssortable0eteled",
              'DataType': "controller_1controllerTableFieldsdataType0eteled",
              "sortable": false,
              "required": false,
            },
          ],
        }
      ],
      controllersNames: [
        { label: 'Employee', value: 'Employee' },
        { label: 'User', value: 'User' },
      ],
      selectedController: 0,
    };
  }

  componentDidMount = async () => {
    this.getFields()
  }
  getFields = async () => {
    fetch('GET', 'fields').then(async (res) => {
      if (res) {

        const { testController } = this.state
        let controllers = testController;
        // let controllersLength = testController && testController.length > 0 ? testController.length : 0;
        for (let i = 0; i < controllers.length; i++) {
          let controllerFields = controllers[i]['fields'];
          if (controllerFields && controllerFields.length > 0) {
            for (let j = 0; j < controllerFields.length; j++) {
              controllerFields[j]['FieldType'] = `controller_${i}controllerFieldsfieldType${j}eteled`;
              controllerFields[j]['Name'] = `controller_${i}controllerFieldsname${j}eteled`;
              controllerFields[j]['Align'] = `controller_${i}controllerFieldsalign${j}eteled`;
              controllerFields[j]['Required'] = `controller_${i}controllerFieldsrequired${j}eteled`;
              controllerFields[j]['Sortable'] = `controller_${i}controllerFieldssortable${j}eteled`;
              controllerFields[j]['DataType'] = `controller_${i}controllerFieldsdataType${j}eteled`;
              controllerFields[j]['Type'] = `controller_${i}controllerFieldstype${j}eteled`;
              controllerFields[j]['Label'] = `controller_${i}controllerFieldslabel${j}eteled`;

              if (controllerFields[j]['type'] === 'autoComplete') {
                controllerFields[j]['SearchField'] = `controller_${i}controllerFieldssearchField${j}eteled`;
                controllerFields[j]['SearchApi'] = `controller_${i}controllerFieldssearchApi${j}eteled`;
              }
              if ((controllerFields[j]['type'] === 'dropDown' || controllerFields[j]['type'] === 'multiSelect')) {
                let options = controllerFields[j]['options'];
                for (let k = 0; k < options.length; k++) {
                  options[k]['Label'] = `controller_${i}controllerFieldssearchField${j}label${k}eteled`
                  options[k]['Value'] = `controller_${i}controllerFieldssearchField${j}value${k}eteled`
                }
              }
            }
            controllers[i]['controllerFields'] = controllerFields;

          }
        }
        await this.setState({
          controllers: controllers
        })
      }
    }).catch((err) => {
      return err;
    })
  }
  handleChange = async (e) => {
    let { selectedName, controllers, selectedController } = this.state;
    for (let i = 0; i < controllers.length; i++) {
      if (controllers[i]['controllerName'] === e) {
        selectedController = i;
        selectedName = e;
      }
    }
    await this.setState({
      selectedName: selectedName,
      selectedController: selectedController,
      // selectedName: selectedName
    })
  }
  getFormFields = () => {

    return ([

    ]);
  }
  addControllerFields = async () => {
    let { controllers, selectedController } = this.state;
    if (controllers && controllers.length > 0) {
      let controllerLength = controllers.length;
      let controllerFields = controllers && controllers.length > 0
        && controllers[selectedController]['controllerFields']
        && controllers[selectedController]['controllerFields'].length > 0 ?
        controllers[selectedController]['controllerFields'] : [];
      let controllerFieldsLength = controllers && controllers.length > 0
        && controllers[selectedController]['controllerFields']
        && controllers[selectedController]['controllerFields'].length > 0 ?
        controllers[selectedController]['controllerFields'].length : 0;
      controllerFields.push({
        "Type": `controller_${controllerLength + 1}controllerFieldstype${controllerFieldsLength + 1}eteled`,
        "Name": `controller_${controllerLength + 1}controllerFieldsname${controllerFieldsLength + 1}eteled`,
        "Label": `controller_${controllerLength + 1}controllerFieldslabel${controllerFieldsLength + 1}eteled`,
        "Required": `controller_${controllerLength + 1}controllerFieldsrequired${controllerFieldsLength + 1}eteled`,
        "Align": `controller_${controllerLength + 1}controllerTableFieldstextAlign${controllerFieldsLength + 1}eteled`,
        "FieldType": `controller_${controllerLength + 1}controllerTableFieldsfieldType${controllerFieldsLength + 1}eteled`,
        "Sortable": `controller_${controllerLength + 1}controllerTableFieldssortable${controllerFieldsLength + 1}eteled`,
        "DataType": `controller_${controllerLength + 1}controllerTableFieldsdataType${controllerFieldsLength + 1}eteled`,
        "sortable": false,
        "required": false,
      })
      controllers[selectedController]['controllerFields'] = controllerFields;
      await this.setState({
        controllers: controllers
      })
    }
  }
  handleInputChange = async (index, fieldName, inputType, e) => {
    let { controllers, selectedController } = this.state;

    // let controllerLength = controllers.length;
    let controllerFields = controllers && controllers.length > 0
      && controllers[selectedController]['controllerFields']
      && controllers[selectedController]['controllerFields'].length > 0 ?
      controllers[selectedController]['controllerFields'] : [];

    if (inputType === 'input') {
      if (e) {
        if (e.target.value) {
          controllerFields[index][fieldName] = e.target.value
        } else {
          controllerFields[index][fieldName] = ''
        }
      } else {
        controllerFields[index][fieldName] = ''
      }
    }
    if (inputType === 'dropDown') {
      if (e) {
        controllerFields[index][fieldName] = e;
        if (e === 'dropDown') {
          let options = controllers && controllers.length > 0
            && controllers[selectedController]['controllerFields'] && controllerFields && controllerFields.length > 0 && controllerFields[index]['options'] && controllerFields[index]['options'].length > 0
            ? controllerFields[index]['options'] : [];
          options.push({
            'Label': `controller_${selectedController}controllerFields${index}options0labeleteled`,
            'Value': `controller_${selectedController}controllerFields${index}options0valueeteled`,
            "label": '',
            "value": '',
          })
          controllerFields[index]['options'] = options;
          delete controllerFields[index]['isMultiSelect'];
          delete controllerFields[index]['SearchField'];
          delete controllerFields[index]['SearchApi'];
          delete controllerFields[index]['searchField'];
          delete controllerFields[index]['searchApi'];
        } else if (e === 'multiSelect') {
          let options = controllers && controllers.length > 0
            && controllers[selectedController]['controllerFields'] && controllerFields && controllerFields.length > 0 && controllerFields[index]['options'] && controllerFields[index]['options'].length > 0
            ? controllerFields[index]['options'] : [];
          options.push({
            'Label': `controller_${selectedController}controllerFields${index}options0labeleteled`,
            'Value': `controller_${selectedController}controllerFields${index}options0valueeteled`,
            "label": '',
            "value": '',
          })
          controllerFields[index]['options'] = options;
          controllerFields[index]['isMultiSelect'] = true;
          delete controllerFields[index]['SearchField'];
          delete controllerFields[index]['SearchApi'];
          delete controllerFields[index]['searchField'];
          delete controllerFields[index]['searchApi'];

        } else if (e === 'autoComplete') {
          controllerFields[index]['SearchField'] = `controller_${selectedController}controllerFields${index}searchField0eteled`;
          controllerFields[index]['SearchApi'] = `controller_${selectedController}controllerFields${index}searchApi0eteled`;
          delete controllerFields[index]['options'];
          delete controllerFields[index]['isMultiSelect'];
        } else {
          if (fieldName === 'type') {
            delete controllerFields[index]['options'];
            delete controllerFields[index]['isMultiSelect'];
            delete controllerFields[index]['SearchField'];
            delete controllerFields[index]['SearchApi'];
            delete controllerFields[index]['searchField'];
            delete controllerFields[index]['searchApi'];
          }
        }
      } else {
        controllerFields[index][fieldName] = ''
      }
    }
    if (inputType === 'checkBox') {
      if (e) {
        controllerFields[index][fieldName] = !controllerFields[index][fieldName]
      } else {
        controllerFields[index][fieldName] = false
      }
    }
    controllers[selectedController]['controllerFields'] = controllerFields;
    await this.setState({
      controllers: controllers
    })
  }
  deleteField = async (index) => {
    let { controllers, selectedController } = this.state;
    if (controllers && controllers.length > 0) {

      controllers[selectedController]['controllerFields'].splice(index, 1)
      await this.setState({
        controllers: controllers
      })
    }
  }
  deleteOptionsField = async (index, optionsIndex) => {
    let { controllers, selectedController } = this.state;
    if (controllers && controllers.length > 0) {

      controllers[selectedController]['controllerFields'][index]['options'].splice(optionsIndex, 1)
      await this.setState({
        controllers: controllers
      })
    }
  }
  addOptions = async (index) => {
    let { controllers, selectedController } = this.state;

    // let controllerLength = controllers.length;
    let controllerFields = controllers && controllers.length > 0
      && controllers[selectedController]['controllerFields']
      && controllers[selectedController]['controllerFields'].length > 0 ?
      controllers[selectedController]['controllerFields'] : [];
    let options = controllerFields[index]['options'];
    let optionsLength = controllerFields[index]['options']
      && controllerFields[index]['options'].length > 0 ?
      controllerFields[index]['options'].length : 0;
    options.push({
      'Label': `controller_${selectedController}controllerFields${index}options${optionsLength}labeleteled`,
      'Value': `controller_${selectedController}controllerFields${index}options${optionsLength}valueeteled`,
      "label": '',
      "value": '',
    })
    controllerFields[index]['options'] = options;
    await this.setState({
      controllers: controllers
    })
  }
  submit = async () => {
    let { controllers } = this.state;
    for (let i = 0; i < controllers.length; i++) {
      let controllerFields = controllers[i]['controllerFields']
        && controllers[i]['controllerFields'].length > 0 ? controllers[i]['controllerFields'] : [];
      if (controllerFields && controllerFields.length > 0) {
        for (let j = 0; j < controllerFields.length; j++) {
          Object.keys(controllers[i]['controllerFields'][j]).forEach((items, index) => {
            if (items !== 'options') {
              let v = controllers[i]['controllerFields'][j][items];
              if (v && typeof v != 'boolean' && v.endsWith('eteled')) {
                delete controllers[i]['controllerFields'][j][items];
              }
            } else {
              let options = controllers[i]['controllerFields'][j]['options'];
              if (options && options.length > 0) {
                for (let k = 0; k < options.length; k++) {
                  Object.keys(controllers[i]['controllerFields'][j]['options'][k]).forEach((oitems, oindex) => {
                    let ov = controllers[i]['controllerFields'][j]['options'][k][oitems];
                    if (ov && typeof ov != 'boolean' && ov.endsWith('eteled')) {
                      delete controllers[i]['controllerFields'][j]['options'][k][oitems];
                    }
                  })
                }

              }
            }
          })
        }
      }
      controllers[i]['fields'] = controllers[i]['controllerFields'];
      // delete controllers[i]['controllerFields']
    }

  }
  handleFilterInputChange = async (index, optionsIndex, fieldName, inputType, e) => {
    let { controllers, selectedController } = this.state;

    // let controllerLength = controllers.length;
    let controllerFields = controllers && controllers.length > 0
      && controllers[selectedController]['controllerFields']
      && controllers[selectedController]['controllerFields'].length > 0 ?
      controllers[selectedController]['controllerFields'] : [];

    if (inputType === 'input') {
      if (e) {
        if (e.target.value) {
          controllerFields[index]['options'][optionsIndex][fieldName] = e.target.value
        } else {
          controllerFields[index]['options'][optionsIndex][fieldName] = ''
        }
      } else {
        controllerFields[index]['options'][optionsIndex][fieldName] = ''
      }
    }
    controllers[selectedController]['controllerFields'] = controllerFields;
    await this.setState({
      controllers: controllers
    })
  }
  render() {
    const { controllers, selectedController } = this.state;
    const { handleSubmit } = this.props

    return (
      <form className='form w-100 ' onSubmit={handleSubmit(this.submit)}>

        <Card className='cardForListMargin ' >
          <CardBody className='tableCardBody'>
            <div className='row'>
              <div className='col-sm-12 my-2'>
                <h4><b>Fields</b>
                </h4>
              </div>
              <div className='col-sm-6'>
                <div className="form__form-group" >
                  <div className="form__form-group-field mb-2">
                    <Field
                      component={DropDown}
                      options={this.state.controllersNames}
                      name='actions'
                      placeholder='Select Controller Name'
                      onChange={(e) => this.handleChange(e)}
                    />
                  </div>
                </div>
              </div>
              <div className='col-sm-12 pb-2'>
                <div className='row'>
                  <div className='col-sm-12'>
                    <h5><b>{`${this.state.selectedName} Controller Fields`}</b>
                      <span className='pl-2' onClick={this.addControllerFields}>
                        <FontAwesomeIcon
                          icon='plus-circle'
                          color='black'
                          size="sm"
                          style={{ fontSize: 14, color: 'black', cursor: 'pointer' }}
                        />
                      </span>
                    </h5>
                  </div>
                  {
                    controllers && controllers.length > 0 &&
                      controllers[selectedController]['controllerFields'] &&
                      controllers[selectedController]['controllerFields'].length > 0 ?
                      controllers[selectedController]['controllerFields'].map((item, index) => {
                        return (
                          <div className='col-sm-6 pb-3'>
                            <div className='card cardBorder pb-0 '>
                              <div className='card-body tableCardBody'>
                                <h5 className='text-right my-2'>
                                  <span className='pl-2' onClick={() => this.deleteField(index)}>
                                    <FontAwesomeIcon
                                      icon='trash-alt'
                                      color='red'
                                      size="sm"
                                      style={{ fontSize: 14, color: 'red', cursor: 'pointer' }}
                                    />
                                  </span>
                                </h5>
                                <div className="form__form-group" >
                                  <label className="form__form-group-label">
                                    Field Name
                                  </label>
                                  <div className="form__form-group-field mb-2">
                                    <Field
                                      type='text'
                                      component={DefaultInput}
                                      name={item.Name}
                                      placeholder='Field Name'
                                      makeValue={item[`name`] ? item[`name`] : ''}
                                      onChange={(e) => this.handleInputChange(index, `name`, 'input', e)}
                                    />
                                  </div>
                                </div>
                                <div className="form__form-group" >
                                  <label className="form__form-group-label">
                                    Label
                                  </label>
                                  <div className="form__form-group-field mb-2">
                                    <Field
                                      type='text'
                                      component={DefaultInput}
                                      name={item.Label}
                                      placeholder='Label'
                                      makeValue={item[`label`] ? item[`label`] : ''}
                                      onChange={(e) => this.handleInputChange(index, `label`, 'input', e)}
                                    />
                                  </div>
                                </div>
                                <div className="form__form-group" >
                                  <label className="form__form-group-label">
                                    Field Type
                                  </label>
                                  <div className="form__form-group-field mb-2">
                                    <Field
                                      type='text'
                                      component={Select}
                                      name={item.Type}
                                      options={this.state.fieldTypeOptions}
                                      placeholder='Field Type'
                                      valueExists={item['type']}
                                      onChange={(e) => this.handleInputChange(index, 'type', 'dropDown', e)}
                                    />
                                  </div>
                                </div>
                                {item['type'] === 'autoComplete' ?
                                  <div className="form__form-group" >
                                    <label className="form__form-group-label">
                                      Search Field
                                    </label>
                                    <div className="form__form-group-field mb-2">
                                      <Field
                                        type='text'
                                        component={DefaultInput}
                                        name={item.SearchField}
                                        placeholder='Search Field'
                                        makeValue={item['searchField']}
                                        onChange={(e) => this.handleInputChange(index, 'searchField', 'input', e)}
                                      />
                                    </div>
                                  </div>
                                  : null}
                                {item['type'] === 'autoComplete' ?
                                  <div className="form__form-group" >
                                    <label className="form__form-group-label">
                                      Search Api
                                    </label>
                                    <div className="form__form-group-field mb-2">
                                      <Field
                                        type='text'
                                        component={DefaultInput}
                                        name={item.SearchApi}
                                        placeholder='Search Api'
                                        makeValue={item['searchApi']}
                                        onChange={(e) => this.handleInputChange(index, 'searchApi', 'input', e)}
                                      />
                                    </div>
                                  </div>
                                  : null}
                                {(item['type'] === 'dropDown' || item['type'] === 'multiSelect') && item['options'] && item['options'].length > 0
                                  ? item['options'].map((optionsItem, optionsIndex) => {
                                    return (
                                      <div className=' cardBorder pb-0 mb-3 cardBorderRadius'>
                                        <div className='card-body tableCardBody'>
                                          <div className='col-sm-12'>
                                            <h5 className='text-right my-2'>
                                              <span className='pl-2'
                                                onClick={() => this.deleteOptionsField(index, optionsIndex)}>
                                                <FontAwesomeIcon
                                                  icon='trash-alt'
                                                  color='red'
                                                  size="sm"
                                                  style={{ fontSize: 14, color: 'red', cursor: 'pointer' }}
                                                />
                                              </span>
                                            </h5>
                                          </div>
                                          <div className='col-sm-12'>
                                            <div className="form__form-group" >
                                              <label className="form__form-group-label">
                                                Label
                                              </label>
                                              <div className="form__form-group-field mb-2">
                                                <Field
                                                  type='text'
                                                  component={DefaultInput}
                                                  name={optionsItem.Label}
                                                  placeholder='Label'
                                                  makeValue={optionsItem['label']}
                                                  onChange={(e) => this.handleFilterInputChange(index, optionsIndex, 'label', 'input', e)}
                                                />
                                              </div>
                                            </div>

                                          </div>
                                          <div className='col-sm-12'>
                                            <div className="form__form-group" >
                                              <label className="form__form-group-label">
                                                Value
                                              </label>
                                              <div className="form__form-group-field mb-2">
                                                <Field
                                                  type='text'
                                                  component={DefaultInput}
                                                  name={optionsItem.Label}
                                                  placeholder='Value'
                                                  makeValue={optionsItem['value']}
                                                  onChange={(e) => this.handleFilterInputChange(index, optionsIndex, 'value', 'input', e)}
                                                />
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    )
                                  })
                                  : null}
                                {(item['type'] === 'dropDown' || item['type'] === 'multiSelect')
                                  && item['options'] && item['options'].length > 0
                                  && < div className='col-sm-12 text-right'>
                                    <Button
                                      onClick={() => this.addOptions(index)}
                                      outline
                                      type='button'
                                      color='primary'
                                    >Add

                                    </Button>
                                  </div>}

                                <div className="form__form-group" >
                                  <label className="form__form-group-label">
                                    Data Type
                                  </label>
                                  <div className="form__form-group-field mb-2">
                                    <Field
                                      type='text'
                                      component={Select}
                                      name={item.DataType}
                                      options={this.state.dataTypeOptions}
                                      placeholder='Data Type'
                                      valueExists={item['dataType']}
                                      onChange={(e) => this.handleInputChange(index, 'dataType', 'dropDown', e)}
                                    />
                                  </div>
                                </div>
                                <div className="form__form-group" >
                                  <label className="form__form-group-label">
                                    Text Align
                                  </label>
                                  <div className="form__form-group-field mb-2">
                                    <Field
                                      type='text'
                                      component={Select}
                                      name={item.Align}
                                      options={this.state.typeOptions}
                                      placeholder='Text Align'
                                      valueExists={item['textAlign']}
                                      onChange={(e) => this.handleInputChange(index, 'textAlign', 'dropDown', e)}
                                    />
                                  </div>
                                </div>
                                <div className="form__form-group" >
                                  <label className="form__form-group-label">
                                    Table Field Type
                                  </label>
                                  <div className="form__form-group-field mb-2">
                                    <Field
                                      type='text'
                                      component={Select}
                                      name={item.FieldType}
                                      options={this.state.tableOptions}
                                      placeholder='Table Field Type'
                                      valueExists={item['fieldType']}
                                      onChange={(e) => this.handleInputChange(index, 'fieldType', 'dropDown', e)}
                                    />
                                  </div>
                                </div>
                                <div className="form__form-group" >
                                  <div className="form__form-group-field mb-2">
                                    <Field
                                      label='Sortable'
                                      type='checkbox'
                                      component={CheckBox}
                                      name={item.Sortable}
                                      checked={item['sortable'] ? item['sortable'] : false}
                                      onChange={(e) => this.handleInputChange(index, 'sortable', 'checkBox', e)}
                                    />
                                  </div>
                                </div>
                                <div className="form__form-group" >
                                  <div className="form__form-group-field mb-2">
                                    <Field
                                      label='Required'
                                      type='checkbox'
                                      component={CheckBox}
                                      name={item.Required}
                                      checked={item['required'] ? item['required'] : false}
                                      onChange={(e) => this.handleInputChange(index, 'required', 'checkBox', e)}
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        )
                      }) : null}
                </div>
              </div>
              <div className='col-sm-12 text-right'>
                <Button color='primary' outline type='submit'>
                  Submit
                </Button>
              </div>
            </div>
          </CardBody>
        </Card>
      </form >

    );
  }
}
Fields = reduxForm({
  form: 'FieldsForm',
  enableReinitialize: true,
})(Fields)
// You have to connect() to any reducers that you wish to connect to yourself
Fields = connect(
  state => ({
    initialValues: state.commonData.data // pull initial values from account reducer
  }),
  { load: loadAccount } // bind account loading action creator
)(Fields);
export default Fields;