import React from 'react';
import { withTranslation } from 'react-i18next';
import fetchMethodRequest from '../../../config/service';
import * as XLSX from 'xlsx/xlsx.js'
import apiCalls from '../../../config/apiCalls';

class SummaryStatsExportModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }


  componentDidMount = async () => {
    // this.getStatsFromServer()
    if (this.props.onRef) {
      this.props.onRef(this);
    }
  }


  componentWillUnmount = async () => {
    if (this.props.onRef) {
      this.props.onRef(null);
    }
  }

  getStatsFromServer = async (sellerID, sellerName) => {
    return await fetchMethodRequest('GET', `${apiCalls.dealSizingSummaryExport}?sellerID=${sellerID}`)
      .then(async (response) => {
        if (response) {
          let totalMaxCount = 0
          let totalMaxValue = 0
          let totalMaxCA_Count = 0
          let totalMaxCA_Value = 0

          response.dealSizingStats.map(item => {
            totalMaxCount += item.maxCount;
            totalMaxValue += item.maxValue;
            totalMaxCA_Count += item.maxCA_Count;
            totalMaxCA_Value += item.maxCA_Value;
          }
          );

          const Headers = ["Name ID", "Name", "Name Connection", "Connected Name", "Source", "Acquired Year", "Known Count - Total", "Known Value – Total", "Unknown/Over $100 Count – Total ",  "Count – Total", "Value - Total", "Count - CA", "Value - CA"];

          const Data = response.dealSizingStats.map(item => [
            item.businessNamesID,
            item.businessName,
            item.nameConnection,
            item.connectedName,
            item.nameSource,
            item.acquiredYear,
            item.knownCount,
            item.knownValue,
            item.unKnown50Count,
            // item.unKnownNullCount,
            item.maxCount,
            item.maxValue,
            item.maxCA_Count,
            item.maxCA_Value
          ]);

          let section2 = [...[Headers], ...Data]

          console.log(section2)
          return [
            {

              stats: [
                ["EstateId", "EstateName", "", "", "", "", "", "", "", "Count - Total", "Value - Total", "Count - CA", "Value - CA"],
                [sellerID, sellerName, "", "", "", "", "", "", "", totalMaxCount, totalMaxValue, totalMaxCA_Count, totalMaxCA_Value],
              ],
            },
            {

              stats: section2,
            },
          ];
        }
      }).catch((err) => {
        return err;
      });
  }


  exportSaleSheet = (data) => {
    const wb = XLSX.utils.book_new();
    const ws = XLSX.utils.aoa_to_sheet([]);

    let row = 0;

    data.forEach((sectionData, index) => {

      row += 1;

      XLSX.utils.sheet_add_aoa(ws, sectionData.stats, { origin: row });

      const headerRow = row + 1;
      const headerColumns = ["A", "B", "C", "D", "E", "F", "G", "H", "I", "J"];
      headerColumns.forEach((col) => {
        ws[`${col}${headerRow}`] = ws[`${col}${headerRow}`] || {};
        ws[`${col}${headerRow}`].s = {
          fill: {
            fgColor: { rgb: "008080" }
          },
          font: { bold: true },
        };
      });

      row += sectionData.stats.length;

      if (index < data.length - 1) {
        const borderRow = row;

        const columns = ["A", "B", "C", "D", "E", "F", "G", "H", "I", "J"];
        columns.forEach((col) => {
          const cell = `${col}${borderRow}`;
          ws[cell] = ws[cell] || {};
          ws[cell].s = ws[cell].s || {};
          ws[cell].s.border = {
            bottom: { style: "thick", color: { rgb: "000000" } },
          };
        });

        row += 1;
      }
    });

    ws['!cols'] = [
      { wch: 30 },
      { wch: 15 },
      { wch: 20 },
      { wch: 15 },
      { wch: 25 },
      { wch: 10 },
      { wch: 15 },
      { wch: 15 },
      { wch: 10 },
      { wch: 15 },
    ];

    XLSX.utils.book_append_sheet(wb, ws, "SummaryStats");

    XLSX.writeFile(wb, "SummaryStats.xlsx");
  };

  handleExport = async (sellerID, sellerName) => {
    const data = await this.getStatsFromServer(sellerID, sellerName);
    if (data) {
      this.exportSaleSheet(data);
    }
  };

  render() {
    const { t } = this.props;
    return (
      <div>
      </div >
    );
  }
}
export default withTranslation('common')(SummaryStatsExportModal);