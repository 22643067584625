import { Dropdown, DropdownProps } from "primereact/dropdown";
import React, { useEffect, useMemo, useState } from "react";

type DropdownSelectProps = {
    dropdownSelectProps: DropdownProps | undefined;
    onSelectCallback: (selectedValue: any) => void;
};

export const DropdownSelect = ({
    dropdownSelectProps,
    onSelectCallback
}: DropdownSelectProps) => {

    const [selectValue, setSelectValue] = useState<any | undefined>(dropdownSelectProps?.options && dropdownSelectProps?.options[0] ? dropdownSelectProps.options[0] : undefined);

    let optionPropertyNames = useMemo(() => {
        if (dropdownSelectProps?.options && dropdownSelectProps?.options[0]) {
            return Object.getOwnPropertyNames(dropdownSelectProps?.options[0]);
        }
    }, [dropdownSelectProps]);

    useEffect(() => {
        onSelectCallback(selectValue);
    }, [selectValue, onSelectCallback]);


    return (
        <span>
            <Dropdown
                style={{ marginLeft: 7, paddingTop: 4, paddingBottom: 4 }}
                options={dropdownSelectProps?.options ? dropdownSelectProps?.options : []}
                optionLabel={optionPropertyNames ? optionPropertyNames[0] : undefined}
                value={selectValue}
                onChange={(e) => { setSelectValue(e.value) }}
                tooltip={dropdownSelectProps?.tooltip ? dropdownSelectProps.tooltip : undefined}
                tooltipOptions={{ position: "top" }}
            />
        </span>
    );
}