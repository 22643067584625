import React from 'react';
import store from '../../../App/store';
import Loader from '../../../App/Loader';
import DataTables from '../../CommonDataTable/DataTable';

//config
import apiCalls from '../../../../config/apiCalls'
import fetch from '../../../../config/service';


// config file
export default class Checklist extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      type: 'Checklists',
      dealTypes: [{ "label": "BK", "value": "BK" }, { "label": "CR", "value": "CR" }],
      Required: [{ "label": "True", "value": "True" }, { "label": "False", "value": "False" }],

    };
  }

  static getDerivedStateFromProps(props, state) {
    let storeData = store.getState()
    let languageData = storeData && storeData.settingsData && storeData.settingsData.settings && storeData.settingsData.settings.languageTranslation ? storeData.settingsData.settings.languageTranslation : ""
    return { languageData: languageData };
  }



  componentDidMount = () => {
    this.getLabelOptions()
  }

  /**
   * get labels from db
   */
  getLabelOptions = () => {
    fetch('GET', apiCalls.Labels)
      .then(async (response) => {
        if (response) {
          let labels = response.labels;
          let modifiedLabels = [];
          for (let label of labels) {
            modifiedLabels.push({
              label: label.labelName,
              value: label.labelName,

            })
          }
          await this.setState({
            company: modifiedLabels
          })
          // this.setUnitName()

        }
      }).catch((err) => {
        return err;
      });


  }

  /**
   * 
   * @returns tablefields to show in table
   */
  getTableFields = () => {
    let data = [
      {
        "show": true,
        textAlign: 'left',
        // width: 80,
        field: 'label',
        // fieldType: 'BoolText',
        "mobile": true,
        header: 'Label Name',
        "filter": true,
        "displayInSettings": true,
        "displayInFilter": true,
        sortable: true,
      }, {
        "show": true,
        textAlign: 'left',
        // width: 80,
        field: 'purpose',

        "mobile": true,
        header: 'Purpose Name',
        "filter": true,
        "displayInSettings": true,
        "displayInFilter": true,
        sortable: true,
      },
      {
        "show": true,
        textAlign: 'left',
        // width: 80,
        field: 'dealType',
        "mobile": true,
        header: 'dealTypes',
        "filter": true,
        "displayInSettings": true,
        "displayInFilter": true,
        sortable: true,
      }, {
        "show": true,
        textAlign: 'left',
        // width: 80,
        field: 'checklistItem',
        "mobile": true,
        header: 'Checklist Item',
        "filter": true,
        "displayInSettings": true,
        "displayInFilter": true,
        sortable: true,
      },
      {
        "show": true,
        textAlign: 'left',
        // width: 40,
        field: 'sequence',
        "allowInt": true,
        "filterType": 'num',
        "mobile": true,
        header: 'Sequence',
        "filter": true,
        "displayInSettings": true,
        "displayInFilter": true,
        sortable: true,
      }, {
        "show": true,
        textAlign: 'left',
        // width: 40,
        field: 'required',
        fieldType: "Boolean",
        "mobile": true,
        header: 'Required',
        "filter": true,
        "displayInSettings": true,
        "displayInFilter": true,
        sortable: true,
      },

      {
        "show": true,
        "textAlign": "left",
        // "width": 40,
        "fieldType": 'Actions',
        "field": "Actions",
        "header": "Actions",
        "filter": false,
        "displayInSettings": true,
        "sortable": false
      }
    ]
    return data;
  };

  /**
   * 
   * @returns formfields to show in form
   */
  getFormFields = () => {
    return ([

      {
        "value": "",
        "type": "dropDown",
        "name": "label",
        "label": "Label",
        "options": this.state.company,
        "id": "name",
        "placeholder": "Name",
        "required": true
      },
      {
        //'show': false,
        // 'isMultiSelect': true,
        "value": "",
        "type": "dropDown",
        "label": "Purpose",
        "name": "purpose",
        "id": "name",
        "placeholder": "Name",
        "required": true
      },
      {
        "value": "",
        "type": "text",
        "formatType": "Number",
        "name": "sequence",
        "label": "Sequence",
        "placeholder": "Sequence",
        // "required": true
      }, {
        "value": "",
        "type": "text",
        "name": "checklistItem",
        "label": "Checklist Item",
        "placeholder": "checklistItem",
      },
      {
        "value": "",
        "type": "dropDown",
        "name": "dealType",
        "label": "Deal Type",
        "id": "name",
        "options": this.state.dealTypes,
        "placeholder": "Name",
        "required": true
      },
      {
        "value": "",
        "type": "dropDown",
        "name": "required",
        "label": "Required",
        "id": "name",
        "options": this.state.Required,
        "placeholder": "required",
        "required": true
      },
      {
        //'show': false,
        "value": "",
        "type": "switch",
        "name": "activeIndicator",
        "label": "Active /Inactive Status",
        "id": "name",
        "placeholder": "Name",
        // "required": true
      },


    ]);
  }


  submit = async (item) => {


  }

  render() {
    return (
      <span>
        <Loader loader={this.state.isLoading} />
        <DataTables
          onRef={(ref) => (this.datatableref = ref)}
          getTableFields={this.getTableFields}
          formFields={this.getFormFields}
          exportRequried={false}
          printRequried={false}
          sortField={"sequence"}
          addRequried={true}
          editRequired={true}
          deleteRequired={false}
          viewRequired={true}
          settingsRequired={false}
          filterRequired={true}
          gridRequried={false}
          sample={false}
          isClearFilters={true}
          globalSearchFieldName='user'
          globalSearch={'Search'}
          type='Checklists'
          displayright={true}
          icon='cog'
          routeTo='Configuration'
          displayViewOfForm='modal'
          apiResponseKey='checklists'
          apiUrl={apiCalls.Checklist}
          entityType='employee'
        />
      </span>
    );
  }
}