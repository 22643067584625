import React from 'react';
import { Container } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import Report from './components/recoveryPlanning';
import SiteDiscovery from './components/siteDiscoveryrecoveryPlanning';
import NonSiteDiscovery from './components/nonSiteDiscoveryPlan';

const employee = ({ t }) => (
  <Container >
    {window.location.pathname === "/nonSiteDiscoveryPlan" ?
      <NonSiteDiscovery />
      : window.location.pathname === "/siteDiscoveryPlan" ?
        <SiteDiscovery /> : <Report />
    }

  </Container>
);

employee.propTypes = {
  t: PropTypes.func.isRequired,
};

export default withTranslation('common')(employee);
