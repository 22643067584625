import React, { PureComponent } from 'react';
import Select from 'react-select';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
const customStyles = {
  option: (styles, { data, isDisabled, isFocused, isSelected }) => {
    return {
      ...styles,
      background: isSelected ? "rgba(28, 160, 181, 0.29)" : "",
      color: 'black',
      textAlign: 'left',
      //display: 'inline'

    };
  },
  menu: (provided, state) => ({
    ...provided,
    zIndex: 9999,
    textTransform: 'capitalize',
  }),
}
class SelectField extends PureComponent {
  static propTypes = {
    onChange: PropTypes.func,
    name: PropTypes.string,
    placeholder: PropTypes.string,
    options: PropTypes.arrayOf(PropTypes.shape({
      value: PropTypes.string,
      label: PropTypes.string,
    })),
    value: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.shape({
        value: PropTypes.string,
        label: PropTypes.string,
      }),
    ]),
  };

  static defaultProps = {
    placeholder: '',
    options: [],
  };

  constructor(props) {
    super(props)
    this.state = {
      value: null,
      defaultValue: this.props.defaultValue ? this.props.options[0] : null,
      isEditShow: false,
    }

  }
  handleChange = (selectedOption) => {
    const { onChange, name } = this.props.input;
    this.setState({
      value: selectedOption,
      isEditShow: this.props.isEditedShow ? true : false,
    });
    // if (name === 'categoryId' || name === 'category') {
    //   this.props.getCategoryTypes(selectedOption, name);
    // }
    // 
    if (name === 'status') {
      // this.props.getTicketStatus(selectedOption);
    }
    onChange(selectedOption.value);
  };

  render() {
    const { placeholder, options, isDisable, id
    } = this.props;
    const { name, value } = this.props.input;
    const isValue = { label: value, value: value };

    const selectValue = this.props.showNull ? null :
      this.state.value && name !== "purpose" && name !== "SubCategory" ? this.state.value :
        isValue && isValue.value ? isValue :
          this.state.defaultValue ? this.state.defaultValue : null
    if (!value && !this.state.value) {
      if (this.state.defaultValue) {
        this.handleChange(this.state.defaultValue);
      }
    }
    this.setState({
      isEditShow: this.props.isEditedShow && this.state.value ? true : false,
      value: this.props.showNull ? null : this.state.value,
    })
    return (
      <div data-toggle="tool-tip"
        title={selectValue && selectValue.label && typeof selectValue.label === "string" && selectValue.label.includes("CN") ? selectValue.label : selectValue && selectValue.value ? selectValue.value : ""}>
        <Select
          styles={customStyles}
          name={name}
          id={id ? id : name}
          value={selectValue}
          isSearchable={true}
          onChange={this.handleChange}
          options={options}
          clearable={false}
          className={this.state.isEditShow ? 'react-select-isedited' : 'react-select'}
          // className="react-select-isedited "
          placeholder={placeholder}
          classNamePrefix={`react-select`}
          style={isDisable ? { background: 'lightgray', border: "none", textTransform: 'capitalize', } :
            { background: 'white', textTransform: 'capitalize', color: 'black' }} {...placeholder}
          isDisabled={isDisable}
        />
      </div>
    );
  }
}

const renderSelectField = (props) => {
  const { meta } = props;
  return (
    <div className="form__form-group-input-wrap">
      <SelectField
        className='mb-1'
        {...props}
      />
      <div className="mb-1" style={{ height: '10px' }}>
        {meta.touched && meta.error && <span className="form__form-group-error">{meta.error}</span>}
      </div>
      {/* {meta.touched && meta.error && <span className="form__form-group-error">{meta.error}</span>} */}
    </div>
  );
};

renderSelectField.propTypes = {
  input: PropTypes.shape({
    onChange: PropTypes.func,
    name: PropTypes.string,
  }).isRequired,
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.string,
  }),
  options: PropTypes.arrayOf(PropTypes.shape({
    value: PropTypes.string,
    label: PropTypes.string,
  })),
  placeholder: PropTypes.string,
};

renderSelectField.defaultProps = {
  meta: null,
  options: [],
  placeholder: '',
};

export default withTranslation('common')(renderSelectField);
