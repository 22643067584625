import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Redirect } from 'react-router';
import { withTranslation } from 'react-i18next';
import fetch from '../../../config/service';
import apiCalls from '../../../config/apiCalls';
import LogoutModal from '../../Cruds/CommonModals/LogoutModal';

class TopbarMenuLinks extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLogout: false,
      isLogoutSuccess: false,
      isOpenLogoutModal: false
    };
  }

  static propTypes = {
    title: PropTypes.string.isRequired,
    icon: PropTypes.string.isRequired,
    path: PropTypes.string.isRequired,
  };
  componentDidMount = () => {
  }
  logout = async () => {
    await this.props.toggleClose();
    this.setState({
      isOpenLogoutModal: true
    })
  }

  // close delete modal
  closeLogoutModal = (type) => {
    this.setState({
      isOpenLogoutModal: false
    })
    if (type === 'close') {
      this.setState({
        isLogoutSuccess: true
      })
      return fetch('POST', apiCalls.logout)
        .then((response) => {
          if (response) {
            if (response.respCode && response.respMessage) {

            } else if (response.errorMessage) {
              // display message
              // showToasterMessage(response.errorMessage, 'error');
            }
            this.setState({ isLoading: false });
          }
        }).catch((err) => {
          return err;
        }
        );

    }
  }

  render() {
    const { title, icon, path, toggleClose, t, } = this.props;
    return (
      <div class=" expandIcon" data-toggle="tool-tip" title={title}>
        {title === 'Log Out' ?
          <div className="topbar__link" style={{
            paddingBottom: '10px',
            paddingLeft: '23px', paddingTop: '10px'
          }}
            onClick={() => this.logout()}>
            <span className={`topbar__link-icon lnr lnr-${icon}`} />
            <span className="topbar__link-title">{t(title)}</span>
          </div>
          :
          // <div onClick={() => {
          //   history.push({
          //     pathname: path,
          //     state: { updateTopbarProfileData: this.props.updateTopbarProfileData }
          //   })
          // }} className="topbar__link">
          //   <span className={`topbar__link-icon lnr lnr-${icon}`} />
          //   <p className="topbar__link-title">{t(title)}</p>
          // </div>
          <Link className="topbar__link px-3" data-toggle="tool-tip" title={title}
            to={{
              pathname: path,
              // state: { updateTopbarProfileData: this.props.updateTopbarProfileData }
            }}
            onClick={toggleClose}>
            <p className="topbar__link-title">{t(title)}</p>
          </Link>
        }

        {this.state.isLogoutSuccess ?
          <div>
            <Redirect to='/log_in' />
          </div> : null}

        {/* display modal for logout */}
        <LogoutModal openLogoutModal={this.state.isOpenLogoutModal}
          closeLogoutModal={this.closeLogoutModal} />
      </div>

    );
  }
}
export default withTranslation('common')(TopbarMenuLinks);
