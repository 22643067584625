import React from 'react';
import { Container, Row } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import CountDisplay from './components/Threshold';
import Loader from '../App/Loader';

class Dashboard extends React.Component {
  static propTypes = {
    t: PropTypes.func.isRequired,
    payload: PropTypes.arrayOf(PropTypes.shape({
      color: PropTypes.string,
      value: PropTypes.string,
    })).isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      dashboardData: {},
      isLoading: false,
    };
  }

  componentDidMount = () => {
    let user = JSON.parse(localStorage.getItem('loginCredentials'));
    if (user) {
      this.setState({
        loginData: user,
        role: user.role
      })
    }
  }
  handlePercentage = async (Val) => {
    let counter = 0;
    const interval = setInterval(async () => {
      if (this.state.isLoading) {
        counter = counter + 15;
        await this.setState({
          progress: counter,
        })
      } else {
        if (!this.state.isLoading) {
          clearInterval(interval);
        }
      }
    }, 100);
  }

  isShowLoader = async (value) => {
    this.setState({
      isLoading: value
    })
    if (value === true) {
      this.handlePercentage("1")
    }
  }



  render() {
    return (
      <div>
        <Loader loader={this.state.isLoading} progress={this.state.progress} className="progress-loader" />

        <Container className="tableCardBody mt-3" style={{ width: '98%', marginLeft: '20px', backgroundColor: "white" }}>
          <Row>
            <div className="topbar__left">
              <span className={`tableheadericon ml-3 lnr lnr-layers`} />
              <h4 className="Kanban_title_card " style={{ width: 300 }}>Deal Sizing Threshold</h4></div>
          </Row>
          <CountDisplay
            isShowLoader={this.isShowLoader}
          />
        </Container >
      </div>
    );
  }
}

export default withTranslation('common')(Dashboard);


