import React from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import classNames from 'classnames';
import { Button, Modal, ModalHeader, ModalBody } from 'reactstrap';
import configImages from '../../../../config/configImages';
import Loader from '../../../App/Loader';

const style = {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    border: "1px solid gray"
};
// let newpassval = value => (value ? config.userPasswordRegex.test(value) ? undefined : 'Password must have at least 12 characters and contain at least 1 Uppercase and 1 special character.' : configMessage.fillField)

// config file
export default class BifurcationPopModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isDisableGo: false,
            isLoading: false,
            beforePreview: this.props.bifurcationResponse.beforePreview,
            afterPreview: this.props.bifurcationResponse.afterPreview

        };
    }


    componentDidMount() {
        this.props.onRef(this);


    }

    componentWillUnmount = () => {
        this.props.onRef(null);
    }

    submitHandler = async () => {
        const { selectedValue } = this.props
        await this.setState({ isDisableGo: true, isLoading: true })
        this.props.cancelBifurcationPopModal(this.props.newStateClaimID ? this.props.newStateClaimID : "Opp", "false")

    }

    handleBifurcationProcess = () => {
        // https://opra2.dosystemsinc.com/taskdetails?id=6707c349897903f4f513d8b0&userId=638896bb23745f40e9bbaf74
    }




    // hari need to move to derived class or controller
    changeFieldValues = (item, column) => {
        const { selectedValue } = this.props
        if (column.field === "statePropertyID") {
            if (this.props.selectedStids.includes(item[column.field])) {
                return <span style={{ color: "red" }}><b>{item[column.field]}</b></span>
            } else {
                return <span >{item[column.field]}</span>
            }
        } else if (column.field === "oppClaimID" && column.isChangetextColor) {
            let lengthofoppClaimIDbefore = this.state.beforePreview[0].oppClaimID.split(".")
            console.log("sddddd", lengthofoppClaimIDbefore)
            if (item[column.field].split(".").length > lengthofoppClaimIDbefore.length) {
                return <span style={{ color: "red" }}><b>{item[column.field]}</b></span>
            } else if (this.props.selectedStids.includes(item["statePropertyID"]) && selectedValue.includes("Merge")) {
                return <span style={{ color: selectedValue.includes("Merge") ? "#025002" : "red" }}><b>{item[column.field]}</b></span>
            } else {
                return <span style={{}}>{item[column.field]}</span>

            }
        } else if (column.field === "stateClaimID" && column.isChangetextColor && selectedValue.includes("State UP") && this.props.selectedStids.includes(item["statePropertyID"])) {
            return <span style={{ color: selectedValue.includes("Merge") ? "#025002" : "red" }}><b>{item[column.field]}</b></span>
        }
        else {
            if (item[column.field] && typeof item[column.field] !== "object") {
                return item[column.field];
            }
        }
    };


    render() {
        const modalClass = classNames({
            'modal-dialog--colored': this.state.colored,
            'modal-dialog--header': this.state.header,
        });
        const { isOpenBifurcationPopModal, selectedBgColor, tableHeader } = this.props
        const { } = this.state
        const selectedValue = this.props.selectedValue === "State UP Initiated Claim Merge Bifurcation" ? "State UP Initiated Claim Merge" : this.props.selectedValue
        return (
            <div className="rnd_Modal" style={{ all: "unset" }}>
                <Modal
                    isOpen={isOpenBifurcationPopModal}
                    fade={false}
                    className={`p-0 fullHeader multi_sort_Modal bifurcationModal ${modalClass} rnd_modal`}
                    // style={style}
                    style={{ maxWidth: 2000 }}
                // size={{ width: this.state.width, height: this.state.height }}
                // position={{ x: this.state.x, y: this.state.y }}
                // onDragStop={(e, d) => {
                //     this.setState({ x: d.x, y: d.y });
                // }}
                // onResizeStop={(e, direction, ref, delta, position) => {
                //     this.setState({
                //         width: ref.style.width,
                //         height: ref.style.height,
                //         ...position
                //     });
                // }}
                // enableResizing={false}
                >
                    <ModalHeader className={" kanbanScreen"} style={{ border: "none", backgroundColor: selectedBgColor }}>
                        <div className="d-flex" style={{ textAlign: "center" }}>
                            <button className="lnr lnr-cross modal__close-btn" type="button" style={{ color: "black", right: 40 }}
                                onClick={() => this.props.cancelBifurcationPopModal()} />
                            <span />
                            <h4 className='' style={{ textAlign: "center" }}><b>{selectedValue} - Confirmation Page</b></h4>
                        </div>

                    </ModalHeader>
                    <ModalBody className={"modal__body mb-0 pt-0"} style={{ height: "100%" }}
                    >
                        <Loader loader={this.state.isLoading} />

                        <div className='d-flex align-items-center'>
                            <div className="card">
                                {/* header={`Before ${tableHeader}`} */}
                                <h4 className='mt-2'><b>{selectedValue.includes("Merge") ? "Before State UP Initiated Merge" : `Before ${tableHeader} Bifurcation`} </b></h4>
                                <DataTable className='mt-3' value={this.state.beforePreview} showGridlines sortOrder={1} groupField='oppClaimID'
                                >
                                    <Column field="statePropertyID" header="St. Prop. ID" body={this.changeFieldValues} style={{ width: "75px" }} />
                                    <Column field="propertyID" header="OPP Prop. ID" body={this.changeFieldValues} style={{ width: "75px" }} />
                                    <Column field="stateClaimID" header="St. Claim ID" body={this.changeFieldValues} style={{ width: "75px" }} />
                                    <Column field="previousStateClaimID" header="Prev. St. Claim ID" body={this.changeFieldValues} style={{ width: "75px" }} />
                                    <Column field="oppClaimID" header="OPP Claim ID" body={this.changeFieldValues} style={{ width: "75px" }} />
                                    <Column field="previousOppClaimID" header="Prev. OPP Claim ID" body={this.changeFieldValues} style={{ width: "75px" }} />
                                </DataTable>
                            </div>
                            <div className='mx-3 my-auto'>
                                <img
                                    src={selectedValue.includes("Merge") ? configImages.mergeIcon : configImages.split2Icon}
                                    style={{ width: 70, height: 70, backgroundColor: selectedValue.includes("Merge") ? "" : selectedBgColor, borderRadius: 0 }}
                                    className='m-2 p-2'
                                />
                            </div>

                            <div className="card">
                                <h4 className='mt-2'><b>{selectedValue.includes("Merge") ? "After State UP Initiated Merge" : `After ${tableHeader} Bifurcation`} </b></h4>

                                {/* header={`After ${tableHeader}`} */}
                                <DataTable className='mt-3' value={this.state.afterPreview} showGridlines sortOrder={1} groupField='oppClaimID'
                                >
                                    <Column field="statePropertyID" header="St. Prop. ID" body={this.changeFieldValues} style={{ width: "75px" }} />
                                    <Column field="propertyID" header="OPP Prop. ID" body={this.changeFieldValues} style={{ width: "75px" }} />
                                    <Column field="stateClaimID" header="St. Claim ID" isChangetextColor={true} body={this.changeFieldValues} bodyStyle={{ backgroundColor: selectedBgColor }} style={{ width: "75px" }} />
                                    <Column field="previousStateClaimID" header="Prev. St. Claim ID" body={this.changeFieldValues} bodyStyle={{ backgroundColor: selectedBgColor }} style={{ width: "75px" }} />
                                    <Column field="oppClaimID" header="OPP Claim ID" isChangetextColor={true} body={this.changeFieldValues} bodyStyle={{ backgroundColor: selectedBgColor }} style={{ width: "75px" }} />
                                    <Column field="previousOppClaimID" header="Prev. OPP Claim ID" body={this.changeFieldValues} bodyStyle={{ backgroundColor: selectedBgColor }} style={{ width: "75px" }} />
                                </DataTable>
                            </div>
                        </div>
                        <div className='row justify-content-center'>
                            <Button style={{ backgroundColor: "#4ea250", color: "white", marginTop: "25px", height: 34 }} onClick={() => this.submitHandler()} disabled={this.state.isDisableGo}>Confirm</Button>
                            <Button color="primary" style={{ marginTop: "25px", height: 34 }} onClick={() => this.props.cancelBifurcationPopModal()}>Cancel</Button>

                        </div>
                    </ModalBody>
                </Modal>
            </div>
        );
    }
}