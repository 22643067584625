import React from 'react';
import { Container } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import Checklist from './components/Checklist';
const checklist = ({ t }) => (
  <Container>
    <Checklist />
  </Container>
);

checklist.propTypes = {
  t: PropTypes.func.isRequired,
};

export default withTranslation('common')(checklist);
