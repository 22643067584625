const intialState = {
    userData: {}
}

const userReducer = (state = intialState, action) => {
    switch (action.type) {
        case 'SET_UESRDATA':
            return {
                ...state,
                userData: action.payload,
            }
        default: return state;
    }
}
export default userReducer;