import React from 'react';
import Scrollbar from 'react-smooth-scrollbar';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import SidebarContent from './SidebarContent';
import { SidebarProps } from '../../../shared/prop-types/ReducerProps';
import apiCalls from '../../../config/apiCalls';
import fetch from "../../../config/service";

export default class Sidebar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      menuList: [],
      loginCredentials: {},
      adminsitrationList: [],
      resourcesList: [],
      Counts: []
    }

  }
  componentDidMount = async () => {
    let loginCredentials = localStorage.getItem("loginCredentials");
    if (loginCredentials) {
      loginCredentials = JSON.parse(loginCredentials);
      this.setState({
        loginCredentials: loginCredentials
      })
    }
    await this.Counts()

    await this.getMenuListFromServer();

  }

  Counts = async () => {
    return fetch('GET', apiCalls.Counts)
      .then(async (response) => {
        if (response) {
          await this.setState({
            Counts: response,
          })
        }
      }).catch((err) => {
        return err;
      });

  }

  getMenuListFromServer = async () => {
    let loginCredentials = this.state.loginCredentials;

    if (loginCredentials) {
      let filterCriteria = {}, url;
      filterCriteria.sortfield = 'sequenceNo';
      filterCriteria.direction = 'asc';
      url = `${apiCalls.MenuList}?filter=${JSON.stringify(filterCriteria)}`;
      return fetch('GET', url)
        .then(async (res) => {
          if (res && res.menulists && res.menulists.length > 0) {
            let menuList = res.menulists;
            let rolePermissions = localStorage.getItem("rolePermissions");
            rolePermissions = JSON.parse(rolePermissions);
            if (rolePermissions) {
              let neWmenuList = [];
              let keys = Object.keys(rolePermissions);
              let Logout = keys.findIndex(u => u === "Logout")
              if (Logout === -1) {
                keys.push("Logout")                
              }
              let profile = keys.findIndex(u => u === "My Profile")
              if (profile === -1) {
                keys.push("My Profile")
              }
              let CountKeys = Object.keys(this.state.Counts)
              if (menuList) {
                menuList.forEach((item) => {
                  if (item.submenus && item.submenus.length > 0) {
                    let newSubmenus = [];
                    item.submenus.forEach(sitem => {
                      keys.forEach(element => {
                        if (sitem.title === element) {
                          let CountTitle = "total".concat(sitem.title)
                          if (rolePermissions[element] !== "NoView") {
                            for (var i = 0; i < CountKeys.length; i++) {
                              if (CountTitle === "totalCompany") {
                                CountTitle = "totalBusinesses"
                              } else if (CountTitle === "totalInvestor") {
                                CountTitle = "totalInvestors"
                              } else if (CountTitle === "totalAddress") {
                                CountTitle = "totalAddresses"
                              } else if (CountTitle === "totalTree") {
                                CountTitle = "totalTrees"
                              }
                              if (CountKeys[i] === CountTitle) {
                                sitem.count = this.state.Counts[CountTitle]
                              }
                            }
                            newSubmenus.push(sitem);
                          }
                        }
                      });
                    })
                    if (newSubmenus && newSubmenus.length > 0) {
                      item.submenus = newSubmenus;
                      neWmenuList.push(item);
                    }
                  } else {
                    keys.forEach(element => {
                      if (item.title === element) {
                        if (rolePermissions[element] !== "NoView") {
                          neWmenuList.push(item);
                        }
                      }
                    });
                  }
                });
              }
              // let counts = neWmenuList.find(o => o.group === 'Administration');
              const avengers = neWmenuList.filter(character => character.group === 'Administration');             
              await this.setState({ menuList: neWmenuList, avengers: avengers.length });
            }
          }
        })

    }
  }

  render() {
    const { changeToDark, changeToLight, changeMobileSidebarVisibility, sidebar } = this.props;
    const sidebarClass = classNames({
      sidebar: true,
      'sidebar--show': sidebar.show,
      'sidebar--collapse': sidebar.collapse,
    });

    return (
      <div className={sidebarClass}>
        <button className="sidebar__back" type="button" onClick={changeMobileSidebarVisibility} />
        <Scrollbar className="sidebar__scroll scroll">
          <div className="sidebar__wrapper sidebar__wrapper--desktop">
            <SidebarContent
              onClick={() => { }}
              changeToDark={changeToDark}
              changeToLight={changeToLight}
              menuList={this.state.menuList}
              adminsitrationList={this.state.avengers}
              Counts={this.state.Counts}
            />
          </div>
          <div className="sidebar__wrapper sidebar__wrapper--mobile">
            <SidebarContent
              onClick={changeMobileSidebarVisibility}
              changeToDark={changeToDark}
              changeToLight={changeToLight}
              resourcesList={this.state.resourcesList}

            />
          </div>

        </Scrollbar>
      </div>
    );
  };
}

Sidebar.propTypes = {
  sidebar: SidebarProps.isRequired,
  changeToDark: PropTypes.func.isRequired,
  changeToLight: PropTypes.func.isRequired,
  changeMobileSidebarVisibility: PropTypes.func.isRequired,
};