import React, { PureComponent } from 'react';
import { Card, CardBody, Col, Row } from 'reactstrap';

import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import config from "../../../config/config"
import store from '../../App/store'
class CountDisplay extends PureComponent {
	static propTypes = {
		t: PropTypes.func.isRequired,
	};

	constructor(props) {
		super(props);
		this.state = {
			activeIndex: 0,
			languageData: ''
		};
	}
	static getDerivedStateFromProps(props, state) {
		let storeData = store.getState()
		let languageData = storeData && storeData.settingsData && storeData.settingsData.settings && storeData.settingsData.settings.languageTranslation ? storeData.settingsData.settings.languageTranslation : ""
		return { languageData: languageData };
	}
	componentDidMount() {
		if (localStorage.getItem('loginCredentials')) {
			let user = JSON.parse(localStorage.getItem('loginCredentials'));
			if (user.roleName) {
				this.setState({ employeeRole: user.roleName });
			}
		}
	}

	handleClick = (index) => {
		this.setState({
			activeIndex: index,
		});
	};

	render() {
		const { employeeRole } = this.state
		return (
			<div>
				{/* {dashboardData ? */}
				<Row>
					<Col md={12} xl={3} lg={6} xs={12}
						style={employeeRole === "Search Manager" ? { display: "block" } : { display: "none" }}
					>
						<Card style={{ paddingBottom: '20px' }} >
							<CardBody className="dashboard__card-widget">
								<div className="card__title" style={{ textTransform: "capitalize" }}>
									<h4 className="bold-text"><Link to="/siteDiscoveryPlan" className='pl-0'>Weekly Recovery Plan</Link></h4>
									<p>Site Discovery</p>
								</div>

							</CardBody>
						</Card>
					</Col>
					<Col md={12} xl={3} lg={6} xs={12}
						style={employeeRole === "Recovery Manager" || employeeRole === config.roleNames.coreOpsManager ? { display: "block" } : { display: "none" }}
					>
						<Card style={{ paddingBottom: '20px' }}>
							<CardBody className="dashboard__card-widget">
								<div className="card__title" style={{ textTransform: "capitalize" }}>
									<h4 className="bold-text"><Link to="/nonSiteDiscoveryPlan" className='pl-0'>Weekly Recovery Plan</Link></h4>
									<p>
									</p>
								</div>
							</CardBody>
						</Card>
					</Col>
					<Col md={12} xl={3} lg={6} xs={12}
					// style={employeeRole === "LeaderShip" || employeeRole === "Admin" || employeeRole && employeeRole.includes("Analyst") ? { display: "block" } : { display: "none" }}
					>
						<Card style={{ paddingBottom: '20px' }}>
							<CardBody className="dashboard__card-widget">
								<div className="card__title" style={{ textTransform: "capitalize" }}>
									<h4 className="bold-text"><Link to="/reportPlan" className='pl-0'>Weekly Recovery Report</Link></h4>
								</div>
							</CardBody>
						</Card>
					</Col>
				</Row>
				{/* : null} */}
				<Row>

				</Row>
			</div>
		);
	}
}

export default withTranslation('common')(CountDisplay);

