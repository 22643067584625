import React from 'react';
import { Container } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import MenuConfiguration from './components/MenuConfiguration';

const menu = ({ t }) => (
  <Container className="Custom_container">
    <MenuConfiguration />
  </Container>
);

menu.propTypes = {
  t: PropTypes.func.isRequired,
};

export default withTranslation('common')(menu);
