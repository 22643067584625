import React from 'react';
import { Col, Container, Row } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import CountDisplay from './components/bucketCheck';
import ExpiredSessionModal from '../Cruds/CommonModals/ExpiredSessionModal';

let timeout

class Dashboard extends React.Component {
  static propTypes = {
    t: PropTypes.func.isRequired,
    payload: PropTypes.arrayOf(PropTypes.shape({
      color: PropTypes.string,
      value: PropTypes.string,
    })).isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {};
  }



  componentDidUpdate() {

    // if (localStorage.getItem('loggedTime')) {
    //   let loggedTime = localStorage.getItem('loggedTime')
    //   if (timeout) {
    //     clearTimeout(timeout)
    //   }
    //   timeout = setTimeout(() => this.setState({ expiredSessionModal: true, openedMultiTabs: true }), loggedTime);
    // }

  }
  cancelexpiredSessionModal = async () => {
    await this.setState({
      expiredSessionModal: false
    })
  }
  expiredSessionModal() {
    return (
      <ExpiredSessionModal
        openConfirmationModal={this.state.expiredSessionModal}
        cancelSessionWarningModal={this.cancelexpiredSessionModal}
        getData={this.getDataFromServer}

      />
    )
  }

  componentDidMount = () => {
    let user = JSON.parse(localStorage.getItem('loginCredentials'));
    if (user) {
      this.setState({
        loginData: user,
        role: user.role
      })
    }
  }

  responseData = () => {

  }


  render() {
    return (
      <div>
        <Container className="tableCardBody" style={{ width: '98%', marginLeft: '20px' }}>
          {/* <Loader loader={this.state.isLoading} /> */}

          <Row>
            <Col md={12}>
            </Col>
          </Row>
          <CountDisplay
            responseData={this.state.responseData}
            loginData={this.state.loginData}
          />

        </Container >
        {this.state.expiredSessionModal ? this.expiredSessionModal() : null}
      </div>
    );
  }
}

export default withTranslation('common')(Dashboard);


