import React from 'react';
import store from '../../../App/store';
import apiCalls from '../../../../config/apiCalls'
import DataTables from '../../CommonDataTable/DataTable';
import fetch from '../../../../config/service';
import Loader from '../../../App/Loader';


// config file
export default class ClaimReason extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      type: 'PropertyReasons',
    };
  }

  static getDerivedStateFromProps(props, state) {
    let storeData = store.getState()
    let languageData = storeData && storeData.settingsData && storeData.settingsData.settings && storeData.settingsData.settings.languageTranslation ? storeData.settingsData.settings.languageTranslation : ""
    return { languageData: languageData };
  }



  componentDidMount = () => {
    this.getStatusOptions()
    //Screen permisions value can be edit,view, no view
    // let screenPermissions = RolePermissions.screenPermissions('Employees');
  }

  //user Task label options
  getStatusOptions = () => {
    fetch('GET', apiCalls.ClaimStatuses)
      .then(async (response) => {
        if (response) {
          let statuses = response.claimStatuses;
          let modifiedStatuses = [];
          for (let status of statuses) {
            modifiedStatuses.push({
              label: status.status,
              value: {
                status: status.status,
                id: status._id,
              },
            })
          }
          await this.setState({
            Statuses: modifiedStatuses
          })
          // this.setUnitName()

        }
      }).catch((err) => {
        return err;
      });
  }
  getTableFields = () => {
    let data = [
      // {
      //   "show": true,
      //   "textAlign": "center",
      //   "width": 6,
      //   "fieldType": "expander",
      //   "header": "",
      //   "filter": false,
      //   "sortable": false,
      //   "placeholder": "Search"
      // },
      {
        "show": true,
        textAlign: 'left',
        width: 80,
        field: 'status',
        // fieldType: 'BoolText',
        "mobile": true,
        header: 'Status Name',
        filter: true,
        "displayInSettings": true,
        "displayInFilter": true,
        sortable: true,
      },
      // {
      //   "show": true,
      //   textAlign: 'left',
      //   width: 40,
      //   field: 'sequence',
      //   "fieldType": 'number',
      //   "mobile": true,
      //   header: 'Sequence',
      //   filter: false,
      //   "displayInSettings": true,
      //   "displayInFilter": true,
      //   sortable: true,
      // },
      {
        "show": true,
        textAlign: 'left',
        width: 80,
        field: 'reason',
        fieldType: 'BoolText',
        "mobile": true,
        header: 'Reason Name',
        filter: true,
        "displayInSettings": true,
        "displayInFilter": true,
        sortable: true,
      },

      {
        "show": true,
        "textAlign": "left",
        "width": 40,
        "fieldType": 'Actions',
        "field": "Actions",
        "header": "Actions",
        "filter": false,
        "displayInSettings": true,
        "sortable": false
      }
    ]
    return data;
  };


  getFormFields = () => {
    return ([

      {
        //'show': false,
        "value": "",
        "type": "text",
        "name": "reason",
        "label": "Reason",
        "id": "name",
        "placeholder": "Reason Name",
        "required": true
      },
      {
        //'show': false,
        // 'isMultiSelect': true,
        "value": "",
        "type": "dropDown",
        "name": "status",
        "label": "Status",
        "options": this.state.Statuses,
        "id": "status",
        "placeholder": "status",
        "required": true
      },
      // {
      //   //'show': false,
      //   // 'isMultiSelect': Number,
      //   "value": "",
      //   "type": "text",
      //   "formatType": "Number",
      //   "name": "sequence",
      //   "label": "Sequence",
      //   // "options": this.state.company,
      //   // "id": "name",
      //   "placeholder": "Sequence",
      //   // "required": true
      // },
      {
        //'show': false,
        "value": "",
        "type": "switch",
        "name": "activeIndicator",
        "label": "Active /Inactive Status",
        "id": "name",
        "placeholder": "Name",
        // "required": true
      },


    ]);
  }


  submit = async (item) => {


  }

  render() {
    return (
      <span>
        <Loader loader={this.state.isLoading} />
        <DataTables
          onRef={(ref) => (this.datatableref = ref)}
          getTableFields={this.getTableFields}
          formFields={this.getFormFields}
          exportRequried={false}
          printRequried={false}
          sortField={"sequence"}
          addRequried={true}
          editRequired={true}
          isClearFilters={true}
          deleteRequired={false}
          viewRequired={true}
          settingsRequired={false}
          filterRequired={true}
          gridRequried={false}
          sample={false}
          expandTableFields={this.expandTableFields}
          globalSearchFieldName='user'
          globalSearch={'Search'}
          type='Claim Reason'
          displayright={true}
          icon='user'
          routeTo='statusReason'
          displayViewOfForm='modal'
          apiResponseKey='claimReasons'
          apiUrl={apiCalls.ClaimReasons}
          entityType='employee'
        />
      </span>
    );
  }
}