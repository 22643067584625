import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { InputText } from "primereact/inputtext";
import { RadioButton } from 'primereact/radiobutton';
import React, { useCallback, useState } from "react";
import { Button } from '@material-ui/core';
import { DropdownProps } from "primereact/dropdown";
import { DropdownSelect } from './DropdownSelect';

type InputSearchProps = {
    searchOnClick: (value: string | undefined, selectValue?: any, ofcType?: string | undefined) => void;
    type: string;
    dropdownSelectProps: DropdownProps | undefined;
};

export const InputSearch = ({
    searchOnClick,
    type,
    dropdownSelectProps
}: InputSearchProps) => {
    const [searchValue, setSearchValue] = useState<undefined | string>();
    const [selectValue, setSelectValue] = useState<any | undefined>(dropdownSelectProps?.options && dropdownSelectProps?.options[0] ? dropdownSelectProps.options[0] : undefined);
    const [ofcType, setOfcType] = useState<undefined | string>();
    const handleDropdownSelectChange = useCallback((selectedValue: any) => {
        setSelectValue(selectedValue);
    }, []);

    return (

        <>
            <div className='col-12' style={{ marginBottom: "12px" }}>
                <div className='row'>
                    <div className='col-12 col-sm-12 col-md-6 col-lg-4' style={{ position: "relative", marginLeft: "69px" }}>
                        <RadioButton
                            inputId='company'
                            name='company'
                            value='Company'
                            style={{ marginLeft: "-84px" }}
                            onChange={(e) => { setOfcType((e).value) }}
                            checked={ofcType === 'Company'}
                        />
                        <label className='p-radiobutton-label'>
                            Companies
                        </label>
                    </div>
                    <div className='col-12 col-sm-12 col-md-6 col-lg-4'>
                        <RadioButton
                            inputId='office'
                            name='office'
                            value='Office'
                            style={{ marginLeft: "-84px" }}
                            onChange={(e) => { setOfcType((e).value) }}
                            checked={ofcType === 'Office'}
                        />
                        <label className='p-radiobutton-label'>
                            Officers
                        </label>
                    </div>
                </div>
            </div>
            {ofcType === "Company" ?
                <div className="p-inputgroup searchField">
                    <span className="p-input-icon-left">
                        <i className="pi pi-search" style={{ display: "block", fontSize: "14px" }} />
                        <span className="p-input-icon-right" style={{ marginTop: "2px" }} >
                            {searchValue ?
                                <FontAwesomeIcon
                                    color='red'
                                    icon={faTimes}
                                    data-toggle="tool-tip"
                                    onClick={() => { setSearchValue("") }}
                                />
                                : undefined}
                            <InputText
                                type="search"
                                name='globalSearch'
                                id='globalSearch'
                                value={searchValue}
                                style={{ height: 34, paddingLeft: "2.5rem" }}
                                onChange={(e) => { setSearchValue((e.target as HTMLInputElement).value) }}
                                onKeyPress={event => {
                                    if (event.key === 'Enter') {
                                        searchOnClick(searchValue, selectValue, "Company")
                                    }
                                }}
                                placeholder={type ? type + " Search" : 'Search'}
                                size={25}
                            />
                        </span>
                    </span>

                    {dropdownSelectProps ?
                        <DropdownSelect
                            dropdownSelectProps={dropdownSelectProps}
                            onSelectCallback={handleDropdownSelectChange}
                        />
                        : undefined}


                    <div style={{ marginLeft: 7, marginTop: 1.25 }}>
                        <Button
                            style={{ backgroundColor: 'lightgrey' }}
                            variant={"contained"}
                            onClick={() => searchOnClick(searchValue, selectValue, "Company")}
                            size={'small'}
                        >
                            Search
                        </Button>
                    </div>
                </div>

                : ofcType === "Office" ? <div className="p-inputgroup searchField">
                    <span className="p-input-icon-left">
                        <i className="pi pi-search" style={{ display: "block", fontSize: "14px" }} />
                        <span className="p-input-icon-right" style={{ marginTop: "2px" }}>
                            {searchValue ?
                                <FontAwesomeIcon
                                    color='red'
                                    icon={faTimes}
                                    data-toggle="tool-tip"
                                    onClick={() => { setSearchValue("") }}
                                />
                                : undefined}
                            <InputText
                                type="search"
                                name='globalSearch'
                                id='globalSearch'
                                value={searchValue}
                                style={{ height: 34, paddingLeft: "2.5rem" }}
                                onChange={(e) => { setSearchValue((e.target as HTMLInputElement).value) }}
                                onKeyPress={event => {
                                    if (event.key === 'Enter') {
                                        searchOnClick(searchValue, selectValue, "Office")
                                    }
                                }}
                                placeholder={"Officers Search"}
                                size={25}
                            />
                        </span>
                    </span>

                    {dropdownSelectProps ?
                        <DropdownSelect
                            dropdownSelectProps={dropdownSelectProps}
                            onSelectCallback={handleDropdownSelectChange}
                        />
                        : undefined}

                    <div style={{ marginLeft: 7, marginTop: 1.25 }}>
                        <Button
                            style={{ backgroundColor: 'lightgrey' }}
                            variant={"contained"}
                            onClick={() => searchOnClick(searchValue, selectValue, "Office")}
                            size={'small'}
                        >
                            Search
                        </Button>
                    </div>
                </div> : null
            }
        </>
    );
};