import React from 'react';
import { Container } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import AuditsLog from './components/AuditsLog';

const auditsLog = ({ t }) => (
  <Container>
    <AuditsLog />
  </Container>
);

auditsLog.propTypes = {
  t: PropTypes.func.isRequired,
};

export default withTranslation('common')(auditsLog);