import React from 'react';
import RulesConfiguration from './components/RuleConfiguration';

const LogIn = () => (
  <div className="rule_Screen">
    <RulesConfiguration />
  </div>
);

export default LogIn;
