import React from 'react';
import store from '../../../App/store';
import DataTables from '../../CommonDataTable/DataTable';
// config file
import apiCalls from '../../../../config/apiCalls'
import fetch from '../../../../config/service'

export default class Status extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      type: 'Status',
    };
  }

  static getDerivedStateFromProps(props, state) {
    let storeData = store.getState()
    let languageData = storeData && storeData.settingsData && storeData.settingsData.settings && storeData.settingsData.settings.languageTranslation ? storeData.settingsData.settings.languageTranslation : ""
    return { languageData: languageData };
  }

  componentDidMount = () => {
    this.getPurposeOptions()
    this.getLabelOptions()
  }

  /**
   * get labels from db
   */
  getLabelOptions = () => {
    fetch('GET', apiCalls.Labels)
      .then(async (response) => {
        if (response) {
          let labels = response.labels;
          let modifiedLabels = [];
          for (let label of labels) {
            modifiedLabels.push({
              label: label.labelName,
              value: {
                labelName: label.labelName,
                id: label._id,
              },
            })
          }
          await this.setState({
            company: modifiedLabels
          })
        }
      }).catch((err) => {
        return err;
      });
  }

  /**
   * get purposes from db
   */
  getPurposeOptions = () => {
    fetch('GET', apiCalls.Purposes)
      .then(async (response) => {
        if (response) {
          let apps = response.purposes;
          let modifiedApps = [];
          for (let app of apps) {
            modifiedApps.push({
              label: app.purposeName,
              value: {
                labelName: app.purposeName,
                id: app._id,
                purposelabel: app.labelName,
                purposelabelId: app.labelId
              },
            })
          }
          await this.setState({
            Purpose: modifiedApps
          })

        }
      }).catch((err) => {
        return err;
      });
  }

  /**
   * 
   * @returns tablefields to show in grid
   */
  getTableFields = () => {
    let data = [
      {
        "show": true,
        textAlign: 'left',
        // width: 65,
        field: 'statusName',
        fieldType: 'BoolText',
        "mobile": true,
        header: 'Name',
        filter: true,
        "displayInSettings": true,
        "displayInFilter": true,
        sortable: true,
      },
      {
        "show": true,
        textAlign: 'left',
        // width: 40,
        field: 'purposeName',
        "mobile": true,
        header: 'Purpose Name',
        filter: true,
        "displayInSettings": true,
        "displayInFilter": true,
        sortable: true,
      },
      {
        "show": true,
        textAlign: 'left',
        // width: 40,
        field: 'labelName',
        "mobile": true,
        header: 'Label Name',
        filter: true,
        "displayInSettings": true,
        "displayInFilter": true,
        sortable: true,
      },

      {
        "show": true,
        "textAlign": "left",
        // "width": 40,
        "fieldType": 'Actions',
        "field": "Actions",
        "header": "Actions",
        "filter": false,
        "displayInSettings": true,
        "sortable": false
      }
    ]
    return data;
  };

  /**
   * 
   * @returns formfields to show in form
   */
  getFormFields = () => {
    return ([
      {
        "value": "",
        "type": "dropDown",
        "name": "purposeName",
        "label": "Purpose",
        "options": this.state.Purpose,
        "id": "name",
        "placeholder": "Name",
        "required": true
      },
      {
        "value": "",
        "type": "text",
        "name": "labelName",
        "label": "Label",
        "id": "name",
        "placeholder": "Name",

      },
      {
        "value": "",
        "type": "text",
        "name": "statusName",
        "label": "Status",
        "id": "name",
        "placeholder": "Name",
        "required": true
      },
      {
        "value": "",
        "type": "switch",
        "name": "activeIndicator",
        "label": "Active /Inactive Status",
        "id": "name",
        "placeholder": "Name",
      },

    ]);
  }

  submit = async (item) => {

  }

  render() {
    return (
      <span>
        <DataTables
          onRef={(ref) => (this.datatableref = ref)}
          getTableFields={this.getTableFields}
          isClearFilters={true}
          formFields={this.getFormFields}
          exportRequried={false}
          printRequried={false}
          addRequried={true}
          editRequired={true}
          deleteRequired={false}
          viewRequired={true}
          settingsRequired={false}
          filterRequired={true}
          gridRequried={false}
          sample={false}
          globalSearchFieldName='user'
          globalSearch={'Search'}
          type='Status'
          displayright={true}
          icon='users'
          routeTo='status'
          displayViewOfForm='modal'
          apiResponseKey='statuss'
          apiUrl={apiCalls.Status}
          entityType='employee'
        />

      </span>
    );
  }
}