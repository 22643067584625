import React from 'react'
import configMessages from '../../../config/configMessages';
const noViewStyle = {
    display: "flex",
    alignContent: "center",
    alignItems: "center",
    height: "100vh",
    justifyContent: "center",
}

class NoViewComponent extends React.Component {
    render() {
        return (

            <div style={noViewStyle}><h3 style={{ color: "red" }}>
                {configMessages.noViewMessage}
            </h3></div>

        )
    }
}


export default NoViewComponent;