import React from 'react';
import DataTables from '../../CommonDataTable/DataTable';
import config from '../../../../config/config';
import apiCalls from '../../../../config/apiCalls'
import store from '../../../App/store';
import RolePermissions from '../../CommonModals/Permissions';

// config file
export default class ValidatedPropertyUpdater extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			type: 'ValidatedPropertyUpdater',
			rolePermission: ""
		};
	}

	static getDerivedStateFromProps(props, state) {
		let storeData = store.getState()
		let languageData = storeData && storeData.settingsData && storeData.settingsData.settings && storeData.settingsData.settings.languageTranslation ? storeData.settingsData.settings.languageTranslation : ""
		return { languageData: languageData };
	}

	componentDidMount = () => {
		//Screen permisions value can be edit,view, no view
		let screenPermissions = RolePermissions.screenPermissions('ValidatedPropertyUpdater');
		if (screenPermissions) {
			this.setState({
				rolePermission: screenPermissions
			})
		}
	}

	getTableFields = () => {
		let { languageData } = this.state
		let data =
			[

				// {
				// 	textAlign: "left",
				// 	width: 80,
				// 	field: "batchNo",
				// 	mobile: true,
				// 	header: "Batch No.",
				// 	filter: true,
				// 	sortable: true,
				// 	show: true,
				// 	textCapitalize: true,
				// 	displayInSettings: true,
				// },
				{
					textAlign: "left",
					width: 80,
					field: "estateId",
					mobile: true,
					header: "ESTATE ID",
					filter: false,
					sortable: true,
					show: true,
					textCapitalize: true,
					displayInSettings: true,
					"allowInt": true,
					"filterType": "num",
				},
				{
					textAlign: "left",
					width: 80,
					field: "estateName",
					mobile: true,
					header: "Estate Name",
					filter: true,
					sortable: true,
					show: true,
					textCapitalize: true,
					displayInSettings: true,
				},
				{
					textAlign: "left",
					width: 80,
					field: "type",
					mobile: true,
					header: "Type",
					filter: false,
					sortable: true,
					show: true,
					textCapitalize: true,
					displayInSettings: true,
				},
				{
					textAlign: "left",
					width: 80,
					field: "status",
					mobile: true,
					header: "Status",
					filter: false,
					sortable: true,
					show: true,
					textCapitalize: true,
					displayInSettings: true,
				},
				{
					textAlign: "left",
					width: 80,
					field: "processedCount",
					mobile: true,
					header: "Processed Count",
					filter: false,
					sortable: true,
					show: true,
					textCapitalize: true,
					displayInSettings: true,
					fieldType: "number",
				},
				{
					textAlign: "left",
					width: 80,
					field: "updatedCount",
					mobile: true,
					header: "Records Updated",
					filter: false,
					sortable: true,
					show: true,
					textCapitalize: true,
					displayInSettings: true,
					fieldType: "number",
				},

				{
					textAlign: "center",
					show: true,
					mobile: true,
					width: 80,
					field: "insertDate",
					fieldType: "Date",
					dateFormat: config.basicDateFromat,
					header: "Created Date",
					filter: false,
					sortable: true,
					displayInSettings: true,
				},
				{
					textAlign: "center",
					show: true,
					mobile: true,
					width: 80,
					field: "updated",
					fieldType: "Date",
					dateFormat: config.basicDateFromat,
					header: "Updated Date",
					filter: false,
					sortable: true,
					displayInSettings: true,
				},
				{
					textAlign: "right",
					width: 80,
					field: "error",
					mobile: true,
					header: "error",
					filter: false,
					sortable: true,
					show: true,
					fieldType: "text",
					displayInSettings: true,
				}
			]
		return data;
	};


	getFormFields = () => {
		let { languageData } = this.state
		return ([
			{
				'show': false,
				"value": "",
				"type": "text",
				"name": "firstName",
				"label": "First Name",
				"id": "firstName",
				"placeholder": "First Name",
				"required": true
			},

		]);
	}

	render() {
		return (
			<span>
				<DataTables
					onRef={(ref) => (this.datatableref = ref)}
					getTableFields={this.getTableFields}
					formFields={this.getFormFields}
					sortField={"insertDate"}
					exportRequried={false}
					printRequried={false}
					screenPermissionsRoute={"validated Property Updater"}
					addRequried={false}
					editRequired={false}
					isClearFilters={false}
					deleteRequired={false}
					viewRequired={true}
					settingsRequired={false}
					filterRequired={false}
					gridRequried={false}
					sample={false}
					globalSearchFieldName='DealName'
					globalSearch={'Search'}
					type="Validated Property"
					displayright={true}
					icon='tag'
					routeTo='ValidatedPropertyUpdater'
					displayViewOfForm='modal'
					apiResponseKey={"validatedPropertyUpdaters"}
					apiUrl={"ValidatedPropertyUpdater"}
					entityType='employee'
				/>
			</span>
		);
	}
}