import React from 'react';
import { Button, Modal, ModalBody, Row, ModalHeader, Label, Table } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import ModalFooter from 'reactstrap/lib/ModalFooter';
import classNames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { InputText } from 'primereact/inputtext';
import { faPlusCircle, faTimes, faStarOfLife } from '@fortawesome/free-solid-svg-icons';
import { Dropdown } from 'primereact/dropdown';
import apiCalls from '../../../config/apiCalls'
import fetchMethodRequest from '../../../config/service'
import MenuDepthModal from './AddMenuTable'
// config file
class MenuModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      PaymentsArray: {},
      colored: false,
      header: true,
      clickSaved: false,
      recordData: {},
      subLevels: []
    };
  }

  componentDidMount = async (type) => {
    if (this.props.onRef) {
      this.props.onRef(this);
    }
    if (this.props.selectedRowData) {
      const { selectedRowData } = this.props
      await this.setState({
        mainlevel: selectedRowData.label,
        mainUrl: selectedRowData.url,
        firstSequence: selectedRowData.sequence,
        secondlevel: selectedRowData.subLabel,
        secondUrl: selectedRowData.subUrl,
        secondSequence: selectedRowData.subSequence,
        thirdDepth: selectedRowData.items,
        activeorInactive: selectedRowData.activeIndicator,
        selectedRowData: selectedRowData
      })
      this.getMenuItems(selectedRowData.label)

    }
  }

  componentWillUnmount() {
    if (this.props.onRef) {
      this.props.onRef(null);
    }
  }

  //Set Values on Onchange
  SetValues = async (e, type) => {
    await this.setState({ [type]: e.target ? e.target.value : e.value })
    if (e.value && type === "mainlevel") {
      this.getMenuItems(e.value)
    }
    if (type === "mainlevel" && this.state.showlabelError) {
      this.setState({
        showlabelError: false
      })

    }
    if (type === "firstSequence" && this.state.showsequenceError) {
      this.setState({
        showsequenceError: false
      })
    }
  }

  closeMenuModal = async () => {
    await this.props.closeMenuModal()
  }

  changeFieldType(e) {
    this.setState(prevState => ({
      [e]: !prevState[e]
    }));
  }

  SaveData = async () => {
    let userBody = {}
    let method, Url;
    if (this.props.selectedRowData && this.props.selectedRowData._id) {
      method = "PUT"
      Url = `${apiCalls.topBarMenulists}/${this.props.selectedRowData._id}`
      userBody = this.state.selectedRowData
    } else {
      method = "POST"
      Url = `${apiCalls.topBarMenulists}`
    }
    if (this.state.mainlevel && this.state.firstSequence) {
      userBody.label = this.state.mainlevel
      userBody.url = this.state.mainUrl
      userBody.sequence = parseInt(this.state.firstSequence ? this.state.firstSequence : 0)
      userBody.activeIndicator = this.state.activeorInactive
      let isValidationExists = this.thirdDepth ? await this.thirdDepth.validationErrors() : true
      if (isValidationExists) {

        userBody.subLabel = this.state.secondlevel
        userBody.subSequence = this.state.secondSequence
        userBody.subUrl = this.state.secondUrl
        userBody.items = this.thirdDepth ? this.thirdDepth.getreturnRows() : []

        return fetchMethodRequest(method, Url, userBody).then(async (response) => {
          if (response) {
            await this.props.closeMenuModal(response)
            // window.location.reload();
          }
        });
      }
    } else {
      if (!this.state.mainlevel) {
        this.setState({
          showlabelError: true,
        })
      }
      if (!this.state.firstSequence) {
        this.setState({
          showsequenceError: true,
        })
      }

    }


  }

  getThirdDepth() {
    const { selectedRowData } = this.props

    return (
      <MenuDepthModal
        onRef={(ref) => (this.thirdDepth = ref)}
        options={this.props.finalLevels}
        loadRows={selectedRowData.items}

      />
    )
  }


  getMenuItems(data) {
    let filterCriteria = {}, url;
    filterCriteria.sortfield = 'sequenceNo';
    filterCriteria.direction = 'asc';
    filterCriteria.criteria = [{ key: "label", value: encodeURIComponent(data), type: "eq" }]
    url = `${apiCalls.topBarMenulists}?filter=${JSON.stringify(filterCriteria)}`;
    let mainLevels = [], subLevels = [];
    return fetchMethodRequest('GET', url)
      .then(async (res) => {
        if (res && res.topBarMenulists && res.topBarMenulists.length > 0) {
          for (var obj of res.topBarMenulists) {
            if (obj.subLabel !== null) {
              let index = subLevels.findIndex(item => item.label === obj.label)
              if (index === -1) {
                subLevels.push({
                  label: obj.subLabel, value: obj.subLabel
                })
              }
            } else {
              for (var item of obj.items) {
                let index = subLevels.findIndex(item => item.label === obj.label)
                if (index === -1) {
                  subLevels.push({
                    label: item.label, value: item.label
                  })
                }
              }
            }

          }
          let data = {}
          data.value = res.topBarMenulists[0].sequence
          this.SetValues(data, "firstSequence")
          this.setState({
            subLevels: subLevels
          })
        } else {
          this.setState({
            subLevels: []
          })
        }
      })
  }

  render() {
    const modalClass = classNames({
      'modal-dialog--colored': this.state.colored,
      'modal-dialog--header': this.state.header,
    });
    return (
      < div >

        <Modal isOpen={this.props.openmenuModal} fade={true}
          className={`right modal-dialog-centered modal-dialog--primary  ${modalClass}`}
          style={{ maxWidth: 1050 }}

        >
          <ModalHeader className={"modal__header kanbanScreen d-flex "}>
            <p className="bold-text  modal__title ml-2" > {this.props.selectedRowData !== "add" ? "Edit Menu" : "Add Menu"}</p>
          </ModalHeader>
          <ModalBody className='deleteModalBody' style={{
            padding: 10,
            // width: 110,
            maxHeight: "87vh",
            height: "auto",
            overflow: "auto",
            textAlign: "left"

          }}>
            <>
              <div className="form__form-group mb-3" style={{ height: '50px' }}>
                <div className='d-flex'><Label className="form__form-group-label">Top Menu</Label>
                  <FontAwesomeIcon icon={faPlusCircle}
                    className='pl-1'
                    color='red'
                    data-toggle="tool-tip"
                    title="Click to add depth"
                    onClick={() => this.changeFieldType('firstDepth')}
                    style={{ width: 15 }}
                  />   <FontAwesomeIcon icon={faStarOfLife}
                    className='pl-1'
                    color='red'
                    data-toggle="tool-tip"
                    title="Mandatory"
                    onClick={() => this.setState({ mainUrl: null })}
                    style={{ width: 10 }}
                  /></div>
                {this.state.firstDepth ? <InputText
                  type="search"
                  name='mainlevel'
                  id='mainlevel'
                  value={this.state['mainlevel']}
                  style={{ width: "100%" }}
                  onChange={(e) => this.SetValues(e, "mainlevel")}
                //placeholder={'Check From'}
                /> : <Dropdown
                  options={this.props.mainLevels}
                  style={{ width: "100%" }}
                  value={this.state.mainlevel}
                  onChange={(e) => this.SetValues(e, "mainlevel")} />
                }
                <span style={this.state.showlabelError ? { color: "red", display: "block" } : { display: "none" }}>Please fill the field</span>
              </div>
              <div className="form__form-group mb-3" style={{ height: '50px' }}>
                <Label className="form__form-group-label">Top Menu Url                      <FontAwesomeIcon icon={faTimes}
                  className='pl-1'
                  color='red'
                  data-toggle="tool-tip"
                  title="Click to clear Url"
                  onClick={() => this.setState({ mainUrl: null })}
                  style={{ width: 15 }}
                /></Label>
                <Dropdown
                  type="search"
                  name='mainlevel'
                  id='mainlevel'
                  value={this.state['mainUrl']}
                  style={{ width: "100%" }}
                  options={this.props.routes}
                  onChange={(e) => this.SetValues(e, "mainUrl")}
                //placeholder={'Check From'}
                />
              </div>
              <div className="form__form-group mb-3" style={{ height: '50px' }}>
                <Label className="form__form-group-label">Top Menu Sequence <FontAwesomeIcon icon={faStarOfLife}
                  className='pl-1'
                  color='red'
                  data-toggle="tool-tip"
                  title="Mandatory"
                  onClick={() => this.setState({ mainUrl: null })}
                  style={{ width: 10 }}
                /></Label>
                <InputText
                  type="search"
                  name='mainlevel'
                  id='mainlevel'
                  value={this.state['firstSequence']}
                  style={{ width: "100%" }}
                  onChange={(e) => this.SetValues(e, "firstSequence")}
                //placeholder={'Sequence'}
                />
                <span style={this.state.showsequenceError ? { color: "red", display: "block" } : { display: "none" }}>Please fill the field</span>
              </div>
              {!this.state['mainUrl'] ?
                <>
                  <div className="form__form-group mb-3" style={{ height: '50px' }}>
                    <div className='d-flex'><Label className="form__form-group-label">First Depth</Label>
                      <FontAwesomeIcon icon={faPlusCircle}
                        className='pl-1'
                        color='red'
                        data-toggle="tool-tip"
                        title="Click to add depth"
                        onClick={() => this.changeFieldType('secondDepth')}
                        style={{ width: 20 }}
                      /> </div>
                    {this.state.secondDepth ? <InputText
                      type="search"
                      name='mainlevel'
                      id='mainlevel'
                      value={this.state['secondlevel']}
                      style={{ width: "100%" }}
                      onChange={(e) => this.SetValues(e, "secondlevel")}
                    //placeholder={'Second Depth '}
                    /> : <Dropdown
                      options={this.state.subLevels}
                      style={{ width: "100%" }}
                      value={this.state.secondlevel}
                      onChange={(e) => this.setState({ secondlevel: e.value })} />
                    }
                  </div>
                  <div className="form__form-group mb-3" style={{ height: '50px' }}>
                    <Label className="form__form-group-label">First Depth Url                      <FontAwesomeIcon icon={faTimes}
                      className='pl-1'
                      color='red'
                      data-toggle="tool-tip"
                      title="Click to add depth"
                      onClick={() => this.setState({ secondUrl: null })}
                      style={{ width: 15 }}
                    /></Label>
                    <Dropdown
                      type="search"
                      name='secondUrl'
                      id='secondUrl'
                      value={this.state['secondUrl']}
                      style={{ width: "100%" }}
                      options={this.props.routes}
                      onChange={(e) => this.SetValues(e, "secondUrl")}
                    //placeholder={'Second Depth Url'}
                    />
                  </div>
                  <div className="form__form-group mb-3" style={{ height: '50px' }}>
                    <Label className="form__form-group-label">First Depth Sequence</Label>
                    <InputText
                      type="search"
                      name='mainlevel'
                      id='mainlevel'
                      value={this.state['secondSequence']}
                      style={{ width: "100%" }}
                      onChange={(e) => this.SetValues(e, "secondSequence")}
                    //placeholder={'Sequence'}
                    />
                  </div>

                </>
                : null}
              <div className="form__form-group mb-3" style={{ height: '50px' }}>
                <Label className="form__form-group-label">Active/InActive</Label>
                <div className={'toggle-btn '}>
                  <input
                    className={'toggle-btn__input'}
                    type="checkbox"
                    name={"activeorInactive"}
                    onChange={(e) => this.setState(prevState => ({
                      "activeorInactive": !prevState["activeorInactive"]
                    }))}
                    checked={this.state.activeorInactive}
                  />
                  <button
                    type="button"
                    className={"toggle-btn__input-label"}
                    onClick={(e) => this.setState(prevState => ({
                      "activeorInactive": !prevState["activeorInactive"]
                    }))}
                  >Toggle
                  </button>
                </div >
              </div>
              {!this.state['secondUrl'] && !this.state["mainUrl"] ?
                < div className="form__form-group mb-3" style={{ height: '50px' }}>
                  <Label className="form__form-group-label">Second Depth</Label>

                  {this.getThirdDepth()}
                </div>
                : null}

            </>
          </ModalBody>
          <ModalFooter className="modalFooter " style={{ display: "block", width: "100%" }}>
            <div>
              <Button color='danger' outline type="button" className={this.props.displayViewOfForm === 'screen' ? "m-2" : "mr-auto mb-0"} onClick={this.closeMenuModal}>
                <FontAwesomeIcon
                  style={{ width: "15px" }}
                />
                {('Back') ? ('Cancel') : 'Cancel'} </Button>
              <Button
                style={{ float: "right" }}
                color='primary'
                className={this.props.displayViewOfForm === 'screen' ? "m-2" : "mb-0"}
                outline
                onClick={() => this.SaveData()}
                // type="submit"
                disabled={this.state.hidebtn}
              >
                {this.props.selectedRowData !== "add" ? "Update" : 'Save'}
              </Button>

            </div>

          </ModalFooter>
        </Modal >
      </div >
    );
  }
}
export default withTranslation('common')(MenuModal);
