import React from 'react';
import {
  Card, CardBody, TabContent, TabPane, Nav, NavItem, NavLink, Row
} from 'reactstrap';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import  OnlineClaimHistory from '../ClaimHistory';
import NoViewComponent from '../CommonDataTable/NoView';
import OnlineClaimStatusForm from './OnlineClaimStatusForm';

class onlineClaimStatus extends React.Component {
  static propTypes = {
    t: PropTypes.func,
    handleSubmit: PropTypes.func.isRequired,
    reset: PropTypes.func.isRequired,
    pristine: PropTypes.bool.isRequired,
    submitting: PropTypes.bool.isRequired,
  };
  constructor(props) {
    super(props);
    this.state = {
      settingsData: '',
      options: [
        { label: 'OPRA Validated Property Updater Logs', value: 'OPRA Validated Property Updater Logs' },
      ],
      isLoading: false,
      isUserUpdated: false,
      activeTab: '1',
      screenPermissions:""
    };
  }

  componentDidMount = async () => {
    let permissions = localStorage.getItem('rolePermissions')
		permissions=permissions?JSON.parse(permissions)["Online Claim Status From State UP Site"] :"NoView"
		await this.setState({ isLoading: false ,screenPermissions:permissions});
    // this.setState({
    //   isLoading: true,
      
    // });
  }
  componentDidUpdate() {

  }
  toggle(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab
      });
    }

  }
  render() {
    const { handleSubmit } = this.props;
    const { options } = this.state
    let isSubmitting = false

    return (
      this.state.screenPermissions==="Edit" ||this.state.screenPermissions==="View" ?

      <Card className="cardForListMargin m-0 card">

        <CardBody className="tableCardBody">
          <Row className="col-sm-12">

            <h4><span className='postionRelative pt-2 ml-1'>
              <b
              ><Link to={"#"} onClick={this.cancelReset} className="tableType pr-0" >
                Online Claim Status From State UP Site
                </Link></b>
            </span>
            </h4>

          </Row>
          <div style={{ width: "100%" }}>
            <Nav tabs >
              <NavItem>
                <NavLink
                  className={classnames({ active: this.state.activeTab === '1' })}
                  onClick={() => { this.toggle('1'); }}
                >
                 Data Entry Page
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={classnames({ active: this.state.activeTab === '2' })}
                  onClick={() => { this.toggle('2'); }}
                >
                  History
                </NavLink>
              </NavItem>
            </Nav>
            <TabContent activeTab={this.state.activeTab}>
              {this.state.activeTab === "1" ?
                <TabPane tabId={this.state.activeTab} >
                  <OnlineClaimStatusForm screenPermissions={this.state.screenPermissions}/>
                </TabPane>
                :
                null
              }
                {this.state.activeTab === "2" ?
                <TabPane tabId={this.state.activeTab} >
                  <OnlineClaimHistory criteria={[]}/>
                </TabPane>
                :
                null
              }
              
            </TabContent>
          </div >
        </CardBody>
      </Card>:this.state.screenPermissions===""?null:<NoViewComponent/>
    );
  }
}
export default onlineClaimStatus;






