import React from 'react';
import { Container } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import RfiReasons from './components/RfiReasons';
const rfiReasons = ({ t }) => (
  <Container className="Custom_container">
    <RfiReasons />
  </Container>
);

rfiReasons.propTypes = {
  t: PropTypes.func.isRequired,
};

export default withTranslation('common')(rfiReasons);
