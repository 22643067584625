import React, { PureComponent } from 'react';
import { Card, CardBody, Col, Row } from 'reactstrap';

import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import ExpiredSessionModal from '../../../containers/Cruds/CommonModals/ExpiredSessionModal';

import RolePermissions from "../../../containers/Cruds/CommonModals/Permissions"
import { Link } from 'react-router-dom';

import store from '../../App/store'
let timeout

class CountDisplay extends PureComponent {
	static propTypes = {
		t: PropTypes.func.isRequired,
	};

	constructor(props) {
		super(props);
		this.state = {
			activeIndex: 0,
			languageData: '',
			reportsArr: [
				{
					"displayTitle": "Report - Utilization – Integrity",
					"title": "Report - Utilization – Integrity",
					"text": "Utilization is defined as the total time spent on work/task out of the total available time per day. Utilization report can help management with capacity planning, estimation of time needed for tasks, and consistency of adherence to the process.",
					"route": "/integrity"
				}, {
					"displayTitle": "Report - Utilization – US Team",
					"title": "Report - Utilization – US Team",
					"text": "	Utilization is defined as the total time spent on work/task out of the total available time per day. Utilization report can help management with capacity planning, estimation of time needed for tasks, and consistency of adherence to the process.",
					"route": "/executive"
				},
				{
					"displayTitle": "Available to File",
					"title": "Report - Available to File",
					"text": "This report shows Ready To File and On Hold Properties Counts.",
					"route": "/availabletoFile"
				},
				{
					"displayTitle": "Report - Deal Sizing Report For Private Equity Portfolio",
					"title": "Report - Deal Sizing Report For Private Equity Portfolio",
					"text": "This report shows summary of Deal Sizing Report For Private Equity Portfolio.",
					"route": "/dealsizingreportforpe"

				}, {
					"displayTitle": "Report - Deal Sizing Report For Companies",
					"title": "Report - Deal Sizing Report For Companies",
					"text": "This report shows summary of deal sizing properties for companies.",
					"route": "/dealsizingreport"
				},
				{
					"displayTitle": "Report - Seller Report",
					"title": "Report - Seller Summary Report",
					"text": "This report shows summary of deal sizing properties for companies.",
					"route": "/sellerSummaryReport"
				}, {
					"displayTitle": "Report - Activity Details Report",
					"title": "Report - Activity Details Report",
					"text": "This report shows total time spent on a given task by user along with other attributes.Recovery Operation this needs to be a hyperlink",
					"route": "/reportslistView"
				},
				{
					"displayTitle": "Report - Required Evidence",
					"title": "Report - Required Evidence",
					"text": "This report shows a breakdown by estate of properties that are marked with a “Validated-Ready to File” status, and are missing evidence",
					"route": "/reportsrequiredevidence"
				},
				{
					"displayTitle": "Report - Available to Confirm Onsite",
					"title": "Report - Available to Confirm Onsite",
					"text": "Available to Confirm Onsite",
					"route": "/availabletoConfirmonSite"
				},
				{
					"displayTitle": "Report List - All Owned Names and Addresses",
					"title": "Report List - All Owned Names and Addresses",
					"text": "Report List - All Owned Names and Addresses",
					"route": "/ownedNamesandAddresses"
				}, {
					"displayTitle": "Change Report",
					"title": "Change Report",
					"text": "Change Report",
					"route": "/namesReport"
				}
			]
		};
	}
	static getDerivedStateFromProps(props, state) {
		let storeData = store.getState()
		let languageData = storeData && storeData.settingsData && storeData.settingsData.settings && storeData.settingsData.settings.languageTranslation ? storeData.settingsData.settings.languageTranslation : ""
		return { languageData: languageData };
	}
	componentDidMount() {
		if (localStorage.getItem('loginCredentials')) {
			let user = JSON.parse(localStorage.getItem('loginCredentials'));
			if (user.roleName) {
				this.setState({ employeeRole: user.roleName });
			}
		}
	}
	componentDidUpdate() {

		// if (localStorage.getItem('loggedTime')) {
		// 	let loggedTime = localStorage.getItem('loggedTime')
		// 	if (timeout) {
		// 		clearTimeout(timeout)
		// 	}
		// 	timeout = setTimeout(() => this.setState({ expiredSessionModal: true, openedMultiTabs: true }), loggedTime);
		// }


	}
	cancelexpiredSessionModal = async () => {
		await this.setState({
			expiredSessionModal: false
		})
	}
	expiredSessionModal() {
		return (
			<ExpiredSessionModal
				openConfirmationModal={this.state.expiredSessionModal}
				cancelSessionWarningModal={this.cancelexpiredSessionModal}
				getData={this.getDataFromServer}

			/>
		)
	}

	handleClick = (index) => {
		this.setState({
			activeIndex: index,
		});
	};

	render() {
		return (
			<div className='col-sm-12 row'>
				{this.state.reportsArr.map(columnId => {
					return (
						<Col md={12} xl={3} lg={6} xs={12}
							style={RolePermissions.screenPermissions(columnId.title) && RolePermissions.screenPermissions(columnId.title) !== "NoView" ? { display: "block" } : { display: "none" }}>
							<Card style={{ paddingBottom: '20px' }}>
								<CardBody className="dashboard__card-widget">
									<div className="card__title">
										<h5 className="bold-text"><Link to={columnId.route} className='pl-0'>{columnId.displayTitle}</Link></h5>
									</div>
									<p>{columnId.text}
									</p>
								</CardBody>
							</Card>
						</Col>
					)
				})}
				{this.state.expiredSessionModal ? this.expiredSessionModal() : null}

			</div>

		);
	}
}

export default withTranslation('common')(CountDisplay);

