import React from 'react';
import TextInput from 'react-autocomplete-input';
import 'react-autocomplete-input/dist/bundle.css';
// config file
export default class TextAutocomplete extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            suggestionValue: ''
        }
    }

    render() {

        const { input, placeholder, isDisable, options, maxOptions } = this.props;
        return (
            <div className="form__form-group-input-wrap ">
                <TextInput style={isDisable ? { backgroundColor: "rgb(230,230,230)" } : {}} trigger={["@"]}
                    {...input}
                    rows='10'
                    options={{ "@": options }}
                    spacer={""}
                    maxOptions={maxOptions}
                    // onChange={(e) => this.setState({
                    //     suggestionValue: e
                    // })}
                    // value={this.state.suggestionValue}
                    disabled={isDisable
                    }
                    placeholder={placeholder ? placeholder : ""}
                />
                {/* <div style={{ height: '13px' }}>
                    {touched && error && <span className="form__form-group-error">{error}</span>}
                </div> */}
            </div>
        );
    }
}