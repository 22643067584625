import React from 'react';
import store from '../../../App/store';
import apiCalls from '../../../../config/apiCalls'
import fetchMethodRequest from '../../../../config/service'
import DataTables from '../../CommonDataTable/DataTable';
import dateFormats from '../../../UI/FormatDate/formatDate';

// config file
export default class Employees extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      type: 'Purposes',
      getDataObj: {
        ranges: [">$25k"], fromSummaries: true
      }
    };
  }

  static getDerivedStateFromProps(props, state) {
    let storeData = store.getState()
    let languageData = storeData && storeData.settingsData && storeData.settingsData.settings && storeData.settingsData.settings.languageTranslation ? storeData.settingsData.settings.languageTranslation : ""
    return { languageData: languageData };
  }



  componentDidMount = async () => {
    if (this.props.onRef) {
      this.props.onRef(this);
    }
    let user = JSON.parse(localStorage.getItem('loginCredentials'));
    let DepartmentArray = [], departMentIdArray = []
    // let user = JSON.parse(localStorage.getItem('loginCredentials'));
    if (user && user.department && user.roleName && user.roleName.includes("CoreOps")) {
      DepartmentArray.push(user.department)
      departMentIdArray.push(user.departmentId)
      await this.setState({ Department: DepartmentArray, departMentIdArray: departMentIdArray })
      // this.submit()
    }
    // this.getLabelOptions()
    //Screen permisions value can be edit,view, no view
    // let screenPermissions = RolePermissions.screenPermissions('Employees');
  }
  componentWillUnmount() {
    if (this.props.onRef) {
      this.props.onRef(null);
    }
  }
  //user Task label options

  getTableFields = () => {
    let data = [
      {
        "show": true,
        "textAlign": 'left',
        "width": 80,
        "field": 'EstateName',
        "mobile": true,
        // "fieldType": "redirect",
        "header": 'Seller Name',
        filter: false,
        "displayInSettings": true,
        "displayInFilter": true,
        "sortable": true,
      }, {
        "show": true,
        "textAlign": 'center',
        "width": 80,
        "field": 'green',
        "mobile": true,
        "header": 'Green',
        "fieldType": "bucketCount",
        "allowInt": true,
        filterType: "num",
        filter: false,
        "displayInSettings": true,
        "displayInFilter": true,
        "sortable": true,
      }, {
        "show": true,
        "textAlign": 'right',
        "width": 80,
        "field": 'greenMin',
        "mobile": true,
        "allowInt": true,
        // filterType: "num",
        "fieldType": "minCount",
        "header": 'Total of Min Estimated Value in Green',
        filter: false,
        "displayInSettings": true,
        "displayInFilter": true,
        "sortable": true,
      },
      // {
      //   "show": true,
      //   "textAlign": 'right',
      //   "width": 80,
      //   "field": 'greenMax',
      //   "mobile": true,
      //   "allowInt": true,
      //   filterType: "num",
      //   "fieldType": "maxCount",
      //   "header": 'Property Max Val Amt',
      //   filter: false,
      //   "displayInSettings": true,
      //   "displayInFilter": true,
      //   "sortable": true,
      // }, 
      {
        "show": true,
        "textAlign": 'center',
        "width": 80,
        "field": 'blue',
        "mobile": true,
        "allowInt": true,
        "header": 'Blue',
        "fieldType": "bucketCount",
        filter: false,
        filterType: "num",
        "displayInSettings": true,
        "displayInFilter": true,
        "sortable": true,
      }, {
        "show": true,
        "textAlign": 'right',
        "width": 80,
        "field": 'blueMin',
        "mobile": true,
        "allowInt": true,
        // filterType: "num",
        "fieldType": "minCount",
        "header": 'Total of Min Estimated Value in Blue',
        filter: false,
        "displayInSettings": true,
        "displayInFilter": true,
        "sortable": true,
      },
      {
        "show": true,
        "textAlign": 'left',
        "width": 80,
        "field": 'Team',
        "mobile": true,
        // "fieldType": "redirect",
        "header": 'Team',
        filter: false,
        "displayInSettings": true,
        "displayInFilter": true,
        "sortable": true,
      }
      // {
      //   "show": true,
      //   "textAlign": 'right',
      //   "width": 80,
      //   "field": 'blueMax',
      //   "mobile": true,
      //   "allowInt": true,
      //   filterType: "num",
      //   "fieldType": "maxCount",
      //   "header": 'Property Max Val Amt',
      //   filter: false,
      //   "displayInSettings": true,
      //   "displayInFilter": true,
      //   "sortable": true,
      // },

    ]
    return data;
  };


  getFormFields = () => {
    return ([
    ]);
  }


  submit = async (item) => {
  }

  getSortedData = (type, field, selectedOption) => {
    var color, getField;
    if (selectedOption === "unsort") {
      field = "greenMax"
      selectedOption = "des"
    }
    getField = field.slice(-3);
    color = field.slice(0, field.length - 3);
    if (getField === "Max") {
      getField = "PropertyMaxValueAmountSum"
    } else if (getField === "Min") {
      getField = "PropertyMinValueAmountSum"
    } else {
      if (field !== "EstateName" && field !== "Team") {
        getField = "COUNT(ns᎐CompanyID)"
        color = field
      } else {
        getField = field
        color = ""
      }

    }
    return type.sort((a, b) => {
      if (color === "blue" || color === "green" && getField !== "Max" && getField !== "Min") {
        if (a[color] === undefined) {
          a[color] = {
            "COUNT(ns᎐CompanyID)": 0,
            PropertyMaxValueAmountSum: 0,
            PropertyMinValueAmountSum: 0,
            Ranges: [],
          }
        }
        if (b[color] === undefined) {
          b[color] = {
            "COUNT(ns᎐CompanyID)": 0,
            PropertyMaxValueAmountSum: 0,
            PropertyMinValueAmountSum: 0,
            Ranges: [],
          }
        }
        if (selectedOption === "asc") {
          return (a[color][getField] < b[color][getField] ? -1 : 1)
        } else {
          if (a[color] && b[color]) return (a[color][getField] > b[color][getField] ? -1 : 1)
        }
      } else if (color !== "" && getField) {
        if (selectedOption === "asc") {
          return (a[color][getField] < b[color][getField] ? -1 : 1)
        } else {
          if (a[color] && b[color]) return (a[color][getField] > b[color][getField] ? -1 : 1)
        }
      } else {
        if (selectedOption === "asc") {
          return (a[field] < b[getField] ? -1 : 1)
        } else {
          return (a[field] > b[getField] ? -1 : 1)
        }
      }
    });
  }


  getUsersSummaryData = () => {
    let allUsersData = this.state.allUsersData
    let customisedExportData = []
    if (allUsersData) {
      for (var obj of allUsersData) {
        let dataitem = {}
        dataitem.EstateName = obj.EstateName
        dataitem.Team = obj.Team
        for (var item of obj.buckets) {
          if (item.bucket === "Green") {
            dataitem.GreenCOUNT = item["COUNT(ns᎐CompanyID)"]
            dataitem.GreenPropertyMinValueAmount = item["PropertyMinValueAmountSum"]
            dataitem.GreenPropertyMaxValueAmount = item["PropertyMaxValueAmountSum"]
          }
          if (item.bucket === "Blue") {
            dataitem.BlueCOUNT = item["COUNT(ns᎐CompanyID)"]
            dataitem.BluePropertyMinValueAmount = item["PropertyMinValueAmountSum"]
            dataitem.BluePropertyMaxValueAmount = item["PropertyMaxValueAmountSum"]
          }
        }
        customisedExportData.push(dataitem)
      }
      return customisedExportData
    } else {
      return []
    }
  }


  changeDealSizingValueFormat = (allUsersData) => {
    let greenindex, blueindex, index;
    for (var obj of allUsersData) {
      greenindex = obj["buckets"].findIndex((field) => field.bucket === "Green");
      if (index !== -1) obj.green = obj.buckets[greenindex]
      blueindex = obj["buckets"].findIndex((field) => field.bucket === "Blue");
      if (index !== -1) obj.blue = obj.buckets[blueindex]
    }
    return allUsersData
  }

  getFormatedUsersData = async (data, filterCriteria, addFilters, type) => {

    if (data) {
      let Url;
      this.setState({
        progress: 0,
        isLoading: true,
        addFilters: addFilters
      })

      // await this.handlePercentage("1")
      Url = `${apiCalls.PropertySummary}?filter=${JSON.stringify(filterCriteria)}`;
      if (addFilters.includes("isExcludeRoyalties")) {
        Url = `${Url}&excludeRoyalties=true`
      } if (addFilters.includes("excludeCoOwners")) {
        Url = `${Url}&excludeCoOwners=true`
      } if (addFilters.includes("isexcludePossiblyPaid")) {
        Url = `${Url}&isexcludePossiblyPaid=true`
      } if (type) {
        Url = `${Url}&type=exportToCsv`
      }
      if (data.departMentIdArray) {
        Url = Url + `&selectedDepartment=${encodeURIComponent(data.departMentIdArray.join(", "))}`
      } else if (this.state.departMentIdArray && this.state.departMentIdArray.length > 0) {
        Url = Url + `&selectedDepartment=${encodeURIComponent(this.state.departMentIdArray.join(", "))}`

      }
      return fetchMethodRequest('POST', Url, data)
        .then(async (response) => {
          if (response && response.lastUpdatedDate) {
            await this.setState({
              lastUpdatedDate: response.lastUpdatedDate,
              Url: Url
            })
          }
          if (response && response.summary && response.summary.length > 0) {
            let responseData;
            // responseData = this.updateSlNoToData(response.summary);
            // responseData = this.updateDateFormat(responseData, this.state.dateFormat);
            responseData = this.changeDealSizingValueFormat(response.summary);
            responseData = this.getSortedData(responseData, "greenMax", "des")
            this.setState({
              isLoading: false,
              isUserUpdated: true,
              allUsersData: responseData
            })
          } else if (response && response.summary.length === 0 && response.errorMessage) {
            this.datatableref.getErrorMessage(response.errorMessage, "", "error")
            this.setState({
              isUserUpdated: true,
              isLoading: false,
              allUsersData: []
            })
          } else if (response && response.summary.length === 0) {
            this.setState({
              isUserUpdated: true,
              isLoading: false,
              allUsersData: []
            })
          } else if (response && response.errorMessage) {
            this.setState({
              isUserUpdated: true,
              isLoading: false,
              allUsersData: []
            })
          }
          return this.state.allUsersData;
        }).catch((err) => {
          return err;
        });
      // return this.state.allUsersData;
    } else {
      this.setState({
        allUsersData: []
      })
      return;
    }
  }

  render() {
    return (
      <span>
        {/* <Loader loader={this.state.isLoading} /> */}
        <DataTables
          updatedDate={this.state.lastUpdatedDate}
          onRef={(ref) => (this.datatableref = ref)}
          getTableFields={this.getTableFields}
          // criteria={[{ "key": "PropertyDataSource", "value": "Royalties", "type": "ne", "add_filter": true }]}
          formFields={this.getFormFields}
          exportRequried={false}
          excelDownload={true}
          printRequried={false}
          sortField={"EstateName"}
          addRequried={false}
          editRequired={false}
          deleteRequired={false}
          viewRequired={true}
          settingsRequired={false}
          filterRequired={false}
          gridRequried={false}
          sample={false}
          isClearFilters={true}
          getSortedData={this.getSortedData}
          getDataObj={this.state.getDataObj}
          expandTableFields={this.expandTableFields}
          globalSearchFieldName='user'
          getFormatedUsersData={this.getFormatedUsersData}
          // globalSearch={''}
          type="UP Opportunity"
          displayright={true}
          icon='menu-circle'
          routeTo='propertySummary'
          displayViewOfForm='modal'
          apiResponseKey='summary'
          apiUrl={apiCalls.PropertySummary}
          entityType='employee'
          apidataforExport={this.getUsersSummaryData}
        />
      </span>
    );
  }
}