import React from 'react';
// import store from '../../../App/store';
import apiCalls from '../../../../config/apiCalls'
import { Container } from 'reactstrap';

import DataTables from '../../CommonDataTable/DataTable';
import fetch from '../../../../config/service';
import Loader from '../../../App/Loader';


// config file
export default class Search extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      type: 'Search',
      dealTypes: [{ "label": "BK", "value": "BK" }, { "label": "CR", "value": "CR" }],
    };
  }


  componentDidMount = () => {
    // localStorage.setItem("defaultPageOption", this.props.pageLimit ? this.props.pageLimit : 100)
    //Screen permisions value can be edit,view, no view
    // let screenPermissions = RolePermissions.screenPermissions('Employees');
  }


  getTableFields = () => {
    let data = [
      {
        "show": true,
        textAlign: 'left',
        width: "col col-lg-1",
        field: 'propertyId',
        // fieldType: 'BoolText',
        "mobile": true,
        header: 'Prop. ID',
        "filter": false,
        "displayInSettings": true,
        "displayInFilter": true,
        sortable: true,
      },
      {
        "show": true,
        textAlign: 'left',
        width: "col col-lg-1",
        field: 'sourceState',
        "allowInt": true,
        "filterType": 'num',
        "mobile": true,
        header: 'Source State',
        "filter": false,
        "displayInSettings": true,
        "displayInFilter": true,
        sortable: true,
      },
      {
        "show": true,
        textAlign: 'left',
        width: "col col-lg-1",
        field: 'statePropertyId',
        // fieldType: 'BoolText',
        "mobile": true,
        header: 'St. Prop. ID',
        "filter": false,
        "displayInSettings": true,
        "displayInFilter": true,
        sortable: true,
      },
      {
        "show": true,
        textAlign: 'left',
        width: "col col-lg-1",
        field: 'propertyOwnerName',
        "allowInt": true,
        "filterType": 'num',
        "mobile": true,
        header: 'Owner Name',
        "filter": false,
        "displayInSettings": true,
        "displayInFilter": true,
        sortable: true,
      },
      {
        "show": true,
        textAlign: 'left',
        width: "col col-lg-1",
        field: 'propertyAddressLine1',
        "allowInt": true,
        "filterType": 'num',
        "mobile": true,
        header: 'Owner Add.',
        "filter": false,
        "displayInSettings": true,
        "displayInFilter": true,
        sortable: true,
      },
      {
        "show": true,
        textAlign: 'left',
        width: "col col-lg-1",
        field: 'propertyValueMax',
        "allowInt": true,
        "filterType": 'num',
        "mobile": true,
        header: 'Prop. Value Max',
        "filter": false,
        "displayInSettings": true,
        "displayInFilter": true,
        sortable: true,
      },
      {
        "show": true,
        textAlign: 'left',
        width: "col col-lg-1",
        field: 'propertyValueMin',
        "allowInt": true,
        "filterType": 'num',
        "mobile": true,
        header: 'Prop. Value Min',
        "filter": false,
        "displayInSettings": true,
        "displayInFilter": true,
        sortable: true,
      },
      {
        "show": true,
        textAlign: 'left',
        width: "col col-lg-1",
        field: 'holderName',
        "allowInt": true,
        "filterType": 'num',
        "mobile": true,
        header: 'Holder Name',
        "filter": false,
        "displayInSettings": true,
        "displayInFilter": true,
        sortable: true,
      },
      // {
      //   "show": true,
      //   textAlign: 'left',
      //   width: "col col-lg-1",
      //   field: 'propertyType',
      //   "allowInt": true,
      //   "filterType": 'num',
      //   "mobile": true,
      //   header: 'Prop. Type',
      //   "filter": false,
      //   "displayInSettings": true,
      //   "displayInFilter": true,
      //   sortable: true,
      // },
      {
        "show": true,
        textAlign: 'left',
        width: "col col-lg-1",
        field: 'propertyDescription',
        "allowInt": true,
        "filterType": 'num',
        "mobile": true,
        header: 'Prop. Description',
        "filter": false,
        "displayInSettings": true,
        "displayInFilter": true,
        sortable: true,
      },
      // {
      //   "show": true,
      //   textAlign: 'left',
      //   width: "col col-lg-1",
      //   field: 'shares',
      //   "allowInt": true,
      //   "filterType": 'num',
      //   "mobile": true,
      //   header: 'Shares',
      //   "filter": false,
      //   "displayInSettings": true,
      //   "displayInFilter": true,
      //   sortable: true,
      // },
      // {
      //   "show": true,
      //   textAlign: 'left',
      //   width: "col col-lg-1",
      //   field: 'claimNotes',
      //   "allowInt": true,
      //   "filterType": 'num',
      //   "mobile": true,
      //   header: 'Claim Notes',
      //   "filter": false,
      //   "displayInSettings": true,
      //   "displayInFilter": true,
      //   sortable: true,
      // },
      {
        "show": true,
        textAlign: 'left',
        width: "col col-lg-1",
        field: 'opraAddedDate',
        "allowInt": true,
        "filterType": 'num',
        "mobile": true,
        "dateFormat": "MM/DD/YYYY",
        "fieldType": 'Date',
        header: 'Imported Date',
        "filter": false,
        "displayInSettings": true,
        "displayInFilter": true,
        sortable: true,
      },
      {
        "show": true,
        textAlign: 'left',
        width: "col col-lg-1",
        field: 'escheatmentDate',
        "allowInt": true,
        "filterType": 'num',
        "mobile": true,
        header: 'Date Escheated',
        "dateFormat": "MM/DD/YYYY",
        "fieldType": 'Date',
        "filter": false,
        "displayInSettings": true,
        "displayInFilter": true,
        sortable: true,
      },
      {
        "show": true,
        textAlign: 'left',
        width: "col col-lg-1",
        field: 'dateOfLastContact',
        "allowInt": true,
        "filterType": 'num',
        "mobile": true,
        header: 'Date of Last Contact',
        "filter": false,
        "dateFormat": "MM/DD/YYYY",
        "fieldType": 'Date',
        "displayInSettings": true,
        "displayInFilter": true,
        sortable: true,
      },
      // {
      //   "show": true,
      //   textAlign: 'left',
      //   width: "col col-lg-1",
      //   field: 'propertyAddressLine1',
      //   "allowInt": true,
      //   "filterType": 'num',
      //   "mobile": true,
      //   header: 'Owner Add. Line 1',
      //   "filter": false,
      //   "displayInSettings": true,
      //   "displayInFilter": true,
      //   sortable: true,
      // },
      // {
      //   "show": true,
      //   textAlign: 'left',
      //   width: "col col-lg-1",
      //   field: 'propertyAddressLine2',
      //   "allowInt": true,
      //   "filterType": 'num',
      //   "mobile": true,
      //   header: 'Owner Add. Line 2',
      //   "filter": false,
      //   "displayInSettings": true,
      //   "displayInFilter": true,
      //   sortable: true,
      // },
      // {
      //   "show": true,
      //   textAlign: 'left',
      //   width: "col col-lg-1",
      //   field: 'propertyAddressLine3',
      //   "allowInt": true,
      //   "filterType": 'num',
      //   "mobile": true,
      //   header: 'Owner Add. Line 3',
      //   "filter": false,
      //   "displayInSettings": true,
      //   "displayInFilter": true,
      //   sortable: true,
      // },
      {
        "show": true,
        textAlign: 'left',
        width: "col col-lg-1",
        field: 'propertyDataSource',
        "allowInt": true,
        "filterType": 'num',
        "mobile": true,
        header: 'property Data Source',
        "filter": false,
        "displayInSettings": true,
        "displayInFilter": true,
        sortable: true,
      },
      {
        "show": true,
        textAlign: 'left',
        width: "col col-lg-1",
        field: 'propertyStatus',
        "allowInt": true,
        "filterType": 'num',
        "mobile": true,
        header: 'property Status',
        "filter": false,
        "displayInSettings": true,
        "displayInFilter": true,
        sortable: true,
      },
    ]
    return data;
  };


  getFormFields = () => {
    return ([]);
  }


  submit = async (item) => {


  }

  render() {
    return (
      <Container>
        <span>
          <Loader loader={this.state.isLoading} />
          <DataTables
            onRef={(ref) => (this.datatableref = ref)}
            getTableFields={this.getTableFields}
            formFields={this.getFormFields}
            Limit={this.props.pageLimit}
            exportRequried={false}
            excelDownload={true}
            printRequried={false}
            frozen={true}
            sortField={"sequence"}
            criteria={this.props.criteria}
            addRequried={false}
            editRequired={false}
            deleteRequired={false}
            viewRequired={true}
            dontShowTitle={true}
            settingsRequired={false}
            filterRequired={true}
            gridRequried={false}
            sample={false}
            isClearFilters={false}
            expandTableFields={this.expandTableFields}
            type='Search Result'
            displayright={true}
            routeTo='Search Result'
            displayViewOfForm='modal'
            apiResponseKey='propertySearches'
            apiUrl={apiCalls.Search}
            entityType='employee'
          />
        </span>
      </Container>
    );
  }
}