import React from 'react';
import { Container } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import Role from './components/Role';
let permissions = localStorage.getItem('rolePermissions')
    permissions = permissions ? JSON.parse(permissions)["Roles"] : "NoView"
const role = ({ t }) => (
  <Container className={permissions==="Edit"||permissions==="View"?"Custom_container":""}>
    <Role />
  </Container>
);

role.propTypes = {
  t: PropTypes.func.isRequired,
};

export default withTranslation('common')(role);
