import React from 'react';
import { Container } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import AvailableFileConfirmOnSite from './components/AvailableFileConfirmOnSite';
const availableFileConfirmOnSite = (props) => (

  <Container >
    <AvailableFileConfirmOnSite {...props} />
  </Container>
);

availableFileConfirmOnSite.propTypes = {
  t: PropTypes.func.isRequired,
};

export default withTranslation('common')(availableFileConfirmOnSite);
