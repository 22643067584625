import React from 'react';
import { Container } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import DocumentForm from './components/DocumentForm';

const documentForm = ({ t }) => (
    <Container className="">
      <DocumentForm />
    </Container>
  );
  
  documentForm.propTypes = {
    t: PropTypes.func.isRequired,
  };
  
  export default withTranslation('common')(documentForm);