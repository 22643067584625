import React from 'react';
import { Container } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import DataSourceGroupings from './components/DataSourceGroupings';
const dataSourceGroupings = ({ t }) => (
  <Container className="Custom_container">
    <DataSourceGroupings />
  </Container>
);

dataSourceGroupings.propTypes = {
  t: PropTypes.func.isRequired,
};

export default withTranslation('common')(dataSourceGroupings);
